import { z } from "zod";

export const oneLivFormUseTerms = z.object({
  useTerms: z.boolean(),
});

const validateDuration = (value: string): boolean => {
  const [hours, minutes] = value.split(":").map(Number);
  if (Number.isNaN(hours) || Number.isNaN(minutes)) return false;
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes >= 30 && totalMinutes <= 120;
};

export const oneLivFormBasicInfo = z.object({
  profilePicture: z.any().refine(
    (value) => {
      if (!value) return false;
      return (value instanceof Blob || typeof value === "string") && value;
    },
    {
      message: "Campo obrigatório",
    },
  ),
  active: z.boolean(),
  name: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" })
    .max(45, { message: "O campo deve ter no máximo 45 caracteres" })
    .refine(
      (value) => !/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/u.test(value),
      {
        message: "O campo não pode conter emojis",
      },
    ),
  pronoun: z.string().optional(),
  professionalCouncil: z.number().min(1, {
    message: "Campo obrigatório",
  }),
  professionalCouncilState: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" }),
  professionalCouncilNumber: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" }),
  initialAppointmentDuration: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" })
    .refine(validateDuration, {
      message: "A duração da consulta deve ter entre 30 minutos e 2 horas",
    }),
  initialAppointmentValue: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" })
    .refine(
      (value) => {
        if (!value) return true;
        const numericValue = Number.parseFloat(value.replace(",", "."));
        return !Number.isNaN(numericValue) && numericValue > 0;
      },
      { message: "O valor da consulta deve ser maior que R$ 0,00" },
    ),
  paymentMethods: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" }),
  summary: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" })
    .max(120, { message: "O campo deve ter no máximo 120 caracteres" }),
  firstSpecialty: z.number().min(1, {
    message: "Campo obrigatório",
  }),
  firstSpecialtyRQE: z.string().optional(),
  secondSpecialty: z.number().optional(),
  secondSpecialtyRQE: z.string().optional(),
  thirdSpecialty: z.number().optional(),
  thirdSpecialtyRQE: z.string().optional(),
});

export const oneLivFormBasicInfoWithValidation = oneLivFormBasicInfo
  .refine(
    (values) => {
      const { firstSpecialty, secondSpecialty } = values;

      const shouldDisplayErrorMessage =
        Boolean(firstSpecialty) &&
        Boolean(secondSpecialty && firstSpecialty === secondSpecialty);

      return !shouldDisplayErrorMessage;
    },
    { message: "As especialidades devem ser diferentes", path: ["secondSpecialty"] },
  )
  .refine(
    (values) => {
      const { firstSpecialty, thirdSpecialty } = values;

      const shouldDisplayErrorMessage =
        Boolean(firstSpecialty) &&
        Boolean(thirdSpecialty) &&
        firstSpecialty === thirdSpecialty;

      return !shouldDisplayErrorMessage;
    },
    { message: "As especialidades devem ser diferentes", path: ["thirdSpecialty"] },
  )
  .refine(
    (values) => {
      const { secondSpecialty, thirdSpecialty } = values;

      const shouldDisplayErrorMessage =
        Boolean(secondSpecialty) &&
        Boolean(thirdSpecialty) &&
        thirdSpecialty === secondSpecialty;

      return !shouldDisplayErrorMessage;
    },
    { message: "As especialidades devem ser diferentes", path: ["thirdSpecialty"] },
  );

export const oneLivFormSocialMedia = z.object({
  instagram: z.string().optional(),
  facebook: z.string().optional(),
  linkedin: z.string().optional(),
  tiktok: z.string().optional(),
  youtube: z.string().optional(),
});

export const oneLivFormAdditionalInfo = z.object({
  about: z.string().optional(),
  moreInfo: z.string().optional(),
});

export const oneLivFormExperiences = z.object({
  experiences: z
    .object({
      id: z.number(),
      value: z.string(),
    })
    .array(),
});

export const oneLivFormTreatmentFocus = z.object({
  treatmentFocus: z
    .object({
      id: z.number(),
      value: z.string(),
    })
    .array(),
});

export const oneLivFormEducationalBackground = z.object({
  hiddenEducationalBackgrounds: z
    .object({
      hiddenId: z.number().optional(),
      idFormation: z.number(),
      idInstitution: z.number(),
      conclusionYear: z.number().optional(),
    })
    .array()
    .optional(),
  educationalBackgrounds: z
    .object({
      hiddenId: z.number().optional(),
      idFormation: z.number(),
      idInstitution: z.number(),
      conclusionYear: z.number().optional(),
    })
    .array()
    .optional(),
});

export const oneLivFormEducationalBackgroundWithValidation =
  oneLivFormEducationalBackground.superRefine((values, ctx) => {
    const uniqueCombinations = new Set<string>();

    values.educationalBackgrounds?.forEach((background, index) => {
      if (background.idFormation) {
        if (!background.idInstitution) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["educationalBackgrounds", index, "idInstitution"],
            message: "Campo obrigatório",
          });
        }

        if (!background.conclusionYear) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["educationalBackgrounds", index, "conclusionYear"],
            message: "Campo obrigatório",
          });
        }

        if (isNaN(Number(background.conclusionYear))) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["educationalBackgrounds", index, "conclusionYear"],
            message: "Por favor, insira um ano válido",
          });
        }

        if (background.idInstitution) {
          const combination = `${background.idFormation}-${background.idInstitution}`;
          if (uniqueCombinations.has(combination)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["educationalBackgrounds", index, "idFormation"],
              message: "Combinação de formação e instituição já existe",
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["educationalBackgrounds", index, "idInstitution"],
              message: "Combinação de formação e instituição já existe",
            });
          } else {
            uniqueCombinations.add(combination);
          }
        }
      }
    });
  });

export const oneLivFormMedicalInstitution = z.object({
  hiddenMedicalInstitutions: z
    .object({
      hiddenId: z.number().optional(),
      idMedicalInstitution: z.number(),
    })
    .array()
    .optional(),
  medicalInstitutions: z
    .object({
      hiddenId: z.number().optional(),
      idMedicalInstitution: z.number(),
    })
    .array()
    .optional(),
});

export const oneLivFormMedicalInstitutionWithValidation =
  oneLivFormMedicalInstitution.superRefine((values, ctx) => {
    const uniqueMedicalInstitutions = new Set<number>();
    values.medicalInstitutions?.forEach((medicalInstitution, index) => {
      const idMedicalInstitution = medicalInstitution.idMedicalInstitution;

      if (idMedicalInstitution) {
        if (uniqueMedicalInstitutions.has(idMedicalInstitution)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["medicalInstitutions", index, "idMedicalInstitution"],
            message: "Não é possível adicionar a mesma instituição mais de uma vez",
          });
        } else {
          uniqueMedicalInstitutions.add(idMedicalInstitution);
        }
      }
    });
  });

export const oneLivFormFAQ = z.object({
  faqs: z
    .object({
      id: z.number(),
      question: z.string(),
      answer: z.string(),
    })
    .array(),
});

export const oneLivFormFAQWithValidation = oneLivFormFAQ.superRefine((values, ctx) => {
  values.faqs.forEach((background, index) => {
    if (background.question && !background.answer) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["faqs", index, "answer"],
        message: "Campo obrigatório",
      });
    }

    if (!background.question && background.answer) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["faqs", index, "question"],
        message: "Campo obrigatório",
      });
    }
  });
});

export const oneLivFormDisableProfile = z.object({
  reason: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" }),
});

export const oneLivFormSchema = oneLivFormUseTerms
  .merge(oneLivFormBasicInfo)
  .merge(oneLivFormSocialMedia)
  .merge(oneLivFormAdditionalInfo)
  .merge(oneLivFormExperiences)
  .merge(oneLivFormTreatmentFocus)
  .merge(oneLivFormEducationalBackground)
  .merge(oneLivFormMedicalInstitution)
  .merge(oneLivFormFAQ)
  .merge(oneLivFormDisableProfile);
