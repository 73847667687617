import { useEffect } from "react";
import { useRouteContext } from "@tanstack/react-router";
import { Userpilot } from "userpilot";
import { env } from "@repo/lib";

export const useUserPilotInitializer = (): void => {
  const { user } = useRouteContext({ strict: false });

  useEffect(() => {
    if (user.codUsuario === 0) {
      return;
    }

    if (user.codUsuario) {
      Userpilot.initialize(env.VITE_USER_PILOT_TOKEN);

      Userpilot.identify(String(user.codUsuario), {
        email: user.email,
      });
    }
  }, [user, user.codUsuario, user.email]);
};
