import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { AppointmentTypeList } from "@/components/appointment-type-list";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { PendingComponent } from "@/components/pending-component";
import { FullPageSpinner } from "@/components/full-page-spinner";

const AppointmentTypesPageQuery = graphql(/* GraphQL */ `
  query AppointmentTypesPageQuery {
    ...AppointmentTypeListQueryFragment
  }
`);

export const AppointmentTypesPage = (): JSX.Element => {
  const queryResult = useGraphQL(AppointmentTypesPageQuery);

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Tipos de atendimento" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <AppointmentTypeList appointmentTypeList={data} />
          ) : (
            <FullPageSpinner />
          )}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/schedule-options/appointment-types/")({
  component: AppointmentTypesPage,
  pendingComponent: () => <PendingComponent title="Tipos de atendimento" />,
});
