import { useFragment, type FragmentType } from "@repo/graphql-types";
import { graphql } from "@repo/graphql-types/gql";
import { useEffect, useState } from "react";
import { Link } from "@tanstack/react-router";
import { Virtuoso } from "react-virtuoso";
import { DoctorWithStethoscope, ExclamationCircle } from "@repo/icons";
import { type Patient } from "@repo/lib";
import {
  EmptyStateButton,
  EmptyStateIcon,
  EmptyStateImage,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { PatientListItem } from "@/components/patient-list-item";
import { ListRoot } from "@/components/list";
import { SearchInput } from "@/components/search-input";

export const PatientListQueryFragment = graphql(/* GraphQL */ `
  fragment PatientListQueryFragment on query_root {
    pacientes(
      order_by: { nome: asc }
      where: { ativo: { _eq: true }, registroVirtual: { _eq: false } }
    ) {
      nome
      telefone
      codPaciente
      ...PatientListItemFragment
    }
  }
`);

const EmptyPlaceholder = (): JSX.Element => (
  <div className="pt-8">
    <EmptyStateRoot>
      <EmptyStateIcon icon={ExclamationCircle} fill="primary" />
      <EmptyStateTitle>Nenhum paciente encontrado</EmptyStateTitle>
    </EmptyStateRoot>
  </div>
);

const Footer = (): JSX.Element => {
  return <div className="h-[6rem]" />;
};

export const PatientList = (props: {
  patientList: FragmentType<typeof PatientListQueryFragment>;
  onClick?: (patient: Patient) => void;
}): JSX.Element => {
  const data = useFragment(PatientListQueryFragment, props.patientList);

  const [searchResults, setSearchResults] = useState(data.pacientes);

  const [height, setHeight] = useState("65vh");

  useEffect(() => {
    const calculateHeight = (): void => {
      const main = document.querySelector("main")?.clientHeight ?? 0;
      const header = document.querySelector("header")?.clientHeight ?? 0;
      const bottomBar = document.querySelector("#bottom-bar")?.clientHeight ?? 0;
      const calculatedHeight = main - header - bottomBar - 50;

      setHeight(`${calculatedHeight}px`);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  return (
    <>
      {data.pacientes.length > 0 ? (
        <div className="justify-center items-center">
          <SearchInput
            items={data.pacientes}
            onSearch={setSearchResults}
            searchKeys={["nome", "telefone"]}
          />
          <Virtuoso
            style={{ height, scrollbarWidth: "none" }}
            data={searchResults}
            components={{ List: ListRoot, EmptyPlaceholder, Footer }}
            // eslint-disable-next-line react/no-unstable-nested-components -- react-virtuoso
            itemContent={(_, patient) => (
              <PatientListItem
                key={patient.codPaciente}
                onClick={props.onClick}
                patient={patient}
              />
            )}
          />
        </div>
      ) : (
        <EmptyStateRoot>
          <EmptyStateImage icon={DoctorWithStethoscope} alt="Lista de pacientes vazia" />
          <EmptyStateTitle>Você não tem pacientes adicionados</EmptyStateTitle>
          <Link to="/appointment/create" className="w-full">
            <EmptyStateButton fullWidth>Agendar paciente</EmptyStateButton>
          </Link>
        </EmptyStateRoot>
      )}
    </>
  );
};
