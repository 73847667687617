import { forwardRef } from "react";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

export const PronounSelectInput = forwardRef<
  HTMLInputElement,
  Omit<SelectInputProps<string>, "items" | "title">
>(
  (
    { onChange, ...props }: Omit<SelectInputProps<string>, "items" | "title">,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const removePronounValue = "-";

    return (
      <SelectInput<string>
        ref={ref}
        {...props}
        onChange={(value) => {
          onChange(value === removePronounValue ? "" : value);
        }}
        title="Pronome"
        items={[
          { label: "-", value: removePronounValue },
          { label: "Dr.", value: "Dr." },
          { label: "Dra.", value: "Dra." },
        ]}
      />
    );
  },
);

PronounSelectInput.displayName = "PronounSelectInput";
