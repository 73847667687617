import { SVGIconProps } from "@repo/icons";
import clsx from "clsx";
import React, { FunctionComponent } from "react";

export type CpsFeedbackBubbleFill = "primary" | "secondary" | "danger";

interface Props extends React.HTMLAttributes<HTMLElement> {
  fill?: CpsFeedbackBubbleFill;
  size?: "large" | "medium";
  Icon: FunctionComponent<SVGIconProps>;
}

const iconOptions: Record<
  string,
  { iconSize: 32 | 40 | 56; circleSize: string; squareSettings: string }
> = {
  large: {
    iconSize: 56,
    circleSize: "w-30 h-30",
    squareSettings: "-top-1 rounded-2xl p-4 after:-bottom-2 after:h-4 after:w-4",
  },
  medium: {
    iconSize: 40,
    circleSize: "w-16 h-16",
    squareSettings: "-top-3 rounded-xl p-2 after:-bottom-1 after:h-2 after:w-2",
  },
};

const fillOptions: Record<string, { light: string; dark: string }> = {
  primary: {
    light: "bg-primary-50",
    dark: "bg-primary-400 after:bg-primary-400",
  },
  secondary: {
    light: "bg-secondary-50",
    dark: "bg-secondary-400 after:bg-secondary-400",
  },
  danger: {
    light: "bg-danger-50",
    dark: "bg-danger-400 after:bg-danger-400",
  },
};

/**
 * `CpsFeedbackBubble` is a component that displan an icon in a bubble.
 *
 * @remarks
 * This component is part of the CPS UI library.
 *
 * @param props - The properties that define the `CpsFeedbackBubble` component.
 * @param fill - The color of the bubble.
 * @param size - The size of the bubble.
 * @param Icon - The icon to be displayed in the bubble.
 *
 * @returns The `CpsFeedbackBubble` component.
 */
export const CpsFeedbackBubble: React.FC<Props> = ({
  size = "large",
  fill = "secondary",
  Icon,
}) => {
  let circleClasses = "relative flex items-center justify-center rounded-full";
  let squareClasses = clsx(
    "absolute flex items-end justify-center after:absolute after:-bottom-2 after:h-4 after:w-4 after:rotate-45",
    iconOptions[size].squareSettings,
  );

  circleClasses += circleClasses.concat(` ${iconOptions[size].circleSize}`);

  circleClasses += circleClasses.concat(` ${fillOptions[fill].light}`);

  squareClasses += squareClasses.concat(` ${fillOptions[fill].dark}`);

  return (
    <div className={circleClasses}>
      <div className={squareClasses}>
        <Icon size={iconOptions[size].iconSize} className="fill-white" />
      </div>
    </div>
  );
};

export default CpsFeedbackBubble;
