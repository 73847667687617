import { cn } from "@repo/lib";

const backgroudByVariant = {
  neutral: "bg-neutral-200",
  primary: "bg-primary-100",
  secondary: "bg-secondary-100",
  danger: "bg-danger-200",
  transparent: "bg-transparent",
};

export interface DayEventBadgeProps {
  title: string;
  variant?: keyof typeof backgroudByVariant;
}

export const DayEventBadge = ({ variant, title }: DayEventBadgeProps): JSX.Element => {
  return (
    <div>
      <span
        className={cn(
          "rounded-full px-2 text-black text-xs font-medium text-nowrap overflow-visible",
          backgroudByVariant[variant ?? "transparent"],
        )}
      >
        {title}
      </span>
    </div>
  );
};
