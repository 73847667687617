import type { SetStateAction } from "react";
import { CpsModalOverlay } from "corpus";
import { cn } from "@repo/lib";

interface ActionSheetRootProps {
  children: React.ReactNode;
  closeButtonText?: string;
  setShowActionSheet: (value: SetStateAction<boolean>) => void;
  showActionSheet: boolean;
  showCloseButton?: boolean;
}

/**
 * Action sheet component.
 *
 * This component provides a customizable action sheet that allows users to
 * interact with a list of options grouped by categories. It offers options
 * to control visibility, user interactions, and includes a customizable
 * close button for user dismissal.
 *
 * @param showCloseButton    - (Optional, defaults to true) Flag to control close button display.
 * @param closeButtonText    - (Optional, defaults to Fechar)Text displayed on the close button.
 * @param setShowActionSheet - Function to control action sheet visibility. Call to show, pass a
 *                             function to hide on callback.
 * @param showActionSheet    - Boolean flag indicating action sheet visibility.
 */

export const ActionSheetRoot = ({
  children,
  closeButtonText,
  setShowActionSheet,
  showActionSheet,
  showCloseButton = true,
}: ActionSheetRootProps): JSX.Element => {
  const closeActionSheet = (): void => {
    setShowActionSheet(false);
  };

  return (
    <CpsModalOverlay onClick={closeActionSheet} show={showActionSheet}>
      <div
        data-testid="action-sheet-modal-test"
        className={cn(
          "fixed bottom-base inset-x-0 z-50 mx-auto my-1 h-fit min-h-[90px] w-full max-w-[632px] items-center rounded-t-lg px-4 transition-all duration-500",
          showActionSheet ? "bottom-0" : "bottom-[-100%] xl:bottom-0",
        )}
        id="detailed-dialog"
      >
        {children}
        {Boolean(showCloseButton) && (
          <ActionSheetCloseButton
            setShowActionSheet={closeActionSheet}
            text={closeButtonText}
          />
        )}
      </div>
    </CpsModalOverlay>
  );
};

export const ActionSheetHeader = ({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div
      {...props}
      className={cn("rounded-lg mb-2 w-full overflow-auto bg-white", className)}
    >
      {children}
    </div>
  );
};

export const ActionSheetHeaderContent = ({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div {...props} className={cn("relative p-4 text-center", className)}>
      {children}
    </div>
  );
};

export const ActionSheetHeaderFooter = ({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div {...props} className={cn("p-4 text-center ", className)}>
      {children}
    </div>
  );
};

export const ActionSheetActionGroup = ({
  children,
  ...props
}: React.ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div {...props} className="rounded-lg mb-2 w-full overflow-auto bg-white">
      <div className="grid grid-cols-1 divide-y">{children}</div>
    </div>
  );
};

interface ActionSheetActionItemProps {
  onClick: () => void;
  text: string;
  variant?: "neutral" | "danger";
}

export const ActionSheetActionItem = ({
  onClick,
  text,
  variant = "neutral",
}: ActionSheetActionItemProps): JSX.Element => {
  return (
    <button className="relative p-[1.5vh] text-center" onClick={onClick} type="button">
      <span
        className={`text-md font-normal leading-6 ${variant === "danger" ? "text-red-400" : "text-neutral-600"}`}
      >
        {text}
      </span>
    </button>
  );
};

interface ActionSheetCloseButtonProps {
  setShowActionSheet: (value: SetStateAction<boolean>) => void;
  text?: string;
}

const ActionSheetCloseButton = ({
  setShowActionSheet,
  text = "Fechar",
}: ActionSheetCloseButtonProps): JSX.Element => {
  return (
    <button
      aria-label="Botão de fechar o seletor"
      className="rounded-40 relative mb-2 w-full bg-white p-4 text-center"
      onClick={() => {
        setShowActionSheet(false);
      }}
      type="button"
    >
      <span className="text-md font-normal leading-6 text-neutral-600">{text}</span>
    </button>
  );
};
