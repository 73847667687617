interface ReservedScheduleTermsOfUseUrlProps {
  reservedScheduleType: number;
  title?: string;
}

const termsOfUseLinks: Record<number, string> = {
  1: "https://ajuda.livance.com.br/pt-BR/articles/8011141-termos-de-uso-periodo-garantido",
  2: "https://ajuda.livance.com.br/pt-BR/articles/8075573-termos-de-uso-periodo-garantido-porto-seguro",
  4: "https://ajuda.livance.com.br/pt-BR/articles/8260193-termos-de-uso-periodo-garantido-parcerias",
};

export const ReservedScheduleTermsOfUseUrl = ({
  reservedScheduleType,
  title = "Ler termos e condições",
}: ReservedScheduleTermsOfUseUrlProps): JSX.Element => {
  return (
    <a
      data-testid="terms-of-use-link"
      href={termsOfUseLinks[reservedScheduleType]}
      target="_blank"
      rel="noopener"
      className="text-[#0080a5] underline"
    >
      <span>{title}</span>
    </a>
  );
};
