import { useEffect } from "react";
import { useRouteContext } from "@tanstack/react-router";
import { trackIdentify } from "@/lib/tracking";

export const useIdentifyUser = (): void => {
  const { user } = useRouteContext({ strict: false });

  useEffect(
    function identifyUser() {
      if (user.codUsuario === 0) {
        return;
      }

      trackIdentify(user);
    },
    [user],
  );
};
