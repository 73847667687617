import { useRouteContext, useSearch } from "@tanstack/react-router";
import { type z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CpsTextArea } from "corpus";
import { graphql } from "@repo/graphql-types/gql";
import { formatIgnoringTimezone } from "@repo/lib";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
} from "@/components/form";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { oneLivFormDisableProfile } from "@/lib/form-schemas/oneliv-form-schema";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql";

const DisableMemberProfileMutation = graphql(`
  mutation DisableMemberProfileMutation(
    $codUsuario: Int!
    $setObject: locus_membro_set_input!
  ) {
    updateMember: update_locus_membro(
      where: { codUsuario: { _eq: $codUsuario } }
      _set: $setObject
    ) {
      affected_rows
    }
  }
`);

interface OneLivDisableProfileDrawerProps {
  setOpen: (open: boolean) => void;
  onDisableSuccess: () => void;
}

export type OneLivFormDisableProfileValues = z.infer<typeof oneLivFormDisableProfile>;

export const OneLivDisableProfileDrawer = ({
  setOpen,
  onDisableSuccess,
}: OneLivDisableProfileDrawerProps): JSX.Element => {
  const { action } = useSearch({ from: "/oneliv/basic-info" });

  const form = useForm<OneLivFormDisableProfileValues>({
    resolver: zodResolver(oneLivFormDisableProfile),
  });

  const { user } = useRouteContext({ strict: false });

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    DisableMemberProfileMutation,
  );

  const handleSubmit = (values: OneLivFormDisableProfileValues): void => {
    const onSuccess = (): void => {
      form.reset({ reason: "" });
      onDisableSuccess();
    };

    void mutateAsync(
      {
        codUsuario: user.codUsuario,
        setObject: {
          ativo: false,
          motivoInativacao: values.reason,
          dataSaida: formatIgnoringTimezone(new Date()),
        },
      },
      { onSuccess },
    );
  };

  return (
    <MessageDrawerRoot
      open={action === "disable-profile"}
      setOpen={setOpen}
      variant="secondary"
    >
      <MessageDrawerTitle>
        <p className="font-medium text-neutral-600 text-xl">
          Gostaríamos de entender o motivo da desativação
        </p>
      </MessageDrawerTitle>
      <FormRoot {...form}>
        <FormHandlerSubmit>
          <FormField
            control={form.control}
            name="reason"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <MessageDrawerBody>
                    <p className="text-base font-normal text-neutral-500">
                      Por favor, escreva o motivo abaixo para nos ajudar a melhorar nossos
                      serviços
                    </p>
                    <CpsTextArea {...field} placeholder="Digite qui" />
                  </MessageDrawerBody>
                </FormControl>
              </FormItem>
            )}
          />
          <MessageDrawerActions>
            <MessageDrawerActionButton
              onClick={() => void form.handleSubmit(handleSubmit)()}
            >
              Desativar perfil
            </MessageDrawerActionButton>
          </MessageDrawerActions>
        </FormHandlerSubmit>
      </FormRoot>
    </MessageDrawerRoot>
  );
};
