"use client";

import { Star, StarFill } from "@repo/icons";
import { forwardRef, useState } from "react";
import {
  CpsSelectorItem,
  CpsSelectorRoot,
  CpsSelectorTitle,
  type CpsSelectorType,
  CpsSingleSelector,
} from "../CpsSelector";

interface CpsRatingStarsProps {
  maxRating: number;
  onRatingSelect: (rating: ValidRatings) => void;
  starFillColor?: "primary" | "secondary";
  title?: string;
}

export type ValidRatings = 0 | 1 | 2 | 3 | 4 | 5;

export const CpsRatingStars = forwardRef<HTMLDivElement, CpsRatingStarsProps>(
  (
    { maxRating, onRatingSelect, starFillColor = "primary", title },
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    const [rating, setRating] = useState<ValidRatings>(0);
    const [hoverRating, setHoverRating] = useState<ValidRatings>(0);

    const handleMouseEnter = (newRating: ValidRatings): void => {
      setHoverRating(newRating);
    };

    const handleMouseLeave = (): void => {
      setHoverRating(0);
    };

    const handleClick = (value: CpsSelectorType): void => {
      const newRating = Number(value) as ValidRatings;
      setRating(newRating);
      onRatingSelect(newRating);
    };

    return (
      <div>
        <CpsSelectorRoot>
          {title ? (
            <CpsSelectorTitle className="text-center">{title}</CpsSelectorTitle>
          ) : null}
          <CpsSingleSelector
            ref={ref}
            onChange={handleClick}
            value={rating}
            className="justify-center"
          >
            {Array.from(
              { length: maxRating },
              (_, index) => (index + 1) as ValidRatings,
            ).map((star) => (
              <CpsSelectorItem
                value={star}
                key={star}
                data-testid={`rating-${star}`}
                onMouseEnter={() => handleMouseEnter(star)}
                onMouseLeave={handleMouseLeave}
                className="bg-transparent w-14 h-14"
              >
                {hoverRating >= star || rating >= star ? (
                  <StarFill size={56} className={`fill-${starFillColor}-400`} />
                ) : (
                  <Star size={56} className="fill-neutral-200" />
                )}
              </CpsSelectorItem>
            ))}
          </CpsSingleSelector>
        </CpsSelectorRoot>
      </div>
    );
  },
);

CpsRatingStars.displayName = "CpsRatingStars";
