import type { FragmentType } from "@repo/graphql-types";
import type { BillingItem as BillingItemFragment } from "@repo/graphql-types/graphql";
import { BillingItem, type BillingItemQueryFragment } from "@/components/billing-item";
import { ListRoot } from "@/components/list/index";

interface BillingItemListProps {
  billingItens: FragmentType<typeof BillingItemQueryFragment>[];
  currentBillingIndex: number;
}

export const BillingItemList = ({
  billingItens,
  currentBillingIndex,
}: BillingItemListProps): JSX.Element => {
  const orderedBillingItens = (): FragmentType<typeof BillingItemQueryFragment>[] => {
    const orderedList = Object.values(billingItens).sort((a, b) => {
      return new Date((a as BillingItemFragment).dataCadastro).getTime() <
        new Date((b as BillingItemFragment).dataCadastro).getTime()
        ? 1
        : -1;
    });

    return orderedList;
  };

  return (
    <ListRoot>
      {Object.values(orderedBillingItens()).map((billingItem) => (
        <div
          className="mt-2"
          key={`billing-list-item-${
            (billingItem as BillingItemFragment).codMovimentacao
          }-${currentBillingIndex}`}
        >
          <BillingItem billingItem={billingItem} />
        </div>
      ))}
    </ListRoot>
  );
};
