import { createFileRoute, redirect } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { OneLivSocialMediaForm } from "@/components/oneliv/oneliv-social-media-form";

export const GetOneLivUserSocialMedia = graphql(`
  query GetOneLivUserSocialMedia {
    ...OneLivSocialMediaFormFragment
  }
`);

const oneLivSocialMediaPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const OneLivSocialMedia = (): JSX.Element => {
  const query = useGraphQL(GetOneLivUserSocialMedia);

  const { data } = query;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Redes sociais" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...query}>
          {data ? <OneLivSocialMediaForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/oneliv/social-media")({
  component: OneLivSocialMedia,
  validateSearch: oneLivSocialMediaPageSearchSchema,
  beforeLoad: ({ context }) => {
    const shouldDisplaySocialMediaPage =
      context.flags["deve-exibir-etapa-de-redes-socias-no-fluxo-do-one-liv-no-app"];

    if (!shouldDisplaySocialMediaPage) {
      redirect({
        to: "/oneliv/additional-info",
        throw: true,
      });
    }
  },
});
