import { PlusCircle } from "@repo/icons";
import { type CpsButtonTextProps, CpsButtonText } from "corpus";
import { forwardRef } from "react";

export const ActionButtonText = forwardRef<HTMLButtonElement, CpsButtonTextProps>(
  ({ children, Icon = PlusCircle, ...props }, ref) => {
    return (
      <CpsButtonText
        ref={ref}
        className="justify-start"
        Icon={Icon}
        color="secondary"
        {...props}
      >
        {children}
      </CpsButtonText>
    );
  },
);

ActionButtonText.displayName = "ActionButtonText";
