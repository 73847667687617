import { graphql } from "@repo/graphql-types/gql";
import { createFileRoute, Link, useRouteContext } from "@tanstack/react-router";
import { z } from "zod";
import { Left } from "@repo/icons";
import { useGraphQL } from "@/hooks/use-graphql";
import { PatientDetailActionList } from "@/components/patient-detail-action-list";
import { PatientDetailPropertyList } from "@/components/patient-detail-property-list";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { RecommendationMembersForPatientsButton } from "@/components/recommendation-members-for-patients-button.tsx";
import { Button } from "@/components/button.tsx";

const PatientDetailPageQuery = graphql(/* GraphQL */ `
  query PatientDetailPageQuery($patientId: Int!) {
    patient: pacientes_by_pk(codPaciente: $patientId) {
      ...PatientDetailActionListFragment
      ...PatientDetailPropertyListFragment
    }
  }
`);

const patientSchema = z.object({
  action: z.string().optional(),
});

export const PatientDetailPage = (): JSX.Element => {
  const { patientId } = Route.useParams();
  const queryResult = useGraphQL(PatientDetailPageQuery, {
    patientId: parseInt(patientId),
  });
  const { data } = queryResult;

  const { ldClient } = useRouteContext({ strict: false });

  const showMembersRecommendationForPatientsButtons =
    ldClient.getShowMembersRecommendationForPatientsButtons();

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Pacientes" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data?.patient ? (
            <div className="flex flex-col h-full justify-between">
              <div>
                <PatientDetailActionList patient={data.patient} />
                <PatientDetailPropertyList patient={data.patient} />
                {showMembersRecommendationForPatientsButtons && patientId ? (
                  <RecommendationMembersForPatientsButton
                    patientId={Number.parseInt(patientId)}
                  />
                ) : null}
              </div>
              <div className="mt-6">
                <Link
                  to="/appointment/create"
                  search={{ patientId: parseInt(patientId) }}
                >
                  <Button fullWidth>Criar novo agendamento</Button>
                </Link>
              </div>
            </div>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/patients/$patientId")({
  component: PatientDetailPage,
  validateSearch: patientSchema,
});
