import { CheckCircle } from "@repo/icons";
import {
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer.tsx";

interface FeedbackDesiredTimeSuccessDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const FeedbackDesiredTimeSuccessDrawer = ({
  open,
  setOpen,
}: FeedbackDesiredTimeSuccessDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot
      open={open}
      setOpen={setOpen}
      icon={CheckCircle}
      variant="secondary"
    >
      <MessageDrawerTitle>Obrigado pelo feedback</MessageDrawerTitle>
      <MessageDrawerBody>
        <p className="mb-6">
          Sua contribuição é essencial para melhorarmos continuamente nossos serviços e
          atendermos melhor às suas necessidades.
        </p>
      </MessageDrawerBody>
    </MessageDrawerRoot>
  );
};
