import { createFileRoute, Link } from "@tanstack/react-router";
import { XCircle } from "@repo/icons";
import { Page } from "@/components/page";
import { HeaderRoot, HeaderTitle } from "@/components/header";
import {
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";

export const AccessBlockedPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Livance" align="center" />
      </HeaderRoot>
      <Page>
        <EmptyStateRoot>
          <EmptyStateIcon fill="danger" icon={XCircle} />
          <EmptyStateTitle>Acesso bloqueado</EmptyStateTitle>
          <EmptyStateDescription>
            Seu acesso ao aplicativo neste momento está bloqueado. Por favor, entre em
            contato com o nosso time de atendimento.
          </EmptyStateDescription>
          {/* TODO: Esse botão tem que ser vermelho quando o CPSButton permitir */}
          <Link to="/auth/logout" preload={false}>
            <EmptyStateButton fullWidth>Sair do aplicativo</EmptyStateButton>
          </Link>
        </EmptyStateRoot>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/access-blocked/")({
  component: AccessBlockedPage,
});
