import { ReceiptMoney } from "@repo/icons";
import { ListRoot } from "@/components/list";
import {
  EmptyStateRoot,
  EmptyStateIcon,
  EmptyStateTitle,
} from "@/components/empty-state";
import { type PeriodPayables } from "@/lib/mappers/payables";
import { PayablesListItem } from "@/components/payables-list-item";

interface PayablesListProps {
  periodPayables: PeriodPayables;
}

export const PayablesList = ({ periodPayables }: PayablesListProps): JSX.Element => {
  const { dayPayables } = periodPayables;

  return (
    <ListRoot>
      {dayPayables.length === 0 ? (
        <div className="flex items-center justify-center">
          <EmptyStateRoot>
            <EmptyStateIcon icon={ReceiptMoney} />
            <EmptyStateTitle>Não há recebimentos no período selecionado</EmptyStateTitle>
          </EmptyStateRoot>
        </div>
      ) : (
        dayPayables.map((payable) => {
          return <PayablesListItem key={payable.date} dayPayables={payable} />;
        })
      )}
    </ListRoot>
  );
};
