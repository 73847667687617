import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { DotsVertical } from "@repo/icons";
import {
  AppointmentTypeModalityEnum,
  convertDateTimeIgnoringTimezone,
  isConsultorioVirtual,
  removeSeconds,
  zonedDate,
} from "@repo/lib";
import { useEffect } from "react";
import {
  Link,
  useLoaderData,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import type { PatientAvatarFragment } from "@/components/patient-avatar";
import { PatientAvatar } from "@/components/patient-avatar";
import {
  ActionSheetActionGroup,
  ActionSheetActionItem,
  ActionSheetHeader,
  ActionSheetHeaderContent,
  ActionSheetHeaderFooter,
  ActionSheetRoot,
} from "@/components/action-sheet";
import { isAppointmentNeedsToBeChargedByCancellationFee } from "@/lib/flexible-pricing";
import { WaitingRoomWarningLatePatient } from "@/components/waiting-room-warning-late-patient";
import { trackEvent } from "@/lib/tracking.ts";

export const WaitingRoomAppointmentActionSheetFragment = graphql(/* GraphQL */ `
  fragment WaitingRoomAppointmentActionSheetFragment on agendamentos {
    codAgendamento
    horaInicio
    horaFim
    pago
    data
    cobraCancelamento
    AgendamentosCheckin {
      codAgendamento
    }
    unidade: Unidade {
      codUnidade
    }
    compromisso: UsuarioCompromisso {
      codUsuarioCompromissoModalidade
    }
    paciente: Paciente {
      codPaciente
      nome
      ...PatientAvatarFragment
    }
    atraso: notificacoesPacientesAtrasados(order_by: { dataCadastro: desc }) {
      tempoAtraso
      dataCadastro
    }
    tags {
      dataCadastro
      codAgendamentoTipoTag
    }
  }
`);

export interface WaitingRoomAppointmentActionSheetProps {
  show: boolean;
  data: FragmentType<typeof WaitingRoomAppointmentActionSheetFragment>;
  cancelAppointment: (isOpenCancelDrawer: boolean) => void;
  closeDialog: () => void;
}

export const WaitingRoomAppointmentActionSheet = ({
  show,
  data,
  cancelAppointment,
  closeDialog,
}: WaitingRoomAppointmentActionSheetProps): JSX.Element => {
  const appointment = useFragment(WaitingRoomAppointmentActionSheetFragment, data);

  const { ldClient, flags } = useRouteContext({
    strict: false,
  });

  const {
    showMembersRecommendationForPatientsButtons,
  }: { showMembersRecommendationForPatientsButtons: boolean } = useLoaderData({
    strict: false,
  });

  const router = useRouter();
  const searchParams = useSearch({
    from: "/waiting-room/",
  });

  const isCreateOnlinePaymentEnabled = flags["ativa-fluxo-de-criar-pagamento-online"];
  const isFollowUpAppointmentEnabled = flags["exibe-projeto-prm"];

  useEffect(() => {
    const overflowClass = "overflow-y-hidden";
    show
      ? document.documentElement.classList.add(overflowClass)
      : document.documentElement.classList.remove(overflowClass);
  }, [show]);

  const {
    codAgendamento,
    paciente,
    unidade,
    pago,
    compromisso,
    horaInicio,
    horaFim,
    data: appointmentDate,
    AgendamentosCheckin,
    cobraCancelamento,
    atraso,
  } = appointment;

  const isAppointmentInThePastOrPresent = (): boolean => {
    const currentDate = zonedDate(new Date());

    const appointmentHoraInicio = convertDateTimeIgnoringTimezone(
      appointmentDate,
      horaInicio,
    );

    return currentDate.getTime() > appointmentHoraInicio.getTime();
  };

  const shouldDisplayChangeAppointmentOptions = !isAppointmentInThePastOrPresent();

  const isTeleconsulta =
    compromisso?.codUsuarioCompromissoModalidade ===
    AppointmentTypeModalityEnum.Teleconsulta;

  const isAppointmentInConsultorioVirtual = isConsultorioVirtual(unidade.codUnidade);

  const isAppointmentTreated = appointment.tags.length > 0;
  const {
    showTreatedAppointmentInWaitingRoom,
  }: { showTreatedAppointmentInWaitingRoom: boolean } = useLoaderData({
    strict: false,
  });

  const handleDrawerChange = (open: boolean, action: string): void => {
    closeDialog();
    if (open && searchParams.action !== action) {
      void router.navigate({
        search: { ...searchParams, action, appointmentId: codAgendamento },
      });
    } else if (!open && searchParams.action === action) {
      router.history.back();
    }
  };

  const handleTreatedAppointmentDrawer = (open: boolean): void => {
    handleDrawerChange(open, "treated-appointment-drawer");
  };

  const handleUntreatedAppointmentDrawer = (open: boolean): void => {
    handleDrawerChange(open, "untreated-appointment-drawer");
  };

  const handleAppointmentCancel = (): void => {
    trackEvent("Cancelar agendamento selecionado", {
      agendamento: codAgendamento,
    });
    cancelAppointment(true);
  };

  type LinkMapType = Record<
    string,
    {
      to: string;
      params?: Record<string, string>;
      search?: { appointmentId?: number; patientId?: number };
    }
  >;

  const linkMap: LinkMapType = {
    "patient-contact": {
      to: "/patients/$patientId",
      params: { patientId: String(paciente?.codPaciente) },
    },
    "receive-room-now": {
      to: "/waiting-room/$codAgendamento/receive-room-now",
      params: { codAgendamento: String(codAgendamento) },
    },
    "send-payment": {
      to: isCreateOnlinePaymentEnabled
        ? "/waiting-room/$codAgendamento/choose-payment-channel/"
        : "/waiting-room/$codAgendamento/send-totem-payment",
      params: { codAgendamento: String(codAgendamento) },
    },
    "reschedule-appointment": {
      to: "/appointment/$codAgendamento/reschedule",
      params: { codAgendamento: String(codAgendamento) },
    },
    "change-time": {
      to: "/waiting-room/$codAgendamento/change-time",
      params: { codAgendamento: String(codAgendamento) },
    },
    "/appointment/$appointmentId/patient-swap": {
      to: "/appointment/$appointmentId/patient-swap-search",
      params: { appointmentId: String(codAgendamento) },
    },
    "/appointment/create": {
      to: "/appointment/create/",
      search: {
        appointmentId: codAgendamento,
        patientId: paciente?.codPaciente,
      },
    },
    recommendation: {
      to: "/recommendation/",
      search: {
        patientId: paciente?.codPaciente,
      },
    },
    "follow-up-appointment": {
      to: "/waiting-room/follow-up-appointment-reminder/$appointmentId",
      params: { appointmentId: String(codAgendamento) },
    },
  };

  const navigateToSelectedOption = (key: string): void => {
    void router.navigate(linkMap[key]);
  };

  const handleRescheduleAction = (): void => {
    const shouldDisplayRescheduleAlertDrawer =
      cobraCancelamento &&
      isAppointmentNeedsToBeChargedByCancellationFee(ldClient, appointmentDate);

    if (shouldDisplayRescheduleAlertDrawer) {
      closeDialog();
      void router.navigate({
        from: "/waiting-room/",
        search: {
          action: "reschedule-flexible-price-appointment",
          appointmentId: codAgendamento,
        },
      });
    } else {
      navigateToSelectedOption("reschedule-appointment");
    }
  };

  const handleCancellationAction = (): void => {
    const shouldDisplayCancellationAlertDrawer =
      cobraCancelamento &&
      isAppointmentNeedsToBeChargedByCancellationFee(ldClient, appointmentDate);

    if (shouldDisplayCancellationAlertDrawer) {
      closeDialog();
      void router.navigate({
        from: "/waiting-room/",
        search: {
          action: "cancel-flexible-price-appointment",
          appointmentId: codAgendamento,
        },
      });
    } else {
      handleAppointmentCancel();
    }
  };

  const isModalOverlayOpen: boolean = searchParams.action === "expand-avatar-image";
  const handleOpenModalOverlay = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/waiting-room/",
        search: { ...searchParams, action: "expand-avatar-image" },
      });
    } else if (isModalOverlayOpen) {
      router.history.back();
    }
  };

  const buildTreatedAppointment = (): JSX.Element => {
    if (!isAppointmentTreated) {
      return (
        <ActionSheetActionItem
          onClick={() => handleTreatedAppointmentDrawer(true)}
          text="Marcar como atendido"
        />
      );
    }
    return (
      <ActionSheetActionItem
        onClick={() => handleUntreatedAppointmentDrawer(true)}
        text="Marcar como não atendido"
      />
    );
  };
  const buildMainContent = (): JSX.Element => {
    const shouldDisplaySwapPatientOption = !pago && !AgendamentosCheckin;

    if (shouldDisplayChangeAppointmentOptions) {
      return (
        <>
          <ActionSheetActionItem
            onClick={() => navigateToSelectedOption("change-time")}
            text="Alterar horário"
          />
          {!isAppointmentInConsultorioVirtual && (
            <ActionSheetActionItem
              onClick={() => navigateToSelectedOption("receive-room-now")}
              text="Receber sala agora"
            />
          )}
          <ActionSheetActionItem
            onClick={() => navigateToSelectedOption("/appointment/create")}
            text="Novo agendamento"
          />
          <ActionSheetActionItem
            onClick={() => handleRescheduleAction()}
            text="Reagendar"
          />
          {shouldDisplaySwapPatientOption ? (
            <ActionSheetActionItem
              onClick={() =>
                navigateToSelectedOption("/appointment/$appointmentId/patient-swap")
              }
              text="Trocar paciente"
            />
          ) : null}
          {showMembersRecommendationForPatientsButtons ? (
            <ActionSheetActionItem
              onClick={() => navigateToSelectedOption("recommendation")}
              text="Indicar profissional"
            />
          ) : null}
        </>
      );
    }
    return (
      <>
        <ActionSheetActionItem
          onClick={() => navigateToSelectedOption("/appointment/create")}
          text="Novo agendamento"
        />
        {showMembersRecommendationForPatientsButtons ? (
          <ActionSheetActionItem
            onClick={() => navigateToSelectedOption("recommendation")}
            text="Indicar profissional"
          />
        ) : null}
      </>
    );
  };

  const buildPaymentGroup = (): JSX.Element | null => {
    if (isAppointmentInConsultorioVirtual || isTeleconsulta) return null;

    if (!pago) {
      return (
        <ActionSheetActionGroup>
          <ActionSheetActionItem
            onClick={() => navigateToSelectedOption("send-payment")}
            text="Enviar pagamento"
          />
        </ActionSheetActionGroup>
      );
    }

    return null;
  };

  return (
    <ActionSheetRoot showActionSheet={show} setShowActionSheet={closeDialog}>
      <ActionSheetHeader className="cursor-pointer">
        <ActionSheetHeaderContent>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row text-left">
              <PatientAvatar
                shouldOpenModalOverlay
                isModalOverlayOpen={isModalOverlayOpen}
                setIsModalOverlayOpen={() => handleOpenModalOverlay(!isModalOverlayOpen)}
                color="primaryLight"
                data={paciente as FragmentType<typeof PatientAvatarFragment>}
                size={40}
              />
              <Link
                to="/patients/$patientId"
                params={{ patientId: String(paciente?.codPaciente) }}
                className="flex px-2 flex-col"
              >
                <p className="text-sm font-medium text-neutral-600">{paciente?.nome}</p>
                <p className="text-sm text-neutral-500">
                  {removeSeconds(horaInicio)} - {removeSeconds(horaFim)}
                </p>
              </Link>
            </div>
            <Link
              to="/patients/$patientId"
              params={{ patientId: String(paciente?.codPaciente) }}
            >
              <DotsVertical size={24} className="fill-neutral-600" />
            </Link>
          </div>
        </ActionSheetHeaderContent>
        {atraso.length > 0 && (
          <ActionSheetHeaderFooter className="p-0">
            <WaitingRoomWarningLatePatient lateTime={atraso[0].tempoAtraso} />
          </ActionSheetHeaderFooter>
        )}
      </ActionSheetHeader>
      {buildPaymentGroup()}
      {showTreatedAppointmentInWaitingRoom ? (
        <ActionSheetActionGroup data-testid="waiting-room-assist-patient-test-id">
          {buildTreatedAppointment()}
        </ActionSheetActionGroup>
      ) : null}
      <ActionSheetActionGroup data-testid="waiting-room-main-content-test-id">
        {isFollowUpAppointmentEnabled ? (
          <ActionSheetActionItem
            onClick={() => navigateToSelectedOption("follow-up-appointment")}
            text="Enviar lembrete"
          />
        ) : null}
        {buildMainContent()}
      </ActionSheetActionGroup>
      {shouldDisplayChangeAppointmentOptions ? (
        <ActionSheetActionGroup>
          <ActionSheetActionItem
            onClick={() => handleCancellationAction()}
            text="Cancelar agendamento"
            variant="danger"
          />
        </ActionSheetActionGroup>
      ) : null}
    </ActionSheetRoot>
  );
};
