export function generateContactUrl(
  data: string,
  type: "phone" | "email" | "whatsapp",
): string {
  const contactsUrls = {
    phone: `tel:${data}`,
    email: `mailto:${data}`,
    whatsapp: `https://api.whatsapp.com/send?phone=${cleanAndPrefixPhoneNumber(data)}`,
  };

  return contactsUrls[type];
}

function cleanAndPrefixPhoneNumber(phoneNumber: string): string {
  const cleanPhoneNumber = phoneNumber.replace(/[()\s-]/g, "");
  return `55${cleanPhoneNumber}`;
}
