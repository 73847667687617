import { Left } from "@repo/icons";
import { ReservedScheduleTypeEnum } from "@repo/lib";
import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { useAtom } from "jotai";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { reservedScheduleFormAtom } from "@/lib/atoms/reserved-schedule-form-atom";
import { ReservedScheduleSlots } from "@/components/reserved-schedule-slots";
import { SlotsFallback } from "@/components/reserved-schedule-slot-fallback";

const GetReservedSchedulesSlotsQuery = graphql(/* GraphQL */ `
  query GetReservedSchedulesSlotsQuery(
    $duracao: String!
    $diaDaSemana: Int!
    $codUsuario: Int!
    $codUnidade: Int!
    $codTipoSala: Int!
    $codModalidadePeriodoGarantido: Int!
    $codPeriodoGarantidoRecorrenteFrequencia: Int!
  ) {
    ...ReservedSchedulesSlotsFragment
  }
`);

export const ReservedSchedulesSlotsPage = (): JSX.Element => {
  const [reservedScheduleFormAtomValue] = useAtom(reservedScheduleFormAtom);
  const { user } = Route.useRouteContext();
  const routerContext = useLoaderData({
    from: "/settings/reserved-schedules/create/slots",
  });
  const queryResult = useGraphQL(GetReservedSchedulesSlotsQuery, {
    duracao: reservedScheduleFormAtomValue.duration,
    diaDaSemana: reservedScheduleFormAtomValue.dayOfWeek,
    codUsuario: user.codUsuario,
    codUnidade: reservedScheduleFormAtomValue.location,
    codTipoSala: reservedScheduleFormAtomValue.roomType,
    codModalidadePeriodoGarantido: routerContext.reservedScheduleType,
    codPeriodoGarantidoRecorrenteFrequencia: reservedScheduleFormAtomValue.frequency,
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Novo período garantido" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper fallback={<SlotsFallback />} {...queryResult}>
          {data ? <ReservedScheduleSlots slotsList={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/reserved-schedules/create/slots")({
  component: ReservedSchedulesSlotsPage,
  beforeLoad: ({ context }) => {
    return {
      reservedScheduleTypeByUser: context.ldClient.getReservedScheduleTypeByUser(),
    };
  },
  loader: ({ context }) => {
    let reservedScheduleType: number = context.reservedScheduleTypeByUser;
    if (
      context.user.codParceiroPortoSeguro &&
      reservedScheduleType !== ReservedScheduleTypeEnum.Porto.valueOf()
    ) {
      reservedScheduleType = ReservedScheduleTypeEnum.Porto.valueOf();
    }
    return { reservedScheduleType };
  },
});
