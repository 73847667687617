import { createFileRoute, redirect } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { type DocumentType, graphql } from "@repo/graphql-types";
import { z } from "zod";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { queryFn, useGraphQL } from "@/hooks/use-graphql";
import { BankAccountForm } from "@/components/bank-account-form";
import { PendingComponent } from "@/components/pending-component";

const BankAccountUpdatePageQuery = graphql(/* GraphQL */ `
  query BankAccountUpdatePageQuery($codUsuario: Int!, $codClinica: Int! = 0) {
    ExpensumGetRecipient(arg1: { codUsuario: $codUsuario, codClinica: $codClinica }) {
      recipient {
        bankAccounts {
          pagarmeId
        }
      }
    }
    ...BankAccountFormQueryFragment
  }
`);

const bankAccountUpdatePageSearchParamsSchema = z.object({
  action: z.string().optional(),
});

export const BankAccountUpdatePage = (): JSX.Element => {
  const { user } = Route.useRouteContext();

  const queryResult = useGraphQL(BankAccountUpdatePageQuery, {
    codUsuario: user.codUsuario,
    codClinica: user.codClinica,
  });
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Conta para recebimento" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <BankAccountForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/bank-account/update")({
  component: BankAccountUpdatePage,
  pendingComponent: () => <PendingComponent title="Conta para recebimento" />,
  loader: async (opts) => {
    try {
      const variables = {
        codUsuario: opts.context.user.codUsuario,
        codClinica: opts.context.user.codClinica,
      };

      const data = await opts.context.queryClient.ensureQueryData<
        DocumentType<typeof BankAccountUpdatePageQuery>
      >({
        queryKey: ["BankAccountUpdatePageQuery", variables],
        queryFn: () =>
          queryFn(BankAccountUpdatePageQuery, opts.context.authClient, variables),
      });

      const bankAccountHasFound = Boolean(
        data.ExpensumGetRecipient?.recipient?.bankAccounts?.at(0)?.pagarmeId,
      );

      if (!bankAccountHasFound) throw new Error("Bank account has not found!");
    } catch (e) {
      redirect({
        to: "/settings/payments",
        throw: true,
      });
    }
  },
  validateSearch: (search) => bankAccountUpdatePageSearchParamsSchema.parse(search),
});
