import { useEffect, type HTMLAttributes } from "react";

export interface ModalAvatarOverlayProps extends HTMLAttributes<HTMLDivElement> {
  show: boolean;
  src: string | undefined;
  alt: string;
  setShow: (value: boolean, event?: React.MouseEvent | React.KeyboardEvent) => void;
}

export const ModalAvatarOverlay = ({
  src,
  alt,
  show,
  setShow,
}: ModalAvatarOverlayProps): JSX.Element => {
  useEffect(() => {
    const overflowClass = "overflow-y-hidden";
    show
      ? document.documentElement.classList.add(overflowClass)
      : document.documentElement.classList.remove(overflowClass);
  }, [show]);

  const isMobile = window.innerWidth < 1024;

  return (
    <div
      className={`fixed inset-0 w-screen h-screen z-[100] bg-black/60 ${show ? "flex justify-center items-center" : "hidden"}`}
      role="button"
      tabIndex={-1}
      onClick={(e) => setShow(false, e)}
      onKeyDown={(e) => setShow(false, e)}
    >
      <div id="modal" tabIndex={-1} className="fixed z-[90] transition-all duration-500">
        <div className="rounded-lg shadow">
          <div className={isMobile ? "block" : "hidden"}>
            <img
              src={src}
              alt={alt}
              className="h-[328px] object-cover object-top rounded-lg mx-auto my-0"
            />
          </div>
          <div className={!isMobile ? "block" : "hidden"}>
            <img
              src={src}
              alt={alt}
              className="h-[600px] object-cover object-top rounded-lg mx-auto my-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
