import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { getMinutesFromDateTime, formatCurrency } from "@repo/lib";
import { ItemContent, Item, ItemMainContent } from "@/components/list";

export const AppointmentTypeListItemQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentTypeListItemQueryFragment on usuariosCompromissos {
    nome
    duracao
    valor
    codUsuarioCompromisso
    tbUsuariosCompromissosFormasRecebimentos(
      where: { ativo: { _eq: true }, UsuarioFormaRecebimento: { ativo: { _eq: true } } }
    ) {
      UsuarioFormaRecebimento {
        TipoFormaRecebimento {
          nome
        }
      }
    }
  }
`);

export const AppointmentTypeListItem = (props: {
  appointmentType: FragmentType<typeof AppointmentTypeListItemQueryFragment>;
}): JSX.Element => {
  const appointmentType = useFragment(
    AppointmentTypeListItemQueryFragment,
    props.appointmentType,
  );

  return (
    <Item
      linkProps={{
        to: "/settings/schedule-options/appointment-types/$appointmentTypeId",
        params: { appointmentTypeId: Number(appointmentType.codUsuarioCompromisso) },
      }}
    >
      <ItemContent>
        <p className="text-sm">{appointmentType.nome}</p>
        <ItemMainContent>{formatCurrency(appointmentType.valor)}</ItemMainContent>
        <p className="text-sm">
          {appointmentType.tbUsuariosCompromissosFormasRecebimentos
            .map(
              (appointmentTypeReceiptPayment) =>
                appointmentTypeReceiptPayment.UsuarioFormaRecebimento.TipoFormaRecebimento
                  .nome,
            )
            .join(" | ")}{" "}
          - {`${getMinutesFromDateTime(String(appointmentType.duracao))} min`}
        </p>
      </ItemContent>
    </Item>
  );
};
