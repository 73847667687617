import { useRouteContext } from "@tanstack/react-router";
import { User } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import {
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";

export const RecipientEmptyStateWrapperQuery = graphql(`
  query RecipientEmptyStateWrapperQuery($codUsuario: Int!, $codClinica: Int) {
    expensumRecipient: ExpensumGetRecipient(
      arg1: { codUsuario: $codUsuario, codClinica: $codClinica }
    ) {
      recipient {
        pagarmeId
      }
    }
  }
`);

interface RecipientEmptyStateWrapperProps {
  children: React.ReactNode;
}

export const RecipientEmptyStateWrapper = ({
  children,
}: RecipientEmptyStateWrapperProps): JSX.Element => {
  const { user } = useRouteContext({ strict: false });

  const queryData = useGraphQL(RecipientEmptyStateWrapperQuery, {
    codUsuario: Number(user.codUsuario),
    codClinica: Number(user.codClinica),
  });

  const { data } = queryData;

  const hasRecipient = Boolean(data?.expensumRecipient?.recipient?.pagarmeId);
  return (
    <AsyncDataWrapper {...queryData}>
      {hasRecipient ? (
        children
      ) : (
        <EmptyStateRoot>
          <EmptyStateIcon icon={User} />
          <EmptyStateTitle>
            Você ainda não se cadastrou para receber pagamentos
          </EmptyStateTitle>
          <EmptyStateDescription>
            Consulte o nosso time de relacionamento e solicite o cadastro para gerenciar
            seus pagamentos.
          </EmptyStateDescription>
        </EmptyStateRoot>
      )}
    </AsyncDataWrapper>
  );
};
