import { type Info, InfoItem } from "@/components/list/item";
import { ListRoot } from "@/components/list/list-root";

interface InformationalListProps {
  title?: string;
  infos: Info[];
}

export const InformationalList = ({
  title,
  infos,
}: InformationalListProps): JSX.Element => {
  return (
    <div className="justify-center items-center gap gap-x-6">
      {title ? <p className="font-medium">{title}</p> : null}

      <ListRoot>
        {infos.map((info: Info) => (
          <InfoItem key={`${info.label}-${info.value}`} itemInfo={info} />
        ))}
      </ListRoot>
    </div>
  );
};
