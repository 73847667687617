import { useFragment, type FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Right } from "@repo/icons";
import { formatCurrency, AppointmentTypeCategoryEnum, cn } from "@repo/lib";
import { Link } from "@tanstack/react-router";
import { CpsBadge } from "corpus";
import { BillingItemDiscount } from "@/components/billing-item-discount";
import { HorarioMenorBagde } from "@/components/horario-menor-badge";
import { useGraphQL } from "@/hooks/use-graphql";

export const BillingItemQueryFragment = graphql(/* GraphQL */ `
  fragment BillingListItemQueryFragment on BillingItem {
    codCobranca
    codMovimentacao
    codOrigem
    codUsuario
    dataCadastro
    quantidade
    tempoUnitario
    tipoOrigem
    valor
    valorDesconto
    valorUnitario
    unidade {
      codUnidade
      nome
    }
    tipoMovimentacao {
      codTipoMovimentacao
      nome
      tipoOperacao
    }
  }
`);

const BillingItemQuery = graphql(/* GraphQL */ `
  query BillingItemQuery($codAgendamento: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      nome
      cobraCancelamento
      codFinalidadeAgendamento
      data
      horaInicio
      horaFim
    }
    agendamentosAlocacoes(where: { codAgendamento: { _eq: $codAgendamento } }) {
      duracaoEmMinutos
    }
  }
`);

export const BillingItem = (props: {
  billingItem: FragmentType<typeof BillingItemQueryFragment>;
}): JSX.Element => {
  const billingItem = useFragment(BillingItemQueryFragment, props.billingItem);

  const isAppointment = billingItem.tipoOrigem === "agendamento";
  const queryResult = useGraphQL(
    BillingItemQuery,
    {
      codAgendamento: Number(billingItem.codOrigem),
    },
    { enabled: isAppointment, staleTime: Infinity },
  );

  const { data: appointmentItem } = queryResult;

  const totalValue = (): string => {
    let billingItemValue = billingItem.valor;

    if (billingItem.valorDesconto) {
      billingItemValue = billingItem.valor + billingItem.valorDesconto;
    }

    return formatCurrency(billingItemValue);
  };

  const flexiblePrice = appointmentItem?.agendamento?.cobraCancelamento;
  const durationLimit =
    appointmentItem?.agendamento?.codFinalidadeAgendamento ===
    Number(AppointmentTypeCategoryEnum.PeriodoGarantido);

  return (
    <div className="py-3">
      <Link
        aria-hidden="true"
        className={cn(
          "flex flex-row items-center justify-between",
          isAppointment ? "cursor-pointer" : "cursor-default",
        )}
        to={isAppointment ? "/settings/billing/details" : undefined}
        search={
          isAppointment
            ? {
                billingItem,
                appointment: appointmentItem?.agendamento,
                allocation: appointmentItem?.agendamentosAlocacoes,
              }
            : undefined
        }
      >
        <div>
          <p className="font-normal text-neutral-500 text-base">
            {billingItem.tipoMovimentacao.nome}
          </p>
          <div className="flex flex-row justify-between mt-1 items-center">
            <div className="mr-2">
              <BillingItemDiscount
                billingItemValue={billingItem.valor}
                discountValue={billingItem.valorDesconto}
              />
              <p className="font-medium text-lg">{totalValue()}</p>
            </div>
            <HorarioMenorBagde originType={billingItem.tipoOrigem} />
          </div>
        </div>
        <div className="flex flex-row items-center">
          {flexiblePrice ? <CpsBadge color="primary">Preço flexível</CpsBadge> : null}
          {durationLimit ? <CpsBadge color="helper">Limite de duração</CpsBadge> : null}

          {isAppointment ? <Right size={16} className="fill-neutral-700" /> : null}
        </div>
      </Link>
    </div>
  );
};
