import { forwardRef } from "react";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

export const OneLivProfessionalCouncilSelectInputFragment = graphql(/* GraphQL */ `
  fragment OneLivProfessionalCouncilSelectInputFragment on query_root {
    professionalCouncils: locus_conselho_profissional(where: { ativo: { _eq: true } }) {
      id
      nome
    }
  }
`);

export interface OneLivProfessionalCouncilSelectInputProps
  extends Omit<SelectInputProps<number>, "items" | "title"> {
  data?: FragmentType<typeof OneLivProfessionalCouncilSelectInputFragment> | null;
}

export const OneLivProfessionalCouncilSelectInput = forwardRef<
  HTMLInputElement,
  OneLivProfessionalCouncilSelectInputProps
>(
  (
    { data, ...props }: OneLivProfessionalCouncilSelectInputProps,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const fragmentResult = useFragment(
      OneLivProfessionalCouncilSelectInputFragment,
      data,
    );

    const activeProfessionalCouncils = fragmentResult?.professionalCouncils ?? [];

    return (
      <SelectInput<number>
        ref={ref}
        {...props}
        title="Conselho Profissional"
        items={activeProfessionalCouncils.map((x) => ({
          value: x.id,
          label: x.nome,
        }))}
      />
    );
  },
);

OneLivProfessionalCouncilSelectInput.displayName = "OneLivProfessionalCouncilSelectInput";
