interface MainElementProps extends React.ComponentPropsWithoutRef<"main"> {
  children: React.ReactNode;
}

/**
 * Main element component that renders the main element of a page - the dominant content of the <body> of a document.
 * Note that only one main element is permitted in a HTML document.
 *
 * @param children - Page component children.
 * @returns JSX.Element - Rendered Page component.
 */
export const MainElement = (props: MainElementProps): JSX.Element => {
  return (
    <main
      className="font-poppins flex flex-col h-screen-with-fallback overflow-hidden"
      {...props}
    >
      {props.children}
    </main>
  );
};
