import { atomWithReset } from "jotai/utils";
import { type z } from "zod";
import { getCurrentDate } from "@repo/lib";
import { TimeSlotSuggestionType } from "@repo/lib/src/enums";
import { type appointmentFormSchema } from "@/lib/form-schemas/appointment-schema";

type AppointmentForm = z.infer<typeof appointmentFormSchema>;

const appointmentFormAtom = atomWithReset<AppointmentForm>({
  unit: 0,
  appointmentType: 0,
  paymentMethod: 0,
  appointmentTime: {
    start: getCurrentDate(),
    end: getCurrentDate(),
    timeSlotType: TimeSlotSuggestionType.Original,
  },
  phone: "",
  name: "",
  email: "",
  sendConfirmationViaWhatsApp: true,
  roomTypes: [],
});

const isBasicInfoStepConcluded = (form: AppointmentForm): boolean => {
  return form.unit !== 0 && form.appointmentType !== 0 && form.paymentMethod !== 0;
};

const isPatientStepConcluded = (form: AppointmentForm): boolean => {
  return isBasicInfoStepConcluded(form) && form.name !== "";
};

export { appointmentFormAtom, isBasicInfoStepConcluded, isPatientStepConcluded };
