import { useFragment, type FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Link } from "@tanstack/react-router";
import { Button } from "@/components/button";
import { InformationalList } from "@/components/list/informational-list";
import { AutomaticAnticipationToggle } from "@/components/automatic-anticipation-toggle";
import { formatRecipientDocument } from "@/lib/string";

export const BankAccountDetailsQueryFragment = graphql(/* GraphQL */ `
  fragment BankAccountDetailsQueryFragment on query_root {
    ExpensumGetRecipient(arg1: { codUsuario: $codUsuario, codClinica: $codClinica }) {
      recipient {
        document
        type
        bankAccounts {
          name
          branchNumber
          branchCheckDigit
          accountNumber
          accountCheckDigit
          type
        }
      }
    }
    ...AutomaticAnticipationToggleQueryFragment
  }
`);

interface BankAccountDetailsProps {
  data: FragmentType<typeof BankAccountDetailsQueryFragment>;
}

export const BankAccountDetails = ({ data }: BankAccountDetailsProps): JSX.Element => {
  const recipientData = useFragment(BankAccountDetailsQueryFragment, data);
  const recipient = recipientData.ExpensumGetRecipient?.recipient;
  const bankAccount = recipient?.bankAccounts?.at(0);

  const getBranchCheckDigitIfExists = bankAccount?.branchCheckDigit
    ? `-${bankAccount.branchCheckDigit}`
    : "";

  const infos = [
    { label: "Instituição", value: `${bankAccount?.name}` },
    {
      label: "Agência",
      value: `${bankAccount?.branchNumber}${getBranchCheckDigitIfExists}`,
    },
    {
      label: "Conta",
      value: `${bankAccount?.accountNumber}-${bankAccount?.accountCheckDigit}`,
    },
    {
      label: "Tipo de conta",
      value: bankAccount?.type === "checking" ? "Conta Corrente" : "Conta Poupança",
    },
    {
      label: recipient?.type === "company" ? "CNPJ" : "CPF",
      value: formatRecipientDocument(
        String(recipient?.type),
        String(recipient?.document),
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-5">
      <h1 className="text-xl text-neutral-600 font-medium w-full">
        Seus pagamentos via totem serão recebidos na conta:
      </h1>
      <InformationalList infos={infos} />
      <Link to="/settings/payments/bank-account/update">
        <Button fullWidth>Trocar conta</Button>
      </Link>
      <AutomaticAnticipationToggle data={recipientData} />
    </div>
  );
};
