export enum BillingItemOriginTypeEnum {
  Agendamento = "agendamento",
  Avulso = "avulso",
  Mensalidade = "mensalidade",
  Recorrente = "recorrente",
  Desativacao = "desativacao",
  DescontoProgressivo = "descontoProgressivo",
  DescontoAgendamento = "descontoAgendamento",
  PeriodoGarantido = "periodoGarantido",
  PrecificacaoVariavel = "precificacaoVariavel",
  HorariosMenores = "horariosMenores",
  AtrasoUtilizacaoDeSala = "atrasoUtilizacaoDeSala",
  HorarioEstendido = "horarioEstendido",
}
