import type { ChangeEvent, ReactNode } from "react";
import { CpsInput } from "corpus";
import { Search } from "@repo/icons";

interface SearchInputWithMinLengthProps {
  id?: string;
  title?: string;
  onSearch: (value: string) => void;
  placeholder?: string;
  searchLengthMin?: number;
}

export const SearchInputWithMinLength = ({
  id,
  title = "Buscar",
  onSearch,
  placeholder = "Buscar",
  searchLengthMin = 3,
}: SearchInputWithMinLengthProps): ReactNode => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (value.length >= searchLengthMin) {
      onSearch(value);
    } else {
      onSearch("");
    }
  };

  return (
    <CpsInput
      id={id ?? "search-input-id"}
      title={title}
      IconLeft={Search}
      onChange={handleInputChange}
      placeholder={placeholder}
    />
  );
};
