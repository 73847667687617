import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { type z } from "zod";
import {
  useNavigate,
  useParams,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { useResetAtom } from "jotai/utils";
import { useAtom } from "jotai";
import {
  AppointmentMode,
  AppointmentPurpose,
  TimeSlotSuggestionType,
  formatIgnoringTimezone,
} from "@repo/lib";
import { type RescheduleAppointmentApiMutation as RescheduleAppointmentApiMutationType } from "@repo/graphql-types/graphql";
import { type flexiblePricingInfo } from "@/lib/form-schemas/appointment-schema";
import { AppointmentFlexiblePriceRadioSelect } from "@/components/appointment-flexible-price-radio-select";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { CreatedAppointmentDrawer } from "@/components/created-appointment-drawer";
import { AppointmentRepeatedPatientErrorDrawer } from "@/components/appointment-repeated-patient-error-drawer.tsx";
import { isRepeatedPatientError } from "@/lib/repeated-patient-error.ts";
import { trackEvent } from "@/lib/tracking";

export const RescheduleAppointmentFlexiblePriceFormFragment = graphql(/* GraphQL */ `
  fragment RescheduleAppointmentFlexiblePriceFormFragment on query_root {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      nomePlano
      numeroCarteirinha
      validadeCarteirinha
      confirmado
      cancelado
      ignorarEnvioEmail
      ignorarEnvioWhatsApp
      Paciente {
        cpfProprio
        codPaciente
      }
    }

    unidadesPrecosFlexiveis: UnidadesPrecosFlexiveis(
      where: {
        ativo: { _eq: true }
        codUnidade: { _eq: $codUnidade }
        codTipoSala: { _in: $codTipoSala }
      }
    ) {
      taxaDesconto
    }
    periodosGarantidos: tbPeriodosGarantidos(
      where: {
        ativo: { _eq: true }
        Agendamento: {
          cancelado: { _eq: false }
          codUnidade: { _eq: $codUnidade }
          codUsuario: { _eq: $codUsuario }
          data: { _eq: $data }
          horaInicio: { _lte: $horaInicio }
          horaFim: { _gt: $horaInicio }
          UsuarioCompromisso: {
            UsuariosCompromissosTiposSalas: { codTipoSala: { _in: $codTipoSala } }
          }
        }
      }
    ) {
      codPeriodoGarantido
    }
  }
`);

const RescheduleAppointmentApiMutation = graphql(/* GraphQL */ `
  mutation RescheduleAppointmentApi($input: RemarcarAgendamentoInput!) {
    remarcarAgendamento(input: $input) {
      data: agendamentoApiOutput {
        codAgendamento
      }
      errors {
        ... on ValidationError {
          __typename
          message
        }
        ... on RepeatedPatientError {
          __typename
          message
        }
      }
    }
  }
`);

interface RescheduleAppointmentFlexiblePriceFormProps {
  data: FragmentType<typeof RescheduleAppointmentFlexiblePriceFormFragment>;
}
export const RescheduleAppointmentFlexiblePriceForm = ({
  data,
}: RescheduleAppointmentFlexiblePriceFormProps): JSX.Element => {
  const fragmentResult = useFragment(
    RescheduleAppointmentFlexiblePriceFormFragment,
    data,
  );

  const [{ ...atomValues }] = useAtom(appointmentFormAtom);

  const resetRescheduleAppointmentForm = useResetAtom(appointmentFormAtom);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    RescheduleAppointmentApiMutation,
    {
      retry: 2,
    },
  );

  const { user } = useRouteContext({ strict: false });

  const router = useRouter();

  const { appointmentId } = useParams({
    from: "/appointment/$appointmentId/flexible-pricing",
  });

  const navigate = useNavigate();

  const searchParams = useSearch({
    from: "/appointment/$appointmentId/flexible-pricing",
  });

  const { periodosGarantidos, unidadesPrecosFlexiveis, agendamento } = fragmentResult;

  const isReservedSchedule = periodosGarantidos.length > 0;

  const navigateWithSearch = (
    action: "success" | "error" | "error-repeated-patient",
  ): void => {
    void navigate({
      to: "/appointment/$appointmentId/flexible-pricing",
      params: { appointmentId },
      search: {
        action,
      },
    });
  };

  const onSubmit = async (
    formData: z.infer<typeof flexiblePricingInfo>,
  ): Promise<void> => {
    const onError = (error?: Error): void => {
      const isRepeatedPatient = error ? isRepeatedPatientError(error) : false;

      if (error && isRepeatedPatient) {
        trackEvent("Alerta Paciente Repetido Visualizado", {
          codUsuario: user.codUsuario,
          codPaciente: agendamento?.Paciente?.codPaciente ?? 0,
          codUnidade: atomValues.unit,
          codTipoSala: atomValues.roomTypes,
          telaOrigem: "Reagendamento preço flexivel",
          data: formatIgnoringTimezone(atomValues.appointmentDate ?? new Date()),
          codAgendamento: atomValues.scheduleId,
          nomePaciente: atomValues.name,
          telefone: atomValues.phone,
        });

        navigateWithSearch("error-repeated-patient");
      }
    };

    const onSuccess = ({
      remarcarAgendamento: response,
    }: RescheduleAppointmentApiMutationType): void => {
      trackEvent("Agendamento Reagendado", {
        codAgendamento: response.data?.codAgendamento,
        codAgendamentoRaiz: appointmentId,
      });

      navigateWithSearch("success");
    };

    const {
      unit,
      appointmentDate,
      appointmentTime,
      appointmentType,
      paymentMethod,
      scheduleId,
      email,
      name,
      phone,
      cpf,
      dateOfBirth,
      sendConfirmationViaWhatsApp,
    } = atomValues;

    const cobraCancelamento = formData.appointmentMode === AppointmentMode.NonRefundable;

    if (agendamento) {
      const {
        cancelado,
        confirmado,
        ignorarEnvioEmail,
        ignorarEnvioWhatsApp,
        nomePlano,
        numeroCarteirinha,
      } = agendamento;

      await mutateAsync(
        {
          input: {
            codAgendamentoOrigem: appointmentId,
            remarcarNovoAgendamento: {
              codUnidade: unit,
              codUsuarioAgenda: scheduleId ?? null,
              data: formatIgnoringTimezone(appointmentDate ?? new Date()),
              horaInicio: formatIgnoringTimezone(appointmentTime.start, "HH:mm"),
              horaFim: formatIgnoringTimezone(appointmentTime.end, "HH:mm"),
              codUsuarioCompromisso: appointmentType,
              codUsuarioFormaRecebimento: paymentMethod,
              codUsuario: user.codUsuario,
              email,
              nome: name,
              telefone: phone,
              cpf: cpf ?? null,
              dataNascimento: dateOfBirth ? formatIgnoringTimezone(dateOfBirth) : null,
              receberWhatsAppConfirmacao: sendConfirmationViaWhatsApp,
              eventual: false,
              ignorarEnvioEmail,
              ignorarEnvioWhatsApp,
              nomePlano,
              numeroCarteirinha,
              confirmado,
              cancelado,
              cobraCancelamento,
              codFinalidadeAgendamento:
                appointmentTime.timeSlotType ===
                TimeSlotSuggestionType.LimitedDurationAppointment.valueOf()
                  ? AppointmentPurpose.HorariosMenores
                  : AppointmentPurpose.AtendimentoPresencial,
            },
          },
        },
        { onSuccess, onError },
      );
    } else {
      onError();
    }
  };

  const getDiscountFee = (): number => {
    return unidadesPrecosFlexiveis.length > 0
      ? unidadesPrecosFlexiveis[0].taxaDesconto
      : 0;
  };

  const navigateToHomePage = (date: string): void =>
    void navigate({ to: "/", search: { date } });

  const handleSuccessDrawerDismiss = (value: boolean): void => {
    if (!value) {
      const appointmentDate = formatIgnoringTimezone(
        atomValues.appointmentDate ?? new Date(),
      );
      resetRescheduleAppointmentForm();
      navigateToHomePage(appointmentDate);
    }
  };

  const handleRepeatedPatientErrorDrawerDismiss = (value: boolean): void => {
    if (!value && searchParams.action === "error-repeated-patient") {
      router.history.go(-2);
    }
  };

  return (
    <>
      <AppointmentFlexiblePriceRadioSelect
        isReservedSchedule={isReservedSchedule}
        onSubmit={onSubmit}
        discountFee={getDiscountFee()}
        submitLabel="Reagendar"
      />
      <CreatedAppointmentDrawer
        setOpen={handleSuccessDrawerDismiss}
        title="Agendamento remarcado"
        description="O paciente receberá um e-mail com os dados do agendamento."
        confirmButtonText="Ir para minha agenda"
      />

      <AppointmentRepeatedPatientErrorDrawer
        open={searchParams.action === "error-repeated-patient"}
        setOpen={handleRepeatedPatientErrorDrawerDismiss}
      />
    </>
  );
};
