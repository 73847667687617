/** Class representing a semantic version. */
export class Version {
  private major: number;
  private minor: number;
  private patch: number;

  /**
   * Set the version.
   * @param version - The version to be set.
   */
  constructor(version: string) {
    const [major, minor, patch] = version.split(".").map(Number);
    this.major = major;
    this.minor = minor;
    this.patch = patch;
  }

  /**
   * Check if the version is greater than the argument.
   * @param version - The version to be compared.
   * @returns A boolean indicating if the version is greater than the argument.
   */
  public greaterThan(version: Version): boolean {
    return (
      this.major > version.major ||
      (this.major === version.major && this.minor > version.minor) ||
      (this.major === version.major &&
        this.minor === version.minor &&
        this.patch > version.patch)
    );
  }

  /**
   * Check if the version is lower than the argument.
   * @param version - The version to be compared.
   * @returns A boolean indicating if the version is lower than the argument.
   */
  public lowerThan(version: Version): boolean {
    return (
      this.major < version.major ||
      (this.major === version.major && this.minor < version.minor) ||
      (this.major === version.major &&
        this.minor === version.minor &&
        this.patch < version.patch)
    );
  }

  /**
   * Check if the version is equal to the argument.
   * @param version - The version to be compared.
   * @returns A boolean indicating if the version is equal to the argument.
   */
  public equals(version: Version): boolean {
    return (
      this.major === version.major &&
      this.minor === version.minor &&
      this.patch === version.patch
    );
  }

  /**
   * Return the version as a string.
   * @returns The version as a string.
   */
  public toString(): string {
    return `${this.major}.${this.minor}.${this.patch}`;
  }
}
