import { DoctorWithClipboard } from "@repo/icons";
import { Link } from "@tanstack/react-router";
import {
  EmptyStateRoot,
  EmptyStateImage,
  EmptyStateTitle,
  EmptyStateButton,
} from "@/components/empty-state";

export const SchedulesEmptyState = (): JSX.Element => {
  return (
    <EmptyStateRoot>
      <EmptyStateImage
        icon={DoctorWithClipboard}
        alt="Imagem lista de agendas sem configurações"
      />
      <EmptyStateTitle>Você ainda não tem agendas configuradas</EmptyStateTitle>
      <Link to="/settings/schedule-options/schedules/create" className="w-full">
        <EmptyStateButton fullWidth>Criar agenda</EmptyStateButton>
      </Link>
    </EmptyStateRoot>
  );
};
