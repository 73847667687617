import { CpsBadge } from "corpus";

interface HorarioMenorBadgeProps {
  originType: string;
}

export const HorarioMenorBagde = ({
  originType,
}: HorarioMenorBadgeProps): JSX.Element => {
  const isHorarioMenor = (): boolean => {
    return originType === "horariosMenores";
  };

  return (
    <div className="text-xs">
      {isHorarioMenor() && (
        <CpsBadge color="helper" size="default">
          Limite de duração
        </CpsBadge>
      )}
    </div>
  );
};
