import { createFileRoute, redirect, useRouter, useSearch } from "@tanstack/react-router";
import { Left, Trash } from "@repo/icons";
import { graphql } from "@repo/graphql-types";
import { MapDaysOfWeekToFullName, formatWithZonedDate } from "@repo/lib";
import { z } from "zod";
import { Page } from "@/components/page";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { ensureQueryData, useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { InfoSection } from "@/components/info-section";
import { formatTimeHourMinute, parseTime } from "@/lib/time";
import { ReservedSchedulesNextSchedules } from "@/components/reserved-schedules-next-schedules";
import { IndividualReservedScheduleActionSheet } from "@/components/individual-reserved-schedule-action-sheet";

const reservedScheduleDetailsPageParamsSchema = z.object({
  individualActionSheetOpen: z.boolean().optional(),
  nextReserveId: z.number().int().optional(),
  date: z.string().optional(),
  dayOfWeek: z.number().optional(),
  locationName: z.string().optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
});

const ReservedSchedulesDetailsQuery = graphql(/* GraphQL */ `
  query ReservedSchedulesDetailsQuery(
    $dataAgendamento: date!
    $horaInicio: time!
    $codPeriodoGarantidoRecorrente: Int!
  ) {
    periodoGarantidoRecorrente: tbPeriodosGarantidosRecorrentes_by_pk(
      codPeriodoGarantidoRecorrente: $codPeriodoGarantidoRecorrente
    ) {
      ativo
      dataInicio
      horaInicio
      horaFim
      diaDaSemana
      Unidade {
        nomeLimpo
      }
      PeriodosGarantidosRecorrentesFrequencia {
        descricao
      }
      TipoSala {
        nome
      }
      ...ReservedSchedulesNextSchedulesFragment
    }
  }
`);

export const ReservedSchedulesDetailsPage = (): JSX.Element => {
  const { reservedScheduleId } = Route.useParams();

  const searchParams = useSearch({
    from: "/settings/reserved-schedules/$reservedScheduleId/",
  });
  const router = useRouter();

  const queryResult = useGraphQL(ReservedSchedulesDetailsQuery, {
    dataAgendamento: formatWithZonedDate(new Date(), "yyyy-MM-dd"),
    horaInicio: formatWithZonedDate(new Date(), "HH:mm"),
    codPeriodoGarantidoRecorrente: reservedScheduleId,
  });

  const { data } = queryResult;

  const reservedScheduleData = data?.periodoGarantidoRecorrente;

  const dayOfWeek = reservedScheduleData?.diaDaSemana
    ? MapDaysOfWeekToFullName.get(reservedScheduleData.diaDaSemana)
    : "";
  const startDate = reservedScheduleData?.dataInicio
    ? formatWithZonedDate(reservedScheduleData.dataInicio, "dd/MM/yyyy")
    : "";
  const timePeriod =
    reservedScheduleData?.horaInicio && reservedScheduleData.horaFim
      ? `${formatTimeHourMinute(parseTime(reservedScheduleData.horaInicio))} -
         ${formatTimeHourMinute(parseTime(reservedScheduleData.horaFim))}`
      : "";
  const reservedScheduleSection = {
    title: "",
    fields: [
      { label: "Unidade", value: reservedScheduleData?.Unidade.nomeLimpo },
      { label: "Tipo de sala", value: reservedScheduleData?.TipoSala.nome },
      {
        label: "Dia da semana",
        value: dayOfWeek,
      },
      {
        label: "Data de início",
        value: startDate,
      },
      {
        label: "Frequência",
        value: reservedScheduleData?.PeriodosGarantidosRecorrentesFrequencia.descricao,
      },
      {
        label: "Horário",
        value: timePeriod,
      },
    ],
  };

  const navigateToCancelPage = (): void => {
    void router.navigate({
      to: "/settings/reserved-schedules/$reservedScheduleId/cancel",
      params: { reservedScheduleId },
    });
  };

  const shouldActionSheetOpen = Boolean(searchParams.individualActionSheetOpen);

  const navigateToIndividualCancelPage = (): void => {
    if (searchParams.nextReserveId) {
      void router.navigate({
        to: "/settings/reserved-schedules/$reservedScheduleId/$nextReserveId/cancel",
        params: {
          reservedScheduleId,
          nextReserveId: searchParams.nextReserveId,
        },
        replace: true,
      });
    }
  };

  const periodTimeString = (): string => {
    return searchParams.startTime && searchParams.endTime
      ? `${formatTimeHourMinute(parseTime(searchParams.startTime))} - ${formatTimeHourMinute(parseTime(searchParams.endTime))}`
      : "";
  };

  const formatedIndividualReservedScheduleDate = searchParams.date
    ? formatWithZonedDate(searchParams.date, "dd/MM/yyyy")
    : "";

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Período garantido" align="center" />
        <HeaderButton icon={Trash} onClick={navigateToCancelPage} align="end" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          <p className="font-medium text-lg">
            {reservedScheduleData?.Unidade.nomeLimpo}, {dayOfWeek}
          </p>
          <InfoSection section={reservedScheduleSection} />
          <div>
            <div className="mt-8">
              {reservedScheduleData ? (
                <ReservedSchedulesNextSchedules data={reservedScheduleData} />
              ) : null}
            </div>
          </div>
          <IndividualReservedScheduleActionSheet
            open={shouldActionSheetOpen}
            setOpen={() => {
              router.history.back();
            }}
            cancelButtonAction={navigateToIndividualCancelPage}
            date={formatedIndividualReservedScheduleDate}
            locationName={searchParams.locationName ?? ""}
            dayOfWeek={searchParams.dayOfWeek}
            period={periodTimeString()}
          />
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/reserved-schedules/$reservedScheduleId/")(
  {
    component: ReservedSchedulesDetailsPage,
    validateSearch: reservedScheduleDetailsPageParamsSchema,
    parseParams: (params) => ({
      reservedScheduleId: z.number().int().parse(Number(params.reservedScheduleId)),
    }),
    beforeLoad: async (opts) => {
      try {
        const codPeriodoGarantidoRecorrente = Number(opts.params.reservedScheduleId);
        const dataAgendamento = formatWithZonedDate(new Date(), "yyyy-MM-dd");
        const horaInicio = formatWithZonedDate(new Date(), "HH:mm");

        const data = await ensureQueryData(opts.context, ReservedSchedulesDetailsQuery, {
          codPeriodoGarantidoRecorrente,
          dataAgendamento,
          horaInicio,
        });

        if (!data.periodoGarantidoRecorrente?.ativo) {
          throw new Error("reserved schedule is not active");
        }
      } catch (e) {
        redirect({
          to: "/settings/reserved-schedules",
          throw: true,
        });
      }
    },
  },
);
