import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { OneLivExperiencesForm } from "@/components/oneliv/oneliv-experiences-form";

const GetOneLivUserExperiences = graphql(`
  query GetOneLivUserExperiences {
    locus_membro {
      ...OneLivExperiencesFormFragment
    }
  }
`);

const oneLivExperiencesPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const OneLivExperiences = (): JSX.Element => {
  const query = useGraphQL(GetOneLivUserExperiences);

  const { data } = query;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Experiências" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...query}>
          {data ? <OneLivExperiencesForm data={data.locus_membro} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/oneliv/experiences")({
  component: OneLivExperiences,
  validateSearch: oneLivExperiencesPageSearchSchema,
});
