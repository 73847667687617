import { formatCurrency } from "@repo/lib";

export const BillingFooter = ({ total }: { total: number }): JSX.Element => {
  return (
    <div
      id="billing-footer"
      className="fixed bottom-0 left-0 right-0
     bg-secondary-500 h-2 rounded-t-xl pt-8 pb-12 px-4 text-neutral-50 text-lg"
    >
      <div className="flex justify-between">
        <p>Valor total:</p>
        <p>{formatCurrency(total)}</p>
      </div>
    </div>
  );
};
