import {
  type CalendarEventType,
  filterEventsByCurrentUnit,
  type CalendarEvent,
  defineAppointmentType,
  convertDateTimeIgnoringTimezone,
} from "@repo/lib";
import { differenceInMinutes, isSameDay, parseISO } from "date-fns";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { useSearch } from "@tanstack/react-router";
import { Calendar, type CalendarProps } from "@/components/calendar";

const HomePageCalendarFragment = graphql(/* GraphQL */ `
  fragment HomePageCalendarFragment on query_root {
    agendamentos(
      where: {
        FinalidadesAgendamento: {
          codFinalidadeAgendamento: { _in: $availableAppointmentsPurpose }
        }
        _and: [{ data: { _gte: $dataInicio } }, { data: { _lte: $dataFim } }]
      }
    ) {
      codAgendamento
      data
      horaInicio
      horaFim
      nome
      confirmado
      cancelado
      cobraCancelamento
      codFinalidadeAgendamento
      pago
      AgendamentosCheckin {
        codAgendamento
      }
      FinalidadesAgendamento {
        codFinalidadeAgendamento
        taxaCobrancaAtraso
      }
      Paciente {
        telefone
        nome
        email
        codPaciente
      }
      UsuarioCompromisso {
        nome
      }
      Unidade {
        codUnidade
        nome
        sigla
        nomeLimpo
        ativo
      }
    }
    tbFechamentos(
      where: { _and: [{ data: { _gte: $dataInicio } }, { data: { _lte: $dataFim } }] }
    ) {
      codUnidade
      data
      horaFim
      horaInicio
      motivo
      codFechamento
      codTipoFechamento
      tbUnidade {
        nome
        sigla
        nomeLimpo
        codUnidade
        ativo
      }
    }
  }
`);

export const FreeSchedulesSlotsHomePageCalendarFragment = graphql(/* GraphQL */ `
  fragment FreeSchedulesSlotsHomePageCalendarFragment on query_root {
    LivanceApiBuscaSelecionaFaixasLivresAgenda(
      arg1: {
        codUsuario: $codUsuario
        codUnidade: $codUnidade
        dataInicio: $dataInicio
        dataFim: $dataFim
      }
    ) {
      inicio
      fim
      prioridade
      podeExceder
      codUsuarioAgenda
      autorizado
    }
  }
`);

interface HomePageCalendarProps
  extends Pick<CalendarProps, "onChangeView" | "onDateClick" | "initialView"> {
  currentDate: string;
  currentUnit: { id: number; name: string; acronym: string };
  homePageCalendarFragmentData: FragmentType<typeof HomePageCalendarFragment>;
  freeSchedulesSlotsHomePageCalendarFragmentData?: FragmentType<
    typeof FreeSchedulesSlotsHomePageCalendarFragment
  >;
}

export const HomePageCalendar = ({
  currentDate,
  currentUnit,
  onChangeView,
  onDateClick,
  initialView,
  homePageCalendarFragmentData,
  freeSchedulesSlotsHomePageCalendarFragmentData,
}: HomePageCalendarProps): JSX.Element => {
  const { tbFechamentos: closedSchedules, agendamentos } = useFragment(
    HomePageCalendarFragment,
    homePageCalendarFragmentData,
  );

  const freeScheduleSlotsFragmentResult = useFragment(
    FreeSchedulesSlotsHomePageCalendarFragment,
    freeSchedulesSlotsHomePageCalendarFragmentData,
  );

  const { showCancelled } = useSearch({
    from: "/",
  });

  const assignColorBasedOnEventType = (eventType: CalendarEventType): string => {
    const appointmentColor = "#39839E";
    const closedColor = "#FC9C9C";
    const openScheduleColor = "#6EE7B7";

    const eventTypeColorMap: Record<string, string> = {
      pending: appointmentColor,
      confirmed: appointmentColor,
      old: appointmentColor,
      cancelled: closedColor,
      closed: closedColor,
      default: openScheduleColor,
    };

    return eventTypeColorMap[eventType] || eventTypeColorMap.default;
  };

  //TODO: Métodos duplicados. Devemos levá-los para o calendar-events.ts
  const buildAppointmentsEvents = (): CalendarEvent[] => {
    const appointments = showCancelled
      ? agendamentos.filter((a) => a.cancelado)
      : agendamentos.filter((a) => !a.cancelado);

    const appointmentEvents = appointments.map((appointment) => {
      return {
        id: `appointment-${appointment.codAgendamento.toString()}`,
        type: defineAppointmentType(appointment),
        title: appointment.nome,
        appointmentType: appointment.UsuarioCompromisso?.nome,
        start: convertDateTimeIgnoringTimezone(appointment.data, appointment.horaInicio),
        end: convertDateTimeIgnoringTimezone(appointment.data, appointment.horaFim),
        appointment,
        unit: {
          id: appointment.Unidade.codUnidade,
          name: appointment.Unidade.nome,
          acronym: appointment.Unidade.sigla,
        },
      } as CalendarEvent;
    });

    return appointmentEvents;
  };

  const buildUnavailableSlotsEvents = (): CalendarEvent[] => {
    const filteredUnavailableSlots = closedSchedules.filter((unavailableSlot) => {
      const manualUnavailableSlot = 2;
      if (
        currentUnit.id === 0 &&
        unavailableSlot.codTipoFechamento === manualUnavailableSlot
      ) {
        return true;
      }

      return (
        unavailableSlot.codUnidade === null ||
        unavailableSlot.codUnidade === currentUnit.id
      );
    });

    const unavailableSlotEvents = filteredUnavailableSlots.map(
      (unavailableSlotElement) =>
        ({
          id: `unavailable-slot-${unavailableSlotElement.codFechamento.toString()}`,
          type: "closed",
          title: unavailableSlotElement.motivo ?? "",
          appointmentType: "Fechamento",
          start: convertDateTimeIgnoringTimezone(
            unavailableSlotElement.data,
            unavailableSlotElement.horaInicio,
          ),
          end: convertDateTimeIgnoringTimezone(
            unavailableSlotElement.data,
            unavailableSlotElement.horaFim,
          ),
          appointment: null,
          unit: {
            id: unavailableSlotElement.codUnidade,
            name: unavailableSlotElement.tbUnidade?.nome,
            acronym: unavailableSlotElement.tbUnidade?.sigla,
          },
        }) as CalendarEvent,
    );

    return unavailableSlotEvents;
  };

  const buildFreeWeekSlotsEvents = (): CalendarEvent[] => {
    const MINIMUM_FREE_SLOT_DURATION = 15;

    const freeSlotsEvents =
      freeScheduleSlotsFragmentResult?.LivanceApiBuscaSelecionaFaixasLivresAgenda;

    const calendarEvents =
      freeSlotsEvents?.map((slot, index) => {
        if (!slot) return;

        return {
          id: `week-slot-${index}`,
          type: "weekSlot",
          title: "",
          appointmentType: "",
          start: parseISO(slot.inicio),
          end: parseISO(slot.fim),
          appointment: null,
          unitId: currentUnit.id,
          unit: currentUnit,
        } as CalendarEvent;
      }) ?? [];

    return calendarEvents
      .filter((event): event is CalendarEvent => event !== undefined)
      .filter((event: CalendarEvent) => {
        const dateDiff = differenceInMinutes(event.end, event.start);
        return dateDiff >= MINIMUM_FREE_SLOT_DURATION;
      });
  };

  const headerEvents = (): CalendarEvent[] => {
    const events = filterEventsByCurrentUnit(
      [
        ...buildAppointmentsEvents(),
        ...buildUnavailableSlotsEvents(),
        ...buildFreeWeekSlotsEvents(),
      ],
      currentUnit.id,
    );

    const filterUniqueDayEvents = events
      .filter(
        (current, index, self) =>
          index ===
          self.findIndex(
            (e) => isSameDay(e.start, current.start) && e.type === current.type,
          ),
      )
      .map((event) => {
        return {
          ...event,
          title: "",
          color: assignColorBasedOnEventType(event.type),
        };
      });

    return filterUniqueDayEvents;
  };

  return (
    <Calendar
      events={headerEvents()}
      selectedDate={currentDate}
      possibleViews={["dayGridDay", "dayGridWeek", "dayGridMonth"]}
      onChangeView={onChangeView}
      onDateClick={onDateClick}
      displayEventTime={false}
      dayMaxEventRows={3}
      initialView={initialView}
    />
  );
};
