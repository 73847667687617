import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { zonedDate, getTimeDateFromStrings } from "@repo/lib";
import {
  createFileRoute,
  redirect,
  useLoaderData,
  useRouter,
} from "@tanstack/react-router";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { z } from "zod";
import { useResetAtom } from "jotai/utils";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { ensureQueryData } from "@/hooks/use-graphql";
import { Page } from "@/components/page";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { PatientSwapPatientList } from "@/components/patient-swap-patient-list";

const PatientSwapSearchPageQuery = graphql(/* GraphQL */ `
  query PatientSwapSearchPageQuery($codAgendamento: Int!) {
    appointment: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      codAgendamento
      data
      horaInicio
      horaFim
    }

    ...PatientSwapPatientListFragment
  }
`);

export const PatientSwapSearchPage = (): JSX.Element => {
  const { data } = useLoaderData({
    from: "/appointment/$appointmentId/patient-swap-search",
  });

  const router = useRouter();

  const setAtomValues = useSetAtom(appointmentFormAtom);

  const resetAppointmentFormAtom = useResetAtom(appointmentFormAtom);

  useEffect(() => {
    if (data) {
      const { appointment } = data;

      const appointmentDate = appointment?.data ? zonedDate(appointment.data) : undefined;

      setAtomValues((prev) => ({
        ...prev,
        appointmentDate,
        appointmentTime: {
          start: appointment?.horaInicio
            ? getTimeDateFromStrings(
                appointmentDate ?? new Date(),
                appointment.horaInicio,
              )
            : new Date(),
          end: appointment?.horaFim
            ? getTimeDateFromStrings(appointmentDate ?? new Date(), appointment.horaFim)
            : new Date(),
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Deve ser executado somente no carregamento da página
  }, []);

  const isPending = !data?.appointment;

  const onBackButtonClick = (): void => {
    resetAppointmentFormAtom();
    router.history.back();
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={onBackButtonClick} icon={Left} align="start" />
        <HeaderTitle title="Trocar paciente" align="center" />
      </HeaderRoot>

      <Page>
        {!isPending ? <PatientSwapPatientList data={data} /> : <FullPageSpinner />}
      </Page>
    </>
  );
};

export const Route = createFileRoute("/appointment/$appointmentId/patient-swap-search")({
  component: PatientSwapSearchPage,
  parseParams: (params) => ({
    appointmentId: z.number().int().parse(Number(params.appointmentId)),
  }),
  loader: async (opts) => {
    try {
      const variables = {
        codAgendamento: opts.params.appointmentId,
      };

      const data = await ensureQueryData(
        opts.context,
        PatientSwapSearchPageQuery,
        variables,
      );

      const { appointment } = data;

      const appointmentNotFound = !appointment;

      if (appointmentNotFound) throw new Error("Appointment not found!");

      return { data };
    } catch (e) {
      redirect({
        to: "/",
        throw: true,
      });
    }

    return {};
  },
});
