import { createFileRoute, redirect } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { Left } from "@repo/icons";
import { type FragmentType } from "@repo/graphql-types/fragment-masking";
import { ensureQueryData, useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import {
  ChangeTimeMinutesForm,
  type ChangeTimeMinutesFragment,
} from "@/components/change-time-form";

const ChangeTimeMinutesPageQuery = graphql(/* GraphQL */ `
  query ChangeTimeMinutesPageQuery($codAgendamento: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      horaInicio
      cancelado
      ...ChangeTimeMinutesFragment
    }
  }
`);

export const ChangeTimePage = (): JSX.Element => {
  const { codAgendamento } = Route.useParams();

  const queryResult = useGraphQL(ChangeTimeMinutesPageQuery, {
    codAgendamento,
  });
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Alterar horário" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <ChangeTimeMinutesForm
              data={data.agendamento as FragmentType<typeof ChangeTimeMinutesFragment>}
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/waiting-room/$codAgendamento/change-time/")({
  component: ChangeTimePage,
  parseParams: (params) => ({
    codAgendamento: z.number().int().parse(Number(params.codAgendamento)),
  }),
  loader: async (opts) => {
    try {
      const variables = {
        codAgendamento: opts.params.codAgendamento,
      };

      const data = await ensureQueryData(
        opts.context,
        ChangeTimeMinutesPageQuery,
        variables,
      );

      const appointment = data.agendamento;

      const appointmentNotFound = !appointment;

      if (appointmentNotFound) throw new Error("Appointment not found!");

      const appointmentCancelled = appointment.cancelado;

      if (appointmentCancelled) throw new Error("Appointment was cancelled!");

      return { appointment };
    } catch (e) {
      redirect({
        to: "/waiting-room",
        throw: true,
      });
    }

    return {};
  },
});
