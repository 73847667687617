import { createFileRoute, useRouteContext } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { Billing } from "@/components/billing";
import { NewBilling } from "@/components/new-billing";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";

export const BillingPage = (): JSX.Element => {
  const { flags } = useRouteContext({ strict: false });
  const useNewMethodBillingList = flags["utiliza-modelo-de-cobranca-refatorado"];

  const renderBillingList = (): JSX.Element => {
    if (useNewMethodBillingList) {
      return <NewBilling />;
    }
    return <Billing />;
  };
  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Histórico de uso" align="center" />
      </HeaderRoot>
      <Page>{renderBillingList()}</Page>
    </>
  );
};

export const Route = createFileRoute("/settings/billing/")({
  component: BillingPage,
});
