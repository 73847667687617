import { CpsButtonText } from "corpus";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  formatDateAndTimeIgnoringTimezone,
  formatIgnoringTimezone,
  getDateFromToday,
  zonedDate,
} from "@repo/lib";
import { useState } from "react";
import { useRouter, useSearch } from "@tanstack/react-router";
import { Calendar } from "@repo/icons";
import { type CpsSelectorType } from "corpus";
import { Button } from "@/components/button";
import {
  DataRangeFilterDrawerForm,
  DataRangeFilterDrawerTitle,
  DateRangeFilterDrawerCalendarSelectSelect,
  DateRangeFilterDrawerFooter,
  DateRangeFilterDrawerRadioSelect,
  DateRangeFilterDrawerRoot,
} from "@/components/date-range-filter-drawer";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";

const today = zonedDate(new Date());

const generateKeyAndLabel = (daysFromToday: number, label: string): [string, string] => {
  const date = getDateFromToday(daysFromToday);
  if (daysFromToday > 0) {
    return [
      `${formatIgnoringTimezone(today, "yyyy-MM-dd")} - ${formatIgnoringTimezone(date, "yyyy-MM-dd")}`,
      label,
    ];
  }

  return [
    `${formatIgnoringTimezone(date, "yyyy-MM-dd")} - ${formatIgnoringTimezone(today, "yyyy-MM-dd")}`,
    label,
  ];
};

const mapFilterLabel = new Map<string, string>([
  generateKeyAndLabel(-6, "Últimos 7 dias"),
  generateKeyAndLabel(-14, "Últimos 15 dias"),
  generateKeyAndLabel(-29, "Últimos 30 dias"),
  generateKeyAndLabel(-59, "Últimos 60 dias"),
  generateKeyAndLabel(14, "Próximos 15 dias"),
  generateKeyAndLabel(29, "Próximos 30 dias"),
]);

interface PaymentFilterFormProps {
  setOpenFilter: (open: boolean, param: "filter") => void;
  open: boolean;
  visiblePeriods?: number[];
  value?: {
    from: Date;
    to: Date | undefined;
  };
}

export const PaymentFilterForm = ({
  value,
  open,
  setOpenFilter,
  visiblePeriods = [-7, -15, -30, -60],
}: PaymentFilterFormProps): JSX.Element => {
  const formSchema = z.object({
    dateRange: z.object({
      from: z.date(),
      to: z.date().optional(),
    }),
  });

  type FormFields = z.infer<typeof formSchema>;
  const form = useForm<FormFields>({
    defaultValues: {
      dateRange: {
        from: value?.from,
        to: value?.to,
      },
    },
  });

  const intervalStart = value?.from
    ? formatIgnoringTimezone(value.from, "yyyy-MM-dd")
    : formatIgnoringTimezone(getDateFromToday(-6), "yyyy-MM-dd");
  const intervalEnd = value?.to
    ? formatIgnoringTimezone(value.to, "yyyy-MM-dd")
    : formatIgnoringTimezone(today, "yyyy-MM-dd");

  const initialRangeKey = `${intervalStart} - ${intervalEnd}`;
  const initialRangeKeyFormatted = `${formatDateAndTimeIgnoringTimezone(intervalStart, undefined, "dd/MM/yyyy")} - ${formatDateAndTimeIgnoringTimezone(intervalEnd, undefined, "dd/MM/yyyy")}`;

  const getInitialFilterLabel = (): string => {
    if (intervalStart === intervalEnd) {
      return formatDateAndTimeIgnoringTimezone(intervalStart, undefined, "dd/MM/yyyy");
    }

    return mapFilterLabel.get(initialRangeKey) ?? initialRangeKeyFormatted;
  };

  const [selectorValue, setSelectorValue] = useState<number | undefined>(undefined);

  const [filterLabel, setFilterLabel] = useState<string>(getInitialFilterLabel());

  const router = useRouter();
  const searchParams: { back?: number } = useSearch({ strict: false });

  const handleSubmit = (formData: FormFields): void => {
    let formStartDate = formData.dateRange.from;
    let formEndDate = formData.dateRange.to;

    if (formEndDate && formStartDate > formEndDate) {
      [formStartDate, formEndDate] = [formEndDate, formStartDate];
    }

    const startDate = formatIgnoringTimezone(formStartDate);

    const endDate = formEndDate
      ? formatIgnoringTimezone(formEndDate)
      : formatIgnoringTimezone(formStartDate);

    handleFilterLabel(startDate, endDate);

    void router.navigate({
      search: {
        startDate,
        endDate,
        back: (searchParams.back ?? 0) + 1,
      },
      replace: true,
    });
  };

  const handleFilterLabel = (from: string, to: string): void => {
    const startDate = formatDateAndTimeIgnoringTimezone(from, undefined, "dd/MM/yyyy");

    const endDate = formatDateAndTimeIgnoringTimezone(to, undefined, "dd/MM/yyyy");

    const rangeKeyInBrazilianFormat = `${startDate} - ${endDate}`;

    const rangeKey = `${from} - ${to}`;

    if (startDate === endDate) {
      setFilterLabel(startDate);
    } else {
      setFilterLabel(mapFilterLabel.get(rangeKey) ?? rangeKeyInBrazilianFormat);
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpenFilter(true, "filter");
        }}
        Icon={Calendar}
        rounded
        fullWidth
        size="small"
      >
        {filterLabel}
      </Button>

      <DateRangeFilterDrawerRoot setOpen={(e) => setOpenFilter(e, "filter")} open={open}>
        <DataRangeFilterDrawerTitle>Filtrar por</DataRangeFilterDrawerTitle>
        <FormRoot {...form}>
          <FormHandlerSubmit handleSubmit={handleSubmit} className="flex flex-col gap-4">
            <DataRangeFilterDrawerForm
              value={selectorValue}
              onChange={(element: CpsSelectorType) => {
                const parsedValue = Number(element);
                const dayDiscount = parsedValue > 0 ? -1 : 1;
                form.setValue("dateRange", {
                  from: getDateFromToday(parsedValue + dayDiscount),
                  to: today,
                });
                setSelectorValue(parsedValue);
              }}
            >
              <DateRangeFilterDrawerRadioSelect title="Período" values={visiblePeriods} />

              <FormField
                control={form.control}
                name="dateRange"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <DateRangeFilterDrawerCalendarSelectSelect
                        {...field}
                        value={{
                          from: field.value.from,
                          to: field.value.to ?? undefined,
                        }}
                        onChange={(element) => {
                          field.onChange(element);
                          setSelectorValue(undefined);
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </DataRangeFilterDrawerForm>
            <DateRangeFilterDrawerFooter>
              <FormSubmitButton className="w-full">Aplicar</FormSubmitButton>
              <CpsButtonText
                onClick={() => {
                  form.reset();
                  setSelectorValue(undefined);
                }}
              >
                Limpar filtro
              </CpsButtonText>
            </DateRangeFilterDrawerFooter>
          </FormHandlerSubmit>
        </FormRoot>
      </DateRangeFilterDrawerRoot>
    </>
  );
};
