import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { OneLivEducationalBackgroundForm } from "@/components/oneliv/oneliv-educational-background-form";

const GetOneLivUserEducationalBackground = graphql(`
  query GetOneLivUserEducationalBackground {
    ...OneLivEducationalBackgroundFormFragment
  }
`);

const oneLivEducationalBackgroundPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const OneLivEducationalBackground = (): JSX.Element => {
  const query = useGraphQL(GetOneLivUserEducationalBackground);

  const { data } = query;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Formações" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...query}>
          {data ? <OneLivEducationalBackgroundForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/oneliv/educational-background")({
  component: OneLivEducationalBackground,
  validateSearch: oneLivEducationalBackgroundPageSearchSchema,
});
