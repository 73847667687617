import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Link } from "@tanstack/react-router";
import { ScheduleFrequencyEnum, setTime, zonedDate } from "@repo/lib";
import { Button } from "@/components/button";
import { ScheduleListItem } from "@/components/schedule-list-item";
import { SchedulesEmptyState } from "@/components/schedules-empty-state";
import { NoAppointmentTypesSchedulesEmptyState } from "@/components/no-appointment-types-schedules-empty-state";

interface ScheduleListProps {
  data: FragmentType<typeof SchedulesListQueryFragment>;
}

export const SchedulesListQueryFragment = graphql(/* GraphQL */ `
  fragment SchedulesListQueryFragment on query_root {
    usuariosAgendas(
      where: { ativo: { _eq: true } }
      order_by: { codUsuarioAgenda: desc }
    ) {
      codUsuarioAgenda
      startDate: dataInicio
      tipoRepeticao: codTipoAgendaRepeticao
      ...ScheduleListItemFragment
    }
    usuariosCompromissos(
      where: {
        ativo: { _eq: true }
        categoria: {
          _neq: 3 # 3 is Período Garantido
        }
      }
    ) {
      codUsuarioCompromisso
    }
  }
`);

export const ScheduleList = ({ data }: ScheduleListProps): JSX.Element => {
  const { usuariosAgendas, usuariosCompromissos } = useFragment(
    SchedulesListQueryFragment,
    data,
  );

  const schedulesSorter = (
    scheduleA: (typeof usuariosAgendas)[0],
    scheduleB: (typeof usuariosAgendas)[0],
  ): SortResult => {
    const defaultByCreationOrder = 0;

    const currentDateLocalized = setTime(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    const getDateLocalized = (date: string): Date =>
      setTime(zonedDate(date), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

    const hasRepetition = (schedule: (typeof usuariosAgendas)[0]): boolean =>
      schedule.tipoRepeticao !== ScheduleFrequencyEnum.NaoRepetir.valueOf();

    const alreadyStarted = (schedule: (typeof usuariosAgendas)[0]): boolean => {
      const scheduleStartDateLocalized = getDateLocalized(schedule.startDate);
      return scheduleStartDateLocalized.getTime() <= currentDateLocalized.getTime();
    };

    const sortByStartDate = (startDateA: string, startDateB: string): SortResult => {
      if (getDateLocalized(startDateA).getTime() < getDateLocalized(startDateB).getTime())
        return -1;
      if (getDateLocalized(startDateA).getTime() > getDateLocalized(startDateB).getTime())
        return 1;
      return defaultByCreationOrder;
    };

    const hasRepetitionA = hasRepetition(scheduleA);
    const hasRepetitionB = hasRepetition(scheduleB);

    if (!hasRepetitionA && hasRepetitionB) return -1;
    if (hasRepetitionA && !hasRepetitionB) return 1;
    if (!hasRepetitionA && !hasRepetitionB)
      return sortByStartDate(scheduleA.startDate, scheduleB.startDate);

    const alreadyStartedA = alreadyStarted(scheduleA);
    const alreadyStartedB = alreadyStarted(scheduleB);

    if (!alreadyStartedA && alreadyStartedB) return -1;
    if (alreadyStartedA && !alreadyStartedB) return 1;
    if (!alreadyStartedA && !alreadyStartedB) {
      return sortByStartDate(scheduleA.startDate, scheduleB.startDate);
    }

    return defaultByCreationOrder;
  };

  const userHasAppointmentTypes = usuariosCompromissos.length > 0;
  const userHasSchedules = usuariosAgendas.length > 0;

  if (!userHasAppointmentTypes) return <NoAppointmentTypesSchedulesEmptyState />;

  if (!userHasSchedules) return <SchedulesEmptyState />;

  usuariosAgendas.sort(schedulesSorter);

  return (
    <>
      <ul className="flex flex-col gap-4">
        {usuariosAgendas.map((schedule) => (
          <li
            className="last:mb-8"
            key={schedule.codUsuarioAgenda}
            data-testid={`schedule-li-${schedule.codUsuarioAgenda}`}
          >
            <ScheduleListItem schedule={schedule} />
          </li>
        ))}
      </ul>
      <div className="sticky bottom-0 pb-4">
        <Link to="/settings/schedule-options/schedules/create">
          <Button fullWidth>Criar agenda</Button>
        </Link>
      </div>
    </>
  );
};

type SortResult = 1 | 0 | -1;
