import { Left } from "@repo/icons";
import {
  createFileRoute,
  redirect,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { z } from "zod";
import { graphql } from "@repo/graphql-types";
import {
  ReservedScheduleCancelPageQueryDocument,
  ReservedScheduleListQueryFragmentFragmentDoc,
} from "@repo/graphql-types/graphql";
import { CpsSpinner } from "corpus";
import {
  DaysOfWeekToPortugueseAdverbial,
  formatWithZonedDate,
  logError,
} from "@repo/lib";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  ensureQueryData,
  useGraphQL,
  useGraphQLMutation,
  useInvalidateQuery,
} from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { ReservedScheduleTermsOfUseUrl } from "@/components/reserved-schedule-terms-of-use-url";
import { ReservedScheduleCancelSimulationInfo } from "@/components/reserved-schedule-cancel-simulation-info";
import { ReservedScheduleCancelSuccessDrawer } from "@/components/reserved-schedule-cancel-success-drawer";
import { ReservedScheduleCancelFailDrawer } from "@/components/reserved-schedule-cancel-fail-drawer";
import { formatTimeHourMinute, parseTime } from "@/lib/time";
import { Button } from "@/components/button";
import { trackEvent } from "@/lib/tracking";

const reservedScheduleCancelPageSearchParamsSchema = z.object({
  success: z.boolean().optional(),
  fail: z.boolean().optional(),
});

const ReservedScheduleCancelPageQuery = graphql(/* GraphQL */ `
  query ReservedScheduleCancelPageQuery(
    $codPeriodoGarantidoRecorrente: Int!
    $dataAgendamento: date!
    $horaInicio: time!
  ) {
    periodoGarantidoRecorrente: tbPeriodosGarantidosRecorrentes_by_pk(
      codPeriodoGarantidoRecorrente: $codPeriodoGarantidoRecorrente
    ) {
      ativo
      dataInicio
      horaInicio
      horaFim
      diaDaSemana
      Unidade {
        nomeLimpo
        codUnidade
      }
      TipoSala {
        nome
      }
      PeriodosGarantidos(
        where: {
          Agendamento: {
            cancelado: { _eq: false }
            _or: [
              { data: { _gt: $dataAgendamento } }
              {
                _and: [
                  { data: { _eq: $dataAgendamento } }
                  { horaInicio: { _gte: $horaInicio } }
                ]
              }
            ]
          }
          ativo: { _eq: true }
        }
      ) {
        codPeriodoGarantido
        codModalidadePeriodoGarantido
        Agendamento {
          data
        }
      }
    }
  }
`);

export const ReservedScheduleCancelSimulationQuery = graphql(/* GraphQL */ `
  query ReservedScheduleCancelSimulationQuery($codPeriodoGarantido: Int!) {
    LivanceApiSimulaCobrancaDeCancelamento(
      arg1: { codPeriodoGarantido: $codPeriodoGarantido, dataReferencia: null }
    ) {
      taxa
      valor
    }
  }
`);

export const CancelReservedScheduleMutation = graphql(/* GraphQL */ `
  mutation CancelReservedScheduleMutation($codPeriodoGarantidoRecorrente: Int!) {
    LivanceApiCancelaPeriodoGarantidoRecorrente(
      periodoGarantidoRecorrente: {
        codPeriodoGarantidoRecorrente: $codPeriodoGarantidoRecorrente
      }
    ) {
      message
      success
    }
  }
`);

export const ReservedScheduleCancelPage = (): JSX.Element => {
  const router = useRouter();
  const { reservedScheduleId } = Route.useParams();

  const { user } = useRouteContext({
    strict: false,
  });

  const params = useSearch({
    from: "/settings/reserved-schedules/$reservedScheduleId/cancel",
  });
  const queryResult = useGraphQL(ReservedScheduleCancelPageQuery, {
    codPeriodoGarantidoRecorrente: reservedScheduleId,
    dataAgendamento: formatWithZonedDate(new Date(), "yyyy-MM-dd"),
    horaInicio: formatWithZonedDate(new Date(), "HH:mm"),
  });

  const { data } = queryResult;
  const reservedSchedule = data?.periodoGarantidoRecorrente;
  const nextSchedule = reservedSchedule?.PeriodosGarantidos[0];

  const queryResultCancelSimulation = useGraphQL(
    ReservedScheduleCancelSimulationQuery,
    { codPeriodoGarantido: nextSchedule?.codPeriodoGarantido ?? 0 },
    { enabled: Boolean(nextSchedule?.codPeriodoGarantido) },
  );

  const { data: dataCancelSimulation } = queryResultCancelSimulation;
  const simulationTax =
    dataCancelSimulation?.LivanceApiSimulaCobrancaDeCancelamento?.taxa ?? 0;
  const simulationCost =
    dataCancelSimulation?.LivanceApiSimulaCobrancaDeCancelamento?.valor ?? 0;

  const { mutateAsync, isPending } = useGraphQLMutation(CancelReservedScheduleMutation);

  const invalidateReservedScheduleListQueryFragment = useInvalidateQuery(
    ReservedScheduleListQueryFragmentFragmentDoc,
  );
  const invalidateReservedScheduleCancelPageQuery = useInvalidateQuery(
    ReservedScheduleCancelPageQueryDocument,
  );

  const handleReservedScheduleCancelAsync = async (): Promise<void> => {
    const variables = {
      codPeriodoGarantidoRecorrente: reservedScheduleId,
    };

    const onSuccess = (): void => {
      trackEvent("Periodo Garantido Recorrente Cancelado", {
        codUsuario: user.codUsuario,
        codPeriodoGarantidoRecorrente: reservedScheduleId,
        codUnidade: reservedSchedule?.Unidade.codUnidade,
      });

      void router.navigate({
        search: {
          success: true,
        },
      });

      invalidateReservedScheduleListQueryFragment();
      invalidateReservedScheduleCancelPageQuery();
    };

    const onError = (error: Error): void => {
      logError(error, variables);
      void router.navigate({
        search: {
          fail: true,
        },
      });
    };

    await mutateAsync(variables, { onSuccess, onError });
  };

  const setOpenDrawer = (open: boolean, paramName: "success" | "fail"): void => {
    const search = {
      success: undefined,
      fail: undefined,
      ...params,
    };
    if (open) {
      search[paramName] = open;

      void router.navigate({
        search,
      });
    } else if (params[paramName]) {
      router.history.back();
    }
  };

  const dayOfWeek = DaysOfWeekToPortugueseAdverbial.get(
    reservedSchedule?.diaDaSemana ?? 0,
  )?.toLocaleLowerCase();
  const startTime = formatTimeHourMinute(parseTime(reservedSchedule?.horaInicio ?? ""));
  const endTime = formatTimeHourMinute(parseTime(reservedSchedule?.horaFim ?? ""));
  const roomTypeName = reservedSchedule?.TipoSala.nome;
  const locationName = reservedSchedule?.Unidade.nomeLimpo;

  const successDrawerDescription = `O seu período garantido de ${locationName}, em sala ${roomTypeName}, ${dayOfWeek}, das ${startTime} às ${endTime}, foi cancelado.`;

  const navigateToCalendar = (): void => {
    router.history.destroy();
    void router.navigate({
      to: "/",
    });
  };

  const navigateToScheduleList = (): void => {
    router.history.go(-3);
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Período garantido" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          <div className="flex flex-col gap-6">
            <p className="font-medium text-lg">Cancelar período garantido</p>
            <p>
              Ao cancelar o período garantido, você perderá a garantida de disponibilidade
              de sala.
            </p>
            <AsyncDataWrapper {...queryResultCancelSimulation} fallback={<CpsSpinner />}>
              <ReservedScheduleCancelSimulationInfo
                tax={simulationTax}
                cost={simulationCost}
                locationName={reservedSchedule?.Unidade.nomeLimpo}
                dayOfWeek={reservedSchedule?.diaDaSemana}
                date={nextSchedule?.Agendamento.data}
                startTime={reservedSchedule?.horaInicio}
                endTime={reservedSchedule?.horaFim}
              />
            </AsyncDataWrapper>
            <p>
              Mais detalhes em{" "}
              <ReservedScheduleTermsOfUseUrl
                reservedScheduleType={nextSchedule?.codModalidadePeriodoGarantido ?? 0}
                title="Termos e Condições de Uso"
              />
              .
            </p>
            <Button
              data-testid="botao-cancela-periodo-garantido"
              onClick={() => {
                void handleReservedScheduleCancelAsync();
              }}
              loading={isPending}
            >
              Cancelar período garantido
            </Button>
          </div>
          <ReservedScheduleCancelSuccessDrawer
            description={successDrawerDescription}
            open={Boolean(params.success)}
            primaryButtonText="Ir para meus períodos"
            secondaryButtonText="Ir para agenda"
            primaryButtonAction={navigateToScheduleList}
            secondaryButtonAction={navigateToCalendar}
          />
          <ReservedScheduleCancelFailDrawer
            open={Boolean(params.fail)}
            setOpen={(open: boolean) => {
              setOpenDrawer(open, "fail");
            }}
          />
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/reserved-schedules/$reservedScheduleId/cancel",
)({
  component: ReservedScheduleCancelPage,
  validateSearch: reservedScheduleCancelPageSearchParamsSchema,
  parseParams: (params) => ({
    reservedScheduleId: z.number().int().parse(Number(params.reservedScheduleId)),
  }),
  beforeLoad: async (opts) => {
    try {
      const codPeriodoGarantidoRecorrente = Number(opts.params.reservedScheduleId);
      const dataAgendamento = formatWithZonedDate(new Date(), "yyyy-MM-dd");
      const horaInicio = formatWithZonedDate(new Date(), "HH:mm");

      const data = await ensureQueryData(opts.context, ReservedScheduleCancelPageQuery, {
        codPeriodoGarantidoRecorrente,
        dataAgendamento,
        horaInicio,
      });

      if (!data.periodoGarantidoRecorrente?.ativo) {
        throw new Error("reserved schedule is not active");
      }
    } catch (e) {
      redirect({
        to: "/settings/reserved-schedules",
        throw: true,
      });
    }
  },
});
