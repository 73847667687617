import { useFragment, type FragmentType } from "@repo/graphql-types";
import { graphql } from "@repo/graphql-types/gql";
import { useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { DoctorWithStethoscope } from "@repo/icons";
import { type Patient } from "@repo/lib";
import { Button } from "@/components/button";
import {
  EmptyStateButton,
  EmptyStateImage,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { PatientListItem } from "@/components/patient-list-item";
import { ListRoot } from "@/components/list";
import { SearchInput } from "@/components/search-input";

export const AppointmentPatientListFragment = graphql(/* GraphQL */ `
  fragment AppointmentPatientListFragment on query_root {
    pacientes(
      order_by: { nome: asc }
      where: { ativo: { _eq: true }, registroVirtual: { _eq: false } }
    ) {
      nome
      telefone
      codPaciente
      ...PatientListItemFragment
    }
  }
`);

interface AppointmentPatientListProps {
  data: FragmentType<typeof AppointmentPatientListFragment>;
  onClick?: (patient: Patient) => void;
  onAddPatientButtonClick?: () => void;
}

export const AppointmentPatientList = ({
  data,
  onClick,
  onAddPatientButtonClick,
}: AppointmentPatientListProps): JSX.Element => {
  const fragmentResult = useFragment(AppointmentPatientListFragment, data);

  const { pacientes } = fragmentResult;

  const [searchResults, setSearchResults] = useState(pacientes);

  const [height, setHeight] = useState("65vh");

  useEffect(() => {
    const calculateHeight = (): void => {
      const main = document.querySelector("main")?.clientHeight ?? 0;
      const header = document.querySelector("header")?.clientHeight ?? 0;
      const calculatedHeight = main - header - 200;

      setHeight(`${calculatedHeight}px`);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  return (
    <>
      {pacientes.length > 0 ? (
        <div className="flex flex-col gap-6">
          <SearchInput
            items={pacientes}
            onSearch={setSearchResults}
            searchKeys={["nome", "telefone"]}
          />
          <Button fullWidth onClick={onAddPatientButtonClick}>
            Adicionar novo paciente
          </Button>
          <Virtuoso
            className="overflow-y-hidden"
            style={{ height, scrollbarWidth: "none" }}
            data={searchResults}
            components={{ List: ListRoot }}
            // eslint-disable-next-line react/no-unstable-nested-components -- react-virtuoso
            itemContent={(_, patient) => (
              <PatientListItem
                key={patient.codPaciente}
                onClick={onClick}
                patient={patient}
              />
            )}
          />
        </div>
      ) : (
        <EmptyStateRoot>
          <EmptyStateImage icon={DoctorWithStethoscope} alt="Lista de pacientes vazia" />
          <EmptyStateTitle>Você ainda não possui pacientes cadastrados</EmptyStateTitle>
          <EmptyStateButton onClick={onAddPatientButtonClick}>
            Adicionar novo paciente
          </EmptyStateButton>
        </EmptyStateRoot>
      )}
    </>
  );
};
