import { CpsCheckboxV2, CpsInput } from "corpus";
import { ExclamationCircle, Search } from "@repo/icons";
import { getCurrentDate, isConsultorioVirtual } from "@repo/lib";
import { type UseFormReturn } from "react-hook-form";
import { type z } from "zod";
import { useAtom } from "jotai";
import { TimeSlotSuggestionType, AppointmentPurpose } from "@repo/lib/src/enums";
import { InputPhone } from "@/components/input-phone";
import { DatePicker } from "@/components/date-picker";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";
import { InputEmail } from "@/components/input-email";
import { InputCpf } from "@/components/input-cpf";
import { AppointmentPatientFormHeader } from "@/components/appointment-patient-form-header";
import { type patientInfo } from "@/lib/form-schemas/appointment-schema";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";

const NUMBER_OF_YEARS_TO_BE_SUBTRACTED_FROM_CURRENT_YEAR = 150;

export type AppointmentPatientFormValues = z.infer<typeof patientInfo>;

interface AppointmentPatientFormBodyProps {
  unit: { id: number; name: string };
  appointmentType?: { name: string; roomTypes: { name: string }[] };
  form: UseFormReturn<AppointmentPatientFormValues>;
  onSubmit?: (formDate: AppointmentPatientFormValues) => Promise<void>;
  setConfirmationDrawer?: (value: boolean) => void;
  disabledFields?: Partial<Record<keyof AppointmentPatientFormValues, boolean>>;
  submitButtonLabel?: string;
}

export const AppointmentPatientFormBody = ({
  form,
  onSubmit,
  setConfirmationDrawer,
  unit,
  appointmentType,
  disabledFields = {},
  submitButtonLabel = "Agendar",
}: AppointmentPatientFormBodyProps): JSX.Element => {
  const [{ appointmentTime }] = useAtom(appointmentFormAtom);
  const currentYear = getCurrentDate().getFullYear();
  const initialYear = currentYear - NUMBER_OF_YEARS_TO_BE_SUBTRACTED_FROM_CURRENT_YEAR;

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit}>
        <AppointmentPatientFormHeader
          unitName={unit.name}
          appointmentType={appointmentType}
          showRoomTypes={!isConsultorioVirtual(unit.id)}
          appointmentPurpose={
            appointmentTime.timeSlotType ===
            TimeSlotSuggestionType.LimitedDurationAppointment
              ? AppointmentPurpose.HorariosMenores
              : AppointmentPurpose.AtendimentoPresencial
          }
        />
        {isConsultorioVirtual(unit.id) ? (
          <div className="bg-secondary-50 flex flex-row gap-2 p-3 rounded-lg items-center">
            <ExclamationCircle className="fill-secondary-500 shrink-0" size={24} />
            <p className="text-secondary-500 text-sm font-medium">
              Este agendamento não fará a reserva de uma sala na unidade.
            </p>
          </div>
        ) : null}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  IconLeft={Search}
                  disabled={disabledFields.name}
                  title="Nome completo"
                  required
                  placeholder="João da Silva"
                  inputMode="text"
                  type="text"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <div className="flex flex-col gap-2">
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputPhone
                    required
                    disabled={disabledFields.phone}
                    title="Telefone"
                    placeholder="(00) 00000-0000"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="sendConfirmationViaWhatsApp"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsCheckboxV2
                    defaultChecked={form.getValues("sendConfirmationViaWhatsApp")}
                    label="Enviar WhatsApp para confirmação"
                    disabled={disabledFields.sendConfirmationViaWhatsApp}
                    {...field}
                    onCheckedChange={field.onChange}
                    value={String(field.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="dateOfBirth"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <DatePicker
                  captionLayout="dropdown"
                  fromYear={initialYear}
                  disabled={disabledFields.dateOfBirth}
                  toYear={currentYear}
                  disabledFutureDates={getCurrentDate()}
                  title="Nascimento"
                  placeholder="dd/mm/aaaa"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InputEmail
                  required
                  disabled={disabledFields.email}
                  title="E-mail"
                  placeholder="Digite aqui"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="cpf"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InputCpf
                  disabled={disabledFields.cpf}
                  title="CPF"
                  placeholder="000.000.000-00"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormSubmitButton
          type={setConfirmationDrawer ? "button" : "submit"}
          onClick={() => {
            setConfirmationDrawer && setConfirmationDrawer(true);
          }}
        >
          {submitButtonLabel}
        </FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
