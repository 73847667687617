import { motion, useCycle, type Variants } from "framer-motion";
import { Left, Right } from "@repo/icons";
import { cn } from "@repo/lib";
import { type ReactElement } from "react";

interface RoomButtonOpenBarProps {
  variant: "left" | "right";
  roomName: string;
  barIsOpen: boolean;
  onClick: () => void;
}

const containerVariants = {
  closed: {
    scale: 1,
    left: "-7rem",
    marginLeft: 0,
    width: "13rem",
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  open: {
    scale: 1,
    left: 0,
    width: "90vw",
    marginLeft: "5vw",
    marginRight: "5vw",
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const getRadiusBarVariants = (isLate: boolean): Variants => ({
  closed: {
    justifyContent: "space-between",
    paddingLeft: "7.5rem",
    paddingRight: "0.5rem",
    backgroundColor: isLate ? "#B91C1C" : "#005675",
    transition: { duration: 0.3 },
  },
  open: {
    justifyContent: "space-between",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    backgroundColor: isLate ? "#B91C1C" : "#005675",
    transition: { duration: 0.3 },
  },
});

const internalVariants = {
  closed: {
    scale: 0,
    opacity: 0,
    display: "none",
    transition: { duration: 0.5 },
  },
  open: {
    scale: 1,
    opacity: 1,
    display: "flex",
    transition: { delay: 0.2, duration: 0.1 },
  },
};

const buttonLeftVariants = {
  closed: {
    scale: 0,
    opacity: 0,
    transition: { duration: 0.5 },
  },
  open: {
    scale: 1,
    opacity: 1,
    transition: { delay: 0.2, duration: 0.1 },
  },
};

const RoomButtonOpenBar = ({
  variant,
  roomName,
  barIsOpen,
  onClick,
}: RoomButtonOpenBarProps): JSX.Element => {
  const Icon = variant === "left" ? Left : Right;
  const clickableClass = variant === "left" ? "gap-2" : "";

  return (
    <div
      role="presentation"
      className={cn("flex items-center cursor-pointer", clickableClass)}
      onClick={onClick}
    >
      {variant === "left" && (
        <motion.button
          variants={buttonLeftVariants}
          initial="closed"
          animate={barIsOpen ? "open" : "closed"}
          className="bg-white/25 rounded-full p-2 hover:bg-white/10"
        >
          <Icon size={24} className="fill-white" />
        </motion.button>
      )}
      <span className="text-md whitespace-nowrap underline">{roomName}</span>
      {variant !== "left" && (
        <button type="button" className="rounded-full p-2">
          <Icon size={24} className="fill-white" />
        </button>
      )}
    </div>
  );
};

export interface AllocatedRoomBarProps {
  roomName: string;
  lateTime?: string;
  time: string;
  onClickButtonReturnRoom?: () => void;
}

export const AllocatedRoomBar = ({
  roomName,
  time,
  lateTime,
  onClickButtonReturnRoom,
}: AllocatedRoomBarProps): ReactElement => {
  const late = Boolean(lateTime);
  const [isOpen, toggleOpen] = useCycle(true, false);
  const radiusBarVariants = getRadiusBarVariants(late);
  const colorBackgroundClass = late ? "bg-danger-600" : "bg-secondary-500";

  return (
    <motion.div
      drag="x"
      dragSnapToOrigin
      dragConstraints={{ left: 0, right: 5 }}
      dragElastic={0}
      dragMomentum={false}
      className="absolute bottom-[4rem] bottom-base transform -translate-x-[15%] m-4"
      variants={containerVariants}
      initial="closed"
      onDragStart={() => toggleOpen()}
      animate={isOpen ? "open" : "closed"}
    >
      <motion.div
        variants={radiusBarVariants}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        className={cn(
          colorBackgroundClass,
          "flex items-center min-h-14 rounded-full justify-end py-2 text-white",
        )}
      >
        <RoomButtonOpenBar
          roomName={roomName}
          barIsOpen={isOpen}
          variant={isOpen ? "left" : "right"}
          onClick={() => toggleOpen()}
        />
        {isOpen ? (
          <>
            <motion.div
              variants={internalVariants}
              initial="closed"
              animate="open"
              className="flex items-center flex-col"
            >
              <span className="text-md whitespace-nowrap">{time}</span>
              {late ? (
                <span className="text-xs whitespace-nowrap">Atraso de {lateTime}</span>
              ) : null}
            </motion.div>
            <motion.button
              variants={internalVariants}
              initial="closed"
              animate="open"
              className="bg-white/25 text-white py-2 px-4 rounded-full hover:bg-white/10"
              onClick={onClickButtonReturnRoom}
            >
              Devolver
            </motion.button>
          </>
        ) : null}
      </motion.div>
    </motion.div>
  );
};
