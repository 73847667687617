import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { Link } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { FemaleDoctorWithSmartphone } from "@repo/icons";
import { cn } from "@repo/lib";
import { useEffect, useState } from "react";
import { Button } from "@/components/button";
import { SearchInput } from "@/components/search-input";
import {
  EmptyStateRoot,
  EmptyStateImage,
  EmptyStateTitle,
  EmptyStateButton,
} from "@/components/empty-state";
import { ListRoot, Item, ItemContent, ItemMainContent } from "@/components/list";

const HEALTH_INSURANCE_OTHER_OPTION_ID = 1;

export const AppointmentReceivingOptionsListQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentReceivingOptionsListQueryFragment on query_root {
    usuariosFormasRecebimentos(
      where: { ativo: { _eq: true }, codTipoFormaRecebimento: { _neq: 1 } }
    ) {
      codUsuarioFormaRecebimento
      nomeConvenio
      TipoConvenio {
        codTipoConvenio
        nome
      }
      tipoPlano {
        nome
      }
    }
  }
`);

export const AppointmentReceivingOptionsList = (props: {
  appointmentReceivingOptions: FragmentType<
    typeof AppointmentReceivingOptionsListQueryFragment
  >;
}): JSX.Element => {
  const { usuariosFormasRecebimentos } = useFragment(
    AppointmentReceivingOptionsListQueryFragment,
    props.appointmentReceivingOptions,
  );

  const [searchResults, setSearchResults] = useState(usuariosFormasRecebimentos);

  useEffect(
    function updateSearchResults() {
      setSearchResults(usuariosFormasRecebimentos);
    },
    [usuariosFormasRecebimentos],
  );

  return (
    <>
      {usuariosFormasRecebimentos.length > 0 ? (
        <>
          <SearchInput
            items={usuariosFormasRecebimentos}
            onSearch={setSearchResults}
            searchKeys={["TipoConvenio.nome", "nomeConvenio"]}
          />
          <ListRoot>
            {searchResults.map((appointmentReceivingOption) => (
              <Item
                key={appointmentReceivingOption.codUsuarioFormaRecebimento}
                linkProps={{
                  to: "/settings/schedule-options/appointment-receiving-options/$appointmentReceivingOptionId",
                  params: {
                    appointmentReceivingOptionId: Number(
                      appointmentReceivingOption.codUsuarioFormaRecebimento,
                    ),
                  },
                }}
              >
                <ItemContent>
                  <ItemMainContent>
                    {appointmentReceivingOption.TipoConvenio?.codTipoConvenio ===
                    HEALTH_INSURANCE_OTHER_OPTION_ID
                      ? appointmentReceivingOption.nomeConvenio
                      : appointmentReceivingOption.TipoConvenio?.nome}
                  </ItemMainContent>
                  <p className="text-sm">{appointmentReceivingOption.tipoPlano?.nome}</p>
                </ItemContent>
              </Item>
            ))}
          </ListRoot>
          <div
            className={cn(
              "sticky bottom-0 pb-4",
              searchResults.length === 0 ? "pt-4" : "",
            )}
          >
            <Link to="/settings/schedule-options/appointment-receiving-options/create">
              <Button fullWidth>Adicionar convênio</Button>
            </Link>
          </div>
        </>
      ) : (
        <EmptyStateRoot>
          <EmptyStateImage
            icon={FemaleDoctorWithSmartphone}
            alt="Imagem de lista de convênios vazia"
          />
          <EmptyStateTitle>Você ainda não tem convênios cadastrados</EmptyStateTitle>
          <Link
            to="/settings/schedule-options/appointment-receiving-options/create"
            className="w-full"
          >
            <EmptyStateButton fullWidth>Adicionar convênio</EmptyStateButton>
          </Link>
        </EmptyStateRoot>
      )}
    </>
  );
};
