import { isCpfValid } from "@repo/lib";
import { z } from "zod";

export const appointmentBasicInfo = z.object({
  unit: z.number().min(1, { message: "Campo obrigatório" }),
  appointmentType: z.number().min(1, { message: "Campo obrigatório" }),
  paymentMethod: z.number().min(1, { message: "Campo obrigatório" }),
  appointmentDate: z.date().optional(),
  appointmentTime: z.object({
    start: z.date(),
    end: z.date(),
    timeSlotType: z.number().optional(),
  }),
  scheduleId: z.number().optional(),
});

export const patientInfo = z.object({
  patientId: z.number().optional(),
  phone: z
    .string({ required_error: "Você precisa informar um número de telefone." })
    .min(1, { message: "Você precisa informar um número de telefone." })
    .refine(
      (value) => /^\(\d{2}\) \d{4,5}-\d{4}$/.test(value),
      "Número de telefone inválido.",
    ),
  name: z
    .string({ required_error: "Você precisa informar um nome" })
    .min(1, { message: "Você precisa informar um nome" }),
  dateOfBirth: z.date().optional(),
  email: z
    .string({ required_error: "Você precisa informar um e-mail" })
    .min(1, { message: "Você precisa informar um e-mail" })
    .email({ message: "E-mail inválido." }),
  cpf: z
    .string()
    .refine((value) => !value || isCpfValid(value), "CPF inválido.")
    .optional(),
  sendConfirmationViaWhatsApp: z.boolean(),
  roomTypes: z.number().array().optional(),
});

export const flexiblePricingInfo = z.object({
  appointmentMode: z.number().optional(),
});

export const rescheduleInfo = z.object({
  appointmentId: z.number().optional(),
});

export const appointmentFormSchema = appointmentBasicInfo
  .merge(patientInfo)
  .merge(rescheduleInfo)
  .merge(flexiblePricingInfo);
