import { createFileRoute, redirect } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { ReservedScheduleTypeEnum } from "@repo/lib/src/enums";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { ensureQueryData, useGraphQL } from "@/hooks/use-graphql";
import { getIP } from "@/lib/ip-getter";
import { ReservedScheduleForm } from "@/components/reserved-schedule-form";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";

const HasUserAcceptedTermsOfUseQuery = graphql(/* GraphQL */ `
  query HasUserAcceptedTermsOfUseQuery($codTipoTermoDeUso: Int!) {
    tbUsuariosTermosDeUso(
      where: { ativo: { _eq: true }, codTipoTermoDeUso: { _eq: $codTipoTermoDeUso } }
    ) {
      codTermosDeUso
      codUsuario
      ativo
      dataCadastro
      codTipoTermoDeUso
      dataUltimaAtualizacao
    }
  }
`);

const GetLocationsQuery = graphql(/* GraphQL */ `
  query GetLocationsQuery {
    ...GetLocationsFragment
  }
`);

export const CreateReservedSchedulePage = (): JSX.Element => {
  const queryResult = useGraphQL(GetLocationsQuery);

  const { data } = queryResult;
  const routerContext = Route.useLoaderData();
  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Criar período garantido" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <ReservedScheduleForm
              locationsFragment={data}
              reservedScheduleType={routerContext.reservedScheduleType}
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/reserved-schedules/create/")({
  component: CreateReservedSchedulePage,
  beforeLoad: ({ context }) => {
    return {
      reservedScheduleTypeByUser: context.ldClient.getReservedScheduleTypeByUser(),
    };
  },
  loader: async ({ context }) => {
    let reservedScheduleType = context.reservedScheduleTypeByUser;
    if (
      context.user.codParceiroPortoSeguro &&
      reservedScheduleType !== ReservedScheduleTypeEnum.Porto.valueOf()
    ) {
      reservedScheduleType = ReservedScheduleTypeEnum.Porto.valueOf();
    }

    const variables = { codTipoTermoDeUso: reservedScheduleType };

    const data = await ensureQueryData(
      context,
      HasUserAcceptedTermsOfUseQuery,
      variables,
    );

    if (data.tbUsuariosTermosDeUso.length === 0) {
      redirect({ to: "/settings/reserved-schedules/terms-of-use", throw: true });
    }

    const memberIP = await getIP();
    return { reservedScheduleType, memberIP };
  },
});
