import { useRouteContext } from "@tanstack/react-router";
import { useState, useEffect } from "react";

export function useMinimumAntecedenceFlexiblePrice(): number {
  const [minimumAntecedence, setMinimumAntecedence] = useState<number>(0);

  const { ldClient } = useRouteContext({ strict: false });

  useEffect(() => {
    const featureFlagValue =
      ldClient.getMinimumAntecedenceForFlexiblePriceAppointmentCreation();

    setMinimumAntecedence(featureFlagValue);
  }, [ldClient]);

  return minimumAntecedence;
}
