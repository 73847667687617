import type { FragmentType } from "@repo/graphql-types";
import type { MovimentacaoExibicao as NewBillingItemFragment } from "@repo/graphql-types/graphql";
import {
  NewBillingItem,
  type NewBillingItemQueryFragment,
} from "@/components/new-billing-item";
import { ListRoot } from "@/components/list/index";

interface NewBillingItemListProps {
  billingItens: FragmentType<typeof NewBillingItemQueryFragment>[];
  currentBillingIndex: number;
}

export const NewBillingItemList = ({
  billingItens,
  currentBillingIndex,
}: NewBillingItemListProps): JSX.Element => {

  const orderedBillingItens = (): FragmentType<typeof NewBillingItemQueryFragment>[] => {
    const orderedList = Object.values(billingItens).sort((a, b) => {
      return new Date((a as NewBillingItemFragment).dataCadastro).getTime() <
        new Date((b as NewBillingItemFragment).dataCadastro).getTime()
        ? 1
        : -1;
    });

    return orderedList;
  };


  return (
    <ListRoot>
      {Object.values(orderedBillingItens()).map((billingItem) => (
        <div
          className="mt-2"
          key={`billing-list-item-${
            (billingItem as NewBillingItemFragment).codMovimentacao
          }-${currentBillingIndex}`}
        >
          <NewBillingItem billingItem={billingItem} />
        </div>
      ))}
    </ListRoot>
  );
};
