import { type SVGIconProps } from "@repo/icons";
import { type FunctionComponent } from "react";
import { type LinkProps } from "@tanstack/react-router";
import { cn } from "@repo/lib";
import { CpsBadge } from "corpus";
import { ListRoot, Item, ItemMainContent } from "@/components/list";
import { trackEvent } from "@/lib/tracking.ts";

export interface MenuListItem {
  name: string;
  Icon: FunctionComponent<SVGIconProps>;
  route?: LinkProps["to"];
  externalRoute?: string;
  showTag?: boolean;
  target?: "_blank" | "_self";
}

export interface MenuListProps {
  menuItems: MenuListItem[];
  title?: string;
}

export const MenuList = ({ menuItems, title }: MenuListProps): JSX.Element => {
  const handleClickEvent = (label: string): void => {
    trackEvent("Item de Menu Clicado", {
      label,
      page: "/settings",
    });
  };
  return (
    <>
      {title ? <h3 className="text-md font-normal text-neutral-700 pt-3">{title}</h3> : null}
      <ListRoot className={cn(title && "pl-6")}>
        {menuItems.map(
          ({ Icon, name, route, externalRoute, showTag = false, target = "_self" }) => (
            <Item
              key={name}
              linkProps={{ to: route, target, preload: false }}
              externalRoute={externalRoute}
              onClick={() => handleClickEvent(String(name))}
            >
              <Icon className="fill-neutral-300 text-center" size={24} />
              <ItemMainContent
                className={cn(
                  "text-neutral-700 text-md",
                  showTag && "flex flex-row justify-between w-full",
                )}
              >
                <p>{name}</p>
                {showTag ? <CpsBadge color="helper">Novo</CpsBadge> : null}
              </ItemMainContent>
            </Item>
          ),
        )}
      </ListRoot>
    </>
  );
};
