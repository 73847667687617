import { Users } from "@repo/icons";
import { Link } from "@tanstack/react-router";
import { CpsButtonText } from "corpus";

interface RecommendationMembersForPatientsButtonProps {
  patientId: number;
}

export const RecommendationMembersForPatientsButton = ({
  patientId,
}: RecommendationMembersForPatientsButtonProps): JSX.Element => {
  return (
    <div>
      <Link to="/recommendation/" search={{ patientId }}>
        <CpsButtonText className="flex flex-row gap-1 p-0" Icon={Users} color="secondary">
          Indicar profissional
        </CpsButtonText>
      </Link>
    </div>
  );
};
