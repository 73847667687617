import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { OneLivFAQForm } from "@/components/oneliv/oneliv-faq-form";

const GetOneLivUserFAQ = graphql(`
  query GetOneLivUserFAQ {
    locus_membro {
      ...OneLivFAQFormFragment
    }
  }
`);

const oneLivFAQPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const OneLivFAQ = (): JSX.Element => {
  const query = useGraphQL(GetOneLivUserFAQ);

  const { data } = query;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Perguntas frequentes" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...query}>
          {data ? <OneLivFAQForm data={data.locus_membro} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/oneliv/faq")({
  component: OneLivFAQ,
  validateSearch: oneLivFAQPageSearchSchema,
});
