import { graphql } from "@repo/graphql-types/gql";
import { Left, Check } from "@repo/icons";
import {
  createFileRoute,
  useLoaderData,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { useState } from "react";
import { useGraphQL, useGraphQLMutation } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import {
  PlanMigrationSelectorRoot,
  PlanMigrationSelectorList,
  PlanMigrationSelectorItem,
} from "@/components/plan-migration-selector";
import {
  isPlanValidForMigration,
  getPlanDisplayNameForList,
  getPlanBenefits,
  getFilteredSortedPlans,
  type Plan,
} from "@/lib/plans";
import { CurrentPlanCard } from "@/components/current-plan-card";
import { trackEvent } from "@/lib/tracking";
import { Button } from "@/components/button";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { SuccessChangePlanDrawer } from "@/components/success-change-plan-drawer";
import { ChangePlanAlert } from "@/components/change-plan-alert";

const psychoanalysisSpecialtyId = 57;
const psychologySpecialtyId = 58;
const psychopedagogySpecialtyId = 70;

const ChangePlanPageQuery = graphql(/* GraphQL */ `
  query ChangePlanPageQuery {
    ...CurrentPlanCardFragment
    ...ChangePlanAlertFragment
    usuarioPlano: usuariosProfissionaisPlanos(where: { ativo: { _eq: true } }) {
      plano {
        nome
        valor
        temFidelidade
      }
      dataInicio
    }
    planos(where: { ativo: { _eq: true } }) {
      codPlano
      nome
      valor
      temFidelidade
    }
    usuariosProfissionais {
      dataValidacao
    }
    usuariosEspecialidades {
      codEspecialidade
    }
  }
`);

const UpdateUsuarioPlanoMigracaoMutation = graphql(`
  mutation LivanceApiUsuarioPlanoMigracao($input: LivanceApiUsuarioPlanoMigracaoInput!) {
    LivanceApiUsuarioPlanoMigracao(arg1: $input) {
      codPlano
    }
  }
`);

export const ChangePlanPage = (): JSX.Element => {
  const { user, queryClient, flags } = useRouteContext({ strict: false });
  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/profile/change-plan/",
  });

  const routerContext = useLoaderData({
    from: "/settings/profile/change-plan/",
  });
  const enableFullPlansForPsico = routerContext.enableFullPlansForPsico;

  const queryResult = useGraphQL(ChangePlanPageQuery);
  const { data } = queryResult;

  const {
    planos = [],
    usuarioPlano = [],
    usuariosProfissionais = [],
    usuariosEspecialidades = [],
  } = data ?? {};

  const [isChecked, setIsChecked] = useState(false);

  const currentPlan =
    usuarioPlano.length > 0
      ? usuarioPlano[0].plano
      : { nome: "", valor: 0, temFidelidade: false };
  const currentPlanRegistrationDate =
    usuarioPlano.length > 0 ? usuarioPlano[0].dataInicio : null;

  const memberRegistrationDate =
    usuariosProfissionais.length > 0 ? usuariosProfissionais[0].dataValidacao : null;
  const memberIsPsico = usuariosEspecialidades.some((especialidade) =>
    [
      psychoanalysisSpecialtyId,
      psychologySpecialtyId,
      psychopedagogySpecialtyId,
    ].includes(especialidade.codEspecialidade),
  );
  const memberRegistrationDateCheckingFlag = flags[
    "deve-considerar-plano-mensal-como-permanencia-de-3-meses"
  ]
    ? memberRegistrationDate
    : "";

  const disabled = (plano: Plan): boolean => {
    return !isPlanValidForMigration(
      plano,
      currentPlan,
      memberRegistrationDate ?? "",
      currentPlanRegistrationDate ?? "",
      memberIsPsico,
      enableFullPlansForPsico,
    );
  };

  const sortedPlans = getFilteredSortedPlans(
    planos,
    currentPlan,
    memberRegistrationDate ?? "",
    currentPlanRegistrationDate ?? "",
    memberIsPsico,
    enableFullPlansForPsico,
  );

  const [checkedPlan, setCheckedPlan] = useState(0);
  const handlePlanChange = (value: number): void => {
    if (checkedPlan === value) {
      setCheckedPlan(0);
    } else {
      trackEvent("Opção de Plano Selecionado", {
        codUsuario: user.codUsuario,
        codPlano: value,
        label: "Alterar Plano",
        page: "/settings/profile/change-plan/",
      });
      setCheckedPlan(value);
    }
  };

  const { mutateAsync: updateUsuarioPlanoMigracaoAsync, isPending } = useGraphQLMutation(
    UpdateUsuarioPlanoMigracaoMutation,
  );

  const onSuccess = (): void => {
    setCheckedPlan(0);
    setIsChecked(false);
    void queryClient.resetQueries({ queryKey: ["MyProfilePageQuery"] });
    handleSuccessDrawer(true);
  };

  const handleSuccessDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/profile/change-plan/",
        search: { action: "change-plan-success" },
      });
    } else if (searchParams.action === "change-plan-success") {
      router.history.go(-2);
    }
  };

  const onError = (): void => setShowUnexpectedErrorDrawer(true);

  const setShowUnexpectedErrorDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/profile/change-plan/",
        search: { action: "error" },
      });
    } else if (searchParams.action === "error") {
      router.history.back();
    }
  };

  const updateUsuarioPlanoOption = async (): Promise<void> => {
    await updateUsuarioPlanoMigracaoAsync(
      {
        input: {
          codUsuario: user.codUsuario,
          codPlano: checkedPlan,
        },
      },
      {
        onSuccess,
        onError,
      },
    );
  };

  const handlePlanOptionChange = (plan: Plan): void => {
    if (disabled(plan)) {
      return;
    }

    handlePlanChange(plan.codPlano ?? 0);

    const buttonRef = document.getElementById("change-plan-button");
    if (buttonRef) buttonRef.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Alterar Plano" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col justify-center items-stretch gap-2 py-4">
                <p className="font-sm font-medium text-neutral-600">Plano atual</p>
                <CurrentPlanCard
                  data={data}
                  memberRegistrationDate={memberRegistrationDateCheckingFlag ?? ""}
                />
                <ChangePlanAlert
                  data={data}
                  disableFullPlansForPsico={!enableFullPlansForPsico}
                />
              </div>
              <div>
                <p className="font-sm font-medium text-neutral-600">Escolha seu plano</p>
                <PlanMigrationSelectorRoot name="plan-migration">
                  <PlanMigrationSelectorList>
                    {sortedPlans.map((plan) => (
                      <PlanMigrationSelectorItem
                        key={`${plan.nome}-${plan.valor}`}
                        isChecked={checkedPlan === plan.codPlano}
                        value={plan.codPlano}
                        onChange={() => handlePlanOptionChange(plan)}
                        data={plan}
                        displayName={getPlanDisplayNameForList(plan.nome)}
                        disabled={disabled(plan)}
                        benefits={getPlanBenefits(
                          plan.temFidelidade,
                          memberIsPsico,
                          plan.nome.includes("Light"),
                          memberRegistrationDateCheckingFlag ?? "",
                        )}
                      />
                    ))}
                  </PlanMigrationSelectorList>
                </PlanMigrationSelectorRoot>
              </div>
              <label className="relative flex flex-row items-center gap-2 border-neutral-100 pb-4 last:pb-0 cursor-pointer group">
                <div className="relative flex items-center">
                  <input
                    type="checkbox"
                    className="w-[20px] h-[20px] border-[2px] border-neutral-200 rounded-lg group-has-[:checked]:bg-secondary-400 group-has-[:checked]:border-secondary-400 appearance-none flex items-center justify-center focus:outline-none bg-white"
                    name="agree-terms"
                    onChange={() => setIsChecked((prev) => !prev)}
                    checked={isChecked}
                  />
                  <span className="absolute inset-0 flex items-center justify-center group group-has-[:checked]:text-white">
                    <Check size={16} className="fill-white" />
                  </span>
                </div>
                <span className="text-neutral-500 font-medium text-sm">
                  Li e aceito os{" "}
                  <a
                    href="https://ajuda.livance.com.br/hc/pt-br/articles/30982622126739-Condi%C3%A7%C3%B5es-Gerais-para-Migra%C3%A7%C3%A3o-de-Planos-de-Mensalidade-da-Livance"
                    target="_blank"
                    className="text-helper-600 underline"
                    rel="noopener"
                  >
                    termos e condições
                  </a>
                </span>
              </label>
              <Button
                id="change-plan-button"
                fullWidth
                disabled={!isChecked || checkedPlan === 0}
                onClick={() => {
                  void updateUsuarioPlanoOption();
                }}
                loading={isPending}
              >
                Alterar Plano
              </Button>

              <UnexpectedErrorDrawer
                open={searchParams.action === "error"}
                setOpen={setShowUnexpectedErrorDrawer}
              />
              <SuccessChangePlanDrawer
                open={searchParams.action === "change-plan-success"}
                setOpen={handleSuccessDrawer}
                onClickProfile={() => {
                  router.history.go(-2);
                }}
                onClickSchedule={() => void router.navigate({ to: "/" })}
              />
            </div>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/profile/change-plan/")({
  component: ChangePlanPage,
  beforeLoad: ({ context }) => {
    return {
      enableFullPlansForPsico: context.ldClient.getEnableFullPlansForPsico(),
    };
  },
  loader: ({ context }) => {
    const enableFullPlansForPsico = context.enableFullPlansForPsico;
    return { enableFullPlansForPsico };
  },
});
