export interface Plan {
  codPlano?: number;
  nome: string;
  valor: number;
  temFidelidade: boolean;
}

/**
 * Recebe uma data de registro do plano anual e retorna se está no primeiro mês de fidelidade.
 *
 * @param planRegistrationDate - A data de registro do plano anual.
 * @returns true ou false, indicando se o plano está no primeiro mês de fidelidade ou não
 */
export const isInFirstMonthOfAnnualPlan = (planRegistrationDate: string): boolean => {
  const today = new Date();
  const registrationDate = new Date(planRegistrationDate);
  registrationDate.setFullYear(today.getFullYear());

  const startValidationDate = new Date(registrationDate);
  const endValidationDate = new Date(
    registrationDate.setMonth(registrationDate.getMonth() + 1),
  );

  return today >= startValidationDate && today <= endValidationDate;
};

/**
 * Recebe um plano e informações sobre o plano atual e retorna se o plano é válido ou não para migração de plano.
 *
 * @param plano - O plano a ser verificado.
 * @param currentPlan - O plano atual do usuário.
 * @param memberRegistrationDate - A data de registro do usuário.
 * @param currentPlanRegistrationDate - A data de registro do plano atual do usuário.
 * @param memberIsPsico - Indica se o usuário é um psicólogo/psicanalista/psicopedagogo.
 * @param enableFullPlansForPsico - Indica se os planos Full estão habilitados para psicólogos/psicanalistas/psicopedagogos.
 * @returns true ou false, indicando se o plano é válido ou não.
 */
export const isPlanValidForMigration = (
  plan: Plan,
  currentPlan: Plan | null | undefined,
  memberRegistrationDate: string,
  currentPlanRegistrationDate: string,
  memberIsPsico: boolean,
  enableFullPlansForPsico: boolean,
): boolean => {
  const limitDate = "2023-04-24";

  const isMemberRegisteredAfterLimitDate =
    new Date(memberRegistrationDate) >= new Date(limitDate);

  const isLightPlan = plan.nome.includes("Light");
  const isAnnualPlan = plan.temFidelidade;

  const currentPlanIsLightPlan = Boolean(currentPlan?.nome.includes("Light"));
  const currentPlanIsAnnual = Boolean(currentPlan?.temFidelidade);

  const isInFirstMonth = isInFirstMonthOfAnnualPlan(currentPlanRegistrationDate);

  if (!enableFullPlansForPsico && memberIsPsico) {
    return isPlanValidForPsychologistMemberMigration(
      isLightPlan,
      currentPlanIsLightPlan,
      isMemberRegisteredAfterLimitDate,
      isAnnualPlan,
      currentPlanIsAnnual,
      isInFirstMonth,
    );
  }

  if (currentPlanIsAnnual) {
    return isInFirstMonth || isAnnualPlan;
  }

  return true;
};

const isPlanValidForPsychologistMemberMigration = (
  isLightPlan: boolean,
  currentPlanIsLightPlan: boolean,
  isMemberRegisteredAfterLimitDate: boolean,
  isAnnualPlan: boolean,
  currentPlanIsAnnual: boolean,
  isInFirstMonth: boolean,
): boolean => {
  if (isMemberRegisteredAfterLimitDate && currentPlanIsLightPlan) {
    if ((currentPlanIsAnnual && isInFirstMonth) || !currentPlanIsAnnual) {
      return isLightPlan;
    }

    return false;
  }
  if (currentPlanIsAnnual && !isInFirstMonth) {
    return isAnnualPlan;
  }
  return true;
};

/**
 * Recebe um nome de um plano cadastrado no banco de dados e retorna o nome que será exibido na tela.
 *
 * @param planName - O nome do plano.
 * @returns nome de exibição do plano na tela.
 */
export const getPlanDisplayNameForList = (planName: string): string => {
  return planName.includes("Light") ? "Plano Light" : "Plano Full";
};

/**
 * Recebe dados de um plano e retorna os pontos de destaque do plano.
 *
 * @param hasFidelity - Indica se o plano tem fidelidade.
 * @param memberIsPsico - Indica se o usuário é um psicólogo/psicanalista/psicopedagogo.
 * @param isLightPlan - Indica se o plano é um plano Light.
 * @param memberRegistrationDate - A data de registro/ativação do usuário.
 * @returns array com benefícios/destaques do plano.
 */
export const getPlanBenefits = (
  hasFidelity: boolean,
  memberIsPsico: boolean,
  isLightPlan: boolean,
  memberRegistrationDate: string,
): string[] => {
  const benefits = [];

  benefits.push(
    hasFidelity
      ? "Compromisso de 12 meses de utilização."
      : getMonthlyPlanRetentionText(memberRegistrationDate),
  );

  if (isLightPlan) {
    benefits.push(
      `Horários de uso da sala de segunda a sexta: ${memberIsPsico ? "07h-14h e 20h-22h" : "07h-10h, 12h-14h e 18h-22h"}. Sábados: horário integral`,
    );
  }

  return benefits;
};

/**
 * Recebe dados de um plano e retorna o nome de exibição do plano para os cards de plano atual
 *
 * @param planName - O nome do plano.
 * @returns O nome do plano para exibição no card.
 */
export const getExhibitionName = (planName: string): string => {
  if (planName.includes("Alice") || planName.includes("Psico")) {
    return planName;
  }
  return planName.includes("Light") ? "Plano Light" : "Plano Full";
};

/**
 * Recebe o dia de pagamento da próxima cobrança e retorna a data da próxima cobrança.
 *
 * @param paymentDueDay - O dia de pagamento da próxima cobrança.
 * @param validationDay - A data de validação do membro.
 * @returns uma string com a data formatada da próxima cobrança
 */
export const getNextPaymentDate = (
  paymentDueDay: number,
  validationDay: string,
): string => {
  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const validationDate = new Date(validationDay);
  const isValidateInTheLastSevenDays = validationDate >= sevenDaysAgo;

  let dueDate;
  const paymentDay = String(paymentDueDay).padStart(2, "0");

  if (currentDay <= paymentDueDay) {
    const formattedCurrentMonth = String(currentMonth + 1).padStart(2, "0");
    dueDate = `${paymentDay}/${formattedCurrentMonth}/${currentYear}`;
  } else {
    const nextMonth = String(
      (currentMonth + (isValidateInTheLastSevenDays ? 3 : 2)) % 12,
    ).padStart(2, "0");
    const nextYear = currentMonth + 2 > 12 ? currentYear + 1 : currentYear;

    dueDate = `${paymentDay}/${nextMonth}/${nextYear}`;
  }

  return dueDate;
};

/**
 * Recebe uma lista de planos e dados do plano atual e retorna a lista de planos filtrada e organizada.
 *
 * @param plans - A lista de planos disponíveis.
 * @param currentPlan - O plano atual do usuário.
 * @param memberRegistrationDate - A data de registro/ativação do usuário.
 * @param currentPlanRegistrationDate - A data de registro/ativação do plano atual do usuário.
 * @param memberIsPsico - Indica se o usuário é um psicólogo/psicanalista/psicopedagogo.
 * @returns array com planos filtrados e ordenados, com os planos Alice e Psico Light excluídos.
 */
export const getFilteredSortedPlans = (
  plans: Plan[],
  currentPlan: Plan,
  memberRegistrationDate: string,
  currentPlanRegistrationDate: string,
  memberIsPsico: boolean,
  enableFullPlansForPsico: boolean,
): Plan[] => {
  const filteredPlans = plans.filter((plan) => {
    const isCurrentPlan = currentPlan.nome === plan.nome;
    const isAlicePlan = plan.nome.includes("Alice");
    const isPsicoLight = plan.nome.includes("Psico Light");

    return !isAlicePlan && !isCurrentPlan && !isPsicoLight;
  });

  // sorting plans to show valid plans first
  const sortedPlans = filteredPlans.sort((a, b) => {
    const isDisabledA = !isPlanValidForMigration(
      a,
      currentPlan,
      memberRegistrationDate,
      currentPlanRegistrationDate,
      memberIsPsico,
      enableFullPlansForPsico,
    );
    const isDisabledB = !isPlanValidForMigration(
      b,
      currentPlan,
      memberRegistrationDate,
      currentPlanRegistrationDate,
      memberIsPsico,
      enableFullPlansForPsico,
    );

    if (!isDisabledA && isDisabledB) {
      return -1;
    } else if (isDisabledA && !isDisabledB) {
      return 1;
    }
    return 0;
  });

  return sortedPlans;
};

/**
 * Retorna o devido texto de permanência do plano mensal
 *
 * @param memberRegistrationDate - A data de registro/ativação do usuário.
 * @returns - Retorna um texto a respeito do tempo de permanência do plano mensal.
 */
export const getMonthlyPlanRetentionText = (memberRegistrationDate: string): string => {
  const withoutGracePeriod = "Sem prazo de permanência mínima.";
  const withGracePeriod = "Permanência mínima de 3 meses.";

  if (memberRegistrationDate) {
    const hasPassedGracePeriod =
      hasUserRegistrationPassedGracePeriod(memberRegistrationDate);

    return hasPassedGracePeriod ? withoutGracePeriod : withGracePeriod;
  }

  return withoutGracePeriod;
};

/**
 * Verifica se a data de validação do usuário já ultrapassou o período de carência do plano mensal
 *
 * @param memberRegistrationDate - A data de registro/ativação do usuário.
 * @returns - Retorna verdadeiro se a data de registro do usuário é mais antiga que três meses, do contrário, retorna falso.
 */
const hasUserRegistrationPassedGracePeriod = (
  memberRegistrationDate: string,
): boolean => {
  const monthlyPlanGracePeriod = 3;
  const registrationDate = new Date(memberRegistrationDate);

  const today = new Date();

  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(today.getMonth() - monthlyPlanGracePeriod);

  threeMonthsAgo.setHours(0, 0, 0, 0);
  registrationDate.setHours(0, 0, 0, 0);

  return registrationDate.getTime() <= threeMonthsAgo.getTime();
};
