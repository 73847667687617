import { FemaleDoctorWithClipboard } from "@repo/icons";
import { Link } from "@tanstack/react-router";
import {
  EmptyStateRoot,
  EmptyStateImage,
  EmptyStateTitle,
  EmptyStateButton,
} from "@/components/empty-state";

export const NoAppointmentTypesSchedulesEmptyState = (): JSX.Element => {
  return (
    <EmptyStateRoot>
      <EmptyStateImage
        icon={FemaleDoctorWithClipboard}
        alt="Imagem do empty state da lista de agendas quando não houver tipos de atendimento configurados"
      />
      <EmptyStateTitle>
        Para criar uma agenda, é preciso criar um tipo de atendimento
      </EmptyStateTitle>
      <Link to="/settings/schedule-options/appointment-types/create" className="w-full">
        <EmptyStateButton fullWidth>Criar tipo de atendimento</EmptyStateButton>
      </Link>
    </EmptyStateRoot>
  );
};
