import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useResetAtom } from "jotai/utils";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatIgnoringTimezone, stringOrUndefined } from "@repo/lib";
import {
  useNavigate,
  useParams,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { Users } from "@repo/icons";
import { useAtom } from "jotai";
import {
  type AppointmentPatientFormValues,
  AppointmentPatientFormBody,
} from "@/components/appointment-patient-form-body";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { patientInfo } from "@/lib/form-schemas/appointment-schema";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql";
import { CreatedAppointmentDrawer } from "@/components/created-appointment-drawer";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
  MessageDrawerActions,
  MessageDrawerActionButton,
} from "@/components/message-drawer";
import { AppointmentRepeatedPatientErrorDrawer } from "@/components/appointment-repeated-patient-error-drawer.tsx";
import { isRepeatedPatientError } from "@/lib/repeated-patient-error.ts";
import { trackEvent } from "@/lib/tracking.ts";

export const AppointmentPatientSwapFormFragment = graphql(/* GraphQL */ `
  fragment AppointmentPatientSwapFormFragment on agendamentos {
    codAgendamento
    codUnidade
    codUsuarioCompromisso
    codUsuarioFormaRecebimento
    data
    nome
    nomeConvenio
    nomePlano
    numeroCarteirinha
    validadeCarteirinha
    horaInicio
    horaFim
    dataNascimento
    confirmado
    cobraCancelamento
    cancelado
    telefone
    codUsuarioAgenda
    telefoneProprio
    email
    ignorarEnvioEmail
    ignorarEnvioWhatsApp
    codFinalidadeAgendamento
    Unidade {
      nomeLimpo
    }
    UsuarioCompromisso {
      nome
      UsuariosCompromissosTiposSalas {
        TipoSala {
          nome
          codTipoSala
        }
      }
    }
  }
`);

const PatientSwapMutation = graphql(`
  mutation PatientSwapMutation($input: TrocarPacienteInput!) {
    trocarPaciente(input: $input) {
      data: agendamentoApiOutput {
        codAgendamento
      }
      errors {
        ... on ValidationError {
          __typename
          message
        }
        ... on RepeatedPatientError {
          __typename
          message
        }
      }
    }
  }
`);

interface AppointmentPatientSwapFormProps {
  queryData: FragmentType<typeof AppointmentPatientSwapFormFragment>;
}
export const AppointmentPatientSwapForm = ({
  queryData,
}: AppointmentPatientSwapFormProps): JSX.Element => {
  const queryResult = useFragment(AppointmentPatientSwapFormFragment, queryData);

  const resetPatientSwapAppointmentForm = useResetAtom(appointmentFormAtom);

  const [{ name, email, phone, patientId, cpf, dateOfBirth }] =
    useAtom(appointmentFormAtom);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(PatientSwapMutation);

  const { user } = useRouteContext({ from: "/appointment/$appointmentId/patient-swap" });

  const searchParams = useSearch({ from: "/appointment/$appointmentId/patient-swap" });

  const { appointmentId } = useParams({
    from: "/appointment/$appointmentId/patient-swap",
  });

  const navigate = useNavigate();

  const router = useRouter();

  const form = useForm<AppointmentPatientFormValues>({
    resolver: zodResolver(patientInfo),
    defaultValues: {
      name,
      email,
      phone,
      patientId,
      cpf: stringOrUndefined(cpf),
      dateOfBirth,
      sendConfirmationViaWhatsApp: true,
    },
  });

  const { UsuarioCompromisso, Unidade, codUnidade, ...otherAppointmentFields } =
    queryResult;

  const navigateWithSearch = (
    action: "success" | "error" | "confirmation" | "error-repeated-patient",
  ): void => {
    void navigate({
      to: "/appointment/$appointmentId/patient-swap",
      params: { appointmentId },
      search: {
        action,
      },
      replace: action === "error-repeated-patient",
    });
  };

  const onSubmit = async (): Promise<void> => {
    const onError = (error: Error): void => {
      const isRepeatedPatient = isRepeatedPatientError(error);

      if (isRepeatedPatient) {
        trackEvent("Alerta Paciente Repetido Visualizado", {
          codUnidade,
          codUsuario: user.codUsuario,
          codPaciente: patientId ?? 0,
          codTipoSala:
            UsuarioCompromisso?.UsuariosCompromissosTiposSalas.map(
              (item) => item.TipoSala.codTipoSala,
            ) ?? [],
          telaOrigem: "Troca de paciente",
          data: formatIgnoringTimezone(new Date(otherAppointmentFields.data)),
          codAgendamento: appointmentId,
          nomePaciente: form.getValues().name,
          telefone: form.getValues().phone,
        });

        navigateWithSearch("error-repeated-patient");
      }
    };

    const onSuccess = (): void => {
      navigateWithSearch("success");
    };

    const {
      email: formEmail,
      name: formName,
      phone: formPhone,
      sendConfirmationViaWhatsApp: formSendConfirmationViaWhatsApp,
      cpf: formCpf,
      dateOfBirth: formDateOfBirth,
    } = form.getValues();

    await mutateAsync(
      {
        input: {
          codAgendamentoOrigem: appointmentId,
          remarcarNovoAgendamento: {
            codUnidade,
            codUsuario: user.codUsuario,
            email: formEmail,
            nome: formName,
            telefone: formPhone,
            cpf: formCpf,
            dataNascimento: formDateOfBirth
              ? formatIgnoringTimezone(formDateOfBirth)
              : null,
            receberWhatsAppConfirmacao: formSendConfirmationViaWhatsApp,
            eventual: false,
            codPaciente: patientId ?? null,
            confirmado: otherAppointmentFields.confirmado,
            cancelado: otherAppointmentFields.cancelado,
            cobraCancelamento: otherAppointmentFields.cobraCancelamento,
            codUsuarioCompromisso: otherAppointmentFields.codUsuarioCompromisso,
            codUsuarioFormaRecebimento: otherAppointmentFields.codUsuarioFormaRecebimento,
            data: otherAppointmentFields.data,
            horaInicio: otherAppointmentFields.horaInicio,
            horaFim: otherAppointmentFields.horaFim,
            codUsuarioAgenda: otherAppointmentFields.codUsuarioAgenda,
            codFinalidadeAgendamento: otherAppointmentFields.codFinalidadeAgendamento,
            ignorarEnvioEmail: otherAppointmentFields.ignorarEnvioEmail,
            ignorarEnvioWhatsApp: otherAppointmentFields.ignorarEnvioWhatsApp,
          },
        },
      },
      { onSuccess, onError },
    );
  };

  const navigateToHomePage = (): void => void navigate({ to: "/" });

  const handleSuccessDrawerDismiss = (value: boolean): void => {
    if (!value) {
      resetPatientSwapAppointmentForm();
      form.reset();
      navigateToHomePage();
    }
  };

  const handleConfirmationDrawerDismiss = (value: boolean): void => {
    if (!value && searchParams.action === "confirmation") {
      router.history.back();
    }
  };

  const handleRepeatedPatientErrorDrawerDismiss = (value: boolean): void => {
    const patientsPageIndex = -2;
    if (!value && searchParams.action === "error-repeated-patient") {
      router.history.go(patientsPageIndex);
    }
  };

  const roomTypes =
    UsuarioCompromisso?.UsuariosCompromissosTiposSalas.map((item) => {
      return {
        name: item.TipoSala.nome,
      };
    }) ?? [];

  return (
    <>
      <AppointmentPatientFormBody
        form={form}
        unit={{ id: codUnidade, name: Unidade.nomeLimpo ?? "" }}
        appointmentType={{
          name: UsuarioCompromisso?.nome ?? "",
          roomTypes,
        }}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- react hook forms
        setConfirmationDrawer={async () => {
          await form.trigger();
          if (form.formState.isValid) {
            navigateWithSearch("confirmation");
          }
        }}
        submitButtonLabel="Trocar paciente"
        disabledFields={{ name: name.length > 0 }}
      />

      <MessageDrawerRoot
        open={searchParams.action === "confirmation"}
        setOpen={handleConfirmationDrawerDismiss}
        icon={Users}
        variant="secondary"
      >
        <MessageDrawerTitle>Trocar paciente</MessageDrawerTitle>
        <MessageDrawerBody>
          <p>
            Ao realizar a alteração, o paciente será notificado sobre o cancelamento via
            e-mail.
          </p>{" "}
          <p>Deseja prosseguir com a alteração?</p>
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- react hook forms
            onClick={async () => {
              await form.handleSubmit(onSubmit)();
            }}
            loading={form.formState.isSubmitting}
          >
            Confirmar
          </MessageDrawerActionButton>
          <MessageDrawerActionButton secondary onClick={() => router.history.back()}>
            Cancelar
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>

      <CreatedAppointmentDrawer
        setOpen={handleSuccessDrawerDismiss}
        title="Paciente alterado"
        description="O paciente receberá um e-mail com os dados do agendamento."
        confirmButtonText="Ir para minha agenda"
      />

      <AppointmentRepeatedPatientErrorDrawer
        open={searchParams.action === "error-repeated-patient"}
        setOpen={handleRepeatedPatientErrorDrawerDismiss}
      />
    </>
  );
};
