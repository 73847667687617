import { graphql, type FragmentType, useFragment } from "@repo/graphql-types";
import { MapDaysOfWeekToFullName } from "@repo/lib";
import { Item } from "@/components/list";
import { formatTimeHourMinute, parseTime } from "@/lib/time";

interface ReservedScheduleListItemProps {
  data: FragmentType<typeof ReservedScheduleListItemQueryFragment>;
}

export const ReservedScheduleListItemQueryFragment = graphql(/* GraphQL */ `
  fragment ReservedScheduleListItemQueryFragment on tbPeriodosGarantidosRecorrentes {
    codPeriodoGarantidoRecorrente
    diaDaSemana
    horaFim
    horaInicio
    PeriodosGarantidos(
      where: {
        Agendamento: {
          cancelado: { _eq: false }
          _or: [
            { data: { _gt: $dataAgendamento } }
            {
              _and: [
                { data: { _eq: $dataAgendamento } }
                { horaInicio: { _gte: $horaInicio } }
              ]
            }
          ]
        }
        ativo: { _eq: true }
      }
    ) {
      ativo
      codPeriodoGarantido
      codAgendamentoFinalidadePeriodoGarantido
      Agendamento {
        codAgendamento
        data
        horaInicio
        horaFim
        cancelado
        codPaciente
        codUnidade
        codUsuario
        codUsuarioFormaRecebimento
        codUsuarioCompromisso
        codUsuarioAgenda
        confirmado
        nome
        meioPagamento
        telefone
        email
      }
    }
    Unidade {
      nomeLimpo
    }
    PeriodosGarantidosRecorrentesFrequencia {
      descricao
    }
    TipoSala {
      nome
    }
  }
`);

export const ReservedScheduleListItem = ({
  data,
}: ReservedScheduleListItemProps): JSX.Element => {
  const reservedScheduleData = useFragment(ReservedScheduleListItemQueryFragment, data);

  const horaInicio = parseTime(reservedScheduleData.horaInicio);
  const horaFim = parseTime(reservedScheduleData.horaFim);

  return (
    <Item
      linkProps={{
        to: "/settings/reserved-schedules/$reservedSchedulesId",
        params: {
          reservedSchedulesId: reservedScheduleData.codPeriodoGarantidoRecorrente,
        },
      }}
    >
      <div className="flex flex-col gap-2">
        <p className="font-medium text-lg">
          {reservedScheduleData.Unidade.nomeLimpo},{" "}
          {MapDaysOfWeekToFullName.get(reservedScheduleData.diaDaSemana)}
        </p>
        <div>
          <p className="text-neutral-500">
            {reservedScheduleData.PeriodosGarantidosRecorrentesFrequencia.descricao} às{" "}
            {formatTimeHourMinute(horaInicio)} - {formatTimeHourMinute(horaFim)}
          </p>
          <p className="text-neutral-500 text-sm">{reservedScheduleData.TipoSala.nome}</p>
        </div>
      </div>
    </Item>
  );
};
