import { CpsInput, type CpsInputProps } from "corpus";
import { Phone } from "@repo/icons";
import { useState } from "react";

interface InputPhoneProps extends CpsInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
}

const maskPhone = (value: string): string => {
  const digits = value.replace(/\D/g, "");
  const areaCode = digits.slice(0, 2);
  const phoneNumber = digits.slice(2);

  if (phoneNumber.length === 8) {
    return `(${areaCode}) ${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4)}`;
  } else if (phoneNumber.length === 9) {
    return `(${areaCode}) ${phoneNumber.slice(0, 5)}-${phoneNumber.slice(5)}`;
  }

  return value;
};

export const InputPhone = ({
  title = "Telefone",
  defaultValue = "",
  onChange,
  ...props
}: InputPhoneProps): JSX.Element => {
  const [phoneValue, setPhoneValue] = useState(defaultValue);

  const handleChangeElement = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const maskedValue = maskPhone(value);
    if (onChange) {
      onChange(maskedValue);
    }
    setPhoneValue(maskedValue);
  };

  return (
    <CpsInput
      IconLeft={Phone}
      title={title}
      value={phoneValue}
      autoComplete="tel"
      type="tel"
      placeholder="Digite aqui"
      inputMode="numeric"
      onChange={handleChangeElement}
      maxLength={15}
      {...props}
    />
  );
};
