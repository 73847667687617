import { cn } from "@repo/cn";
import { type SVGIconProps } from "@repo/icons";
import type { FunctionComponent, InputHTMLAttributes } from "react";
import { forwardRef } from "react";

export interface CpsInputProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  errorMessage?: string;
  helpMessage?: string;
  type?: string;
  required?: boolean;
  IconLeft?: FunctionComponent<SVGIconProps>;
  borderColor?: string;
  rounded?: boolean;
  IconRight?: FunctionComponent<SVGIconProps>;
  cursorPointer?: boolean;
}

const borderColors: Record<string, string> = {
  primary: "border-primary-50",
  secondary: "border-secondary-50",
  neutral: "border-neutral-200",
};

/**
 * `CpsInput` is a component that renders an input field.
 *
 * @remarks
 * This component is part of the CPS UI library.
 *
 * @example
 * Here is a basic usage example:
 * ```tsx
 * <CpsInput title="Username" />
 * ```
 * @param title - The title of the input field.
 * @param errorMessage - The error message to be displayed below the input field.
 * @param helpMessage - The help message to be displayed below the input field.
 * @param type - The type of the input field.
 * @param required - Whether the input field is required.
 * @param register - The `register` function from the `useForm` hook.
 * @param IconLeft - The icon to be displayed on the left side of the input field.
 * @param borderColor - The color of the border around the input field.
 * @param rounded - Whether the input field should be rounded.
 * @param IconRight - The icon to be displayed on the right side of the input field.
 * @param cursorPointer - Whether the cursor should be displayed when the input field is focused.
 *
 * @returns The `CpsInput` component.
 */
export const CpsInput = forwardRef<HTMLInputElement, CpsInputProps>(
  (
    {
      title,
      errorMessage,
      helpMessage,
      required,
      type = "text",
      IconLeft,
      borderColor = "neutral",
      rounded = false,
      IconRight,
      cursorPointer,
      ...props
    },
    ref,
  ) => {
    const getInputVariationClasses = (): string => {
      const round = rounded ? "rounded-full" : "rounded-md";
      const iconLeftPadding = cn(IconLeft && "pl-12 pr-4");
      const iconRightPadding = cn(IconRight && "pr-12");
      const error = cn(errorMessage && "border-danger-200");
      const hasCursorPointer = cn(cursorPointer && "cursor-pointer");
      const hasTitle = cn(title && "mt-1");

      return cn(
        borderColors[borderColor],
        round,
        error,
        iconLeftPadding,
        iconRightPadding,
        hasCursorPointer,
        hasTitle,
      );
    };

    return (
      <div className="w-full">
        {title ? (
          <label className="font-medium">
            {title}
            {required ? "*" : null}
          </label>
        ) : null}
        <div className="relative">
          {IconLeft ? (
            <span
              className={cn("absolute left-4 top-4", cursorPointer && "cursor-pointer")}
              onClick={props.onClick}
            >
              <IconLeft className="fill-neutral-300" size={24} />
            </span>
          ) : null}
          <input
            className={cn(
              "hover:border-primary-300 focus:border-primary-400 focus:ring-primary-200 flex w-full border-2 bg-white p-4 text-sm font-normal text-neutral-600 transition-all duration-200 placeholder:text-neutral-300 focus:outline-none focus:ring disabled:bg-neutral-50 disabled:text-neutral-300 disabled:placeholder:text-neutral-300",
              getInputVariationClasses(),
            )}
            placeholder={props.placeholder ?? title}
            type={type}
            {...props}
            ref={ref}
          />
          {IconRight ? (
            <span
              className={cn(
                "absolute right-4 top-4",
                cursorPointer && "cursor-pointer",
              )}
              onClick={props.onClick}
            >
              <IconRight className="fill-neutral-300" size={24} />
            </span>
          ) : null}
        </div>
        <span
          className={cn(
            "text-xs",
            errorMessage ? "text-danger-400" : "text-neutral-500",
          )}
        >
          {errorMessage ?? helpMessage}
        </span>
      </div>
    );
  },
);

CpsInput.displayName = "CpsInput";

export default CpsInput;
