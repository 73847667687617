import { type ICpsTimeProps } from "corpus";
import { useState } from "react";
import { Button } from "@/components/button";
import { TimePicker } from "@/components/time-picker";

export interface TimeSelectorProps {
  timerConfigs?: ICpsTimeProps;
  labelButton?: string;
  onChange?: (hour: number, minute: number) => void;
}

/**
 * `TimeSelector` is a component that renders a time selector.
 *
 * @example
 * Here is a basic usage example:
 * ```tsx
 * <TimeSelector />
 * ```
 * @param timerConfigs - An object containing the properties of the time picker.
 * @param onChange - A function that is called when the time selector is confirmed. It takes two arguments: the hour and the minute.
 *
 * @returns The `TimeSelector` component.
 */
export const TimeSelector = ({
  timerConfigs,
  onChange,
  labelButton = "Selecionar",
}: TimeSelectorProps): JSX.Element => {
  const [hour, setHour] = useState(
    timerConfigs?.initialHour ?? timerConfigs?.minHour ?? 0,
  );
  const [minute, setMinute] = useState(
    timerConfigs?.initialMinutes ?? timerConfigs?.minMinutes ?? 0,
  );

  const handleConfirm = (): void => {
    onChange && onChange(hour, minute);
  };

  const handleChange = (newHour: number, newMinute: number): void => {
    setHour(newHour);
    setMinute(newMinute);
    timerConfigs?.onChange && timerConfigs.onChange(newHour, newMinute);
  };

  return (
    <>
      <div className="py-6 w-full">
        <TimePicker {...timerConfigs} onChange={handleChange} />
      </div>
      <div className="pt-6 w-full">
        <Button onClick={handleConfirm} fullWidth>
          {labelButton}
        </Button>
      </div>
    </>
  );
};
