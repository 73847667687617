import { graphql } from "@repo/graphql-types/gql";
import { createFileRoute } from "@tanstack/react-router";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { BottomBar } from "@/components/bottom-bar";
import { HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { PatientList } from "@/components/patient-list";

const PatientPageQuery = graphql(/* GraphQL */ `
  query PatientPageQuery {
    ...PatientListQueryFragment
  }
`);

export const PatientsPage = (): JSX.Element => {
  const queryResult = useGraphQL(PatientPageQuery);
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Pacientes" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <PatientList patientList={data} /> : null}
        </AsyncDataWrapper>
      </Page>
      <BottomBar />
    </>
  );
};

export const Route = createFileRoute("/patients/")({
  component: PatientsPage,
});
