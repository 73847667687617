import { CpsAvatar } from "corpus";
import { ExclamationCircle, Right } from "@repo/icons";
import { type MembroPerfilPublico } from "@repo/graphql-types/graphql";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types/fragment-masking";
import { Item, ItemContent, ItemMainContent } from "@/components/list";
import {
  EmptyStateIcon,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state.tsx";
import { getNameInitials } from "@/lib/string.ts";

export const MembersListFragment = graphql(/* GraphQL */ `
  fragment MembersListFragment on PerfisPublicosConnection {
    listaMembros: nodes {
      codUsuario
      nome
      especialidades {
        nome
      }
    }
  }
`);

interface MemberListProps {
  memberNameSearch: string;
  data: FragmentType<typeof MembersListFragment>;
  handleClickItem: (member?: MembroPerfilPublico | null) => void;
}

export const MembersList = ({
  memberNameSearch,
  data,
  handleClickItem,
}: MemberListProps): JSX.Element | null => {
  const { listaMembros } = useFragment(MembersListFragment, data);

  if (memberNameSearch.length < 3) return null;

  if (listaMembros && listaMembros.length > 0) {
    return (
      <>
        {listaMembros.map((member) => (
          <Item
            key={`item-${member?.codUsuario}`}
            onClick={() => handleClickItem(member)}
          >
            <CpsAvatar
              alt="Avatar"
              color="primaryLight"
              initials={getNameInitials(member?.nome ?? "")}
              size={40}
            />
            <ItemContent>
              <ItemMainContent>{member?.nome}</ItemMainContent>
              <p className="text-sm">{member?.especialidades?.[0]?.nome ?? "-"}</p>
            </ItemContent>
            <Right size={16} data-align="end" />
          </Item>
        ))}
      </>
    );
  }

  return (
    <div className="my-4">
      <EmptyStateRoot>
        <EmptyStateIcon icon={ExclamationCircle} fill="primary" />
        <EmptyStateTitle>Nenhum profissional encontrado</EmptyStateTitle>
      </EmptyStateRoot>
    </div>
  );
};
