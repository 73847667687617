import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import type { BillingQueryQuery } from "@repo/graphql-types/graphql";
import { DoctorWithStethoAndClipboard } from "@repo/icons";
import type { BillingListQueryFragment } from "@/components/billing-list";
import { BillingList } from "@/components/billing-list";
import {
  EmptyStateRoot,
  EmptyStateImage,
  EmptyStateTitle,
} from "@/components/empty-state";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";

const BillingQuery = graphql(/* GraphQL */ `
  query BillingQuery {
    LivanceApiListaCobrancasFuturas {
      Billings {
        ...BillingListQueryFragment
      }
    }
    LivanceApiSelecionaCobrancasLimitandoQuantidadeRetornada {
      Billings {
        ...BillingListQueryFragment
      }
    }
  }
`);

export const Billing = (): JSX.Element => {
  const queryResult = useGraphQL(BillingQuery);
  const { data } = queryResult;

  const billingListQuery: BillingQueryQuery = data ?? {};
  const openBillings: FragmentType<typeof BillingListQueryFragment>[] = (billingListQuery
    .LivanceApiListaCobrancasFuturas?.Billings ?? []) as FragmentType<
    typeof BillingListQueryFragment
  >[];
  const closeBillings: FragmentType<typeof BillingListQueryFragment>[] = (billingListQuery
    .LivanceApiSelecionaCobrancasLimitandoQuantidadeRetornada?.Billings ??
    []) as FragmentType<typeof BillingListQueryFragment>[];
  const billingList: FragmentType<typeof BillingListQueryFragment>[] =
    openBillings.concat(closeBillings);

  return (
    <AsyncDataWrapper {...queryResult}>
      {billingList.length > 0 ? (
        <BillingList billingList={{ ...billingList }} />
      ) : (
        <EmptyStateRoot>
          <EmptyStateImage
            icon={DoctorWithStethoAndClipboard}
            alt="Imagem lista de cobranças sem movimentações"
          />
          <EmptyStateTitle>Nenhuma movimentação neste mês</EmptyStateTitle>
        </EmptyStateRoot>
      )}
    </AsyncDataWrapper>
  );
};
