import { useRouter } from "@tanstack/react-router";
import { type AlignProps } from "@repo/lib";
import { type SVGIconProps } from "@repo/icons/src/interface";

interface HeaderButtonProps extends React.ComponentPropsWithoutRef<"button">, AlignProps {
  icon: React.ElementType<SVGIconProps>;
}
export const HeaderButton = ({
  icon: Icon,
  onClick,
  ...rest
}: HeaderButtonProps): React.JSX.Element => {
  const router = useRouter();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (onClick) {
      onClick(event);
    } else {
      router.history.back();
    }
  };

  return (
    <button type="button" {...rest} onClick={handleClick}>
      <Icon size={24} className="fill-white" />
    </button>
  );
};

interface HeaderTitleProps extends AlignProps {
  title: string;
}
export const HeaderTitle = ({ title }: HeaderTitleProps): React.JSX.Element => {
  return <h1 className="text-xl font-semibold text-white">{title}</h1>;
};

interface HeaderRootProps {
  children?:
    | React.ReactElement<HeaderButtonProps | HeaderTitleProps>
    | React.ReactElement<HeaderButtonProps | HeaderTitleProps>[];
}

export const HeaderRoot = ({ children }: HeaderRootProps): React.JSX.Element => {
  let startItems, endItems, centerItems;
  if (Array.isArray(children)) {
    startItems = children.filter((child) => child.props.align === "start");
    endItems = children.filter((child) => child.props.align === "end");
    centerItems = children.filter(
      (child) => child.props.align === "center" || !child.props.align,
    );
  } else if (children?.props.align === "start") {
    startItems = children;
  } else if (children?.props.align === "end") {
    endItems = children;
  } else {
    centerItems = children;
  }

  return (
    <header className="bg-primary-400 z-20">
      <div className="flex flex-row justify-between items-center gap-3 px-4 h-14">
        <div className="flex justify-start gap-3">{startItems}</div>
        <div className="flex justify-center gap-3">{centerItems}</div>
        <div className="flex justify-end gap-3">{endItems}</div>
      </div>
    </header>
  );
};
