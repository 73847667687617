import {
  Calendar,
  CalendarTime,
  Clock,
  LocationMarker,
  type SVGIconProps,
} from "@repo/icons";
import {
  formatDateAndTimeIgnoringTimezone,
  formatIgnoringTimezone,
} from "@repo/lib/src/helpers";
import { parseISO } from "date-fns";
import { type FunctionComponent } from "react";

interface AppointmentDrawerPatientLinksProps {
  appointmentTypeName: string;
  startTime: string;
  endTime: string;
  date: string;
  unit: string;
}

export const AppointmentDrawerScheduleInformation = ({
  appointmentTypeName,
  startTime,
  endTime,
  date,
  unit,
}: AppointmentDrawerPatientLinksProps): JSX.Element => {
  const formattedStartTime = formatDateAndTimeIgnoringTimezone(date, startTime, "HH:mm");

  const formattedEndTime = formatDateAndTimeIgnoringTimezone(date, endTime, "HH:mm");

  const formattedDate = formatIgnoringTimezone(parseISO(date), "dd 'de' MMMM");

  return (
    <div className="my-4 flex flex-col items-start gap-3">
      <LinedData text={appointmentTypeName} icon={Calendar} />
      <LinedData text={`${formattedStartTime} - ${formattedEndTime}`} icon={Clock} />
      <LinedData text={formattedDate} icon={CalendarTime} />
      <LinedData text={unit} icon={LocationMarker} />
    </div>
  );
};

interface LinedDataProps {
  icon: FunctionComponent<SVGIconProps>;
  text: string;
}

const LinedData = ({ icon: Icon, text }: LinedDataProps): JSX.Element => (
  <div className="flex flex-row items-center gap-4 w-full">
    <Icon className="fill-secondary-400" size={24} />
    <div className="overflow-hidden break-all w-full">
      <span className="text-md font-medium leading-6 text-neutral-500 xl:text-xl">
        {text}
      </span>
    </div>
  </div>
);
