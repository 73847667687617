import { MapDaysOfWeekToFirstLetter, DaysOfWeekEnum } from "@repo/lib";
import { forwardRef } from "react";
import {
  CpsMultiSelector,
  type CpsMultiSelectorProps,
  CpsSelectorRoot,
  CpsSelectorTitle,
  CpsSelectorItem,
} from "corpus";

export const DaysOfWeekMultiSelector = forwardRef<
  HTMLDivElement,
  Omit<CpsMultiSelectorProps, "children">
>(({ title, ...props }, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
  const validDaysOfWeek = [
    DaysOfWeekEnum.Monday,
    DaysOfWeekEnum.Tuesday,
    DaysOfWeekEnum.Wednesday,
    DaysOfWeekEnum.Thursday,
    DaysOfWeekEnum.Friday,
    DaysOfWeekEnum.Saturday,
  ];

  return (
    <CpsSelectorRoot>
      {title ? (
        <CpsSelectorTitle required={props.required}>{title}</CpsSelectorTitle>
      ) : null}
      <CpsMultiSelector
        activeBackgroundColor="secondary"
        data-testid="days-of-week-multi-selector-test-id"
        {...props}
        ref={ref}
      >
        {validDaysOfWeek.map((day) => (
          <CpsSelectorItem value={day} key={day}>
            {MapDaysOfWeekToFirstLetter.get(day)}
          </CpsSelectorItem>
        ))}
      </CpsMultiSelector>
    </CpsSelectorRoot>
  );
});

DaysOfWeekMultiSelector.displayName = "DaysOfWeekMultiSelector";
