export const getImageSourceFromBase64 = (
  mimetype?: string | null,
  base64?: string | null,
): string | undefined => {
  if (!base64 || !mimetype) {
    return undefined;
  }

  return `data:${mimetype};base64,${base64}`;
};
