import {
  TooltipDrawerRoot,
  TooltipDrawerTitle,
  TooltipDrawerBody,
} from "@/components/tooltip-drawer";

interface PayablesInformationDrawer {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const PayablesInformationDrawer = ({
  open,
  setOpen,
}: PayablesInformationDrawer): JSX.Element => {
  return (
    <TooltipDrawerRoot setOpen={setOpen} open={open}>
      <TooltipDrawerTitle>Recebimentos</TooltipDrawerTitle>
      <TooltipDrawerBody>
        <p>
          Nesta tela, você encontrará uma lista com todos os pagamentos recebidos e a
          receber.
        </p>
        <p>
          Os valores exibidos são os valores líquidos, ou seja, são os valores já com as
          deduções das taxas. As etiquetas indicam o status de cada pagamento (pago,
          previsto, a confirmar ou rejeitado). Clique em um pagamento para obter mais
          detalhes sobre essa transação.
        </p>
        <p className="mb-3">
          Ao clicar em &quot;Conta de recebimento&quot;, você irá para tela com as
          informações da conta para onde seus pagamentos são transferidos.
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Entenda os status:</span>
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Pago:</span> OA transferência
          foi concluída e confirmada pelo banco receptor. Caso o valor não conste em sua
          conta, entre em contato com seu banco.
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Previsto:</span> A
          transferência está programada para ocorrer em breve, mas ainda não foi
          realizada. O valor pode variar caso ocorram outros pagamentos ao longo do dia.
        </p>
        <p>
          <span className="font-semibold text-neutral-600">A confirmar:</span> A
          transferência já foi enviada para sua conta, mas ainda aguardamos a confirmação
          do banco.
        </p>
        <p className="mb-3">
          <span className="font-semibold text-neutral-600">Rejeitado:</span> A
          transferência foi recusada pelo banco de destino. Atualize os dados da conta
          para tentar novamente.
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Entenda o ícone “olho”:</span>
        </p>
        <p>
          O ícone do olho ao lado do ícone de interrogação é uma ferramenta de
          privacidade. Ao clicar nele, os valores exibidos na tela serão ocultados,
          garantindo sua tranquilidade e segurança ao usar o aplicativo em locais
          públicos. Para exibir novamente os valores, basta clicar no ícone do olho mais
          uma vez.
        </p>
      </TooltipDrawerBody>
    </TooltipDrawerRoot>
  );
};
