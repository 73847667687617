import {
  Adjustments,
  Cash,
  Rotate,
  User1,
  Logout,
  CalendarTime,
  Invite,
  HelpCircle,
  GraphList,
} from "@repo/icons";
import { createFileRoute, useLoaderData, useRouteContext } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { getAppInfo } from "@repo/lib";
import { BottomBar } from "@/components/bottom-bar";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";

export const SettingsPage = (): JSX.Element => {
  const routerContext = useLoaderData({
    from: "/settings/",
  });
  const actualVersion = useQuery({
    queryKey: ["appVersion"],
    queryFn: async () => {
      return await getAppInfo();
    },
  });

  const showReservedScheduleOption = routerContext.showReservedScheduleOption;
  const showReferralPage = routerContext.showReferralPage;

  const { flags } = useRouteContext({
    strict: false,
  });

  const menuItens: (MenuListItem & { show: boolean })[] = [
    {
      name: "Indique e ganhe",
      Icon: Invite,
      route: "/settings/referral",
      show: showReferralPage,
      showTag: true,
    },
    {
      name: "Visão geral",
      Icon: GraphList,
      route: "/settings/customer-contact-overview",
      show: flags["exibe-menu-visao-geral-relatorio-atendimento"],
      showTag: true,
    },
    {
      name: "Meu perfil",
      Icon: User1,
      route: "/settings/profile",
      show: true,
    },
    {
      name: "Configurações da agenda",
      Icon: Adjustments,
      route: "/settings/schedule-options",
      show: true,
    },
    {
      name: "Gerenciar pagamentos",
      Icon: Cash,
      route: "/settings/payments",
      show: true,
    },
    {
      name: "Histórico de uso",
      Icon: Rotate,
      route: "/settings/billing",
      show: true,
    },
    {
      name: "Período garantido",
      Icon: CalendarTime,
      route: "/settings/reserved-schedules",
      show: showReservedScheduleOption,
    },
    {
      name: "Central de ajuda",
      Icon: HelpCircle,
      externalRoute: "https://ajuda.livance.com.br/hc/pt-br",
      show: true,
      target: "_blank",
    },
    {
      name: "Sair do aplicativo",
      Icon: Logout,
      route: "/auth/logout",
      show: true,
    },
  ];

  const activeMenuItens = menuItens.filter((item) => item.show);

  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Mais opções" />
      </HeaderRoot>
      <Page className="pt-2 pb-[120px]">
        <MenuList menuItems={activeMenuItens} />
        {actualVersion.data ? (
          <p className="flex justify-center  text-xs text-slate-300">
            {actualVersion.data.version}
          </p>
        ) : null}
      </Page>
      <BottomBar />
    </>
  );
};

export const Route = createFileRoute("/settings/")({
  component: SettingsPage,
  beforeLoad: ({ context }) => {
    return {
      showReservedScheduleOption: context.ldClient.getShowReservedScheduleOption(),
      showReferralPage: context.ldClient.getShowReferralPage(),
    };
  },
  loader: ({ context }) => {
    const showReservedScheduleOption = context.showReservedScheduleOption;
    const showReferralPage = context.showReferralPage;
    return { showReservedScheduleOption, showReferralPage };
  },
});
