interface PaymentsRootProps {
  children: React.ReactNode;
}

export const PaymentsRoot = ({ children }: PaymentsRootProps): JSX.Element => {
  return <div className="flex flex-col gap-6">{children}</div>;
};

interface PaymentsActionButtonsProps {
  children: React.ReactNode;
}

export const PaymentsActionButtons = ({
  children,
}: PaymentsActionButtonsProps): JSX.Element => {
  return <div className="flex flex-col gap-2">{children}</div>;
};

interface PaymentsContentProps {
  children: React.ReactNode;
}
export const PaymentsContent = ({ children }: PaymentsContentProps): JSX.Element => {
  return <div>{children}</div>;
};
