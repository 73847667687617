import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useRouter } from "@tanstack/react-router";
import { ScheduleFrequencyEnum, setTime, zonedDate } from "@repo/lib";
import { ScheduleDaysOfWeekList } from "@/components/schedule-days-of-week-list";
import { ScheduleListItemCardAppointmentType } from "@/components/schedule-list-item-card-appointment-type";
import { ScheduleListItemCardFrequency } from "@/components/schedule-list-item-card-frequency";
import { ScheduleListItemCardHead } from "@/components/schedule-list-item-card-head";
import { ScheduleListItemCardUnit } from "@/components/schedule-list-item-card-unit";
import { CardContent, CardRoot } from "@/components/card";
import { ScheduleListItemCardStartDate } from "@/components/schedule-list-item-card-start-date";

export const ScheduleListItemFragment = graphql(/* GraphQL */ `
  fragment ScheduleListItemFragment on usuariosAgendas {
    codUsuarioAgenda
    tipoRepeticao: codTipoAgendaRepeticao
    startDate: dataInicio
    ...ScheduleListItemCardFrequencyFragment
    ...ScheduleListItemCardAppointmentTypeFragment
    ...ScheduleListItemCardHeadFragment
    ...ScheduleListItemCardUnitFragment
    ...ScheduleDaysOfWeekListFragment
    ...ScheduleListItemCardStartDateFragment
  }
`);

export const ScheduleListItem = (props: {
  schedule: FragmentType<typeof ScheduleListItemFragment>;
}): JSX.Element => {
  const schedule = useFragment(ScheduleListItemFragment, props.schedule);
  const codUsuarioAgenda = schedule.codUsuarioAgenda;

  const router = useRouter();

  const sendToEditSchedulePage = (): void => {
    void router.navigate({
      to: "/settings/schedule-options/schedules/$scheduleId",
      params: { scheduleId: codUsuarioAgenda },
    });
  };

  const shouldShowStartDateComponent = (): boolean => {
    const currentDateLocalized = setTime(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    const { startDate, tipoRepeticao } = schedule;

    const scheduleStartDateLocalized = setTime(zonedDate(startDate), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    return (
      scheduleStartDateLocalized.getTime() > currentDateLocalized.getTime() ||
      tipoRepeticao === Number(ScheduleFrequencyEnum.NaoRepetir)
    );
  };

  return (
    <CardRoot onClick={() => sendToEditSchedulePage()}>
      <ScheduleListItemCardHead schedule={schedule} />
      <CardContent className="gap-y-3 pt-0">
        <ScheduleListItemCardUnit schedule={schedule} />
        <ScheduleListItemCardAppointmentType schedule={schedule} />
        <div className="flex flex-row justify-between">
          <ScheduleListItemCardFrequency schedule={schedule} />
          <ScheduleDaysOfWeekList schedule={schedule} />
        </div>
        {shouldShowStartDateComponent() && (
          <ScheduleListItemCardStartDate schedule={schedule} />
        )}
      </CardContent>
    </CardRoot>
  );
};
