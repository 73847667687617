import {
  useState,
  type ReactNode,
  useEffect,
  type ReactElement,
  type FormEvent,
} from "react";
import {
  type CheckboxV2Props,
  CpsCheckboxGroup,
  CpsCheckboxV2,
  CpsRadioButtonGroup,
  CpsRadioButtonV2,
  type RadioButtonV2Props,
} from "corpus";
import { cn } from "@repo/lib";
import {
  DrawerRoot,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerFooter,
  DrawerBody,
} from "@/components/drawer";
import { SearchInput } from "@/components/search-input";
import { normalizeToHTMLAttribute } from "@/lib/string";

export interface SelectDrawerItemProps<T extends string | number> {
  value: T;
  label: string;
}

interface SelectDrawerProps {
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
}

export const SelectDrawer = ({
  open,
  setOpen,
  onClose,
  children,
}: SelectDrawerProps): JSX.Element => {
  return (
    <DrawerRoot open={open} setOpen={setOpen} onClose={onClose}>
      <DrawerContent>{children}</DrawerContent>
    </DrawerRoot>
  );
};

interface SelectDrawerTitleProps {
  children: ReactNode;
}

export const SelectDrawerTitle = ({ children }: SelectDrawerTitleProps): JSX.Element => (
  <DrawerHeader>
    <DrawerTitle>{children}</DrawerTitle>
  </DrawerHeader>
);

interface SelectDrawerBodyProps<T extends string | number> {
  className?: string;
  children: ReactElement;
  drawerSelectedItems: SelectDrawerItemProps<T>[] | undefined;
}

const SelectDrawerBody = <T extends string | number>({
  className,
  children,
}: SelectDrawerBodyProps<T>): JSX.Element => {
  return (
    <DrawerBody>
      <div
        className={cn(
          "text-md font-normal text-neutral-500 max-w-md w-full mx-auto flex flex-col overflow-auto gap-y-1",
          className,
        )}
      >
        <ul className="grid grid-cols-1 divide-y gap-[4px]">{children}</ul>
      </div>
    </DrawerBody>
  );
};

interface SelectDrawerBodySingleListProps<T extends string | number> {
  children: ReactElement<RadioButtonV2Props>[];
  initialSelectedItem: SelectDrawerItemProps<T> | undefined;
  setSelectedItem: (newValue: SelectDrawerItemProps<T> | undefined) => void;
}

export const SelectDrawerBodySingleList = <T extends string | number>({
  children,
  initialSelectedItem,
  setSelectedItem,
}: SelectDrawerBodySingleListProps<T>): JSX.Element => {
  const [drawerSelectedItem, setDrawerSelectedItem] = useState<
    SelectDrawerItemProps<T> | undefined
  >(initialSelectedItem);

  useEffect(() => {
    if (!initialSelectedItem) return;

    setSelectedItem(initialSelectedItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Carregamento inicial do componente para atualizar o valor já checado
  }, []);

  const handleChange = (value: string | FormEvent<HTMLDivElement>): void => {
    if (!children.length || !value) return;
    const item = children.find((x) => x.props.value.toString() === value);

    if (!item) return;

    setDrawerSelectedItem({ label: item.props.label, value: item.props.value as T });
    setSelectedItem({ label: item.props.label, value: item.props.value as T });
  };

  return (
    <SelectDrawerBody
      drawerSelectedItems={drawerSelectedItem ? [drawerSelectedItem] : undefined}
    >
      <CpsRadioButtonGroup
        defaultValue={initialSelectedItem?.value.toString()}
        onChange={handleChange}
      >
        {children}
      </CpsRadioButtonGroup>
    </SelectDrawerBody>
  );
};

interface SelectDrawerBodyListRadioProps<T extends string | number> {
  children: string;
  value: T;
}

export const SelectDrawerBodyListRadio = <T extends string | number>({
  children,
  value,
}: SelectDrawerBodyListRadioProps<T>): ReactElement => {
  return (
    <CpsRadioButtonV2
      id={normalizeToHTMLAttribute(`radio-${value}`)}
      label={children}
      value={value}
    />
  );
};

interface SelectDrawerBodyMultipleListProps<T extends string | number> {
  children: ReactElement<CheckboxV2Props>[];
  selectedItems: SelectDrawerItemProps<T>[] | undefined;
  setSelectedItems: (newValue: SelectDrawerItemProps<T>[] | undefined) => void;
}

export const SelectDrawerBodyMultipleList = <T extends string | number>({
  children,
  selectedItems,
  setSelectedItems,
}: SelectDrawerBodyMultipleListProps<T>): JSX.Element => {
  const [drawerSelectedItems, setDrawerSelectedItems] = useState<
    SelectDrawerItemProps<T>[] | undefined
  >(selectedItems);

  const handleChange = (value: string[]): void => {
    if (!children.length) return;
    const items = children
      .map((x) => {
        if (value.includes(x.props.value.toString())) {
          return { label: x.props.children, value: x.props.value };
        }

        return undefined;
      })
      .filter((item) => item !== undefined) as SelectDrawerItemProps<T>[];

    setSelectedItems(items);
    setDrawerSelectedItems(items);
  };

  return (
    <SelectDrawerBody drawerSelectedItems={drawerSelectedItems}>
      <CpsCheckboxGroup name="select-drawer-checkbox-group" onChange={handleChange}>
        {children}
      </CpsCheckboxGroup>
    </SelectDrawerBody>
  );
};

interface SelectDrawerBodyListCheckboxProps<T extends string | number> {
  children: string;
  value: T;
  checked?: boolean;
  className?: string;
}

export const SelectDrawerBodyListCheckbox = <T extends string | number>({
  children,
  value,
  checked,
  className,
}: SelectDrawerBodyListCheckboxProps<T>): JSX.Element => {
  return (
    <CpsCheckboxV2
      className={className}
      color="primary"
      id={normalizeToHTMLAttribute(`checkbox-${children}-${value}`)}
      defaultChecked={checked}
      value={String(value)}
      label={children}
    />
  );
};

interface SelectDrawerFooterProps {
  children: ReactNode;
}

export const SelectDrawerFooter = ({
  children,
}: SelectDrawerFooterProps): JSX.Element => {
  return <DrawerFooter>{children}</DrawerFooter>;
};

interface SelectDrawerSearchProps<T extends string | number> {
  items: SelectDrawerItemProps<T>[];
  onSearch: (items: SelectDrawerItemProps<T>[]) => void;
  placeholder?: string;
}

export const SelectDrawerSearch = <T extends string | number>({
  items,
  onSearch,
  placeholder = "Buscar",
}: SelectDrawerSearchProps<T>): JSX.Element => {
  useEffect(() => {
    onSearch(items);
  }, [items, onSearch]);

  return (
    <SearchInput
      items={items}
      onSearch={onSearch}
      searchKeys={["label", "value"]}
      placeholder={placeholder}
    />
  );
};
