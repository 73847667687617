import { CpsTextArea } from "corpus";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { type z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { useNavigate, useRouteContext } from "@tanstack/react-router";
import { GetOneLivUserAdditionalInfoDocument } from "@repo/graphql-types/graphql";
import { oneLivFormAdditionalInfo } from "@/lib/form-schemas/oneliv-form-schema";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";

export const OneLivAdditionalInfoFormFragment = graphql(/* GraphQL */ `
  fragment OneLivAdditionalInfoFormFragment on locus_membro {
    codUsuario
    sobre
    maisInformacoes
  }
`);

export const UpdateMemberAdditionalInfoMutation = graphql(/* GraphQL */ `
  mutation UpdateMemberAdditionalInfo(
    $codUsuario: Int!
    $setObject: locus_membro_set_input!
  ) {
    updateMember: update_locus_membro(
      where: { codUsuario: { _eq: $codUsuario } }
      _set: $setObject
    ) {
      affected_rows
    }
  }
`);

export type OneLivFormAdditionalInfoValues = z.infer<typeof oneLivFormAdditionalInfo>;

interface AdditionalInfoFormProps {
  data?: FragmentType<typeof OneLivAdditionalInfoFormFragment>[];
}

export const OneLivAdditionalInfoForm = ({
  data,
}: AdditionalInfoFormProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivAdditionalInfoFormFragment, data);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    UpdateMemberAdditionalInfoMutation,
  );

  const navigate = useNavigate();

  const { user } = useRouteContext({ strict: false });

  const invalidateGetOneLivUserAdditionalInfo = useInvalidateQuery(
    GetOneLivUserAdditionalInfoDocument,
  );

  const getDefaultValues = (): OneLivFormAdditionalInfoValues => {
    const member = fragmentResult?.[0];

    return {
      about: member?.sobre ?? "",
      moreInfo: member?.maisInformacoes ?? "",
    };
  };

  const form = useForm<OneLivFormAdditionalInfoValues>({
    resolver: zodResolver(oneLivFormAdditionalInfo),
    defaultValues: getDefaultValues(),
  });

  const onSubmit = async (formData: OneLivFormAdditionalInfoValues): Promise<void> => {
    const onSuccess = (): void => {
      invalidateGetOneLivUserAdditionalInfo();

      void navigate({
        to: "/oneliv/experiences",
      });
    };

    await mutateAsync(
      {
        codUsuario: user.codUsuario,
        setObject: {
          maisInformacoes: formData.moreInfo,
          sobre: formData.about,
        },
      },
      { onSuccess },
    );
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit}>
        <p className="font-medium text-neutral-600">
          Inclua mais informações sobre você. Informações detalhadas deixam seu perfil
          mais atrativo.
        </p>
        <FormField
          control={form.control}
          name="about"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsTextArea
                  title="Sobre o profissional"
                  placeholder="Digite aqui"
                  inputMode="text"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="moreInfo"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsTextArea
                  title="Mais informações"
                  placeholder="Digite aqui"
                  inputMode="text"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormSubmitButton type="submit">Avançar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
