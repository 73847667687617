import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { type GetAllBanksOutput } from "@repo/graphql-types/graphql";
import { forwardRef } from "react";
import { SelectInput, type SelectInputProps } from "@/components/select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";

export const BanksListToSelectQueryFragment = graphql(/* GraphQL */ `
  fragment BanksListToSelectQueryFragment on GetAllBanksOutput {
    code
    fullName
  }
`);

interface BanksSelectInputProps extends Omit<SelectInputProps<string>, "items"> {
  data: FragmentType<typeof BanksListToSelectQueryFragment>[];
}

export const BanksSelectInput = forwardRef<HTMLInputElement, BanksSelectInputProps>(
  ({ data, ...props }, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
    const banks = useFragment(BanksListToSelectQueryFragment, data);

    const items: SelectDrawerItemProps<string>[] =
      banks.length > 0
        ? (banks as GetAllBanksOutput[])
            .filter((bank) => bank.code && bank.fullName)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Por já ter eliminado via filter todos os resultados dos quais code ou fullName são undefined, é seguro usar `!` aqui.
            .sort((bankA, bankB) => bankA.fullName!.localeCompare(bankB.fullName!))
            .map((bank) => {
              const formattedCode = String(bank.code).padStart(3, "0");
              return {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Por já ter eliminado via filter todos os resultados dos quais code ou fullName são undefined, é seguro usar `!` aqui.
                label: `${formattedCode} - ${bank.fullName!.toUpperCase()}`,
                value: formattedCode,
              };
            })
        : [];

    return <SelectInput<string> {...props} items={items} ref={ref} />;
  },
);

BanksSelectInput.displayName = "BanksSelectInput";
