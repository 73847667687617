import { ExclamationCircle } from "@repo/icons";
import {
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

interface ReservedScheduleCancelFailDrawerProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const ReservedScheduleCancelFailDrawer = ({
  open,
  setOpen,
}: ReservedScheduleCancelFailDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot icon={ExclamationCircle} open={open} setOpen={setOpen}>
      <MessageDrawerTitle>Erro ao cancelar período</MessageDrawerTitle>
      <MessageDrawerBody>
        <div className="mb-6">
          Não conseguimos cancelar o período garantido. Entre em contato com o nosso time
          ou tente novamente.
        </div>
      </MessageDrawerBody>
    </MessageDrawerRoot>
  );
};
