import { z } from "zod";

export const reservedScheduleBasicInfo = z.object({
  location: z.number({ required_error: "Campo obrigatório" }).min(1, "Campo obrigatório"),
  locationName: z.string(),
  roomType: z.number({ required_error: "Campo obrigatório" }).min(1, "Campo obrigatório"),
  frequency: z
    .number({ required_error: "Campo obrigatório" })
    .min(1, "Campo obrigatório"),
  dayOfWeek: z
    .number({ required_error: "Campo obrigatório" })
    .min(1, "Campo obrigatório"),
  duration: z.string({ required_error: "Campo obrigatório" }),
});

export const reservedScheduleSlotInfo = z.object({
  slot: z.string({ required_error: "Campo obrigatório" }),
});

export const reservedScheduleStartingDayInfo = z.object({
  startingDay: z
    .date({ required_error: "Campo obrigatório" })
    .min(new Date(), "Campo obrigatório"),
});
