import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { ScheduleFrequencyEnum } from "@repo/lib";

export const ScheduleListItemCardFrequencyFragment = graphql(/* GraphQL */ `
  fragment ScheduleListItemCardFrequencyFragment on usuariosAgendas {
    tipoRepeticao: codTipoAgendaRepeticao
  }
`);

export const ScheduleListItemCardFrequency = (props: {
  schedule: FragmentType<typeof ScheduleListItemCardFrequencyFragment>;
}): JSX.Element => {
  const schedule = useFragment(ScheduleListItemCardFrequencyFragment, props.schedule);

  const frequencyMapping: Record<number, string> = {
    [ScheduleFrequencyEnum.Semanalmente]: "Semanalmente",
    [ScheduleFrequencyEnum.Quinzenalmente]: "Quinzenalmente",
    [ScheduleFrequencyEnum.NaoRepetir]: "Dia único",
  };
  const frequency = frequencyMapping[schedule.tipoRepeticao];

  return <p className="font-medium text-gray-800">{frequency}</p>;
};
