import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import {
  TimeSlotSuggestionType,
  convertDateTimeIgnoringTimezone,
  formatIgnoringTimezone,
  formatWithZonedDate,
  getCurrentDate,
} from "@repo/lib";
import { zodResolver } from "@hookform/resolvers/zod";
import { type z } from "zod";
import { useForm } from "react-hook-form";
import { useNavigate, useRouter, useSearch } from "@tanstack/react-router";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { AppointmentFormCalendar } from "@/components/appointment-form-calendar";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormControl,
  FormItem,
} from "@/components/form";
import {
  type UnitsListToSelectQueryFragment,
  UnitsSelectInput,
} from "@/components/units-select-input";
import { AppointmentTypesSelectInput } from "@/components/appointment-types-select-input";
import { PaymentMethodsSelectInput } from "@/components/payment-methods-select-input";
import { Calendar } from "@/components/calendar";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { appointmentBasicInfo } from "@/lib/form-schemas/appointment-schema";
import { AppointmentFormDurationSuggestionAlert } from "@/components/appointment-form-duration-suggestion-alert";
import { AppointmentFormLimitedDurationDrawer } from "@/components/appointment-form-limited-duration-drawer";
import { differenceInMinutes } from "@/lib/time";
import { trackEvent } from "@/lib/tracking";

export const AppointmentFormFragment = graphql(/* GraphQL */ `
  fragment AppointmentFormFragment on query_root {
    usuariosAgendas(
      where: {
        ativo: { _eq: true }
        tbUsuariosAgendasCompromissos: {
          ativo: { _eq: true }
          UsuarioCompromisso: {
            usuariosCompromissosCanaisAgendamentos: { codCanalAgendamento: { _eq: 2 } }
          }
        }
        _or: [{ dataFim: { _gte: $dataInicio } }, { dataFim: { _is_null: true } }]
      }
    ) {
      codUsuarioAgenda
      usuariosAgendasCompromissos: tbUsuariosAgendasCompromissos(
        where: {
          ativo: { _eq: true }
          UsuarioCompromisso: {
            ativo: { _eq: true }
            categoria: { _neq: 3 }
            usuariosCompromissosCanaisAgendamentos: { codCanalAgendamento: { _eq: 2 } }
          }
        }
      ) {
        usuarioCompromisso: UsuarioCompromisso {
          ...AppointmentTypesListToSelectQueryFragment
          codUsuarioCompromisso
          nome
          duracao
          usuariosCompromissosFormasRecebimentos: tbUsuariosCompromissosFormasRecebimentos(
            where: {
              ativo: { _eq: true }
              UsuarioFormaRecebimento: { ativo: { _eq: true } }
            }
          ) {
            codUsuarioFormaRecebimento
            codUsuarioCompromisso
            UsuarioFormaRecebimento {
              codUsuarioFormaRecebimento
              ...PaymentMethodsListToSelectQueryFragment
            }
          }
        }
        UsuarioAgenda {
          codUsuarioAgenda
        }
      }

      usuariosAgendasUnidades: tbUsuariosAgendasUnidades(
        where: { ativo: { _eq: true }, Unidade: { ativo: { _eq: true } } }
      ) {
        codUsuarioAgenda
        codUnidade
        Unidade {
          codUnidade
          nomeLimpo
          sigla
          ...UnitsListToSelectQueryFragment
        }
      }

      usuariosAgendasDiasSemanas: tbUsuariosAgendasDiasSemanas(
        where: { ativo: { _eq: true } }
      ) {
        diaSemana
      }
    }

    usuariosFormasRecebimentosAggregate: usuariosFormasRecebimentos_aggregate(
      where: { codTipoFormaRecebimento: { _eq: 2 }, ativo: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
  }
`);

interface AppointmentPageSearchParams {
  date?: string;
  locationId?: number;
  limitedSlotSelected?: boolean;
  appointmentType?: number;
}

export interface ScheduleFormProps {
  queryData: FragmentType<typeof AppointmentFormFragment>;
}

export const AppointmentForm = ({ queryData }: ScheduleFormProps): JSX.Element => {
  const { usuariosAgendas, usuariosFormasRecebimentosAggregate } = useFragment(
    AppointmentFormFragment,
    queryData,
  );

  const router = useRouter();
  const navigate = useNavigate();

  const showPaymentMethodsField =
    usuariosFormasRecebimentosAggregate.aggregate?.count !== undefined
      ? usuariosFormasRecebimentosAggregate.aggregate.count > 0
      : true;

  const allUnits = usuariosAgendas
    .flatMap((usuarioAgenda) =>
      usuarioAgenda.usuariosAgendasUnidades.map(
        (usuarioAgendaUnidade) => usuarioAgendaUnidade.Unidade,
      ),
    )
    .sort((a, b) => (a?.nomeLimpo ?? "").localeCompare(b?.nomeLimpo ?? ""));

  const allAppointmentTypes = usuariosAgendas.flatMap((usuarioAgenda) =>
    usuarioAgenda.usuariosAgendasCompromissos.map(
      (usuariosAgendasCompromisso) => usuariosAgendasCompromisso.usuarioCompromisso,
    ),
  );

  const filterAppointmentTypesBySchedule = (
    selectedUnitId?: number,
  ): typeof allAppointmentTypes => {
    let result: typeof allAppointmentTypes = allAppointmentTypes;

    if (selectedUnitId) {
      const filteredSchedulesByUnit = usuariosAgendas.filter((usuarioAgenda) =>
        usuarioAgenda.usuariosAgendasUnidades.find(
          (usuarioAgendaUnidade) => usuarioAgendaUnidade.codUnidade === selectedUnitId,
        ),
      );

      const appointmentTypesBySchedule = filteredSchedulesByUnit.flatMap(
        (scheduleByUnit) =>
          scheduleByUnit.usuariosAgendasCompromissos.map(
            (usuariosAgendasCompromisso) => usuariosAgendasCompromisso.usuarioCompromisso,
          ),
      );

      result = appointmentTypesBySchedule;
    }

    return result.sort((a, b) => a.nome.localeCompare(b.nome));
  };

  const allPaymentMethods = allAppointmentTypes.flatMap((singleAppointmentType) =>
    singleAppointmentType.usuariosCompromissosFormasRecebimentos.map(
      (usuarioCompromissoFormaRecebimento) =>
        usuarioCompromissoFormaRecebimento.UsuarioFormaRecebimento,
    ),
  );

  const filterPaymentMethodsByAppointmentType = (
    selectedAppointmentType?: (typeof allAppointmentTypes)[0],
  ): typeof allPaymentMethods => {
    if (selectedAppointmentType) {
      return selectedAppointmentType.usuariosCompromissosFormasRecebimentos.map(
        (usuarioCompromissoFormaRecebimento) =>
          usuarioCompromissoFormaRecebimento.UsuarioFormaRecebimento,
      );
    }

    return allPaymentMethods;
  };

  const [atomValues, setAtomValues] = useAtom(appointmentFormAtom);

  const {
    unit,
    appointmentType,
    paymentMethod,
    appointmentDate,
    appointmentId,
    patientId,
    ...otherAtomValues
  } = atomValues;

  const today = getCurrentDate();

  const searchParams: AppointmentPageSearchParams = useSearch({
    strict: false,
  });

  const { date: searchParamsDate, locationId, limitedSlotSelected } = searchParams;

  const navigateSearch = (search: AppointmentPageSearchParams): void => {
    void navigate({ search: { ...searchParams, ...search }, replace: true });
  };

  useEffect(() => {
    const defaultUnitIdFromParams = locationId ?? unit;
    const defaultUnitId = defaultUnitIdFromParams
      ? defaultUnitIdFromParams
      : tryGetUniqueAvailableUnitId() ?? 0;

    const defaultAppointmentType =
      tryGetUniqueAvailableAppointmentType(defaultUnitId) ?? undefined;
    const defaultAppointmentTypeId =
      defaultAppointmentType?.codUsuarioCompromisso ?? appointmentType;

    if (!searchParams.locationId || !searchParams.appointmentType) {
      if (defaultUnitId || defaultAppointmentTypeId) {
        navigateSearch({
          locationId: defaultUnitId,
          appointmentType: defaultAppointmentTypeId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on mount
  }, []);

  const initialDate = (): Date => {
    if (searchParamsDate) {
      return convertDateTimeIgnoringTimezone(searchParamsDate);
    }

    if (appointmentDate) return appointmentDate;

    return today;
  };

  const tryGetUniqueAvailableUnitId = (): number | undefined => {
    const distinctUnits = new Set(allUnits.map((x) => x?.codUnidade));
    if (distinctUnits.size !== 1) return;

    return allUnits[0]?.codUnidade;
  };

  const tryGetUniqueAvailableAppointmentType = (
    unitId?: number,
  ): (typeof allAppointmentTypes)[0] | undefined => {
    if (!unitId) return;

    const appointmentTypesBySchedule = filterAppointmentTypesBySchedule(unitId);
    const distinctAppointmentTypes = new Set(
      appointmentTypesBySchedule.map((x) => x.codUsuarioCompromisso),
    );
    if (distinctAppointmentTypes.size !== 1) return;

    return appointmentTypesBySchedule[0];
  };

  const tryGetUniqueAvailablePaymentMethodId = (
    appointmentTypeToFilter: (typeof allAppointmentTypes)[0] | undefined,
  ): number | undefined => {
    if (!appointmentTypeToFilter) return;

    const paymentMethodsByAppointmentType = filterPaymentMethodsByAppointmentType(
      appointmentTypeToFilter,
    );
    const distinctPaymentMethods = new Set(
      paymentMethodsByAppointmentType.map((x) => x.codUsuarioFormaRecebimento),
    );
    if (distinctPaymentMethods.size !== 1) return;

    return paymentMethodsByAppointmentType[0]?.codUsuarioFormaRecebimento;
  };

  const buildDefaultValues = (): {
    unit: number;
    appointmentDate: Date;
    appointmentType: number;
    paymentMethod: number;
  } => {
    const defaultUnitIdFromParams = locationId ?? unit;
    const defaultUnitId = defaultUnitIdFromParams
      ? defaultUnitIdFromParams
      : tryGetUniqueAvailableUnitId() ?? 0;

    const defaultAppointmentType =
      tryGetUniqueAvailableAppointmentType(defaultUnitId) ?? undefined;
    const defaultAppointmentTypeId =
      defaultAppointmentType?.codUsuarioCompromisso ?? appointmentType;

    const defaultPaymentMethodId =
      tryGetUniqueAvailablePaymentMethodId(defaultAppointmentType) ?? paymentMethod;

    return {
      unit: defaultUnitId,
      appointmentDate: initialDate(),
      appointmentType: defaultAppointmentTypeId,
      paymentMethod: defaultPaymentMethodId,
    };
  };

  type AppointmentForm = z.infer<typeof appointmentBasicInfo>;

  const form = useForm<AppointmentForm>({
    resolver: zodResolver(appointmentBasicInfo),
    defaultValues: buildDefaultValues(),
  });

  const selectedUnitId = form.watch("unit");
  const selectedUnit = allUnits.find(
    (singleUnit) => singleUnit?.codUnidade === selectedUnitId,
  );

  const selectedAppointmentTypeId = form.watch("appointmentType");
  const selectedAppointmentType = allAppointmentTypes.find(
    (singleAppointmentType) =>
      singleAppointmentType.codUsuarioCompromisso === selectedAppointmentTypeId,
  );

  const selectedPaymentMethodId = form.watch("paymentMethod");
  const appointmentDateValue = form.watch("appointmentDate");
  const appointmentTime = form.watch("appointmentTime");

  const getTimeSlotDuration = (): number => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Sem essa checagem o componente quebra
    return appointmentTime
      ? differenceInMinutes({
          startTime: formatWithZonedDate(appointmentTime.start, "HH:mm"),
          endTime: formatWithZonedDate(appointmentTime.end, "HH:mm"),
        })
      : 0;
  };

  const filteredAppointmentTypes = filterAppointmentTypesBySchedule(selectedUnitId);

  const filteredPaymentMethods = filterPaymentMethodsByAppointmentType(
    selectedAppointmentType,
  );

  const handleFormSubmit = (formData: AppointmentForm): void => {
    const { start, end, timeSlotType } = formData.appointmentTime;

    start.setSeconds(0);
    start.setMilliseconds(0);

    formData.appointmentTime = { start, end, timeSlotType };

    const possibleSchedules = usuariosAgendas.filter(
      (usuarioAgenda) =>
        usuarioAgenda.usuariosAgendasCompromissos.find(
          (usuarioAgendaCompromisso) =>
            usuarioAgendaCompromisso.usuarioCompromisso.codUsuarioCompromisso ===
            formData.appointmentType,
        ) &&
        usuarioAgenda.usuariosAgendasUnidades.find(
          (usuarioAgendaUnidade) => usuarioAgendaUnidade.codUnidade === formData.unit,
        ) &&
        usuarioAgenda.usuariosAgendasDiasSemanas.find(
          (usuarioAgendaDiaSemana) => usuarioAgendaDiaSemana.diaSemana === start.getDay(),
        ),
    );

    formData.scheduleId =
      possibleSchedules.length > 0 ? possibleSchedules[0].codUsuarioAgenda : undefined;

    setAtomValues({
      appointmentId,
      patientId,
      ...otherAtomValues,
      ...formData,
    });

    navigateToNextStep(timeSlotType);
  };

  const navigateToNextStep = (timeSlotType?: number): void => {
    const routesConfig = {
      reschedule: {
        to: "/appointment/$appointmentId/reschedule-patient",
        params: { appointmentId },
      },
      patient: {
        to: "/appointment/create/patient",
        search: { patientId },
      },
      "search-patient": {
        to: "/appointment/create/search-patient",
      },
    };

    let routeConfig;

    if (appointmentId) {
      routeConfig = routesConfig.reschedule;
    } else if (patientId) {
      routeConfig = routesConfig.patient;
    } else {
      routeConfig = routesConfig["search-patient"];
    }

    if (
      timeSlotType !== TimeSlotSuggestionType.LimitedDurationAppointment ||
      limitedSlotSelected
    ) {
      void navigate({
        ...routeConfig,
        replace: timeSlotType === TimeSlotSuggestionType.LimitedDurationAppointment,
      });
    } else {
      void navigate({
        search: {
          limitedSlotSelected: true,
          ...searchParams,
        },
      });
    }
  };

  const isFormBasicInfoValid =
    form.getValues("unit") &&
    form.getValues("appointmentType") &&
    form.getValues("paymentMethod") &&
    form.getValues("appointmentDate");

  const onChangeUnit = (changedUnitId: number): void => {
    void form.trigger(["unit"]);

    const appointmentTypeIsFilled = Boolean(selectedAppointmentTypeId);
    const appointmentTypeStillExists = filterAppointmentTypesBySchedule(
      changedUnitId,
    ).some((x) => x.codUsuarioCompromisso === selectedAppointmentTypeId);

    if (appointmentTypeIsFilled && appointmentTypeStillExists) return;

    const uniqueAppointmentTypeId =
      tryGetUniqueAvailableAppointmentType(changedUnitId)?.codUsuarioCompromisso;

    if (uniqueAppointmentTypeId) {
      form.setValue("appointmentType", uniqueAppointmentTypeId);
      onChangeAppointmentType(uniqueAppointmentTypeId);

      if (!appointmentId) {
        navigateSearch({
          date: searchParamsDate,
          locationId: changedUnitId,
          appointmentType: uniqueAppointmentTypeId,
        });
      }
      return;
    }

    form.resetField("appointmentType", { defaultValue: 0 });
    form.resetField("paymentMethod", { defaultValue: 0 });

    if (!appointmentId) {
      navigateSearch({
        date: searchParamsDate,
        locationId: changedUnitId,
      });
    }
  };

  const onChangeAppointmentType = (changedAppointmentTypeId: number): void => {
    void form.trigger(["appointmentType"]);

    const paymentMethodIsFilled = Boolean(selectedPaymentMethodId);
    const paymentMethodStillExists = filterPaymentMethodsByAppointmentType(
      allAppointmentTypes.find(
        (x) => x.codUsuarioCompromisso === changedAppointmentTypeId,
      ),
    ).some((x) => x.codUsuarioFormaRecebimento === selectedPaymentMethodId);

    if (paymentMethodIsFilled && paymentMethodStillExists) return;

    const uniquePaymentMethodId = tryGetUniqueAvailablePaymentMethodId(
      allAppointmentTypes.find(
        (x) => x.codUsuarioCompromisso === changedAppointmentTypeId,
      ),
    );

    if (uniquePaymentMethodId) {
      form.setValue("paymentMethod", uniquePaymentMethodId);
    } else {
      form.resetField("paymentMethod", { defaultValue: 0 });
    }
  };

  const closeLimitedDurationDrawer = (value: boolean): void => {
    if (!value && limitedSlotSelected) {
      router.history.back();
    }
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit>
        <FormField
          control={form.control}
          name="unit"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <UnitsSelectInput
                  title="Unidades"
                  data={allUnits as FragmentType<typeof UnitsListToSelectQueryFragment>[]}
                  {...field}
                  onChange={(
                    event: number | React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    field.onChange(event);
                    onChangeUnit(Number(event));
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="appointmentType"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <AppointmentTypesSelectInput
                  title="Tipo de atendimento"
                  data={filteredAppointmentTypes}
                  {...field}
                  onChange={(
                    event: number | React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    field.onChange(event);

                    navigateSearch({
                      appointmentType: Number(event),
                    });

                    onChangeAppointmentType(Number(event));
                  }}
                  disabled={!selectedUnitId}
                />
              </FormControl>
            </FormItem>
          )}
        />
        {selectedAppointmentTypeId ? (
          <AppointmentFormDurationSuggestionAlert
            appointmentTypeId={selectedAppointmentTypeId}
          />
        ) : null}
        {showPaymentMethodsField ? (
          <FormField
            control={form.control}
            name="paymentMethod"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <PaymentMethodsSelectInput
                    title="Forma de recebimento"
                    data={filteredPaymentMethods}
                    {...field}
                    onChange={(
                      event: number | React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      field.onChange(event);
                      void form.trigger(["paymentMethod"]);
                    }}
                    disabled={!selectedAppointmentTypeId}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ) : null}

        <div className="sticky -top-4 bg-white z-10 -mt-4 pt-4 pb-6">
          <FormField
            control={form.control}
            name="appointmentDate"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Calendar
                    initialView="dayGridWeek"
                    possibleViews={["dayGridMonth", "dayGridWeek"]}
                    selectedDate={
                      appointmentDateValue
                        ? formatIgnoringTimezone(appointmentDateValue)
                        : formatIgnoringTimezone(today)
                    }
                    disabledPastDates
                    onDateClick={(date: string) => {
                      if (!appointmentId) {
                        navigateSearch({
                          date,
                        });
                      }
                      void form.trigger(["unit", "appointmentType", "paymentMethod"]);
                    }}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        {isFormBasicInfoValid ? (
          <FormField
            control={form.control}
            name="appointmentTime"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <AppointmentFormCalendar
                    codUsuarioCompromisso={selectedAppointmentTypeId}
                    codUsuarioFormaRecebimento={selectedPaymentMethodId}
                    currentDate={
                      appointmentDateValue
                        ? formatIgnoringTimezone(appointmentDateValue)
                        : formatIgnoringTimezone(today)
                    }
                    codAgendamento={appointmentId}
                    onEventClick={(
                      start: Date | null,
                      end: Date | null,
                      timeSlotType?: number | null,
                    ): void => {
                      trackEvent("Agendamento Horario Selecionado", {
                        horaInicio: start,
                        horaFim: end,
                        tipoDeSlot: timeSlotType,
                      });

                      field.onChange({ start, end, timeSlotType });
                      void form.handleSubmit(handleFormSubmit)();
                    }}
                    unit={{
                      id: selectedUnit?.codUnidade ?? 0,
                      name: selectedUnit?.nomeLimpo ?? "",
                      acronym: selectedUnit?.sigla ?? "",
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ) : null}
      </FormHandlerSubmit>
      <AppointmentFormLimitedDurationDrawer
        duration={getTimeSlotDuration()}
        open={Boolean(limitedSlotSelected)}
        setOpen={closeLimitedDurationDrawer}
        buttonAction={() =>
          navigateToNextStep(TimeSlotSuggestionType.LimitedDurationAppointment)
        }
      />
    </FormRoot>
  );
};
