import { Clock, CreditCard, Left, Notes } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";

const menuItens: MenuListItem[] = [
  {
    name: "Agendas",
    Icon: Notes,
    route: "/settings/schedule-options/schedules",
  },
  {
    name: "Tipos de atendimento",
    Icon: Clock,
    route: "/settings/schedule-options/appointment-types",
  },
  {
    name: "Convênios",
    Icon: CreditCard,
    route: "/settings/schedule-options/appointment-receiving-options",
  },
];

export const SchedulesSettingsPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Configurações da agenda" />
      </HeaderRoot>
      <Page className="pt-2">
        <MenuList menuItems={menuItens} />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/schedule-options/")({
  component: SchedulesSettingsPage,
});
