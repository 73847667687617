import {
  createFileRoute,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import {
  convertDateTimeIgnoringTimezone,
  formatDateAndTimeIgnoringTimezone,
  formatIgnoringTimezone,
  getDateFromToday,
  zonedDate,
} from "@repo/lib";
import { z } from "zod";
import { HelpCircle, Left } from "@repo/icons";
import { addDays } from "date-fns";
import { type ExpensumGetPayablesInput } from "@repo/graphql-types/graphql";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { mapExpensumGetPayablesResultToPeriodPayables } from "@/lib/mappers/payables";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { AmountTitle } from "@/components/amount-title";
import { PaymentFilterForm } from "@/components/payment-filter-form";
import {
  PaymentsRoot,
  PaymentsActionButtons,
  PaymentsContent,
} from "@/components/payments-root";
import { PayablesInformationDrawer } from "@/components/payables-information-drawer";
import { PayablesList } from "@/components/payables-list";
import { DownloadFileButton } from "@/components/download-file-button";

const ExpensumPayablesQuery = graphql(`
  query ExpensumPayablesQuery($input: ExpensumGetPayablesInput!) {
    ExpensumGetPayables(arg1: $input) {
      data {
        date
        payables {
          id
          paymentDate
          amount
          status
          installment
          type
          paymentMethod
          accrualAt
          createdAt
        }
      }
    }
  }
`);

const today = zonedDate(new Date());
const lastsFourteenDays = getDateFromToday(-14);

const defaultStartDate = formatIgnoringTimezone(lastsFourteenDays, "yyyy-MM-dd");
const defaultEndDate = formatIgnoringTimezone(today, "yyyy-MM-dd");

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

const payablesPageSearchSchema = z
  .object({
    back: z.number().optional(),
    filter: z.string().optional(),
    about: z.string().optional(),
    startDate: z
      .string()
      .refine((value) => !value || dateRegex.test(value))
      .optional(),
    endDate: z
      .string()
      .optional()
      .refine((value) => !value || dateRegex.test(value)),
  })
  .refine((data) => {
    const startDate = data.startDate;
    const endDate = data.endDate;

    data.startDate = startDate ?? endDate ?? defaultStartDate;
    data.endDate = endDate ?? startDate ?? defaultEndDate;

    return true;
  });

export const PaymentReceiptsPage = (): JSX.Element => {
  const { user } = useRouteContext({ strict: false });

  const searchParams = useSearch({
    from: "/settings/payments/payables/",
  });

  const { about, filter, endDate, startDate } = searchParams;

  const router = useRouter();

  const setOpenDrawer = (open: boolean, paramName: "filter" | "about"): void => {
    const search: z.infer<typeof payablesPageSearchSchema> = {
      filter: undefined,
      about: undefined,
      ...searchParams,
    };

    if (open) {
      search[paramName] = String(open);

      void router.navigate({
        search,
      });
    } else if (searchParams[paramName]) {
      router.history.back();
    }
  };

  const buildExpensumGetPayablesQueryInput = (): ExpensumGetPayablesInput => {
    let interval = {
      paymentDateSince: startDate,
      paymentDateUntil: endDate,
    };

    if (startDate && endDate && startDate === endDate) {
      interval = {
        paymentDateSince: startDate,
        paymentDateUntil: formatIgnoringTimezone(
          addDays(convertDateTimeIgnoringTimezone(startDate), 1),
        ),
      };
    }

    return {
      codUsuario: user.codUsuario,
      codClinica: user.codClinica,
      ...interval,
    };
  };

  const queryResult = useGraphQL(ExpensumPayablesQuery, {
    input: buildExpensumGetPayablesQueryInput(),
  });

  const { data } = queryResult;

  const periodPayables = mapExpensumGetPayablesResultToPeriodPayables(data);

  const getFileNameAndContent = (): { fileName: string; fileContent: string } => {
    const csvRows: string[][] = [];

    const headerRow = [
      "Valor total",
      "Status",
      "Descrição da operação",
      "Método de pagamento",
      "Parcela",
      "Data de criação",
      "Data de pagamento",
    ];

    csvRows.push(headerRow);

    if (periodPayables.dayPayables.length > 0) {
      const { dayPayables } = periodPayables;

      const payables = dayPayables.flatMap((item) => item.payables);

      const sortedPayables = [...payables].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return dateA.getTime() - dateB.getTime();
      });

      sortedPayables.forEach((payable) => {
        const {
          amount,
          status,
          paymentMethod,
          installment,
          createdAt,
          paymentDate,
          type,
        } = payable;

        const itemRow: string[] = [
          amount,
          status,
          type,
          paymentMethod,
          installment.toString(),
          createdAt,
          paymentDate,
        ];

        csvRows.push(itemRow);
      });

      const fileContent = csvRows
        .map((row) => row.map((value) => `"${value}"`).join(","))
        .join("\n");

      const fileName = `recebimentos_do_periodo_${formatDateAndTimeIgnoringTimezone(startDate ?? "", undefined, "dd-MM-yyyy")}_${formatDateAndTimeIgnoringTimezone(endDate ?? "", undefined, "dd-MM-yyyy")}`;

      return { fileName, fileContent };
    }

    return { fileName: "", fileContent: "" };
  };

  const { fileContent, fileName } = getFileNameAndContent();

  const navigateBack = (): void => {
    const backSteps = searchParams.back ?? 0;
    const historySteps = -1 - backSteps;

    router.history.go(historySteps);
  };

  const setOpenFilter = (open: boolean): void => {
    setOpenDrawer(open, "filter");
  };

  const setOpenAbout = (open: boolean): void => {
    setOpenDrawer(open, "about");
  };

  const paymentFilterValue = {
    from: convertDateTimeIgnoringTimezone(startDate ?? ""),
    to: convertDateTimeIgnoringTimezone(endDate ?? ""),
  };

  const showDownloadButton = periodPayables.dayPayables.length > 0;

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={navigateBack} icon={Left} align="start" />
        <HeaderTitle title="Recebimentos" align="center" />
        <HeaderButton
          align="end"
          icon={HelpCircle}
          onClick={() => {
            setOpenDrawer(true, "about");
          }}
        />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <>
              <PaymentsRoot>
                <AmountTitle
                  title="Valor do período"
                  amount={periodPayables.totalAmount}
                />
                <PaymentsActionButtons>
                  <PaymentFilterForm
                    open={Boolean(filter)}
                    setOpenFilter={setOpenFilter}
                    value={paymentFilterValue}
                    visiblePeriods={[-15, -30, 15, 30]}
                  />
                  {showDownloadButton ? (
                    <DownloadFileButton
                      fileContent={fileContent}
                      fileExtension="csv"
                      fileName={fileName}
                      title="Baixar relatório"
                    />
                  ) : null}
                </PaymentsActionButtons>
                <PaymentsContent>
                  <PayablesList periodPayables={periodPayables} />
                </PaymentsContent>
              </PaymentsRoot>

              <PayablesInformationDrawer open={Boolean(about)} setOpen={setOpenAbout} />
            </>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/payables/")({
  component: PaymentReceiptsPage,
  validateSearch: payablesPageSearchSchema,
});
