import { ExclamationCircle } from "@repo/icons";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
  MessageDrawerActions,
  MessageDrawerActionButton,
} from "@/components/message-drawer";

interface AppointmentRepeatedPatientErrorDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
}

export const AppointmentRepeatedPatientErrorDrawer = ({
  open,
  setOpen,
}: AppointmentRepeatedPatientErrorDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot
      open={open}
      setOpen={setOpen}
      icon={ExclamationCircle}
      variant="secondary"
    >
      <MessageDrawerTitle>Agendamentos repetidos</MessageDrawerTitle>
      <MessageDrawerBody>
        <p>Observamos vários agendamentos para esse paciente nesta semana. </p>
        <p>
          Orientamos agendamentos apenas com pacientes definidos, utilizando dados reais e
          evitando cancelamentos desnecessários.
        </p>
        <p>
          Siga as boas praticas estabelecidas em nosso{" "}
          <a
            href="https://ajuda.livance.com.br/pt-BR/collections/3541735-codigo-de-conduta"
            target="_blank"
            rel="noopener"
            className="text-[#0080a5] underline"
          >
            código de conduta.
          </a>
        </p>
      </MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton onClick={() => setOpen(false)}>
          Revisar paciente
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
