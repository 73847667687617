import { CpsInput } from "corpus";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { type z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { SocialMediaEnum } from "@repo/lib";
import { GetOneLivUserSocialMediaDocument } from "@repo/graphql-types/graphql";
import { useNavigate } from "@tanstack/react-router";
import { oneLivFormSocialMedia } from "@/lib/form-schemas/oneliv-form-schema";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";

export const OneLivSocialMediaFormFragment = graphql(/* GraphQL */ `
  fragment OneLivSocialMediaFormFragment on query_root {
    locus_rede_social_membro {
      redeSocial
      url
    }
  }
`);

const UpdateSocialMediaMutation = graphql(`
  mutation UpdateMemberSocialMedia($input: LocusUpdateMemberSocialMediaInput!) {
    LocusUpdateMemberSocialMedia(arg1: $input)
  }
`);

export type OneLivFormSocialMediaValues = z.infer<typeof oneLivFormSocialMedia>;

interface SocialMediaFormProps {
  data?: FragmentType<typeof OneLivSocialMediaFormFragment>;
}

export const OneLivSocialMediaForm = ({ data }: SocialMediaFormProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivSocialMediaFormFragment, data);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(UpdateSocialMediaMutation);

  const navigate = useNavigate();

  const invalidateGetOneLivUserSocialMedia = useInvalidateQuery(
    GetOneLivUserSocialMediaDocument,
  );

  const getDefaultValues = (): OneLivFormSocialMediaValues => {
    const memberSocialMedias = fragmentResult?.locus_rede_social_membro;

    const instagram = memberSocialMedias?.find(
      (item) => item.redeSocial === String(SocialMediaEnum.Instagram),
    );
    const facebook = memberSocialMedias?.find(
      (item) => item.redeSocial === String(SocialMediaEnum.Facebook),
    );
    const linkedin = memberSocialMedias?.find(
      (item) => item.redeSocial === String(SocialMediaEnum.Linkedin),
    );
    const tiktok = memberSocialMedias?.find(
      (item) => item.redeSocial === String(SocialMediaEnum.TikTok),
    );
    const youtube = memberSocialMedias?.find(
      (item) => item.redeSocial === String(SocialMediaEnum.YouTube),
    );

    return {
      instagram: instagram?.url ?? "",
      facebook: facebook?.url ?? "",
      linkedin: linkedin?.url ?? "",
      tiktok: tiktok?.url ?? "",
      youtube: youtube?.url ?? "",
    };
  };

  const form = useForm<OneLivFormSocialMediaValues>({
    resolver: zodResolver(oneLivFormSocialMedia),
    defaultValues: getDefaultValues(),
  });

  const onSubmit = async (formData: OneLivFormSocialMediaValues): Promise<void> => {
    const onSuccess = (): void => {
      invalidateGetOneLivUserSocialMedia();

      void navigate({
        to: "/oneliv/additional-info",
      });
    };

    const socialMedia = Object.values(SocialMediaEnum).map((redeSocial) => ({
      name: redeSocial,
      url:
        formData[redeSocial.toLocaleLowerCase() as keyof OneLivFormSocialMediaValues] ??
        "",
    }));

    await mutateAsync(
      {
        input: {
          socialMedia,
        },
      },
      { onSuccess },
    );
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit}>
        <p className="font-medium text-neutral-600">
          Informe o link completo de suas redes sociais, caso tenha. Eles serão
          apresentados no seu perfil do OneLiv.
        </p>
        <FormField
          control={form.control}
          name="instagram"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  title="Instagram"
                  placeholder="Digite aqui"
                  inputMode="text"
                  type="text"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="facebook"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  title="Facebook"
                  placeholder="Digite aqui"
                  inputMode="text"
                  type="text"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="linkedin"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  title="Linkedin"
                  placeholder="Digite aqui"
                  inputMode="text"
                  type="text"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tiktok"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  title="TikTok"
                  placeholder="Digite aqui"
                  inputMode="text"
                  type="text"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="youtube"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  title="YouTube"
                  placeholder="Digite aqui"
                  inputMode="text"
                  type="text"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormSubmitButton type="submit">Avançar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
