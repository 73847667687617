import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { useNavigate } from "@tanstack/react-router";
import { useAtom } from "jotai";
import { type Patient } from "@repo/lib";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { AppointmentPatientList } from "@/components/appointment-patient-list";

const CreateAppointmentPatientListFragment = graphql(/* GraphQL */ `
  fragment CreateAppointmentPatientListFragment on query_root {
    ...AppointmentPatientListFragment
  }
`);

interface CreateAppointmentPatientListProps {
  data: FragmentType<typeof CreateAppointmentPatientListFragment>;
}

export const CreateAppointmentPatientList = ({
  data,
}: CreateAppointmentPatientListProps): JSX.Element => {
  const fragmentResult = useFragment(CreateAppointmentPatientListFragment, data);
  const [atomValues, setAtomValues] = useAtom(appointmentFormAtom);

  const navigate = useNavigate();

  const onAddPatientButtonClick = (): void => {
    setAtomValues({
      ...atomValues,
      patientId: undefined,
      name: "",
      phone: "",
      email: "",
      cpf: undefined,
      dateOfBirth: undefined,
    });

    void navigate({ to: "/appointment/create/patient" });
  };

  const onPatientSelected = (patient: Patient): void => {
    setAtomValues({
      ...atomValues,
      ...patient,
    });

    void navigate({ to: "/appointment/create/patient" });
  };

  return (
    <AppointmentPatientList
      onAddPatientButtonClick={onAddPatientButtonClick}
      onClick={onPatientSelected}
      data={fragmentResult}
    />
  );
};
