import { DrawerRoot, DrawerContent, DrawerTitle } from "@/components/drawer";
import { TimeSelector } from "@/components/time-selector";

interface AppointmentSlotSearchSelectorProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setSelectedTime: (selectedTime: { hour: number; minute: number }) => void;
  shouldAllowEveryHour?: boolean;
}

export const AppointmentSlotSearchSelector = ({
  open,
  setOpen,
  setSelectedTime,
  shouldAllowEveryHour = false,
}: AppointmentSlotSearchSelectorProps): JSX.Element => {
  const TIMER_CONFIGS = {
    initialHour: new Date().getHours() + 1,
    initialMinutes: 0,
    minHour: shouldAllowEveryHour ? 0 : 7,
    maxHour: shouldAllowEveryHour ? 24 : 22,
    minMinutes: 0,
    maxMinutes: 59,
    stepHour: 1,
    stepMinutes: 5,
  };

  return (
    <DrawerRoot open={open} setOpen={setOpen}>
      <DrawerContent>
        <DrawerTitle>Buscar horários</DrawerTitle>
        <TimeSelector
          onChange={(hour, minute) => {
            setSelectedTime({ hour, minute });
            setOpen(false);
          }}
          timerConfigs={TIMER_CONFIGS}
        />
      </DrawerContent>
    </DrawerRoot>
  );
};
