import { formatIgnoringTimezone } from "@repo/lib";
import React from "react";

interface AppointmentSummaryRootProps {
  children: React.ReactNode;
}

export const AppointmentSummaryRoot = ({
  children,
}: AppointmentSummaryRootProps): JSX.Element => {
  return <div className="flex flex-row gap-3 items-center">{children}</div>;
};

interface AppointmentSummaryBox {
  children: React.ReactNode;
}

export const AppointmentSummaryBox = ({
  children,
}: AppointmentSummaryBox): JSX.Element => {
  return (
    <div className="flex flex-col justify-center items-center bg-secondary-50 py-3 px-6 rounded-20 aspect-square">
      {children}
    </div>
  );
};

interface AppointmentSummaryBodyProps {
  children: React.ReactNode;
}

export const AppointmentSummaryBody = ({
  children,
}: AppointmentSummaryBodyProps): JSX.Element => {
  return <div className="flex flex-col gap-1 truncate w-fit">{children}</div>;
};

interface AppointmentSummaryTitleProps {
  children: React.ReactNode;
}

export const AppointmentSummaryTitle = ({
  children,
}: AppointmentSummaryTitleProps): JSX.Element => {
  return (
    <div className="flex flex-row justify-between">
      <p className="text-lg font-medium text-neutral-600 leading-5 truncate">
        {children}
      </p>
    </div>
  );
};

interface AppointmentSummaryDescriptionProps {
  endDateTime?: Date;
  startDateTime?: Date;
  unitName: string;
}

export const AppointmentSummaryDescription = ({
  endDateTime,
  startDateTime,
  unitName,
}: AppointmentSummaryDescriptionProps): JSX.Element => {
  const formatTime = (date: Date): string => formatIgnoringTimezone(date, "HH:mm");

  return (
    <p className="text-xs font-normal text-neutral-500">
      {startDateTime && endDateTime ? (
        <span>{`${formatTime(startDateTime)} - ${formatTime(endDateTime)}`}</span>
      ) : null}
      <span className="text-neutral-300"> | </span>
      <span>{unitName}</span>
    </p>
  );
};

interface AppointmentSummaryFooterProps {
  children: React.ReactNode;
  label?: string;
}

export const AppointmentSummaryFooter = ({
  children,
  label = "",
}: AppointmentSummaryFooterProps): JSX.Element => {
  return (
    <div className="flex flex-row justify-between">
      <p className="text-xs font-normal text-neutral-500">
        <span>{children}</span>
      </p>
      {Boolean(label) && (
        <p className="flex text-xs text-center items-center font-medium bg-helper-200 px-2 rounded-full self-end">
          {label}
        </p>
      )}
    </div>
  );
};
