import {
  Add,
  AddFill,
  Calendar,
  CalendarFill,
  Settings,
  SettingsFill,
  Sofa,
  SofaFill,
  Patients,
  PatientsFill,
} from "@repo/icons";
import { Link, type LinkProps, useSearch } from "@tanstack/react-router";

interface BarOptionsProps {
  url: LinkProps["to"];
  search?: LinkProps["search"];
  Icon: React.ElementType;
  SelectedIcon: React.ElementType;
}

interface SearchParams {
  date?: string;
  locationId?: number;
}

export const BottomBar = (): JSX.Element => {
  const searchParams: SearchParams = useSearch({ strict: false });

  const barOptions: Record<string, BarOptionsProps> = {
    calendar: {
      Icon: Calendar,
      SelectedIcon: CalendarFill,
      url: "/",
    },
    waitingRoom: {
      Icon: Sofa,
      SelectedIcon: SofaFill,
      url: "/waiting-room",
    },
    newSchedule: {
      Icon: Add,
      SelectedIcon: AddFill,
      url: "/appointment/create",
      search: { date: searchParams.date, locationId: searchParams.locationId },
    },
    patient: {
      Icon: Patients,
      SelectedIcon: PatientsFill,
      url: "/patients",
    },
    menu: {
      Icon: Settings,
      SelectedIcon: SettingsFill,
      url: "/settings",
    },
  };

  return (
    <div id="bottom-bar" className="z-20 fixed w-full bottom-0">
      <nav className="pt-3 w-full bg-white justify-evenly pb-4 shadow-2xl shadow-black">
        <div className="flex">
          {Object.entries(barOptions).map(([key, option]) => (
            <Link
              aria-label={`Botão ${key}`}
              key={key}
              to={option.url}
              search={option.search}
              className="flex w-full justify-center"
              preload={false}
            >
              {({ isActive }) => {
                const Icon = isActive ? option.SelectedIcon : option.Icon;
                return <Icon className="fill-secondary-400" size={32} />;
              }}
            </Link>
          ))}
        </div>
      </nav>
    </div>
  );
};
