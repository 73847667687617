import { cn } from "@repo/lib";

interface PageProps extends React.ComponentPropsWithoutRef<"div"> {
  children: React.ReactNode;
  className?: string;
}

/**
 * Page component that renders page content with standard page styling.
 *
 * @param children - Page component children.
 * @param className -  Page componente className
 * @returns JSX.Element - Rendered Page component.
 */
export const Page = ({ children, className, ...props }: PageProps): JSX.Element => {
  return (
    <div
      className={cn(
        "flex-1 container m-auto overflow-scroll scrollbar-hidden overscroll-none px-4 pt-4 pb-4",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
