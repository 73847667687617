/**
 * Função que converte um valor em centavos para o formato de moeda brasileira (Reais).
 *
 * @param amountInCents - Valor em centavos a ser convertido.
 * @returns Valor formatado em Reais e string.
 */
export const formatCurrencyFromCentsToReais = (amountInCents: number): string => {
  const amountInReais = amountInCents / 100;

  const formattedAmount = formatCurrency(amountInReais);

  return formattedAmount;
};

/**
 * Esta função recebe um valor numérico e uma moeda como parâmetros, e retorna o valor formatado na moeda especificada.
 * Se nenhuma moeda for especificada, o padrão será 'BRL'.
 *
 * @param value - O valor que será formatado.
 * @param currency - A moeda na qual o valor será formatado. O padrão é 'BRL'.
 * @param locale - O identificador de localidade que define a formatação da string numérica. O padrão é 'pt-BR'.
 *
 * @returns Retorna o valor formatado na moeda especificada em string.
 */
export const formatCurrency = (
  value: number,
  currency = "BRL",
  locale = "pt-BR",
): string => {
  return value.toLocaleString(locale, { style: "currency", currency });
};

/**
 * Esta função recebe uma string representando um valor monetário, remove todos os caracteres não numéricos,
 * remove zeros à esquerda e garante que a string resultante tenha pelo menos três dígitos, preenchendo com zeros à esquerda se necessário.
 *
 * @param value - A string representando o valor monetário a ser formatado.
 * @returns A string formatada com pelo menos três dígitos.
 */
export const sanitizeAndFormatAmount = (value: string): string => {
  const valueReplaced = value.replace(/\D/g, "").replace(/^0+/g, "").padStart(3, "0");

  const formattedAmount = (Number(valueReplaced) / 100).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedAmount;
};

/**
 * Esta função recebe uma string representando um valor monetário no formato brasileiro (ex: "1.000,30"),
 * remove os separadores de milhares e substitui a vírgula decimal por um ponto, e então converte a string para um número.
 *
 * @param value - A string representando o valor monetário a ser convertido.
 * @returns O valor convertido em número.
 */
export const convertBrazilianCurrencyToNumber = (value: string): number => {
  const sanitizedValue = value.replace(/\./g, "").replace(",", ".");
  const numberValue = Number(sanitizedValue);

  if (Number.isNaN(numberValue)) {
    throw new Error("Falha na conversão da string para número.");
  }

  return numberValue;
};

  /**
   * Formata um valor monetário em uma string no formato brasileiro.
   *
   * @param value - O valor a ser formatado como string.
   * @returns A string formatada representando o valor monetário, ex: 100,00.
   */
  export const formatCurrencyString = (value: string): string => {
    const valueReplaced = value
      .replace(/\D/g, "")
      .replace(/^00?0?/g, "")
      .padStart(3, "0");

    const formattedAmount = (Number(valueReplaced) / 100).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedAmount;
  };