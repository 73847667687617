import { useEffect } from "react";
import { useRouteContext } from "@tanstack/react-router";

export const usePendoInitializer = (): void => {
  const { user } = useRouteContext({ strict: false });

  useEffect(() => {
    if (user.codUsuario === 0) {
      return;
    }

    window.pendo?.initialize({
      visitor: {
        id: String(user.codUsuario),
        email: user.email ?? "",
      },
      account: {
        id: "Livance - App",
        accountName: "app users",
      },
      enableDesignerKeyboardShortcut: true,
    });
  }, [user]);
};
