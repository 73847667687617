import type { MinuteConfig } from "@/components/minutes-selector.tsx";

/**
 * Generates an array of time slots based on the provided parameters.
 *
 * @param startTime - The start time in the format "HH:MM".
 * @param currentPage - The current page number for pagination.
 * @param slotsPerPage - The number of slots to be generated per page.
 * @returns An array of time slots in the format "HH:MM".
 */
export function generateTimeSlots(
  startTime: string | undefined,
  currentPage: number,
  slotsPerPage: number,
): string[] {
  if (!startTime) {
    return [];
  }

  const slots: string[] = [];
  const [hours, minutes] = startTime.split(":").map(Number);
  const baseDate = new Date();
  baseDate.setHours(hours, minutes, 0, 0);

  const startIndex = (currentPage - 1) * slotsPerPage + (currentPage > 1 ? 1 : 0);
  const totalSlots = startIndex + slotsPerPage;

  for (let index = startIndex; index < totalSlots; index++) {
    if (currentPage === 1 && index === 2) continue;

    const offsetMinutes = (index - 2) * 5;
    const newDate = new Date(baseDate.getTime() + offsetMinutes * 60000);
    const formattedTime = `${newDate.getHours().toString().padStart(2, "0")}:${newDate.getMinutes().toString().padStart(2, "0")}`;
    slots.push(formattedTime);

    if (currentPage === 1 && slots.length < slotsPerPage && index === totalSlots - 1) {
      const extraOffsetMinutes = (index - 1) * 5;
      const extraDate = new Date(baseDate.getTime() + extraOffsetMinutes * 60000);
      const extraFormattedTime = `${extraDate.getHours().toString().padStart(2, "0")}:${extraDate.getMinutes().toString().padStart(2, "0")}`;
      slots.push(extraFormattedTime);
    }
  }

  return slots;
}

/**
 * Calculates the difference in minutes between two time slots.
 *
 * @param slotInicio - The start time slot in the format "HH:MM".
 * @param consultaInicioStr - The end time slot in the format "HH:MM".
 * @returns The difference in minutes between the two time slots.
 */

export function calculateMinuteDifference(
  slotInicio: string | null | undefined,
  consultaInicioStr: string,
): number {
  if (!slotInicio) return 0;

  const slotTime = new Date(slotInicio);
  const [hours, minutes] = consultaInicioStr.split(":").map(Number);
  const consultaTime = new Date(slotTime);
  consultaTime.setHours(hours, minutes, 0, 0);

  const diff = (slotTime.getTime() - consultaTime.getTime()) / 60000;

  return diff;
}

export interface MinuteConfigsTypes extends MinuteConfig {
  inicio: string | null | undefined;
  fim: string | null | undefined;
  reason: string | undefined;
}

/**
 * Formats an array of minute configurations for display.
 *
 * @param minuteConfigs - An array of minute configuration objects.
 * @returns An array of formatted minute configuration objects.
 */
export function formatSlotsForDisplay(
  minuteConfigs: MinuteConfigsTypes[],
): MinuteConfigsTypes[] {
  return minuteConfigs.map((config) => {
    const absValue = Math.abs(Number(config.value));
    if (absValue > 0) {
      return {
        ...config,
        value: Number(config.value) < 0 ? `-${absValue}` : `+${absValue}`,
      };
    }
    return config;
  });
}
