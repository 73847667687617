import { CpsInput, type CpsInputProps } from "corpus";
import { forwardRef, useState } from "react";
import { maskNumber } from "@repo/lib";

interface InputNumberProps extends CpsInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | number) => void;
}

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    { title, defaultValue = 0, onChange, ...props },
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const [numericValue, setNumericValue] = useState(defaultValue);

    const handleChangeElement = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.currentTarget;
      let maskedValue: number = maskNumber(value);
      maskedValue =
        props.min && maskedValue < Number(props.min) ? Number(props.min) : maskedValue;
      maskedValue =
        props.max && maskedValue > Number(props.max) ? Number(props.max) : maskedValue;

      if (onChange) {
        onChange(maskedValue);
      }

      setNumericValue(maskedValue);
    };

    return (
      <CpsInput
        ref={ref}
        title={title}
        value={numericValue}
        inputMode="numeric"
        type="number"
        onChange={handleChangeElement}
        {...props}
      />
    );
  },
);

InputNumber.displayName = "InputNumber";
