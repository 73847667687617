import { type ReactNode } from "react";

interface DatePickerRootProps {
  children: ReactNode;
}

export const DatePickerRoot = ({ children }: DatePickerRootProps): JSX.Element => {
  return (
    <div className="flex flex-col justify-center items-center flex-grow">{children}</div>
  );
};
