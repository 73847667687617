import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { DoorExit } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types/fragment-masking";
import { useEffect, useState, type ReactElement, useMemo } from "react";
import {
  preferencesStorageCache,
  zonedDate,
  formatWithZonedDate,
  logError,
} from "@repo/lib";
import { ActiveAllocationQueryDocument } from "@repo/graphql-types/graphql";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer.tsx";
import { ReturnRoomActionSearchParamsSchema } from "@/lib/action-schemas/return-room.ts";
import { useGraphQLMutation, useInvalidateQuery } from "@/hooks/use-graphql";
import { trackEvent } from "@/lib/tracking";
import { getTotalMinutes } from "@/lib/time";
import { TimeType, dateDiff } from "@/lib/date";
import { type CsatConfig } from "@/lib/launchdarkly.ts";

export const ReturnRoomDrawerFragment = graphql(/* GraphQL */ `
  fragment ReturnRoomDrawerFragment on agendamentos {
    name: nome
    startTime: horaInicio
    endTime: horaFim
    appointmentId: codAgendamento
    activeAllocation: AgendamentosAlocacoesAtivas {
      locationRoom: UnidadeSala {
        locationId: codUnidade
        roomType: codTipoSala
      }
    }
  }
`);

const ReturnRoomMutation = graphql(/* GraphQL */ `
  mutation ReturnRoomMutation {
    finalizaAlocacao {
      sucesso
    }
  }
`);

interface ReturnRoomDrawerProps {
  data?: FragmentType<typeof ReturnRoomDrawerFragment>;
}

interface CsatSearchParamsProps {
  appointmentId?: number;
  locationIdCsat?: number;
  roomType?: number;
}

const CSAT_DRAWER_KEY = "lastCsatDrawerDisplay";

export const ReturnRoomDrawer = ({
  data,
}: ReturnRoomDrawerProps): ReactElement | null => {
  const [csatSearchParams, setCsatSearchParams] = useState<CsatSearchParamsProps>({});
  const searchParams: Record<string, unknown> = useSearch({ strict: false });
  const router = useRouter();
  const { user, flags } = useRouteContext({ strict: false });

  const csatConfig = useMemo(() => flags["configuracao-banner-csat"], [flags]);

  const isOpen = ReturnRoomActionSearchParamsSchema.safeParse(searchParams).success;

  const { mutate, isPending } = useGraphQLMutation(ReturnRoomMutation);

  const queryData = useFragment(ReturnRoomDrawerFragment, data);

  const invalidateActiveAllocationQuery = useInvalidateQuery(
    ActiveAllocationQueryDocument,
  );

  useEffect(() => {
    if (queryData && queryData.activeAllocation.length > 0) {
      setCsatSearchParams({
        appointmentId: queryData.appointmentId,
        locationIdCsat: queryData.activeAllocation[0].locationRoom.locationId,
        roomType: queryData.activeAllocation[0].locationRoom.roomType,
      });
    }
  }, [queryData, queryData?.activeAllocation, queryData?.appointmentId]);

  if (!queryData) {
    return null;
  }

  const { name, startTime, endTime, appointmentId } = queryData;

  const shouldShowCsat = async (config: CsatConfig): Promise<boolean> => {
    if (!config.ativo) {
      return false;
    }
    const lastNotificationStr = String(
      (await preferencesStorageCache.get(CSAT_DRAWER_KEY)) || "",
    );

    const now: Date = new Date();
    if (!lastNotificationStr) {
      return true;
    }

    const lastNotification: Date = zonedDate(lastNotificationStr);
    const minutesBetween = dateDiff(lastNotification, now, TimeType.Minutes);
    const timeToShowAgain = getTotalMinutes(config.exibirNovamenteEm);

    if (minutesBetween >= timeToShowAgain) {
      await preferencesStorageCache.set(CSAT_DRAWER_KEY, now);
      return true;
    }

    return false;
  };

  const closeModal = (): void => {
    if (isOpen) {
      router.history.back();
    }
  };

  const onModalClose = (isOpening: boolean): void => {
    if (isOpening) {
      return;
    }

    closeModal();
  };

  const openCsat = (): void => {
    trackEvent("CSAT de Atendimento Visualizado", {
      codUsuario: user.codUsuario,
      codAgendamento: appointmentId,
    });

    void router.navigate({
      search: {
        ...searchParams,
        ...csatSearchParams,
        action: "csat",
      },
      replace: true,
    });
  };

  const updateLastCsatDrawerDisplay = async (): Promise<void> => {
    await preferencesStorageCache.set(CSAT_DRAWER_KEY, new Date());
  };

  const handleSuccess = async (): Promise<void> => {
    if (await shouldShowCsat(csatConfig)) {
      void updateLastCsatDrawerDisplay();
      openCsat();
    } else {
      closeModal();
    }
  };

  const returnRoom = (): void => {
    const onSuccess = (): void => {
      trackEvent("Sala Devolvida", {
        codUsuario: user.codUsuario,
        codAgendamento: appointmentId,
        hora: formatWithZonedDate(new Date(), "HH:mm"),
        minutosAtraso: searchParams.minutosAtraso,
        atrasado: searchParams.atrasado,
      });

      invalidateActiveAllocationQuery();

      void handleSuccess();
    };

    const onError = (error: Error): void => {
      logError(error);

      void router.navigate({
        search: { action: "error" },
      });
    };

    mutate({}, { onSuccess, onError });
  };

  return (
    <MessageDrawerRoot
      open={isOpen}
      icon={DoorExit}
      setOpen={(opening) => {
        onModalClose(opening);
      }}
      variant="secondary"
    >
      <MessageDrawerTitle>Tem certeza que deseja devolver a sala?</MessageDrawerTitle>
      <MessageDrawerBody>
        <p>
          Esta ação é referente ao atendimento de{" "}
          <span className="font-medium">{name}</span>, das{" "}
          <span className="font-medium">{startTime.substring(0, 5)}</span> às{" "}
          <span className="font-medium">{endTime.substring(0, 5)}</span>
        </p>
      </MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton onClick={returnRoom} loading={isPending}>
          Devolver
        </MessageDrawerActionButton>
        <MessageDrawerActionButton onClick={closeModal} secondary>
          Cancelar
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
