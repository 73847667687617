import { type FragmentType, useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { ExclamationCircle, Real } from "@repo/icons";
import { formatCurrencyString, logError } from "@repo/lib";
import { useRouteContext, useRouter } from "@tanstack/react-router";
import { CpsCheckboxGroup, CpsCheckboxV2 } from "corpus";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import { InputCurrency } from "@/components/input-currency.tsx";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form.tsx";
import { SelectInput } from "@/components/select-input.tsx";
import { type SelectDrawerItemProps } from "@/components/select-drawer.tsx";
import {
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer.tsx";

export const SendPaymentFormFragment = graphql(`
  fragment SendPaymentFormFragment on agendamentos {
    codAgendamento
    valor
    compromisso: UsuarioCompromisso {
      valor
    }
  }
`);

const SendPaymentMutation = graphql(`
  mutation SendPaymentMutation(
    $payment: expensum_event_totem_payment_sent_insert_input!
  ) {
    paymentSent: insert_expensum_event_totem_payment_sent_one(object: $payment) {
      id
    }
  }
`);

interface SendPaymentFormProps {
  data: FragmentType<typeof SendPaymentFormFragment>;
}

export const SendPaymentForm = ({ data }: SendPaymentFormProps): JSX.Element => {
  const { user } = useRouteContext({ strict: false });

  const appointment = useFragment(SendPaymentFormFragment, data);
  const { mutateAsync } = useGraphQLMutation(SendPaymentMutation);
  const router = useRouter();

  const [openDrawerFeedbackError, setOpenDrawerFeedbackError] = useState(false);

  const formSchema = z
    .object({
      amountToReceive: z.string().refine(
        (value) => {
          const numericValue = parseFloat(value.replace(",", "."));
          return !isNaN(numericValue) && numericValue > 0;
        },
        { message: "Campo obrigatório" },
      ),
      paymentMethods: z
        .array(z.enum(["credit", "debit"]))
        .nonempty("Selecione pelo menos uma forma de pagamento"),
      installments: z.number().int().min(1).optional(),
    })
    .transform((formSchemaData) => {
      return {
        ...formSchemaData,
        installments:
          formSchemaData.paymentMethods.length === 1 &&
          formSchemaData.paymentMethods.includes("debit")
            ? 1
            : formSchemaData.installments,
      };
    });

  type FormFields = z.infer<typeof formSchema>;

  const getDefaultAmount = (): string => {
    const amount = (appointment.valor || appointment.compromisso?.valor) ?? 0;

    return formatCurrencyString(String(amount * 100));
  };

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amountToReceive: getDefaultAmount(),
      paymentMethods: ["credit", "debit"],
      installments: 1,
    },
  });

  const handleSubmit = async (formData: FormFields): Promise<void> => {
    const { installments, amountToReceive, paymentMethods } = formData;
    const { codAgendamento } = appointment;

    const amountFloat =
      Number(amountToReceive.replace(/\D/g, "").replace(/^00?0?/g, "")) / 100;

    const payment = {
      amount: amountFloat,
      codAgendamento,
      codUsuario: Number(user.codUsuario),
      codClinica: Number(user.codClinica),
      credit: paymentMethods.includes("credit"),
      debit: paymentMethods.includes("debit"),
      installments,
    };

    const onError = (error: Error): void => {
      logError(error, { ...payment });
      setOpenDrawerFeedbackError(true);
    };

    const onSuccess = (): void => {
      void router.navigate({ to: "/waiting-room" });
    };

    await mutateAsync({ payment }, { onError, onSuccess });
  };

  const inputsInstallments: SelectDrawerItemProps<number>[] = [
    { label: "1x sem juros", value: 1 },
    { label: "2x sem juros", value: 2 },
    { label: "3x sem juros", value: 3 },
    { label: "4x sem juros", value: 4 },
    { label: "5x sem juros", value: 5 },
    { label: "6x sem juros", value: 6 },
    { label: "7x sem juros", value: 7 },
    { label: "8x sem juros", value: 8 },
    { label: "9x sem juros", value: 9 },
    { label: "10x sem juros", value: 10 },
    { label: "11x sem juros", value: 11 },
    { label: "12x sem juros", value: 12 },
  ];

  const paymentMethods = form.watch("paymentMethods");

  return (
    <>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-6">
            <FormField
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputCurrency
                      id="valor"
                      title="Valor a receber"
                      IconLeft={Real}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
              name="amountToReceive"
            />

            <div>
              <fieldset className="font-medium">Forma de pagamento</fieldset>
              <FormField
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <CpsCheckboxGroup direction="row" {...field}>
                        <CpsCheckboxV2
                          id="credit"
                          label="Crédito"
                          value="credit"
                          defaultChecked={paymentMethods.includes("credit")}
                        />
                        <CpsCheckboxV2
                          id="debit"
                          label="Débito"
                          value="debit"
                          defaultChecked={paymentMethods.includes("debit")}
                        />
                      </CpsCheckboxGroup>
                    </FormControl>
                  </FormItem>
                )}
                name="paymentMethods"
              />
            </div>

            {paymentMethods.includes("credit") ? (
              <FormField
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <SelectInput
                        name="installments-input"
                        items={inputsInstallments}
                        value={field.value}
                        onChange={field.onChange}
                        title="Parcelamento no cartão de crédito em"
                      />
                    </FormControl>
                  </FormItem>
                )}
                name="installments"
              />
            ) : null}

            <div className="flex flex-col">
              <div className="flex">
                <FormSubmitButton className="w-full">Confirmar</FormSubmitButton>
              </div>
            </div>
          </div>
        </FormHandlerSubmit>
      </FormRoot>
      <MessageDrawerRoot
        icon={ExclamationCircle}
        open={openDrawerFeedbackError}
        setOpen={setOpenDrawerFeedbackError}
        variant="primary"
      >
        <MessageDrawerTitle>Erro inesperado</MessageDrawerTitle>
        <MessageDrawerBody>
          Ocorreu um erro ao processar a sua solicitação, por favor, tente novamente.
        </MessageDrawerBody>
      </MessageDrawerRoot>
    </>
  );
};
