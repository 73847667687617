import { DatePayableStatus, formatWithZonedDate } from "@repo/lib";
import { CpsBadge } from "corpus";
import { useAtomValue } from "jotai";
import { Item, ItemContent, ItemMainContent } from "@/components/list";
import { type DayPayables } from "@/lib/mappers/payables";
import { amountVisibilityAtom } from "@/lib/atoms/amount-visibility";

interface PayablesListItemProps {
  dayPayables: DayPayables;
}

export const PayablesListItem = ({ dayPayables }: PayablesListItemProps): JSX.Element => {
  const { date, dayOfWeek, totalAmount, status } = dayPayables;

  const isAmountVisible = useAtomValue(amountVisibilityAtom);

  const formattedDate = formatWithZonedDate(date, "dd/MM/yyyy");

  const buildPayablesDateInformation = (): string => {
    return `${formattedDate}  ${dayOfWeek}`;
  };

  const buildAmountText = (amount: string): string => {
    if (!isAmountVisible) {
      return "R$ \u2022\u2022\u2022\u2022\u2022";
    }

    return amount;
  };

  const buildBadge = (): JSX.Element => {
    const badgeMap: Record<
      DatePayableStatus,
      "neutral" | "danger" | "warning" | "success" | "helper"
    > = {
      [DatePayableStatus.Foreseen]: "helper",
      [DatePayableStatus.Rejected]: "danger",
      [DatePayableStatus.ToBeConfirmed]: "helper",
      [DatePayableStatus.Paid]: "success",
    };

    return (
      <CpsBadge data-align="end" color={badgeMap[status]}>
        {status}
      </CpsBadge>
    );
  };

  return (
    <Item linkProps={{ to: "/settings/payments/payables/$date", params: { date } }}>
      <ItemContent>
        <p className="text-xs text-neutral-500 mb-1">{buildPayablesDateInformation()}</p>
        <ItemMainContent className="mb-2">
          {buildAmountText(totalAmount.formattedAmount)}
        </ItemMainContent>
      </ItemContent>
      {buildBadge()}
    </Item>
  );
};
