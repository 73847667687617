import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

export const AppointmentTypesListToSelectQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentTypesListToSelectQueryFragment on usuariosCompromissos {
    codUsuarioCompromisso
    nome
  }
`);
interface AppointmentTypesSelectInputProps
  extends Omit<SelectInputProps<number>, "items"> {
  data: FragmentType<typeof AppointmentTypesListToSelectQueryFragment>[];
}

export const AppointmentTypesSelectInput = forwardRef<
  HTMLInputElement,
  AppointmentTypesSelectInputProps
>(({ data, ...props }, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
  const appointmentTypes = useFragment(AppointmentTypesListToSelectQueryFragment, data);

  const items: SelectDrawerItemProps<number>[] = appointmentTypes.map(
    (appointmentType: { nome: string; codUsuarioCompromisso: number }) => {
      return {
        label: appointmentType.nome,
        value: appointmentType.codUsuarioCompromisso,
      };
    },
  );

  return <SelectInput<number> {...props} items={items} ref={ref} />;
});

AppointmentTypesSelectInput.displayName = "AppointmentTypesSelectInput";
