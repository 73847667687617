import { formatCurrency } from "@repo/lib";
import { CpsBadge } from "corpus";

interface BillingItemDiscountProps {
  discountValue?: number | null;
  billingItemValue: number;
}

export const BillingItemDiscount = ({
  discountValue,
  billingItemValue,
}: BillingItemDiscountProps): JSX.Element => {
  const hasDiscount = (): boolean => {
    return Boolean(discountValue);
  };

  return (
    <>
      {hasDiscount() && (
        <div className="flex flex-row items-center">
          <p className="font-normal text-neutral-500 text-sm pr-2">
            {formatCurrency(billingItemValue)}
          </p>
          <CpsBadge color="warning" size="default">
            {`- ${formatCurrency(Math.abs(discountValue ?? 0))}`}
          </CpsBadge>
        </div>
      )}
    </>
  );
};
