import { SVGIconProps } from "@repo/icons";
import clsx from "clsx";
import React, { FunctionComponent, forwardRef, memo } from "react";

interface ICpsTextArea extends React.ComponentPropsWithoutRef<"textarea"> {
  title?: string;
  helperText?: string;
  Icon?: FunctionComponent<SVGIconProps>;
  onIconClick?: () => void;
}

/**
 * `CpsTextArea` is a component that renders a text area.
 *
 * @remarks
 * This component is part of the CPS UI library.
 *
 * @example
 * Here is a basic usage example:
 * ```tsx
 * <CpsTextArea title="Campo de texto" />
 * ```
 * @param title - The title of the text area.
 * @param helperText - The helper text of the text area.
 * @param props - The props of the text area.
 *
 * @returns The `CpsTextArea` component.
 */
export const CpsTextArea = forwardRef<HTMLTextAreaElement, ICpsTextArea>(
  ({ title, helperText, Icon, onIconClick, ...props }, ref) => {
    const cpsTextAreaClasses = clsx(
      "mt-1 flex w-full rounded-md border-2 border-neutral-200 bg-white p-4 pr-12 text-sm font-normal text-neutral-600 transition-all duration-200 placeholder:text-neutral-400 disabled:cursor-not-allowed disabled:bg-neutral-50",
      !props.disabled &&
        "hover:border-primary-300 focus:border-primary-400 focus:ring-primary-200 focus:outline-none focus:ring",
    );

    return (
      <div className="w-full">
        {title && (
          <div className="flex gap-2 items-center">
            <label className="font-medium">
              {title}
              {props.required && "*"}
            </label>
            {Icon && onIconClick ? (
              <button onClick={onIconClick} tabIndex={0} type="button">
                <Icon className="fill-neutral-300" size={24} />
              </button>
            ) : null}
          </div>
        )}
        <div className="relative">
          <textarea className={cpsTextAreaClasses} {...props} ref={ref} />
          {helperText && (
            <span className="text-sm font-normal text-neutral-300">{helperText}</span>
          )}
        </div>
      </div>
    );
  },
);

const MemoCpsTextArea = memo(CpsTextArea);
MemoCpsTextArea.displayName = "CpsTextArea";

export default MemoCpsTextArea;
