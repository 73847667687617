import { Auth0Client } from "@auth0/auth0-spa-js";
import { QueryClient } from "@tanstack/react-query";
import {
  type UserContext,
  auth0Domain,
  clientId,
  preferencesStorageCache,
  callbackUri,
  audience,
  scope,
} from "@repo/lib";
import { type Client } from "graphql-ws";
import { createWsClient } from "@/lib/graphql-client.ts";
import type { FeatureFlags, LaunchDarklyClient } from "@/lib/launchdarkly.ts";

export interface RootRouteContext {
  authClient: Auth0Client;
  queryClient: QueryClient;
  ldClient: LaunchDarklyClient;
  wsClient: Client;
  user: UserContext;
  flags: FeatureFlags;
}

const queryClient = new QueryClient();

const authClient = new Auth0Client({
  domain: auth0Domain,
  clientId,
  useRefreshTokens: true,
  useRefreshTokensFallback: false,
  cache: preferencesStorageCache,
  authorizationParams: {
    redirect_uri: callbackUri,
    audience,
    scope,
  },
});

const wsClient = createWsClient(authClient);

const user = {
  apelido: "",
  codUsuario: 0,
  codClinica: 0,
  codPerfil: 0,
  bloqueado: false,
  ativo: false,
  nome: "",
  sobrenome: "",
  altaTaxaCancelamento: false,
  sub: "",
  codParceiroPortoSeguro: false,
  codConselhoClasse: "",
};

export const initialRootContext = {
  authClient,
  wsClient,
  queryClient,
  user,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Will be defined at root level
  ldClient: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Will be defined at root level
  flags: undefined!,
};
