import { CpsRadioButtonGroup, CpsRadioButtonV2 } from "corpus";
import { useParams, useRouter } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";

const channelSendPaymentTypeFormSchema = z.object({
  selectedChannel: z
    .string()
    .min(1, "Por favor, selecione como deseja enviar o pagamento."),
});
type ChannelSendPaymentTypeFormFields = z.infer<typeof channelSendPaymentTypeFormSchema>;

export const ChoosePaymentChannelForm = (): JSX.Element => {
  const { codAgendamento } = useParams({
    from: "/waiting-room/$codAgendamento/choose-payment-channel/",
  });
  const router = useRouter();

  const totemId = "totem";
  const onlineId = "online";

  const form = useForm<ChannelSendPaymentTypeFormFields>({
    resolver: zodResolver(channelSendPaymentTypeFormSchema),
    defaultValues: {
      selectedChannel: "",
    },
  });

  const onSubmit = (data: ChannelSendPaymentTypeFormFields): void => {
    const { selectedChannel } = data;

    if (selectedChannel === "online") {
      void router.navigate({
        to: "/settings/payments/create",
        search: { appointmentId: codAgendamento, origin: "Sala de espera" },
      });
    } else if (selectedChannel === "totem") {
      void router.navigate({
        to: "/waiting-room/$codAgendamento/send-totem-payment",
        params: { codAgendamento },
      });
    }
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit}>
        <h2 className="font-medium">Por onde quer enviar o seu pagamento?</h2>
        <FormField
          control={form.control}
          name="selectedChannel"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsRadioButtonGroup {...field}>
                  <CpsRadioButtonV2
                    id={onlineId}
                    key={onlineId}
                    value={onlineId}
                    label="Pagamento online"
                  />
                  <CpsRadioButtonV2
                    id={totemId}
                    key={totemId}
                    value={totemId}
                    label="Pagamento no totem"
                  />
                </CpsRadioButtonGroup>
              </FormControl>
            </FormItem>
          )}
        />
        <FormSubmitButton fullWidth>Avançar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
