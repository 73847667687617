import { useRouter, useSearch } from "@tanstack/react-router";
import {
  TooltipDrawerRoot,
  TooltipDrawerTitle,
  TooltipDrawerBody,
} from "@/components/tooltip-drawer";

export const OneLivHelperDrawer = (): JSX.Element => {
  const { action }: { action: string } = useSearch({ strict: false });

  const router = useRouter();

  const handleDrawerDismiss = (value: boolean): void => {
    if (!value) {
      router.history.back();
    }
  };

  return (
    <TooltipDrawerRoot setOpen={handleDrawerDismiss} open={action === "help"}>
      <TooltipDrawerTitle>Sobre o perfil OneLiv</TooltipDrawerTitle>
      <TooltipDrawerBody>
        <p className="mb-3">
          O OneLiv é um portal para alavancar a divulgação do seu consultório na Livance.
          É uma excelente oportunidade para aumentar sua divulgação e conquistar mais
          pacientes. Para conhecer mais, acesse o site:{" "}
          <a
            href="https://oneliv.com.br/"
            target="_blank"
            rel="noopener"
            className="text-blue-500 underline"
          >
            https://oneliv.com.br/
          </a>
        </p>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <p>
              <span className="font-medium text-neutral-600">Como funciona:</span>
            </p>
            <ul className="flex flex-col list-disc px-5">
              <li>
                Pacientes encontram e agendam consultas com profissionais da Livance de
                forma 100% online;
              </li>
              <li>Aumenta sua visibilidade para atrair potenciais novos pacientes;</li>
              <li>
                Com uma experiência premium, conecta você a pacientes que buscam
                atendimento diferenciado e estão dispostos a pagar por uma consulta
                particular;
              </li>
            </ul>
          </div>
          <div>
            <p>
              Para mais informações, acesse a{" "}
              <a
                href="https://ajuda.livance.com.br/hc/pt-br/articles/33265500395667-Como-funciona-o-OneLiv"
                target="_blank"
                rel="noopener"
                className="text-blue-500 underline"
              >
                central de ajuda.
              </a>
            </p>
          </div>
        </div>
      </TooltipDrawerBody>
    </TooltipDrawerRoot>
  );
};
