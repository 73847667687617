import { MapDaysOfWeekToFirstLetter, type DaysOfWeekEnum, cn } from "@repo/lib";

export interface DaysOfWeekListProps {
  days: DaysOfWeekEnum[];
  activeDays?: DaysOfWeekEnum[];
}

export const DaysOfWeekList = ({
  days,
  activeDays = [],
}: DaysOfWeekListProps): JSX.Element => {
  const checkIfDayMustBeChecked = (day: DaysOfWeekEnum): boolean => {
    return activeDays.some((activeDay) => activeDay === day);
  };

  return (
    <div className="flex flex-row gap-x-3">
      {days.map((day) => (
        <div
          key={`days-of-week-list-item-key-${day}`}
          data-testid={`days-of-week-list-item-${day}`}
          className={cn(
            "flex items-center justify-center font-medium",
            checkIfDayMustBeChecked(day) ? "text-gray-800" : "text-gray-300",
          )}
        >
          {MapDaysOfWeekToFirstLetter.get(day)}
        </div>
      ))}
    </div>
  );
};
