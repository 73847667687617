import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Dolar } from "@repo/icons";
import { ListRoot } from "@/components/list";
import { PatientPaymentListItem } from "@/components/patient-payments-list-item";
import {
  EmptyStateRoot,
  EmptyStateIcon,
  EmptyStateTitle,
} from "@/components/empty-state";

export const PatientPaymentsListFragment = graphql(/* GraphQL */ `
  fragment PatientPaymentsListFragment on ExpensumGetPaymentsOutput {
    payments {
      codAgendamento
      ...PatientPaymentsListItemFragment
    }
  }
`);

interface PatientPaymentsListProps {
  data: FragmentType<typeof PatientPaymentsListFragment>;
}

export const PatientPaymentsList = ({ data }: PatientPaymentsListProps): JSX.Element => {
  const { payments } = useFragment(PatientPaymentsListFragment, data);

  return (
    <ListRoot>
      {payments.length === 0 ? (
        <EmptyStateRoot>
          <EmptyStateIcon icon={Dolar} />
          <EmptyStateTitle>Nenhum pagamento encontrado</EmptyStateTitle>
        </EmptyStateRoot>
      ) : (
        payments.map((payment, index) => {
          return (
            <PatientPaymentListItem
              // eslint-disable-next-line react/no-array-index-key -- Não há identificar único
              key={`${payment?.codAgendamento ?? 0}-${index}`}
              data={payment}
            />
          );
        })
      )}
    </ListRoot>
  );
};
