import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { OneLivTreatmentFocusForm } from "@/components/oneliv/oneliv-treatment-focus-form";

const GetOneLivUserTreatmentFocus = graphql(`
  query GetOneLivUserTreatmentFocus {
    locus_membro {
      ...OneLivTreatmentFocusFormFragment
    }
  }
`);

const oneLivTreatmentFocusPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const OneLivTreatmentFocus = (): JSX.Element => {
  const query = useGraphQL(GetOneLivUserTreatmentFocus);

  const { data } = query;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Focos de tratamento" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...query}>
          {data ? <OneLivTreatmentFocusForm data={data.locus_membro} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/oneliv/treatment-focus")({
  component: OneLivTreatmentFocus,
  validateSearch: oneLivTreatmentFocusPageSearchSchema,
});
