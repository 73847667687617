import { useMutation, type UseMutationResult } from "@tanstack/react-query";
import { useRouteContext } from "@tanstack/react-router";
import { env } from "@repo/lib";
import { getAuthorizationHeaders } from "@/lib/authentication-headers";

export function useLivanceAPIMutation<TData>(
  path: string,
  init: RequestInit,
): UseMutationResult<TData> {
  const routeContext = useRouteContext({ strict: false });
  const { authClient } = routeContext;

  return useMutation({
    mutationFn: async (variables): Promise<TData> => {
      const response = await fetch(`${env.VITE_LIVANCE_API_URL}/${path}`, {
        ...init,
        headers: {
          ...init.headers,
          ...(await getAuthorizationHeaders(authClient)),
        },
        body: JSON.stringify(variables),
      });

      if (response.ok) return (await response.json()) as TData;

      throw new Error("Erro ao fazer a requisição");
    },
  });
}
