import { formatIgnoringTimezone, formatWithZonedDate } from "@repo/lib";
import { forwardRef, useState } from "react";
import { Calendar } from "@repo/icons";
import { CpsInput } from "corpus";
import {
  type CaptionLayout,
  type DateFormatter,
  type DateRange,
  DayPicker,
  type SelectRangeEventHandler,
} from "react-day-picker";
import { ptBR } from "date-fns/locale";
import "react-day-picker/dist/style.css";
import "@/styles/day-picker.css";
import { Button } from "@/components/button";
import { DatePickerRoot } from "@/components/date-picker-root";
import { DrawerContent, DrawerFooter, DrawerRoot } from "@/components/drawer";

interface DatePickerRangeProps {
  disabledPastDates?: Date;
  disabledFutureDates?: Date;
  captionLayout?: CaptionLayout;
  showOutsideDays?: boolean;
  fromYear?: number;
  toYear?: number;
  title?: string;
  onChange?: (date: DateRange) => void;
  value?: DateRange;
}

const MINIMAL_DATE = new Date(-8640000000000000);
const MAXIMUM_DATE = new Date(8640000000000000);

export const DatePickerRange = forwardRef<HTMLInputElement, DatePickerRangeProps>(
  (
    {
      disabledPastDates = MINIMAL_DATE,
      disabledFutureDates = MAXIMUM_DATE,
      captionLayout = "buttons",
      showOutsideDays = true,
      title = "Período específico",
      fromYear,
      toYear,
      onChange,
      value,
    }: DatePickerRangeProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(value);
    const [dateValues, setDateValues] = useState<DateRange>(
      value ?? {
        from: undefined,
        to: undefined,
      },
    );
    const formatMonthUpperInitial: DateFormatter = (date: Date) => {
      const formattedDate = formatWithZonedDate(date, "LLLL", ptBR);
      return (
        <>
          {formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)},{" "}
          {formatWithZonedDate(date, "yyyy", ptBR)}
        </>
      );
    };

    const resetUnsubmittedDate = (): void => {
      setSelectedRange({
        from: dateValues.from,
        to: dateValues.to,
      });
    };

    const openCalendarDrawer = (): void => {
      setOpen((isOpen) => !isOpen);
    };

    const handleRangeSelect: SelectRangeEventHandler = (range?: DateRange) => {
      setSelectedRange(range);
    };

    const submitSelectedDate = (): void => {
      const { from, to } = selectedRange ?? {};
      setDateValues({
        from,
        to,
      });
      onChange && onChange({ from, to });
      setOpen(false);
    };

    return (
      <>
        <div className="flex gap-3">
          <div className="w-full">
            <CpsInput
              cursorPointer
              id="date-picker-from"
              title={title}
              IconLeft={Calendar}
              ref={ref}
              onClick={openCalendarDrawer}
              onChange={(e) => e.preventDefault()}
              value={
                dateValues.from
                  ? formatIgnoringTimezone(dateValues.from, "dd/MM/yyyy")
                  : ""
              }
              placeholder="De"
            />
          </div>
          <div className="w-full self-end">
            <CpsInput
              cursorPointer
              id="date-picker-to"
              IconLeft={Calendar}
              ref={ref}
              onClick={openCalendarDrawer}
              onChange={(e) => e.preventDefault()}
              value={
                dateValues.to ? formatIgnoringTimezone(dateValues.to, "dd/MM/yyyy") : ""
              }
              placeholder="Até"
            />
          </div>
        </div>
        <DrawerRoot setOpen={setOpen} open={open} onClose={resetUnsubmittedDate}>
          <DrawerContent>
            <DatePickerRoot>
              <DayPicker
                mode="range"
                selected={selectedRange}
                onSelect={handleRangeSelect}
                today={new Date()}
                showOutsideDays={showOutsideDays}
                fixedWeeks
                disabled={{ before: disabledPastDates, after: disabledFutureDates }}
                locale={ptBR}
                captionLayout={captionLayout}
                fromYear={fromYear}
                toYear={toYear}
                defaultMonth={selectedRange?.from ?? new Date()}
                formatters={{ formatCaption: formatMonthUpperInitial }}
              />
            </DatePickerRoot>
            <DrawerFooter>
              <Button onClick={submitSelectedDate} fullWidth>
                Confirmar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerRoot>
      </>
    );
  },
);

DatePickerRange.displayName = "DatePickerRange";
