import {
  addMinutes,
  differenceInMinutes,
  isBefore,
  isSameDay,
  startOfHour,
  subMinutes,
} from "date-fns";
import type { CalendarEvent } from "../interfaces";
import {
  convertDateTimeIgnoringTimezone,
  setTimeWithoutZonedDate,
  zonedDate,
} from "./date";
import { calculateMinutesIntervalsBetweenDates, getNextFullHourOrHalfHour } from "./time";

export interface Unit {
  codUnidade: number;
  nome: string;
  sigla?: string;
}
const DEFAULT_SLOTS_DURATION = 30;
const MINIMUM_NONE_DURATION = 15;
const MINIMUM_HATCHED_AREA_DURATION_THRESHOLD = 7;

/* Start-Builders */
export const buildEmptyEvents = (
  existingEvents: CalendarEvent[],
  currentDate: Date,
  currentUnit: Unit,
): CalendarEvent[] => {
  const result: CalendarEvent[] = [];
  const initialEvents = buildFullDayEmptyEvent(currentDate, currentUnit);

  const currentUnitEvents = filterEventsByCurrentUnit(
    existingEvents,
    currentUnit.codUnidade,
  );

  const todayEvents = currentUnitEvents.filter((e) => isSameDay(e.start, currentDate));
  const notTodayEvents = currentUnitEvents.filter(
    (e) => !isSameDay(e.start, currentDate),
  );

  const sortedExistingEvents = todayEvents
    .slice()
    .sort((a, b) => a.start.getTime() - b.start.getTime());

  let index = 0;

  for (const initialEvent of initialEvents) {
    let currentStart = initialEvent.start;

    for (const existingEvent of sortedExistingEvents) {
      const localCurrentStart = currentStart;
      const hasClosestEvent = result.find((event) => localCurrentStart < event.end);
      const previousEventEnd = new Date(existingEvent.start.getTime() - 1);
      const isNoneEventValid = previousEventEnd > currentStart;

      if (!hasClosestEvent && isNoneEventValid) {
        result.push({
          type: "none",
          id: `none-x-${++index}`,
          title: "",
          appointmentType: "",
          start: currentStart,
          end: previousEventEnd,
          appointment: null,
          unit: {
            id: currentUnit.codUnidade,
            name: currentUnit.nome,
            acronym: currentUnit.sigla ?? "",
          },
        });
      }

      result.push(existingEvent);
      currentStart = new Date(existingEvent.end.getTime() + 1);
    }

    if (currentStart < initialEvent.end) {
      result.push({
        type: "none",
        id: `none-${++index}`,
        title: "",
        appointmentType: "",
        start: currentStart,
        end: initialEvent.end,
        appointment: null,
        unit: {
          id: currentUnit.codUnidade,
          name: currentUnit.nome,
          acronym: currentUnit.sigla ?? "",
        },
      });
    }
  }

  const eventsWithThirtyMinutesSlots = addThirtyMinutesSlotsToNoneEvents(result);

  return [...eventsWithThirtyMinutesSlots, ...notTodayEvents];
};

const isNonNoneEventInRange = (
  event: CalendarEvent,
  intervalStart: Date,
  intervalEnd: Date,
  events: CalendarEvent[],
  currentIndex: number,
): boolean => {
  const isNonNoneEvent = event.type !== "none";
  const isEventInRange =
    (event.start >= intervalStart && event.start < intervalEnd) ||
    (event.end > intervalStart && event.end <= intervalEnd) ||
    (event.start <= intervalStart && event.end >= intervalEnd);
  const isNotSameEvent = events.indexOf(event) !== currentIndex;

  return isNonNoneEvent && isEventInRange && isNotSameEvent;
};

const handleGenericEvents = (
  event: CalendarEvent,
  index: number,
  eventStart: Date,
  eventEnd: Date,
  events: CalendarEvent[],
  newEvents: CalendarEvent[],
): void => {
  const totalIntervals = calculateMinutesIntervalsBetweenDates(
    eventStart,
    eventEnd,
    DEFAULT_SLOTS_DURATION,
  );

  const lastIntervalStart = addMinutes(eventStart, totalIntervals * 30);
  const intervalEnd = addMinutes(
    lastIntervalStart,
    Math.min(DEFAULT_SLOTS_DURATION, differenceInMinutes(eventEnd, lastIntervalStart)),
  );
  const nextEvent = events[index + 1];
  const remainingIntervalMinutes = differenceInMinutes(lastIntervalStart, intervalEnd);
  const roundTimeToNextInterval = getNextFullHourOrHalfHour(nextEvent.start);
  const isShortInterval = remainingIntervalMinutes < DEFAULT_SLOTS_DURATION;

  const shouldCreateNoneAfterEvent =
    isShortInterval &&
    getNextFullHourOrHalfHour(nextEvent.start) > nextEvent.start &&
    remainingIntervalMinutes > 0 &&
    totalIntervals > 1 &&
    nextEvent.type === "none";

  if (shouldCreateNoneAfterEvent) {
    newEvents.push(event, {
      type: "none",
      id: `none-new-${newEvents.length + 1}`,
      title: "",
      appointmentType: "",
      start:
        remainingIntervalMinutes >= MINIMUM_HATCHED_AREA_DURATION_THRESHOLD
          ? intervalEnd
          : subMinutes(
              roundTimeToNextInterval,
              Math.max(MINIMUM_HATCHED_AREA_DURATION_THRESHOLD, remainingIntervalMinutes),
            ),
      end: getNextFullHourOrHalfHour(nextEvent.start),
      appointment: null,
      unit: event.unit,
    });
  } else {
    newEvents.push(event);
  }
};

const addThirtyMinutesSlotsToNoneEvents = (events: CalendarEvent[]): CalendarEvent[] => {
  const newEvents: CalendarEvent[] = [];

  events.forEach((event, index) => {
    const eventStart = startOfHour(new Date(event.start));
    const eventEnd = new Date(event.end);
    const totalIntervals = calculateMinutesIntervalsBetweenDates(
      eventStart,
      eventEnd,
      DEFAULT_SLOTS_DURATION,
    );

    if (event.type !== "none") {
      handleGenericEvents(event, index, eventStart, eventEnd, events, newEvents);
      return;
    }

    for (let i = 0; i < totalIntervals; i++) {
      const intervalStart = addMinutes(eventStart, i * DEFAULT_SLOTS_DURATION);
      const intervalEnd = addMinutes(
        intervalStart,
        Math.min(DEFAULT_SLOTS_DURATION, differenceInMinutes(eventEnd, intervalStart)),
      );

      if (differenceInMinutes(intervalEnd, intervalStart) <= MINIMUM_NONE_DURATION) {
        continue;
      }

      if (
        !events.some((e) =>
          isNonNoneEventInRange(e, intervalStart, intervalEnd, events, index),
        )
      ) {
        newEvents.push({
          type: "none",
          id: `none-new-${newEvents.length + 1}`,
          title: "",
          appointmentType: "",
          start: intervalStart,
          end: intervalEnd,
          appointment: null,
          unit: event.unit,
        });
      }
    }
  });

  return newEvents;
};

export const buildFullDayEmptyEvent = (
  currentDate: Date,
  currentUnit: Unit,
): CalendarEvent[] => {
  return [
    {
      id: "none",
      type: "none",
      title: "",
      appointmentType: "",
      start: setTimeWithoutZonedDate(currentDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
      end: setTimeWithoutZonedDate(currentDate, {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
      appointment: undefined,
      unit: {
        id: currentUnit.codUnidade,
        name: currentUnit.nome,
        acronym: currentUnit.sigla ?? "",
      },
    } as CalendarEvent,
  ];
};
/* End-Builders */

/* Start-Aux*/
export const filterEventsByCurrentUnit = (
  events: CalendarEvent[],
  codUnidade: number,
): CalendarEvent[] => {
  if (codUnidade === 0) {
    return events;
  }

  return events.filter((event) => {
    return !event.unit.id || event.unit.id === codUnidade;
  });
};

interface DefineAppointmentTypeProps {
  cancelado: boolean;
  confirmado: boolean;
  data: string;
  horaFim: string;
}

export const defineAppointmentType = (
  appointment: DefineAppointmentTypeProps,
): string => {
  if (appointment.cancelado) {
    return "cancelled";
  }

  if (
    isBefore(
      convertDateTimeIgnoringTimezone(appointment.data, appointment.horaFim),
      zonedDate(new Date()),
    )
  ) {
    return "old";
  }

  if (appointment.confirmado) {
    return "confirmed";
  }

  return "pending";
};
/* End-Aux */
