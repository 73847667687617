import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

export const OneLivCoursesSelectInputFragment = graphql(/* GraphQL */ `
  fragment OneLivCoursesSelectInputFragment on locus_curso_formacao {
    id
    nome
  }
`);

export interface OneLivCoursesSelectInputProps
  extends Omit<SelectInputProps<number>, "items"> {
  data?: FragmentType<typeof OneLivCoursesSelectInputFragment>[];
}

export const OneLivCoursesSelectInput = forwardRef<
  HTMLInputElement,
  OneLivCoursesSelectInputProps
>(
  (
    { data, ...props }: OneLivCoursesSelectInputProps,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const courses = useFragment(OneLivCoursesSelectInputFragment, data);

    const getItems = (): SelectDrawerItemProps<number>[] => {
      if (!courses || courses.length === 0) return [];

      const items: SelectDrawerItemProps<number>[] = courses.map((course) => {
        return {
          label: course.nome,
          value: course.id,
        };
      });

      return items;
    };

    const items = getItems();

    return <SelectInput<number> ref={ref} {...props} items={items} searchable />;
  },
);

OneLivCoursesSelectInput.displayName = "OneLivCoursesSelectInput";
