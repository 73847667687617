import { useRouter, useSearch } from "@tanstack/react-router";
import { CircleCheck } from "@repo/icons";
import {
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

export const AppointmentExtendedDrawer = (): JSX.Element => {
  const { action }: { action: string } = useSearch({ strict: false });
  const router = useRouter();
  const isOpen = action === "extend-time-success";

  const handleDrawerDismiss = (value: boolean): void => {
    if (!value && isOpen) {
      router.history.back();
    }
  };

  return (
    <MessageDrawerRoot
      icon={CircleCheck}
      variant="secondary"
      open={isOpen}
      setOpen={handleDrawerDismiss}
    >
      <MessageDrawerTitle>Atendimento estendido</MessageDrawerTitle>
      <MessageDrawerBody>
        Sua extensão de atendimento foi realizada com sucesso.
      </MessageDrawerBody>
    </MessageDrawerRoot>
  );
};
