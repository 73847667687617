import { type FragmentType, useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import {
  formatCurrency,
  formatIgnoringTimezone,
  removeSecondsFromTimeString,
} from "@repo/lib";
import { CpsBadge } from "corpus";
import { parseISO } from "date-fns";
import { TipoAlocacao } from "@repo/graphql-types/graphql";
import { BillingItemDiscount } from "@/components/billing-item-discount";
import { InfoSection } from "@/components/info-section";

export const BillingItemDetailsQueryFragment = graphql(/* GraphQL */ `
  fragment BillingItemDetailsQueryFragment on MovimentacaoExibicao {
    cobrado
    codCobranca
    codModalidadeFiscal
    codMovimentacao
    codOrigem
    codTipoMovimentacao
    codUnidade
    codUsuario
    dataCadastro
    dataVencimento
    identificacaoTipoMovimentacao
    valorUnitario
    valorDesconto
    valorAcrescimo
    valor
    tipoOrigem
    tempoUnitario
    quantidade
    possuiExtensao
    nomeTipoMovimentacao
    nomeUnidade
  }
`);

export const AllocationDetailsQueryFragment = graphql(/* GraphQL */ `
  fragment AllocationDetailsQueryFragment on AlocacaoDetalhada {
    horaFimCobrado
    horaFimDevolvido
    horaInicio
    partes {
      horaFim
      horaInicio
      taxaCobranca
      tipoAlocacao
    }
  }
`);

export const AppointmentDetailsQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentDetailsQueryFragment on agendamentos {
    nome
    cobraCancelamento
    codFinalidadeAgendamento
    data
    horaInicio
    horaFim
  }
`);

const parseTipoAlocacao = (tipoAlocacao: string): string => {
  const tipoAlocacaoMap: Record<string, string> = {
    ALOCACAO_PADRAO: "Agendamento",
    EXTENSAO: "Extensão de agendamento",
    ATRASO: "Atraso",
    PERIODO_GARANTIDO: "Período garantido",
  };

  return tipoAlocacaoMap[tipoAlocacao] || tipoAlocacao;
};

interface BillingItemDetailsProps {
  billingData: FragmentType<typeof BillingItemDetailsQueryFragment>;
  allocationData: FragmentType<typeof AllocationDetailsQueryFragment> | undefined;
  appointmentData: FragmentType<typeof AppointmentDetailsQueryFragment>;
}

export const BillingItemDetails = ({
  billingData,
  allocationData,
  appointmentData,
}: BillingItemDetailsProps): JSX.Element => {
  const billingItemDetails = useFragment(BillingItemDetailsQueryFragment, billingData);
  const allocationDetails = useFragment(AllocationDetailsQueryFragment, allocationData);
  const appointmentDetails = useFragment(
    AppointmentDetailsQueryFragment,
    appointmentData,
  );

  const allocationStartTime = removeSecondsFromTimeString(
    String(allocationDetails?.horaInicio),
  );
  const allocationEndTime = removeSecondsFromTimeString(
    String(allocationDetails?.horaFimDevolvido),
  );
  const chargedEndTime = removeSecondsFromTimeString(
    String(allocationDetails?.horaFimCobrado),
  );

  const appointmentStartTime = removeSecondsFromTimeString(
    String(appointmentDetails.horaInicio),
  );
  const appointmentEndTime = removeSecondsFromTimeString(
    String(appointmentDetails.horaFim),
  );
  const formattedAppointmentDate = formatIgnoringTimezone(
    parseISO(String(appointmentDetails.data)),
    "dd/MM/yyyy",
  );

  const appointmentDateTime = `${formattedAppointmentDate} - ${appointmentStartTime} - ${appointmentEndTime}`;

  const billingValue = Number(
    billingItemDetails.valor + billingItemDetails.valorAcrescimo,
  );
  const hasDiscount = billingItemDetails.valorDesconto < 0;

  const totalBillingValue = hasDiscount
    ? billingValue + Number(billingItemDetails.valorDesconto)
    : billingValue;

  const flexiblePrice = appointmentDetails.cobraCancelamento;
  const durationLimit = appointmentDetails.codFinalidadeAgendamento === 3;

  const section = {
    title: "",
    fields: [
      {
        label: "Unidade",
        value: billingItemDetails.nomeUnidade ?? "",
      },
      {
        label: "Paciente",
        value: appointmentDetails.nome ?? "",
      },
      {
        label: "Agendamento",
        value: appointmentDateTime,
      },
      ...(allocationDetails
        ? [
            {
              label: "Utilização",
              value: `${allocationStartTime} - ${allocationEndTime}`,
            },
            {
              label: "Informações de cobrança",
              value: "  ",
            },
          ]
        : []),
    ],
  };

  const detailsSection = {
    title: "",
    fields: allocationDetails?.partes.map((partialAllocation) => ({
      label: parseTipoAlocacao(partialAllocation.tipoAlocacao),
      labelTag:
        partialAllocation.tipoAlocacao === TipoAlocacao.Atraso &&
        partialAllocation.taxaCobranca > 0
          ? `${partialAllocation.taxaCobranca}x min`
          : undefined,
      value: `${removeSecondsFromTimeString(String(partialAllocation.horaInicio))} - ${removeSecondsFromTimeString(String(partialAllocation.horaFim))}`,
    })),
  };

  return (
    <div className="flex flex-col items-start justify-center">
      <p className="font-normal text-sm text-neutral-500">
        {billingItemDetails.nomeTipoMovimentacao}
      </p>
      <BillingItemDiscount
        billingItemValue={Number(
          billingItemDetails.valor + billingItemDetails.valorAcrescimo,
        )}
        discountValue={Number(billingItemDetails.valorDesconto)}
      />
      <p className="font-medium text-2xl text-neutral-700">
        {formatCurrency(totalBillingValue)}
      </p>

      <div className="w-full mt-6">
        <p className="font-medium">Detalhes do agendamento</p>

        {flexiblePrice || durationLimit ? (
          <div className="flex flex-row justify-between items-center py-6 border-b border-neutral-100">
            <p className="text-sm text-gray-600 font-normal">Modalidade</p>
            {flexiblePrice ? <CpsBadge color="primary">Preço flexível</CpsBadge> : null}
            {durationLimit ? <CpsBadge color="helper">Limite de duração</CpsBadge> : null}
          </div>
        ) : null}
        <InfoSection section={section} includeLastLine={false} lineColorLevel={300} />
        {detailsSection.fields && detailsSection.fields.length > 0 ? (
          <div className="mt-3 bg-neutral-50 rounded-40 w-full">
            <div className="px-3 text-sm">
              <InfoSection
                section={{ ...detailsSection, fields: detailsSection.fields }}
                includeLastLine
                lineColorLevel={300}
              />
            </div>
            <div className="flex justify-between px-3 py-4 font-medium">
              <p>Total</p>
              <p>{`${allocationStartTime} - ${chargedEndTime}`}</p>
            </div>
          </div>
        ) : null}
        <div className="mt-3 border-b border-spacing-30 border-b-neutral-100" />
      </div>
    </div>
  );
};
