import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { type z } from "zod";
import { type UseFormReturn } from "react-hook-form";
import { CpsInput } from "corpus";
import { FormField, FormItem, FormControl } from "@/components/form";
import { type oneLivFormBasicInfo } from "@/lib/form-schemas/oneliv-form-schema";
import { OneLivSpecialtySelectInput } from "@/components/oneliv/oneliv-specialty-select-input";

export type OneLivFormBasicInfoValues = z.infer<typeof oneLivFormBasicInfo>;

export const OneLivSpecialtiesInputFragment = graphql(/* GraphQL */ `
  fragment OneLivSpecialtiesInputFragment on query_root {
    specialties: locus_especialidade {
      id
      conselhoProfissionalEspecialidades(
        where: { conselhoProfissional: { ativo: { _eq: true } } }
      ) {
        idConselhoProfissional
      }
      ...OneLivSpecialtySelectInputFragment
    }

    professionalCouncil: locus_conselho_profissional(where: { sigla: { _eq: "CRM" } }) {
      id
    }
  }
`);

interface OneLivSpecialtiesInputProps {
  form: UseFormReturn<OneLivFormBasicInfoValues>;
  data?: FragmentType<typeof OneLivSpecialtiesInputFragment> | null;
}

export const OneLivSpecialtiesInput = ({
  data,
  form,
}: OneLivSpecialtiesInputProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivSpecialtiesInputFragment, data);

  const selectedProfessionalCouncil = form.watch("professionalCouncil");

  const specialties = fragmentResult?.specialties;

  const getValidSpecialties = (): typeof specialties => {
    if (selectedProfessionalCouncil) {
      return specialties?.filter((specialty) =>
        specialty.conselhoProfissionalEspecialidades.some(
          (conselhoProfissionalEspecialidade) =>
            conselhoProfissionalEspecialidade.idConselhoProfissional ===
            selectedProfessionalCouncil,
        ),
      );
    }

    return specialties;
  };

  const validSpecialties = getValidSpecialties();

  const CRM_ID = fragmentResult?.professionalCouncil[0].id;

  const shouldDisplayRQEInputs =
    selectedProfessionalCouncil && selectedProfessionalCouncil === CRM_ID;

  return (
    <>
      <FormField
        control={form.control}
        name="firstSpecialty"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <OneLivSpecialtySelectInput
                required
                title="Primeira especialidade"
                data={validSpecialties}
                {...field}
              />
            </FormControl>
          </FormItem>
        )}
      />

      {shouldDisplayRQEInputs ? (
        <FormField
          control={form.control}
          name="firstSpecialtyRQE"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  title="RQE da primeira especialidade (opcional)"
                  placeholder="Digite aqui"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
      ) : null}

      <FormField
        control={form.control}
        name="secondSpecialty"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <OneLivSpecialtySelectInput
                title="Segunda especialidade (opcional)"
                data={validSpecialties}
                {...field}
              />
            </FormControl>
          </FormItem>
        )}
      />

      {shouldDisplayRQEInputs && form.watch("secondSpecialty") !== 0 ? (
        <FormField
          control={form.control}
          name="secondSpecialtyRQE"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  title="RQE da segunda especialidade (opcional)"
                  placeholder="Digite aqui"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
      ) : null}

      <FormField
        control={form.control}
        name="thirdSpecialty"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <OneLivSpecialtySelectInput
                title="Terceira especialidade (opcional)"
                data={validSpecialties}
                {...field}
              />
            </FormControl>
          </FormItem>
        )}
      />

      {shouldDisplayRQEInputs && form.watch("thirdSpecialty") !== 0 ? (
        <FormField
          control={form.control}
          name="thirdSpecialtyRQE"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  title="RQE da terceira especialidade (opcional)"
                  placeholder="Digite aqui"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
      ) : null}
    </>
  );
};
