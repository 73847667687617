import { CpsAlert } from "corpus";
import {
  useSearch,
  useRouter,
  useNavigate,
  useRouteContext,
} from "@tanstack/react-router";
import { ExclamationCircle } from "@repo/icons";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
  MessageDrawerActionButton,
} from "@/components/message-drawer.tsx";
import { trackEvent } from "@/lib/tracking";

interface HighCancellationAlertProps {
  showMoreInfo?: boolean;
}

interface SearchParams {
  action?: string;
}

export const HighCancellationAlert = ({
  showMoreInfo = false,
}: HighCancellationAlertProps): JSX.Element => {
  const router = useRouter();
  const { user } = useRouteContext({ strict: false });
  const navigate = useNavigate();
  const params: SearchParams = useSearch({
    strict: false,
  });
  const isDrawerOpen = params.action === "show-high-cancellation-alert";
  const setDrawerOpen = (open: boolean): void => {
    if (!open && params.action === "show-high-cancellation-alert") {
      router.history.back();
    }
  };

  const handleMoreInfoClick = (): void => {
    trackEvent("Alerta Frequencia Cancelamento Clicado", {
      codUsuario: user.codUsuario,
      dataHora: new Date().toISOString(),
    });
    void navigate({
      search: { ...params, action: "show-high-cancellation-alert" },
    });
  };

  const handleCloseDrawer = (): void => {
    setDrawerOpen(false);
  };

  return (
    <>
      <CpsAlert
        title="Alta frequência de cancelamentos."
        type="info"
        linkPosition="bottom"
        linkTitle={showMoreInfo ? "Saiba mais" : undefined}
        linkProps={showMoreInfo ? { onClick: handleMoreInfoClick } : undefined}
      />
      {showMoreInfo ? (
        <MessageDrawerRoot
          open={isDrawerOpen}
          setOpen={setDrawerOpen}
          icon={ExclamationCircle}
          variant="secondary"
        >
          <MessageDrawerTitle>Cancelamentos frequentes</MessageDrawerTitle>
          <MessageDrawerBody>
            <p>Observamos uma frequência alta de cancelamentos em sua conta.</p>
            <p>O uso excessivo deste recurso prejudica toda a nossa comunidade.</p>
            <p>
              Siga as boas práticas estabelecidas em nosso{" "}
              <a
                href="https://ajuda.livance.com.br/hc/pt-br/articles/28454975059475-Pol%C3%ADtica-de-Uso-da-Plataforma-Livance"
                target="_blank"
                rel="noopener"
                className="text-[#0080a5] underline"
              >
                código de conduta.
              </a>
            </p>
          </MessageDrawerBody>
          <MessageDrawerActionButton onClick={handleCloseDrawer}>
            Entendi
          </MessageDrawerActionButton>
        </MessageDrawerRoot>
      ) : null}
    </>
  );
};
