import { Button, type ButtonProps } from "@/components/button";

interface AppointmentDrawerActionButtonProps extends ButtonProps {
  children: string;
  onClick: () => void;
}

export const AppointmentDrawerActionButton = ({
  onClick,
  children,
  ...props
}: AppointmentDrawerActionButtonProps): JSX.Element => {
  return (
    <Button onClick={onClick} {...props} fullWidth>
      {children}
    </Button>
  );
};
