import type { BillingListQueryFragmentFragment } from "@repo/graphql-types/graphql";
import { cn, formatWithZonedDate } from "@repo/lib";
import { Draggable } from "@/components/draggable";

interface BillingHeaderProps {
  billingList: BillingListQueryFragmentFragment[];
  currentBillingIndex: number;
  showBillingItensByBilling: (billingIndex: number) => void;
}

export const BillingHeader = ({
  billingList,
  currentBillingIndex,
  showBillingItensByBilling,
}: BillingHeaderProps): JSX.Element => {
  const getFormattedExpirationDate = (expirationDate: string): string => {
    const formattedExpirationDate = formatWithZonedDate(expirationDate, "MMM yy");
    return (
      formattedExpirationDate.charAt(0).toUpperCase() + formattedExpirationDate.slice(1)
    );
  };

  const scrollToItem = (index: number): void => {
    const item = document.getElementById(`billing-item-${index}`);
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  };

  const clickHandler = (index: number): void => {
    showBillingItensByBilling(index);
    scrollToItem(index);
  };

  return (
    <Draggable>
      <div className="flex flex-row-reverse overflow-x-scroll scrollbar-hidden gap-2 lg:pb-2">
        {Object.values(billingList)
          .sort((a, b) => (a.dataVencimento < b.dataVencimento ? 1 : -1))
          .map((billing, index: number) => (
            <div key={billing.codCobranca} id={`billing-item-${index}`}>
              <button
                className={cn(
                  "rounded-70 text-sm font-medium px-6 min-h-[36px] min-w-[100px] py-2",
                  index !== currentBillingIndex
                    ? "bg-neutral-50 text-neutral-300"
                    : "bg-secondary-400 text-secondary-400-contrast",
                )}
                disabled={false}
                onClick={() => clickHandler(index)}
                type="button"
              >
                {getFormattedExpirationDate(billing.dataVencimento)}
              </button>
            </div>
          ))}
      </div>
    </Draggable>
  );
};
