import { type FragmentType, useFragment } from "@repo/graphql-types";
import { graphql } from "@repo/graphql-types/gql";
import { CodUsuarioAcao } from "@repo/graphql-types/graphql";
import { CpsAlert } from "corpus";

export const BlockedActionsListAlertFragment = graphql(/* GraphQL */ `
  fragment BlockedActionsListAlertFragment on query_root {
    listaAcoesBloqueadas {
      codUsuarioAcao
    }
  }
`);

export interface BlockedAppointmentAlertProps {
  blockedActionsListFragmentData: FragmentType<typeof BlockedActionsListAlertFragment>;
}

export const BlockedAppointmentAlert = ({
  blockedActionsListFragmentData,
}: BlockedAppointmentAlertProps): JSX.Element | null => {
  const blockedActionsListFragment = useFragment(
    BlockedActionsListAlertFragment,
    blockedActionsListFragmentData,
  ).listaAcoesBloqueadas;

  if (!blockedActionsListFragment?.length) return null;

  const hasBlockedAppointment: boolean = blockedActionsListFragment.some(
    (blockedAction) =>
      blockedAction &&
      blockedAction.codUsuarioAcao.toString() === CodUsuarioAcao.Agendamento.toString(),
  );

  return hasBlockedAppointment ? (
    <div className="pt-4">
      <CpsAlert
        title="Você não tem permissão para fazer agendamentos"
        description="A restrição se deve à ausência do seu documento profissional ou forma de pagamento. Por favor, entre em contato com nosso time de Relacionamento com o Cliente e envie o documento necessário."
        linkTitle="Entrar em contato"
        link="https://wa.me/551132304410"
        linkPosition="bottom"
        type="info"
      />
    </div>
  ) : null;
};
