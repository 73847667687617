import { ExclamationCircle, Image as ImageIcon } from "@repo/icons";
import { CpsFeedbackBubble } from "corpus";
import { forwardRef, useEffect } from "react";
import { ModalAvatarOverlay } from "@/components/modal-avatar-overlay";

interface OneLivProfilePictureProps {
  isError?: boolean;
  shouldOpenModalOverlay?: boolean;
  isModalOverlayOpen?: boolean;
  value: Blob | string;
  setIsModalOverlayOpen?: (value: boolean) => void;
}

export const OneLivProfilePicture = forwardRef<
  HTMLButtonElement,
  OneLivProfilePictureProps
>(
  (
    {
      isError = false,
      shouldOpenModalOverlay = false,
      isModalOverlayOpen = false,
      setIsModalOverlayOpen,
      value,
    }: OneLivProfilePictureProps,
    ref,
  ): JSX.Element => {
    useEffect(() => {
      let imageSrc: string | undefined;
      if (value && value instanceof Blob) {
        imageSrc = URL.createObjectURL(value);
      }
      return () => {
        if (imageSrc) {
          URL.revokeObjectURL(imageSrc);
        }
      };
    }, [value]);

    if (isError) {
      return <CpsFeedbackBubble Icon={ExclamationCircle} fill="danger" size="large" />;
    }

    if (!value) {
      return (
        <div className="bg-neutral-50 p-[34px] h-[124px] w-[124px] rounded-full">
          <ImageIcon className="fill-neutral-300" size={56} />
        </div>
      );
    }

    const shouldOpen = shouldOpenModalOverlay && value;

    const getImageSrc = (): string => {
      if (value && value instanceof Blob) {
        return URL.createObjectURL(value);
      }

      return value;
    };

    const imageSrc = getImageSrc();

    const setModalOverlay = (open: boolean): void => {
      if (setIsModalOverlayOpen && shouldOpen) {
        setIsModalOverlayOpen(open);
      }
    };

    return (
      <>
        <button
          ref={ref}
          tabIndex={0}
          onClick={() => setModalOverlay(true)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setModalOverlay(true);
            }
          }}
          aria-label="Foto de perfil"
          type="button"
        >
          <img
            alt="Foto de perfil"
            data-testid="oneliv-profile-picture"
            className="rounded-full h-[124px] w-[124px] object-cover hover:cursor-pointer"
            src={imageSrc}
          />
        </button>
        {shouldOpenModalOverlay ? (
          <ModalAvatarOverlay
            src={imageSrc}
            alt="Foto de perfil"
            show={isModalOverlayOpen}
            setShow={() => setModalOverlay(false)}
          />
        ) : null}
      </>
    );
  },
);

OneLivProfilePicture.displayName = "OneLivProfilePicture";
