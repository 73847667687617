/// <reference types="vite/client" />
import { Capacitor } from "@capacitor/core";
import { env } from "./env";

//helpers
export const ENVIRONMENT = import.meta.env.MODE;
export const iosOrAndroid: boolean = Capacitor.isNativePlatform();
export const isAndroid = Capacitor.getPlatform() === "android";
export const isIos = Capacitor.getPlatform() === "ios";

const HTTP_SCHEME = "http://";
const HTTPS_SCHEME = "https://";
const WSS_SCHEME = "wss://";
const WS_SCHEME = "ws://";

//hasura
const HASURA_PROD_ENDPOINT = env.VITE_HASURA_ENDPOINT;
const HASURA_LOCAL_ENDPOINT = isAndroid
  ? "10.0.2.2:8080/v1/graphql"
  : "localhost:8080/v1/graphql";

export const HASURA_ENDPOINT =
  ENVIRONMENT === "production"
    ? `${HTTPS_SCHEME}${HASURA_PROD_ENDPOINT}`
    : `${HTTP_SCHEME}${HASURA_LOCAL_ENDPOINT}`;

export const HASURA_WEBSOCKETS_ENDPOINT =
  ENVIRONMENT === "production"
    ? `${WSS_SCHEME}${HASURA_PROD_ENDPOINT}`
    : `${WS_SCHEME}${HASURA_LOCAL_ENDPOINT}`;

//rudderstack
export const RUDDERSTACK_WRITE_KEY = env.VITE_RUDDERSTACK_WRITE_KEY;
export const RUDDERSTACK_DATA_PLANE_URL = env.VITE_RUDDERSTACK_DATA_PLANE_URL;

//configuração geral do app
export const APP_BUNDLE_URL = "br.com.livance"; // used to create the deep link
export const PROD_URL_NO_HTTPS = env.VITE_URL_NO_HTTPS;
export const FRONTEND_URL =
  ENVIRONMENT === "production" ? `https://${PROD_URL_NO_HTTPS}` : "http://localhost:3000";
export const IOS_APP_STORE_ID = "id1523716506";
export const IOS_APP_NAME = "livance";
export const WEB_LOCALE = "pt-br";
export const PLAYSTORE_URL = `https://play.google.com/store/apps/details?id=${APP_BUNDLE_URL}`;
export const COUNTRY_CODE_APPLE = "br";
export const APP_STORE_URL = `https://apps.apple.com/${COUNTRY_CODE_APPLE}/app/${IOS_APP_NAME}/${IOS_APP_STORE_ID}`;
export const storeUrlByPlatform = (): string | undefined => {
  if (iosOrAndroid) {
    return isAndroid ? PLAYSTORE_URL : APP_STORE_URL;
  }
  return undefined;
};

//auth0
export const auth0Domain = env.VITE_AUTH0_DOMAIN;
export const clientId = env.VITE_AUTH0_CLIENTID;
export const audience = env.VITE_AUTH0_AUDIENCE;
export const scope = env.VITE_AUTH0_SCOPE;
export const appId = "br.com.livance";
export const callbackUri: string = iosOrAndroid
  ? `${appId}://${auth0Domain}/capacitor/${appId}/auth/callback`
  : `${FRONTEND_URL}/auth/callback`;

export const appUrl: string = iosOrAndroid
  ? `${appId}://${auth0Domain}/capacitor/${appId}`
  : FRONTEND_URL;
export const DEEP_LINK_ALLOWED_URLS: string[] = [
  `${appId}://${auth0Domain}/capacitor/${appId}`,
  FRONTEND_URL,
];

export const issuer = env.VITE_AUTH0_ISSUER;

//date
export const SaoPauloTZ = "America/Sao_Paulo";

// APP - DISCOVERY
export const VITE_LAUNCHDARKLY_SDK_KEY = env.VITE_LAUNCHDARKLY_SDK_KEY;
export const VITE_SENTRY_DSN = env.VITE_SENTRY_DSN;
export const VITE_SENTRY_ENVIRONMENT = env.VITE_SENTRY_ENVIRONMENT;
export const VITE_PAGARME_ENCRYPTED_KEY = env.VITE_PAGARME_ENCRYPTED_KEY;
