import { MapDaysOfWeekToFullName } from "@repo/lib";
import {
  ActionSheetRoot,
  ActionSheetHeader,
  ActionSheetActionGroup,
  ActionSheetActionItem,
  ActionSheetHeaderContent,
} from "@/components/action-sheet";
import { InfoSection } from "@/components/info-section";

interface IndividualReservedScheduleActionSheetProps {
  open: boolean;
  setOpen: () => void;
  date: string;
  locationName: string;
  dayOfWeek?: number;
  period: string;
  cancelButtonAction: () => void;
}

export const IndividualReservedScheduleActionSheet = ({
  open,
  setOpen,
  date,
  locationName,
  dayOfWeek,
  period,
  cancelButtonAction,
}: IndividualReservedScheduleActionSheetProps): JSX.Element => {
  const reservedScheduleDetailsSection = {
    title: "",
    fields: [
      { label: "Data", value: date },
      { label: "Unidade", value: locationName },
      {
        label: "Dia da semana",
        value: dayOfWeek ? MapDaysOfWeekToFullName.get(dayOfWeek) : "",
      },
      {
        label: "Horário",
        value: period,
      },
    ],
  };

  return (
    <ActionSheetRoot showActionSheet={open} setShowActionSheet={setOpen}>
      <ActionSheetHeader>
        <ActionSheetHeaderContent>
          <InfoSection section={reservedScheduleDetailsSection} />
        </ActionSheetHeaderContent>
      </ActionSheetHeader>
      <ActionSheetActionGroup>
        <ActionSheetActionItem
          text="Cancelar período garantido"
          onClick={cancelButtonAction}
          variant="danger"
        />
      </ActionSheetActionGroup>
    </ActionSheetRoot>
  );
};
