import { useAtomValue } from "jotai";
import {
  DaysOfWeekToPortugueseAdverbial,
  RoomTypeEnum,
  formatCurrency,
  formatWithZonedDate,
} from "@repo/lib";
import { format } from "date-fns";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import {
  useNavigate,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { useResetAtom } from "jotai/utils";
import { CircleCheck, ExclamationCircle } from "@repo/icons";
import { type CreateReservedScheduleMutationMutation as CreateReservedScheduleMutationType } from "@repo/graphql-types/graphql";
import { Button } from "@/components/button";
import { InformationalList } from "@/components/list/informational-list";
import { type Info } from "@/components/list";
import {
  reservedScheduleFormAtom,
  reservedScheduleSlotFormAtom,
  reservedScheduleStartingDayFormAtom,
} from "@/lib/atoms/reserved-schedule-form-atom";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
  MessageDrawerActions,
  MessageDrawerActionButton,
} from "@/components/message-drawer";
import { trackEvent } from "@/lib/tracking";

export const GetReservedSchedulePriceFragment = graphql(`
  fragment GetReservedSchedulePriceFragment on query_root {
    ReservedSchedulePrice: LivanceApiSimulaCobrancaPeriodoGarantido(
      params: {
        codModalidadePeriodoGarantido: $codModalidadePeriodoGarantido
        codTipoSala: $codTipoSala
        codUnidade: $codUnidade
        codUsuario: $codUsuario
        duracao: $duracao
      }
    ) {
      price: valor
    }
  }
`);

export const CreateReservedScheduleMutation = graphql(`
  mutation CreateReservedScheduleMutation(
    $dataInicio: String!
    $horaInicio: String!
    $horaFim: String!
    $margemInicial: String!
    $codUsuario: Int!
    $codUnidade: Int!
    $codTipoSala: Int!
    $codModalidadePeriodoGarantido: Int!
    $codPeriodoGarantidoRecorrenteFrequencia: Int!
  ) {
    LivanceApiCriaPeriodoGarantidoRecorrente(
      periodoGarantidoRecorrente: {
        dataInicio: $dataInicio
        horaInicio: $horaInicio
        horaFim: $horaFim
        margemInicial: $margemInicial
        codUsuario: $codUsuario
        codUnidade: $codUnidade
        codTipoSala: $codTipoSala
        codModalidadePeriodoGarantido: $codModalidadePeriodoGarantido
        codPeriodoGarantidoRecorrenteFrequencia: $codPeriodoGarantidoRecorrenteFrequencia
      }
    ) {
      codPeriodoGarantidoRecorrente
      message
      success
    }
  }
`);

const MARGEM_INICIAL = "0";
interface ReservedScheduleConfirmProps {
  reservedSchedulePrice: FragmentType<typeof GetReservedSchedulePriceFragment>;
  reservedScheduleType: number;
}

export const ReservedScheduleConfirm = ({
  reservedSchedulePrice,
  reservedScheduleType,
}: ReservedScheduleConfirmProps): JSX.Element => {
  const data = useFragment(GetReservedSchedulePriceFragment, reservedSchedulePrice);
  const reservedScheduleAtom = useAtomValue(reservedScheduleFormAtom);
  const reservedScheduleSlotAtom = useAtomValue(reservedScheduleSlotFormAtom);
  const reservedScheduleStartingDayAtom = useAtomValue(
    reservedScheduleStartingDayFormAtom,
  );

  const formatTimePeriod = (slot: { horaFim: string; horaInicio: string }): string => {
    const { horaInicio, horaFim } = slot;

    return `${horaInicio.slice(0, 5)} - ${horaFim.slice(0, 5)}`;
  };
  const finalPrice: number = data.ReservedSchedulePrice?.price
    ? data.ReservedSchedulePrice.price
    : 0;

  const resetScheduleAtom = useResetAtom(reservedScheduleFormAtom);
  const resetScheduleSlotAtom = useResetAtom(reservedScheduleSlotFormAtom);
  const resetStartingDay = useResetAtom(reservedScheduleStartingDayFormAtom);
  const { user } = useRouteContext({
    strict: false,
  });
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useGraphQLMutation(CreateReservedScheduleMutation);

  const handleCloseDrawerSuccessAction = (): void => {
    resetStartingDay();
    resetScheduleAtom();
    resetScheduleSlotAtom();
    router.history.go(-5);
  };

  const submitCreateReservedSchedule = async (): Promise<void> => {
    const onSuccess = ({
      LivanceApiCriaPeriodoGarantidoRecorrente: response,
    }: CreateReservedScheduleMutationType): void => {
      if (response?.codPeriodoGarantidoRecorrente) {
        trackEvent("Periodo Garantido Criacao Confirmada", {
          codUsuario: user.codUsuario,
          codPeriodoGarantidoRecorrente: response.codPeriodoGarantidoRecorrente,
        });
      }

      void navigate({
        search: {
          action: "create-reserved-schedule-success",
        },
      });
    };

    const onError = (): void => {
      void navigate({
        search: {
          action: "create-reserved-schedule-error",
        },
      });
    };

    await mutateAsync(
      {
        codModalidadePeriodoGarantido: reservedScheduleType,
        codPeriodoGarantidoRecorrenteFrequencia: reservedScheduleAtom.frequency,
        codTipoSala: reservedScheduleAtom.roomType,
        codUnidade: reservedScheduleAtom.location,
        codUsuario: user.codUsuario,
        dataInicio: formatWithZonedDate(reservedScheduleStartingDayAtom.startingDay),
        horaFim: reservedScheduleSlotAtom.horaFim,
        horaInicio: reservedScheduleSlotAtom.horaInicio,
        margemInicial: MARGEM_INICIAL,
      },
      {
        onSuccess,
        onError,
      },
    );
  };
  const router = useRouter();
  const params = useSearch({
    from: "/settings/reserved-schedules/create/confirm",
  });

  const setOpenFeedbackDrawer = (value: boolean, action: string): void => {
    if (value && params.action === action) {
      void navigate({
        search: {
          action,
        },
      });
    } else if (!value && params.action === action) {
      if (params.action === "create-reserved-schedule-error") {
        router.history.back();
      }
      if (params.action === "create-reserved-schedule-success") {
        handleCloseDrawerSuccessAction();
      }
    }
  };

  const infos: Info[] = [
    {
      label: "Unidade",
      value: reservedScheduleAtom.locationName,
    },
    {
      label: "Tipo de sala",
      value: RoomTypeEnum[reservedScheduleAtom.roomType],
    },
    {
      label: "Dia da semana",
      value: `${DaysOfWeekToPortugueseAdverbial.get(reservedScheduleAtom.dayOfWeek)}`,
    },
    {
      label: "Data de início",
      value: format(reservedScheduleStartingDayAtom.startingDay, "dd/MM/yyyy"),
    },
    {
      label: "Horários",
      value: formatTimePeriod(reservedScheduleSlotAtom),
    },
    {
      label: "Valor do período",
      value: formatCurrency(finalPrice),
    },
  ];

  return (
    <>
      <InformationalList title="Revise os dados do seu período" infos={infos} />
      <div className="pt-6">
        <Button
          loading={isPending}
          onClick={() => void submitCreateReservedSchedule()}
          fullWidth
        >
          Criar período garantido
        </Button>
      </div>

      <MessageDrawerRoot
        icon={CircleCheck}
        open={params.action === "create-reserved-schedule-success"}
        setOpen={(value) =>
          setOpenFeedbackDrawer(value, "create-reserved-schedule-success")
        }
        variant="secondary"
      >
        <MessageDrawerTitle>Período criado com sucesso</MessageDrawerTitle>
        <MessageDrawerBody>
          Sempre que precisar, você poderá criar novos períodos.
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton onClick={handleCloseDrawerSuccessAction}>
            Ir para meus períodos
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>

      <MessageDrawerRoot
        open={params.action === "create-reserved-schedule-error"}
        setOpen={(value) =>
          setOpenFeedbackDrawer(value, "create-reserved-schedule-error")
        }
        icon={ExclamationCircle}
        variant="primary"
      >
        <MessageDrawerTitle>Erro ao criar período</MessageDrawerTitle>
        <MessageDrawerBody>
          Não conseguimos criar o seu período garantido. Entre em contato com o nosso time
          ou tente novamente.
        </MessageDrawerBody>
      </MessageDrawerRoot>
    </>
  );
};
