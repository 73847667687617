import { type CalendarEventType } from "@repo/lib";
import { CpsBadge } from "corpus";

interface AppointmentDrawerHeaderProps {
  appointmentType: CalendarEventType;
}

interface AppointmentDrawerBadgeProps {
  text: string;
  color: "neutral" | "danger" | "warning" | "success" | "helper";
}

const badgeByAppointmentType: Record<
  Event["type"],
  AppointmentDrawerBadgeProps | undefined
> = {
  pending: {
    text: "Pendente",
    color: "warning",
  },
  confirmed: {
    text: "Confirmado",
    color: "helper",
  },
  cancelled: {
    text: "Cancelado",
    color: "danger",
  },
};

export const AppointmentDrawerHeader = ({
  appointmentType,
}: AppointmentDrawerHeaderProps): JSX.Element => {
  const badgeData = badgeByAppointmentType[appointmentType];

  return (
    <>
      {badgeData ? (
        <div data-testid="appointment-drawer-badge" className="mb-4">
          <CpsBadge color={badgeData.color}>{badgeData.text}</CpsBadge>
        </div>
      ) : null}
    </>
  );
};
