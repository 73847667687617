import { createFileRoute } from "@tanstack/react-router";
import { Left, Stories } from "@repo/icons";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";

export const CustomerContactOverviewPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Visão Geral" align="center" />
        <HeaderButton icon={Stories} align="end" />
      </HeaderRoot>
      <Page>
        <div />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/customer-contact-overview/")({
  component: CustomerContactOverviewPage,
});
