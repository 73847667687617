import { DoorEnter, Exclamation } from "@repo/icons";
import {
  type CalendarEventType,
  cn,
  type DayEvents,
  formatTitleAsPatientName,
  getAppointmentDurationFromEvent,
  formatIgnoringTimezone,
} from "@repo/lib";
import { AppointmentPurpose } from "@repo/lib/src/enums";
import { CpsAvatar } from "corpus";
import { type ReactNode, useRef } from "react";
import { DayEventBadge, type DayEventBadgeProps } from "@/components/day-event-badge";

const eventTypes: Record<
  string,
  {
    badgeBackground: DayEventBadgeProps["variant"];
    background: string;
    icon: string;
  }
> = {
  pending: {
    background: "bg-primary-50",
    icon: "primaryLight",
    badgeBackground: "primary",
  },
  confirmed: {
    background: "bg-secondary-50",
    icon: "secondaryLight",
    badgeBackground: "secondary",
  },
  old: {
    background: "bg-neutral-100",
    icon: "neutralLight",
    badgeBackground: "neutral",
  },
  available: {
    background: "bg-success-50",
    icon: "bg-success-200",
    badgeBackground: "transparent",
  },
  availableLimited: {
    background: "bg-secondary-50",
    icon: "bg-secondary-100",
    badgeBackground: "secondary",
  },
  closed: {
    background: "bg-danger-50",
    icon: "bg-danger-200",
    badgeBackground: "transparent",
  },
  cancelled: {
    background: "bg-danger-50",
    icon: "dangerLight",
    badgeBackground: "danger",
  },
  none: {
    background: "bg-transparent",
    icon: "neutralLight",
    badgeBackground: "transparent",
  },
  weekSlot: {
    background: "bg-success-100",
    icon: "bg-success-200",
    badgeBackground: "transparent",
  },
  holiday: {
    background: "bg-danger-50",
    icon: "bg-danger-200",
    badgeBackground: "transparent",
  },
  timePolicy: {
    background: "bg-danger-50",
    icon: "bg-danger-200",
    badgeBackground: "transparent",
  },
};

export const DayEvent = ({
  type,
  start,
  end,
  appointmentType,
  appointmentPurposeId,
  unit,
  title,
}: DayEvents): JSX.Element => {
  let squareClass = cn(
    "rounded-20 h-full gap-2 w-full overflow-hidden flex p-2",
    eventTypes[type].background,
  );

  const durationOfAppointmentInMinutes = getAppointmentDurationFromEvent(start, end);
  let size: 40 | 24 | 56 | 72 | 96 | 156 = 40;
  let smallerEventClass = "";
  let formattedName: string;
  let descriptionSize: "small" | "medium" | "large" = "large";
  if (durationOfAppointmentInMinutes < 40 && durationOfAppointmentInMinutes >= 30) {
    descriptionSize = "medium";
  }
  if (durationOfAppointmentInMinutes < 30) {
    size = 24;
    smallerEventClass = "text-xs flex flex-row gap-2 items-center";
    squareClass += " items-center px-2 py-1";
    formattedName = formatTitleAsPatientName(title);
    descriptionSize = "small";
  }

  if (appointmentPurposeId === AppointmentPurpose.HorariosEstendidos) {
    formattedName = "Extensão de agendamento";
  }

  const patientAppointment = (): JSX.Element => {
    return (
      <DayEventRoot className={squareClass}>
        {appointmentPurposeId !== AppointmentPurpose.HorariosEstendidos ? (
          <DayEventPatientAvatar type={type} title={title} size={size} />
        ) : null}
        <DayEventContent className={smallerEventClass}>
          <div className="flex flex-row gap-2">
            <DayEventTitle title={formattedName ? formattedName : title} />
            {appointmentPurposeId === AppointmentPurpose.HorariosEstendidos ? (
              <div className={cn(descriptionSize !== "large" && "mx-1")}>
                <DayEventBadge
                  variant={eventTypes[type].badgeBackground}
                  title={`${durationOfAppointmentInMinutes} minutos`}
                />
              </div>
            ) : null}
          </div>
          {appointmentPurposeId !== AppointmentPurpose.HorariosEstendidos ? (
            <DayEventDescription
              appointmentType={appointmentType}
              unit={unit}
              start={start}
              end={end}
              descriptionSize={descriptionSize}
              type={type}
              durationOfAppointmentInMinutes={durationOfAppointmentInMinutes}
              appointmentPurposeId={appointmentPurposeId}
            />
          ) : null}
        </DayEventContent>
      </DayEventRoot>
    );
  };

  const slotAppointment = (available: boolean): JSX.Element => {
    return (
      <DayEventRoot className={cn(squareClass, "cursor-pointer")}>
        <DayEventIcon
          smallDescription={descriptionSize === "small"}
          available={available}
          type={type}
        />
        <DayEventContent className={smallerEventClass}>
          <DayEventTitle title={appointmentType || title} />
          <DayEventDescription
            unit={unit}
            start={start}
            end={end}
            descriptionSize={descriptionSize}
            type={type}
            durationOfAppointmentInMinutes={durationOfAppointmentInMinutes}
          />
          {type === "closed" && <p className="font-small text-neutral-600">{title}</p>}
        </DayEventContent>
      </DayEventRoot>
    );
  };

  const colorOnly = (): JSX.Element => (
    <DayEventRoot className="w-[6px] bg-success-200 h-full rounded-t-md rounded-b-md absolute -left-3">
      <div className="min-h-[35px] h-full w-full" />
    </DayEventRoot>
  );

  const noneElement = (): JSX.Element => (
    <DayEventRoot className={squareClass}>
      <div className="min-h-[35px] h-full w-full" />
    </DayEventRoot>
  );

  const actions = {
    pending: patientAppointment,
    confirmed: patientAppointment,
    old: patientAppointment,
    cancelled: patientAppointment,
    available: () => slotAppointment(true),
    availableLimited: () => slotAppointment(true),
    closed: () => slotAppointment(false),
    holiday: () => slotAppointment(false),
    timePolicy: () => slotAppointment(false),
    weekSlot: colorOnly,
    none: noneElement,
  };

  const action = actions[type];
  return action();
};

interface DayEventPatientAvatarProps {
  type: CalendarEventType;
  title: string;
  size: 24 | 40 | 56 | 72 | 96 | 156;
}
export const DayEventPatientAvatar = ({
  type,
  size,
  title,
}: DayEventPatientAvatarProps): JSX.Element => {
  return <CpsAvatar color={eventTypes[type].icon} initials={title} size={size} />;
};

interface DayEventIconProps {
  available: boolean;
  smallDescription: boolean;
  type: CalendarEventType;
}
export const DayEventIcon = ({
  type,
  available,
  smallDescription,
}: DayEventIconProps): JSX.Element => {
  return (
    <>
      {available ? (
        <DoorEnter
          className={`${eventTypes[type].icon} ${smallDescription ? "p-1" : "p-2"} rounded-full`}
          size={smallDescription ? 24 : 40}
        />
      ) : (
        <Exclamation
          className={cn(
            eventTypes[type].icon,
            smallDescription ? "p-1 min-w-1" : "p-2 min-w-10",
            "rounded-full",
          )}
          size={smallDescription ? 24 : 40}
        />
      )}
    </>
  );
};

export const DayEventTitle = ({ title }: { title: string }): JSX.Element => {
  return <b className="font-medium text-neutral-600 text-nowrap">{title}</b>;
};

interface DayEventDescriptionProps {
  start: Date;
  end: Date;
  appointmentType?: string;
  unit: {
    acronym: string;
  };
  descriptionSize: "small" | "medium" | "large";
  type: CalendarEventType;
  durationOfAppointmentInMinutes: number;
  appointmentPurposeId?: number | null;
}

export const DayEventDescription = ({
  start,
  end,
  appointmentType,
  unit,
  descriptionSize,
  type,
  durationOfAppointmentInMinutes,
  appointmentPurposeId,
}: DayEventDescriptionProps): JSX.Element => {
  const startFormatted = formatIgnoringTimezone(start, "HH:mm");
  const endFormatted = formatIgnoringTimezone(end, "HH:mm");

  const timePeriod = (
    <span key="patientName">
      {startFormatted} - {endFormatted}
    </span>
  );
  const unitAcronym = unit.acronym
    ? [
        <span
          key="pointMarker1"
          className="mx-2 mb-[2.5px] inline-block h-1 w-1 rounded-full bg-neutral-500"
        />,
        <span key="unitName">{unit.acronym}</span>,
      ]
    : null;

  const appointmentTypeSpan = appointmentType
    ? [
        <span
          key="pointMarker2"
          className="mx-2 mb-[2.5px] inline-block h-1 w-1 rounded-full bg-neutral-500"
        />,
        <span key="appointmentName">{appointmentType}</span>,
      ]
    : null;

  const descriptionComplement: Record<string, ReactNode[]> = {
    small:
      appointmentPurposeId === AppointmentPurpose.HorariosMenores
        ? [timePeriod]
        : [timePeriod, unitAcronym],
    medium:
      appointmentPurposeId === AppointmentPurpose.HorariosMenores
        ? [timePeriod, unitAcronym]
        : [timePeriod, unitAcronym, appointmentTypeSpan],
    large: [timePeriod, unitAcronym, appointmentTypeSpan],
  };

  return (
    <div
      className={cn(
        "truncate flex",
        descriptionSize === "large" ? "flex-col" : "flex-row",
      )}
    >
      <div className="truncate text-xs font-normal text-neutral-500">
        {...descriptionComplement[descriptionSize]}
      </div>
      {appointmentPurposeId === AppointmentPurpose.HorariosMenores ? (
        <div className={cn(descriptionSize !== "large" && "mx-1")}>
          <DayEventBadge
            variant={eventTypes[type].badgeBackground}
            title={`Limitado a ${durationOfAppointmentInMinutes} min`}
          />
        </div>
      ) : null}
    </div>
  );
};

interface DayEventContentProps {
  children: ReactNode;
  className?: string;
}

export const DayEventContent = ({
  children,
  className,
}: DayEventContentProps): JSX.Element => {
  return <div className={cn("min-w-10 max-w-full", className)}>{children}</div>;
};

interface DayEventRootProps {
  children: ReactNode;
  className?: string;
}

export const DayEventRoot = ({ children, className }: DayEventRootProps): JSX.Element => {
  const eventRef = useRef<HTMLDivElement>(null);
  return (
    <div data-testid="root" className={className} ref={eventRef}>
      {children}
    </div>
  );
};
