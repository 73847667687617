import { createFileRoute, useRouter } from "@tanstack/react-router";
import { HelpCircle, Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { GetOneLivUserBasicInfoDocument } from "@repo/graphql-types/graphql";
import { z } from "zod";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL, useInvalidateQuery } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { OneLivBasicInfoForm } from "@/components/oneliv/oneliv-basic-info-form";
import { OneLivHelperDrawer } from "@/components/oneliv/oneliv-helper-drawer";

const GetOneLivUserBasicInfo = graphql(`
  query GetOneLivUserBasicInfo {
    ...OneLivBasicInfoFormFragment
  }
`);

const oneLivBasicInfoPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const OneLivBasicInfo = (): JSX.Element => {
  const query = useGraphQL(GetOneLivUserBasicInfo);

  const { data } = query;

  const invalidateGetOneLivUserBasicInfo = useInvalidateQuery(
    GetOneLivUserBasicInfoDocument,
  );

  const router = useRouter();

  const onBackButtonClick = (): void => {
    invalidateGetOneLivUserBasicInfo();
    router.history.back();
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={onBackButtonClick} icon={Left} align="start" />
        <HeaderTitle title="Perfil OneLiv" align="center" />
        <HeaderButton
          align="end"
          icon={HelpCircle}
          onClick={() =>
            void router.navigate({
              search: { action: "help" },
            })
          }
        />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...query}>
          {data ? <OneLivBasicInfoForm data={data} /> : null}
          <OneLivHelperDrawer />
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/oneliv/basic-info")({
  component: OneLivBasicInfo,
  validateSearch: oneLivBasicInfoPageSearchSchema,
});
