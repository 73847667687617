export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Remove caracteres não numéricos de uma string.
 *
 * @param value - A string a ser formatada.
 * @returns A string contendo apenas números.
 */
export const formatStringToNumber = (value: string): string => {
  if (!value) return value;
  const formattedValue = value.replace(/\D/g, "");
  return formattedValue;
};

/**
 * Função que trata possíveis casos de strings vazias ou com apenas espaços
 *
 * @param cpf - Valor da string
 * @returns Valor da string ou undefined
 */
export const stringOrUndefined = (value?: string): string | undefined => {
  if (!value) return undefined;

  const isStringWithOnlyEmptySpaces = value.trim() === "";

  return isStringWithOnlyEmptySpaces ? undefined : value;
};
