import { Left } from "@repo/icons";
import { createFileRoute, useNavigate, useRouteContext } from "@tanstack/react-router";
import "@/styles/calendar.css";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { formatWithZonedDate } from "@repo/lib";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  appointmentFormAtom,
  isPatientStepConcluded,
} from "@/lib/atoms/appointment-form-atom";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { AppointmentFlexiblePriceForm } from "@/components/appointment-flexible-price-form";

const AppointmentFlexiblePricePageQuery = graphql(`
  query AppointmentFlexiblePricePageQuery(
    $codTipoSala: [Int!]
    $codUnidade: Int!
    $codUsuario: Int!
    $data: date!
    $horaInicio: time!
  ) {
    ...AppointmentFlexiblePriceFormFragment
  }
`);

const appointmentFlexiblePricingPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
  codAgendamento: z.number().optional(),
});

export const AppointmentFlexiblePricingPage = (): JSX.Element => {
  const [atomValues] = useAtom(appointmentFormAtom);

  const { unit, appointmentDate, appointmentTime, roomTypes } = atomValues;

  const { user } = useRouteContext({ from: "/appointment/create/flexible-pricing" });

  const navigate = useNavigate();

  useEffect(() => {
    if (!isPatientStepConcluded(atomValues)) {
      void navigate({ to: "/" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Apenas no carregamento inicial da página
  }, []);

  const queryResult = useGraphQL(AppointmentFlexiblePricePageQuery, {
    codUnidade: unit,
    codUsuario: user.codUsuario,
    data: formatWithZonedDate(appointmentDate ?? new Date()),
    horaInicio: formatWithZonedDate(appointmentTime.start, "HH:mm"),
    codTipoSala: roomTypes,
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Novo agendamento" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <AppointmentFlexiblePriceForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/appointment/create/flexible-pricing")({
  component: AppointmentFlexiblePricingPage,
  validateSearch: appointmentFlexiblePricingPageSearchSchema,
  beforeLoad: ({ context }) => {
    return {
      showCreatedAppointmentFeedbackDrawer:
        context.ldClient.getShowCreatedAppointmentFeedbackDrawer(),
    };
  },
  loader: ({ context }) => {
    const minimumAntecedence =
      context.ldClient.getMinimumAntecedenceForFlexiblePriceAppointmentCreation();

    return { minimumAntecedence };
  },
});
