import {
  Certificate,
  HelpCircle,
  Invite,
  Left,
  ReceiptMoney,
  Referral,
  Share,
} from "@repo/icons";
import {
  createFileRoute,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { Share as CapacitorShare } from "@capacitor/share";
import { CpsToast } from "corpus";
import { useState } from "react";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  EmptyStateRoot,
  EmptyStateImage,
  EmptyStateTitle,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateBody,
} from "@/components/empty-state";
import { trackEvent } from "@/lib/tracking.ts";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
} from "@/components/message-drawer";

export const ReferralPage = (): JSX.Element => {
  const { user } = useRouteContext({ strict: false });
  const router = useRouter();
  const searchParams = useSearch({ from: "/settings/referral/" });

  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const setShowTooltipInformationDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/referral",
        search: { action: "info" },
      });
    } else if (searchParams.action === "info") {
      router.history.back();
    }
  };

  const shareLink = async (): Promise<void> => {
    const codUsuario = user.codUsuario;

    trackEvent("Botão Clicado", {
      codUsuario,
      label: "Compartilhar Link",
      page: "/settings/referral/",
    });

    const url = `https://livance.me/conheca-a-livance?utm_content=${codUsuario}`;

    // if browser does not support share API, copy link to clipboard
    const canShare = await CapacitorShare.canShare();
    if (!canShare.value) {
      await navigator.clipboard.writeText(url);
      setOpenCopiedLinkToast(true);
    }

    await CapacitorShare.share({
      title: "Venha para a Livance!",
      text: "Olá! Gostaria de compartilhar com você meu link de indicação da Livance. Através desse link, você poderá se cadastrar e agendar uma visita para conhecer uma das unidades da Livance. Espero que aproveite a oportunidade!",
      url,
    });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Indique e ganhe" align="center" />
        <HeaderButton
          icon={HelpCircle}
          onClick={() => {
            setShowTooltipInformationDrawer(true);
          }}
          align="end"
        />
      </HeaderRoot>
      <Page>
        <EmptyStateRoot>
          <EmptyStateImage
            icon={Referral}
            alt="Imagem de indique e ganhe"
            className="w-full h-[30vh]"
          />
          <div className="items-center justify-center flex flex-col gap-2 mx-auto max-w-[360px]">
            <EmptyStateTitle>Gentileza gera gentileza</EmptyStateTitle>
            <EmptyStateDescription>
              O programa de indicações da Livance no qual você é recompensado!
            </EmptyStateDescription>
          </div>
          <EmptyStateBody>
            <ul className="flex flex-col gap-2 px-6 py-4 text-sm font-normal text-neutral-600">
              <li className="flex gap-3">
                <Invite className="fill-primary-400" size={32} />
                <span className="self-end">
                  Indique a Livance para um colega através do link abaixo
                </span>
              </li>
              <li className="flex gap-2">
                <Certificate className="fill-primary-400 self-center" size={24} />
                <span className="self-end">
                  Seu contato se torna profissional da Livance
                </span>
              </li>
              <li className="flex gap-2">
                <ReceiptMoney className="fill-primary-400" size={32} />
                <span className="self-end">
                  Você recebe um <b>crédito de R$180</b> em sua próxima fatura
                </span>
              </li>
            </ul>
          </EmptyStateBody>
          <EmptyStateButton
            Icon={Share}
            onClick={() => {
              void shareLink();
            }}
            fullWidth
          >
            Compartilhar link
          </EmptyStateButton>
        </EmptyStateRoot>
        <CpsToast
          title="Link copiado para a área de transferência"
          show={openCopiedLinkToast}
          onClose={() => setOpenCopiedLinkToast(false)}
          type="success"
          duration={10}
        />
        <MessageDrawerRoot
          variant="secondary"
          open={searchParams.action === "info"}
          setOpen={setShowTooltipInformationDrawer}
        >
          <MessageDrawerTitle>Gentileza gera gentileza</MessageDrawerTitle>
          <MessageDrawerBody>
            <p className="text-left">
              O programa Gentileza Gera Gentileza da Livance permite que você, como
              membro, indique profissionais da saúde para se juntarem à nossa comunidade e
              seja recompensado por isso. Ao indicar um colega que se torne membro, você
              recebe um crédito de R$180 na sua próxima fatura.
            </p>
            <p className="text-left">
              Esta é a nossa maneira de agradecer e incentivar você a compartilhar a
              experiência Livance com outros profissionais, contribuindo para o
              crescimento de uma rede de consultórios compartilhados de alta qualidade.{" "}
            </p>
            <p className="text-left">
              Participe e ajude a transformar a experiência de atendimentos em
              consultórios!
            </p>
            <p className="text-left">
              Para indicar, clique no link de indicação, compartilhe com seu colega e
              aguarde a confirmação do cadastro e visita agendada. Juntos, vamos construir
              uma comunidade ainda mais forte e colaborativa.
            </p>
          </MessageDrawerBody>
        </MessageDrawerRoot>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/referral/")({
  component: ReferralPage,
});
