import { CircleCheck } from "@repo/icons";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

interface ReservedScheduleCancelSuccessDrawerProps {
  description: string;
  open: boolean;
  primaryButtonText: string;
  primaryButtonAction: () => void;
  secondaryButtonText: string;
  secondaryButtonAction: () => void;
}

export const ReservedScheduleCancelSuccessDrawer = ({
  description,
  open,
  primaryButtonText,
  primaryButtonAction,
  secondaryButtonText,
  secondaryButtonAction,
}: ReservedScheduleCancelSuccessDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot
      variant="secondary"
      icon={CircleCheck}
      open={open}
      setOpen={() => {
        /* do not allow close without redirect */
      }}
      dismissible={false}
    >
      <MessageDrawerTitle>Período garantido cancelado</MessageDrawerTitle>
      <MessageDrawerBody>{description}</MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton onClick={primaryButtonAction}>
          {primaryButtonText}
        </MessageDrawerActionButton>
        <MessageDrawerActionButton onClick={secondaryButtonAction} secondary>
          {secondaryButtonText}
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
