import { useEffect, useState } from "react";

// Beware: This will make the component re-render every second.
export function useVariableNow(enabled: boolean): Date {
  const [now, setNow] = useState(new Date());

  // const { data: offsetTime } = useQuery({
  //   queryKey: ["clock", "offsetTime"],
  //   queryFn: async () => {
  //     const localDate = new Date();
  //     const response = await fetch(HASURA_ENDPOINT);
  //     const serverDateString = response.headers.get("Date");
  //
  //     if (!serverDateString) {
  //       throw new Error("Invalid Date header");
  //     }
  //
  //     const serverDate = new Date(serverDateString);
  //
  //     const timeOffset = differenceInSeconds(localDate, serverDate);
  //
  //     // Não nos preocupemos com peixe pequeno - Latência do servidor
  //     return Math.abs(timeOffset) > 2 ? timeOffset : 0;
  //   },
  //   staleTime: 60 * 60 * 1000, // 60 minutes
  // });

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);

    if (!enabled) {
      clearInterval(interval);
      return;
    }

    return () => {
      clearInterval(interval);
    };
  }, [enabled]);

  return now;
}
