import { LivanceTypedError } from "@/lib/livance-typed-error.tsx";

interface ErrorType {
  __typename?: string | null;
  message: string;
}

type ResponseDataType = Record<
  string,
  {
    errors?: ErrorType[] | null;
  }
>;

export function throwOnError<TData extends ResponseDataType>(
  data: Record<string, TData>,
): void {
  const responseData = Object.values(data)[0];

  if (Array.isArray(responseData.errors) && responseData.errors.length > 0) {
    const error = responseData.errors[0] as ErrorType;
    throw new LivanceTypedError(error.message, error.__typename ?? "UnexpectedError");
  }
}
