import { graphql } from "@repo/graphql-types/gql";
import { Bank, Dolar, Left, ReceiptMoney } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { CpsAlert } from "corpus";
import { type InvalidRecipientStatus } from "@repo/lib";
import { useGraphQL } from "@/hooks/use-graphql";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { RecipientEmptyStateWrapper } from "@/components/recipient-empty-state";

export const ManagePaymentsQuery = graphql(`
  query ManagePaymentsQuery($codUsuario: Int!, $codClinica: Int) {
    expensumRecipient: ExpensumGetRecipient(
      arg1: { codUsuario: $codUsuario, codClinica: $codClinica }
    ) {
      recipient {
        status
      }
    }
  }
`);

const menuItems: MenuListItem[] = [
  {
    name: "Pagamentos",
    Icon: Dolar,
    route: "/settings/payments/patient-payments",
  },
  {
    name: "Recebimentos",
    Icon: ReceiptMoney,
    route: "/settings/payments/payables",
  },
  {
    name: "Conta de recebimento",
    Icon: Bank,
    route: "/settings/payments/bank-account",
  },
];

const recipientInvalidStatuses: InvalidRecipientStatus[] = [
  "refused",
  "suspended",
  "inactive",
  "blocked",
];

export const ManagePaymentsPage = (): JSX.Element => {
  const { user } = Route.useRouteContext();

  const queryData = useGraphQL(ManagePaymentsQuery, {
    codUsuario: Number(user.codUsuario),
    codClinica: Number(user.codClinica),
  });

  const { data } = queryData;

  const recipientStatus = data?.expensumRecipient?.recipient?.status;
  const hasRecipientInvalidStatus = recipientInvalidStatuses.some(
    (invalidStatus) => invalidStatus === recipientStatus,
  );

  return (
    <div className="min-h-screen bg-white">
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Gerenciar Pagamentos" align="center" />
      </HeaderRoot>
      <Page className="pt-2">
        <AsyncDataWrapper {...queryData}>
          <RecipientEmptyStateWrapper>
            {hasRecipientInvalidStatus ? (
              <CpsAlert
                title="Não é possível gerenciar novos pagamentos no momento, apenas visualizar o histórico. Dúvidas? Contate o time de Relacionamento."
                description=""
                type="info"
              />
            ) : null}
            <MenuList menuItems={menuItems} />
          </RecipientEmptyStateWrapper>
        </AsyncDataWrapper>
      </Page>
    </div>
  );
};

export const Route = createFileRoute("/settings/payments/")({
  component: ManagePaymentsPage,
});
