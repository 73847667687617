import { cn } from "@repo/lib";
import { CpsButton, type CpsButtonProps } from "corpus";
import { forwardRef } from "react";

export type ButtonProps = Omit<CpsButtonProps, "iconClassName">;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <CpsButton
        className={cn(
          "bg-secondary-400 text-secondary-400-contrast hover:bg-secondary-400 focus:ring-secondary-300",
          className,
        )}
        iconClassName="fill-secondary-400-contrast"
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = "Button";
