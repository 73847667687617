import { useNavigate } from "@tanstack/react-router";
import { useAtom, useSetAtom } from "jotai";
import { ExclamationCircle } from "@repo/icons";
import { CpsAlert } from "corpus";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { type z } from "zod";
import { ptBR } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
import {
  reservedScheduleFormAtom,
  reservedScheduleSlotFormAtom,
  reservedScheduleStartingDayFormAtom,
} from "@/lib/atoms/reserved-schedule-form-atom";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";
import { reservedScheduleStartingDayInfo } from "@/lib/form-schemas/reserved-schedule-schema";
import { filterDaysOfWeek, formatMonthUpperInitial } from "@/lib/date";

export const ReservedScheduleStartingDayForm = (): JSX.Element => {
  const selectedSlot = useAtom(reservedScheduleSlotFormAtom);
  const reservedScheduleAtom = useAtom(reservedScheduleFormAtom);
  const setReservedScheduleStartingDayFormAtom = useSetAtom(
    reservedScheduleStartingDayFormAtom,
  );
  const selectedDate = new Date(`${selectedSlot[0].data}T00:00`);
  type ReservedScheduleStartingDayForm = z.infer<typeof reservedScheduleStartingDayInfo>;
  const form = useForm<ReservedScheduleStartingDayForm>({
    resolver: zodResolver(reservedScheduleStartingDayInfo),
    defaultValues: {
      startingDay: selectedDate,
    },
  });

  const isCalendarDisabled = (date: Date): boolean => {
    return (
      date < selectedDate ||
      filterDaysOfWeek(reservedScheduleAtom[0].dayOfWeek).includes(date.getDay())
    );
  };

  const navigate = useNavigate();
  const handleSubmit = (formData: ReservedScheduleStartingDayForm): void => {
    setReservedScheduleStartingDayFormAtom(formData);
    void navigate({ to: "/settings/reserved-schedules/create/confirm" });
  };

  return (
    <div className="flex flex-col gap-6 items-center">
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={handleSubmit}>
          <span className="text-neutral-600  font-medium">Defina a data de início</span>
          <FormField
            control={form.control}
            name="startingDay"
            render={({ field }) => (
              <FormItem className="m-auto">
                <FormControl>
                  <DayPicker
                    {...field}
                    mode="single"
                    showOutsideDays
                    fixedWeeks
                    selected={field.value}
                    onSelect={field.onChange}
                    locale={ptBR}
                    captionLayout="dropdown-buttons"
                    defaultMonth={selectedDate}
                    disabled={(date) => isCalendarDisabled(date)}
                    formatters={{ formatCaption: formatMonthUpperInitial }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <CpsAlert
            title="Datas apresentadas com base na disponibilidade da unidade."
            description=""
            Icon={ExclamationCircle}
            type="info"
          />
          <FormSubmitButton>Avançar</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>
    </div>
  );
};
