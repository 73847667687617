import forge from "node-forge";
import { VITE_PAGARME_ENCRYPTED_KEY } from "@repo/lib";

interface PagarmeResponseTypes {
  public_key: string;
  id: string;
}

export const getEncodedCard = async (
  cardNumber: string,
  cardName: string,
  cardExpiry: string,
  cardCvc: string,
): Promise<string> => {
  const options = {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const pagarmeResponse = await fetch(
    `https://api.pagar.me/1/transactions/card_hash_key?api_key=${VITE_PAGARME_ENCRYPTED_KEY}`,
    options,
  );

  let queryString = `card_number=${cardNumber.replace(/\s/g, "")}&card_holder_name=${cardName}&card_expiration_date=${cardExpiry}&card_cvv=${cardCvc}`;
  queryString = encodeURI(queryString);

  const { public_key: publicKeyPem, id } =
    (await pagarmeResponse.json()) as PagarmeResponseTypes;

  const publicKey: forge.pki.rsa.PublicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  const encryptedData: string = publicKey.encrypt(queryString, "RSAES-PKCS1-V1_5");
  const base64Encoded: string = forge.util.encode64(encryptedData);

  return `${id}_${base64Encoded}`;
};
