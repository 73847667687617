/* eslint-disable import/no-duplicates -- Necessário, pois o import do ptBR do date-fns não estava funcionando corretamente. */
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  differenceInWeeks,
  endOfMonth,
  format as formatDatefns,
  isBefore,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { ptBR } from "date-fns/locale";
import { SaoPauloTZ } from "../utils/config";
import { ScheduleFrequencyEnum } from "../enums";

/**
 * Função que converte uma data ou string de data para o fuso horário especificado.
 *
 * @param date - Objeto Date ou string de data a ser convertido.
 * @param timezone - Fuso horário para o qual a data deve ser convertida (padrão: SaoPauloTZ).
 * @returns Objeto Date convertido para o fuso horário especificado.
 */
export const zonedDate = (date: Date | string, timezone = SaoPauloTZ): Date => {
  return utcToZonedTime(date, timezone);
};

/**
 * Função que formata uma data ou string de data para uma representação de string
 * com um formato específico, fazendo a transformação da data para o fuso de São Paulo.
 *
 * @param date - Objeto Date ou string de data a ser formatado.
 * @param dateFormat - Formato desejado para a representação da data (padrão: "yyyy-MM-dd").
 * @param locale - Localidade para formatação da representação da data (padrão: ptBR).
 * @returns Representação formatada da data.
 */
export const formatWithZonedDate = (
  date: Date | string,
  dateFormat = "yyyy-MM-dd",
  locale = ptBR,
): string => {
  const utcToZoned = zonedDate(date, SaoPauloTZ);
  return formatDatefns(utcToZoned, dateFormat, { locale });
};

/**
 * Função que formata uma data ou string de data para uma representação de string
 * com um formato específico, sem considerar o fuso horário.
 *
 * @param date - Objeto Date ou string de data a ser formatado.
 * @param dateFormat - Formato desejado para a representação da data (padrão: "yyyy-MM-dd").
 * @param locale - Localidade para formatação da representação da data (padrão: ptBR).
 * @returns Representação formatada da data sem considerar o fuso horário.
 */
export const format = (
  date: Date | string,
  dateFormat = "yyyy-MM-dd",
  locale = ptBR,
): string => {
  if (typeof date === "string") {
    return formatDatefns(new Date(date), dateFormat, { locale });
  }

  return formatDatefns(date, dateFormat, { locale });
};

/**
 * Função que ajusta as propriedades de horas, minutos, segundos e milissegundos
 * de um objeto Date, considerando o fuso horário de São Paulo.
 *
 * @param date - Objeto Date original.
 * @param Object - Objeto contendo propriedades opcionais para horas, minutos, segundos e milissegundos.
 * @param hours - Horas a serem configuradas (opcional, padrão: 0).
 * @param minutes - Minutos a serem configurados (opcional, padrão: 0).
 * @param seconds - Segundos a serem configurados (opcional, padrão: 0).
 * @param milliseconds - Milissegundos a serem configurados (opcional, padrão: 0).
 * @returns Objeto Date ajustado com as novas configurações.
 */
export const setTime = (
  date: Date | string,
  {
    hours,
    minutes,
    seconds,
    milliseconds,
  }: { hours?: number; minutes?: number; seconds?: number; milliseconds?: number },
): Date => {
  const dateZoned = zonedDate(date, SaoPauloTZ);
  dateZoned.setHours(hours ?? 0, minutes ?? 0, seconds ?? 0, milliseconds ?? 0);
  return dateZoned;
};

/**
 * Função que ajusta as propriedades de horas, minutos, segundos e milissegundos.
 *
 * @param date - Objeto Date original.
 * @param Object - Objeto contendo propriedades opcionais para horas, minutos, segundos e milissegundos.
 * @param hours - Horas a serem configuradas (opcional, padrão: 0).
 * @param minutes - Minutos a serem configurados (opcional, padrão: 0).
 * @param seconds - Segundos a serem configurados (opcional, padrão: 0).
 * @param milliseconds - Milissegundos a serem configurados (opcional, padrão: 0).
 * @returns Objeto Date ajustado com as novas configurações.
 */
export const setTimeWithoutZonedDate = (
  date: Date,
  {
    hours,
    minutes,
    seconds,
    milliseconds,
  }: { hours?: number; minutes?: number; seconds?: number; milliseconds?: number },
): Date => {
  date.setHours(hours ?? 0, minutes ?? 0, seconds ?? 0, milliseconds ?? 0);
  return date;
};

/**
 * Função que retorna data atual no fuso horário de São Paulo.
 *
 * @returns - Objeto Date com a data de hoje.
 */

export const getCurrentDate = (): Date => {
  const today = startOfDay(zonedDate(new Date()));

  return today;
};

/**
 * Função que retorna uma data com base em um deslocamento a partir da data atual no fuso horário de São Paulo.
 *
 * @param daysOffset - O número de dias para deslocar a partir da data atual. Um valor negativo indica dias anteriores.
 * @returns - Objeto Date com a data deslocada.
 */
export const getDateFromToday = (daysOffset: number): Date => {
  const today = getCurrentDate();

  const desiredDate = addDays(today, daysOffset);

  return desiredDate;
};

/**
 * Esta função recebe uma data e um tempo como strings, e retorna um objeto Date.
 * O objeto Date é criado no fuso horário de São Paulo e o horário é definido com base na string de tempo fornecida.
 *
 * @param date - A data como uma string no formato 'YYYY/MM/DD' ou um objeto Date.
 * @param time - O tempo como uma string no formato 'HH:MM'.
 * @returns Retorna um objeto Date com a data e o horário fornecidos, no fuso horário de São Paulo.
 */
export const getTimeDateFromStrings = (date: string | Date, time: string): Date => {
  const timeSplited = time.split(":");

  const dateZoned = zonedDate(date, SaoPauloTZ);
  dateZoned.setHours(Number(timeSplited[0]), Number(timeSplited[1]), 0, 0);

  return dateZoned;
};

/**
 * Esta função recebe uma data e hora como string ou objeto Date, e retorna o total de minutos desde a meia-noite.
 * O objeto Date é criado no fuso horário de São Paulo.
 *
 * @param dateTime - A data e hora como uma string no formato 'HH:MM:SS' ou um objeto Date.
 * @returns Retorna o total de minutos desde a meia-noite, arredondado para o número inteiro mais próximo.
 */
export const getMinutesFromDateTime = (dateTime: string | Date): number => {
  const zonedData =
    dateTime instanceof Date
      ? zonedDate(dateTime)
      : getTimeDateFromStrings(zonedDate(new Date()), dateTime);
  const minutes =
    zonedData.getMinutes() + zonedData.getHours() * 60 + zonedData.getSeconds() / 60;

  return Math.round(minutes);
};

/**
 * Esta função recebe string no formato HH:mm:ss e retorna um objeto contendo as horas e os minutos.
 *
 * @param time - Uma string no formato HH:mm:ss.
 * @returns Retorna um objeto contendo as horas e os minutos.
 */
export const getHourAndMinutesFromHHmmss = (
  time: string,
): { hour: number; minute: number } => {
  const timeRegex = /^(?<hours>\d{2}):(?<minutes>\d{2}):(?<seconds>\d{2})$/;
  const match = timeRegex.exec(time);

  if (!match) {
    return {
      hour: 0,
      minute: 0,
    };
  }

  const [, hours, minutes] = match.map(Number);

  return {
    hour: hours,
    minute: minutes,
  };
};

/**
 * Esta função recebe valores numéricos representando horas, minutos e segundos, e retorna uma string no formato 'HH:MM:SS'.
 * Os valores de horas devem estar no intervalo de 0 a 23, os valores de minutos e segundos devem estar no intervalo de 0 a 59.
 * Se o parâmetro opcional 'minutes' ou 'seconds' não forem fornecidos, eles serão considerados como 0 por padrão.
 *
 * @param hours - O valor numérico das horas, no intervalo de 0 a 23.
 * @param minutes - (Opcional) O valor numérico dos minutos, no intervalo de 0 a 59 (padrão: 0).
 * @param seconds - (Opcional) O valor numérico dos segundos, no intervalo de 0 a 59 (padrão: 0).
 * @returns Retorna uma string no formato 'HH:MM:SS' representando a hora formatada.
 * @throws Lança um erro se os valores fornecidos estiverem fora dos limites válidos.
 */
export const getFormattedTimeFromNumbers = (
  hours: number,
  minutes = 0,
  seconds = 0,
): string => {
  if (
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59 ||
    seconds < 0 ||
    seconds > 59
  ) {
    throw new Error("Horas, minutos ou segundos inválidos");
  }

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

/**
 * Esta função recebe string no formato 'HH:mm:ss' e devolve um resultado no formato 'HH:mm'.
 *
 * @param time - Uma string no formato 'HH:mm:ss'.
 * @returns Retorna uma string no formato 'HH:mm' representando a hora formatada.
 */
export const removeSecondsFromTimeString = (time: string): string => {
  const timeRegex =
    /^(?<hours>\d{2}):(?<minutes>\d{2})(?::(?<seconds>\d{2})(?:\.\d+)?)?$/;
  const match = timeRegex.exec(time);

  if (match) {
    const { hours, minutes } = match.groups ?? { hours: "00", minutes: "00" };
    return `${hours}:${minutes}`;
  }

  return time;
};

/**
 * Esta função recebe string no formato 'yyyy-MM-ddTHH:mm:ss' e devolve um resultado no formato 'HH:mm'.
 *
 * @param date - Uma string no formato 'yyyy-MM-ddTHH:mm:ss'.
 * @returns Retorna uma string no formato 'HH:mm' representando a hora formatada.
 */
export const formatDateStringToHourMinute = (date: string): string => {
  const dateAndTime = date.split("T");

  const time = dateAndTime[1];

  if (!time) return date;

  const [hours, minutes] = time.split(":");

  if (!hours || !minutes) return date;

  return `${hours}:${minutes}`;
};

/**
 * Função que ajusta uma data adicionando ou subtraindo uma quantidade específica de períodos.
 *
 * @param date - Objeto Date a ser ajustado.
 * @param amount - Quantidade de períodos a serem adicionados ou subtraídos (pode ser negativo para subtração).
 * @param period - Tipo de período a ser ajustado ("week" para semana ou "month" para mês).
 * @returns Objeto Date ajustado conforme o período especificado.
 */
export const adjustDateByPeriod = (
  date: Date,
  amount: number,
  period: "week" | "month",
): Date => {
  return period === "week" ? addWeeks(date, amount) : addMonths(date, amount);
};

/**
 * Função que ajusta uma data tratando possíveis diferenças de timezone.
 *
 * @param date - Objeto Date a ser ajustado.
 * @param time - Representação das horas e minutos.
 * @returns Data desejada.
 */
export const convertDateTimeIgnoringTimezone = (
  date: Date | string,
  time = "00:00",
): Date => {
  const dateObject = typeof date === "string" ? parseISO(date) : date;

  const timeSplitted = time.split(":");
  const [hours, minutes] = timeSplitted.map(Number);

  const newDate = new Date(dateObject);

  newDate.setHours(hours, minutes, 0, 0);

  return newDate;
};

export const formatIgnoringTimezone = (date: Date, dateFormat = "yyyy-MM-dd"): string => {
  return formatDatefns(date, dateFormat, { locale: ptBR });
};

export const formatDateAndTimeIgnoringTimezone = (
  date: Date | string,
  time = "00:00",
  dateFormat = "yyyy-MM-dd",
): string => {
  const convertedDate = convertDateTimeIgnoringTimezone(date, time);

  return formatDatefns(convertedDate, dateFormat, { locale: ptBR });
};

/**
 * Função que retorna um array contendo todos os dias do mês de uma determinada data.
 *
 * @param date - Objeto Date para o qual se deseja obter os dias do mês.
 * @returns Array contendo todos os dias do mês ordenados em relação à data fornecida.
 */
export const getAllDaysOfMonthFromDateString = (date: string): Date[] => {
  const dateObject = convertDateTimeIgnoringTimezone(date);

  const start = startOfMonth(dateObject);
  const end = endOfMonth(dateObject);
  const length = differenceInDays(end, start) + 1;
  const daysOfMonth = Array.from({ length }, (_, i) => addDays(start, i));
  const daysOfMonthOrdered = [
    ...daysOfMonth.filter(
      (day) => isBefore(day, dateObject) || day.getTime() === dateObject.getTime(),
    ),
    ...daysOfMonth.filter((day) => day.getTime() > dateObject.getTime()),
  ];
  return daysOfMonthOrdered;
};

/**
 * Função que retorna um array contendo todos os dias da semana de uma determinada data.
 *
 * @param date - Objeto Date para o qual se deseja obter os dias da semana.
 * @returns Array contendo todos os dias da semana ordenados em relação à data fornecida.
 */
export const getAllDaysOfWeekFromDateString = (date: string): Date[] => {
  const dateObject = convertDateTimeIgnoringTimezone(date);

  const start = startOfWeek(dateObject);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => addDays(start, i));
  const daysOfWeekOrdered = [
    ...daysOfWeek.filter(
      (day) => isBefore(day, dateObject) || day.getTime() === dateObject.getTime(),
    ),
    ...daysOfWeek.filter((day) => day.getTime() > dateObject.getTime()),
  ];
  return daysOfWeekOrdered;
};

/**
 * Verifica se uma determinada data está contida dentro de uma repetição da agenda com base na data de início e na frequência.
 *
 * @param startDate - A data de início da agenda.
 * @param checkDate - A data a ser verificada em relação à agenda.
 * @param scheduleFrequency - A frequência de repetição da agenda em dias.
 * @returns - Retorna true se a data a ser verificada cair dentro da repetição da agenda, false caso contrário.
 */
export const isDateWithinRepetition = (
  startDate: Date,
  checkDate: Date,
  scheduleFrequency: ScheduleFrequencyEnum,
): boolean => {
  if (scheduleFrequency === ScheduleFrequencyEnum.NaoRepetir) {
    return startDate.getTime() === checkDate.getTime();
  }

  const frequencyCheckers: Record<
    number,
    (dateToCheck: Date, initialDate: Date) => boolean
  > = {
    [ScheduleFrequencyEnum.Semanalmente]: (dateToCheck, initialDate) =>
      differenceInWeeks(dateToCheck, initialDate) % 1 === 0,
    [ScheduleFrequencyEnum.Quinzenalmente]: (dateToCheck, initialDate) =>
      differenceInWeeks(dateToCheck, initialDate) % 2 === 0,
  };

  const checker = frequencyCheckers[scheduleFrequency];

  return checker(checkDate, startDate);
};

/**
 * Verifica se um dado agendamento já ocorreu ou se ainda está para acontecer.
 *
 * @param startDate - A data do agendamento.
 * @param checkDate - A horário de início do agendamento.
 * @returns - Retorna true se o agendamento estiver no passado, false caso contrário.
 */
export const checkIfAppointmentHasOccurred = (
  date: string,
  startTime: string,
): boolean => {
  const now = zonedDate(new Date()).getTime();

  const appointmentStartTime = convertDateTimeIgnoringTimezone(date, startTime).getTime();

  return now >= appointmentStartTime;
};
