import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { DaysOfWeekEnum } from "@repo/lib";
import { DaysOfWeekList } from "@/components/days-of-week-list";

export const ScheduleDaysOfWeekListFragment = graphql(/* GraphQL */ `
  fragment ScheduleDaysOfWeekListFragment on usuariosAgendas {
    agendasDiasSemanas: tbUsuariosAgendasDiasSemanas(where: { ativo: { _eq: true } }) {
      diaSemana
    }
  }
`);

export const ScheduleDaysOfWeekList = (props: {
  schedule: FragmentType<typeof ScheduleDaysOfWeekListFragment>;
}): JSX.Element => {
  const schedule = useFragment(ScheduleDaysOfWeekListFragment, props.schedule);
  const scheduleDaysOfWeekList = schedule.agendasDiasSemanas;

  const validDaysOfWeek = [
    DaysOfWeekEnum.Monday,
    DaysOfWeekEnum.Tuesday,
    DaysOfWeekEnum.Wednesday,
    DaysOfWeekEnum.Thursday,
    DaysOfWeekEnum.Friday,
    DaysOfWeekEnum.Saturday,
  ];

  const activeDaysOfWeek = validDaysOfWeek.filter((dayOfWeek) => {
    return scheduleDaysOfWeekList.some(
      (scheduleDayOfWeek: { diaSemana: number }) =>
        Number(scheduleDayOfWeek.diaSemana) === Number(dayOfWeek),
    );
  });

  return <DaysOfWeekList activeDays={activeDaysOfWeek} days={validDaysOfWeek} />;
};
