import {
  Building,
  Clock,
  DoctorWithClipboard,
  Door,
  HelpCircle,
  Left,
} from "@repo/icons";
import { Link, createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import "@/styles/calendar.css";
import { graphql } from "@repo/graphql-types/gql";
import { useState } from "react";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { fetchQuery } from "@/hooks/use-graphql";
import {
  EmptyStateRoot,
  EmptyStateImage,
  EmptyStateTitle,
  EmptyStateDescription,
  EmptyStateBody,
  EmptyStateButton,
} from "@/components/empty-state";
import { OneLivHelperDrawer } from "@/components/oneliv/oneliv-helper-drawer";

const GetOneLivUser = graphql(`
  query GetOneLivUser {
    locus_membro {
      id
    }
  }
`);

export const OneLivWelcomePage = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Perfil OneLiv" align="center" />
        <HeaderButton
          align="end"
          icon={HelpCircle}
          onClick={() =>
            void navigate({
              search: { action: "help" },
            })
          }
        />
      </HeaderRoot>

      <Page>
        <EmptyStateRoot>
          <EmptyStateImage
            icon={DoctorWithClipboard}
            alt="Cadastro no OneLiv não realizado"
            className="w-full h-[25vh]"
          />
          <EmptyStateTitle>Descubra o OneLiv</EmptyStateTitle>
          <EmptyStateDescription>
            Um portal para promover seu trabalho e conquistar mais pacientes.
          </EmptyStateDescription>
          <EmptyStateBody>
            <ul className="flex flex-col gap-2 px-4 py-6 text-sm font-normal text-neutral-600">
              <li className="flex gap-2">
                <Door className="fill-primary-400" size={24} />
                <span className="self-end">
                  Conecta pacientes que buscam atendimento diferenciado e exclusivo
                </span>
              </li>
              <li className="flex gap-2">
                <Clock className="fill-primary-400 self-center" size={24} />
                <span className="">
                  Amplia sua visibilidade e fortalece sua reputação profissional
                </span>
              </li>
              <li className="flex gap-2">
                <Building className="fill-primary-400" size={24} />
                <span className="self-end">
                  Agendamentos online de forma prática, rápida e descomplicada
                </span>
              </li>
            </ul>
          </EmptyStateBody>
          <Link to="/oneliv/use-terms" className="w-full">
            <EmptyStateButton
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
              }}
              fullWidth
            >
              Criar perfil OneLiv
            </EmptyStateButton>
          </Link>
        </EmptyStateRoot>

        <OneLivHelperDrawer />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/oneliv/welcome")({
  component: OneLivWelcomePage,
  loader: async (opts) => {
    const data = await fetchQuery(opts.context, GetOneLivUser);

    const isUserRegistered = data.locus_membro.length !== 0;

    if (isUserRegistered) {
      redirect({
        to: "/oneliv/basic-info",
        throw: true,
      });
    }

    return {
      data,
    };
  },
});
