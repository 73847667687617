import { Disconnected } from "@repo/icons";
import {
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

interface DisconnectedErrorDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
}
export const DisconnectedErrorDrawer = ({
  open,
  setOpen,
  onClose,
}: DisconnectedErrorDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot
      open={open}
      setOpen={setOpen}
      icon={Disconnected}
      variant="primary"
      onClose={onClose}
    >
      <MessageDrawerTitle>Problemas de conexão</MessageDrawerTitle>
      <MessageDrawerBody>
        Ocorreu um erro ao processar, verifique sua conexão com a internet.
      </MessageDrawerBody>
    </MessageDrawerRoot>
  );
};
