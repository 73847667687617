import {
  createContext,
  useState,
  useMemo,
  useCallback,
  type ReactNode,
  type SetStateAction,
} from "react";
import { useRouter, useNavigate, useSearch } from "@tanstack/react-router";

export interface ErrorDrawerContextProps {
  showErrorDrawer: (message?: string, onClose?: SetStateAction<() => void>) => void;
  closeErrorDrawer: () => void;
  isDrawerVisible: boolean;
  errorMessage: string;
}

export const ErrorDrawerContext = createContext<ErrorDrawerContextProps | undefined>(
  undefined,
);

interface ErrorDrawerProviderProps {
  children: ReactNode;
}

interface SearchParamsType {
  action: string;
}

export const ErrorDrawerProvider = ({
  children,
}: ErrorDrawerProviderProps): JSX.Element => {
  const router = useRouter();
  const navigate = useNavigate();
  const params: SearchParamsType = useSearch({ strict: false });

  const [errorMessage, setErrorMessage] = useState("");
  const [closeErrorDrawer, setCloseErrorDrawer] = useState<() => void>(
    () => router.history.back,
  );

  const showErrorDrawer = useCallback(
    (message?: string, onClose?: SetStateAction<() => void>): void => {
      if (onClose) {
        setCloseErrorDrawer(() => onClose);
      } else {
        setCloseErrorDrawer(() => router.history.back);
      }

      if (message) {
        setErrorMessage(message);

        void navigate({
          search: {
            ...params,
            action: "expected-api-error",
          },
        });
      } else {
        setErrorMessage(
          "Ocorreu um erro ao processar a sua solicitação. Por favor, tente novamente.",
        );

        void navigate({
          search: {
            ...params,
            action: "unexpected-api-error",
          },
        });
      }
    },
    [navigate, params, router.history],
  );

  const isDrawerVisible =
    params.action === "expected-api-error" || params.action === "unexpected-api-error";

  const contextValue = useMemo(
    () => ({
      showErrorDrawer,
      closeErrorDrawer,
      isDrawerVisible,
      errorMessage,
    }),
    [showErrorDrawer, closeErrorDrawer, isDrawerVisible, errorMessage],
  );

  return (
    <ErrorDrawerContext.Provider value={contextValue}>
      {children}
    </ErrorDrawerContext.Provider>
  );
};
