/**
 * Formata um número de CPF (Cadastro de Pessoa Física).
 *
 * @param value - O número de CPF a ser formatado.
 * @returns O número de CPF formatado no formato XXX.XXX.XXX-XX.
 */
export const formatCPF = (value: string): string => {
  const numericValue = value.replace(/\D/g, "").slice(0, 11);
  // XXX.XXX.XXX-XX
  return numericValue
    .replace(/(?<temp2>\d{3})(?<temp1>\d)/, "$1.$2")
    .replace(/(?<temp2>\d{3})(?<temp1>\d)/, "$1.$2")
    .replace(/(?<temp2>\d{3})(?<temp1>\d{1,2})$/, "$1-$2");
};

/**
 * Formata um número para o formato monetário (X.XXX,XX).
 *
 * @param value - O número a ser formatado.
 * @returns O número formatado no formato monetário.
 */
export const maskCurrency = (value: string): string => {
  const onlyNumbers = value.replace(/\D/g, "").replace(/^0+/, "").padStart(3, "0");

  let integer = onlyNumbers.slice(0, -2);
  const decimal = onlyNumbers.slice(-2);

  integer = integer.replace(/(?:\d)(?=(?:\d{3})+(?!\d))/g, "$&.");

  return `${integer},${decimal}`;
};

/**
 * Formata um número para o formato numérico.
 *
 * @param value - O número a ser formatado.
 * @returns O número formatado no formato numérico.
 */
export const maskNumber = (value: string): number => {
  const onlyNumbers = value.replace(/\D/g, "").replace(/^0+/, "").padStart(1, "0");
  return Number(onlyNumbers);
};

/**
 * Valida um número de CPF (Cadastro de Pessoa Física).
 *
 * @param cpf - O número de CPF a ser validado.
 * @returns um booleano indicando se o CPF é válido ou não.
 */
export const validateCPFChecksum = (cpf: string): boolean => {
  const modifiedCpf = cpf.replace(/(?:[~!@#$%^&*()_+=`{}[\]\-|\\:;'<>,./? ])+/g, "");

  if (modifiedCpf.length !== 11) {
    return false;
  }
  let sum = 0,
    i;

  const firstVerifyingDigit = parseInt(modifiedCpf.substring(9, 10), 10);
  const secondVerifyingDigit = parseInt(modifiedCpf.substring(10, 11), 10);

  if (
    modifiedCpf === "" ||
    modifiedCpf === "00000000000" ||
    modifiedCpf === "11111111111" ||
    modifiedCpf === "22222222222" ||
    modifiedCpf === "33333333333" ||
    modifiedCpf === "44444444444" ||
    modifiedCpf === "55555555555" ||
    modifiedCpf === "66666666666" ||
    modifiedCpf === "77777777777" ||
    modifiedCpf === "88888888888" ||
    modifiedCpf === "99999999999"
  ) {
    return false;
  }

  for (i = 1; i <= 9; i++) {
    sum = sum + parseInt(modifiedCpf.substring(i - 1, i), 10) * (11 - i);
  }

  if (checkSumCpfResult(sum, firstVerifyingDigit)) {
    sum = 0;
    for (i = 1; i <= 10; i++) {
      sum = sum + parseInt(modifiedCpf.substring(i - 1, i), 10) * (12 - i);
    }
    return checkSumCpfResult(sum, secondVerifyingDigit);
  }
  return false;
};

/**
 * Verifica se soma dos números do CPF é igual ao número verificador do CPF
 *
 * @param sum - O número da soma dos 9 primeiros dígitos do CPF.
 * @param verifyingDigit - O penúltimo ou último dígito do CPF.
 * @returns um booleano indicando se a soma dos números do CPF é igual ao penúltimo ou último dígito do CPF.
 */
const checkSumCpfResult = (sum: number, verifyingDigit: number): boolean => {
  let result = (sum * 10) % 11;
  if (result === 10 || result === 11) {
    result = 0;
  }
  return result === verifyingDigit;
};

const trimEnd = (str: string, sequence: string): string => {
  let temp = str;
  while (temp.endsWith(sequence)) {
    temp = temp.substring(0, temp.length - sequence.length);
  }
  return temp;
};

const internalFormatPhoneNumber = (value: string): string => {
  let numericValue = value.replace(/\D/g, "");
  numericValue = numericValue.slice(
    numericValue.length > 11 ? numericValue.length - 11 : 0,
  );
  if (numericValue.length <= 8) {
    // XXXX-XXXX
    return numericValue.replace(/(?<temp1>\d{4})(?<temp2>\d{0,4})/, "$1-$2");
  } else if (numericValue.length <= 9) {
    // XXXXX-XXXX
    return numericValue.replace(/(?<temp1>\d{5})(?<temp2>\d{0,4})/, "$1-$2");
  } else if (numericValue.length <= 10) {
    // (XX) XXXX-XXXX
    return numericValue.replace(
      /(?<temp1>\d{2})(?<temp2>\d{4})(?<temp3>\d{0,4})/,
      "($1) $2-$3",
    );
  }
  // (XX) XXXXX-XXXX
  return numericValue.replace(
    /(?<temp1>\d{2})(?<temp2>\d{5})(?<temp3>\d{0,4})/,
    "($1) $2-$3",
  );
};

/**
 * Formata um número de telefone removendo caracteres desnecessários.
 *
 * @param value - O número de telefone a ser formatado.
 * @returns O número de telefone formatado.
 */
export const formatPhoneNumber = (value: string): string => {
  const phone = internalFormatPhoneNumber(value);
  return trimEnd(trimEnd(trimEnd(phone, "-"), ")"), "(");
};

/**
 * Valida um endereço de email.
 *
 * @param email - O endereço de email a ser validado.
 * @returns Verdadeiro se o endereço de email for válido, falso caso contrário.
 */
export const validateEmail = (email: string): boolean => {
  return (
    (/^(?<temp1>(?<temp2>[^<>()[\]\\.,;:\s@"]+(?<temp3>\.[^<>()[\]\\.,;:\s@"]+)*)|.(?<temp4>".+"))@(?<temp5>(?<temp6>\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(?<temp7>(?<temp8>[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
      (email || "").toLowerCase(),
    )?.length ?? 0) > 0
  );
};

/**
 * Formata um número de cartão de crédito
 *
 * @param value - O número do cartão a ser formatado.
 * @returns O número formatado no formato XXXX XXXX XXXX XXXX.
 */
export const formatCreditCard = (value: string): string => {
  const numericValue = value.replace(/\D/g, "").slice(0, 16);

  return numericValue.replace(/(?<temp1>\d{4})(?=\d)/g, "$1 ");
};

/**
 * Formata a data de vencimento do cartão
 *
 * @param value - os dígitos numéricos da data de vencimento do cartão.
 * @returns O número formatado no formato XX/XX.
 */
export const formatCardExpireDate = (value: string): string => {
  const numericValue = value.replace(/\D/g, "").slice(0, 4);

  return numericValue.replace(/(?<mm>\d{2})(?<yy>\d{2})/, "$1/$2");
};
