import {
  TooltipDrawerRoot,
  TooltipDrawerTitle,
  TooltipDrawerBody,
} from "@/components/tooltip-drawer";

interface ChoosePaymentChannelTooltipProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ChoosePaymentChannelTooltip = ({
  open,
  setOpen,
}: ChoosePaymentChannelTooltipProps): JSX.Element => {
  return (
    <TooltipDrawerRoot open={open} setOpen={setOpen}>
      <TooltipDrawerTitle>Enviar pagamento</TooltipDrawerTitle>
      <TooltipDrawerBody>
        <p>
          Escolha como enviar a cobrança para o paciente. Se optar por{" "}
          <b>Pagamento Online</b>, o link poderá ser compartilhado com o paciente para que
          ele pague de forma prática e segura. Se preferir <b>Pagamento no Totem</b>, a
          cobrança será enviada para o Totem da unidade do agendamento.
        </p>
      </TooltipDrawerBody>
    </TooltipDrawerRoot>
  );
};
