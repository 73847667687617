import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { ensureQueryData } from "@/hooks/use-graphql";
import { AppointmentPatientSwapForm } from "@/components/appointment-patient-swap-form";
import { FullPageSpinner } from "@/components/full-page-spinner";

const PatientSwapPageQuery = graphql(`
  query PatientSwapPageQuery($codAgendamento: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      codAgendamento
      data
      horaInicio
      codFinalidadeAgendamento
      horaFim
      ...AppointmentPatientSwapFormFragment
    }
  }
`);

const patientSwapPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const PatientSwapPage = (): JSX.Element => {
  const { appointment } = Route.useLoaderData();

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Trocar paciente" align="center" />
      </HeaderRoot>

      <Page>
        {appointment ? (
          <AppointmentPatientSwapForm queryData={appointment} />
        ) : (
          <FullPageSpinner />
        )}
      </Page>
    </>
  );
};

export const Route = createFileRoute("/appointment/$appointmentId/patient-swap")({
  component: PatientSwapPage,
  validateSearch: patientSwapPageSearchSchema,
  parseParams: (params) => ({
    appointmentId: z.number().int().parse(Number(params.appointmentId)),
  }),
  loader: async (opts) => {
    try {
      const variables = {
        codAgendamento: opts.params.appointmentId,
      };

      const data = await ensureQueryData(opts.context, PatientSwapPageQuery, variables);

      const appointment = data.agendamento;

      const appointmentNotFound = !appointment;

      if (appointmentNotFound) throw new Error("Appointment not found!");

      return { appointment };
    } catch (e) {
      redirect({
        to: "/",
        throw: true,
      });
    }

    return {};
  },
});
