import { createFileRoute, useSearch } from "@tanstack/react-router";
import {
  UnavailableSlotType,
  allLocationObject,
  format,
  getCurrentDate,
  formatWithZonedDate,
  type CalendarView,
  adjustDateByPeriod,
  formatIgnoringTimezone,
  getAllDaysOfWeekFromDateString,
  getAllDaysOfMonthFromDateString,
  AppointmentPurpose,
} from "@repo/lib";
import type {
  CalendarPageAppointmentsQueryQuery,
  CalendarPageFreeSlotsQueryQuery,
  CalendarPageScheduleQueryQuery,
  UnavailableSlotPageQuery,
} from "@repo/graphql-types/graphql";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { queryFn, prefetchQuery, useGraphQL } from "@/hooks/use-graphql";
import { BottomBar } from "@/components/bottom-bar";
import { HomeCalendar } from "@/components/home-calendar";
import "@/styles/calendar.css";
import { HeaderRoot } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { type RootRouteContext } from "@/routes/-root-context";
import { CalendarLocationSelector } from "@/components/calendar-location-selector";
import { CalendarToolbar } from "@/components/calendar-toolbar";

const UnavailableSlotPage = graphql(`
  query UnavailableSlotPage($codFechamento: Int!) {
    tbFechamentos(
      where: { codFechamento: { _eq: $codFechamento }, codTipoFechamento: { _eq: 2 } }
    ) {
      codTipoFechamento
    }
  }
`);

const CalendarPageScheduleQuery = graphql(`
  query CalendarPageScheduleQuery($codUsuario: Int!) {
    usuariosAgendas(where: { ativo: { _eq: true } }) {
      agendasUnidades: tbUsuariosAgendasUnidades(
        where: { Unidade: { ativo: { _eq: true } } }
      ) {
        Unidade {
          nome
          nomeLimpo
          codUnidade
          ativo
          sigla
        }
      }
    }
    ...UnitsBySchedulesQueryFragment
    ...BlockedActionsListFragment
    ...TimePoliciesQueryFragment
    ...ScheduleSlotsFragment
  }
`);

const CalendarPageAppointmentsQuery = graphql(`
  query CalendarPageAppointmentsQuery(
    $dataInicio: date!
    $dataFim: date!
    $dataInicioToString: String!
    $dataFimToString: String!
    $availableAppointmentsPurpose: [Int!]!
  ) {
    ...AppointmentsQueryFragment
    ...HolidaysQueryFragment
  }
`);

const CalendarPageFreeSlotsQuery = graphql(`
  query CalendarPageFreeSlotsQuery(
    $dataInicio: date!
    $dataFim: date!
    $codUsuario: Int!
    $codUnidade: Int
  ) {
    ...FreeScheduleSlotsFragment
  }
`);

const calendarViewSchema: z.ZodType<CalendarView> = z.union([
  z.literal("dayGridDay"),
  z.literal("dayGridWeek"),
  z.literal("dayGridMonth"),
]);

const calendarPageSearchSchema = z.object({
  date: z
    .string()
    .refine((value) => !value || /^\d{4}-\d{2}-\d{2}$/.test(value))
    .catch(format(getCurrentDate())),
  locationId: z.number().catch(0),
  unavailableSlotId: z.number(),
  appointmentId: z.number(),
  action: z.string(),
  calendarView: calendarViewSchema.catch("dayGridDay"),
  showCancelled: z.boolean().catch(false),
});

export const CalendarPage = (): JSX.Element => {
  let currentLocation = allLocationObject;

  const searchParams: z.infer<typeof calendarPageSearchSchema> = useSearch({
    from: "/",
  });

  const { date, locationId, calendarView } = searchParams;

  const routeContext = Route.useRouteContext();

  const currentDate = calendarPageSearchSchema.shape.date.parse(date);

  const allDaysOfSelectedPeriod =
    calendarView === "dayGridMonth"
      ? getAllDaysOfMonthFromDateString(currentDate)
      : getAllDaysOfWeekFromDateString(currentDate);

  const getCodUnidade = (): number | null => {
    if (locationId) return locationId;

    if (currentLocation.codUnidade === 0) return null;

    return currentLocation.codUnidade;
  };

  const startDate = formatWithZonedDate(allDaysOfSelectedPeriod[0], "yyyy-MM-dd");
  const endDate = formatWithZonedDate(
    allDaysOfSelectedPeriod[allDaysOfSelectedPeriod.length - 1],
    "yyyy-MM-dd",
  );

  const getSchedulePlaceholderData = (
    previousData?: CalendarPageScheduleQueryQuery,
  ): CalendarPageScheduleQueryQuery => {
    if (previousData) {
      return {
        ...previousData,
      };
    }

    return {
      usuariosAgendas: [],
      // @ts-expect-error -- tipagem de fragment
      unidadesPoliticasDeHorarios: [],
      listaAcoesBloqueadas: [],
      LivanceApiUsuarioAgendaAtivosPorUsuario: [],
    };
  };

  const getAppointmentPlaceholderData = (
    previousData?: CalendarPageAppointmentsQueryQuery,
  ): CalendarPageAppointmentsQueryQuery => {
    if (previousData) {
      return {
        ...previousData,
      };
    }

    return {
      // @ts-expect-error -- tipagem de fragment
      agendamentos: [],
      tbFechamentos: [],
    };
  };

  const getFreeSlotsPlaceholderData = (
    previousData?: CalendarPageFreeSlotsQueryQuery,
  ): CalendarPageFreeSlotsQueryQuery => {
    if (previousData) {
      return {
        ...previousData,
      };
    }

    return {
      // @ts-expect-error -- tipagem de fragment
      LivanceApiBuscaSelecionaFaixasLivresAgenda: [],
    };
  };

  const scheduleQueryResult = useGraphQL(
    CalendarPageScheduleQuery,
    { codUsuario: routeContext.user.codUsuario },
    {
      placeholderData: getSchedulePlaceholderData,
    },
  );
  const { data: scheduleData, isSuccess: isScheduleQuerySuccess } = scheduleQueryResult;
  const availableAppointmentsPurpose = [
    AppointmentPurpose.AtendimentoPresencial,
    AppointmentPurpose.HorariosMenores,
    AppointmentPurpose.HorariosEstendidos,
  ];

  const appointmentQueryResult = useGraphQL(
    CalendarPageAppointmentsQuery,
    {
      dataInicio: startDate,
      dataInicioToString: startDate,
      dataFim: endDate,
      dataFimToString: endDate,
      availableAppointmentsPurpose,
    },
    {
      placeholderData: getAppointmentPlaceholderData,
    },
  );
  const { data: homeCalendarData } = appointmentQueryResult;

  const freeSlotsQueryResult = useGraphQL(
    CalendarPageFreeSlotsQuery,
    {
      dataInicio: startDate,
      dataFim: endDate,
      codUsuario: routeContext.user.codUsuario,
      codUnidade: getCodUnidade(),
    },
    {
      placeholderData: getFreeSlotsPlaceholderData,
    },
  );
  const { data: freeSlotsData } = freeSlotsQueryResult;

  const prefetchPeriod = (start: Date, end: Date): void => {
    const prefetchStartDate = formatWithZonedDate(start, "yyyy-MM-dd");
    const prefetchEndDate = formatWithZonedDate(end, "yyyy-MM-dd");

    prefetchQuery(routeContext, CalendarPageAppointmentsQuery, {
      dataInicio: prefetchStartDate,
      dataInicioToString: prefetchStartDate,
      dataFim: prefetchEndDate,
      dataFimToString: prefetchEndDate,
      availableAppointmentsPurpose,
    });
  };

  const prefetchAdjacentPeriods = (period: "week" | "month"): void => {
    const firstDay = allDaysOfSelectedPeriod[0];
    const lastDay = allDaysOfSelectedPeriod[allDaysOfSelectedPeriod.length - 1];

    const previousPeriodStart = adjustDateByPeriod(firstDay, -1, period);
    const previousPeriodEnd = adjustDateByPeriod(lastDay, -1, period);
    const nextPeriodStart = adjustDateByPeriod(firstDay, 1, period);
    const nextPeriodEnd = adjustDateByPeriod(lastDay, 1, period);

    prefetchPeriod(previousPeriodStart, previousPeriodEnd);
    prefetchPeriod(nextPeriodStart, nextPeriodEnd);
  };

  if (isScheduleQuerySuccess) {
    prefetchAdjacentPeriods(calendarView === "dayGridMonth" ? "month" : "week");

    const filteredUnitByLocationId = scheduleData.usuariosAgendas
      .flatMap((unit) => unit.agendasUnidades)
      .find((unitSchedule) => unitSchedule.Unidade?.codUnidade === locationId)?.Unidade;

    currentLocation = filteredUnitByLocationId ?? allLocationObject;
  }

  const calendarSkeleton = (
    <div aria-label="Esqueleto do loading no calendar parent" className="pt-4">
      <div className="py-4">
        <div className="h-8 mb-4 animate-pulse rounded-md bg-neutral-200 shadow-none" />
        <div className="flex flex-row gap-2 justify-between">
          {[...Array<number>(7)].map((_, index) => {
            return (
              <div
                className="w-10 h-14 flex animate-pulse flex-row items-center justify-center rounded-md bg-neutral-200"
                key={`skeleton-calendar-${index.toString()}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <HeaderRoot>
        <CalendarLocationSelector
          isPending={appointmentQueryResult.isPending}
          currentLocation={currentLocation}
          align="start"
        />
        <CalendarToolbar align="end" />
      </HeaderRoot>
      <Page className="py-0 pb-[52px]">
        <AsyncDataWrapper {...appointmentQueryResult} fallback={calendarSkeleton}>
          {homeCalendarData && scheduleData && freeSlotsData ? (
            <HomeCalendar
              appointmentsQueryData={homeCalendarData}
              scheduleQueryData={scheduleData}
              freeSlotsQueryData={freeSlotsData}
              currentDate={currentDate}
              currentLocation={currentLocation}
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
      <BottomBar />
    </>
  );
};

export const Route = createFileRoute("/")({
  component: CalendarPage,
  validateSearch: calendarPageSearchSchema.partial(),
  beforeLoad: async ({ context, search, navigate }) => {
    try {
      const unavailableSlotId = search.unavailableSlotId;
      if (unavailableSlotId) {
        await verifyUnavailableSlotIdIsValid(unavailableSlotId, context);
      }

      if (!Object.keys(search).length) {
        void navigate({
          replace: true,
          to: "/",
          search: {
            date: formatIgnoringTimezone(getCurrentDate()),
            locationId: 0,
            calendarView: "dayGridDay",
          },
        });
      }
    } catch (e) {
      void navigate({
        replace: true,
        to: "/",
        search: {
          date: formatWithZonedDate(getCurrentDate()),
          locationId: 0,
          calendarView: "dayGridDay",
        },
      });
    }
  },
});

async function verifyUnavailableSlotIdIsValid(
  unavailableSlotId: number,
  context: RootRouteContext,
): Promise<void> {
  const variable = {
    codFechamento: unavailableSlotId,
  };

  const unavailableSlot = await context.queryClient.fetchQuery<UnavailableSlotPageQuery>({
    queryKey: [UnavailableSlotPage, variable],
    queryFn: () => queryFn(UnavailableSlotPage, context.authClient, variable),
  });

  if (
    unavailableSlot.tbFechamentos.length < 1 ||
    unavailableSlot.tbFechamentos[0].codTipoFechamento ===
      UnavailableSlotType.CreatedBySystem.valueOf()
  )
    throw new Error("Unavailable slot not found!");
}
