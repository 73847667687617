import type { FragmentType } from "@repo/graphql-types";
import { graphql, useFragment } from "@repo/graphql-types";

export const ProfessionalUserPreferredNameFragment = graphql(`
  fragment ProfessionalUserPreferredNameFragment on query_root {
    usuariosProfissionais {
      Usuario {
        nome
        sobrenome
        apelido
      }
    }
  }
`);

const getPreferredName = (props: {
  name: string;
  lastName: string;
  nickname: string | null;
  isError: boolean;
}): string => {
  if (props.isError) {
    return "Não foi possível carregar as informações";
  }

  return props.nickname && props.nickname !== ""
    ? props.nickname
    : `${props.name} ${props.lastName}`;
};

export const ProfessionalUserPreferredName = (props: {
  isError: boolean;
  queryData: FragmentType<typeof ProfessionalUserPreferredNameFragment>;
}): JSX.Element => {
  const fragmentData = useFragment(
    ProfessionalUserPreferredNameFragment,
    props.queryData,
  );

  const { isError } = props;

  return (
    <p className="text-center font-medium text-[20px]">
      {getPreferredName({
        isError,
        name: !isError ? fragmentData.usuariosProfissionais[0]?.Usuario.nome : "",
        lastName: !isError
          ? fragmentData.usuariosProfissionais[0]?.Usuario.sobrenome
          : "",
        nickname: !isError
          ? fragmentData.usuariosProfissionais[0]?.Usuario.apelido ?? null
          : "",
      })}
    </p>
  );
};
