import { type FragmentType, useFragment } from "@repo/graphql-types";
import { graphql } from "@repo/graphql-types/gql";
import { CpsCheckboxGroup, CpsCheckboxV2, CpsSpinner } from "corpus";
import { type ControllerRenderProps } from "react-hook-form";
import { type CreatePaymentFormValues } from "@/lib/form-schemas/create-payment-schema";

export const OnlinePaymentTypesCheckboxGroupFragment = graphql(`
  fragment OnlinePaymentTypesCheckboxGroupFragment on expensum_payment_type {
    name
    id
    description
  }
`);

interface OnlinePaymentTypesCheckboxGroupProps {
  data: FragmentType<typeof OnlinePaymentTypesCheckboxGroupFragment>[] | null;
  field: ControllerRenderProps<CreatePaymentFormValues, "paymentMethods">;
}

export const OnlinePaymentTypesCheckboxGroup = ({
  data,
  field,
}: OnlinePaymentTypesCheckboxGroupProps): JSX.Element => {
  const paymentTypes = useFragment(OnlinePaymentTypesCheckboxGroupFragment, data);

  if (!paymentTypes) {
    return <CpsSpinner />;
  }

  const handleChange = (value: string): void => {
    const selectedPaymentType = paymentTypes.find((type) => type.name === value);
    if (selectedPaymentType) {
      const newValue = field.value.some((method) => method.name === value)
        ? field.value.filter((method) => method.name !== value)
        : [...field.value, selectedPaymentType];
      field.onChange(newValue);
    }
  };

  return (
    <CpsCheckboxGroup direction="row" name="payment-methods-checkbox-group">
      {paymentTypes.map((paymentType) => (
        <CpsCheckboxV2
          key={paymentType.id}
          id={paymentType.name}
          label={paymentType.description}
          value={paymentType.name}
          checked={field.value.some((method) => method.name === paymentType.name)}
          onCheckedChange={() => handleChange(paymentType.name)}
        />
      ))}
    </CpsCheckboxGroup>
  );
};
