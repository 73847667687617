import { graphql } from "@repo/graphql-types/gql";
import {
  DaysOfWeekEnum,
  Holiday,
  getFormattedTimeFromNumbers,
  isConsultorioVirtual,
  type UserContext,
  type DayEvents,
  convertDateTimeIgnoringTimezone,
  formatIgnoringTimezone,
} from "@repo/lib";
import { CpsAlert, CpsSpinner } from "corpus";
import { useRouteContext } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { ExclamationCircle } from "@repo/icons";
import type { CloseScheduleType, TimeSlotSuggestionType } from "@repo/lib/src/enums";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
} from "@/components/message-drawer";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { CalendarDayEvent } from "@/components/calendar-day-event";
import { useGraphQL } from "@/hooks/use-graphql";
import { AppointmentFab } from "@/components/appointment-fab";
import { trackEvent } from "@/lib/tracking";
import {
  createAvailableDayEventProps,
  createClosedDayEvent,
  createOldDayEvent,
} from "@/lib/day-event/day-event-factory";
import {
  subtractEvents,
  createHatchedEventsInEmptySpaces,
  mergeDayEvents,
} from "@/lib/day-event/day-event-utils";
import { addMinutes } from "@/lib/date";
import { formatTimeHourMinute, getTotalMinutes } from "@/lib/time";
import { ReportUnavailabilityDrawer } from "@/components/report-unavailability-drawer.tsx";

const GetAvailableTimesQuery = graphql(`
  query GetAvailableTimesQuery(
    $codUnidade: Int!
    $codUsuarioCompromisso: Int!
    $codUsuarioFormaRecebimento: Int!
    $data: String!
    $diaSemana: Int!
    $selectedDate: date!
  ) {
    GetAvailableTimes(
      arg1: {
        codUnidade: $codUnidade
        codUsuarioCompromisso: $codUsuarioCompromisso
        codUsuarioFormaRecebimento: $codUsuarioFormaRecebimento
        data: $data
        comHorarioExtra: true
        comHorariosMenores: true
      }
    ) {
      dataHora
      dataHoraFim
      tipoHorarioSugestao
    }

    LivanceApiFeriadosPorPeriodo(arg1: { dataInicio: $data, dataFim: $data }) {
      horaInicio
      horaFim
      codUnidade
    }

    agendamentos(
      where: {
        data: { _eq: $selectedDate }
        codFinalidadeAgendamento: { _neq: 2 }
        cancelado: { _eq: false }
      }
    ) {
      horaInicio
      horaFim
      data
      codAgendamento
      Unidade {
        nomeLimpo
        sigla
        codUnidade
      }
      Paciente {
        nome
      }
    }

    tbFechamentos(
      where: {
        _and: [
          { data: { _eq: $selectedDate } }
          {
            _or: [
              { codUnidade: { _eq: $codUnidade } }
              { codUnidade: { _is_null: true } }
            ]
          }
        ]
      }
    ) {
      horaFim
      horaInicio
      data
      motivo
      codFechamento
      codUnidade
      codTipoFechamento
      Unidade: tbUnidade {
        nomeLimpo
        sigla
        codUnidade
      }
    }

    usuarioCompromisso: usuariosCompromissos_by_pk(
      codUsuarioCompromisso: $codUsuarioCompromisso
    ) {
      duracao
      tiposSalas {
        codTipoSala
      }
    }

    usuariosAgendas(
      where: {
        ativo: { _eq: true }
        tbUsuariosAgendasUnidades: {
          codUnidade: { _eq: $codUnidade }
          ativo: { _eq: true }
        }
        tbUsuariosAgendasCompromissos: {
          codUsuarioCompromisso: { _eq: $codUsuarioCompromisso }
          ativo: { _eq: true }
        }
        tbUsuariosAgendasDiasSemanas: {
          diaSemana: { _eq: $diaSemana }
          ativo: { _eq: true }
        }
      }
    ) {
      codUsuarioAgenda
    }
  }
`);

const SearchSpecificSlotQuery = graphql(`
  query SearchSpecificSlotQuery($input: LivanceApiBuscaHorarioLivreInput!) {
    LivanceApiBuscaHorarioLivre(arg1: $input) {
      autorizado
      codUnidade
      codUsuario
      codUsuarioAgenda
      fim
      inicio
      tipoHorarioSugestao
      prioridade
      podeExceder
    }
  }
`);

interface Unit {
  id: number;
  name: string;
  acronym: string;
}

interface AppointmentFormCalendarProps {
  codUsuarioCompromisso: number;
  codUsuarioFormaRecebimento: number;
  codAgendamento?: number;
  currentDate: string;
  unit: Unit;
  onEventClick: (
    start: Date | null,
    end: Date | null,
    timeSlotType?: number | null,
  ) => void;
}

const DEFAULT_CALENDAR_MIN_TIME = "07:00:00";

interface RouteContextProps {
  user: UserContext;
  showAppointmentsAndCloseSchedulesDuringSchedule: boolean;
  showFeedbackCaptureDesiredTime: boolean;
}

const unavailableScheduleTypes = ["old", "closed", "none"];

export const AppointmentFormCalendar = ({
  codUsuarioCompromisso,
  codUsuarioFormaRecebimento,
  codAgendamento,
  currentDate,
  unit,
  onEventClick,
}: AppointmentFormCalendarProps): JSX.Element => {
  const { user, showAppointmentsAndCloseSchedulesDuringSchedule }: RouteContextProps =
    useRouteContext({
      strict: false,
    });

  const currentDateObject = convertDateTimeIgnoringTimezone(currentDate);

  const getAvailableTimesQueryResult = useGraphQL(GetAvailableTimesQuery, {
    codUnidade: unit.id,
    codUsuarioCompromisso,
    codUsuarioFormaRecebimento,
    data: currentDate,
    diaSemana: currentDateObject.getDay(),
    selectedDate: currentDate,
  });

  const { data: getAvailableTimesQueryData } = getAvailableTimesQueryResult;

  useEffect(
    function trackAppointmentFilled() {
      if (getAvailableTimesQueryData) trackEvent("Agendamento Dados Preenchidos");
    },
    [getAvailableTimesQueryData],
  );

  const [selectedTime, setSelectedTime] = useState<{ hour: number; minute: number }>();
  const [specificSlotEvents, setSpecificSlotEvents] =
    useState<typeof searchSpecificSlotQueryData>();
  const [showNoResultsDrawer, setShowNoResultsDrawer] = useState(false);
  const [showReportUnavailabilityDrawer, setShowReportUnavailabilityDrawer] =
    useState(false);

  const { showFeedbackCaptureDesiredTime }: RouteContextProps = useRouteContext({
    strict: false,
  });

  const searchSpecificSlotQueryResult = useGraphQL(
    SearchSpecificSlotQuery,
    {
      input: {
        codUsuario: user.codUsuario,
        codUnidade: unit.id,
        codUsuarioCompromisso,
        data: currentDate,
        horarioInicio: getFormattedTimeFromNumbers(
          Number(selectedTime?.hour),
          selectedTime?.minute,
        ),
        comHorariosMenores: true,
        codAgendamentoOrigem: codAgendamento,
      },
    },
    {
      enabled: Boolean(selectedTime),
    },
  );

  const { data: searchSpecificSlotQueryData } = searchSpecificSlotQueryResult;

  useEffect(() => {
    setSpecificSlotEvents(undefined);
    setSelectedTime(undefined);
  }, [unit, codUsuarioCompromisso, codUsuarioFormaRecebimento, currentDate]);

  useEffect(() => {
    if (searchSpecificSlotQueryData) {
      setSpecificSlotEvents(searchSpecificSlotQueryData);
    }
  }, [searchSpecificSlotQueryData]);

  const calculateMinTime = (date: Date): string => {
    const minHour = isConsultorioVirtual(unit.id) ? 0 : 6;

    const hour = String(Math.max(date.getHours(), minHour));
    const paddedHour = hour.padStart(2, "0");

    return `${paddedHour}:00:00`;
  };

  const initialTimeSlot = (events: DayEvents[]): string => {
    if (specificSearchResultMapResult.length >= 1) {
      const firstAvailableTime = specificSearchResultMapResult[0];
      return calculateMinTime(firstAvailableTime.start);
    }

    const availableTimes = getAvailableTimesQueryData?.GetAvailableTimes;

    if (availableTimes) {
      const sortedAvailableTimes = [...availableTimes].sort((a, b) => {
        const dateA = new Date(a?.dataHora ?? currentDate);
        const dateB = new Date(b?.dataHora ?? currentDate);
        return dateA.getTime() - dateB.getTime();
      });

      const firstAvailableTime = sortedAvailableTimes[0];
      const initialTime = new Date(firstAvailableTime?.dataHora ?? currentDate);

      const anyEventBeforeAvailableTime = events.find(
        (event) => event.start < initialTime && event.type !== "none",
      );

      if (anyEventBeforeAvailableTime) {
        return calculateMinTime(addMinutes(initialTime, -30));
      }

      return calculateMinTime(initialTime);
    }

    return DEFAULT_CALENDAR_MIN_TIME;
  };

  const mapSpecificSearchResultIntoEvents = (): DayEvents[] => {
    if (specificSlotEvents?.LivanceApiBuscaHorarioLivre) {
      const specificSlots = specificSlotEvents.LivanceApiBuscaHorarioLivre;

      const sourceEvents: DayEvents[] = specificSlots
        .filter((specificSlot) => specificSlot?.autorizado)
        .map((specificSlot, index) => {
          return createAvailableDayEventProps({
            appointmentId: `id ${index}`,
            timeSlotType: specificSlot?.tipoHorarioSugestao as TimeSlotSuggestionType,
            unit,
            start: specificSlot?.inicio ?? "",
            end: specificSlot?.fim ?? "",
          });
        });

      if (showAppointmentsAndCloseSchedulesDuringSchedule && sourceEvents.length >= 1) {
        const hatchedEvents = createHatchedEventsInEmptySpaces(
          sourceEvents,
          currentDateObject,
        );
        return [...sourceEvents, ...hatchedEvents];
      }

      return sourceEvents;
    }

    return [];
  };

  const trackEventSpecificHourSearch = (
    isSpecificSearchFound: boolean,
    resultsQty: number,
  ): void => {
    const eventProperties = {
      codAgendamentoOrigem: codAgendamento,
      codUnidade: unit.id,
      codUsuarioCompromisso,
      data: currentDate,
      duracao: getAvailableTimesQueryData?.usuarioCompromisso?.duracao,
      hora: getFormattedTimeFromNumbers(Number(selectedTime?.hour), selectedTime?.minute),
      horarioBuscadoRetornado: isSpecificSearchFound,
      horariosRetornados: specificSlotEvents?.LivanceApiBuscaHorarioLivre?.filter(
        (item) => item?.autorizado,
      ),
      quantidadeHorariosRetornados: resultsQty,
    };

    trackEvent("Agendamento Horario Buscado", eventProperties);
  };

  const specificSearchResultMapResult = mapSpecificSearchResultIntoEvents();

  useEffect(() => {
    const filterNonAvailableTypes = (slot: DayEvents): boolean =>
      !unavailableScheduleTypes.includes(slot.type);

    const specificSearchResultMapResultFiltered = specificSearchResultMapResult.filter(
      filterNonAvailableTypes,
    );

    if (specificSlotEvents) {
      const isSpecificSearchFound =
        specificSearchResultMapResultFiltered.length === 1 &&
        shouldExecuteOnEventClick(specificSearchResultMapResultFiltered[0]);

      const resultsQty = specificSearchResultMapResultFiltered.length;
      trackEventSpecificHourSearch(isSpecificSearchFound, resultsQty);

      if (specificSearchResultMapResultFiltered.length === 0) {
        setShowNoResultsDrawer(true);
      } else if (isSpecificSearchFound) {
        const { start, end, timeSlotType } = specificSearchResultMapResultFiltered[0];
        onEventClick(start, end, timeSlotType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Precisa apenas do specificSlotEvents
  }, [specificSlotEvents]);

  const shouldExecuteOnEventClick = (event: DayEvents): boolean => {
    const formattedSelectedTime = getFormattedTimeFromNumbers(
      Number(selectedTime?.hour),
      selectedTime?.minute,
    );

    const firstSpecificTimeStart = formatIgnoringTimezone(event.start, "HH:mm:ss");

    return firstSpecificTimeStart === formattedSelectedTime;
  };

  const mapGetAvailableTimesIntoEvents = (): DayEvents[] => {
    if (getAvailableTimesQueryData?.GetAvailableTimes) {
      const availableTimes = getAvailableTimesQueryData.GetAvailableTimes;

      const sourceEvents: DayEvents[] = availableTimes.map((availableTime, index) => {
        return createAvailableDayEventProps({
          appointmentId: index,
          timeSlotType: availableTime?.tipoHorarioSugestao as TimeSlotSuggestionType,
          unit,
          start: availableTime?.dataHora ?? "",
          end: availableTime?.dataHoraFim ?? "",
        });
      });

      if (showAppointmentsAndCloseSchedulesDuringSchedule) {
        const appointments: DayEvents[] = getAvailableTimesQueryData.agendamentos.map(
          (appointment) => {
            return createOldDayEvent({
              appointmentId: appointment.codAgendamento,
              title: appointment.Paciente?.nome ?? "",
              unit: {
                id: appointment.Unidade.codUnidade,
                name: appointment.Unidade.nomeLimpo ?? "",
                acronym: appointment.Unidade.sigla ?? "",
              },
              start: appointment.horaInicio,
              end: appointment.horaFim,
              date: appointment.data,
            });
          },
        );

        const closedSchedules = getAvailableTimesQueryData.tbFechamentos.map(
          (closeSchedule) => {
            return createClosedDayEvent({
              id: closeSchedule.codFechamento,
              title: "",
              unit: {
                id: closeSchedule.Unidade?.codUnidade ?? 0,
                name: closeSchedule.Unidade?.nomeLimpo ?? "",
                acronym: closeSchedule.Unidade?.sigla ?? "",
              },
              start: closeSchedule.horaInicio,
              end: closeSchedule.horaFim,
              date: closeSchedule.data,
              closeScheduleType: closeSchedule.codTipoFechamento as CloseScheduleType,
            });
          },
        );

        const breakCloseSchedules = subtractEvents(closedSchedules, appointments);
        const mergedCloseSchedules = mergeDayEvents(breakCloseSchedules);

        const initialTime = initialTimeSlot(breakCloseSchedules);
        const initialTimeDate = new Date(`${currentDate} ${initialTime}`);
        const firstVisibleCloseSchedule = mergedCloseSchedules.find(
          (cs) => cs.end > initialTimeDate && cs.start < initialTimeDate,
        );

        if (firstVisibleCloseSchedule) {
          firstVisibleCloseSchedule.start = initialTimeDate;
        }

        const existingEvents = [
          ...sourceEvents,
          ...appointments,
          ...mergedCloseSchedules,
        ];

        const minSlotDuration = getTotalMinutes(
          getAvailableTimesQueryData.usuarioCompromisso?.duracao ?? "00:00:00",
        );

        const hatchedEvents = createHatchedEventsInEmptySpaces(
          existingEvents,
          currentDateObject,
          minSlotDuration,
        );

        return [...existingEvents, ...hatchedEvents];
      }

      return [...sourceEvents];
    }

    return [];
  };

  const mapQueryResultIntoHolidays = (): Holiday[] => {
    if (getAvailableTimesQueryData?.LivanceApiFeriadosPorPeriodo) {
      const holidaysQueryResult = getAvailableTimesQueryData.LivanceApiFeriadosPorPeriodo;

      return holidaysQueryResult
        .filter((holiday) => holiday)
        .map(
          (holiday) =>
            new Holiday(
              holiday?.horaFim ?? "23:59",
              holiday?.horaInicio ?? "00:00",
              holiday?.codUnidade ?? undefined,
            ),
        );
    }

    return [];
  };

  const isHoliday = (): boolean => {
    const holidays = mapQueryResultIntoHolidays();

    return (
      holidays.some((holiday) => holiday.doesHolidayCloseGivenUnit(unit.id)) ||
      currentDateObject.getDay() === Number(DaysOfWeekEnum.Sunday)
    );
  };

  const userHasOpenSchedule = (): boolean => {
    const schedules = getAvailableTimesQueryData?.usuariosAgendas;

    return Boolean(schedules && schedules.length > 0);
  };

  const buildDayEvents = (): DayEvents[] => {
    if (specificSlotEvents) {
      if (specificSearchResultMapResult.length === 0) {
        return mapGetAvailableTimesIntoEvents();
      }

      return specificSearchResultMapResult;
    }

    return mapGetAvailableTimesIntoEvents();
  };

  const events = buildDayEvents();

  const renderDayEvents = (): JSX.Element => {
    const shouldDisplayClosedUnitMessage = isHoliday();

    if (shouldDisplayClosedUnitMessage) {
      return (
        <CpsAlert
          title={`A unidade ${unit.acronym} não está disponível para receber agendamentos no dia selecionado.`}
          type="info"
        />
      );
    }

    const hasOpenSchedule = userHasOpenSchedule();

    if (!hasOpenSchedule && !specificSlotEvents) {
      return (
        <div className="flex flex-col gap-6 w-full">
          <CpsAlert
            title={`Você não possui nenhuma agenda aberta neste dia na unidade ${unit.acronym} com o tipo de atendimento selecionado.`}
            type="info"
          />
        </div>
      );
    }

    if (!events.some((e) => !unavailableScheduleTypes.includes(e.type))) {
      return (
        <div className="flex flex-col gap-6 w-full">
          <CpsAlert
            title={`Não foram encontrados horários disponíveis neste dia na unidade ${unit.acronym}.`}
            type="info"
          />
        </div>
      );
    }

    return (
      <CalendarDayEvent
        className="pt-0"
        currentDate={currentDate}
        events={events}
        minTime={initialTimeSlot(events)}
        maxTime={isConsultorioVirtual(unit.id) ? "24:00:00" : "23:00:00"}
        slotDuration="00:30:00"
        onEventClick={(info) => {
          const notClickedEvents = ["old", "closed", "none"];
          if (!notClickedEvents.includes(info.event.extendedProps.type as string)) {
            onEventClick(
              info.event.start,
              info.event.end,
              info.event.extendedProps.timeSlotType as TimeSlotSuggestionType,
            );
          }
        }}
      />
    );
  };

  if (searchSpecificSlotQueryResult.isLoading) {
    return <CpsSpinner />;
  }

  const getHourSelectedTimeFormatted = formatTimeHourMinute(
    getFormattedTimeFromNumbers(Number(selectedTime?.hour), selectedTime?.minute),
  );

  const reportUnavailability = (): void => {
    trackEvent("Agendamento Feedback Horario Coletado", {
      codUnidade: unit.id,
      siglaUnidade: unit.acronym,
      codTiposSalas:
        getAvailableTimesQueryData?.usuarioCompromisso?.tiposSalas.map(
          (cod) => cod.codTipoSala,
        ) ?? [],
      duracaoTeorica: getAvailableTimesQueryData?.usuarioCompromisso?.duracao ?? "-",
      data: currentDate,
      horarioBuscado: getHourSelectedTimeFormatted,
    });
  };

  return (
    <AsyncDataWrapper fallback={<CpsSpinner />} {...getAvailableTimesQueryResult}>
      <>
        <p className="text-sm font-medium sticky top-[8.1rem] bg-white z-10 -mt-6 pb-6">
          Disponibilidade de salas
        </p>

        {specificSearchResultMapResult.length > 0 && (
          <div className="-mt-6 pb-6">
            <CpsAlert
              title={
                showFeedbackCaptureDesiredTime
                  ? "Não há salas disponíveis no horário solicitado, mas encontramos opções próximas. Nenhuma sugestão de horário te atende?"
                  : "Não há salas disponíveis no horário solicitado, mas encontramos opções próximas."
              }
              type="info"
              {...(showFeedbackCaptureDesiredTime && {
                linkTitle: "Reportar indisponibilidade",
                linkPosition: "bottom",
                linkProps: {
                  onClick: () => setShowReportUnavailabilityDrawer(true),
                },
              })}
            />
            <ReportUnavailabilityDrawer
              open={showReportUnavailabilityDrawer}
              setOpen={setShowReportUnavailabilityDrawer}
              reportUnavailabilityTrackEvent={reportUnavailability}
              desiredTime={getHourSelectedTimeFormatted}
            />
          </div>
        )}
        {renderDayEvents()}
        <AppointmentFab
          setSelectedTime={setSelectedTime}
          shouldAllowEveryHour={isConsultorioVirtual(unit.id)}
        />

        <MessageDrawerRoot
          open={showNoResultsDrawer}
          setOpen={setShowNoResultsDrawer}
          icon={ExclamationCircle}
          variant="secondary"
        >
          <MessageDrawerTitle>Horário indisponível</MessageDrawerTitle>
          <MessageDrawerBody>
            Não foram encontradas disponibilidades na unidade desejada. Por favor, tente
            selecionar outro horário.
          </MessageDrawerBody>
        </MessageDrawerRoot>
      </>
    </AsyncDataWrapper>
  );
};
