import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  stringOrUndefined,
  AppointmentPurpose,
  TimeSlotSuggestionType,
  formatIgnoringTimezone,
  convertDateTimeIgnoringTimezone,
} from "@repo/lib";
import {
  useNavigate,
  useParams,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { type RescheduleAppointmentApiMutation } from "@repo/graphql-types/graphql";
import {
  type AppointmentPatientFormValues,
  AppointmentPatientFormBody,
} from "@/components/appointment-patient-form-body";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { patientInfo } from "@/lib/form-schemas/appointment-schema";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql";
import { CreatedAppointmentDrawer } from "@/components/created-appointment-drawer";
import { useFlexiblePriceAvailability } from "@/hooks/use-flexible-price-availability";
import { CreatedAppointmentWithFeedbackDrawer } from "@/components/created-appointment-with-feedback-drawer.tsx";
import { isRepeatedPatientError } from "@/lib/repeated-patient-error.ts";
import { AppointmentRepeatedPatientErrorDrawer } from "@/components/appointment-repeated-patient-error-drawer.tsx";
import { trackEvent } from "@/lib/tracking";

export const RescheduleAppointmentPatientFormFragment = graphql(/* GraphQL */ `
  fragment RescheduleAppointmentPatientFormFragment on query_root {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      nomePlano
      numeroCarteirinha
      validadeCarteirinha
      nome
      dataNascimento
      confirmado
      cancelado
      telefone
      telefoneProprio
      email
      ignorarEnvioEmail
      ignorarEnvioWhatsApp
      Paciente {
        receberWhatsAppConfirmacao
        cpf
        cpfProprio
        codPaciente
      }
    }

    newAppointmentType: usuariosCompromissos_by_pk(
      codUsuarioCompromisso: $codUsuarioCompromisso
    ) {
      nome
      codParceiroIntegracao
      tiposSalas {
        codTipoSala
        TipoSala {
          unidadesPrecosFlexiveis(
            where: { ativo: { _eq: true }, codUnidade: { _eq: $codUnidade } }
          ) {
            UnidadesPrecosFlexiveisParceirosIntegracoes {
              codParceiroIntegracao
            }
          }
          nome
        }
      }
    }
    newUnit: unidades_by_pk(codUnidade: $codUnidade) {
      nomeLimpo
      sigla
    }
  }
`);

const RescheduleAppointmentApiMutation = graphql(/* GraphQL */ `
  mutation RescheduleAppointmentApi($input: RemarcarAgendamentoInput!) {
    remarcarAgendamento(input: $input) {
      data: agendamentoApiOutput {
        codAgendamento
      }
      errors {
        ... on ValidationError {
          __typename
          message
        }
        ... on RepeatedPatientError {
          __typename
          message
        }
      }
    }
  }
`);

interface RouteContextType {
  showCreatedAppointmentFeedbackDrawer: boolean;
  user: {
    codUsuario: number;
  };
}

interface RescheduleAppointmentPatientFormProps {
  data: FragmentType<typeof RescheduleAppointmentPatientFormFragment>;
}
export const RescheduleAppointmentPatientForm = ({
  data,
}: RescheduleAppointmentPatientFormProps): JSX.Element | null => {
  const queryResult = useFragment(RescheduleAppointmentPatientFormFragment, data);

  const { newAppointmentType: appointmentTypeResult } = queryResult;

  const [atomValues, setAtomValues] = useAtom(appointmentFormAtom);

  const resetRescheduleAppointmentForm = useResetAtom(appointmentFormAtom);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    RescheduleAppointmentApiMutation,
    {
      retry: 2,
    },
  );

  const { user, showCreatedAppointmentFeedbackDrawer }: RouteContextType =
    useRouteContext({
      strict: false,
    });
  const router = useRouter();

  const searchParams = useSearch({
    from: "/appointment/$appointmentId/reschedule-patient",
  });

  const { appointmentId } = useParams({
    from: "/appointment/$appointmentId/reschedule-patient",
  });

  const unidadesPrecosFlexiveis = appointmentTypeResult?.tiposSalas.flatMap(
    (roomType) => {
      return roomType.TipoSala.unidadesPrecosFlexiveis;
    },
  );

  const isFlexiblePriceAvailable = useFlexiblePriceAvailability(
    atomValues.unit,
    appointmentTypeResult?.codParceiroIntegracao ?? undefined,
    unidadesPrecosFlexiveis,
  );

  const navigate = useNavigate();

  const {
    dataNascimento,
    Paciente,
    email,
    nome,
    telefone,
    confirmado,
    cancelado,
    ignorarEnvioEmail,
    ignorarEnvioWhatsApp,
    nomePlano,
    numeroCarteirinha,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Página que renderiza o componente valida se esse valor é não nulo
  } = queryResult.agendamento!;

  const form = useForm<AppointmentPatientFormValues>({
    resolver: zodResolver(patientInfo),
    defaultValues: {
      cpf: stringOrUndefined(Paciente?.cpf ?? undefined),
      dateOfBirth: dataNascimento
        ? convertDateTimeIgnoringTimezone(dataNascimento)
        : undefined,
      email: email ?? undefined,
      name: nome ?? undefined,
      phone: telefone,
      sendConfirmationViaWhatsApp: Paciente?.receberWhatsAppConfirmacao,
    },
  });

  const navigateWithSearch = (
    action: "success" | "error" | "error-repeated-patient",
    codAgendamento?: number,
  ): void => {
    void navigate({
      to: "/appointment/$appointmentId/reschedule-patient",
      params: { appointmentId },
      search: {
        action,
        codAgendamento,
      },
    });
  };

  const onSubmit = async (formData: AppointmentPatientFormValues): Promise<void> => {
    setAtomValues({ ...atomValues, ...formData });

    if (isFlexiblePriceAvailable) {
      handleFlexiblePriceStepRedirection();
      return;
    }

    const onError = (error: Error): void => {
      const isRepeatedPatient = isRepeatedPatientError(error);
      if (isRepeatedPatient) {
        trackEvent("Alerta Paciente Repetido Visualizado", {
          codUsuario: user.codUsuario,
          codPaciente: Paciente?.codPaciente ?? 0,
          codUnidade: atomValues.unit,
          codTipoSala:
            appointmentTypeResult?.tiposSalas.map((tipoSala) => tipoSala.codTipoSala) ??
            [],
          telaOrigem: "Reagendamento",
          data: formatIgnoringTimezone(atomValues.appointmentDate ?? new Date()),
          codAgendamento: atomValues.scheduleId,
          nomePaciente: form.getValues().name,
          telefone: form.getValues().phone,
        });

        navigateWithSearch("error-repeated-patient");
      }
    };

    const onSuccess = ({
      remarcarAgendamento: response,
    }: RescheduleAppointmentApiMutation): void => {
      trackEvent("Agendamento Reagendado", {
        codAgendamento: response.data?.codAgendamento,
        codAgendamentoRaiz: appointmentId,
      });

      navigateWithSearch("success", appointmentId);
    };

    const {
      unit,
      appointmentDate,
      appointmentTime,
      appointmentType,
      paymentMethod,
      scheduleId,
    } = atomValues;

    await mutateAsync(
      {
        input: {
          codAgendamentoOrigem: appointmentId,
          remarcarNovoAgendamento: {
            codUnidade: unit,
            codUsuarioAgenda: scheduleId ?? null,
            data: formatIgnoringTimezone(appointmentDate ?? new Date()),
            horaInicio: formatIgnoringTimezone(appointmentTime.start, "HH:mm"),
            horaFim: formatIgnoringTimezone(appointmentTime.end, "HH:mm"),
            codUsuarioCompromisso: appointmentType,
            codUsuarioFormaRecebimento: paymentMethod,
            codUsuario: user.codUsuario,
            email: formData.email,
            nome: formData.name,
            telefone: formData.phone,
            cpf: formData.cpf,
            dataNascimento: formData.dateOfBirth
              ? formatIgnoringTimezone(formData.dateOfBirth)
              : null,
            receberWhatsAppConfirmacao: formData.sendConfirmationViaWhatsApp,
            eventual: false,
            ignorarEnvioEmail,
            ignorarEnvioWhatsApp,
            nomePlano,
            numeroCarteirinha,
            confirmado,
            cancelado,
            codFinalidadeAgendamento:
              appointmentTime.timeSlotType ===
              TimeSlotSuggestionType.LimitedDurationAppointment.valueOf()
                ? AppointmentPurpose.HorariosMenores
                : AppointmentPurpose.AtendimentoPresencial,
          },
        },
      },
      { onSuccess, onError },
    );
  };

  const navigateToHomePage = (date: string): void =>
    void navigate({ to: "/", search: { date } });

  const handleSuccessDrawerDismiss = (value: boolean): void => {
    if (!value) {
      const appointmentDate = formatIgnoringTimezone(
        atomValues.appointmentDate ?? new Date(),
      );
      resetRescheduleAppointmentForm();
      form.reset();
      navigateToHomePage(appointmentDate);
    }
  };

  const handleFlexiblePriceStepRedirection = (): void => {
    const codTipoSalas =
      appointmentTypeResult?.tiposSalas.map((tipoSala) => tipoSala.codTipoSala) ?? [];

    setAtomValues((prev) => ({ ...prev, roomTypes: codTipoSalas }));

    void navigate({
      to: "/appointment/$appointmentId/flexible-pricing",
      params: { appointmentId },
    });
  };

  const handleRepeatedPatientErrorDrawerDismiss = (value: boolean): void => {
    if (!value && searchParams.action === "error-repeated-patient") {
      router.history.back();
    }
  };

  const roomTypes =
    appointmentTypeResult?.tiposSalas.map((roomType) => {
      return { name: roomType.TipoSala.nome };
    }) ?? [];

  return (
    <>
      <AppointmentPatientFormBody
        form={form}
        disabledFields={{ name: true }}
        unit={{ id: atomValues.unit, name: queryResult.newUnit?.nomeLimpo ?? "" }}
        appointmentType={{
          name: appointmentTypeResult?.nome ?? "",
          roomTypes,
        }}
        onSubmit={onSubmit}
        submitButtonLabel={isFlexiblePriceAvailable ? "Confirmar" : "Reagendar"}
      />

      {showCreatedAppointmentFeedbackDrawer ? (
        <CreatedAppointmentWithFeedbackDrawer
          locationAcronym={queryResult.newUnit?.sigla ?? "-"}
          open={Boolean(searchParams.action === "success")}
          setOpen={handleSuccessDrawerDismiss}
        />
      ) : (
        <CreatedAppointmentDrawer
          title="Agendamento remarcado"
          description="O paciente receberá um e-mail com os dados do agendamento."
          setOpen={handleSuccessDrawerDismiss}
          confirmButtonText="Ir para minha agenda"
        />
      )}

      <AppointmentRepeatedPatientErrorDrawer
        open={searchParams.action === "error-repeated-patient"}
        setOpen={handleRepeatedPatientErrorDrawerDismiss}
      />
    </>
  );
};
