import { CpsInput, type CpsInputProps } from "corpus";
import { forwardRef, useState } from "react";
import { maskCurrency } from "@repo/lib";

interface InputCurrencyProps extends CpsInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
}

export const InputCurrency = forwardRef<HTMLInputElement, InputCurrencyProps>(
  (
    { title = "Valor total", defaultValue = "", onChange, ...props },
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const [currencyValue, setCurrencyValue] = useState(defaultValue);

    const handleChangeElement = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.currentTarget;
      const maskedValue: string = maskCurrency(value);
      if (onChange) {
        onChange(maskedValue);
      }
      setCurrencyValue(value === "0,0" ? "" : maskedValue);
    };

    return (
      <CpsInput
        ref={ref}
        title={title}
        value={currencyValue}
        placeholder="R$"
        inputMode="numeric"
        onChange={handleChangeElement}
        {...props}
      />
    );
  },
);

InputCurrency.displayName = "InputCurrency";
