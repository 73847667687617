import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatIgnoringTimezone, stringOrUndefined } from "@repo/lib";
import {
  useNavigate,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { type CreateAppointmentMutation as CreateAppointmentMutationType } from "@repo/graphql-types/graphql";
import { AppointmentPurpose, TimeSlotSuggestionType } from "@repo/lib/src/enums";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { patientInfo } from "@/lib/form-schemas/appointment-schema";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql";
import {
  AppointmentPatientFormBody,
  type AppointmentPatientFormValues,
} from "@/components/appointment-patient-form-body";
import { useFlexiblePriceAvailability } from "@/hooks/use-flexible-price-availability";
import { CreatedAppointmentWithFeedbackDrawer } from "@/components/created-appointment-with-feedback-drawer.tsx";
import { CreatedAppointmentDrawer } from "@/components/created-appointment-drawer.tsx";
import { AppointmentRepeatedPatientErrorDrawer } from "@/components/appointment-repeated-patient-error-drawer.tsx";
import { trackEvent } from "@/lib/tracking";
import { isRepeatedPatientError } from "@/lib/repeated-patient-error.ts";


export const AppointmentFormPatientFragment = graphql(/* GraphQL */ `
  fragment AppointmentFormPatientFragment on query_root {
    appointmentType: usuariosCompromissos_by_pk(
      codUsuarioCompromisso: $codUsuarioCompromisso
    ) {
      nome
      codParceiroIntegracao
      tiposSalas {
        codTipoSala
        TipoSala {
          unidadesPrecosFlexiveis(
            where: { ativo: { _eq: true }, codUnidade: { _eq: $codUnidade } }
          ) {
            UnidadesPrecosFlexiveisParceirosIntegracoes {
              codParceiroIntegracao
            }
          }
          nome
        }
      }
    }
    unit: unidades_by_pk(codUnidade: $codUnidade) {
      codUnidade
      nomeLimpo
      sigla
    }
  }
`);

const CreateAppointmentMutation = graphql(`
  mutation CreateAppointment($input: CriarAgendamentoInput!) {
    criarAgendamento(input: $input) {
      data: agendamentoApiOutput {
        codAgendamento
      }
      errors {
        ... on ValidationError {
          __typename
          message
        }
        ... on RepeatedPatientError {
          __typename
          message
        }
      }
    }
  }
`);

interface AppointmentPatientFormProps {
  data: FragmentType<typeof AppointmentFormPatientFragment>;
}

export const AppointmentPatientForm = ({
  data,
}: AppointmentPatientFormProps): JSX.Element => {
  const router = useRouter();

  const fragmentResult = useFragment(AppointmentFormPatientFragment, data);

  const { appointmentType: appointmentTypeResult, unit: unitResult } = fragmentResult;

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(CreateAppointmentMutation, {
    retry: 2,
  });

  const { user, queryClient, showCreatedAppointmentFeedbackDrawer } = useRouteContext({
    from: "/appointment/create/patient",
  });

  const unidadesPrecosFlexiveis = appointmentTypeResult?.tiposSalas.flatMap(
    (roomType) => {
      return roomType.TipoSala.unidadesPrecosFlexiveis;
    },
  );

  const isFlexiblePriceAvailable = useFlexiblePriceAvailability(
    unitResult?.codUnidade,
    appointmentTypeResult?.codParceiroIntegracao ?? undefined,
    unidadesPrecosFlexiveis,
  );

  const searchParams = useSearch({
    from: "/appointment/create/patient",
  });

  const [
    {
      dateOfBirth,
      email,
      name,
      phone,
      cpf,
      patientId,
      sendConfirmationViaWhatsApp,
      ...rest
    },
    setAtomValues,
  ] = useAtom(appointmentFormAtom);

  const resetAppointmentFormAtom = useResetAtom(appointmentFormAtom);

  const navigate = useNavigate();

  const navigateWithSearch = (
    action: "success" | "error" | "error-repeated-patient",
    codAgendamento?: number,
  ): void => {
    void navigate({
      to: "/appointment/create/patient",
      search: {
        ...searchParams,
        action,
        codAgendamento,
      },
      replace: action === "error-repeated-patient",
    });
  };

  const form = useForm<AppointmentPatientFormValues>({
    resolver: zodResolver(patientInfo),
    defaultValues: {
      patientId,
      cpf: stringOrUndefined(cpf),
      dateOfBirth,
      email,
      name,
      phone,
      sendConfirmationViaWhatsApp,
    },
  });

  const onSubmit = async (formData: AppointmentPatientFormValues): Promise<void> => {
    setAtomValues({
      ...rest,
      ...formData,
      roomTypes:
        appointmentTypeResult?.tiposSalas.map((tipoSala) => tipoSala.codTipoSala) ?? [],
    });

    if (isFlexiblePriceAvailable) {
      handleFlexiblePriceStepRedirection();
      return;
    }

    const getAppointmentModality = (): string => {
      return appointmentTime.timeSlotType ===
        TimeSlotSuggestionType.LimitedDurationAppointment.valueOf()
        ? "duracao_limitada"
        : "padrao";
    };

    const onError = (error: Error): void => {
      const isRepeatedPatient = isRepeatedPatientError(error);
      
      if (isRepeatedPatient) {
        setAtomValues((prev) => ({ ...prev, patientId: undefined }));
        trackEvent("Alerta Paciente Repetido Visualizado", {
          codUsuario: user.codUsuario,
          codPaciente: patientId ?? 0,
          codUnidade: rest.unit,
          codTipoSala: appointmentTypeResult?.tiposSalas.map(
            (tipoSala) => tipoSala.codTipoSala,
          ),
          telaOrigem: "Novo agendamento",
          data: formatIgnoringTimezone(rest.appointmentDate ?? new Date()),
          nomePaciente: name,
          telefone: phone,
        });

        navigateWithSearch("error-repeated-patient");
      }
    };

    const onSuccess = ({
      criarAgendamento: response,
    }: CreateAppointmentMutationType): void => {
      trackEvent("Agendamento Realizado", {
        codAgendamento: response.data?.codAgendamento,
        modalidade: getAppointmentModality(),
      });

      void queryClient.resetQueries({ queryKey: ["PatientPageQuery"] });
      void queryClient.resetQueries({
        queryKey: ["AppointmentPatientSearchPageQuery"],
      });

      navigateWithSearch("success", response.data?.codAgendamento);
    };

    const {
      unit,
      appointmentDate,
      appointmentTime,
      appointmentType,
      paymentMethod,
      scheduleId,
    } = rest;

    await mutateAsync(
      {
        input: {
          codUnidade: unit,
          codUsuario: user.codUsuario,
          codUsuarioCompromisso: appointmentType,
          data: formatIgnoringTimezone(appointmentDate ?? new Date()),
          codUsuarioFormaRecebimento: paymentMethod,
          email: formData.email,
          nome: formData.name,
          telefone: formData.phone,
          cpf: formData.cpf ?? null,
          dataNascimento: formData.dateOfBirth
            ? formatIgnoringTimezone(formData.dateOfBirth)
            : null,
          horaFim: formatIgnoringTimezone(appointmentTime.end, "HH:mm"),
          horaInicio: formatIgnoringTimezone(appointmentTime.start, "HH:mm"),
          codUsuarioAgenda: scheduleId ?? null,
          receberWhatsAppConfirmacao: formData.sendConfirmationViaWhatsApp,
          ignorarEnvioEmail: false,
          ignorarEnvioWhatsApp: false,
          codPaciente: patientId ?? null,
          codFinalidadeAgendamento:
            appointmentTime.timeSlotType ===
            TimeSlotSuggestionType.LimitedDurationAppointment.valueOf()
              ? AppointmentPurpose.HorariosMenores
              : AppointmentPurpose.AtendimentoPresencial,
        },
      },
      { onSuccess, onError },
    );
  };

  const handleFlexiblePriceStepRedirection = (): void => {
    const codTipoSalas =
      appointmentTypeResult?.tiposSalas.map((tipoSala) => tipoSala.codTipoSala) ?? [];
    setAtomValues((prev) => ({ ...prev, roomTypes: codTipoSalas }));

    void navigate({ to: "/appointment/create/flexible-pricing" });
  };

  const navigateToHomePage = (date: string): void =>
    void navigate({ to: "/", search: { date } });

  const handleSuccessDrawerDismiss = (value: boolean): void => {
    if (!value) {
      const appointmentDate = formatIgnoringTimezone(rest.appointmentDate ?? new Date());
      resetAppointmentFormAtom();
      form.reset();
      navigateToHomePage(appointmentDate);
    }
  };

  const handleRepeatedPatientErrorDrawerDismiss = (value: boolean): void => {
    const patientsPageIndex = -1;
    if (!value) {
      router.history.go(patientsPageIndex);
    }
  };

  const roomTypes =
    appointmentTypeResult?.tiposSalas.map((roomType) => {
      return { name: roomType.TipoSala.nome };
    }) ?? [];

  return (
    <>
      <AppointmentPatientFormBody
        form={form}
        unit={{ id: unitResult?.codUnidade ?? 0, name: unitResult?.nomeLimpo ?? "" }}
        appointmentType={{
          name: appointmentTypeResult?.nome ?? "",
          roomTypes,
        }}
        disabledFields={{ name: Boolean(searchParams.patientId) }}
        onSubmit={onSubmit}
        submitButtonLabel={isFlexiblePriceAvailable ? "Confirmar" : "Agendar"}
      />

      {showCreatedAppointmentFeedbackDrawer ? (
        <CreatedAppointmentWithFeedbackDrawer
          locationAcronym={unitResult?.sigla ?? "-"}
          setOpen={handleSuccessDrawerDismiss}
          open={Boolean(searchParams.action === "success")}
        />
      ) : (
        <CreatedAppointmentDrawer
          title="Agendamento marcado"
          setOpen={handleSuccessDrawerDismiss}
          description="O paciente receberá um e-mail com os dados do agendamento."
          confirmButtonText="Ir para minha agenda"
        />
      )}

      <AppointmentRepeatedPatientErrorDrawer
        open={searchParams.action === "error-repeated-patient"}
        setOpen={handleRepeatedPatientErrorDrawerDismiss}
      />
    </>
  );
};
