import {
  CpsButtonText,
  CpsSelectorItem,
  CpsSingleSelector,
  CpsSelectorRoot,
} from "corpus";
import { useEffect } from "react";
import { useLoaderData, useRouter, useSearch } from "@tanstack/react-router";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckinFiltersEnum, WaitingRoomStatusFiltersEnum } from "@repo/lib";
import {
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
  DrawerTitle,
} from "@/components/drawer";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormRoot,
  FormItem,
  FormSubmitButton,
} from "@/components/form";

const checkinFIlters = [
  { label: "Pendente", value: CheckinFiltersEnum.Pending },
  { label: "Realizado", value: CheckinFiltersEnum.Done },
];

const statusFilters = [
  { label: "Não atendidos", value: WaitingRoomStatusFiltersEnum.AppointmentUntreated },
  { label: "Atendidos", value: WaitingRoomStatusFiltersEnum.AppointmentTreated },
];

interface WaitingRoomFilterDrawerParams {
  action?: string;
  checkin?: number;
  status?: number;
}

export const WaitingRoomFilterDrawer = (): JSX.Element => {
  const params: WaitingRoomFilterDrawerParams = useSearch({ from: "/waiting-room/" });
  const router = useRouter();

  const {
    showTreatedAppointmentInWaitingRoom,
  }: { showTreatedAppointmentInWaitingRoom: boolean } = useLoaderData({
    strict: false,
  });

  const setDrawerDismiss = (value: boolean): void => {
    if (!value && params.action === "filter-drawer") {
      void router.navigate({
        search: { ...params, action: undefined },
        replace: true,
      });
    }
  };

  const formSchema = z.object({
    checkin: z.number().optional(),
    status: z.number().optional(),
  });

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      checkin: params.checkin ?? CheckinFiltersEnum.None,
      status: params.status ?? WaitingRoomStatusFiltersEnum.None,
    },
  });

  useEffect(() => {
    form.reset({
      checkin: params.checkin ?? CheckinFiltersEnum.None,
      status: params.status ?? WaitingRoomStatusFiltersEnum.None,
    });
  }, [params.checkin, params.status, form]);

  const handleSubmit = (formData: FormFields): void => {
    void router.navigate({
      search: {
        ...params,
        ...formData,
        action: undefined,
      },
      replace: true,
    });
  };

  const handleClearFilter = (): void => {
    form.reset({
      checkin: params.checkin ?? CheckinFiltersEnum.None,
      status: params.status ?? WaitingRoomStatusFiltersEnum.None,
    });
    void router.navigate({
      search: {
        ...params,
        checkin: undefined,
        status: undefined,
      },
      replace: true,
    });
  };

  return (
    <DrawerRoot open={params.action === "filter-drawer"} setOpen={setDrawerDismiss}>
      <FormRoot {...form}>
        <DrawerContent>
          <FormHandlerSubmit handleSubmit={handleSubmit} className="w-full">
            <DrawerHeader>
              <DrawerTitle>Filtrar por:</DrawerTitle>
            </DrawerHeader>
            <DrawerBody>
              <div className="flex flex-col gap-6 my-6 ">
                <div className="flex flex-col gap-2">
                  <span>Check-in:</span>
                  <FormField
                    control={form.control}
                    name="checkin"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <CpsSelectorRoot>
                            <CpsSingleSelector
                              activeBackgroundColor="secondary"
                              key="checkin"
                              fullWidth
                              {...field}
                            >
                              {checkinFIlters.map((item) => (
                                <CpsSelectorItem
                                  className="min-h-[42px] w-[100px] max-w-[230px]"
                                  value={item.value}
                                  key={item.value}
                                >
                                  {item.label}
                                </CpsSelectorItem>
                              ))}
                            </CpsSingleSelector>
                          </CpsSelectorRoot>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>

                {showTreatedAppointmentInWaitingRoom ? (
                  <div className="flex flex-col gap-2">
                    <span>Status:</span>
                    <FormField
                      control={form.control}
                      name="status"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <CpsSelectorRoot>
                              <CpsSingleSelector
                                activeBackgroundColor="secondary"
                                key="status"
                                fullWidth
                                {...field}
                              >
                                {statusFilters.map((item) => (
                                  <CpsSelectorItem
                                    className="min-h-[42px] w-[100px] max-w-[230px]"
                                    value={item.value}
                                    key={item.value}
                                  >
                                    {item.label}
                                  </CpsSelectorItem>
                                ))}
                              </CpsSingleSelector>
                            </CpsSelectorRoot>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                ) : null}
              </div>
            </DrawerBody>
            <DrawerFooter>
              <FormSubmitButton className="w-full">Aplicar</FormSubmitButton>
              <CpsButtonText onClick={handleClearFilter}>Limpar filtro</CpsButtonText>
            </DrawerFooter>
          </FormHandlerSubmit>
        </DrawerContent>
      </FormRoot>
    </DrawerRoot>
  );
};
