import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import {
  Controller,
  type ControllerProps,
  type FieldError,
  type FieldPath,
  type FieldValues,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { cn } from "@repo/lib";
import { Button, type ButtonProps } from "@/components/button";

export const FormRoot = FormProvider;

interface FormProps extends React.ComponentPropsWithoutRef<"form"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- react hook forms SubmitHandler not generic
  handleSubmit?: (data: any) => Promise<void> | void;
}

export const FormHandlerSubmit = ({
  handleSubmit,
  className,
  ...props
}: FormProps): JSX.Element => {
  const form = useFormContext();

  return (
    <form
      className={cn("max-w-2xl mx-auto flex flex-col gap-6", className)}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- react hook forms
      onSubmit={handleSubmit ? form.handleSubmit(handleSubmit) : undefined}
      {...props}
    />
  );
};

interface FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
}

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
);

export const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>): JSX.Element => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = (): {
  invalid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  isValidating: boolean;
  error?: FieldError | undefined;
  id: string;
  name: string;
  formItemId: string;
  formMessageId: string;
} => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always
  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

interface FormItemContextValue {
  id: string;
}

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue,
);

export const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn("space-y-2", className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = "FormItem";

export const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formMessageId } = useFormField();

  return (
    <>
      <Slot
        ref={ref}
        id={formItemId}
        aria-describedby={error ? formMessageId : ""}
        aria-invalid={Boolean(error)}
        {...props}
      />
      <FormMessage />
    </>
  );
});
FormControl.displayName = "FormControl";

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error.message) : children;

  if (!body) {
    return null;
  }

  return (
    <FormErrorMessage id={formMessageId} ref={ref} className={className} {...props}>
      {body}
    </FormErrorMessage>
  );
});
FormMessage.displayName = "FormMessage";

export const FormErrorMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  if (!children) {
    return null;
  }

  return (
    <p
      ref={ref}
      className={cn("text-sm font-medium text-danger-400", className)}
      {...props}
    >
      {children}
    </p>
  );
});
FormErrorMessage.displayName = "FormErrorMessage";

type FormSubmitButtonProps = ButtonProps;

export const FormSubmitButton = ({
  className,
  ...props
}: FormSubmitButtonProps): JSX.Element => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <div className={cn("space-y-2", className)}>
      <Button
        type="submit"
        fullWidth
        loading={isSubmitting}
        disabled={isSubmitting}
        {...props}
      />
    </div>
  );
};
FormSubmitButton.displayName = "FormSubmitButton";
