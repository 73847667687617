import { Selector } from "@repo/icons";
import { allLocationObject, type Location } from "@repo/lib";
import { useNavigate, useSearch } from "@tanstack/react-router";

interface CalendarLocationSelectorProps {
  currentLocation?: Location;
  isPending: boolean;
  align: "start";
}

export const CalendarLocationSelector = ({
  currentLocation,
  isPending,
}: CalendarLocationSelectorProps): JSX.Element => {
  const selectedLocation: Location = currentLocation ?? allLocationObject;

  const navigate = useNavigate();

  const searchParams = useSearch({
    from: "/",
  });

  const openModal = (): void => {
    void navigate({ search: { ...searchParams, action: "select-location" } });
  };

  if (isPending) {
    return (
      <div>
        <div className="animate-pulse gap-3 h-6 w-16 bg-primary-200 rounded-md shadow-none" />
      </div>
    );
  }

  return (
    <div className="">
      <button
        className="rounded-full flex items-center"
        onClick={openModal}
        type="button"
      >
        <p className="text-white font-medium text-lg pr-1">
          {selectedLocation.nomeLimpo}
        </p>
        <Selector className="fill-white" size={16} />
      </button>
    </div>
  );
};
