"use client";

import { CheckCircle, Exclamation, InformationCircle, X, XCircle } from "@repo/icons";
import { clsx } from "clsx";
import { useEffect, useState } from "react";

export interface CpsToastProps {
  title: string;
  description?: string;
  type: "success" | "danger" | "info" | "warning";
  onClose: () => void;
  duration?: number;
  show: boolean;
}

const styles = {
  success: {
    icon: <CheckCircle className="fill-success-400 shrink-0" size={24} />,
    border: "border-success-400",
  },
  danger: {
    icon: <XCircle className="fill-danger-400 shrink-0" size={24} />,
    border: "border-danger-400",
  },
  info: {
    icon: <InformationCircle className="fill-helper-400 shrink-0" size={24} />,
    border: "border-helper-400",
  },
  warning: {
    icon: <Exclamation className="fill-warning-400 shrink-0" size={24} />,
    border: "border-warning-400",
  },
};

/**
 * `CpsToast` is a component that renders a toast.
 *
 * @remarks
 * This component is part of the CPS UI library.
 *
 * @example
 * Here is a basic usage example:
 * ```tsx
 * <CpsToast title="Toast success" />
 * ```
 * @param title - The title of the toast.
 * @param description - The description of the toast.
 * @param type - The type of the toast.
 * @param onClose - A function that is called when the toast is closed.
 * @param duration - The duration of the toast in milliseconds.
 * @param show - Whether the toast is visible.
 *
 * @returns The `CpsToast` component.
 */
export const CpsToast = ({
  title,
  description,
  type,
  onClose,
  duration,
  show,
}: CpsToastProps): JSX.Element => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (duration) {
      timeoutId = setTimeout(onClose, duration * 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [duration, onClose]);

  const closeToast = (): (() => void) => {
    setIsClosing(true);

    const timeoutId = setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  const cpsToastShowClasses = clsx(
    "bottom-3 fixed left-1/2 z-50 -translate-x-1/2",
    isClosing ? "animate-toast-fadeout" : "animate-toast-fadein",
    show ? "block" : "hidden",
  );

  return (
    <div data-testid="cps-toast-test-id" className={cpsToastShowClasses}>
      <div
        className={clsx(
          "py-6 flex w-[328px] justify-between rounded-md border-b-4 bg-white px-4 shadow-xl shadow-neutral-200",
          styles[type].border,
        )}
      >
        <div className="flex">
          {styles[type].icon}
          <div className="ml-2">
            <p className="font-medium text-neutral-500">{title}</p>
            {description ? (
              <p className="mt-2 font-medium text-neutral-400">{description}</p>
            ) : null}
          </div>
        </div>
        <div className="ml-3">
          <button type="button" onClick={closeToast}>
            <X className="cursor-pointer" size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CpsToast;
