import { Edit1, User, InformationCircle, CheckCircle, HelpCircle } from "@repo/icons";
import { CpsAlert, CpsAvatar, CpsButtonText, CpsInput, CpsTextArea } from "corpus";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { useAtom } from "jotai/index";
import { useResetAtom } from "jotai/utils";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { InputPhone } from "@/components/input-phone.tsx";
import { CardContent, CardRoot } from "@/components/card.tsx";
import { recommendationMemberAtom } from "@/lib/atoms/recommendation-member-atom.ts";
import { getImageSourceFromBase64 } from "@/lib/image-source.ts";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form.tsx";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql.ts";
import {
  MessageDrawerActionButton,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer.tsx";
import {
  TooltipDrawerBody,
  TooltipDrawerRoot,
  TooltipDrawerTitle,
} from "@/components/tooltip-drawer.tsx";
import { trackEvent } from "@/lib/tracking";

export const RecommendationMembersForPatientFormFragment = graphql(/* GraphQL */ `
  fragment RecommendationMembersForPatientFormFragment on query_root {
    paciente: pacientes_by_pk(codPaciente: $codPaciente) {
      codPaciente
      nome
      telefone
    }
    fotoPerfilMembro: LivanceApiSelecionaFotoDePerfil(arg1: { codUsuario: $codMembro }) {
      arquivo
      mimetype
      codTipoArquivo
    }
  }
`);

const IndicateMemberForPatientMutation = graphql(/* GraphQL */ `
  mutation IndicateMemberForPatientMutation($input: IndicarProfissionalInput!) {
    indicarProfissional(input: $input) {
      sucesso
      errors {
        ... on ValidationError {
          __typename
          message
        }
      }
    }
  }
`);

interface RecommendationMembersForPatientsFormProps {
  data: FragmentType<typeof RecommendationMembersForPatientFormFragment>;
}
export const RecommendationMembersForPatientsForm = ({
  data,
}: RecommendationMembersForPatientsFormProps): JSX.Element => {
  const router = useRouter();
  const params = useSearch({
    from: "/recommendation/confirmation/",
  });
  const [member] = useAtom(recommendationMemberAtom);
  const resetMemberAtomValues = useResetAtom(recommendationMemberAtom);

  const fragmentResult = useFragment(RecommendationMembersForPatientFormFragment, data);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    IndicateMemberForPatientMutation,
  );

  const { user } = useRouteContext({ strict: false });

  const { paciente, fotoPerfilMembro } = fragmentResult;

  const imageSrc = getImageSourceFromBase64(
    fotoPerfilMembro?.mimetype,
    fotoPerfilMembro?.arquivo,
  );

  const formSchema = z.object({
    patientPhone: z.string(),
    messageToIndicatedProfessional: z.string(),
  });

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      patientPhone: paciente?.telefone,
      messageToIndicatedProfessional: "",
    },
  });

  const handleClickMemberEditButton = (): void => {
    resetMemberAtomValues();
    router.history.back();
  };

  const handleSubmit = async (formData: FormFields): Promise<void> => {
    const onSuccess = (): void => {
      trackEvent("Indicacao Membro Enviada", {
        codUsuarioIndicando: user.codUsuario,
        codUsuarioIndicado: member.id,
        codPacienteIndicado: params.patientId,
      });

      void router.navigate({
        search: {
          ...params,
          action: "success",
        },
      });
    };

    await mutateAsync(
      {
        input: {
          codPaciente: params.patientId,
          codUsuarioIndicado: member.id,
          telefonePaciente: formData.patientPhone.replace(/[^\d]/g, ""),
          mensagem: formData.messageToIndicatedProfessional,
        },
      },
      { onSuccess },
    );
  };

  const drawerSuccessDismiss = (value: boolean): void => {
    if (!value && params.action === "success") {
      resetMemberAtomValues();
      void router.navigate({
        to: "/",
      });
    }
  };

  const openDrawerMessageTooltip = (): void => {
    void router.navigate({ search: { ...params, action: "message-tooltip" } });
  };

  const drawerMessageTooltipDismiss = (value: boolean): void => {
    if (!value && params.action === "message-tooltip") {
      router.history.back();
    }
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit className="justify-between h-[90vh]" handleSubmit={handleSubmit}>
        <div className="flex flex-col gap-6">
          <h2 className="font-medium">
            Por favor, confirme todas as informações antes de enviar a indicação.
          </h2>
          <CpsInput
            title="Nome do paciente"
            disabled
            value={paciente?.nome}
            IconLeft={User}
          />
          <FormField
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputPhone {...field} />
                </FormControl>
              </FormItem>
            )}
            name="patientPhone"
          />

          <div className="flex-col flex gap-2 font-medium justify-between">
            <h2 className="font-medium">Profissional</h2>
            <CardRoot onClick={handleClickMemberEditButton}>
              <CardContent className="row flex-row justify-between">
                <div className="flex-row flex justify-between gap-4">
                  <CpsAvatar
                    initials={member.name}
                    color="primaryLight"
                    size={40}
                    src={imageSrc}
                  />
                  <div className="flex-col">
                    <h3 className="font-medium text-md">{member.name}</h3>
                    <p className="font-normal">{member.specialty}</p>
                  </div>
                </div>
                <CpsButtonText
                  Icon={Edit1}
                  color="secondary"
                  size="xs"
                  className="self-start py-0"
                >
                  Alterar
                </CpsButtonText>
              </CardContent>
            </CardRoot>
          </div>

          <FormField
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsTextArea
                    title="Mensagem para o profissional"
                    Icon={HelpCircle}
                    onIconClick={openDrawerMessageTooltip}
                    placeholder="Digite aqui"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
            name="messageToIndicatedProfessional"
          />
        </div>
        <div className="flex flex-col gap-6">
          <CpsAlert
            type="info"
            title="Ao prosseguir, o paciente receberá a indicação e o profissional será informado por email com os dados do paciente para contato."
            Icon={InformationCircle}
          />
          <FormSubmitButton>Enviar indicação</FormSubmitButton>
        </div>

        <TooltipDrawerRoot
          open={params.action === "message-tooltip"}
          setOpen={drawerMessageTooltipDismiss}
        >
          <TooltipDrawerTitle>Mensagem para o profissional</TooltipDrawerTitle>
          <TooltipDrawerBody>
            <p>
              Escreva uma mensagem para o profissional indicado. Não se preocupe, o
              paciente não verá essa mensagem.
            </p>
          </TooltipDrawerBody>
        </TooltipDrawerRoot>

        <MessageDrawerRoot
          icon={CheckCircle}
          variant="secondary"
          open={params.action === "success"}
          setOpen={drawerSuccessDismiss}
        >
          <MessageDrawerTitle>Indicação enviada</MessageDrawerTitle>
          <MessageDrawerBody>
            Obrigado por ajudar a proporcionar o melhor cuidado aos pacientes.
          </MessageDrawerBody>
          <MessageDrawerActionButton onClick={() => drawerSuccessDismiss(false)}>
            Fechar
          </MessageDrawerActionButton>
        </MessageDrawerRoot>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
