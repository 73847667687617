import { useRouter, useSearch } from "@tanstack/react-router";
import {
  TooltipDrawerBody,
  TooltipDrawerRoot,
  TooltipDrawerTitle,
} from "@/components/tooltip-drawer";

interface ExtendTimeDrawerProps {
  action: string;
}

export const ExtendTimeDrawer = (): JSX.Element => {
  const params: ExtendTimeDrawerProps = useSearch({ strict: false });
  const router = useRouter();

  const navigateBack = (open: boolean): void => {
    if (!open && params.action === "extend-time-drawer") {
      router.history.back();
    }
  };

  return (
    <TooltipDrawerRoot
      open={params.action === "extend-time-drawer"}
      setOpen={navigateBack}
    >
      <TooltipDrawerTitle>
        <span className="font-medium">Estender atendimento</span>
      </TooltipDrawerTitle>
      <TooltipDrawerBody>
        <div className="mb-10">
          <p>Precisa de mais tempo para finalizar seu atendimento?</p>
          <p>
            Utilize a funcionalidade de extensão de agendamento e adicione pontualmente
            minutos para esse atendimento.
          </p>
          <p>
            As opções de minutos apresentados refletem a disponibilidade da unidade.
            Escolha uma opção e garanta um atendimento tranquilo sem prejudicar outros
            colegas.
          </p>
          <p>
            Lembre-se que a cobrança dessa duração será feita com base na totalidade do
            tempo agendado, e não será possível cancelar após a confirmação da extensão.
          </p>
          <p>
            Sempre que o tempo agendado for ultrapassado, uma taxa adicional será cobrada.
          </p>
        </div>
      </TooltipDrawerBody>
    </TooltipDrawerRoot>
  );
};
