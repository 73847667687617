interface WaitingRoomWarningLatePatientProps {
  lateTime: string | null | undefined;
}

export const WaitingRoomWarningLatePatient = ({
  lateTime,
}: WaitingRoomWarningLatePatientProps): JSX.Element => {
  const labelLateTime = lateTime
    ? `Paciente atrasará ${lateTime} minutos`
    : "Paciente atrasará para consulta";
  return (
    <div
      data-testid="warning-late-patient"
      className="h-[42px] items-center bg-primary-50 shadow-[outset_0_0_0_1px] shadow-primary-300 rounded-b-lg justify-center flex p-2"
    >
      <span className="text-sm">{labelLateTime}</span>
    </div>
  );
};
