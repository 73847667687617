import { Preferences } from "@capacitor/preferences";

export const preferencesStorageCache = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Auth0 ICache signature expects an any type
  get: async (key: string): Promise<any> => {
    const result = await Preferences.get({ key });
    return result.value ? (JSON.parse(result.value) as unknown) : undefined;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Auth0 ICache signature expects an any type
  set: async (key: string, value: any): Promise<void> => {
    return Preferences.set({
      key,
      value: value ? JSON.stringify(value) : "",
    });
  },

  remove: async (key: string): Promise<void> => {
    return Preferences.remove({
      key,
    });
  },

  // Optional
  allKeys: async (): Promise<string[]> => {
    const result = await Preferences.keys();
    return result.keys;
  },
};
