import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import { SelectInput, type SelectInputProps } from "@/components/select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";

export const HealthInsurancesListToSelectQueryFragment = graphql(/* GraphQL */ `
  fragment HealthInsurancesListToSelectQueryFragment on tiposConvenios {
    codTipoConvenio
    nome
  }
`);

interface HealthInsurancesSelectInputProps
  extends Omit<SelectInputProps<number>, "items"> {
  data: FragmentType<typeof HealthInsurancesListToSelectQueryFragment>[];
}

export const HealthInsurancesSelectInput = forwardRef<
  HTMLInputElement,
  HealthInsurancesSelectInputProps
>(({ data, ...props }, ref: React.Ref<HTMLInputElement>): JSX.Element => {
  const healthInsurances = useFragment(HealthInsurancesListToSelectQueryFragment, data);

  const items: SelectDrawerItemProps<number>[] = healthInsurances.map(
    (healthInsurance) => {
      return {
        label: healthInsurance.nome,
        value: healthInsurance.codTipoConvenio,
      };
    },
  );

  return <SelectInput<number> {...props} items={items} ref={ref} />;
});

HealthInsurancesSelectInput.displayName = "HealthInsurancesSelectInput";
