interface TransactionType {
  valorUnitario: number;
  usuariosMovimentacoesRecorrentes: RecurringFinancialRecord[];
}
interface RecurringFinancialRecord {
  quantidade: number;
  ativo: boolean;
  dataInicio: string;
}

/**
 * Calcula o valor da mensalidade com abono
 *
 * @param transactionType - As transações a serem consideradas.
 * @param basePrice - O valor base da mensalidade.
 * @returns O valor da mensalidade com abono.
 */
export const getPlanValueWithAllowance = (
  transactionType: TransactionType,
  basePrice: number,
): number => {
  const activeTransactions = filterActiveLastTransactions(transactionType);
  return (
    basePrice -
    activeTransactions.reduce(
      (value, currentTransaction) =>
        value + transactionType.valorUnitario * currentTransaction.quantidade,
      0,
    )
  );
};

const filterActiveLastTransactions = (
  transactionType: TransactionType,
): RecurringFinancialRecord[] => {
  const sortByDateDesc = (
    a: RecurringFinancialRecord,
    b: RecurringFinancialRecord,
  ): number => {
    return new Date(b.dataInicio).getTime() - new Date(a.dataInicio).getTime();
  };

  return transactionType.usuariosMovimentacoesRecorrentes
    .filter((transaction) => transaction.ativo)
    .sort(sortByDateDesc)
    .slice(0, 2);
};
