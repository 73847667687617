/* eslint-disable react/no-array-index-key -- Utilizado para montagem do formulário */
/* eslint-disable camelcase -- Classe gerada pelo Codegen*/
import { CpsInput } from "corpus";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { type z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import {
  type Locus_Faq_Membro_Insert_Input,
  GetOneLivUserFaqDocument,
} from "@repo/graphql-types/graphql";
import { useNavigate, useRouter, useSearch } from "@tanstack/react-router";
import React from "react";
import { CircleCheck } from "@repo/icons";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
  MessageDrawerActions,
  MessageDrawerActionButton,
} from "@/components/message-drawer";
import { ActionButtonText } from "@/components/action-button-text";
import { oneLivFormFAQWithValidation } from "@/lib/form-schemas/oneliv-form-schema";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";

export const OneLivFAQFormFragment = graphql(/* GraphQL */ `
  fragment OneLivFAQFormFragment on locus_membro {
    id
    faqs {
      id
      pergunta
      resposta
    }
  }
`);

const UpdateMemberFAQMutation = graphql(/* GraphQL */ `
  mutation UpdateMemberFAQMutation($objects: [locus_faq_membro_insert_input!]!) {
    insert_locus_faq_membro(
      on_conflict: {
        constraint: faq_membro_pkey
        update_columns: [pergunta, resposta, deletado]
      }
      objects: $objects
    ) {
      affected_rows
    }
  }
`);

export type OneLivFormFAQValues = z.infer<typeof oneLivFormFAQWithValidation>;

interface OneLivFAQFormProps {
  data?: FragmentType<typeof OneLivFAQFormFragment>[];
}

export const OneLivFAQForm = ({ data }: OneLivFAQFormProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivFAQFormFragment, data);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(UpdateMemberFAQMutation);

  const { action } = useSearch({ from: "/oneliv/faq" });

  const navigate = useNavigate({ from: "/oneliv/faq" });

  const router = useRouter();

  const memberFAQs = fragmentResult?.[0]?.faqs ?? [];

  const invalidateGetOneLivUserFAQ = useInvalidateQuery(GetOneLivUserFaqDocument);

  const getDefaultValues = (): OneLivFormFAQValues => {
    const defaultValues = memberFAQs.length
      ? memberFAQs.map((item) => ({
          id: item.id,
          question: item.pergunta,
          answer: item.resposta,
        }))
      : Array.from({ length: 3 }, () => ({ id: 0, question: "", answer: "" }));

    return { faqs: defaultValues };
  };

  const form = useForm<OneLivFormFAQValues>({
    resolver: zodResolver(oneLivFormFAQWithValidation),
    defaultValues: getDefaultValues(),
  });

  const handleSuccessDrawerDismiss = (value: boolean): void => {
    if (!value) {
      form.reset(getDefaultValues());
      router.history.back();
    }
  };

  const onSubmit = async (formData: OneLivFormFAQValues): Promise<void> => {
    const onSuccess = (): void => {
      invalidateGetOneLivUserFAQ();

      void navigate({
        search: { action: "success" },
      });
    };

    const idMember = fragmentResult?.[0].id;

    const objects = formData.faqs
      .filter(
        ({ id, question, answer }) => !(id === 0 && question === "" && answer === ""),
      )
      .map(({ id, question, answer }) => {
        const isDeletado = Boolean(!question && !answer && id);

        const originalFAQ = memberFAQs.find((faq) => faq.id === id);

        const insertInput: Locus_Faq_Membro_Insert_Input = {
          deletado: isDeletado,
          idMembro: idMember,
          id: id || undefined,
          pergunta: isDeletado ? originalFAQ?.pergunta : question,
          resposta: isDeletado ? originalFAQ?.resposta : answer,
        };

        return insertInput;
      });

    await mutateAsync(
      {
        objects,
      },
      { onSuccess },
    );
  };

  const faqsValues = form.watch("faqs");

  const addNewFAQ = (): void => {
    form.setValue("faqs", [...faqsValues, { id: 0, question: "", answer: "" }]);
  };

  return (
    <>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={onSubmit}>
          <p className="font-medium text-neutral-600">
            Adicione as perguntas frequentes que você costuma receber de seus pacientes,
            juntamente com as respostas que você normalmente fornece.
          </p>
          {faqsValues.map((item, index) => (
            <React.Fragment key={`${item.id}-${index}`}>
              <FormField
                key={`${item.id}-${index}-question`}
                control={form.control}
                name={`faqs.${index}.question`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <CpsInput
                        title={`Pergunta (${index + 1})`}
                        placeholder="Digite aqui"
                        inputMode="text"
                        type="text"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                key={`${item.id}-${index}-answer`}
                control={form.control}
                name={`faqs.${index}.answer`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <CpsInput
                        title={`Resposta (${index + 1})`}
                        placeholder="Digite aqui"
                        inputMode="text"
                        type="text"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              {index + 1 < faqsValues.length && (
                <hr className="text-neutral-200 border-1" />
              )}
            </React.Fragment>
          ))}

          <ActionButtonText onClick={addNewFAQ}>
            Adicionar nova pergunta frequente
          </ActionButtonText>

          <FormSubmitButton type="submit">Concluir</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>

      <MessageDrawerRoot
        open={action === "success"}
        setOpen={handleSuccessDrawerDismiss}
        icon={CircleCheck}
        variant="secondary"
      >
        <MessageDrawerTitle>
          Parabéns, você acaba de preencher todas as informações do seu perfil OneLiv!
        </MessageDrawerTitle>
        <MessageDrawerBody>
          Caso deseja alterar alguma informação basta voltar a este formulário
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            onClick={() =>
              void navigate({
                to: "/settings",
              })
            }
          >
            Voltar para o perfil
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
    </>
  );
};
