import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AppointmentReceivingOptionsList } from "@/components/appointment-receiving-options-list";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { PendingComponent } from "@/components/pending-component";

const AppointmentReceivingOptionsPageQuery = graphql(/* GraphQL */ `
  query AppointmentReceivingOptionsPageQuery {
    ...AppointmentReceivingOptionsListQueryFragment
  }
`);

export const AppointmentReceivingOptionsPage = (): JSX.Element => {
  const queryResult = useGraphQL(AppointmentReceivingOptionsPageQuery);
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Convênios" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <AppointmentReceivingOptionsList appointmentReceivingOptions={data} />
          ) : (
            <FullPageSpinner />
          )}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/schedule-options/appointment-receiving-options/",
)({
  component: AppointmentReceivingOptionsPage,
  pendingComponent: () => <PendingComponent title="Convênios" />,
});
