import { useFragment, type FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Right } from "@repo/icons";
import {
  formatCurrency,
  cn,
  AppointmentPurpose,
  BillingItemOriginTypeEnum,
} from "@repo/lib";
import { Link } from "@tanstack/react-router";
import { CpsBadge } from "corpus";
import { BillingItemDiscount } from "@/components/billing-item-discount";
import { useGraphQL } from "@/hooks/use-graphql";

export const NewBillingItemQueryFragment = graphql(/* GraphQL */ `
  fragment NewBillingListItemQueryFragment on MovimentacaoExibicao {
    codCobranca
    codMovimentacao
    codOrigem
    codUsuario
    dataCadastro
    quantidade
    tempoUnitario
    tipoOrigem
    valor
    valorDesconto
    valorUnitario
    valorAcrescimo
    nomeUnidade
    nomeTipoMovimentacao
  }
`);

const BillingItemQuery = graphql(/* GraphQL */ `
  query BillingItemQuery($codAgendamento: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      nome
      cobraCancelamento
      codFinalidadeAgendamento
      data
      horaInicio
      horaFim
    }
    agendamentosAlocacoes(where: { codAgendamento: { _eq: $codAgendamento } }) {
      duracaoEmMinutos
    }
  }
`);

const appointmentsOriginTypes = [
  BillingItemOriginTypeEnum.Agendamento.valueOf(),
  BillingItemOriginTypeEnum.HorariosMenores.valueOf(),
  BillingItemOriginTypeEnum.PeriodoGarantido.valueOf(),
];

export const NewBillingItem = (props: {
  billingItem: FragmentType<typeof NewBillingItemQueryFragment>;
}): JSX.Element => {
  const billingItem = useFragment(NewBillingItemQueryFragment, props.billingItem);

  const isAppointment = appointmentsOriginTypes.includes(billingItem.tipoOrigem ?? "");
  const queryResult = useGraphQL(
    BillingItemQuery,
    {
      codAgendamento: Number(billingItem.codOrigem),
    },
    { enabled: isAppointment, staleTime: Infinity },
  );

  const { data: appointmentItem } = queryResult;

  const totalValue = (): string => {
    let billingItemValue = (billingItem.valor + billingItem.valorAcrescimo) as number;

    if (billingItem.valorDesconto) {
      billingItemValue += billingItem.valorDesconto as number;
    }

    return formatCurrency(billingItemValue);
  };
  const flexiblePrice = appointmentItem?.agendamento?.cobraCancelamento;
  const durationLimit =
    appointmentItem?.agendamento?.codFinalidadeAgendamento ===
    AppointmentPurpose.HorariosMenores.valueOf();

  return (
    <div className="py-3">
      <Link
        aria-hidden="true"
        className={cn(
          "flex flex-row items-center justify-between",
          isAppointment ? "cursor-pointer" : "cursor-default",
        )}
        to={isAppointment ? "/settings/billing/$billingItemId" : undefined}
        params={{ billingItemId: String(billingItem.codMovimentacao) }}
        search={
          isAppointment
            ? {
                appointmentId: String(billingItem.codOrigem),
              }
            : undefined
        }
      >
        <div>
          <p className="font-normal text-neutral-500 text-base">
            {billingItem.nomeTipoMovimentacao}
          </p>
          <div className="flex flex-row justify-between mt-1 items-center">
            <div className="mr-2">
              <BillingItemDiscount
                billingItemValue={
                  (billingItem.valor + billingItem.valorAcrescimo) as number
                }
                discountValue={billingItem.valorDesconto as number}
              />
              <p className="font-medium text-lg">{totalValue()}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center">
          {flexiblePrice ? <CpsBadge color="primary">Preço flexível</CpsBadge> : null}
          {durationLimit ? <CpsBadge color="helper">Limite de duração</CpsBadge> : null}

          {isAppointment ? <Right size={16} className="fill-neutral-700" /> : null}
        </div>
      </Link>
    </div>
  );
};
