import { graphql } from "@repo/graphql-types";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { CpsAlert } from "corpus";
import { isInFirstMonthOfAnnualPlan } from "@/lib/plans";

const psychoanalysisSpecialtyId = 57;
const psychologySpecialtyId = 58;
const psychopedagogySpecialtyId = 70;
const memberLimitDate = "2023-04-24";

export const ChangePlanAlertFragment = graphql(/* GraphQL */ `
  fragment ChangePlanAlertFragment on query_root {
    usuarioPlano: usuariosProfissionaisPlanos(where: { ativo: { _eq: true } }) {
      plano {
        nome
        temFidelidade
      }
      dataInicio
    }
    usuariosEspecialidades {
      codEspecialidade
    }
    usuariosEspecialidades {
      codEspecialidade
    }
    usuariosProfissionais {
      dataValidacao
    }
  }
`);

interface ChangePlanAlertProps {
  data: FragmentType<typeof ChangePlanAlertFragment>;
  disableFullPlansForPsico?: boolean;
}

export const ChangePlanAlert = ({
  data,
  disableFullPlansForPsico = true,
}: ChangePlanAlertProps): JSX.Element => {
  const fragmentResult = useFragment(ChangePlanAlertFragment, data);
  const {
    usuarioPlano,
    usuariosEspecialidades = [],
    usuariosProfissionais,
  } = fragmentResult;

  const plano: { nome: string; temFidelidade: boolean } =
    Array.isArray(usuarioPlano) && usuarioPlano.length > 0
      ? usuarioPlano[0].plano
      : { nome: "", temFidelidade: false };

  const dataInicio = usuarioPlano.length > 0 ? usuarioPlano[0].dataInicio : "";
  const dataValidacao = usuariosProfissionais[0].dataValidacao ?? "";

  const { nome: planName, temFidelidade } = plano;

  const isLightPlan = planName.includes("Light");
  const isPsico = usuariosEspecialidades.some((especialidade) =>
    [
      psychoanalysisSpecialtyId,
      psychologySpecialtyId,
      psychopedagogySpecialtyId,
    ].includes(especialidade.codEspecialidade),
  );

  const cpsAlertMessage = (): string => {
    const isInFirstMonthOfPlan = isInFirstMonthOfAnnualPlan(dataInicio);

    if (
      disableFullPlansForPsico &&
      isPsico &&
      isLightPlan &&
      dataValidacao >= memberLimitDate
    ) {
      if (temFidelidade && !isInFirstMonthOfPlan) {
        return "Os demais planos estão indisponíveis. Consulte nosso time de atendimento ao membro para mais informações";
      }
      if ((temFidelidade && isInFirstMonthOfPlan) || !temFidelidade) {
        return "Você pode migrar apenas para outro plano light, pois os demais planos estão indisponíveis no momento";
      }
    }

    if (temFidelidade && !isInFirstMonthOfPlan) {
      return "Você pode migrar apenas para outro plano anual, respeitando o prazo mínimo de permanência de 12 meses do seu plano atual";
    }

    return "";
  };
  const showCpsAlert = cpsAlertMessage() !== "";

  return (
    <>
      {showCpsAlert ? (
        <CpsAlert description="" title={cpsAlertMessage()} type="info" />
      ) : null}
    </>
  );
};
