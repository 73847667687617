import { Link } from "@tanstack/react-router";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { CpsAlert } from "corpus";
import { LivanceApiPaymentMethod } from "@repo/lib";
import { Button } from "@/components/button";
import { type Info } from "@/components/list/item";
import { InformationalList } from "@/components/list/informational-list";

export const CardDetailsQueryFragment = graphql(/* GraphQL */ `
  fragment CardDetailsQueryFragment on query_root {
    usuarioProfissional: usuariosProfissionais_by_pk(codUsuario: $codUsuario) {
      formaDePagamento
    }
    usuariosCartoes(where: { ativo: { _eq: true } }) {
      ativo
      bandeira
      numero
      validade
    }
  }
`);

interface CardDetailsProps {
  data: FragmentType<typeof CardDetailsQueryFragment>;
}

export const CardDetails = ({ data }: CardDetailsProps): JSX.Element => {
  const fragmentResult = useFragment(CardDetailsQueryFragment, data);
  const { usuarioProfissional, usuariosCartoes } = fragmentResult;

  const cartao = usuariosCartoes[0];

  const isConfiguredToCard =
    usuarioProfissional?.formaDePagamento === LivanceApiPaymentMethod.CreditCard;

  const infos: Info[] = [
    {
      label: "Bandeira",
      value: cartao.bandeira ?? "",
    },
    {
      label: "Número",
      value: cartao.numero ?? "",
    },
    {
      label: "Validade",
      value: cartao.validade ?? "",
    },
    {
      label: "Status",
      value: cartao.ativo ? "Ativo" : "Não Ativo",
    },
  ];

  return (
    <div>
      <InformationalList title="Detalhes do Cartão" infos={infos} />
      {!isConfiguredToCard ? (
        <CpsAlert
          description=""
          title="Sua conta está configurada para pagamento via boleto, para alterar a forma de pagamento consulte nosso time de relacionamento"
          type="info"
        />
      ) : (
        <Link to="/settings/profile/card/create">
          <Button fullWidth>Alterar cartão</Button>
        </Link>
      )}
    </div>
  );
};
