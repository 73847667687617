import { CpsAlert } from "corpus";

export interface AppointmentDrawerAlertProps {
  alertData?: string;
}

export const AppointmentDrawerAlert = ({
  alertData = "",
}: AppointmentDrawerAlertProps): JSX.Element => {
  return (
    <div className="pb-4">
      <CpsAlert
        description=""
        title={`Ao ultrapassar o tempo agendado, o valor do minuto adicional será ${alertData} vezes o minuto padrão.`}
        type="info"
      />
    </div>
  );
};
