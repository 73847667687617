import { cn } from "@repo/lib";
import { CpsBadge } from "corpus";

export interface InfoSectionProps {
  section: {
    title: string;
    fields: {
      label: string;
      labelTag?: string;
      value?: string | null;
    }[];
  };
  includeLastLine?: boolean;
  lineColorLevel?: number;
}

export const InfoSection = ({
  section,
  includeLastLine = true,
  lineColorLevel = 100,
}: InfoSectionProps): JSX.Element | null => {
  const hasFields = section.fields.some((field) => field.value);
  if (!hasFields) return null;

  return (
    <div className="flex flex-col gap-6" key={section.title}>
      <p className="font-medium text-neutral-600">{section.title}</p>
      {section.fields.map(
        (field, index) =>
          field.value && (
            <div
              className={cn(
                "flex justify-between",
                includeLastLine || index !== section.fields.length - 1
                  ? "border-b border-spacing-30"
                  : "",
                `border-b-neutral-${lineColorLevel}`,
              )}
              key={field.label}
            >
              <p className="flex gap-1 text-neutral-500">
                {field.label}
                {field.labelTag ? (
                  <CpsBadge color="helper">{field.labelTag}</CpsBadge>
                ) : null}
              </p>
              <p className="text-neutral-600 mb-4 font-medium">{field.value}</p>
            </div>
          ),
      )}
    </div>
  );
};
