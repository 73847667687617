import { differenceInMinutes } from "date-fns";

export const removeSeconds = (time: string): string => {
  return time.split(":").slice(0, 2).join(":");
};

/**
 * Calcula a duração de período e retorna esta já formatada
 *
 * @param unitTime - tempo unitário no formato HH:MM:SS
 * @param quantity - quantidade de tempo unitário
 * @returns duração no formato HH:MM
 */
export const calculateFormattedDuration = (
  unitTime: string,
  quantity: number,
): string => {
  const [hours, minutes, seconds] = unitTime.split(":").map(Number);

  const unitSeconds = hours * 3600 + minutes * 60 + seconds;
  const durationInSeconds = quantity * unitSeconds;

  const durationHours = Math.floor(durationInSeconds / 3600);
  const durationMinutes = Math.floor((durationInSeconds % 3600) / 60);

  const formattedHours = String(durationHours).padStart(2, "0");
  const formattedMinutes = String(durationMinutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

/**
 * Calcula o número de intervalos dada uma duração entre duas datas.
 * @param start - Data inicial.
 * @param end - Data final.
 * @param intervalDuration - A duração de cada intervalo em minutos.
 * @returns Número de intervalos.
 */
export const calculateMinutesIntervalsBetweenDates = (
  start: Date,
  end: Date,
  intervalDuration: number,
): number => {
  const totalMinutes = differenceInMinutes(end, start);
  return Math.ceil(totalMinutes / intervalDuration);
};

/**
 * Obtém a próxima hora cheia ou a próxima meia hora a partir de uma data.
 * @param date - Data para a qual se deseja obter o próximo horário cheio ou meia hora.
 * @returns Próxima hora cheia ou meia hora.
 */
export const getNextFullHourOrHalfHour = (date: Date): Date => {
  const nextDate = new Date(date);
  const minutes = nextDate.getMinutes();

  if (minutes < 30) {
    nextDate.setMinutes(30);
  } else {
    nextDate.setMinutes(0);
    nextDate.setHours(nextDate.getHours() + 1);
  }

  nextDate.setSeconds(0);
  nextDate.setMilliseconds(0);

  return nextDate;
};
