import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

export const OneLivEducationalInstitutionSelectInputFragment = graphql(/* GraphQL */ `
  fragment OneLivEducationalInstitutionSelectInputFragment on locus_instituicao_ensino {
    id
    nome
  }
`);

export interface OneLivEducationalInstitutionSelectInputProps
  extends Omit<SelectInputProps<number>, "items"> {
  data?: FragmentType<typeof OneLivEducationalInstitutionSelectInputFragment>[];
}

export const OneLivEducationalInstitutionSelectInput = forwardRef<
  HTMLInputElement,
  OneLivEducationalInstitutionSelectInputProps
>(
  (
    { data, ...props }: OneLivEducationalInstitutionSelectInputProps,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const educationalInstitutions = useFragment(
      OneLivEducationalInstitutionSelectInputFragment,
      data,
    );

    const getItems = (): SelectDrawerItemProps<number>[] => {
      if (!educationalInstitutions || educationalInstitutions.length === 0) return [];

      const items: SelectDrawerItemProps<number>[] = educationalInstitutions.map(
        (educationalInstitution) => {
          return {
            label: educationalInstitution.nome,
            value: educationalInstitution.id,
          };
        },
      );

      return items;
    };

    const items = getItems();

    return <SelectInput<number> ref={ref} {...props} items={items} searchable />;
  },
);

OneLivEducationalInstitutionSelectInput.displayName =
  "OneLivEducationalInstitutionSelectInput";
