import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { formatCurrency } from "@repo/lib";
import { CpsBadge } from "corpus";

export const WaitingRoomAppointmentListItemPaymentFragment = graphql(/* GraphQL */ `
  fragment WaitingRoomAppointmentListItemPaymentFragment on agendamentos {
    codAgendamento
    pago
    pagar
    valor
    onlinePayments {
      codAgendamento
      amount
      status
    }
    agendamentosVinculados: AgendamentosVinculados(
      where: { codAgendamentoTipoVinculo: { _eq: 2 } }
    ) {
      onlinePayments {
        codAgendamento
        amount
        status
      }
    }
  }
`);

interface PaymentInfo {
  isPaid: boolean;
  amount: number;
}

interface WaitingRoomAppointmentListItemPaymentProps {
  data: FragmentType<typeof WaitingRoomAppointmentListItemPaymentFragment>;
}

export const WaitingRoomAppointmentListItemPayment = ({
  data,
}: WaitingRoomAppointmentListItemPaymentProps): JSX.Element => {
  const appointment = useFragment(WaitingRoomAppointmentListItemPaymentFragment, data);

  const { pagar, pago, valor, onlinePayments, agendamentosVinculados } = appointment;

  const relatedAppointmentsOnlinePayments = agendamentosVinculados.flatMap(
    (item) => item.onlinePayments,
  );

  const hasPayment =
    pagar || onlinePayments.length > 0 || relatedAppointmentsOnlinePayments.length > 0;

  const buildContent = (): JSX.Element | null => {
    if (!hasPayment) return null;

    const { totalPaid, totalPending } = getTotalPaidAndTotalPendingAmounts();

    return (
      <div className="flex flex-col gap-2 items-end">
        {totalPaid > 0 &&
          paymentBadge({ label: "Pago", color: "success", amount: totalPaid })}
        {totalPending > 0 &&
          paymentBadge({ label: "Em aberto", color: "helper", amount: totalPending })}
      </div>
    );
  };

  const getTotalPaidAndTotalPendingAmounts = (): {
    totalPaid: number;
    totalPending: number;
  } => {
    const payments: PaymentInfo[] = [];

    payments.push(...getOnlinePaymentsInfo());

    if (pagar) {
      payments.push({ amount: valor, isPaid: pago });
    }

    const { totalPaid, totalPending } = payments.reduce(
      (acc, payment) => {
        if (payment.isPaid) {
          acc.totalPaid += payment.amount;
        } else {
          acc.totalPending += payment.amount;
        }
        return acc;
      },
      { totalPaid: 0, totalPending: 0 },
    );

    return { totalPaid, totalPending };
  };

  const getOnlinePaymentsInfo = (): PaymentInfo[] => {
    if (onlinePayments.length === 0 && relatedAppointmentsOnlinePayments.length === 0)
      return [];

    const relatedAppointmentsSet = new Set(
      relatedAppointmentsOnlinePayments.map((item) => item.codAgendamento),
    );

    const combinedOnlinePayments = [
      ...onlinePayments.filter(
        (payment) => !relatedAppointmentsSet.has(payment.codAgendamento),
      ),
      ...relatedAppointmentsOnlinePayments,
    ];

    return combinedOnlinePayments.map((payment) => ({
      amount: payment.amount / 100,
      isPaid: payment.status === "paid",
    }));
  };

  const paymentBadge = ({
    label,
    color,
    amount,
  }: {
    label: string;
    color: "success" | "helper";
    amount: number;
  }): JSX.Element => (
    <div className="flex text-xs justify-end w-full">
      <div className="flex flex-row gap-x-2">
        <CpsBadge color={color}>{label}</CpsBadge>
        <div className="flex items-center ml-auto min-w-[4.5rem] text-right">
          <span className="text-neutral-400">R$</span>
          <p>{formatCurrency(amount).replace("R$", "")}</p>
        </div>
      </div>
    </div>
  );

  return <>{buildContent()}</>;
};
