import {
  TooltipDrawerRoot,
  TooltipDrawerTitle,
  TooltipDrawerBody,
} from "@/components/tooltip-drawer";

interface PatientPaymentInformationDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const PatientPaymentInformationDrawer = ({
  open,
  setOpen,
}: PatientPaymentInformationDrawerProps): JSX.Element => {
  return (
    <TooltipDrawerRoot setOpen={setOpen} open={open}>
      <TooltipDrawerTitle>Pagamentos</TooltipDrawerTitle>
      <TooltipDrawerBody>
        <p className="mb-3">
          Nesta seção, é possível analisar o valor do pagamento, a forma de pagamento
          utilizada pelo paciente, detalhes sobre o agendamento e o nome do paciente, bem
          como o status de cada pagamento.
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Entenda os status:</span>
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Pago:</span> O pagamento foi
          realizado e está aprovado;
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Cancelado:</span> O pagamento
          inicialmente realizado foi posteriormente cancelado;
        </p>
        <p className="mb-3">
          <span className="font-semibold text-neutral-600">Em aberto:</span> O pagamento
          ainda não foi realizado.
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Entenda o ícone “olho”:</span>
        </p>
        <p>
          O ícone do olho ao lado do ícone de interrogação é uma ferramenta de
          privacidade. Ao clicar nele, os valores exibidos na tela serão ocultados,
          garantindo sua tranquilidade e segurança ao usar o aplicativo em locais
          públicos. Para exibir novamente os valores, basta clicar no ícone do olho mais
          uma vez.
        </p>
      </TooltipDrawerBody>
    </TooltipDrawerRoot>
  );
};
