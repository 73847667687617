import { createFileRoute, useRouter } from "@tanstack/react-router";
import { useEffect } from "react";
import { z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { type FragmentType } from "@repo/graphql-types/fragment-masking";
import { useGraphQL } from "@/hooks/use-graphql";
import {
  SendPaymentForm,
  type SendPaymentFormFragment,
} from "@/components/send-payment-form";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";

const SendPaymentPageQuery = graphql(/* GraphQL */ `
  query SendPaymentPageQuery($codAgendamento: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      pago
      ...SendPaymentFormFragment
    }
  }
`);

export const SendPaymentPage = (): JSX.Element => {
  const router = useRouter();
  const { codAgendamento } = Route.useParams();

  const queryResult = useGraphQL(SendPaymentPageQuery, {
    codAgendamento,
  });
  const { data } = queryResult;

  useEffect(() => {
    if ((data && !data.agendamento) ?? data?.agendamento?.pago) {
      void router.navigate({ to: "/waiting-room" });
    }
  }, [data, router]);

  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Enviar pagamento" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <SendPaymentForm
              data={data.agendamento as FragmentType<typeof SendPaymentFormFragment>}
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/waiting-room/$codAgendamento/send-totem-payment/")({
  component: SendPaymentPage,
  parseParams: (params) => ({
    codAgendamento: z.number().int().parse(Number(params.codAgendamento)),
  }),
});
