import { Ban, CalendarDay } from "@repo/icons";
import { useNavigate, useRouteContext, useSearch } from "@tanstack/react-router";
import {
  cn,
  formatWithZonedDate,
  getCurrentDate,
  getTimeDateFromStrings,
} from "@repo/lib";
import { trackEvent } from "@/lib/tracking";

interface CalendarToolbarProps {
  align: "end";
}

export const CalendarToolbar = (_: CalendarToolbarProps): JSX.Element => {
  const navigate = useNavigate();
  const today = getCurrentDate().getDate();

  const searchParams = useSearch({
    from: "/",
  });

  const { user: userContext } = useRouteContext({
    strict: false,
  });

  const checkHighCancellationRate = userContext.altaTaxaCancelamento;

  const selectedDate = searchParams.date
    ? getTimeDateFromStrings(searchParams.date, "00:00")
    : new Date();
  const isSelectedDateToday = selectedDate.getDate() === today;

  const onCancelledHandler = (): void => {
    if (checkHighCancellationRate && !searchParams.showCancelled) {
      trackEvent("Alerta Frequencia Cancelamento Visualizado", {
        codUsuario: userContext.codUsuario,
        dataHora: new Date().toISOString(),
        telaOrigem: "Calendário",
      });
    }
    void navigate({
      search: {
        ...searchParams,
        showCancelled: !searchParams.showCancelled,
      },
    });
  };

  const onTodayHandler = (): void => {
    void navigate({
      search: {
        ...searchParams,
        date: formatWithZonedDate(new Date()),
      },
    });
  };

  return (
    <div>
      <div className="flex items-center space-x-3">
        <button onClick={onCancelledHandler} type="button">
          <Ban
            className={searchParams.showCancelled ? "fill-white" : "fill-primary-200"}
            size={28}
          />
        </button>
        <button onClick={onTodayHandler} type="button" className="relative">
          <CalendarDay
            className={cn(isSelectedDateToday ? "fill-primary-100" : "fill-white")}
            size={28}
          />
          <div className="absolute h-5 w-5 top-[3px] left-1">
            <span
              className={cn(
                "text-xs",
                isSelectedDateToday ? "text-primary-100" : "text-white",
              )}
            >
              {today}
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};
