import { useContext } from "react";
import {
  ErrorDrawerContext,
  type ErrorDrawerContextProps,
} from "@/lib/context/error-drawer-context";

export const useErrorDrawer = (): ErrorDrawerContextProps => {
  const context = useContext(ErrorDrawerContext);
  if (!context) {
    throw new Error("useErrorDrawer must be used within a ErrorDrawerProvider");
  }
  return context;
};
