import { Left } from "@repo/icons";
import { createFileRoute, useParams, useRouteContext } from "@tanstack/react-router";
import { z } from "zod";
import { useAtom } from "jotai";
import { graphql } from "@repo/graphql-types/gql";
import { formatIgnoringTimezone } from "@repo/lib";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { useGraphQL } from "@/hooks/use-graphql";
import { RescheduleAppointmentFlexiblePriceForm } from "@/components/reschedule-appointment-flexible-price-form";

const RescheduleAppointmentFlexiblePricingPageQuery = graphql(`
  query RescheduleAppointmentFlexiblePricingPageQuery(
    $codTipoSala: [Int!]
    $codUnidade: Int!
    $codUsuario: Int!
    $data: date!
    $horaInicio: time!
    $codAgendamento: Int!
  ) {
    ...RescheduleAppointmentFlexiblePriceFormFragment
  }
`);

const rescheduleAppointmentFlexiblePricingPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const RescheduleAppointmentFlexiblePricingPage = (): JSX.Element => {
  const [atomValues] = useAtom(appointmentFormAtom);

  const { unit, appointmentDate, appointmentTime, roomTypes } = atomValues;

  const { user } = useRouteContext({
    strict: false,
  });

  const { appointmentId } = useParams({
    from: "/appointment/$appointmentId/flexible-pricing",
  });

  const queryResult = useGraphQL(RescheduleAppointmentFlexiblePricingPageQuery, {
    codUnidade: unit,
    codUsuario: user.codUsuario,
    data: formatIgnoringTimezone(appointmentDate ?? new Date()),
    horaInicio: formatIgnoringTimezone(appointmentTime.start, "HH:mm"),
    codTipoSala: roomTypes,
    codAgendamento: appointmentId,
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Reagendamento" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <RescheduleAppointmentFlexiblePriceForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/appointment/$appointmentId/flexible-pricing")({
  component: RescheduleAppointmentFlexiblePricingPage,
  validateSearch: rescheduleAppointmentFlexiblePricingPageSearchSchema,
  parseParams: (params) => ({
    appointmentId: z.number().int().parse(Number(params.appointmentId)),
  }),
  loader: ({ context }) => {
    const minimumAntecedence =
      context.ldClient.getMinimumAntecedenceForFlexiblePriceAppointmentCreation();

    return { minimumAntecedence };
  },
});
