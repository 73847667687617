import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { z } from "zod";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { CreditCardForm } from "@/components/credit-card-form";

const CardCreatePageQuery = graphql(/* GraphQL */ `
  query CardCreatePageQuery {
    ...AppointmentReceivingOptionsListQueryFragment
    ...AppointmentReceivingOptionsQueryFragment
  }
`);

const createCardOptionSchema = z.object({
  action: z.string().optional(),
});

export const CardCreatePage = (): JSX.Element => {
  const queryResult = useGraphQL(CardCreatePageQuery);
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Cadastrar cartão" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <CreditCardForm /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/profile/card/create")({
  component: CardCreatePage,
  validateSearch: createCardOptionSchema,
});
