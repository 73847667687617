import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { format } from "date-fns";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { ReservedScheduleList } from "@/components/reserved-schedule-list";

const ReservedSchedulesQuery = graphql(/* GraphQL */ `
  query GetPeriodosGarantidosRecorrentesDoUsuario(
    $dataAgendamento: date!
    $horaInicio: time!
  ) {
    ...ReservedScheduleListQueryFragment
  }
`);

export const ReservedSchedulesPage = (): JSX.Element => {
  const queryResult = useGraphQL(ReservedSchedulesQuery, {
    dataAgendamento: format(new Date(), "yyyy-MM-dd"),
    horaInicio: format(new Date(), "HH:mm"),
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Período garantido" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <ReservedScheduleList reservedScheduleList={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/reserved-schedules/")({
  component: ReservedSchedulesPage,
});
