import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { OneLivMedicalInstitutionsForm } from "@/components/oneliv/oneliv-medical-institutions-form";

const GetOneLivUserMedicalInstitutions = graphql(`
  query GetOneLivUserMedicalInstitutions {
    ...OneLivMedicalInstitutionsFormFragment
  }
`);

const oneLivMedicalInstitutionsPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const OneLivMedicalInstitutions = (): JSX.Element => {
  const query = useGraphQL(GetOneLivUserMedicalInstitutions);

  const { data } = query;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Instituições médicas" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...query}>
          {data ? <OneLivMedicalInstitutionsForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/oneliv/medical-institutions")({
  component: OneLivMedicalInstitutions,
  validateSearch: oneLivMedicalInstitutionsPageSearchSchema,
});
