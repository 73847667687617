"use client";

import * as Checkbox from "@radix-ui/react-checkbox";
import { type CheckboxProps } from "@radix-ui/react-checkbox";
import { cn } from "@repo/cn";
import { Check } from "@repo/icons";
import {
  createContext,
  forwardRef,
  type ReactElement,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

export interface CheckboxV2Props extends CheckboxProps {
  label: string;
  value: string;
}

export const CpsCheckboxV2 = ({
  label,
  className,
  ...props
}: CheckboxV2Props): JSX.Element => {
  const { name, onChange } = useCheckboxGroupContext();

  return (
    <label
      className={cn(
        "relative flex h-full cursor-pointer items-center p-4 gap-4 w-full bg-neutral-50 rounded-20",
        className,
      )}
      htmlFor={props.id}
    >
      <Checkbox.Root
        className="data-[state=checked]:border-primary-400 data-[state=checked]:bg-primary-400 data-[state=unchecked]:border-neutral-200 w-6 h-6 flex items-center justify-center rounded-30 transition-all duration-200 border-2 hover:border-neutral-400 bg-white outline-none cursor-pointer shrink-0"
        name={name}
        onCheckedChange={(isChecked: boolean) => {
          onChange && onChange(String(props.value), isChecked);
        }}
        {...props}
      >
        <Checkbox.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] bg-primary-400">
          <Check className="fill-white" size={24} />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <span className="font-medium text-neutral-600">{label}</span>
    </label>
  );
};

export interface CpsCheckboxGroupProps {
  children: ReactElement<CheckboxV2Props>[];
  name: string;
  direction?: "row" | "col";
  onChange?: (value: string[]) => void;
}

export const CpsCheckboxGroup = forwardRef<HTMLInputElement, CpsCheckboxGroupProps>(
  (
    { children, name, direction = "col", onChange },
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const [checkedValues, setCheckedValues] = useState<string[]>([]);

    useEffect(() => {
      if (
        !children.length &&
        !children.some((child) => child.props.defaultChecked || child.props.checked)
      )
        return;

      const initialCheckedValues = children
        .filter((child) => child.props.defaultChecked || child.props.checked)
        .map((child) => String(child.props.value));

      onChange && onChange(initialCheckedValues);
      setCheckedValues(initialCheckedValues);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- Carregamento inicial do componente para atualizar os valores já checados
    }, []);

    const handleChange = (value: string, isChecked: boolean): void => {
      let updatedValues: string[];
      if (isChecked) {
        updatedValues = [...checkedValues, value];
      } else {
        updatedValues = checkedValues.filter((val) => val !== value);
      }

      setCheckedValues(updatedValues);
      onChange && onChange(updatedValues);
    };

    const groupDir = direction === "row" ? "flex-row" : "flex-col";

    return (
      <CheckboxGroupContext.Provider value={{ name, onChange: handleChange }}>
        <div
          ref={ref}
          data-testid={`test-id-${name}`}
          className={`flex ${groupDir} gap-2`}
        >
          {children}
        </div>
      </CheckboxGroupContext.Provider>
    );
  },
);

interface CheckboxGroupContextProps {
  name?: string;
  children?: ReactNode;
  onChange?: (value: string, isChecked: boolean) => void;
}

const CheckboxGroupContext = createContext<CheckboxGroupContextProps>({});

const useCheckboxGroupContext = (): CheckboxGroupContextProps =>
  useContext(CheckboxGroupContext);
