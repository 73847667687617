import { DrawerContent, DrawerRoot } from "@/components/drawer";

export interface AppointmentDrawerRootProps {
  children: React.ReactNode;
  open: boolean;
  handleCloseCalendarAppointmentDetails: () => void;
}

export const AppointmentDrawerRoot = ({
  children,
  open,
  handleCloseCalendarAppointmentDetails,
}: AppointmentDrawerRootProps): JSX.Element => {
  const handleDrawerOnChange = (value: boolean): void => {
    if (!value) {
      handleCloseCalendarAppointmentDetails();
    }
  };

  return (
    <DrawerRoot setOpen={handleDrawerOnChange} open={open}>
      <DrawerContent>
        <div id="detailed-dialog" tabIndex={-1}>
          <div className="relative w-full">{children}</div>
        </div>
      </DrawerContent>
    </DrawerRoot>
  );
};
