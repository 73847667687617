import { forwardRef } from "react";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

type UnavailableSlotsReasonsSelectInputProps = Omit<SelectInputProps<string>, "items">;

export const UnavailableSlotsReasonsSelectInput = forwardRef<
  HTMLInputElement,
  UnavailableSlotsReasonsSelectInputProps
>(({ ...props }, ref: React.Ref<HTMLInputElement>): JSX.Element => {
  const unavailableSlotsReasons: SelectDrawerItemProps<string>[] = [
    { value: "Sem motivo", label: "Sem motivo" },
    { value: "Congresso", label: "Congresso" },
    { value: "Cirurgia", label: "Cirurgia" },
    { value: "Viagem", label: "Viagem" },
    { value: "Férias", label: "Férias" },
    { value: "Outro", label: "Outro" },
  ];

  return <SelectInput<string> ref={ref} {...props} items={unavailableSlotsReasons} />;
});

UnavailableSlotsReasonsSelectInput.displayName = "UnavailableSlotsReasonsSelectInput";
