import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CpsAlert, CpsInput } from "corpus";
import { Trade } from "@repo/icons";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { formatStringToNumber } from "@repo/lib";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormSubmitButton,
  FormControl,
  FormItem,
} from "@/components/form";
import {
  type BanksListToSelectQueryFragment,
  BanksSelectInput,
} from "@/components/banks-select-input";
import { BankAccountTypesSelectInput } from "@/components/bank-account-types-select-input";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { formatRecipientDocument } from "@/lib/string";

export const BankAccountFormQueryFragment = graphql(/* GraphQL */ `
  fragment BankAccountFormQueryFragment on query_root {
    ExpensumGetRecipient(arg1: { codUsuario: $codUsuario, codClinica: $codClinica }) {
      recipient {
        name
        type
        document
        bankAccounts {
          accountNumber
          accountCheckDigit
          name
        }
      }
    }
    banks: GetAllBanks {
      ...BanksListToSelectQueryFragment
      code
      name
    }
  }
`);

export const BankAccountUpdateMutation = graphql(/* GraphQL */ `
  mutation BankAccountUpdateMutation(
    $object: expensum_event_bank_account_update_insert_input!
  ) {
    insert_expensum_event_bank_account_update_one(object: $object) {
      id
    }
  }
`);

enum BankAccountFormAction {
  BankAccountUpdateConfirmation = "bank-account-update-confirmation",
  Error = "error",
}

interface BankAccountFormProps {
  data: FragmentType<typeof BankAccountFormQueryFragment>;
}

export const BankAccountForm = ({ data }: BankAccountFormProps): JSX.Element => {
  const router = useRouter();
  const { action } = useSearch({ from: "/settings/payments/bank-account/update" });

  const { user } = useRouteContext({ strict: false });

  const bankAccountFormData = useFragment(BankAccountFormQueryFragment, data);

  const recipient = bankAccountFormData.ExpensumGetRecipient?.recipient;
  const currentBankAccount = recipient?.bankAccounts?.at(0);

  const {
    mutateAsync: bankAccountUpdateMutateAsync,
    isPending: bankAccountUpdateIsPending,
  } = useGraphQLMutation(BankAccountUpdateMutation);

  const showBankAccountUpdateConfirmationDrawer =
    action === BankAccountFormAction.BankAccountUpdateConfirmation.valueOf();
  const setShowBankAccountUpdateConfirmationDrawer = (show: boolean): void => {
    if (show) {
      void router.navigate({
        to: "/settings/payments/bank-account/update",
        search: { action: BankAccountFormAction.BankAccountUpdateConfirmation },
      });
    } else if (showBankAccountUpdateConfirmationDrawer) {
      router.history.back();
    }
  };

  const showUnexpectedErrorDrawer = action === BankAccountFormAction.Error.valueOf();
  const setShowUnexpectedErrorDrawer = (show: boolean): void => {
    if (show) {
      void router.navigate({
        to: "/settings/payments/bank-account/update",
        search: { action: BankAccountFormAction.Error },
      });
    } else if (showUnexpectedErrorDrawer) {
      router.history.back();
    }
  };

  const formSchema = z.object({
    bank: z
      .string({
        required_error: "Campo obrigatório",
      })
      .min(1, "Campo obrigatório"),
    bankAccountType: z
      .string({
        required_error: "Campo obrigatório",
      })
      .min(1, "Campo obrigatório"),
    branchNumber: z
      .string({
        required_error: "Campo obrigatório",
      })
      .length(4, "A agência deve ter 4 caracteres")
      .refine((val) => val !== "0000", {
        message: "Número da agência inválido",
      }),
    branchCheckDigit: z
      .string()
      .max(1, "O dígito da agência deve ter, no máximo, 1 caractere")
      .optional(),
    accountNumber: z
      .string({
        required_error: "Campo obrigatório",
      })
      .min(1, "Campo obrigatório")
      .max(13, "A conta deve ter, no máximo, 13 caracteres"),
    accountCheckDigit: z
      .string({
        required_error: "Campo obrigatório",
      })
      .min(1, "Campo obrigatório")
      .max(2, "O dígito da conta deve ter, no máximo, 2 caracteres"),
  });

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
  });

  const handleSubmit = (): void => setShowBankAccountUpdateConfirmationDrawer(true);

  const onConfirmBankAccountUpdate = async (): Promise<void> => {
    const {
      bank,
      branchNumber,
      branchCheckDigit,
      accountNumber,
      accountCheckDigit,
      bankAccountType: type,
    } = form.getValues();

    const variables = {
      object: {
        codUsuario: user.codUsuario,
        codClinica: user.codClinica,
        holderName: recipient?.name,
        holderType: recipient?.type,
        holderDocument: recipient?.document,
        bank,
        branchNumber,
        branchCheckDigit,
        accountNumber,
        accountCheckDigit,
        type,
        name: bankAccountFormData.banks?.find((x) => x?.code === Number(bank))?.name,
      },
    };

    await bankAccountUpdateMutateAsync(variables, {
      onSuccess: onBankAccountUpdateSuccess,
      onError: onBankAccountUpdateError,
    });
  };

  const onBankAccountUpdateSuccess = (): void => router.history.go(-2);

  const onBankAccountUpdateError = (): void => setShowUnexpectedErrorDrawer(true);

  const recipientType = recipient?.type === "company" ? "CNPJ" : "CPF";

  const recipientDocument = formatRecipientDocument(
    String(recipient?.type),
    String(recipient?.document),
  );

  const bankAccountUpdateAlertText = `A nova conta de recebimento precisa ser do mesmo titular do ${recipientType} ${recipientDocument}. Para alterar a titularidade de PJ para PF ou vice-versa, entre em contato conosco.`;

  return (
    <>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={handleSubmit}>
          <CpsAlert title={bankAccountUpdateAlertText} description="" type="info" />
          <FormField
            control={form.control}
            name="bank"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BanksSelectInput
                    data={
                      bankAccountFormData.banks as FragmentType<
                        typeof BanksListToSelectQueryFragment
                      >[]
                    }
                    title="Instituição"
                    required
                    searchable
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="bankAccountType"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BankAccountTypesSelectInput
                    title="Tipo de conta"
                    required
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="branchNumber"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    title="Agência"
                    required
                    type="text"
                    inputMode="numeric"
                    maxLength={4}
                    {...field}
                    value={formatStringToNumber(field.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="branchCheckDigit"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    title="Dígito da Agência"
                    type="text"
                    maxLength={1}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="accountNumber"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    title="Conta"
                    required
                    type="text"
                    inputMode="numeric"
                    maxLength={13}
                    {...field}
                    value={formatStringToNumber(field.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="accountCheckDigit"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    title="Dígito da Conta"
                    required
                    type="text"
                    maxLength={2}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormSubmitButton>Trocar conta</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>

      <UnexpectedErrorDrawer
        open={showUnexpectedErrorDrawer}
        setOpen={setShowUnexpectedErrorDrawer}
      />
      <MessageDrawerRoot
        icon={Trade}
        variant="secondary"
        open={showBankAccountUpdateConfirmationDrawer}
        setOpen={setShowBankAccountUpdateConfirmationDrawer}
      >
        <MessageDrawerTitle>Alteração de conta</MessageDrawerTitle>
        <MessageDrawerBody>
          {`Esta conta substituirá a conta ${currentBankAccount?.accountNumber}-${currentBankAccount?.accountCheckDigit} da instituição ${currentBankAccount?.name}`}
          <br />
          <br />
          Lembre-se que seu pedido de troca de conta poderá levar até 36 horas para ser
          concluído e, durante este período, você receberá em sua conta atual.
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            onClick={() => void onConfirmBankAccountUpdate()}
            loading={bankAccountUpdateIsPending}
            data-testid="confirm-bank-account-update"
          >
            Confirmar
          </MessageDrawerActionButton>
          <MessageDrawerActionButton
            secondary
            onClick={() => setShowBankAccountUpdateConfirmationDrawer(false)}
          >
            Cancelar
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
    </>
  );
};
