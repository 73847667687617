import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { removeSecondsFromTimeString } from "@repo/lib";
import { Edit } from "@repo/icons";
import { CardHeader, CardIcon, CardTitle } from "@/components/card";

export const ScheduleListItemCardHeadFragment = graphql(/* GraphQL */ `
  fragment ScheduleListItemCardHeadFragment on usuariosAgendas {
    inicio
    fim
  }
`);

export const ScheduleListItemCardHead = (props: {
  schedule: FragmentType<typeof ScheduleListItemCardHeadFragment>;
}): JSX.Element => {
  const { inicio, fim } = useFragment(ScheduleListItemCardHeadFragment, props.schedule);

  const buildTitle = (): string => {
    const formattedInicio = removeSecondsFromTimeString(inicio);
    const formattedFim = removeSecondsFromTimeString(fim);

    return `Agenda ${formattedInicio} às ${formattedFim}`;
  };

  return (
    <CardHeader>
      <CardTitle>{buildTitle()}</CardTitle>
      <CardIcon className="fill-neutral-500" size={24} icon={Edit} />
    </CardHeader>
  );
};
