import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { ScheduleList } from "@/components/schedule-list";
import { PendingComponent } from "@/components/pending-component";
import { FullPageSpinner } from "@/components/full-page-spinner";

const SchedulesPageQuery = graphql(/* GraphQL */ `
  query SchedulesPageQuery {
    ...SchedulesListQueryFragment
  }
`);

export const SchedulesPage = (): JSX.Element => {
  const queryResult = useGraphQL(SchedulesPageQuery);
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Agendas" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <ScheduleList data={data} /> : <FullPageSpinner />}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/schedule-options/schedules/")({
  component: SchedulesPage,
  pendingComponent: () => <PendingComponent title="Agendas" />,
});
