import { graphql } from "@repo/graphql-types";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { Edit1, OneLivLogo, Share } from "@repo/icons";
import { Link, useRouteContext } from "@tanstack/react-router";
import { CpsBadge, CpsToast } from "corpus";
import { useState } from "react";
import { Share as CapacitorShare } from "@capacitor/share";
import { CardContent, CardRoot } from "@/components/card";
import { trackEvent } from "@/lib/tracking";

export const OneLivProfileCardFragment = graphql(/* GraphQL */ `
  fragment OneLivProfileCardFragment on query_root {
    locus_membro {
      slug
      ativo
    }
    locus_conselho_profissional(where: { ativo: { _eq: true } }) {
      sigla
    }
  }
`);

interface OneLivProfileCardProps {
  data: FragmentType<typeof OneLivProfileCardFragment>;
}

export const OneLivProfileCard = ({ data }: OneLivProfileCardProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivProfileCardFragment, data);

  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const isProfileCreated = fragmentResult.locus_membro.length > 0;

  const { user } = useRouteContext({ strict: false });

  const member = fragmentResult.locus_membro[0];

  const shareLink = async (): Promise<void> => {
    trackEvent("Oneliv Perfil Compartilhado", {
      codUsuario: user.codUsuario,
    });

    const url = `https://oneliv.com.br/profissional/${member.slug}?utm_source=app-shared-link`;

    const canShare = await CapacitorShare.canShare();
    if (!canShare.value) {
      await navigator.clipboard.writeText(url);
      setOpenCopiedLinkToast(true);
    }

    await CapacitorShare.share({
      title: "Conheça meu perfil no OneLiv!",
      text: "Olá! Gostaria de compartilhar com você o link do meu perfil no OneLiv. Através desse link, você poderá agendar consultas comigo de maneira rápida e fácil!",
      url,
    });
  };

  const shouldDisplayOneLivCard = (): boolean => {
    const allowedProfessionalCouncils = fragmentResult.locus_conselho_profissional;

    return allowedProfessionalCouncils
      .map((item) => item.sigla)
      .includes(user.codConselhoClasse ?? "");
  };

  return (
    <>
      {shouldDisplayOneLivCard() ? (
        <>
          <CardRoot>
            <CardContent className="gap-3">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2 items-center">
                  <OneLivLogo size={24} />
                  <p className="text-neutral-600 font-medium text-xs">Perfil OneLiv</p>
                  {isProfileCreated ? (
                    <CpsBadge>{member.ativo ? "Ativo" : "Inativo"}</CpsBadge>
                  ) : null}
                </div>
                <Link
                  className="flex flex-row items-center text-secondary-400 font-medium text-xs"
                  to="/oneliv/welcome"
                >
                  <Edit1 className="font-medium text-x fill-secondary-400" />
                  {isProfileCreated ? "Alterar" : "Criar perfil"}
                </Link>
              </div>
              {isProfileCreated ? (
                <div className="flex flex-row justify-between items-center bg-neutral-50 w-full py-4 px-3 rounded-lg">
                  <p className="text-xs font-medium text-neutral-500">
                    Compartilhar meu perfil
                  </p>
                  <button
                    data-testid="share-profile-button"
                    type="button"
                    onClick={() => {
                      void shareLink();
                    }}
                  >
                    <Share className="fill-secondary-400" size={24} />
                  </button>
                </div>
              ) : null}
            </CardContent>
          </CardRoot>

          <CpsToast
            title="Link copiado para a área de transferência"
            show={openCopiedLinkToast}
            onClose={() => setOpenCopiedLinkToast(false)}
            type="success"
            duration={10}
          />
        </>
      ) : null}
    </>
  );
};
