import { ExclamationCircle } from "@repo/icons";
import {
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { useErrorDrawer } from "@/hooks/use-error-drawer";

interface ErrorDrawerProps {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  message?: string;
}
export const ErrorDrawer = ({
  open,
  setOpen,
  message,
}: ErrorDrawerProps): JSX.Element => {
  const { isDrawerVisible, errorMessage, closeErrorDrawer } = useErrorDrawer();

  const onOpenChange = (value: boolean): void => {
    if (!value && isDrawerVisible) {
      closeErrorDrawer();
    }
  };

  return (
    <MessageDrawerRoot
      open={open ?? isDrawerVisible}
      setOpen={setOpen ?? onOpenChange}
      icon={ExclamationCircle}
      variant="primary"
    >
      <MessageDrawerTitle>Algo deu errado</MessageDrawerTitle>
      <MessageDrawerBody>{message ?? errorMessage}</MessageDrawerBody>
    </MessageDrawerRoot>
  );
};
