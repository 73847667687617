import { createFileRoute, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import {
  CheckinFiltersEnum,
  WaitingRoomStatusFiltersEnum,
  formatWithZonedDate,
} from "@repo/lib";
import { useEffect, useState } from "react";
import { type VariablesOf } from "@graphql-typed-document-node/core";
import { useGraphQL } from "@/hooks/use-graphql";
import { BottomBar } from "@/components/bottom-bar";
import { WaitingRoomAppointmentList } from "@/components/waiting-room-appointment-list";
import { HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { WaitingRoomFilterDrawer } from "@/components/waiting-room-filter-drawer";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { WaitingRoomFilterButton } from "@/components/waiting-room-filter-button.tsx";

type WhereType = VariablesOf<typeof WaitingRoomPageQuery>["whereBuild"];

interface WhereCondition {
  today: string;
  tags: number | undefined;
  checkin: number | undefined;
}

function buildWhereCondition(condition: WhereCondition): WhereType {
  const whereBase: WhereType = {
    data: { _eq: condition.today },
    cancelado: { _eq: false },
    codFinalidadeAgendamento: { _nin: [2, 4] },
  };

  if (
    condition.tags === WaitingRoomStatusFiltersEnum.AppointmentUntreated &&
    condition.checkin === CheckinFiltersEnum.Pending
  ) {
    whereBase._not = { tags: {}, _or: [{ _not: { AgendamentosCheckin: {} } }] };
  } else if (condition.tags === WaitingRoomStatusFiltersEnum.AppointmentUntreated) {
    whereBase._not = { tags: {} };
  } else if (condition.checkin === CheckinFiltersEnum.Pending) {
    whereBase._not = { AgendamentosCheckin: {} };
  }

  if (condition.tags === WaitingRoomStatusFiltersEnum.AppointmentTreated) {
    whereBase.tags = { codAgendamentoTipoTag: { _eq: 1 } };
  }

  if (condition.checkin === CheckinFiltersEnum.Done) {
    whereBase.AgendamentosCheckin = { dataCheckin: {} };
  }

  return whereBase;
}

const WaitingRoomPageQuery = graphql(/* GraphQL */ `
  query WaitingRoomPageQuery($whereBuild: agendamentos_bool_exp!) {
    ...WaitingRoomAppointmentListQueryFragment
    ...WaitingRoomFilterButtonFragment
  }
`);

const waitingRoomPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
  appointmentId: z.number().optional().catch(0),
  checkin: z.number().optional().catch(CheckinFiltersEnum.None),
  status: z.number().optional().catch(WaitingRoomStatusFiltersEnum.None),
});

const INITIAL_REFETCH_INTERVAL = 2_000;
const DEFAULT_REFETCH_INTERVAL = 30_000;

export const WaitingRoomPage = (): JSX.Element => {
  const searchParams = useSearch({ from: "/waiting-room/" });
  const today = formatWithZonedDate(new Date());
  // initial value to sync after two seconds and bring patient payment if value is updated
  const [refetchInterval, setRefetchInterval] = useState(INITIAL_REFETCH_INTERVAL);

  const queryResult = useGraphQL(
    WaitingRoomPageQuery,
    {
      whereBuild: buildWhereCondition({
        today,
        tags: searchParams.status,
        checkin: searchParams.checkin,
      }),
    },
    { refetchInterval },
  );

  useEffect(() => {
    const timeout = setTimeout(() => void queryResult.refetch(), 2000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  const { data, isRefetching } = queryResult;

  if (isRefetching && refetchInterval === INITIAL_REFETCH_INTERVAL) {
    setRefetchInterval(DEFAULT_REFETCH_INTERVAL);
  }

  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Sala de espera" />
        {data ? <WaitingRoomFilterButton data={data} align="end" /> : <div />}
      </HeaderRoot>
      <Page className="pb-[120px]">
        <AsyncDataWrapper {...queryResult}>
          {data ? <WaitingRoomAppointmentList data={data} /> : null}
          <WaitingRoomFilterDrawer />
        </AsyncDataWrapper>
      </Page>
      <BottomBar />
    </>
  );
};

export const Route = createFileRoute("/waiting-room/")({
  validateSearch: waitingRoomPageSearchSchema,
  component: WaitingRoomPage,
  beforeLoad: ({ context }) => {
    return {
      showTreatedAppointmentInWaitingRoom:
        context.ldClient.getShowTreatedAppointmentInWaitingRoom(),
      showMembersRecommendationForPatientsButtons:
        context.ldClient.getShowMembersRecommendationForPatientsButtons(),
    };
  },
  loader: ({ context }) => {
    const showTreatedAppointmentInWaitingRoom =
      context.showTreatedAppointmentInWaitingRoom;
    const showMembersRecommendationForPatientsButtons =
      context.showMembersRecommendationForPatientsButtons;

    return {
      showTreatedAppointmentInWaitingRoom,
      showMembersRecommendationForPatientsButtons,
    };
  },
});
