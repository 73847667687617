import { FullPageSpinner } from "@/components/full-page-spinner";
import { HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";

interface PendingComponentProps {
  title: string;
}

export const PendingComponent = ({ title }: PendingComponentProps): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderTitle title={title} align="center" />
      </HeaderRoot>
      <Page>
        <FullPageSpinner />
      </Page>
    </>
  );
};
