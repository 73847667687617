import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import type { NewBillingQueryQuery } from "@repo/graphql-types/graphql";
import { DoctorWithStethoAndClipboard } from "@repo/icons";
import type { NewBillingListQueryFragment } from "@/components/new-billing-list";
import { NewBillingList } from "@/components/new-billing-list";
import {
  EmptyStateRoot,
  EmptyStateImage,
  EmptyStateTitle,
} from "@/components/empty-state";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";

const NewBillingQuery = graphql(/* GraphQL */ `
  query NewBillingQuery {
    cobrancasAbertas {
      NewBillings : nodes {
        ...NewBillingListQueryFragment
      }
    }
    cobrancasFechadas {
      NewBillings : nodes {
        ...NewBillingListQueryFragment
      }
    }
  }
`);

export const NewBilling = (): JSX.Element => {
  const queryResult = useGraphQL(NewBillingQuery);
  const { data } = queryResult;

  const newBillingListQuery: NewBillingQueryQuery = data ?? {};
  const newOpenBillings: FragmentType<typeof NewBillingListQueryFragment>[] =
    (newBillingListQuery.cobrancasAbertas?.NewBillings ?? []) as FragmentType<
      typeof NewBillingListQueryFragment
    >[];
  const newClosedBillings: FragmentType<typeof NewBillingListQueryFragment>[] =
    (newBillingListQuery.cobrancasFechadas?.NewBillings ?? []) as FragmentType<
      typeof NewBillingListQueryFragment
    >[];
  const newBillingList: FragmentType<typeof NewBillingListQueryFragment>[] =
    newOpenBillings.concat(newClosedBillings);

  return (
    <AsyncDataWrapper {...queryResult}>
      {newBillingList.length > 0 ? (
        <NewBillingList billingList={{ ...newBillingList }} />
      ) : (
        <EmptyStateRoot>
          <EmptyStateImage
            icon={DoctorWithStethoAndClipboard}
            alt="Imagem lista de cobranças sem movimentações"
          />
          <EmptyStateTitle>Nenhuma movimentação neste mês</EmptyStateTitle>
        </EmptyStateRoot>
      )}
    </AsyncDataWrapper>
  );
};
