import { useRouteContext } from "@tanstack/react-router";
import { useState, useEffect } from "react";

interface UnidadePrecosFlexiveis {
  UnidadesPrecosFlexiveisParceirosIntegracoes: { codParceiroIntegracao: number }[];
}

export function useFlexiblePriceAvailability(
  locationId?: number,
  codParceiroIntegracao?: number,
  unidadesPrecosFlexiveis?: UnidadePrecosFlexiveis[],
): boolean {
  const [isFlexiblePriceAvailable, setIsFlexiblePriceAvailable] =
    useState<boolean>(false);
  const { ldClient } = useRouteContext({ strict: false });

  useEffect(() => {
    const getFFAsync = async (): Promise<void> => {
      const featureFlagValue = await ldClient.getFlexiblePriceAvailability(
        locationId ?? 0,
      );
      setIsFlexiblePriceAvailable(
        featureFlagValue &&
          roomTypeHasCancellationFee(codParceiroIntegracao, unidadesPrecosFlexiveis),
      );
    };

    void getFFAsync();
  }, [codParceiroIntegracao, ldClient, locationId, unidadesPrecosFlexiveis]);

  return isFlexiblePriceAvailable;
}

const roomTypeHasCancellationFee = (
  codParceiroIntegracao?: number,
  unidadesPrecosFlexiveis?: UnidadePrecosFlexiveis[],
): boolean => {
  if (unidadesPrecosFlexiveis && unidadesPrecosFlexiveis.length > 0) {
    if (codParceiroIntegracao) {
      return unidadesPrecosFlexiveis.some((unidadePrecoFlexivel) =>
        unidadePrecoFlexivel.UnidadesPrecosFlexiveisParceirosIntegracoes.some(
          (item) => item.codParceiroIntegracao === codParceiroIntegracao,
        ),
      );
    }

    return unidadesPrecosFlexiveis.some(
      (unidadePrecoFlexivel) =>
        unidadePrecoFlexivel.UnidadesPrecosFlexiveisParceirosIntegracoes.length === 0,
    );
  }

  return false;
};
