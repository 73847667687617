import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import {
  MultiSelectInput,
  type MultiSelectInputProps,
} from "@/components/multi-select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";

export const UnitsListToMultiSelectQueryFragment = graphql(/* GraphQL */ `
  fragment UnitsListToMultiSelectQueryFragment on unidades {
    codUnidade
    nome
  }
`);

interface UnitsMultiSelectInputProps
  extends Omit<MultiSelectInputProps<number>, "items"> {
  data: FragmentType<typeof UnitsListToMultiSelectQueryFragment>[];
}

export const UnitsMultiSelectInput = forwardRef<
  HTMLInputElement,
  UnitsMultiSelectInputProps
>(({ data, ...props }, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
  const units = useFragment(UnitsListToMultiSelectQueryFragment, data);

  const items: SelectDrawerItemProps<number>[] = units.map((unit) => {
    return {
      label: unit.nome,
      value: unit.codUnidade,
    };
  });

  const uniqueItems = items.filter(
    (item, index, self) => index === self.findIndex((t) => t.value === item.value),
  );

  return <MultiSelectInput<number> {...props} items={uniqueItems} ref={ref} />;
});

UnitsMultiSelectInput.displayName = "UnitsMultiSelectInput";
