import { useEffect } from "react";
import { iosOrAndroid } from "@repo/lib";
import { useRouteContext } from "@tanstack/react-router";
import { CustomerIO } from "@repo/customerio-plugin";

export const usePushNotificationPrompt = (): void => {
  const { user } = useRouteContext({ strict: false });

  useEffect(() => {
    if (user.codUsuario === 0) {
      return;
    }

    if (iosOrAndroid && user.codUsuario) {
      CustomerIO.requestPushNotificationsPermission();

      const { codUsuario: identifier, email = "" } = user;

      CustomerIO.identify({ identifier: identifier.toString(), email });
    }
  }, [user, user.codUsuario, user.email]);
};
