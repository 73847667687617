import { CalendarTime } from "@repo/icons";
import { useState } from "react";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
} from "@/components/message-drawer";
import { FeedbackDesiredTimeSuccessDrawer } from "@/components/feedback-desired-time-success-drawer.tsx";

interface ReportUnavailabilityDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  desiredTime: string;
  reportUnavailabilityTrackEvent: () => void;
}

export const ReportUnavailabilityDrawer = ({
  open,
  setOpen,
  desiredTime,
  reportUnavailabilityTrackEvent,
}: ReportUnavailabilityDrawerProps): JSX.Element => {
  const [openSuccessDrawer, setOpenSuccessDrawer] = useState(false);

  const sendFeedback = (): void => {
    reportUnavailabilityTrackEvent();
    setOpen(false);
    setOpenSuccessDrawer(true);
  };

  return (
    <>
      <MessageDrawerRoot
        open={open}
        setOpen={setOpen}
        icon={CalendarTime}
        variant="secondary"
      >
        <h2 className="text-xl text-center font-medium">
          Reportar indisponibilidade de horário
        </h2>
        <MessageDrawerBody>
          <p>
            Informo que não consegui criar meu agendamento devido à indisponibilidade de
            horário na unidade
          </p>
          {desiredTime ? (
            <div className="bg-neutral-50">
              <div className="flex flex-row justify-between p-4">
                <p className="text-neutral-500 text-sm">Horário desejado</p>
                <p className="text-neutral-600 font-medium text-sm">{desiredTime}</p>
              </div>
            </div>
          ) : null}
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            onClick={sendFeedback}
            data-testid="submit-button-report-unavailability"
          >
            Enviar feedback
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
      <FeedbackDesiredTimeSuccessDrawer
        open={openSuccessDrawer}
        setOpen={setOpenSuccessDrawer}
      />
    </>
  );
};
