import { createFileRoute, redirect, useRouter } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { Left, Trash } from "@repo/icons";
import { useState } from "react";
import { AppointmentTypeCategoryEnum } from "@repo/lib";
import { fetchQuery, useGraphQL, useGraphQLMutation } from "@/hooks/use-graphql";
import { AppointmentTypeForm } from "@/components/appointment-type-form";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { PendingComponent } from "@/components/pending-component";

const GetAppointmentTypeEditPageQuery = graphql(`
  query GetAppointmentTypeEditPageQuery($codUsuario: Int!, $codUsuarioCompromisso: Int!) {
    appointmentType: usuariosCompromissos_by_pk(
      codUsuarioCompromisso: $codUsuarioCompromisso
    ) {
      sistema
      ativo
      category: usuariosCompromissosCategoria {
        codUsuarioCompromissoCategoria
      }
    }
    ...GetAppointmentTypeFormQueryFragment
  }
`);

const DeleteAppointmentTypeMutation = graphql(`
  mutation DeleteAppointmentType($input: LivanceApiDeleteUsuarioCompromissoInput!) {
    LivanceApiDeleteUsuarioCompromisso(arg1: $input)
  }
`);

export const AppointmentTypePage = (): JSX.Element => {
  const { user, queryClient } = Route.useRouteContext();

  const router = useRouter();
  const params = Route.useParams();

  const [showDeleteConfirmationDrawer, setShowDeleteConfirmationDrawer] = useState(false);

  const [showUnexpectedErrorDrawer, setShowUnexpectedErrorDrawer] =
    useState<boolean>(false);

  const { mutate: deleteMutate } = useGraphQLMutation(DeleteAppointmentTypeMutation);

  const handleDeleteAppointmentType = (): void => {
    setShowDeleteConfirmationDrawer(!showDeleteConfirmationDrawer);
    deleteMutate(
      {
        input: {
          codUsuarioCompromisso: params.appointmentTypeId,
        },
      },
      {
        onSuccess: () => {
          void queryClient.resetQueries({
            queryKey: ["AppointmentTypesPageQuery"],
          });
          router.history.back();
        },
        onError: () => {
          setShowUnexpectedErrorDrawer(true);
        },
      },
    );
  };

  const queryResult = useGraphQL(GetAppointmentTypeEditPageQuery, {
    codUsuarioCompromisso: Number(params.appointmentTypeId),
    codUsuario: Number(user.codUsuario),
  });

  const { data } = queryResult;

  const appointmentTypeData = data?.appointmentType;

  const isCategoryNotManual =
    appointmentTypeData &&
    appointmentTypeData.category.codUsuarioCompromissoCategoria !==
      Number(AppointmentTypeCategoryEnum.Manual);

  const isAppointmentTypeInternal = Boolean(appointmentTypeData?.sistema);

  const isUndeletable = isCategoryNotManual ?? isAppointmentTypeInternal;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Editar atendimento" align="center" />
        {isUndeletable ? (
          <div />
        ) : (
          <HeaderButton
            icon={Trash}
            onClick={() => {
              setShowDeleteConfirmationDrawer(true);
            }}
            align="end"
          />
        )}
      </HeaderRoot>
      <MessageDrawerRoot
        icon={Trash}
        variant="secondary"
        open={showDeleteConfirmationDrawer}
        setOpen={setShowDeleteConfirmationDrawer}
      >
        <MessageDrawerTitle>Excluir atendimento</MessageDrawerTitle>
        <MessageDrawerBody>
          Tem certeza de que deseja excluir este atendimento?
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            onClick={() => {
              handleDeleteAppointmentType();
            }}
          >
            Excluir
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data && queryResult.isFetchedAfterMount ? (
            <AppointmentTypeForm data={data} />
          ) : (
            <FullPageSpinner />
          )}
        </AsyncDataWrapper>
      </Page>
      <UnexpectedErrorDrawer
        open={showUnexpectedErrorDrawer}
        setOpen={setShowUnexpectedErrorDrawer}
      />
    </>
  );
};

export const Route = createFileRoute(
  "/settings/schedule-options/appointment-types/$appointmentTypeId",
)({
  parseParams: (params) => ({
    appointmentTypeId: z.number().int().parse(Number(params.appointmentTypeId)),
  }),
  loader: async ({ context, params }) => {
    try {
      const variables = {
        codUsuario: context.user.codUsuario,
        codUsuarioCompromisso: params.appointmentTypeId,
      };

      const data = await fetchQuery(context, GetAppointmentTypeEditPageQuery, variables);

      if (!data.appointmentType?.ativo) {
        throw new Error("Appointment type not found!");
      }
    } catch (e) {
      redirect({
        to: "/settings/schedule-options/appointment-types",
        throw: true,
      });
    }

    return {};
  },
  component: AppointmentTypePage,
  pendingComponent: () => <PendingComponent title="Editar atendimento" />,
});
