import { graphql } from "@repo/graphql-types/gql";
import { type ReactElement, useMemo } from "react";
import {
  type ParseRoute,
  type RouterState,
  useRouterState,
  useSearch,
  useRouter,
  useRouteContext,
} from "@tanstack/react-router";
import { add, differenceInMinutes, differenceInSeconds, max } from "date-fns";
import { ActiveAllocationDetailsQueryDocument } from "@repo/graphql-types/graphql";
import { AllocatedRoomBar } from "@/components/allocated-room-bar";
import { useGraphQL, useInvalidateQuery } from "@/hooks/use-graphql";
import { parseDateTime } from "@/lib/date";
import { type routeTree } from "@/route-tree.gen";
import { useVariableNow } from "@/hooks/use-now";
import { ReturnRoomDrawer } from "@/components/return-room-drawer";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { ReturnRoomActionSearchParams } from "@/lib/action-schemas/return-room";
import { CsatAllocatedRoomDrawer } from "@/components/csat-allocated-room-drawer";
import {
  AllocatedRoomBarV2,
  type AllocatedRoomBarV2TrackParams,
} from "@/components/allocated-room-bar-v2";

const ActiveAllocationQuery = graphql(/* GraphQL */ `
  query ActiveAllocationQuery {
    activeAllocation: AgendamentosAlocacoesAtivas {
      horaInicio
      appointment: Agendamento {
        data
        codAgendamento
        horaFim
        horaInicio
        ...ReturnRoomDrawerFragment
      }
      locationRoom: UnidadeSala {
        nome
      }
    }
  }
`);

const CsatCategoriesQuery = graphql(/* GraphQL */ `
  query CsatCategoriesQuery {
    ...GetAllCsatCategoriesQueryFragment
  }
`);

const isEnabledRoute = (routerState: RouterState): boolean => {
  const { location } = routerState;
  const visibleRoutes: ParseRoute<typeof routeTree>["fullPath"][] = [
    "/",
    "/waiting-room",
    "/patients",
    "/settings",
  ];

  return visibleRoutes.some(
    (x) => location.pathname === x || location.pathname === `${x}/`,
  );
};

const calculateLateTime = (
  allocationStartTime: Date,
  appointmentStartTime: Date,
  plannedDateAppointmentEnding: Date,
  now: Date,
): number => {
  // Se a horaInicio da alocação for depois da horaInicio do agendamento, ele adiciona
  // esse tempo no final da alocação, se não, ele não adiciona nada. Ou seja, ele pega a maior horaFim entre
  // horaFimAgendamento e horaFimAgendamento + (horaInicioAlocacao - horaInicioAgendamento)
  // Se a horaInicioAlocação for antes da horaInicioAgendamento,
  // esse horário é negativo e sobressai horaFimAgendamento

  const startingTimeOffset = differenceInSeconds(
    allocationStartTime,
    appointmentStartTime,
  );
  const endingTimeWithOffset = add(plannedDateAppointmentEnding, {
    seconds: startingTimeOffset,
  });

  const compensatedEndingTime = max([plannedDateAppointmentEnding, endingTimeWithOffset]);

  return differenceInMinutes(now, compensatedEndingTime);
};
const ONE_HOUR_STALE_TIME = 3600000;

const AllocatedRoomBarController = (): ReactElement | null => {
  const { data: allocation } = useGraphQL(ActiveAllocationQuery, undefined, {
    refetchInterval: 10_000,
  });
  const { data: csatCategories } = useGraphQL(CsatCategoriesQuery, undefined, {
    staleTime: ONE_HOUR_STALE_TIME,
  });

  const { flags } = useRouteContext({ strict: false });
  const shouldUseNewAllocatedRoomBar = useMemo(
    () => flags["nova-barra-de-alocacoes"],
    [flags],
  );

  const router = useRouter();
  const routerState = useRouterState();
  const searchParams: Record<string, unknown> = useSearch({ strict: false });
  const enabled = isEnabledRoute(routerState);
  const now = useVariableNow(enabled);

  const invalidateActiveAllocationDetailsQuery = useInvalidateQuery(
    ActiveAllocationDetailsQueryDocument,
  );

  const navigateToReturnRoom = (): void => {
    invalidateActiveAllocationDetailsQuery();

    void router.navigate({
      search: {
        ...searchParams,
        ...ReturnRoomActionSearchParams,
      },
    });
  };

  const navigateToReturnRoomWithParams = (
    params: AllocatedRoomBarV2TrackParams,
  ): void => {
    invalidateActiveAllocationDetailsQuery();

    void router.navigate({
      search: {
        ...params,
        ...searchParams,
        ...ReturnRoomActionSearchParams,
      },
    });
  };

  if (!enabled) {
    return null;
  }

  const activeAllocation = allocation?.activeAllocation[0];

  const isCsatOpen = searchParams.action === "csat";

  const closeModal = (): void => {
    if (isCsatOpen) {
      router.history.back();
    }
  };

  const onModalClose = (isOpening: boolean): void => {
    if (isOpening) {
      return;
    }

    closeModal();
  };

  if (!activeAllocation) {
    return (
      <CsatAllocatedRoomDrawer
        open={isCsatOpen}
        setOpen={onModalClose}
        data={csatCategories}
      />
    );
  }

  const { appointment } = activeAllocation;
  const plannedDateAppointmentEnding = parseDateTime(
    appointment.data,
    appointment.horaFim,
  );
  const allocationStartTime = parseDateTime(
    appointment.data,
    activeAllocation.horaInicio,
  );
  const appointmentStartTime = parseDateTime(appointment.data, appointment.horaInicio);

  const lateTime = calculateLateTime(
    allocationStartTime,
    appointmentStartTime,
    plannedDateAppointmentEnding,
    now,
  );
  const lateTimeString = lateTime > 0 ? `${lateTime} min` : undefined;

  const activeAllocationName =
    activeAllocation.locationRoom.nome?.split(" ").slice(1, 3).join(" ") ??
    "Sala Desconhecida";

  const elapsedTime = `${differenceInMinutes(now, allocationStartTime)} min`;

  const handleErrorDrawerDismiss = (value: boolean): void => {
    if (!value && searchParams.action === "error") {
      router.history.back();
    }
  };

  return (
    <>
      {shouldUseNewAllocatedRoomBar ? (
        <AllocatedRoomBarV2 onClickButtonReturnRoom={navigateToReturnRoomWithParams} />
      ) : (
        <AllocatedRoomBar
          roomName={activeAllocationName}
          time={elapsedTime}
          lateTime={lateTimeString}
          onClickButtonReturnRoom={navigateToReturnRoom}
        />
      )}
      <ReturnRoomDrawer data={appointment} />
      <UnexpectedErrorDrawer
        open={searchParams.action === "error"}
        setOpen={handleErrorDrawerDismiss}
      />
    </>
  );
};

export { AllocatedRoomBarController };
