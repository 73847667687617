import {
  CloseScheduleType,
  TimeSlotSuggestionType,
  type DayEvents,
  convertDateTimeIgnoringTimezone,
} from "@repo/lib";
import { parseISO } from "date-fns";

interface Unit {
  id: number;
  name: string;
  acronym: string;
}

interface CreateOldDayEventProps {
  appointmentId: string | number;
  title: string;
  appointmentType?: string;
  unit: Unit;
  start: string;
  end: string;
  date?: string;
}

export const createOldDayEvent = ({
  appointmentId,
  title,
  appointmentType,
  unit,
  start,
  end,
  date,
}: CreateOldDayEventProps): DayEvents => {
  const { start: startDateTime, end: endDateTime } = getStartEndDateTimes(
    start,
    end,
    date,
  );

  return {
    id: `old-${appointmentId}`,
    type: "old",
    title,
    appointmentType: appointmentType ?? "",
    unit,
    start: startDateTime,
    end: endDateTime,
  };
};

interface CreateAvailableDayEventProps {
  appointmentId: string | number;
  unit: Unit;
  timeSlotType: TimeSlotSuggestionType;
  start: string;
  end: string;
  date?: string;
}

export const createAvailableDayEventProps = ({
  appointmentId,
  timeSlotType,
  unit,
  start,
  end,
  date,
}: CreateAvailableDayEventProps): DayEvents => {
  const { start: startDateTime, end: endDateTime } = getStartEndDateTimes(
    start,
    end,
    date,
  );

  const durationOfAppointment = endDateTime.getTime() - startDateTime.getTime();
  const durationOfAppointmentInMinutes = Math.floor(durationOfAppointment / (1000 * 60));

  const isLimitedDuration =
    timeSlotType === TimeSlotSuggestionType.LimitedDurationAppointment;

  const title = isLimitedDuration
    ? `Limitado a ${durationOfAppointmentInMinutes} min`
    : "Disponível";

  const type = isLimitedDuration ? "availableLimited" : "available";

  return {
    id: `${type}-${appointmentId}`,
    type,
    title,
    appointmentType: "",
    timeSlotType,
    unit,
    start: startDateTime,
    end: endDateTime,
  };
};

interface CreateClosedDayEventProps {
  id: string | number;
  title: string;
  unit: Unit;
  start: string;
  end: string;
  date?: string;
  closeScheduleType?: CloseScheduleType;
}

export const createClosedDayEvent = ({
  id,
  title,
  unit,
  start,
  end,
  date,
  closeScheduleType,
}: CreateClosedDayEventProps): DayEvents => {
  const { start: startDateTime, end: endDateTime } = getStartEndDateTimes(
    start,
    end,
    date,
  );

  const appointmentType =
    closeScheduleType === CloseScheduleType.ClosedForMovement
      ? "Deslocamento"
      : "Fechamento";

  return {
    id: `unavailable-slot-${id}`,
    type: "closed",
    title,
    appointmentType,
    unit,
    start: startDateTime,
    end: endDateTime,
  };
};

interface CreateHatchedDayEventProps {
  id: string | number;
  start: Date;
  end: Date;
}

export const createHatchedDayEvent = ({
  id,
  start,
  end,
}: CreateHatchedDayEventProps): DayEvents => {
  return {
    id: `hatched-${id}`,
    type: "none",
    title: "",
    appointmentType: "",
    unit: { id: 0, name: "", acronym: "" },
    start,
    end,
  };
};

interface CreateGenericDayEventProps {
  id: string | number;
  type: DayEvents["type"];
  title: string;
  unit: Unit;
  start: Date;
  end: Date;
  appointmentType?: string;
  timeSlotType?: TimeSlotSuggestionType | null;
  appointmentPurposeId?: number | null;
}

export const createGenericDayEvent = ({
  id,
  type,
  title,
  unit,
  start,
  end,
  appointmentType,
  timeSlotType,
  appointmentPurposeId,
}: CreateGenericDayEventProps): DayEvents => {
  return {
    id: `${type}-${id}`,
    type,
    title,
    appointmentType: appointmentType ?? "",
    unit,
    start,
    end,
    timeSlotType,
    appointmentPurposeId,
  };
};

const getStartEndDateTimes = (
  start: string,
  end: string,
  date?: string,
): { start: Date; end: Date } => {
  let startDateTime: Date;
  let endDateTime: Date;

  if (!date) {
    startDateTime = parseISO(start);
    endDateTime = parseISO(end);
  } else {
    startDateTime = convertDateTimeIgnoringTimezone(date, start);
    endDateTime = convertDateTimeIgnoringTimezone(date, end);
  }

  return { start: startDateTime, end: endDateTime };
};
