import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

export const UnitsListToSelectQueryFragment = graphql(/* GraphQL */ `
  fragment UnitsListToSelectQueryFragment on unidades {
    codUnidade
    nome
  }
`);

interface UnitsSelectInputProps extends Omit<SelectInputProps<number>, "items"> {
  data: FragmentType<typeof UnitsListToSelectQueryFragment>[];
  allUnitsOption?: boolean;
}

export const UnitsSelectInput = forwardRef<HTMLInputElement, UnitsSelectInputProps>(
  (
    { data, allUnitsOption, ...props }: UnitsSelectInputProps,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const units = useFragment(UnitsListToSelectQueryFragment, data);

    const items: SelectDrawerItemProps<number>[] = units.map((unit) => {
      return {
        label: unit.nome,
        value: unit.codUnidade,
      };
    });

    if (allUnitsOption) {
      items.unshift({
        label: "Todas",
        value: 0,
      });
    }

    return <SelectInput<number> ref={ref} {...props} items={items} />;
  },
);

UnitsSelectInput.displayName = "UnitsSelectInput";
