import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { formatDateOfBirth } from "@/lib/string";
import { type Info } from "@/components/list/item";
import { InformationalList } from "@/components/list/informational-list";

export const PatientDetailPropertyListFragment = graphql(/* GraphQL */ `
  fragment PatientDetailPropertyListFragment on pacientes {
    cpf
    email
    dataNascimento
    telefone
  }
`);

export const PatientDetailPropertyList = (props: {
  patient: FragmentType<typeof PatientDetailPropertyListFragment>;
}): JSX.Element => {
  const patient = useFragment(PatientDetailPropertyListFragment, props.patient);

  const infos: Info[] = [
    {
      label: "Data de nascimento",
      value: patient.dataNascimento ? formatDateOfBirth(patient.dataNascimento) : "-",
    },
    {
      label: "Telefone",
      value: patient.telefone,
    },
    {
      label: "Email",
      value: patient.email ?? "-",
    },
    {
      label: "CPF",
      value: patient.cpf ?? "-",
    },
  ];

  return (
    <div className="mt-6">
      <InformationalList title="Informações" infos={infos} />
    </div>
  );
};
