import { graphql } from "@repo/graphql-types";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { Check } from "@repo/icons";
import { CardContent, CardRoot } from "@/components/card";
import { getPlanValueWithAllowance } from "@/lib/allowance";
import { getExhibitionName, getMonthlyPlanRetentionText } from "@/lib/plans";

const psychoanalysisSpecialtyId = 57;
const psychologySpecialtyId = 58;
const psychopedagogySpecialtyId = 70;

export const CurrentPlanCardFragment = graphql(/* GraphQL */ `
  fragment CurrentPlanCardFragment on query_root {
    tiposMovimentacoes(
      where: { ativo: { _eq: true }, identificacao: { _eq: "Mensalidade" } }
    ) {
      valorUnitario
    }
    usuariosProfissionaisPlanos(
      where: {
        ativo: { _eq: true }
        plano: {
          tipoMovimentacao: { usuariosMovimentacoesRecorrentes: { ativo: { _eq: true } } }
        }
      }
    ) {
      plano {
        tipoMovimentacao {
          valorUnitario
          usuariosMovimentacoesRecorrentes {
            quantidade
            ativo
            dataInicio
          }
        }
      }
    }
    usuarioPlano: usuariosProfissionaisPlanos(where: { ativo: { _eq: true } }) {
      plano {
        nome
        valor
        temFidelidade
      }
    }
    usuariosEspecialidades {
      codEspecialidade
    }
  }
`);

interface CurrentPlanCardProps {
  data: FragmentType<typeof CurrentPlanCardFragment>;
  memberRegistrationDate: string;
}

export const CurrentPlanCard = ({
  data,
  memberRegistrationDate,
}: CurrentPlanCardProps): JSX.Element => {
  const fragmentResult = useFragment(CurrentPlanCardFragment, data);
  const {
    usuariosProfissionaisPlanos,
    usuariosEspecialidades,
    usuarioPlano,
    tiposMovimentacoes,
  } = fragmentResult;

  const basePrice = tiposMovimentacoes[0].valorUnitario;

  const planoMovimentacoes =
    Array.isArray(usuariosProfissionaisPlanos) && usuariosProfissionaisPlanos.length > 0
      ? usuariosProfissionaisPlanos[0].plano
      : null;

  const plano =
    Array.isArray(usuarioPlano) && usuarioPlano.length > 0 ? usuarioPlano[0].plano : null;

  const planName = plano?.nome ?? "";

  const movimentacaoRecorrente = planoMovimentacoes?.tipoMovimentacao ?? {
    valorUnitario: 0,
    usuariosMovimentacoesRecorrentes: [],
  };

  const planPrice = getPlanValueWithAllowance(movimentacaoRecorrente, basePrice);
  const isLightPlan = plano?.nome.includes("Light");
  const isPsico = usuariosEspecialidades.some((especialidade) =>
    [
      psychoanalysisSpecialtyId,
      psychologySpecialtyId,
      psychopedagogySpecialtyId,
    ].includes(especialidade.codEspecialidade),
  );

  return (
    <CardRoot>
      <CardContent className="flex flex-col w-full justify-center items-stretch bg-neutral-50">
        <div className="flex flex-row justify-between items-start">
          <div className="flex flex-col gap-1">
            <div className="text-neutral-600 font-medium text-md">
              {getExhibitionName(planName)}
            </div>
            <div className="text-neutral-600 font-semibold text-2xl">
              R$ {planPrice}
              <span className="text-neutral-500 font-normal text-xs">/mês</span>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center text-primary font-medium text-xs bg-white py-1 px-2 rounded-xl">
            {plano?.temFidelidade ? "Anual" : "Mensal"}
          </div>
        </div>
        <div className="flex flex-col gap-1 mt-2">
          <div className="flex flex-row text-neutral-500 font-normal text-xs gap-1">
            <Check size={16} className="fill-secondary-500 shrink-0" />
            {plano?.temFidelidade
              ? "Compromisso de 12 meses de utilização."
              : getMonthlyPlanRetentionText(memberRegistrationDate)}
          </div>
          {isLightPlan ? (
            <div className="flex flex-row text-neutral-500 font-normal text-xs gap-1">
              <Check size={16} className="fill-secondary-500 shrink-0" />
              {`Uso de sala de segunda a sexta: ${isPsico ? "07h-14h e 20h-22h" : "07h-10h, 12h-14h e 18h-22h"}. Sábados: horário integral.`}
            </div>
          ) : null}
        </div>
      </CardContent>
    </CardRoot>
  );
};
