import { graphql } from "@repo/graphql-types";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import {
  Edit1,
  Mastercard,
  Visa,
  Elo,
  Hipercard,
  Jcb,
  DinersClub,
  Amex,
  CreditCard,
} from "@repo/icons";
import { Link } from "@tanstack/react-router";
import { CardContent, CardRoot } from "@/components/card";
import { Button } from "@/components/button";

export const CardInfoQueryFragment = graphql(/* GraphQL */ `
  fragment CardInfoQueryFragment on query_root {
    usuariosCartoes(where: { ativo: { _eq: true } }) {
      bandeira
      numero
      validade
    }
  }
`);

interface CardInfoWithEditOptionProps {
  data: FragmentType<typeof CardInfoQueryFragment>;
}

export const CardInfoWithEditOption = ({
  data,
}: CardInfoWithEditOptionProps): JSX.Element => {
  const getCardBanner = (flag: string): JSX.Element | undefined => {
    const flags: Record<string, JSX.Element | undefined> = {
      mastercard: <Mastercard size={24} />,
      visa: <Visa size={24} />,
      elo: <Elo size={24} />,
      hipercard: <Hipercard size={24} />,
      jcb: <Jcb size={24} />,
      dinersclub: <DinersClub size={24} />,
      amex: <Amex size={24} />,
      default: <CreditCard size={24} />,
    };

    return flags[flag.toLowerCase()] ?? flags.default;
  };

  const fragmentResult = useFragment(CardInfoQueryFragment, data);
  const { usuariosCartoes } = fragmentResult;

  const card = usuariosCartoes.length > 0 ? usuariosCartoes[0] : null;

  return (
    <Link to="/settings/profile/card">
      {card ? (
        <CardRoot>
          <CardContent className="flex flex-row justify-between items-center">
            <div className="flex flex-row justify-between items-center">
              {getCardBanner(String(card.bandeira))}
              <div className="flex flex-col ml-2">
                <div className="text-neutral-600 font-medium text-xs">
                  {String(card.bandeira).toUpperCase()} {String(card.numero).slice(-4)}
                </div>
                <div className="text-neutral-500 font-normal text-xs">
                  Vencimento: {card.validade}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center text-secondary-400 font-medium text-xs">
              <Edit1 className="fill-secondary-400" />
              Alterar Cartão
            </div>
          </CardContent>
        </CardRoot>
      ) : (
        <Button fullWidth>Cadastrar cartão</Button>
      )}
    </Link>
  );
};
