import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { Wallet } from "@repo/icons";
import { checkIfAppointmentHasOccurred } from "@repo/lib";
import { Link, useRouteContext } from "@tanstack/react-router";
import { CpsButtonText } from "corpus";

export const AppointmentDrawerOnlinePaymentButtonFragment = graphql(/* GraphQL */ `
  fragment AppointmentDrawerOnlinePaymentButtonFragment on agendamentos {
    codAgendamento
    data
    horaInicio
  }
`);

interface AppointmentDrawerOnlinePaymentButtonProps {
  fragmentData: FragmentType<typeof AppointmentDrawerOnlinePaymentButtonFragment>;
}

export const AppointmentDrawerOnlinePaymentButton = ({
  fragmentData,
}: AppointmentDrawerOnlinePaymentButtonProps): JSX.Element => {
  const fragmentResult = useFragment(
    AppointmentDrawerOnlinePaymentButtonFragment,
    fragmentData,
  );

  const { flags } = useRouteContext({ strict: false });

  const shouldDisplayOnlinePaymentButton =
    flags["exibe-entrada-no-fluxo-de-criacao-de-pagamento-online-no-pre-consulta"];

  const { codAgendamento, data, horaInicio } = fragmentResult;

  const isAppointmentAlreadyOccurred = checkIfAppointmentHasOccurred(data, horaInicio);

  const buttonLabel = isAppointmentAlreadyOccurred
    ? "Criar pagamento"
    : "Criar pagamento antecipado";

  return (
    <>
      {shouldDisplayOnlinePaymentButton ? (
        <div>
          <Link
            to="/settings/payments/create"
            search={{ appointmentId: codAgendamento, origin: "Drawer de agendamento" }}
          >
            <CpsButtonText
              className="flex flex-row gap-1 p-0"
              Icon={Wallet}
              color="secondary"
            >
              {buttonLabel}
            </CpsButtonText>
          </Link>
        </div>
      ) : null}
    </>
  );
};
