import { ErrorComponent, Link, Navigate } from "@tanstack/react-router";
import { Disconnected, ExclamationCircle, Left, Logout } from "@repo/icons";
import { type AuthenticationError } from "@auth0/auth0-spa-js";
import { useEffect } from "react";
import { logError } from "@repo/lib";
import { MainElement } from "@/components/main-element";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  EmptyStateRoot,
  EmptyStateIcon,
  EmptyStateTitle,
  EmptyStateDescription,
  EmptyStateBody,
  EmptyStateButton,
} from "@/components/empty-state";

interface ErrorPageProps extends React.ComponentPropsWithoutRef<"main"> {
  error: unknown;
}

const ErrorPageTemplate = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <MainElement>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Livance" align="center" />
      </HeaderRoot>
      <Page>
        <EmptyStateRoot>
          <EmptyStateIcon icon={ExclamationCircle} fill="danger" />
          {children}
        </EmptyStateRoot>
      </Page>
    </MainElement>
  );
};

export const NotFoundPage = (): JSX.Element => {
  return (
    <ErrorPageTemplate>
      <EmptyStateTitle>Página não encontrada</EmptyStateTitle>
      <Link to="/" className="w-full">
        <EmptyStateButton fullWidth>Voltar para a Página Inicial</EmptyStateButton>
      </Link>
    </ErrorPageTemplate>
  );
};

export const LoginErrorPage = (): JSX.Element => {
  return (
    <ErrorPageTemplate>
      <EmptyStateTitle>Erro no login</EmptyStateTitle>
      <EmptyStateDescription>
        Usuário não autorizado. Essa aplicação é restrita para profissionais cadastrados.
        Por favor, verifique o seu cadastro.
      </EmptyStateDescription>
      <Link to="/auth/logout" className="w-full" preload={false}>
        <EmptyStateButton secondary fullWidth Icon={Logout}>
          Fazer logout
        </EmptyStateButton>
      </Link>
    </ErrorPageTemplate>
  );
};

export const AccessDeniedPage = ({ error }: { error: Error }): JSX.Element => {
  const cause = error.cause as string;
  const defaultMessage =
    "Não encontramos uma conta associada a esse login/e-mail. Verifique se você entrou com o usuário correto, e tente o acesso com outro login e senha.";

  return (
    <ErrorPageTemplate>
      <EmptyStateTitle>Acesso negado</EmptyStateTitle>
      <EmptyStateDescription>{cause || defaultMessage}</EmptyStateDescription>
      <Link to="/auth/logout" className="w-full" preload={false}>
        <EmptyStateButton secondary fullWidth Icon={Logout}>
          Fazer logout
        </EmptyStateButton>
      </Link>
    </ErrorPageTemplate>
  );
};

export const GenericErrorPage = ({ error }: ErrorPageProps): JSX.Element => {
  useEffect(() => {
    logError(error);
  }, [error]);

  return (
    <ErrorPageTemplate>
      <EmptyStateTitle>Ocorreu um erro</EmptyStateTitle>
      <EmptyStateDescription>
        Se o problema persistir, entre em contato.
      </EmptyStateDescription>
      <EmptyStateBody>
        <ErrorComponent error={error} />
        <p className="p-2">{error?.toString()}</p>
      </EmptyStateBody>
      <Link to="/" className="w-full">
        <EmptyStateButton fullWidth>Voltar para o Início</EmptyStateButton>
      </Link>
      <Link to="/auth/logout" className="w-full" preload={false}>
        <EmptyStateButton secondary fullWidth Icon={Logout}>
          Fazer logout
        </EmptyStateButton>
      </Link>
    </ErrorPageTemplate>
  );
};

export const NetworkErrorPage = ({ error }: ErrorPageProps): JSX.Element => {
  useEffect(() => {
    logError(error);
  }, [error]);

  return (
    <MainElement>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Livance" align="center" />
      </HeaderRoot>
      <Page>
        <EmptyStateRoot>
          <EmptyStateIcon icon={Disconnected} fill="primary" />
          <EmptyStateTitle>Problemas de conexão</EmptyStateTitle>
          <EmptyStateDescription>
            Ocorreu um erro ao processar, verifique sua conexão com a internet.
          </EmptyStateDescription>
          <EmptyStateBody>
            <ErrorComponent error={error} />
          </EmptyStateBody>
          <Link to="/" className="w-full">
            <EmptyStateButton fullWidth>Voltar para o Início</EmptyStateButton>
          </Link>
          <Link to="/auth/logout" className="w-full" preload={false}>
            <EmptyStateButton secondary fullWidth Icon={Logout}>
              Fazer logout
            </EmptyStateButton>
          </Link>
        </EmptyStateRoot>
      </Page>
    </MainElement>
  );
};

export const ErrorPage = ({ error }: ErrorPageProps): JSX.Element => {
  if (
    error instanceof Error &&
    Object.hasOwn(error, "error") &&
    ((error as AuthenticationError).error === "missing_refresh_token" ||
      (error as AuthenticationError).error === "invalid_grant")
  ) {
    return <Navigate params={{}} to="/auth/login" search={{ returnTo: "/" }} />;
  }

  if (error instanceof Error && error.message.includes("Usuário não autorizado")) {
    return <LoginErrorPage />;
  }

  if (error instanceof Error && error.message.includes("Acesso Negado")) {
    return <AccessDeniedPage error={error} />;
  }

  if (error instanceof TypeError && error.message.includes("Network request failed")) {
    return <NetworkErrorPage error={error} />;
  }

  return <GenericErrorPage error={error} />;
};
