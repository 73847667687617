import { useAtom } from "jotai";
import { CpsAlert } from "corpus";
import { AppointmentPurpose, TimeSlotSuggestionType } from "@repo/lib/src/enums";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import {
  AppointmentSummaryBody,
  AppointmentSummaryBox,
  AppointmentSummaryDescription,
  AppointmentSummaryFooter,
  AppointmentSummaryRoot,
  AppointmentSummaryTitle,
} from "@/components/appointment-summary";

interface AppointmentPatientFormHeaderProps {
  appointmentType?: { name: string; roomTypes: { name: string }[] };
  unitName: string;
  showRoomTypes?: boolean;
  appointmentPurpose?: number;
}

export const AppointmentPatientFormHeader = ({
  appointmentType,
  unitName,
  showRoomTypes = true,
  appointmentPurpose,
}: AppointmentPatientFormHeaderProps): JSX.Element => {
  const [{ appointmentDate, appointmentTime }] = useAtom(appointmentFormAtom);
  const timeSlotType =
    appointmentPurpose === AppointmentPurpose.HorariosMenores.valueOf()
      ? TimeSlotSuggestionType.LimitedDurationAppointment
      : TimeSlotSuggestionType.Original;

  const buildRoomTypesInfo = (): string | undefined => {
    const roomTypes = appointmentType?.roomTypes.map((roomType) => roomType.name) ?? [];

    if (roomTypes.length === 1) {
      return `Sala ${roomTypes[0]}`;
    } else if (roomTypes.length > 1) {
      return `Salas ${roomTypes.join(" | ")}`;
    }

    return undefined;
  };

  const roomTypes = buildRoomTypesInfo();

  return (
    <>
      <AppointmentSummaryRoot>
        <AppointmentSummaryBox>
          <span className="text-xl text-neutral-600 font-medium">
            {appointmentDate?.getDate()}
          </span>
          <span className="text-xs text-neutral-600 font-medium">
            {`${appointmentDate?.toLocaleDateString("pt-BR", { month: "short" }).slice(0, 3)}.`}
          </span>
        </AppointmentSummaryBox>
        <AppointmentSummaryBody>
          <AppointmentSummaryTitle>{appointmentType?.name ?? ""}</AppointmentSummaryTitle>
          <AppointmentSummaryDescription
            endDateTime={appointmentTime.end}
            startDateTime={appointmentTime.start}
            unitName={unitName}
          />
          {showRoomTypes && roomTypes ? (
            <AppointmentSummaryFooter>{roomTypes}</AppointmentSummaryFooter>
          ) : null}
        </AppointmentSummaryBody>
      </AppointmentSummaryRoot>
      {timeSlotType === TimeSlotSuggestionType.LimitedDurationAppointment && (
        <CpsAlert
          title="Atenção, esse atendimento tem limite de duração."
          description=""
          type="info"
        />
      )}
    </>
  );
};
