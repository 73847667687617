import { useFragment, type FragmentType, graphql } from "@repo/graphql-types";
import { Down, Up } from "@repo/icons";
import { formatWithZonedDate, zonedDate } from "@repo/lib";
import { Link, useParams } from "@tanstack/react-router";
import { CpsAlert, CpsButtonText } from "corpus";
import { useState } from "react";
import { Button } from "@/components/button";

export const ReservedSchedulesNextSchedulesFragment = graphql(/* GraphQL */ `
  fragment ReservedSchedulesNextSchedulesFragment on tbPeriodosGarantidosRecorrentes {
    PeriodosGarantidos(
      where: {
        Agendamento: {
          cancelado: { _eq: false }
          _or: [
            { data: { _gt: $dataAgendamento } }
            {
              _and: [
                { data: { _eq: $dataAgendamento } }
                { horaInicio: { _gte: $horaInicio } }
              ]
            }
          ]
        }
        ativo: { _eq: true }
      }
    ) {
      ativo
      codPeriodoGarantido
      codPeriodoGarantidoRecorrente
      Agendamento {
        codAgendamento
        data
        horaInicio
        horaFim
        Unidade {
          nomeLimpo
        }
      }
    }
  }
`);

interface ReservedSchedulesNextSchedulesProps {
  data: FragmentType<typeof ReservedSchedulesNextSchedulesFragment>;
}

export const ReservedSchedulesNextSchedules = ({
  data,
}: ReservedSchedulesNextSchedulesProps): JSX.Element => {
  const queryData = useFragment(ReservedSchedulesNextSchedulesFragment, data);
  const params = useParams({ strict: false });
  const [rowsToShow, setRowsToShow] = useState(2); // start showing 2 lines

  const reservedSchedules = queryData.PeriodosGarantidos.slice(0, rowsToShow * 3); // multiply by 3 because we have 3 columns

  return reservedSchedules.length > 0 ? (
    <div className="flex flex-col gap-4">
      <p className="text-neutral-500">Próximas reservas</p>
      <div className="grid grid-cols-3 gap-4">
        {reservedSchedules.map((reservedSchedule) => (
          <Link
            to="./"
            key={reservedSchedule.codPeriodoGarantido}
            params={params}
            search={{
              individualActionSheetOpen: true,
              date: reservedSchedule.Agendamento.data,
              locationName: reservedSchedule.Agendamento.Unidade.nomeLimpo,
              startTime: reservedSchedule.Agendamento.horaInicio,
              endTime: reservedSchedule.Agendamento.horaFim,
              nextReserveId: reservedSchedule.codPeriodoGarantido,
              dayOfWeek: zonedDate(reservedSchedule.Agendamento.data).getDay(),
            }}
          >
            <Button key={reservedSchedule.codPeriodoGarantido} rounded size="small">
              {formatWithZonedDate(reservedSchedule.Agendamento.data, "dd/MM")}
            </Button>
          </Link>
        ))}
      </div>
      <div className="flex justify-center">
        {reservedSchedules.length < queryData.PeriodosGarantidos.length && (
          <CpsButtonText
            onClick={() => {
              setRowsToShow(rowsToShow + 1);
            }}
            color="secondary"
            Icon={Down}
          >
            Exibir mais reservas
          </CpsButtonText>
        )}
        {reservedSchedules.length === queryData.PeriodosGarantidos.length &&
          reservedSchedules.length > 6 && (
            <CpsButtonText
              onClick={() => {
                setRowsToShow(2);
              }}
              color="secondary"
              Icon={Up}
            >
              Exibir menos reservas
            </CpsButtonText>
          )}
      </div>
    </div>
  ) : (
    <CpsAlert
      title="Não foram encontradas reservas para os próximos meses"
      type="info"
      description=""
    />
  );
};
