import { useRouter, useSearch } from "@tanstack/react-router";
import { Filter } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types/fragment-masking";
import { type WaitingRoomSearch } from "@/components/waiting-room-appointment-list.tsx";
import { HeaderButton } from "@/components/header.tsx";

export const WaitingRoomFilterButtonFragment = graphql(/* GraphQL */ `
  fragment WaitingRoomFilterButtonFragment on query_root {
    agendamentos(where: $whereBuild, order_by: { horaInicio: asc }) {
      codAgendamento
    }
  }
`);

const BadgeActiveFilter = (): JSX.Element => {
  return (
    <div
      data-testid="badge-active-filter"
      className="absolute top-0 right-[-2px] bg-secondary-500 rounded-full w-[10px] h-[10px]"
    />
  );
};

interface WaitingRoomFilterButtonProps {
  data: FragmentType<typeof WaitingRoomFilterButtonFragment>;
  align: "start" | "end";
}
export const WaitingRoomFilterButton = ({
  data,
  align,
}: WaitingRoomFilterButtonProps): JSX.Element => {
  const { agendamentos } = useFragment(WaitingRoomFilterButtonFragment, data);
  const params: WaitingRoomSearch = useSearch({ from: "/waiting-room/" });
  const router = useRouter();

  const handleOpenFilterDrawer = (): void => {
    void router.navigate({
      search: {
        action: "filter-drawer",
        ...params,
      },
    });
  };

  if (agendamentos.length === 0 && !params.checkin && !params.status) {
    return <div />;
    /* eslint-disable  @typescript-eslint/prefer-nullish-coalescing --- desabilitado pois com ?? não pega todos os casos de uso */
  } else if (agendamentos.length > 0 || params.checkin || params.status) {
    return (
      <div className="relative flex items-center">
        <HeaderButton
          onClick={handleOpenFilterDrawer}
          icon={Filter}
          align={align}
          data-testid="filter-button"
        />
        {/* eslint-disable @typescript-eslint/prefer-nullish-coalescing --- desabilitado pois com ?? não funciona com um ou outro */}
        {params.checkin || params.status ? <BadgeActiveFilter /> : null}
      </div>
    );
  }
  return <div />;
};
