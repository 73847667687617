import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { ExclamationCircle, Image as ImageIcon } from "@repo/icons";
import { CpsFeedbackBubble } from "corpus";
import { ProfilePictureFragment } from "@/components/change-profile-picture-button";
import { ModalAvatarOverlay } from "@/components/modal-avatar-overlay";
import { getImageSourceFromBase64 } from "@/lib/image-source";

interface ProfilePictureProps {
  queryData: FragmentType<typeof ProfilePictureFragment>;
  isError?: boolean;
  shouldOpenModalOverlay?: boolean;
  isModalOverlayOpen?: boolean;
  setIsModalOverlayOpen?: (value: boolean) => void;
}

export const ProfilePicture = ({
  queryData,
  isError = false,
  shouldOpenModalOverlay = false,
  isModalOverlayOpen = false,
  setIsModalOverlayOpen,
}: ProfilePictureProps): JSX.Element => {
  const fragmentData = useFragment(ProfilePictureFragment, queryData);

  if (isError) {
    return <CpsFeedbackBubble Icon={ExclamationCircle} fill="danger" size="large" />;
  }

  const { profilePictureFile } = fragmentData;
  if (!profilePictureFile) {
    return (
      <div className="bg-neutral-50 p-10 rounded-full">
        <ImageIcon className="fill-neutral-300" size={80} />
      </div>
    );
  }

  const { mimetype, arquivo: base64 } = profilePictureFile;

  if (!mimetype || !base64) {
    return (
      <div className="bg-neutral-50 p-[34px] h-[124px] w-[124px] rounded-full">
        <ImageIcon className="fill-neutral-300" size={56} />
      </div>
    );
  }

  const imageSrc = getImageSourceFromBase64(mimetype, base64);
  const shouldOpen = shouldOpenModalOverlay && imageSrc;

  const setModalOverlay = (value: boolean): void => {
    if (setIsModalOverlayOpen && shouldOpen) {
      setIsModalOverlayOpen(value);
    }
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={() => setModalOverlay(true)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setModalOverlay(true);
          }
        }}
      >
        <img
          alt="Foto de perfil"
          className="rounded-full h-[124px] w-[124px] object-cover hover:cursor-pointer"
          src={imageSrc}
        />
      </div>
      {shouldOpenModalOverlay ? (
        <ModalAvatarOverlay
          src={imageSrc}
          alt="Foto de perfil"
          show={isModalOverlayOpen}
          setShow={() => setModalOverlay(false)}
        />
      ) : null}
    </>
  );
};
