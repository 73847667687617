import { CalendarTime } from "@repo/icons";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

interface AppointmentFormLimitedDurationDrawerProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  buttonAction: () => void;
  duration: number;
}

export const AppointmentFormLimitedDurationDrawer = ({
  buttonAction,
  duration,
  open,
  setOpen,
}: AppointmentFormLimitedDurationDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot
      icon={CalendarTime}
      variant="secondary"
      open={open}
      setOpen={setOpen}
    >
      <MessageDrawerTitle>Novo agendamento</MessageDrawerTitle>
      <MessageDrawerBody>
        Esse atendimento tem duração limite de {duration} minutos, ao ultrapassar o
        período agendado o valor do minuto adicional será 4 vezes o minuto padrão.
      </MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton onClick={buttonAction}>
          Confirmar
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
