import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { HelpCircle, Left } from "@repo/icons";
import { z } from "zod";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { ExtendTimeForm } from "@/components/extend-time-form";
import { ExtendTimeDrawer } from "@/components/extend-time-drawer";
import { FullPageSpinner } from "@/components/full-page-spinner";

const ExtendTimePageQuery = graphql(/* GraphQL */ `
  query ExtendTimePageQuery($codAgendamento: Int!) {
    ...ExtendTimeFormFragment
  }
`);

const ExtendTimePageSchema = z.object({
  appointmentId: z.number().optional(),
});

export const ExtendTimePage = (): JSX.Element => {
  const { appointmentId } = Route.useParams();
  const navigate = useNavigate();

  const queryResult = useGraphQL(ExtendTimePageQuery, {
    codAgendamento: Number(appointmentId),
  });

  const { data } = queryResult;

  const handleOpenDrawer = (): void => {
    void navigate({ search: { action: "extend-time-drawer" } });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Estender atendimento" align="center" />
        <HeaderButton align="end" icon={HelpCircle} onClick={handleOpenDrawer} />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data && queryResult.isFetchedAfterMount ? (
            <ExtendTimeForm queryData={data} />
          ) : (
            <FullPageSpinner />
          )}
        </AsyncDataWrapper>
        <ExtendTimeDrawer />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/extend-time/$appointmentId")({
  component: ExtendTimePage,
  validateSearch: ExtendTimePageSchema,
});
