import { motion, useCycle, type Variants } from "framer-motion";
import { Left, Right } from "@repo/icons";
import { AppointmentPurpose, cn } from "@repo/lib";
import { type ReactElement, useMemo } from "react";
import { graphql } from "@repo/graphql-types/gql";
import { useRouteContext, Link } from "@tanstack/react-router";
import { ProgressBar } from "@/components/progress-bar";
import { useGraphQL } from "@/hooks/use-graphql";
import { getTotalMinutes } from "@/lib/time";

const ActiveAllocationDetailsQuery = graphql(/* GraphQL */ `
  query ActiveAllocationDetailsQuery {
    activeAllocationDetails: alocacaoAtivaDetalhada {
      codAgendamento
      codFinalidadeAgendamento
      dataAgendamento
      horaInicioAlocacao
      horaFimAlocacao
      horaInicioAgendamento
      horaFimAgendamento
      nomePaciente
      nomeUsuarioCompromisso
      nomeUnidadeSala
      pacienteRegistroVirtual
      tempoAtrasado
      tempoRestante
      tempoAlocado
      progresso
      atrasado
      siglaSala
    }
  }
`);

const containerVariants = {
  closed: {
    scale: 1,
    left: "-7rem",
    marginLeft: 0,
    width: "13rem",
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  open: {
    scale: 1,
    left: 0,
    width: "90vw",
    marginLeft: "5vw",
    marginRight: "5vw",
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const getRadiusBarVariants = (isLate: boolean): Variants => ({
  closed: {
    justifyContent: "space-between",
    paddingLeft: "7.5rem",
    paddingRight: "0.5rem",
    borderRadius: "5rem",
    backgroundColor: isLate ? "#B91C1C" : "#005675",
    transition: { duration: 0.3, borderRadius: { delay: 0.25 } },
  },
  open: {
    justifyContent: "space-between",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "1.5rem",
    backgroundColor: isLate ? "#B91C1C" : "#005675",
    transition: { duration: 0.3, borderRadius: { delay: 0 } },
  },
});

const internalVariants = {
  closed: {
    opacity: 0,
    display: "none",
    transition: { duration: 0.1 },
  },
  open: {
    opacity: 1,
    display: "flex",
    transition: { duration: 0.1 },
  },
};

const buttonLeftVariants = {
  closed: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
  open: {
    opacity: 1,
    transition: { duration: 0.1 },
  },
};

interface RoomButtonOpenBarProps {
  variant: "left" | "right";
  barIsOpen: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const RoomButtonOpenBar = ({
  variant,
  barIsOpen,
  onClick,
  children,
}: RoomButtonOpenBarProps): JSX.Element => {
  const Icon = variant === "left" ? Left : Right;
  const clickableClass = variant === "left" ? "gap-2" : "";

  return (
    <div
      role="presentation"
      className={cn("flex items-center cursor-pointer", clickableClass)}
      onClick={onClick}
    >
      {variant === "left" && (
        <motion.button
          variants={buttonLeftVariants}
          initial="closed"
          animate={barIsOpen ? "open" : "closed"}
          className="bg-white/25 rounded-full p-2 hover:bg-white/10"
        >
          <Icon size={16} className="fill-white" />
        </motion.button>
      )}
      {children}
      {variant !== "left" && (
        <button type="button" className="rounded-full p-2">
          <Icon size={24} className="fill-white" />
        </button>
      )}
    </div>
  );
};

export interface AllocatedRoomBarV2TrackParams {
  minutosAtraso: number;
  atrasado: boolean;
}

export interface AllocatedRoomBarV2Props {
  onClickButtonReturnRoom?: (params: AllocatedRoomBarV2TrackParams) => void;
}

export const AllocatedRoomBarV2 = ({
  onClickButtonReturnRoom,
}: AllocatedRoomBarV2Props): ReactElement => {
  const { flags } = useRouteContext({ strict: false });

  const { data: allocationDetailsQuery } = useGraphQL(
    ActiveAllocationDetailsQuery,
    undefined,
    {
      refetchInterval: 10000,
    },
  );

  const shouldAllowExtension = useMemo(
    () => flags["permitir-estender-horario-de-agendamento"],
    [flags],
  );

  const activeAllocationDetails = allocationDetailsQuery?.activeAllocationDetails;

  const allowAppointmentExtension =
    shouldAllowExtension &&
    activeAllocationDetails?.codFinalidadeAgendamento !==
      AppointmentPurpose.PeriodoGarantido.valueOf();

  const isLate = activeAllocationDetails?.atrasado ?? false;
  const elapsedTime = getTotalMinutes(activeAllocationDetails?.tempoAlocado ?? "");
  const lateTimeInMinutes = getTotalMinutes(activeAllocationDetails?.tempoAtrasado ?? "");
  const remainingTimeInMinutes = getTotalMinutes(
    activeAllocationDetails?.tempoRestante ?? "",
  );
  const remainingOrLateTime = isLate
    ? `Atrasado ${lateTimeInMinutes} min`
    : `Restam ${remainingTimeInMinutes} min`;

  const [isOpen, toggleOpen] = useCycle(true, false);
  const radiusBarVariants = getRadiusBarVariants(isLate);
  const colorBackgroundClass = isLate ? "bg-danger-600" : "bg-secondary-500";

  return (
    <motion.div
      drag="x"
      dragSnapToOrigin
      dragConstraints={{ left: 0, right: 5 }}
      dragElastic={0}
      dragMomentum={false}
      className="absolute bottom-[4rem] bottom-base transform -translate-x-[15%] m-4 font-medium"
      variants={containerVariants}
      initial="closed"
      onDragStart={() => toggleOpen()}
      animate={isOpen ? "open" : "closed"}
    >
      <motion.div
        variants={radiusBarVariants}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        className={cn(
          colorBackgroundClass,
          "flex flex-col min-h-14 justify-end text-white",
          isOpen ? "py-6 !px-4" : "py-2",
        )}
      >
        <RoomButtonOpenBar
          barIsOpen={isOpen}
          variant={isOpen ? "left" : "right"}
          onClick={() => toggleOpen()}
        >
          <motion.div
            variants={internalVariants}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            className="truncate flex flex-col"
          >
            {!activeAllocationDetails?.pacienteRegistroVirtual ? (
              <span className="text-md whitespace-nowrap leading-5">
                {activeAllocationDetails?.nomePaciente}
              </span>
            ) : null}
            <span className="text-sm leading-5 truncate whitespace-nowrap">
              {activeAllocationDetails?.nomeUsuarioCompromisso}
            </span>
          </motion.div>

          <motion.div
            variants={internalVariants}
            initial="open"
            animate={!isOpen ? "open" : "closed"}
          >
            <span className="text-md whitespace-nowrap">
              {activeAllocationDetails?.siglaSala ?? ""}
            </span>
          </motion.div>
        </RoomButtonOpenBar>

        <motion.div
          className="flex flex-col gap-4 pt-4"
          variants={internalVariants}
          initial="closed"
          animate={isOpen ? "open" : "closed"}
        >
          <div>
            <div className="flex flex-row justify-between">
              <span className="text-md whitespace-nowrap">Em andamento</span>
              <span className="text-md whitespace-nowrap">
                {activeAllocationDetails?.siglaSala ?? ""}
              </span>
            </div>
            <ProgressBar progress={activeAllocationDetails?.progresso ?? 0} />
            <div className="flex flex-row justify-between">
              <span className="text-md whitespace-nowrap">{elapsedTime} min</span>
              <span className="text-md whitespace-nowrap">{remainingOrLateTime}</span>
            </div>
          </div>
          <div className="flex flex-row w-full gap-4">
            {allowAppointmentExtension ? (
              <motion.button
                variants={internalVariants}
                initial="closed"
                animate="open"
                className="w-full justify-center bg-white/25 text-white py-2 px-4 rounded-full hover:bg-white/10"
              >
                <Link
                  to="/extend-time/$appointmentId"
                  params={{
                    appointmentId: String(activeAllocationDetails?.codAgendamento),
                  }}
                  search={{ elapsedTime, isLate }}
                  className="w-full"
                >
                  Estender
                </Link>
              </motion.button>
            ) : null}
            <motion.button
              variants={internalVariants}
              initial="closed"
              animate="open"
              className="w-full justify-center bg-white/25 text-white py-2 px-4 rounded-full hover:bg-white/10"
              onClick={() =>
                onClickButtonReturnRoom?.({
                  minutosAtraso: lateTimeInMinutes,
                  atrasado: isLate,
                })
              }
            >
              Devolver
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
