import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { CpsAlert } from "corpus";

export const ProfilePictureAlertFragment = graphql(`
  fragment ProfilePictureAlertFragment on query_root {
    profilePictureFile: LivanceApiSelecionaFotoDePerfil(
      arg1: { codUsuario: $codUsuario }
    ) {
      arquivo
      mimetype
    }
  }
`);

interface ProfilePictureAlertProps {
  queryData: FragmentType<typeof ProfilePictureAlertFragment>;
  isError: boolean;
}

export const ProfilePictureAlert = ({
  queryData,
  isError,
}: ProfilePictureAlertProps): JSX.Element | null => {
  const fragmentData = useFragment(ProfilePictureAlertFragment, queryData);

  const hasProfilePicture = (): boolean => {
    return fragmentData.profilePictureFile?.arquivo !== null;
  };

  return !isError && !hasProfilePicture() ? (
    <CpsAlert
      description=""
      title="Sua foto é essencial e estará visível em seu site profissional e nas portas dos consultórios durante os atendimentos."
      type="info"
    />
  ) : null;
};
