import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import {
  MultiSelectInput,
  type MultiSelectInputProps,
} from "@/components/multi-select-input";
import { mapPaymentMethodsToSelectDrawerItemsProps } from "@/lib/mappers/payment-methods";

export const PaymentMethodsListToMultiSelectQueryFragment = graphql(/* GraphQL */ `
  fragment PaymentMethodsListToMultiSelectQueryFragment on usuariosFormasRecebimentos {
    codUsuarioFormaRecebimento
    codTipoFormaRecebimento
    codTipoConvenio
    nomeConvenio
    TipoConvenio {
      nome
    }
    TipoFormaRecebimento {
      nome
    }
  }
`);

interface PaymentMethodsMultiSelectInputProps
  extends Omit<MultiSelectInputProps<number>, "items"> {
  data: FragmentType<typeof PaymentMethodsListToMultiSelectQueryFragment>[];
}

export const PaymentMethodsMultiSelectInput = forwardRef<
  HTMLInputElement,
  PaymentMethodsMultiSelectInputProps
>(({ data, ...props }, ref: React.Ref<HTMLInputElement>): JSX.Element => {
  const paymentMethods = useFragment(PaymentMethodsListToMultiSelectQueryFragment, data);

  return (
    <MultiSelectInput<number>
      {...props}
      items={mapPaymentMethodsToSelectDrawerItemsProps(paymentMethods)}
      ref={ref}
    />
  );
});

PaymentMethodsMultiSelectInput.displayName = "PaymentMethodsMultiSelectInput";
