import { type FunctionComponent, type ReactNode } from "react";
import { CpsButtonText, CpsFeedbackBubble, type CpsFeedbackBubbleFill } from "corpus";
import { type SVGIconProps } from "@repo/icons";
import {
  DrawerRoot,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/drawer";
import { Button } from "@/components/button";

export interface MessageDrawerRootProps {
  children: ReactNode;
  icon?: FunctionComponent<SVGIconProps>;
  onClose?: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  variant?: CpsFeedbackBubbleFill;
  dismissible?: boolean;
}

export const MessageDrawerRoot = ({
  children,
  icon,
  onClose,
  open,
  setOpen,
  variant = "primary",
  dismissible = true,
}: MessageDrawerRootProps): JSX.Element => {
  return (
    <DrawerRoot dismissible={dismissible} open={open} setOpen={setOpen} onClose={onClose}>
      <DrawerContent>
        {icon !== undefined && <MessageDrawerIcon variant={variant} icon={icon} />}
        {children}
      </DrawerContent>
    </DrawerRoot>
  );
};

interface MessageDrawerIconProps {
  icon: FunctionComponent<SVGIconProps>;
  variant: CpsFeedbackBubbleFill;
}

const MessageDrawerIcon = ({ icon, variant }: MessageDrawerIconProps): JSX.Element => {
  return (
    <div data-testid="message-drawer-icon-id" className="flex justify-center mt-3">
      <CpsFeedbackBubble fill={variant} Icon={icon} />
    </div>
  );
};

interface MessageDrawerTitleProps {
  children: ReactNode;
}

export const MessageDrawerTitle = ({
  children,
}: MessageDrawerTitleProps): JSX.Element => (
  <DrawerHeader>
    <div className="text-center font-medium">
      <DrawerTitle>{children}</DrawerTitle>
    </div>
  </DrawerHeader>
);

interface MessageDrawerBodyProps {
  children: ReactNode;
}

export const MessageDrawerBody = ({ children }: MessageDrawerBodyProps): JSX.Element => (
  <div className="text-center text-md font-normal text-neutral-500 max-w-md w-full mx-auto flex flex-col overflow-auto gap-y-1">
    {children}
  </div>
);

interface MessageDrawerActionsProps {
  children: ReactNode;
}

export const MessageDrawerActions = ({
  children,
}: MessageDrawerActionsProps): JSX.Element => {
  return <div className="py-4 flex flex-col gap-4">{children}</div>;
};

interface MessageDrawerActionButtonProps {
  children: string;
  onClick?: () => void;
  secondary?: boolean;
  loading?: boolean;
  "data-testid"?: string;
}

export const MessageDrawerActionButton = ({
  children,
  onClick,
  secondary,
  "data-testid": dataTestId,
  loading = false,
}: MessageDrawerActionButtonProps): JSX.Element => {
  if (secondary) {
    return (
      <div className="flex justify-center">
        <CpsButtonText onClick={onClick}>{children}</CpsButtonText>
      </div>
    );
  }
  return (
    <Button onClick={onClick} fullWidth loading={loading} data-testid={dataTestId}>
      {children}
    </Button>
  );
};
