import { CheckCircle } from "@repo/icons";
import { useAtom } from "jotai";
import { Share as CapacitorShare } from "@capacitor/share";
import { useRouter, useSearch } from "@tanstack/react-router";
import { CpsToast } from "corpus";
import { useState } from "react";
import {
  MessageDrawerActionButton,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { createPaymentFormAtom } from "@/lib/atoms/create-payment-atom";
import { generateOnlinePaymentLink } from "@/lib/online-payment";

interface CreatePaymentConfirmSuccessDrawerProps {
  open: boolean;
  onClose?: () => void;
}

export const CreatePaymentConfirmSuccessDrawer = ({
  open,
  onClose,
}: CreatePaymentConfirmSuccessDrawerProps): JSX.Element => {
  const { guid } = useSearch({ from: "/settings/payments/create/confirmation" });
  const router = useRouter();
  const [atomValues] = useAtom(createPaymentFormAtom);
  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const handleDrawerDismiss = (value: boolean): void => {
    if (!value && open) {
      void router.navigate({ to: "/" });
    }
  };

  const shareLink = async (): Promise<void> => {
    const url = generateOnlinePaymentLink(guid ?? "");

    const canShare = await CapacitorShare.canShare();
    if (!canShare.value) {
      await navigator.clipboard.writeText(url);
      setOpenCopiedLinkToast(true);

      return;
    }

    await CapacitorShare.share({
      url,
    });
  };

  return (
    <>
      <MessageDrawerRoot
        open={open}
        setOpen={handleDrawerDismiss}
        icon={CheckCircle}
        variant="secondary"
        onClose={onClose}
      >
        <MessageDrawerTitle>
          Pagamento criado no valor de R$ {atomValues?.value}
        </MessageDrawerTitle>
        <MessageDrawerBody>
          Utilize o botão abaixo para compartilhar o link de pagamento.
        </MessageDrawerBody>
        <MessageDrawerActionButton
          onClick={() => {
            void shareLink();
          }}
        >
          Compartilhar
        </MessageDrawerActionButton>
      </MessageDrawerRoot>
      <CpsToast
        title="Link copiado para a área de transferência"
        show={openCopiedLinkToast}
        onClose={() => setOpenCopiedLinkToast(false)}
        type="success"
        duration={10}
      />
    </>
  );
};
