import type { EventClickArg, EventContentArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import type { DateClickArg } from "@fullcalendar/interaction";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useEffect, useRef, useState } from "react";
import { type LongPressReactEvents, useLongPress } from "use-long-press";
import { cn, formatIgnoringTimezone, zonedDate } from "@repo/lib";
import { type DayEvents } from "@repo/lib";
import { DayEvent } from "@/components/day-event";
import { WeekEvent } from "@/components/week-event";
import { createGenericDayEvent } from "@/lib/day-event/day-event-factory";

interface CalendarDayEventProps {
  events: DayEvents[];
  currentDate: string;
  minTime?: string;
  maxTime?: string;
  slotDuration?: string;
  weekView?: boolean;
  onEventClick?: (info: EventClickArg) => void;
  onEmptyClick?: (date: string) => void;
  onEmptyLongClick?: (date: string, start?: string, end?: string) => void;
  className?: string;
}

interface FullCalendarHTMLElement extends HTMLElement {
  fcSeg?: {
    eventRange: {
      def: {
        publicId: string;
      };
    };
  };
}

export const CalendarDayEvent = ({
  events,
  currentDate,
  minTime = "07:00:00",
  maxTime = "22:00:00",
  slotDuration = "00:60:00",
  weekView = false,
  onEventClick,
  onEmptyClick,
  onEmptyLongClick,
  className,
}: CalendarDayEventProps): JSX.Element => {
  const calendarRef = useRef<FullCalendar>(null);
  const [isLongPress, setIsLongPress] = useState(false);

  useEffect(() => {
    queueMicrotask(() => {
      if (calendarRef.current) {
        calendarRef.current
          .getApi()
          .changeView(weekView ? "timeGridWeek" : "timeGridDay");
      }
    });
  }, [weekView]);

  useEffect(() => {
    queueMicrotask(() => {
      if (calendarRef.current) {
        calendarRef.current.getApi().gotoDate(currentDate);
      }
    });
  }, [currentDate]);

  const renderEventContent = (eventInfo: EventContentArg): JSX.Element => {
    const extendedProps = eventInfo.event.extendedProps as DayEvents;
    const eventProps = createGenericDayEvent({
      id: eventInfo.event.id,
      type: extendedProps.type,
      title: eventInfo.event.title,
      appointmentPurposeId: extendedProps.appointmentPurposeId,
      timeSlotType: extendedProps.timeSlotType,
      appointmentType: extendedProps.appointmentType,
      unit: extendedProps.unit,
      start: eventInfo.event.start ?? new Date(),
      end: eventInfo.event.end ?? new Date(),
    });

    if (weekView) {
      return <WeekEvent {...eventProps} />;
    }
    return <DayEvent {...eventProps} />;
  };

  const handleLongPress = (callback: LongPressReactEvents): void => {
    const target = callback.target as FullCalendarHTMLElement;

    const isEmptySlot =
      target.childNodes.length === 0 || target.classList.contains("fc-weekslot-event");

    if (isEmptySlot) {
      setIsLongPress(true);
      const selectedEventId = target.fcSeg?.eventRange.def.publicId;
      const event = events.find((x) => x.id === selectedEventId);

      if (event) {
        const startTime = formatIgnoringTimezone(event.start, "HH:mm");
        const endTime = formatIgnoringTimezone(event.end, "HH:mm");

        onEmptyLongClick && onEmptyLongClick(currentDate, startTime, endTime);
      } else {
        onEmptyLongClick && onEmptyLongClick(currentDate);
      }
    } else {
      target.parentElement?.click();
    }
  };

  const bind = useLongPress(handleLongPress, { threshold: 500 });

  const dateClickHandler = (info: DateClickArg): void => {
    const dateString = info.dateStr.split("T")[0];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- jsEvent is always can be undefined
    if (info.jsEvent?.type === "touchend" && !isLongPress) {
      onEmptyClick && onEmptyClick(dateString);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- jsEvent is always can be undefined
    if (info.jsEvent?.type === "mouseup") {
      onEmptyClick && onEmptyClick(dateString);
    }
  };

  return (
    <div className={cn("rounded-40 bg-white pt-4", className)}>
      <div className="day-events mt-2 h-[100%]" id="day-events-id" {...bind()}>
        <FullCalendar
          allDaySlot={false}
          dateClick={dateClickHandler}
          eventClick={onEventClick}
          eventContent={renderEventContent}
          events={events}
          headerToolbar={false}
          height="auto"
          initialView={weekView ? "timeGridWeek" : "timeGridDay"}
          locale="pt-br"
          nowIndicator
          now={zonedDate(new Date())}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          ref={calendarRef}
          selectLongPressDelay={500}
          selectable
          slotDuration={slotDuration}
          slotLabelFormat={{
            hour: "numeric",
            minute: "numeric",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          slotMaxTime={maxTime}
          slotMinTime={minTime}
        />
      </div>
    </div>
  );
};
