import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useMemo, useState } from "react";
import { CpsTextArea, CpsRatingStars } from "corpus";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { isIos } from "@repo/lib";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";
import {
  CSATFeedbackMultiSelectInput,
  type ValidRatings,
} from "@/components/csat-feedback-multi-select-input";
import { trackEvent } from "@/lib/tracking";
import {
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerRoot,
  DrawerTitle,
} from "@/components/drawer";

export const GetAllCsatCategoriesQueryFragment = graphql(/* GraphQL */ `
  fragment GetAllCsatCategoriesQueryFragment on query_root {
    csatCategorias {
      ...CsatCategoriesListQueryFragment
    }
  }
`);

interface CsatAllocatedRoomDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
  data: FragmentType<typeof GetAllCsatCategoriesQueryFragment> | undefined;
}

export const CsatAllocatedRoomDrawer = ({
  open,
  setOpen,
  onClose,
  data,
}: CsatAllocatedRoomDrawerProps): JSX.Element => {
  const [selectedRating, setSelectedRating] = useState<ValidRatings>(0);

  const csatCategoriesData = useFragment(GetAllCsatCategoriesQueryFragment, data);

  const handleRatingSelect = (rating: ValidRatings): void => {
    setSelectedRating(rating);
    form.setValue("rating", rating);
  };

  const { appointmentId, locationIdCsat, roomType }: Record<string, unknown> = useSearch({
    strict: false,
  });

  const { user: userContext, flags } = useRouteContext({
    strict: false,
  });

  const csatConfig = useMemo(() => flags["configuracao-banner-csat"], [flags]);

  const formSchema = z.object({
    rating: z.number().min(1, "Campo obrigatório"),
    feedback: z
      .array(
        z.object({
          codCsatCategoria: z.number(),
          nome: z.string(),
        }),
      )
      .optional(),
    comment: z.string().optional(),
  });

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      rating: 0,
      feedback: [],
      comment: "",
    },
  });

  const handleSubmit = (formData: FormFields): void => {
    trackEvent("CSAT de Atendimento Enviado", {
      codUsuario: userContext.codUsuario,
      codAgendamento: appointmentId,
      codUnidade: locationIdCsat,
      codTipoSala: roomType,
      nota: formData.rating,
      motivosSelecionados: formData.feedback,
      comentario: formData.comment,
    });
    setOpen(false);
  };

  const router = useRouter();

  const CSAT_EXCLUDED_LOCATION_COD = [1, 6];
  let showDrawer = open;

  if (CSAT_EXCLUDED_LOCATION_COD.includes(Number(locationIdCsat))) {
    showDrawer = false;
    void router.navigate({
      search: {
        ...URLSearchParams,
        action: undefined,
      },
      replace: true,
    });
  }

  const cssClass = isIos ? "max-h-[85%] !h-max" : "!h-max";

  return (
    <DrawerRoot open={showDrawer} setOpen={setOpen} onClose={onClose}>
      <DrawerContent className={cssClass}>
        <DrawerTitle className="text-xl font-medium text-neutral-700 text-center">
          Como foi sua experiência na unidade?
        </DrawerTitle>
        <FormRoot {...form}>
          <FormHandlerSubmit handleSubmit={handleSubmit} className="w-full">
            <DrawerBody>
              <FormField
                control={form.control}
                name="rating"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <CpsRatingStars
                        {...field}
                        title="Avalie de 1 a 5 estrelas"
                        maxRating={5}
                        onRatingSelect={handleRatingSelect}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {selectedRating > 0 ? (
                <>
                  <FormField
                    control={form.control}
                    name="feedback"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <CSATFeedbackMultiSelectInput
                            {...field}
                            rating={selectedRating}
                            data={csatCategoriesData?.csatCategorias ?? []}
                            value={field.value ?? []}
                            onChange={(selected) => form.setValue("feedback", selected)}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  {csatConfig.exibirComentario ? (
                    <FormField
                      control={form.control}
                      name="comment"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <CpsTextArea
                              {...field}
                              placeholder="Deseja adicionar um comentário?"
                              rows={2}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  ) : null}
                </>
              ) : null}
            </DrawerBody>
            <DrawerFooter>
              <FormSubmitButton className="w-full">Enviar</FormSubmitButton>
            </DrawerFooter>
          </FormHandlerSubmit>
        </FormRoot>
      </DrawerContent>
    </DrawerRoot>
  );
};
