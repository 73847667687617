import { cn } from "@repo/lib";
import { forwardRef } from "react";

export const ListRoot = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({className, ...props}, ref): JSX.Element => {
    return (
      <ul className="justify-center items-center">
        <div
          className={cn("divide-y-2 divide-gray-100", className)}
          {...props}
          ref={ref}
        />
      </ul>
    );
  },
);

ListRoot.displayName = "ListRoot";
