import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { formatWithZonedDate, getCurrentDate } from "@repo/lib";
import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { z } from "zod";
import { useResetAtom } from "jotai/utils";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { isPast } from "date-fns";
import { type FragmentType } from "@repo/graphql-types";
import { useGraphQL } from "@/hooks/use-graphql";
import "@/styles/calendar.css";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  AppointmentForm,
  type AppointmentFormFragment,
} from "@/components/appointment-form";
import { SchedulesEmptyState } from "@/components/schedules-empty-state";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { type appointmentFormSchema } from "@/lib/form-schemas/appointment-schema";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { PendingComponent } from "@/components/pending-component";
import {
  type BlockedActionsListAlertFragment,
  BlockedAppointmentAlert,
} from "@/components/blocked-appointment-alert";

const AppointmentPageQuery = graphql(`
  query AppointmentPageQuery(
    $dataInicio: date!
    $codAgendamento: Int!
    $codPaciente: Int!
  ) {
    usuariosAgendas(
      where: {
        ativo: { _eq: true }
        tbUsuariosAgendasCompromissos: {
          ativo: { _eq: true }
          UsuarioCompromisso: {
            usuariosCompromissosCanaisAgendamentos: { codCanalAgendamento: { _eq: 2 } }
          }
        }
        _or: [{ dataFim: { _gte: $dataInicio } }, { dataFim: { _is_null: true } }]
      }
    ) {
      codUsuarioAgenda
    }

    ...AppointmentFormFragment
    ...BlockedActionsListAlertFragment

    paciente: pacientes_by_pk(codPaciente: $codPaciente) {
      codPaciente
      telefone
      cpf
      nome
      email
      receberWhatsAppConfirmacao
      dataNascimento
    }

    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      codUnidade
      codUsuarioCompromisso
      codUsuarioFormaRecebimento
      data
    }
  }
`);

const appointmentPageSearchSchema = z.object({
  date: z
    .string()
    .optional()
    .refine((value) => !value || /^\d{4}-\d{2}-\d{2}$/.test(value))
    .catch(formatWithZonedDate(getCurrentDate())),
  locationId: z.number().optional().catch(0),
  appointmentId: z.number().optional().catch(0),
  patientId: z.number().optional().catch(0),
});

export const AppointmentPage = (): JSX.Element => {
  const router = useRouter();
  const { appointmentId, patientId } = useSearch({ from: "/appointment/create/" });

  const resetRescheduleAppointmentForm = useResetAtom(appointmentFormAtom);

  const [appointmentFormAtomValue, setAppointFormAtomValue] =
    useAtom(appointmentFormAtom);

  const [isSetAtomInitialValuesExecuted, setIsSetAtomInitialValuesExecuted] =
    useState(false);

  const queryResult = useGraphQL(AppointmentPageQuery, {
    dataInicio: formatWithZonedDate(new Date(), "yyyy-MM-dd"),
    codAgendamento: appointmentId ?? 0,
    codPaciente: patientId ?? 0,
  });

  const { data, isFetching } = queryResult;

  const setAtomInitialValues = (): void => {
    let atom: z.infer<typeof appointmentFormSchema> = { ...appointmentFormAtomValue };

    if (data?.agendamento) {
      const {
        codUnidade: unit,
        codUsuarioCompromisso: appointmentType,
        codUsuarioFormaRecebimento: paymentMethod,
        data: appointmentDate,
      } = data.agendamento;

      atom = {
        ...atom,
        unit,
        appointmentType,
        paymentMethod,
        appointmentDate: setInitialDate(appointmentDate),
      };
    }

    if (data?.paciente) {
      const {
        codPaciente,
        nome: name,
        receberWhatsAppConfirmacao: sendConfirmationViaWhatsApp,
        telefone: phone,
        cpf,
        email,
        dataNascimento: dateOfBirth,
      } = data.paciente;

      atom = {
        ...atom,
        patientId: codPaciente,
        name,
        sendConfirmationViaWhatsApp,
        phone,
        email: email ?? "",
        cpf: cpf ?? undefined,
        dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : undefined,
      };
    }

    setAppointFormAtomValue(atom);
    setIsSetAtomInitialValuesExecuted(true);
  };

  const setInitialDate = (initialDate: string): Date => {
    const dateObject = new Date(initialDate);

    return isPast(dateObject) ? new Date() : dateObject;
  };

  const isPending = !isSetAtomInitialValuesExecuted || isFetching;

  useEffect(() => {
    setAtomInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Deve ser executado quando carregar os dados
  }, [data]);

  const onBackButtonClick = (): void => {
    resetRescheduleAppointmentForm();
    router.history.back();
  };

  const content = (): JSX.Element => {
    if (isPending) {
      return <FullPageSpinner />;
    }

    if (data?.usuariosAgendas && data.usuariosAgendas.length > 0) {
      return (
        <>
          <div className="pb-4">
            <BlockedAppointmentAlert
              blockedActionsListFragmentData={
                data as FragmentType<typeof BlockedActionsListAlertFragment>
              }
            />
          </div>
          <AppointmentForm
            queryData={data as FragmentType<typeof AppointmentFormFragment>}
          />
        </>
      );
    }

    return <SchedulesEmptyState />;
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" onClick={onBackButtonClick} />
        <HeaderTitle title="Novo agendamento" align="center" />
      </HeaderRoot>

      <Page id="page-create-appointment-id">{content()}</Page>
    </>
  );
};

export const Route = createFileRoute("/appointment/create/")({
  component: AppointmentPage,
  validateSearch: appointmentPageSearchSchema,
  loaderDeps: ({ search: { appointmentId, patientId } }) => ({
    appointmentId,
    patientId,
  }),
  beforeLoad: ({ context }) => {
    return {
      showAppointmentDurationSuggestion:
        context.ldClient.getShowAppointmentDurationSuggestion(),
      showFeedbackCaptureDesiredTime:
        context.ldClient.getShowFeedbackCaptureDesiredTime(),
      showAppointmentsAndCloseSchedulesDuringSchedule:
        context.ldClient.getShowAppointmentsAndCloseSchedulesDuringSchedule(),
    };
  },
  pendingComponent: () => <PendingComponent title="Novo agendamento" />,
});
