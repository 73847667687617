import { type BrowserClient, type feedbackIntegration, getClient } from "@sentry/react";
import { motion } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import { X } from "@repo/icons";
import { useRouteContext, useRouter } from "@tanstack/react-router";

export const UserFeedbackButton = (): JSX.Element | null => {
  const constraintsRef = useRef<HTMLDivElement>(null);
  const { ldClient, flags } = useRouteContext({ strict: false });
  const [closed, setClosed] = useState(false);
  const client = getClient<BrowserClient>();
  const feedback =
    client?.getIntegrationByName<ReturnType<typeof feedbackIntegration>>("Feedback");

  const router = useRouter();
  const currentPath = router.state.location.pathname;

  const { routesWithUserFeedback } =
    flags["configura-rotas-que-aparece-barrinha-de-feedback"];

  const shouldShowFeedback = (): boolean => {
    if (routesWithUserFeedback === "all") {
      return true;
    } else if (Array.isArray(routesWithUserFeedback)) {
      return routesWithUserFeedback.some((route) => {
        if (route.endsWith("/*")) {
          const regex = new RegExp(`^${route.slice(0, -2)}`);
          return regex.test(currentPath);
        }
        return route === currentPath;
      });
    } else if (routesWithUserFeedback === "none") {
      return false;
    }

    return false;
  };

  const showFeedback = shouldShowFeedback();

  useEffect(() => {
    const setConstraintsHeight = (): void => {
      if (constraintsRef.current) {
        constraintsRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    setConstraintsHeight();

    window.addEventListener("resize", setConstraintsHeight);

    return () => {
      window.removeEventListener("resize", setConstraintsHeight);
    };
  }, []);

  if (!showFeedback) {
    return null;
  }

  if (!feedback) {
    return null;
  }

  if (!ldClient.getShowReportProblems() || closed) {
    return null;
  }

  return (
    <div
      className="fixed top-0 right-0 h-[100vh] w-14 z-10 flex pointer-events-none"
      ref={constraintsRef}
    >
      <motion.div
        drag="y"
        dragSnapToOrigin={false}
        dragConstraints={constraintsRef}
        dragMomentum={false}
        style={{
          rotate: -90,
        }}
        className="absolute top-1/4 origin-bottom-right px-4 py-2 min-w-64 m-auto right-0 rounded-t-md bg-primary-400 tracking-wider text-white drop-shadow-md pointer-events-auto flex items-center"
        onClick={() => {
          feedback.openDialog();
        }}
      >
        <button type="button" className="flex-1 whitespace-nowrap">
          Enviar Feedback
        </button>
        <button
          type="button"
          onClick={(event) => {
            setClosed(true);
            event.stopPropagation();
          }}
        >
          <X className="fill-white mx-2" />
        </button>
      </motion.div>
    </div>
  );
};
