import { Dot } from "@repo/icons";
import { clsx } from "clsx";
import type { MouseEventHandler } from "react";
import { forwardRef, memo } from "react";
import { cn } from "@repo/cn";

export interface CpsBadgeProps extends React.ComponentPropsWithoutRef<"span"> {
  size?: "default" | "large";
  color?: "neutral" | "danger" | "warning" | "success" | "helper" | "primary";
  hasDot?: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  children: string;
}

const badgeColors: Record<string, string> = {
  neutral: "bg-neutral-100",
  danger: "bg-danger-100",
  warning: "bg-warning-100",
  success: "bg-success-100",
  helper: "bg-helper-100",
  primary: "bg-primary-100",
};

const badgeDotColor: Record<string, string> = {
  neutral: "fill-neutral-700",
  danger: "fill-danger-700",
  success: "fill-success-700",
  warning: "fill-warning-700",
  helper: "fill-helper-700",
};

/**
 * `CpsBadge` is a component that renders a badge.
 *
 * @remarks
 * This component is part of the CPS UI library.
 *
 * @example
 * Here is a basic usage example:
 * ```tsx
 * <CpsBadge color="danger">
 *     Lorem ipsum dolor sit amet
 * </CpsBadge>
 * ```
 * @param size - The size of the badge.
 * @param color - The color of the badge.
 * @param hasDot - Whether to display a dot icon in the beginning of the badge.
 * @param children - The text of the badge.
 * @param onClick - A function that is called when the badge is clicked.
 *
 * @returns The `CpsBadge` component.
 */
export const CpsBadge = forwardRef<HTMLSpanElement, CpsBadgeProps>(
  (
    { size = "default", color = "neutral", hasDot = false, children, onClick, className },
    ref,
  ) => {
    const badgeSize: Record<string, string> = {
      default: "text-xs h-[24px]",
      large: "text-sm h-[26px]",
    };

    const sizeClasses = badgeSize[size];
    const colorClasses = badgeColors[color];

    const ShowDot: React.FunctionComponent = () => {
      return (
        <>
          {hasDot && (
            <Dot className={clsx(children && "mr-1", badgeDotColor[color])} size={8} />
          )}
        </>
      );
    };

    const commonClasses =
      "px-3 flex select-none items-center justify-center py-1 text-center font-medium rounded-70 font-medium text-neutral-600";

    return (
      <>
        <span
          className={cn(
            "whitespace-nowrap w-fit",
            sizeClasses,
            colorClasses,
            commonClasses,
            className,
          )}
          ref={ref}
          onClick={onClick}
        >
          <ShowDot />
          {children}
        </span>
      </>
    );
  },
);

const MemoCpsBadge = memo(CpsBadge);
MemoCpsBadge.displayName = "CpsBadge";

export default MemoCpsBadge;
