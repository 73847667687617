/* eslint-disable @typescript-eslint/no-unsafe-member-access -- magic needed for hook typings */
/* eslint-disable @typescript-eslint/no-explicit-any -- magic needed for hook typings */
import type { Auth0Client } from "@auth0/auth0-spa-js";
import { type TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
  type FetchQueryOptions,
  type InvalidateQueryFilters,
  type QueryFilters,
} from "@tanstack/react-query";
import { useRouteContext } from "@tanstack/react-router";
import { type GraphQLError, print } from "graphql";
import { request } from "graphql-request";
import { HASURA_ENDPOINT } from "@repo/lib";
import { type SetStateAction, useEffect, useState } from "react";
import { type Client, type ExecutionResult, type SubscribePayload } from "graphql-ws";
import { getAuthorizationHeaders } from "@/lib/authentication-headers.ts";
import { type RootRouteContext } from "@/routes/-root-context";
import { useErrorDrawer } from "@/hooks/use-error-drawer.tsx";
import { throwOnError } from "@/lib/throw-on-error.tsx";
import { LivanceTypedError } from "@/lib/livance-typed-error.tsx";

export async function queryFn<TData, TVariables>(
  document: TypedDocumentNode<TData, TVariables>,
  authClient: Auth0Client,
  variables?: TVariables,
): Promise<any> {
  const requestHeaders = await getAuthorizationHeaders(authClient);

  return await request(HASURA_ENDPOINT, document, variables ?? undefined, requestHeaders);
}

export async function queryFnWithError<TData, TVariables>(
  document: TypedDocumentNode<TData, TVariables>,
  authClient: Auth0Client,
  variables?: TVariables,
): Promise<any> {
  const requestHeaders = await getAuthorizationHeaders(authClient);

  const result = await request(
    HASURA_ENDPOINT,
    document,
    variables ?? undefined,
    requestHeaders,
  );

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition --- intentionally unnecessary check
  if (result) {
    throwOnError(result);
  }

  return result;
}

export function useGraphQL<TData, TVariables>(
  document: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options?: Omit<UseQueryOptions<TData, GraphQLError>, "queryKey">,
): UseQueryResult<TData, GraphQLError> {
  const routeContext = useRouteContext({ strict: false });
  const { authClient } = routeContext;

  return useQuery({
    ...options,
    gcTime: Infinity,
    queryKey: [(document.definitions[0] as any).name.value, variables],
    queryFn: () => queryFn(document, authClient, variables),
  });
}

export async function fetchQuery<TData, TVariables>(
  context: RootRouteContext,
  document: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options?: FetchQueryOptions<TData, GraphQLError>,
): Promise<TData> {
  const { authClient, queryClient } = context;

  return await queryClient.fetchQuery({
    ...options,
    queryKey: [(document.definitions[0] as any).name.value, variables],
    queryFn: () => queryFn(document, authClient, variables),
  });
}

export async function ensureQueryData<TData, TVariables>(
  context: RootRouteContext,
  document: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options?: FetchQueryOptions<TData, GraphQLError>,
): Promise<TData> {
  const { authClient, queryClient } = context;

  return await queryClient.ensureQueryData({
    ...options,
    queryKey: [(document.definitions[0] as any).name.value, variables],
    queryFn: () => queryFn(document, authClient, variables),
  });
}

export function useGraphQLMutation<
  TData extends Record<string, any> = any,
  TVariables = Record<string, any>,
>(
  document: TypedDocumentNode<TData, TVariables>,
  options?: UseMutationOptions<TData, GraphQLError, TVariables>,
): UseMutationResult<TData, GraphQLError, TVariables, any> {
  const routeContext = useRouteContext({ strict: false });
  const { authClient } = routeContext;

  return useMutation({
    ...options,
    mutationFn: (variables) => queryFn(document, authClient, variables),
  });
}

interface UseMutationOptionsExtensions {
  onCloseErrorDrawer?: SetStateAction<() => void>;
}

export function useGraphQLMutationWithErrorHandler<
  TData extends Record<string, any> = any,
  TVariables = Record<string, any>,
>(
  document: TypedDocumentNode<TData, TVariables>,
  options?: UseMutationOptions<TData, GraphQLError, TVariables> &
    UseMutationOptionsExtensions,
): UseMutationResult<TData, GraphQLError, TVariables, any> {
  const routeContext = useRouteContext({ strict: false });
  const { authClient } = routeContext;
  const { showErrorDrawer } = useErrorDrawer();

  return useMutation({
    ...options,
    retry: (failureCount, error) => {
      if (error instanceof LivanceTypedError) {
        return false;
      }

      if (!options?.retry) {
        return false;
      }
      if (typeof options.retry === "number") {
        return failureCount < options.retry;
      }

      return true;
    },
    mutationFn: (variables) => queryFnWithError(document, authClient, variables),
    onSuccess: (data, variables, context) => {
      const actionKey = Object.keys(data)[0] as keyof TData;
      const responseData = data[actionKey];

      if (responseData.data && options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      options?.onError?.(error, variables, context);
      if (error instanceof LivanceTypedError) {
        if (error.typeName === "ValidationError") {
          showErrorDrawer(error.message, options?.onCloseErrorDrawer);
        } else if (error.typeName === "UnexpectedError") {
          showErrorDrawer(undefined, options?.onCloseErrorDrawer);
        }
      } else {
        showErrorDrawer(undefined, options?.onCloseErrorDrawer);
      }
    },
  });
}

export function useSubscription<
  TData,
  TVariables extends Record<string, any> = Record<string, any>,
  TError = unknown,
>(
  document: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
): SubscriptionResult<ExecutionResult<TData>, TError> {
  const { wsClient, user } = useRouteContext({ strict: false });

  /* eslint-disable @typescript-eslint/no-unnecessary-condition -- ESLint is unable to get if unmounted or not */
  const [data, setData] = useState<ExecutionResult<TData>>();
  const [error, setError] = useState<TError | null>(null);
  useEffect(() => {
    let mounted = true;
    const cancellationController = new AbortController();
    const iter = subscriptionFn<TData, TVariables>(
      wsClient,
      document,
      variables,
      cancellationController.signal,
    );

    if (user.codUsuario === 0) {
      // User is not logged in, do nothing.
      return;
    }

    void (async () => {
      try {
        while (mounted) {
          const subscriptionResult = await iter.next();

          if (mounted) {
            setData(subscriptionResult.value);
            setError(null);
          }
          if (subscriptionResult.done) {
            break;
          }
        }
      } catch (err) {
        if (mounted) {
          setError(err as TError);
        }
      }
    })();

    return () => {
      mounted = false;
      cancellationController.abort();
    };
  }, [user.codUsuario, document, variables, wsClient]);

  return { data, error, isError: error !== null };
}

async function* subscriptionFn<TData, TVariables extends Record<string, unknown>>(
  wsClient: Client,
  document: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  cancellationToken?: AbortSignal,
): AsyncGenerator<ExecutionResult<TData>, undefined> {
  const subscription = wsClient.iterate<TData, TVariables>({
    query: print(document),
    variables,
  } as SubscribePayload);

  for await (const result of subscription) {
    if (cancellationToken?.aborted) {
      break;
    }
    yield result;
  }
}

interface SubscriptionResult<TData = unknown, TError = unknown> {
  data: TData | undefined;
  error: TError | null;
  isError: boolean;
}

export function prefetchQuery<TData, TVariables>(
  routeContext: RootRouteContext,
  document: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options?: Omit<UseQueryOptions<TData, GraphQLError>, "queryKey">,
): void {
  const { authClient, queryClient } = routeContext;
  void queryClient.prefetchQuery({
    staleTime: 60000,
    ...options,
    queryKey: [(document.definitions[0] as any).name.value, variables],
    queryFn: () => queryFn(document, authClient, variables),
  });
}

export function useInvalidateQuery<TData, TVariables>(
  document: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
): () => void {
  const routeContext = useRouteContext({ strict: false });
  const { queryClient } = routeContext;

  return () => {
    const queryKey = [(document.definitions[0] as any).name.value, variables ?? {}];
    void queryClient.invalidateQueries(queryKey as InvalidateQueryFilters);
  };
}

export function useResetQuery<TData, TVariables>(
  document: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
): () => void {
  const routeContext = useRouteContext({ strict: false });
  const { queryClient } = routeContext;

  return () => {
    const queryKey = [(document.definitions[0] as any).name.value, variables ?? {}];
    void queryClient.resetQueries(queryKey as QueryFilters);
  }
}