import { type SVGIconProps } from "@repo/icons";
import { cn } from "@repo/lib";
import {
  type ComponentPropsWithoutRef,
  type FunctionComponent,
  type ReactNode,
} from "react";

interface CardRootProps extends Omit<ComponentPropsWithoutRef<"div">, "onClick"> {
  onClick?: (
    event?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
}

export const CardRoot = ({ children, onClick, ...props }: CardRootProps): JSX.Element => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (
      onClick &&
      (event.key === "Enter" || event.key === " " || event.type === "click")
    ) {
      onClick(event);
    }
  };

  return (
    <div
      {...props}
      tabIndex={onClick ? 0 : undefined}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role={onClick ? "button" : undefined}
      className="border border-gray-200 rounded-lg flex gap-3 flex-col justify-around"
    >
      {children}
    </div>
  );
};

export const CardHeader = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div
      {...props}
      className={cn(
        "flex flex-row items-center justify-between w-full px-4 pt-4",
        className,
      )}
    >
      {children}
    </div>
  );
};

interface CardTitleProps {
  children: ReactNode;
}

export const CardTitle = ({ children }: CardTitleProps): JSX.Element => {
  return <p className="font-medium text-sm text-gray-800">{children}</p>;
};

interface CardIconProps extends SVGIconProps {
  icon: FunctionComponent<SVGIconProps>;
  onClick?: () => void;
}

export const CardIcon = ({
  size,
  onClick,
  icon: Icon,
  ...props
}: CardIconProps): JSX.Element => {
  return onClick ? (
    <button onClick={onClick} type="button">
      <Icon {...props} size={size} />
    </button>
  ) : (
    <Icon {...props} size={size} />
  );
};

export const CardContent = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div {...props} className={cn("flex flex-col text-sm p-4 pb-4", className)}>
      {children}
    </div>
  );
};

export const CardFooter = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div
      {...props}
      className={cn(
        "pb-4 px-4 flex flex-row items-center justify-between w-full",
        className,
      )}
    >
      {children}
    </div>
  );
};
