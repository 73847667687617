import { Network } from "@capacitor/network";
import { useEffect, useState } from "react";

export const useNetworkStatus = (): boolean => {
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const getStatus = async (): Promise<void> => {
      const status = await Network.getStatus();
      setIsConnected(status.connected);
    };

    const handler = Network.addListener("networkStatusChange", (status) => {
      setIsConnected(status.connected);
    });

    void getStatus();

    return () => {
      void handler.then((h) => h.remove());
    };
  }, []);

  return isConnected;
};
