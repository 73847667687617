import {
  type LDClient,
  type LDContext,
  type LDContextCommon,
  type LDMultiKindContext,
  type LDSingleKindContext,
  withLDProvider,
} from "launchdarkly-react-client-sdk";
import type React from "react";
import { VITE_LAUNCHDARKLY_SDK_KEY, Version } from "@repo/lib";

export interface FeatureFlags {
  "permite-atendimentos-de-ate-15-minutos": boolean;
  "duracao-maxima-do-tipo-de-atendimento-em-minutos": number;
  "projeto-preco-flexivel": boolean;
  "minimal-app-version": string;
  "antecedencia-minima-para-criacao-de-agendamento-com-preco-flexivel": number;
  "duracao-tipo-atendimento-padrao-porto": number;
  "modalidade-periodo-garantido-por-usuario": number;
  "exibir-opcao-periodo-garantido-menu-app": boolean;
  "exibe-sugestao-de-duracao-para-tipo-de-atendimento-no-app": boolean;
  "exibir-drawer-de-feedback-ao-criar-um-agendamento": boolean;
  "captura-feedback-sobre-horario-desejado": boolean;
  "libera-planos-full-para-psico": boolean;
  "exibir-indique-e-ganhe": boolean;
  "exibe-migracao-de-plano-no-app": boolean;
  "exibe-marcar-paciente-como-atendido-na-sala-de-espera": boolean;
  "mostra-barrinha-reportar-problemas": boolean;
  "atualiza-mutation-receber-sala-agora-no-app-novo": boolean;
  "exibe-drawer-de-erro-de-conexao": boolean;
  "mostra-agendamentos-e-fechamentos-na-busca-de-horario": boolean;
  "configuracao-banner-csat": CsatConfig;
  "inclui-salas-flex-em-tipos-de-atendimento-que-aceitam-apenas-multi": boolean;
  "exibe-botoes-de-indicacao-de-membros-para-pacientes": boolean;
  "deve-considerar-plano-mensal-como-permanencia-de-3-meses": boolean;
  "nova-barra-de-alocacoes": boolean;
  "permitir-estender-horario-de-agendamento": boolean;
  "utiliza-modelo-de-cobranca-refatorado": boolean;
  "deve-exibir-etapa-de-redes-socias-no-fluxo-do-one-liv-no-app": boolean;
  "configura-rotas-que-aparece-barrinha-de-feedback": RoutesShowUserFeedbackConfig;
  "envio-automatico-link-pagamento": boolean;
  "ativa-fluxo-de-criar-pagamento-online": boolean;
  "exibe-menu-visao-geral-relatorio-atendimento": boolean;
  "exibe-entrada-no-fluxo-de-criacao-de-pagamento-online-no-pre-consulta": boolean;
  "exibe-projeto-prm": boolean;
}

export const DefaultFeatureFlagsValue: FeatureFlags = {
  "antecedencia-minima-para-criacao-de-agendamento-com-preco-flexivel": 72,
  "atualiza-mutation-receber-sala-agora-no-app-novo": false,
  "captura-feedback-sobre-horario-desejado": false,
  "configuracao-banner-csat": {
    ativo: false,
    exibirNovamenteEm: "00:00:00",
    exibirComentario: false,
  },
  "duracao-maxima-do-tipo-de-atendimento-em-minutos": 120,
  "duracao-tipo-atendimento-padrao-porto": 20,
  "exibe-botoes-de-indicacao-de-membros-para-pacientes": false,
  "exibe-drawer-de-erro-de-conexao": false,
  "exibe-marcar-paciente-como-atendido-na-sala-de-espera": false,
  "exibe-migracao-de-plano-no-app": false,
  "exibe-sugestao-de-duracao-para-tipo-de-atendimento-no-app": false,
  "exibir-drawer-de-feedback-ao-criar-um-agendamento": false,
  "libera-planos-full-para-psico": false,
  "exibir-indique-e-ganhe": false,
  "exibir-opcao-periodo-garantido-menu-app": false,
  "inclui-salas-flex-em-tipos-de-atendimento-que-aceitam-apenas-multi": false,
  "minimal-app-version": "0.0.0",
  "modalidade-periodo-garantido-por-usuario": 1,
  "mostra-agendamentos-e-fechamentos-na-busca-de-horario": false,
  "mostra-barrinha-reportar-problemas": true,
  "permite-atendimentos-de-ate-15-minutos": false,
  "projeto-preco-flexivel": false,
  "deve-considerar-plano-mensal-como-permanencia-de-3-meses": false,
  "nova-barra-de-alocacoes": false,
  "permitir-estender-horario-de-agendamento": false,
  "utiliza-modelo-de-cobranca-refatorado": false,
  "deve-exibir-etapa-de-redes-socias-no-fluxo-do-one-liv-no-app": false,
  "configura-rotas-que-aparece-barrinha-de-feedback": { routesWithUserFeedback: "none" },
  "envio-automatico-link-pagamento": false,
  "ativa-fluxo-de-criar-pagamento-online": false,
  "exibe-menu-visao-geral-relatorio-atendimento": false,
  "exibe-entrada-no-fluxo-de-criacao-de-pagamento-online-no-pre-consulta": false,
  "exibe-projeto-prm": false,
};

export interface RoutesShowUserFeedbackConfig {
  routesWithUserFeedback: string[] | string;
}

export interface CsatConfig {
  exibirNovamenteEm: string;
  exibirComentario: boolean;
  ativo: boolean;
}

const anonymousUserContext: LDContext = {
  kind: "user",
  anonymous: true,
};

const defaultContext: LDMultiKindContext = {
  kind: "multi",
  user: anonymousUserContext,
};

export const withDefaultLDProvider = (app: React.ComponentType): React.ComponentType => {
  return withLDProvider({
    clientSideID: VITE_LAUNCHDARKLY_SDK_KEY,
    context: defaultContext,
    flags: DefaultFeatureFlagsValue,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(app);
};

export class LaunchDarklyClient {
  constructor(ldClient: LDClient) {
    this.instance = ldClient;
  }

  private instance: LDClient;

  private get currentContext(): LDMultiKindContext {
    return this.instance.getContext() as LDMultiKindContext;
  }

  public getFlagValue<T>(key: keyof FeatureFlags, defaultValue: T): T {
    const flagValue = this.instance.variation(key, defaultValue) as T;
    return flagValue ?? defaultValue;
  }

  public userContextAlreadyAdded(): boolean {
    return (
      (this.currentContext.user as LDContextCommon).key !== undefined &&
      !(this.currentContext.user as LDContextCommon).anonymous
    );
  }

  public async addContext(context: LDSingleKindContext): Promise<void> {
    const newContext = this.mergeContext(this.currentContext, context);

    if (newContext) {
      await this.identify(newContext);
    }
  }

  public async addContexts(contexts: LDSingleKindContext[]): Promise<void> {
    let changed: boolean | LDMultiKindContext = false;
    let currentContext = this.currentContext;
    for (const context of contexts) {
      changed = this.mergeContext(currentContext, context) || changed;
      currentContext = changed ? changed : currentContext;
    }
    if (changed) {
      await this.identify(changed);
    }
  }

  private mergeContext(
    baseContext: LDMultiKindContext,
    context: LDSingleKindContext,
  ): LDMultiKindContext | false {
    const current = baseContext[context.kind] as LDSingleKindContext | undefined;
    if (!current || current.key !== context.key) {
      return { ...baseContext, [context.kind]: context };
    }
    return false;
  }

  private async identify(context: LDMultiKindContext): Promise<LDClient> {
    await this.instance.identify(context);

    await this.instance.waitForInitialization();

    return this.instance;
  }

  public getShowReservedScheduleOption(): boolean {
    return this.getFlagValue<boolean>(
      "exibir-opcao-periodo-garantido-menu-app",
      DefaultFeatureFlagsValue["exibir-opcao-periodo-garantido-menu-app"],
    );
  }

  public getShowReportProblems(): boolean {
    return this.getFlagValue<boolean>(
      "mostra-barrinha-reportar-problemas",
      DefaultFeatureFlagsValue["mostra-barrinha-reportar-problemas"],
    );
  }

  public getShowCreatedAppointmentFeedbackDrawer(): boolean {
    return this.getFlagValue<boolean>(
      "exibir-drawer-de-feedback-ao-criar-um-agendamento",
      DefaultFeatureFlagsValue["exibir-drawer-de-feedback-ao-criar-um-agendamento"],
    );
  }

  public getShowFeedbackCaptureDesiredTime(): boolean {
    return this.getFlagValue<boolean>(
      "captura-feedback-sobre-horario-desejado",
      DefaultFeatureFlagsValue["captura-feedback-sobre-horario-desejado"],
    );
  }

  public getShowMembersRecommendationForPatientsButtons(): boolean {
    return this.getFlagValue<boolean>(
      "exibe-botoes-de-indicacao-de-membros-para-pacientes",
      DefaultFeatureFlagsValue["exibe-botoes-de-indicacao-de-membros-para-pacientes"],
    );
  }

  public getShowAppointmentDurationSuggestion(): boolean {
    return this.getFlagValue<boolean>(
      "exibe-sugestao-de-duracao-para-tipo-de-atendimento-no-app",
      DefaultFeatureFlagsValue[
        "exibe-sugestao-de-duracao-para-tipo-de-atendimento-no-app"
      ],
    );
  }
  public getAllow15MinutesAppointment(): boolean {
    return this.getFlagValue<boolean>(
      "permite-atendimentos-de-ate-15-minutos",
      DefaultFeatureFlagsValue["permite-atendimentos-de-ate-15-minutos"],
    );
  }

  public getReservedScheduleTypeByUser(): number {
    return this.getFlagValue<number>(
      "modalidade-periodo-garantido-por-usuario",
      DefaultFeatureFlagsValue["modalidade-periodo-garantido-por-usuario"],
    );
  }

  public getAppointmentTypeMaxDurationInMinutes(): number {
    return this.getFlagValue<number>(
      "duracao-maxima-do-tipo-de-atendimento-em-minutos",
      DefaultFeatureFlagsValue["duracao-maxima-do-tipo-de-atendimento-em-minutos"],
    );
  }

  public getPortoAppointmentTypeDuration(): number {
    return this.getFlagValue<number>(
      "duracao-tipo-atendimento-padrao-porto",
      DefaultFeatureFlagsValue["duracao-tipo-atendimento-padrao-porto"],
    );
  }

  public async getFlexiblePriceAvailability(codUnidade: number): Promise<boolean> {
    await this.addContext({ kind: "office", key: `livance-${codUnidade}` });
    return this.getFlagValue<boolean>(
      "projeto-preco-flexivel",
      DefaultFeatureFlagsValue["projeto-preco-flexivel"],
    );
  }

  public getMinimumAntecedenceForFlexiblePriceAppointmentCreation(): number {
    return this.getFlagValue<number>(
      "antecedencia-minima-para-criacao-de-agendamento-com-preco-flexivel",
      DefaultFeatureFlagsValue[
        "antecedencia-minima-para-criacao-de-agendamento-com-preco-flexivel"
      ],
    );
  }
  public getEnableFullPlansForPsico(): boolean {
    return this.getFlagValue<boolean>(
      "libera-planos-full-para-psico",
      DefaultFeatureFlagsValue["libera-planos-full-para-psico"],
    );
  }
  public getShowReferralPage(): boolean {
    return this.getFlagValue<boolean>(
      "exibir-indique-e-ganhe",
      DefaultFeatureFlagsValue["exibir-indique-e-ganhe"],
    );
  }
  public getShowPlanMigrationPage(): boolean {
    return this.getFlagValue<boolean>(
      "exibe-migracao-de-plano-no-app",
      DefaultFeatureFlagsValue["exibe-migracao-de-plano-no-app"],
    );
  }

  public getShowTreatedAppointmentInWaitingRoom(): boolean {
    return this.getFlagValue<boolean>(
      "exibe-marcar-paciente-como-atendido-na-sala-de-espera",
      DefaultFeatureFlagsValue["exibe-marcar-paciente-como-atendido-na-sala-de-espera"],
    );
  }

  public getShowAppointmentsAndCloseSchedulesDuringSchedule(): boolean {
    return this.getFlagValue<boolean>(
      "mostra-agendamentos-e-fechamentos-na-busca-de-horario",
      DefaultFeatureFlagsValue["mostra-agendamentos-e-fechamentos-na-busca-de-horario"],
    );
  }

  public getUseNewMutationReceiveRoomNow(): boolean {
    return this.getFlagValue<boolean>(
      "atualiza-mutation-receber-sala-agora-no-app-novo",
      DefaultFeatureFlagsValue["atualiza-mutation-receber-sala-agora-no-app-novo"],
    );
  }

  public getShowDisconnectedErrorDrawer(): boolean {
    return this.getFlagValue<boolean>(
      "exibe-drawer-de-erro-de-conexao",
      DefaultFeatureFlagsValue["exibe-drawer-de-erro-de-conexao"],
    );
  }

  public getMinimalAppVersion(): Version {
    const flagValue: string = this.getFlagValue<string>(
      "minimal-app-version",
      DefaultFeatureFlagsValue["minimal-app-version"],
    );
    return new Version(flagValue);
  }

  public getIncludesFlexRoomInMultiAppointment(): boolean {
    return this.getFlagValue<boolean>(
      "inclui-salas-flex-em-tipos-de-atendimento-que-aceitam-apenas-multi",
      DefaultFeatureFlagsValue[
        "inclui-salas-flex-em-tipos-de-atendimento-que-aceitam-apenas-multi"
      ],
    );
  }
}
