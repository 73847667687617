import { CpsBadge } from "corpus";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { format } from "date-fns";
import {
  formatCurrencyFromCentsToReais,
  formatWithZonedDate,
  removeSecondsFromTimeString,
} from "@repo/lib";
import { useAtomValue } from "jotai";
import { Item, ItemContent, ItemMainContent } from "@/components/list";
import { amountVisibilityAtom } from "@/lib/atoms/amount-visibility";

export const PatientPaymentsListItemFragment = graphql(/* GraphQL */ `
  fragment PatientPaymentsListItemFragment on ExpensumGetPaymentInfo {
    pago
    origem
    meioPagamentoSelecionado
    valor
    criadoEm
    agendamento {
      data
      horaInicio
      unidade
      paciente {
        nome
      }
    }
    checkout {
      guid
      nomeDoPagador
      emailDoPagador
      telefoneDoPagador
    }
    parcelas
    acquirerTransactionKey
  }
`);

interface PatientPaymentListItemProps {
  data: FragmentType<typeof PatientPaymentsListItemFragment> | null;
}

export const PatientPaymentListItem = ({
  data,
}: PatientPaymentListItemProps): JSX.Element => {
  const fragmentResult = useFragment(PatientPaymentsListItemFragment, data);

  const isAmountVisible = useAtomValue(amountVisibilityAtom);

  const buildPaymentMethodInformation = (): string => {
    const installments = fragmentResult?.parcelas;
    const paymentType = fragmentResult?.meioPagamentoSelecionado;

    const buildCreditLabel = (): string => {
      if (!installments) return "Crédito";

      return `Crédito ${installments}x`;
    };

    const paymentMethodsDictionary: Record<string, string> = {
      credito: buildCreditLabel(),
      debito: "Débito",
      pix: "PIX",
    };

    return paymentMethodsDictionary[paymentType ?? ""] ?? "";
  };

  const paymentMethodInfo = buildPaymentMethodInformation();

  const buildAmountText = (): string => {
    const amount = fragmentResult?.valor;

    if (!isAmountVisible) {
      return "R$ \u2022\u2022\u2022\u2022\u2022";
    }

    return amount ? formatCurrencyFromCentsToReais(amount) : "R$ 0,00";
  };

  const buildBadge = (): JSX.Element => {
    const pago = fragmentResult?.pago;

    const text = pago ? "Pago" : "Em Aberto";
    const color = pago ? "success" : "helper";

    return (
      <CpsBadge data-align="end" color={color}>
        {text}
      </CpsBadge>
    );
  };

  const buildDateInformation = (): string => {
    const agendamento = fragmentResult?.agendamento;

    if (agendamento) {
      const date = agendamento.data;
      const horaInicio = agendamento.horaInicio;

      const formattedData = date
        ? format(new Date(`${date}T00:00:00-03:00`), "dd/MM/yyyy")
        : "";

      const formattedTime = horaInicio ? removeSecondsFromTimeString(horaInicio) : "";

      return `Agendamento ${formattedData} ${formattedTime}`;
    }

    if (fragmentResult?.criadoEm) {
      const criadoEm = fragmentResult.criadoEm;
      return `Pagamento criado em ${formatWithZonedDate(criadoEm, "dd/MM/yyyy HH:mm")}`;
    }

    return "";
  };

  return (
    <Item
      linkProps={{
        to: "/settings/payments/patient-payments/details",
        search: {
          payment: {
            ...fragmentResult,
            meioPagamentoSelecionado: paymentMethodInfo,
          },
        },
      }}
    >
      <ItemContent>
        <p className="text-xs text-gray-600 mb-1">{paymentMethodInfo}</p>
        <ItemMainContent className="mb-2">{buildAmountText()}</ItemMainContent>
        <p className="text-xs font-medium text-gray-800">
          {fragmentResult?.agendamento?.paciente.nome ?? ""}
        </p>
        <p className="text-xs text-gray-600 mb-1">{buildDateInformation()}</p>
      </ItemContent>
      {buildBadge()}
    </Item>
  );
};
