import { Capacitor } from "@capacitor/core";
import { captureException as captureExceptionCapacitor } from "@sentry/capacitor";
import { captureException } from "@sentry/react";

export const logError = (err: unknown, extra?: Record<string, unknown>): void => {
  if (Capacitor.isNativePlatform()) {
    captureExceptionCapacitor(err, extra);
  } else {
    captureException(err, extra);
  }
};
