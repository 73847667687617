import { z } from "zod";
import { graphql } from "@repo/graphql-types";
import { HelpCircle, Left } from "@repo/icons";
import {
  zonedDate,
  getDateFromToday,
  convertDateTimeIgnoringTimezone,
  formatDateAndTimeIgnoringTimezone,
} from "@repo/lib";
import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { useGraphQL } from "@/hooks/use-graphql";
import { PatientPaymentsList } from "@/components/patient-payments-list";
import { Page } from "@/components/page";
import { PatientPaymentInformationDrawer } from "@/components/patient-payment-information-drawer";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { PaymentFilterForm } from "@/components/payment-filter-form";
import {
  PaymentsActionButtons,
  PaymentsContent,
  PaymentsRoot,
} from "@/components/payments-root";
import { AmountTitle } from "@/components/amount-title";

const PatientsPaymentsQuery = graphql(`
  query PatientsPaymentsQuery($intervalStart: String!, $intervalEnd: String!) {
    patientPayments: ExpensumGetPayments(
      arg1: { intervalStart: $intervalStart, intervalEnd: $intervalEnd }
    ) {
      totalAmount
      ...PatientPaymentsListFragment
    }
  }
`);

const today = zonedDate(new Date());
const last7Days = getDateFromToday(-6); // menos um dia pois conta o dia de hoje

const patientPaymentsPageSearchParams = z.object({
  back: z.number().optional(),
  filter: z.boolean().optional(),
  about: z.boolean().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
});

export const PatientPaymentsPage = (): JSX.Element => {
  const searchParams = useSearch({
    from: "/settings/payments/patient-payments/",
  });

  const buildInterval = (): { intervalStart: string; intervalEnd: string } => {
    const intervalStart = searchParams.startDate ?? searchParams.endDate ?? last7Days;
    const intervalEnd = searchParams.endDate ?? searchParams.startDate ?? today;

    return {
      intervalStart: formatDateAndTimeIgnoringTimezone(
        intervalStart,
        undefined,
        "yyyy-MM-dd",
      ),
      intervalEnd: formatDateAndTimeIgnoringTimezone(
        intervalEnd,
        undefined,
        "yyyy-MM-dd",
      ),
    };
  };

  const { intervalStart, intervalEnd } = buildInterval();

  const router = useRouter();

  const setOpenDrawer = (open: boolean, paramName: "filter" | "about"): void => {
    const search: z.infer<typeof patientPaymentsPageSearchParams> = {
      ...searchParams,
      filter: undefined,
      about: undefined,
    };

    if (open) {
      search[paramName] = open;

      void router.navigate({ search });
    } else if (searchParams[paramName]) {
      router.history.back();
    }
  };

  const queryData = useGraphQL(PatientsPaymentsQuery, {
    intervalStart,
    intervalEnd,
  });

  const { data } = queryData;

  const navigateBack = (): void => {
    const backSteps = searchParams.back ?? 0;
    const historySteps = -1 - backSteps;

    router.history.go(historySteps);
  };

  const setOpenFilter = (open: boolean): void => {
    setOpenDrawer(open, "filter");
  };

  const setOpenAbout = (open: boolean): void => {
    setOpenDrawer(open, "about");
  };

  const paymentFilterValue = {
    from: convertDateTimeIgnoringTimezone(intervalStart),
    to: convertDateTimeIgnoringTimezone(intervalEnd),
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={navigateBack} icon={Left} align="start" />
        <HeaderTitle title="Pagamentos" />
        <HeaderButton
          align="end"
          icon={HelpCircle}
          onClick={() => {
            setOpenDrawer(true, "about");
          }}
        />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryData}>
          <PaymentsRoot>
            <AmountTitle
              title="Valor total"
              amount={data?.patientPayments?.totalAmount ?? 0}
            />
            <PaymentsActionButtons>
              <PaymentFilterForm
                open={Boolean(searchParams.filter)}
                setOpenFilter={setOpenFilter}
                value={paymentFilterValue}
              />
            </PaymentsActionButtons>
            <PaymentsContent>
              {data?.patientPayments ? (
                <PatientPaymentsList data={data.patientPayments} />
              ) : null}
            </PaymentsContent>
          </PaymentsRoot>

          <PatientPaymentInformationDrawer
            open={Boolean(searchParams.about)}
            setOpen={setOpenAbout}
          />
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/patient-payments/")({
  component: PatientPaymentsPage,
  validateSearch: patientPaymentsPageSearchParams,
});
