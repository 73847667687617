import { Check } from "@repo/icons";
import { cn } from "@repo/lib";
import {
  createContext,
  useContext,
  type HTMLAttributes,
  type InputHTMLAttributes,
  useState,
  forwardRef,
} from "react";

interface PlanMigrationSelectorRootProps {
  children: React.ReactElement<PlanMigrationSelectorListProps>;
  name: string;
  onChange?: (value: string) => void;
}

export const PlanMigrationSelectorRoot = ({
  children,
  name,
  onChange,
}: PlanMigrationSelectorRootProps): JSX.Element => {
  const [checkedValue, setCheckedValue] = useState("");

  const handleOnChange = (value: string): void => {
    setCheckedValue(value);

    onChange && onChange(value);
  };

  return (
    <PlanMigrationSelectorContext.Provider
      value={{ name, onChange: handleOnChange, checkedValue }}
    >
      <div className="flex flex-col gap-1">{children}</div>
    </PlanMigrationSelectorContext.Provider>
  );
};

interface PlanMigrationSelectorListProps extends HTMLAttributes<HTMLDivElement> {
  children:
    | React.ReactElement<PlanMigrationSelectorItemProps>[]
    | React.ReactElement<PlanMigrationSelectorItemProps>;
}

export const PlanMigrationSelectorList = ({
  children,
}: PlanMigrationSelectorListProps): JSX.Element => {
  return <div className="bg-white rounded-30 flex flex-col gap-2">{children}</div>;
};

interface PlanMigrationSelectorItemProps extends InputHTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
  data: { valor: number; nome: string; temFidelidade: boolean };
  disabled: boolean;
  displayName: string;
  benefits: string[];
}

export const PlanMigrationSelectorItem = forwardRef<
  HTMLInputElement,
  PlanMigrationSelectorItemProps
>(
  (
    { isChecked, data, disabled, displayName, benefits, ...props },
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const { name, onChange } = usePlanMigrationSelectorContext();

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      onChange && onChange(e.target.value, e.target.checked);
    };

    return (
      <label
        className={cn(
          "relative flex flex-row items-center border border-neutral-100 py-4 px-6 gap-4 cursor-pointer group rounded-lg",
          disabled && "bg-neutral-50 border-neutral-100",
          isChecked && "border-secondary-200",
        )}
        htmlFor={props.id}
      >
        <div className="relative flex items-center justify-center">
          <input
            type="checkbox"
            className="bg-white w-[20px] h-[20px] border-[2px] border-neutral-200 rounded-full group-has-[:checked]:bg-secondary-400 group-has-[:checked]:border-secondary-400 appearance-none flex items-center justify-center focus:outline-none"
            name={name}
            onChange={handleOnChange}
            checked={isChecked}
            ref={ref}
            {...props}
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group group-has-[:checked]:bg-white group-has-[:checked]:w-[6px] group-has-[:checked]:h-[6px] group-has-[:checked]:rounded-lg" />
        </div>
        <span className="group-has-[:checked]:text-neutral-600 text-neutral-500 font-medium w-full">
          <div className="flex flex-col w-full justify-center items-stretch">
            <div className="flex flex-row justify-between items-start">
              <div className="flex flex-col gap-1">
                <div
                  className={cn(
                    "text-neutral-600 font-medium text-md",
                    disabled && "text-neutral-500",
                  )}
                >
                  {displayName}
                </div>
                <div
                  className={cn(
                    "text-neutral-600 font-semibold text-2xl",
                    disabled && "text-neutral-500",
                  )}
                >
                  R$ {data.valor}
                  <span className="text-neutral-500 font-normal text-xs">/mês</span>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center text-primary font-medium text-xs bg-neutral-100 py-1 px-2 rounded-xl">
                {data.temFidelidade ? "Anual" : "Mensal"}
              </div>
            </div>
            <div className="flex flex-col gap-1 mt-2">
              {benefits.map((benefit) => (
                <div
                  key={`${data.nome}-${benefit.length}`}
                  className="flex flex-row text-neutral-500 font-normal text-xs gap-1"
                >
                  <Check size={16} className="fill-secondary-500 shrink-0" />
                  {benefit}
                </div>
              ))}
            </div>
          </div>
        </span>
      </label>
    );
  },
);

PlanMigrationSelectorItem.displayName = "PlanMigrationSelectorItem";

interface PlanMigrationSelectorContextProps {
  name?: string;
  checkedValue?: string;
  onChange?: (value: string, isChecked: boolean) => void;
}

const PlanMigrationSelectorContext = createContext<PlanMigrationSelectorContextProps>({});

const usePlanMigrationSelectorContext = (): PlanMigrationSelectorContextProps =>
  useContext(PlanMigrationSelectorContext);
