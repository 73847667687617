import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { SplashScreen } from "@capacitor/splash-screen";
import { resetAppInfoCache } from "@repo/lib";

export const initAutoUpdate = (): void => {
  const updateReady = (): void => {
    void SplashScreen.hide();
    resetAppInfoCache();
  };

  void CapacitorUpdater.addListener("appReady", updateReady);

  void CapacitorUpdater.notifyAppReady();
};
