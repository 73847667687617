import { type DayEvents } from "@repo/lib";
import { dateDiff, TimeType } from "@/lib/date";
import {
  createGenericDayEvent,
  createHatchedDayEvent,
} from "@/lib/day-event/day-event-factory";

/**
 * Quebra ums lista de eventos removendo slots de horários onde
 * existem outros eventos. A entrada são dois arrays de eventos, um
 * contendo os eventos originais, que irão gerar os novos eventos, e
 * o outro contendo os eventos com os slots de horários que serão removidos
 * dos eventos originais.
 * A saída é um novo array de eventos contendo os novos horários dos eventos
 * originais.
 *
 * Exemplo:
 *         Entrada (eventos originais):  [----------------] [-----------------]
 *                  (slots subtraidos):        [###]     [######]    [####]
 *
 *         Saída       (novos eventos):  [-----]   [-----]      [----]    [---]
 *
 * @param originalEvents - Array de eventos originais.
 * @param eventsToSubtract - Array de eventos com slots a serem removidos.
 * @returns Um novo array de de eventos com os horários removidos.
 */
export const subtractEvents = (
  originalEvents: DayEvents[],
  eventsToSubtract: DayEvents[],
): DayEvents[] => {
  const newEvents: DayEvents[] = [];

  const originalEventsCopy = [...originalEvents];
  const eventsToSubtractCopy = [...eventsToSubtract];

  originalEventsCopy.sort((a, b) => a.start.getTime() - b.start.getTime());
  eventsToSubtractCopy.sort((a, b) => a.start.getTime() - b.start.getTime());

  originalEventsCopy.forEach((originalEvent) => {
    eventsToSubtractCopy.forEach((eventToSubtract, index) => {
      if (
        eventToSubtract.start < originalEvent.end &&
        eventToSubtract.end > originalEvent.start
      ) {
        if (eventToSubtract.start > originalEvent.start) {
          newEvents.push(
            createGenericDayEvent({
              id: originalEvent.start.getTime().toString(),
              type: originalEvent.type,
              title: "",
              unit: originalEvent.unit,
              start: originalEvent.start,
              end: eventToSubtract.start,
              appointmentType: originalEvent.appointmentType,
              timeSlotType: originalEvent.timeSlotType,
              appointmentPurposeId: originalEvent.appointmentPurposeId,
            }),
          );
        }
        if (
          eventToSubtract.end < originalEvent.end &&
          index === eventsToSubtractCopy.length - 1
        ) {
          newEvents.push(
            createGenericDayEvent({
              id: eventToSubtract.end.getTime(),
              type: originalEvent.type,
              title: "",
              unit: originalEvent.unit,
              start: eventToSubtract.end,
              end: originalEvent.end,
              appointmentType: originalEvent.appointmentType,
              timeSlotType: originalEvent.timeSlotType,
              appointmentPurposeId: originalEvent.appointmentPurposeId,
            }),
          );
        }
        originalEvent.start = eventToSubtract.end;
      }
    });

    if (originalEvent.start < originalEvent.end) {
      newEvents.push(
        createGenericDayEvent({
          id: originalEvent.start.getTime(),
          type: originalEvent.type,
          title: "",
          unit: originalEvent.unit,
          start: originalEvent.start,
          end: originalEvent.end,
          appointmentType: originalEvent.appointmentType,
          timeSlotType: originalEvent.timeSlotType,
          appointmentPurposeId: originalEvent.appointmentPurposeId,
        }),
      );
    }
  });

  return newEvents;
};

/**
 * Recebe um array de eventos e retorna um array unindo eventos que se sobrepõem.
 *
 * @param events - Array de eventos a ser unido.
 * @returns Array de eventos unidos.
 */
export const mergeDayEvents = (events: DayEvents[]): DayEvents[] => {
  if (events.length === 0) return [];

  events.sort((a, b) => a.start.getTime() - b.start.getTime());

  const mergedEvents = [events[0]];

  for (let i = 1; i < events.length; i++) {
    const lastMergedEvent = mergedEvents[mergedEvents.length - 1];
    const actualMergingEvent = events[i];

    if (lastMergedEvent.end >= actualMergingEvent.start) {
      mergedEvents[mergedEvents.length - 1].end = new Date(
        Math.max(lastMergedEvent.end.getTime(), actualMergingEvent.end.getTime()),
      );
    } else {
      mergedEvents.push(actualMergingEvent);
    }
  }

  return mergedEvents;
};

/**
 * Recebe um array de eventos e um dia e cria eventos vazios
 * para preencher os espaços do dia que não possuem eventos.
 *
 * @param events - Array de eventos do dia.
 * @param currentData - Dia dos eventos.
 * @param minSlotTimeInMinutes - Tamanho mínimo que um slot deve ter para
 * ser incluido, o valor default é 6 minutos.
 * @param dayStartHour - Horario de inicio para os eventos serem criados,
 * o valor default é 00:01.
 * @param dayEndHour - Horario de término para os eventos serem criados,
 * o valor default é 23:59.
 * @returns Array de eventos vazios.
 */
export const createHatchedEventsInEmptySpaces = (
  existingEvents: DayEvents[],
  currentDate: Date,
  minSlotTimeInMinutes = 6,
  dayStartHour?: Date,
  dayEndHour?: Date,
): DayEvents[] => {
  const allDayBlocked = [
    createHatchedDayEvent({
      id: "all-day",
      start: dayStartHour ?? new Date(currentDate.setHours(0, 0, 1, 0)),
      end: dayEndHour ?? new Date(currentDate.setHours(23, 59, 59, 999)),
    }),
  ];
  const allDayBroken = subtractEvents(allDayBlocked, existingEvents);

  const allDayMerged = mergeDayEvents(allDayBroken);

  // O filtro é necessário para não quebrar a exibição do calendário. Quando o slot possui 5 minutos o
  // calendário aumenta o tamanho do slot e sobrepõe outros eventos na grade mesmo sem estar no mesmo horário.
  return allDayMerged.filter((slot) => {
    const duration = dateDiff(new Date(slot.start), new Date(slot.end), TimeType.Minutes);
    return duration >= minSlotTimeInMinutes;
  });
};
