interface ProgressBarProps {
  progress: number;
}

export const ProgressBar = ({ progress }: ProgressBarProps): JSX.Element => {
  const styles = {
    progress: {
      width: `${progress * 100}%`,
    },
  };

  return (
    <div data-testid="full-progress-bar" className="my-1 rounded w-full h-2 bg-white/25">
      <div
        data-testid="actual-progress-bar"
        style={styles.progress}
        className="transition-all duration-200 bg-white h-full rounded w-[50%] max-w-full"
      />
    </div>
  );
};
