import { useState, useCallback } from "react";
import { debounce } from "lodash";
import { graphql } from "@repo/graphql-types/gql";
import { type MembroPerfilPublico } from "@repo/graphql-types/graphql";
import { useSetAtom } from "jotai/index";
import { useRouter, useSearch } from "@tanstack/react-router";
import { useGraphQL } from "@/hooks/use-graphql";
import { recommendationMemberAtom } from "@/lib/atoms/recommendation-member-atom.ts";
import { ListRoot } from "@/components/list";
import { MembersList } from "@/components/members-list";
import { SearchInputWithMinLength } from "@/components/search-input-with-min-length.tsx";

export const RecommendationMembersForPatientQuery = graphql(/* GraphQL */ `
  query RecommendationMembersForPatientQuery($nomeMembro: String!) {
    perfisPublicos(first: 100, nomeMembro: $nomeMembro) {
      ...MembersListFragment
    }
  }
`);

export const RecommendationMembersForPatientSearch = (): JSX.Element => {
  const router = useRouter();
  const params = useSearch({ from: "/recommendation/" });
  const [memberName, setMemberName] = useState("");
  const setMemberAtomValues = useSetAtom(recommendationMemberAtom);

  const debouncedFunction = debounce((name: string) => setMemberName(name), 500);

  const debouncedSetMemberName = useCallback(
    (name: string) => {
      debouncedFunction(name);
    },
    [debouncedFunction],
  );

  const queryResult = useGraphQL(
    RecommendationMembersForPatientQuery,
    {
      nomeMembro: memberName,
    },
    {
      enabled: memberName.length >= 3,
    },
  );

  const { data } = queryResult;

  const handleClickItem = (member?: MembroPerfilPublico | null): void => {
    if (member) {
      setMemberAtomValues({
        id: member.codUsuario,
        name: member.nome ?? "-",
        specialty: member.especialidades?.[0]?.nome ?? "-",
      });

      void router.navigate({
        to: "/recommendation/confirmation",
        search: {
          patientId: params.patientId,
        },
      });
    }
  };

  return (
    <div>
      <SearchInputWithMinLength
        onSearch={debouncedSetMemberName}
        placeholder="Digite aqui"
        title="Busque o nome do profissional"
      />
      <ListRoot>
        {data?.perfisPublicos ? (
          <MembersList
            memberNameSearch={memberName}
            data={data.perfisPublicos}
            handleClickItem={handleClickItem}
          />
        ) : null}
      </ListRoot>
    </div>
  );
};
