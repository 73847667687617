import { App as CapApp, type AppInfo } from "@capacitor/app";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { iosOrAndroid } from "./config";

let cachedAppInfo: AppInfo | null = null;
let lastFetchTime = 0;

export const getAppInfo = async (): Promise<AppInfo> => {
  // Só funciona em dispositivos móveis
  if (iosOrAndroid) {
    if (cachedAppInfo && Date.now() - lastFetchTime < 1000 * 60 * 5) {
      return cachedAppInfo;
    }
    const bundledPackageInfo = await CapApp.getInfo();
    const updaterValue = await CapacitorUpdater.current();

    if (
      updaterValue.bundle.version !== "builtin" &&
      updaterValue.bundle.status === "success"
    ) {
      bundledPackageInfo.version = updaterValue.bundle.version;
    }

    lastFetchTime = Date.now();
    return bundledPackageInfo;
  }

  return {
    id: "br.com.livance",
    version: __APP_VERSION__,
    build: "web",
    name: "Livance",
  };
};

export const resetAppInfoCache = (): void => {
  cachedAppInfo = null;
};
