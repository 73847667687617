import { Clock } from "@repo/icons";
import { useEffect, useState } from "react";
import { motion, useCycle } from "framer-motion";
import { AppointmentSlotSearchSelector } from "@/components/appointment-slot-search-selector";

interface AppointmentFabProps {
  setSelectedTime: (selectedTime: { hour: number; minute: number }) => void;
  shouldAllowEveryHour: boolean;
}

export const AppointmentFab = ({
  setSelectedTime,
  shouldAllowEveryHour,
}: AppointmentFabProps): JSX.Element => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isOpen, toggleOpen] = useCycle(true, false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const containerEvents = document.getElementById("page-create-appointment-id");

    const handleScroll = (): void => {
      if (!containerEvents) return;
      const { scrollTop } = containerEvents;
      if (scrollTop > lastScrollTop && isOpen) {
        // Scroll Down
        toggleOpen();
      } else if (scrollTop <= lastScrollTop && !isOpen) {
        // Scroll Up
        toggleOpen();
      }
      setLastScrollTop(Math.max(0, scrollTop));
    };

    if (containerEvents) {
      containerEvents.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerEvents) {
        containerEvents.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastScrollTop, isOpen, toggleOpen]);

  const containerVariants = {
    closed: {
      width: "3.5rem",
      height: "3.5rem",
      padding: "1rem",
      justifyContent: "center",
      opacity: 1,
      transition: {
        duration: 0.1,
      },
    },
    open: {
      width: "16.5rem",
      height: "3.5rem",
      padding: "1rem",
      justifyContent: "space-between",
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const labelVariants = {
    open: {
      display: "flex",
      scale: 1,
      width: "12.5rem",
      opacity: 1,
      transition: {
        duration: 0.2,
        delay: 0.07,
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "0rem",
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
  };

  return (
    <>
      <motion.button
        variants={containerVariants}
        initial="open"
        animate={isOpen ? "open" : "closed"}
        type="button"
        className="fixed bottom-[2rem] right-4 rounded-full bg-secondary-400 flex items-center shadow-md z-50"
        onClick={() => setOpenDrawer(true)}
      >
        <Clock size={24} className="fill-white" />
        <motion.label
          animate={isOpen ? "open" : "closed"}
          variants={labelVariants}
          initial="open"
          className="text-neutral-50 whitespace-nowrap"
        >
          Buscar horário específico
        </motion.label>
      </motion.button>

      <AppointmentSlotSearchSelector
        open={openDrawer}
        setOpen={setOpenDrawer}
        setSelectedTime={setSelectedTime}
        shouldAllowEveryHour={shouldAllowEveryHour}
      />
    </>
  );
};
