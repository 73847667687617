import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { BillingItemDetails } from "@/components/billing-item-details";

export const BillingDetailsQuery = graphql(/* GraphQL */ `
  query BillingDetailsQuery($billingItemId: Int!) {
    billingItem: movimentacaoDetalhada(codMovimentacao: $billingItemId) {
      ...BillingItemDetailsQueryFragment
    }
  }
`);

export const AllocationDetailsQuery = graphql(/* GraphQL */ `
  query AllocationDetailsQuery($codAgendamento: Int!) {
    allocation: alocacaoDetalhada(codAgendamento: $codAgendamento) {
      ...AllocationDetailsQueryFragment
    }
  }
`);

export const AppointmentDetailsQuery = graphql(/* GraphQL */ `
  query AppointmentDetailsQuery($codAgendamento: Int!) {
    appointment: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      ...AppointmentDetailsQueryFragment
    }
  }
`);

export interface BillingItemRouteParams {
  appointmentId: string;
}

export const NewBillingItemDetailsPage = (): JSX.Element => {
  const { appointmentId } = Route.useSearch();
  const { billingItemId } = Route.useParams();

  const billingQueryResult = useGraphQL(BillingDetailsQuery, {
    billingItemId: Number(billingItemId),
  });
  const allocationQueryResult = useGraphQL(AllocationDetailsQuery, {
    codAgendamento: Number(appointmentId),
  });
  const appointmentQueryResult = useGraphQL(AppointmentDetailsQuery, {
    codAgendamento: Number(appointmentId),
  });
  const { data: billingItemResult } = billingQueryResult;
  const { data: allocationResult } = allocationQueryResult;
  const { data: appointmentResult } = appointmentQueryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Histórico de uso" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...appointmentQueryResult}>
          {billingItemResult?.billingItem &&
          appointmentResult?.appointment ? (
            <BillingItemDetails
              billingData={billingItemResult.billingItem}
              allocationData={allocationResult?.allocation}
              appointmentData={appointmentResult.appointment}
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/billing/$billingItemId")({
  component: NewBillingItemDetailsPage,
});
