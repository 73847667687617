import { CpsAlert } from "corpus";

interface FlexiblePriceAlertProps {
  message: string;
}

export const FlexiblePriceAlert = ({
  message,
}: FlexiblePriceAlertProps): JSX.Element | null => {
  return (
    <CpsAlert
      title={message}
      description=""
      type="info"
      link="https://ajuda.livance.com.br/hc/pt-br/articles/30667869930515-Agendamento-com-cancelamento-com-at%C3%A9-3-dias-de-anteced%C3%AAncia"
      linkTitle="Saiba mais"
      linkPosition="bottom"
      linkProps={{ target: "_blank" }}
    />
  );
};
