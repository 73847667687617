import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import type { BillingListQueryFragmentFragment } from "@repo/graphql-types/graphql";
import { useMemo, useState } from "react";
import { BillingFooter } from "@/components/billing-footer";
import { BillingHeader } from "@/components/billing-header";
import type { BillingItemQueryFragment } from "@/components/billing-item";
import { BillingItemList } from "@/components/billing-item-list";

export const BillingListQueryFragment = graphql(/* GraphQL */ `
  fragment BillingListQueryFragment on Billing {
    codCobranca
    valor
    dataVencimento
    movimentacoes {
      ...BillingListItemQueryFragment
    }
  }
`);

export const BillingList = (props: {
  billingList: FragmentType<typeof BillingListQueryFragment>[];
}): JSX.Element => {
  const billingListFragment = useFragment(BillingListQueryFragment, props.billingList);

  const billingList = useMemo(
    () => billingListFragment as BillingListQueryFragmentFragment[],
    [billingListFragment],
  );
  const [currentBillingIndex, setCurrentBillingIndex] = useState<number>(0);

  const [billingItens, setBillingItens] = useState<
    FragmentType<typeof BillingItemQueryFragment>[]
  >(
    billingList[currentBillingIndex].movimentacoes as FragmentType<
      typeof BillingItemQueryFragment
    >[],
  );

  const showBillingItensByBilling = (billingIndex: number): void => {
    setCurrentBillingIndex(billingIndex);
    setBillingItens(
      billingList[billingIndex].movimentacoes as FragmentType<
        typeof BillingItemQueryFragment
      >[],
    );
  };

  return (
    <div className="mx-auto w-full h-full overflow-hidden">
      <BillingHeader
        billingList={billingList}
        currentBillingIndex={currentBillingIndex}
        showBillingItensByBilling={showBillingItensByBilling}
      />
      <div className="h-full overflow-y-scroll scrollbar-hidden">
        <BillingItemList
          billingItens={billingItens}
          currentBillingIndex={currentBillingIndex}
        />
      </div>
      <BillingFooter total={billingList[currentBillingIndex]?.valor} />
    </div>
  );
};
