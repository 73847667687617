import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { ReservedScheduleStartingDayForm } from "@/components/reserved-schedule-starting-day-form";

export const ReservedScheduleStartingDayPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Novo período garantido" align="center" />
      </HeaderRoot>
      <Page>
        <ReservedScheduleStartingDayForm />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/reserved-schedules/create/starting-day")({
  component: ReservedScheduleStartingDayPage,
});
