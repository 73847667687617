import { CircleCheck } from "@repo/icons";
import { useSearch } from "@tanstack/react-router";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

interface CreatedAppointmentDrawerProps {
  title: string;
  description: string;
  confirmButtonText: string;
  setOpen: (open: boolean) => void;
}

export const CreatedAppointmentDrawer = ({
  title,
  description,
  confirmButtonText,
  setOpen,
}: CreatedAppointmentDrawerProps): JSX.Element => {
  const searchParams: { action: "success" | "error" } = useSearch({ strict: false });

  return (
    <MessageDrawerRoot
      open={searchParams.action === "success"}
      setOpen={setOpen}
      icon={CircleCheck}
      variant="secondary"
    >
      <MessageDrawerTitle>{title}</MessageDrawerTitle>
      <MessageDrawerBody>{description}</MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton onClick={() => setOpen(false)}>
          {confirmButtonText}
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
