import { createFileRoute } from "@tanstack/react-router";
import { DoorExit } from "@repo/icons";
import type { RootRouteContext } from "@/routes/-root-context";
import { HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state.tsx";
import { logout } from "@/lib/logout.ts";

const beforeLoad = async (context: RootRouteContext): Promise<void> => {
  await logout(context.authClient, context.queryClient);
};

export const LogoutPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Livance" align="center" />
      </HeaderRoot>
      <Page>
        <EmptyStateRoot>
          <EmptyStateIcon icon={DoorExit} />
          <EmptyStateTitle>Saindo do seu consultório</EmptyStateTitle>
          <EmptyStateDescription>
            Estamos efetuando o logout. Por favor aguarde.
          </EmptyStateDescription>
        </EmptyStateRoot>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/auth/logout")({
  component: LogoutPage,
  beforeLoad: async ({ context }) => {
    await beforeLoad(context);
  },
});
