export class Holiday {
  constructor(
    public endTime: string,
    public startTime: string,
    public unitId?: number,
    public day?: number,
    public month?: number,
    public year?: number,
  ) {}

  doesHolidayCloseGivenUnit(unitId: number): boolean {
    return this.isHolidayAllDay() && this.isValidForGivenLocation(unitId);
  }

  isHolidayAllDay(): boolean {
    return this.startTime.startsWith("00:00") && this.endTime.startsWith("23:59");
  }

  isValidForGivenLocation(locationId: number): boolean {
    return !this.unitId || this.unitId === locationId;
  }
}
