import { type DocumentType, graphql } from "@repo/graphql-types";
import { CalendarTime, Clock, Door, Left, Link } from "@repo/icons";
import {
  createFileRoute,
  redirect,
  useLoaderData,
  useNavigate,
} from "@tanstack/react-router";
import { ReservedScheduleTypeEnum } from "@repo/lib/src/enums";
import { logError } from "@repo/lib";
import { queryFn, useGraphQLMutation } from "@/hooks/use-graphql";
import { Button } from "@/components/button";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { getIP } from "@/lib/ip-getter";
import { ReservedScheduleTermsOfUseUrl } from "@/components/reserved-schedule-terms-of-use-url";

const HasUserAcceptedTermsOfUseQuery = graphql(/* GraphQL */ `
  query HasUserAcceptedTermsOfUseQuery($codTipoTermoDeUso: Int!) {
    tbUsuariosTermosDeUso(
      where: { ativo: { _eq: true }, codTipoTermoDeUso: { _eq: $codTipoTermoDeUso } }
    ) {
      codTermosDeUso
      codUsuario
      ativo
      dataCadastro
      codTipoTermoDeUso
      dataUltimaAtualizacao
    }
  }
`);

const UpsertTermsOfUseMutation = graphql(/* GraphQL */ `
  mutation UpsertTermsOfUseMutation(
    $IP: String!
    $codTipoTermoDeUso: Int!
    $codUsuario: Int!
    $dataUltimaAtualizacao: datetime2!
    $ativo: Boolean = true
  ) {
    insert_tbUsuariosTermosDeUso_one(
      object: {
        IP: $IP
        ativo: $ativo
        codTipoTermoDeUso: $codTipoTermoDeUso
        codUsuario: $codUsuario
        dataCadastro: $dataUltimaAtualizacao
        dataUltimaAtualizacao: $dataUltimaAtualizacao
      }
      if_matched: {
        match_columns: [codUsuario, codTipoTermoDeUso]
        update_columns: [dataUltimaAtualizacao, ativo, IP]
      }
    ) {
      codTermosDeUso
      codUsuario
      codTipoTermoDeUso
      dataCadastro
      dataUltimaAtualizacao
      ativo
    }
  }
`);

export const TermsOfUsePage = (): JSX.Element => {
  const routerContext = useLoaderData({
    from: "/settings/reserved-schedules/terms-of-use/",
  });
  const reservedScheduleType = routerContext.reservedScheduleType;
  const routeContext = Route.useRouteContext();
  const navigate = useNavigate();
  const { mutate } = useGraphQLMutation(UpsertTermsOfUseMutation);

  const submitCheckedTerms = (): void => {
    const onSuccess = (): void => {
      routeContext.queryClient
        .resetQueries({ queryKey: ["HasUserAcceptedTermsOfUseQuery"] })
        .then(() => {
          void navigate({
            to: "/settings/reserved-schedules/create",
          });
        })
        .catch((err) => {
          logError(err);
        });
    };

    mutate(
      {
        IP: routerContext.memberIP,
        codUsuario: routeContext.user.codUsuario,
        codTipoTermoDeUso: reservedScheduleType,
        dataUltimaAtualizacao: new Date().toISOString(),
      },
      { onSuccess },
    );
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Período garantido" />
      </HeaderRoot>
      <Page>
        <div className="flex flex-col gap-2">
          <span className="text-xl font-medium text-neutral-600">Termos de uso</span>
          <span>
            O período garantido tem termos de uso distintos do modelo padrão da Livance.
          </span>
          <span>Confira abaixo.</span>
        </div>
        <div className="pt-10">
          <ul className="flex flex-col gap-6">
            <li className="flex gap-2">
              <div className="bg-primary-50 h-[40px] w-[40px] p-2 rounded-full self-center">
                <Door className="fill-primary-400" size={24} />
              </div>
              <div className="flex flex-col">
                <span className="text-sm font-medium">Modelo de precificação</span>
                {reservedScheduleType === ReservedScheduleTypeEnum.Livance.valueOf() && (
                  <span className="text-sm">
                    Pague o valor das horas reservadas, considerando o preço estabelecido
                    em contrato, acrescido de 20%.
                  </span>
                )}
                {reservedScheduleType !== ReservedScheduleTypeEnum.Livance.valueOf() && (
                  <span className="text-sm">
                    Pague o valor das horas reservadas, considerando o preço estabelecido
                    em contrato.
                  </span>
                )}
              </div>
            </li>
            <li className="flex gap-2">
              <div className="bg-primary-50 h-[40px] w-[40px] p-2 rounded-full self-center">
                <CalendarTime className="fill-primary-400" size={24} />
              </div>
              <div className="flex flex-col">
                <span className="text-sm font-medium">Cancelamento antecipado</span>
                <span className="text-sm">
                  Cancele um dia pontual do período sem custo com uma antecedência mínima
                  de 7 dias.
                </span>
              </div>
            </li>
            <li className="flex gap-2">
              <div className="bg-primary-50 h-[40px] w-[40px] p-2 rounded-full self-center">
                <Clock className="fill-primary-400" size={24} />
              </div>
              <div className="flex flex-col">
                <span className="text-sm font-medium">Tempo mínimo de reserva</span>
                {reservedScheduleType === ReservedScheduleTypeEnum.Livance.valueOf() && (
                  <span className="text-sm">
                    Crie períodos garantidos de no mínimo 4 horas consecutivas e com
                    frequência semanal.
                  </span>
                )}
                {reservedScheduleType !== ReservedScheduleTypeEnum.Livance.valueOf() && (
                  <span className="text-sm">
                    Crie períodos garantidos de no mínimo 1 hora consecutiva e com
                    frequência semanal ou quinzenal.
                  </span>
                )}
              </div>
            </li>
            <li className="text-left text-sm flex flex-row gap-1 items-center font-medium">
              <ReservedScheduleTermsOfUseUrl
                reservedScheduleType={reservedScheduleType}
              />
              <Link size={16} className="fill-[#0080a5]" />
            </li>
          </ul>
        </div>
        <div data-testid="submit-terms-button" className="pt-10">
          <Button onClick={submitCheckedTerms} fullWidth>
            Li e aceito os termos
          </Button>
        </div>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/reserved-schedules/terms-of-use/")({
  component: TermsOfUsePage,
  beforeLoad: ({ context }) => {
    return {
      reservedScheduleTypeByUser: context.ldClient.getReservedScheduleTypeByUser(),
    };
  },
  loader: async ({ context }) => {
    let reservedScheduleType = context.reservedScheduleTypeByUser;
    if (
      context.user.codParceiroPortoSeguro &&
      reservedScheduleType !== ReservedScheduleTypeEnum.Porto.valueOf()
    ) {
      reservedScheduleType = ReservedScheduleTypeEnum.Porto.valueOf();
    }
    const data = await context.queryClient.ensureQueryData<
      DocumentType<typeof HasUserAcceptedTermsOfUseQuery>
    >({
      queryKey: [
        "HasUserAcceptedTermsOfUseQuery",
        {
          codTipoTermoDeUso: reservedScheduleType,
        },
      ],
      queryFn: () =>
        queryFn(HasUserAcceptedTermsOfUseQuery, context.authClient, {
          codTipoTermoDeUso: reservedScheduleType,
        }),
    });
    if (data.tbUsuariosTermosDeUso.length > 0) {
      redirect({ to: "/settings/reserved-schedules/create", throw: true });
    }
    const memberIP = await getIP();
    return { reservedScheduleType, memberIP };
  },
});
