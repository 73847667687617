import { type FragmentType, useFragment } from "@repo/graphql-types/fragment-masking";
import { type LivanceApiBuscaHorarioLivreReagendamentoOutput } from "@repo/graphql-types/graphql";
import { graphql } from "@repo/graphql-types/gql";
import { useRouter } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { CpsSpinner } from "corpus";
import { useState } from "react";
import { formatIgnoringTimezone, zonedDate } from "@repo/lib";
import { MinutesSelector } from "@/components/minutes-selector";
import {
  calculateMinuteDifference,
  formatSlotsForDisplay,
  generateTimeSlots,
  type MinuteConfigsTypes,
} from "@/lib/time-slots.ts";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form.tsx";
import { useGraphQL } from "@/hooks/use-graphql.ts";
import { AsyncDataWrapper } from "@/components/async-data-wrapper.tsx";

export const ChangeTimeMinutesFragment = graphql(`
  fragment ChangeTimeMinutesFragment on agendamentos {
    codAgendamento
    horaInicio
    horaFim
  }
`);

const ChangeTimeFormQuery = graphql(/* GraphQL */ `
  query ChangeTimeFormQuery($codAgendamento: Int!, $horarios: [String!]!) {
    horarios: LivanceApiBuscaHorarioLivreReagendamento(
      codAgendamento: $codAgendamento
      horarios: $horarios
    ) {
      autorizado
      fim
      inicio
      motivo
    }
  }
`);

interface ChangeTimeMinutesFormProps {
  data: FragmentType<typeof ChangeTimeMinutesFragment>;
}

export const ChangeTimeMinutesForm = ({
  data,
}: ChangeTimeMinutesFormProps): JSX.Element => {
  const router = useRouter();
  const appointment = useFragment(ChangeTimeMinutesFragment, data);
  const { horaInicio } = appointment;
  const totalNextPages = 7;
  const slotsPerPage = 4;
  const totalPreviousPages = -5;
  const [currentPage, setCurrentPage] = useState(1);
  const timeSlots = generateTimeSlots(appointment.horaInicio, currentPage, slotsPerPage);
  const queryResultGetAvailableTimes = useGraphQL(ChangeTimeFormQuery, {
    codAgendamento: appointment.codAgendamento,
    horarios: timeSlots,
  });
  const { data: queryResultGetAvailableTimesData } = queryResultGetAvailableTimes;
  const horarios = queryResultGetAvailableTimesData?.horarios ?? [];

  const getMinutesConfigs = (
    slots: LivanceApiBuscaHorarioLivreReagendamentoOutput[] | string[],
  ): MinuteConfigsTypes[] => {
    const dateNow = formatIgnoringTimezone(zonedDate(new Date()), "yyyy-MM-dd");

    return slots
      .map((item) => {
        const inicio = typeof item === "string" ? `${dateNow}T${item}:00` : item.inicio;
        const fim = typeof item === "string" ? "" : item.fim;
        const autorizado = typeof item === "string" ? false : item.autorizado;
        const motivo = typeof item === "string" ? "" : item.motivo;

        return {
          inicio,
          fim,
          value: String(calculateMinuteDifference(inicio, horaInicio)),
          unavailable: !autorizado,
          reason:
            motivo === "não é possível agendar no passado, ele já passou :("
              ? "Não é possível alterar o horário para um momento que já passou."
              : "No momento, não temos salas disponíveis para esta ação.",
        };
      })
      .filter((item: MinuteConfigsTypes) => item.value !== "0");
  };

  const formattedMinuteConfigs: MinuteConfigsTypes[] =
    horarios.length > 0
      ? formatSlotsForDisplay(
          getMinutesConfigs(horarios as LivanceApiBuscaHorarioLivreReagendamentoOutput[]),
        )
      : formatSlotsForDisplay(getMinutesConfigs(timeSlots));

  const nextPage = (): void => {
    if (currentPage < totalNextPages) {
      setCurrentPage(currentPage + 1);
      form.reset({ selectedMinute: undefined });
    }
  };

  const previousPage = (): void => {
    if (currentPage > totalPreviousPages) {
      setCurrentPage(currentPage - 1);
      form.reset({ selectedMinute: undefined });
    }
  };

  const formSchema = z.object({
    selectedMinute: z.string({ required_error: "Campo obrigatório" }),
  });

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      selectedMinute: undefined,
    },
  });

  const handleSubmit = async (formData: FormFields): Promise<void> => {
    const selectedMinuteObj = formattedMinuteConfigs.find(
      (minuteObj) => minuteObj.value === formData.selectedMinute,
    );

    if (selectedMinuteObj?.inicio && selectedMinuteObj.fim) {
      await router.navigate({
        to: "/waiting-room/$codAgendamento/change-time/confirm",
        params: {
          codAgendamento: appointment.codAgendamento,
        },
        search: {
          horaInicio: selectedMinuteObj.inicio,
          horaFim: selectedMinuteObj.fim,
        },
      });
    }
  };

  const spinner = (
    <div className="mt-4">
      <CpsSpinner />
    </div>
  );

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={handleSubmit}>
        <div className="flex flex-col gap-8">
          <div>
            <h4 className="text-neutral-600 text-md mb-6">
              Para quantos minutos deseja alterar?
            </h4>
            <FormField
              control={form.control}
              render={({ field }) => (
                <AsyncDataWrapper {...queryResultGetAvailableTimes} fallback={spinner}>
                  <FormItem>
                    <FormControl>
                      <MinutesSelector
                        minutes={formattedMinuteConfigs}
                        onLeftArrowClick={previousPage}
                        onRightArrowClick={nextPage}
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                </AsyncDataWrapper>
              )}
              name="selectedMinute"
            />
          </div>
          <FormSubmitButton>Confirmar</FormSubmitButton>
        </div>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
