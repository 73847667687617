import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { useAtom } from "jotai";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { RescheduleAppointmentPatientForm } from "@/components/reschedule-appointment-patient-form";
import { useGraphQL } from "@/hooks/use-graphql";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";

const RescheduleAppointmentPatientPageQuery = graphql(`
  query RescheduleAppointmentPatientPageQuery(
    $codAgendamento: Int!
    $codUnidade: Int!
    $codUsuarioCompromisso: Int!
  ) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      codAgendamento
    }

    ...RescheduleAppointmentPatientFormFragment
  }
`);

const rescheduleAppointmentFlexiblePricingPageSearchSchema = z.object({
  action: z.string().optional().catch(""),
});

export const RescheduleAppointmentPatientPage = (): JSX.Element => {
  const [{ unit, appointmentType }] = useAtom(appointmentFormAtom);

  const { appointmentId } = Route.useParams();

  const queryResult = useGraphQL(RescheduleAppointmentPatientPageQuery, {
    codAgendamento: appointmentId,
    codUsuarioCompromisso: appointmentType,
    codUnidade: unit,
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Reagendamento" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data?.agendamento ? <RescheduleAppointmentPatientForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/appointment/$appointmentId/reschedule-patient")({
  component: RescheduleAppointmentPatientPage,
  validateSearch: rescheduleAppointmentFlexiblePricingPageSearchSchema,
  parseParams: (params) => ({
    appointmentId: z.number().int().parse(Number(params.appointmentId)),
  }),
  beforeLoad: ({ context }) => {
    return {
      showCreatedAppointmentFeedbackDrawer:
        context.ldClient.getShowCreatedAppointmentFeedbackDrawer(),
    };
  },
});
