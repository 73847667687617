import { Right } from "@repo/icons";
import { cn, type AlignProps } from "@repo/lib";
import { Link, type ReactNode, type LinkProps } from "@tanstack/react-router";
import { type ComponentPropsWithoutRef } from "react";

interface ItemProps extends ComponentPropsWithoutRef<"li"> {
  children: React.ReactElement<AlignProps> | React.ReactElement<AlignProps>[];
  linkProps?: LinkProps;
  externalRoute?: string;
}

export const Item = ({
  children,
  linkProps,
  externalRoute,
  ...props
}: ItemProps): JSX.Element => {
  const itemElements = Array.isArray(children) ? children : [children];

  const startItems = itemElements.filter(
    (child) => !child.props["data-align"] || child.props["data-align"] === "start",
  );

  const endItems = itemElements.filter((child) => child.props["data-align"] === "end");

  if (linkProps?.to ?? externalRoute) {
    const LinkComponent = linkProps?.to ? Link : "a";

    const linkComponentProps = linkProps?.to
      ? { ...linkProps }
      : { href: externalRoute, ...linkProps };

    return (
      <li className="py-6 w-full">
        <LinkComponent
          className="justify-between items-center flex"
          {...linkComponentProps}
        >
          <div className="flex flex-row items-center gap-4 w-full">{startItems}</div>
          <div className="flex flex-row items-center gap-4">
            {endItems}
            <Right className="shrink-0" size={24} />
          </div>
        </LinkComponent>
      </li>
    );
  }

  return (
    <li {...props} className="py-6 w-full">
      <div className="justify-between items-center flex gap-4">
        <div data-testid="startItems" className="flex flex-row items-center gap-4">
          {startItems}
        </div>
        <div data-testid="endItems" className="flex flex-row items-center gap-4 min-w-0">
          {endItems}
        </div>
      </div>
    </li>
  );
};

interface ItemContentProps {
  children: JSX.Element[] | JSX.Element;
}

export const ItemContent = ({ children }: ItemContentProps): JSX.Element => {
  return <div className="flex flex-col">{children}</div>;
};

interface ItemMainContentProps extends React.ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
}

export const ItemMainContent = ({
  className,
  children,
  ...props
}: ItemMainContentProps): JSX.Element => {
  return (
    <div {...props} className={cn("font-medium text-lg", className)}>
      {children}
    </div>
  );
};

export interface Info {
  label: string;
  value: string;
}

interface InfoItemProps {
  itemInfo: Info;
}

export const InfoItem = ({ itemInfo }: InfoItemProps): JSX.Element => {
  return (
    <Item>
      <p className="text-sm text-gray-600 font-normal">{itemInfo.label}</p>
      <p className="text-sm text-gray-800 font-medium truncate" data-align="end">
        {itemInfo.value}
      </p>
    </Item>
  );
};
