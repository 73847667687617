import { TextZoom } from "@capacitor/text-zoom";
import { iosOrAndroid } from "@repo/lib";
import { useEffect } from "react";

const MAX_ZOOM_ALLOWED = 1.2;

export const useSetMaxZoom = (): void => {
  useEffect(() => {
    if (iosOrAndroid) {
      const setMaxZoom = async (): Promise<void> => {
        const preferredZoom = await TextZoom.getPreferred();

        if (preferredZoom.value > MAX_ZOOM_ALLOWED) {
          await TextZoom.set({ value: MAX_ZOOM_ALLOWED });
        }
      };

      void setMaxZoom();
    }
  }, []);
};
