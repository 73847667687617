import { Left, Trash } from "@repo/icons";
import { createFileRoute, redirect, useRouter, useSearch } from "@tanstack/react-router";
import { z } from "zod";
import { graphql } from "@repo/graphql-types";
import { useState } from "react";
import { fetchQuery, useGraphQLMutation } from "@/hooks/use-graphql";
import { ScheduleForm } from "@/components/schedule-form";
import { Page } from "@/components/page";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { PendingComponent } from "@/components/pending-component";

const EditSchedulePageQuery = graphql(/* GraphQL */ `
  query EditSchedulePageQuery($codUsuario: Int!, $codUsuarioAgenda: Int! = 0) {
    ...ScheduleFormQueryFragment
    schedules: usuariosAgendas(
      where: {
        codUsuarioAgenda: { _eq: $codUsuarioAgenda }
        codUsuario: { _eq: $codUsuario }
        ativo: { _eq: true }
      }
    ) {
      codUsuarioAgenda
    }
  }
`);

const DeleteScheduleMutation = graphql(`
  mutation DeleteScheduleMutation($input: LivanceApiDeleteUsuarioAgendaInput!) {
    LivanceApiDeleteUsuarioAgenda(arg1: $input)
  }
`);

const editSchedulePageSearchParamsSchema = z.object({
  action: z.string().optional(),
});

export const EditSchedulePage = (): JSX.Element => {
  const router = useRouter();
  const { queryClient, data } = Route.useRouteContext();
  const { scheduleId } = Route.useParams();
  const searchParams = useSearch({
    from: "/settings/schedule-options/schedules/$scheduleId",
  });

  const showDeleteConfirmationDrawer = searchParams.action === "delete";
  const setShowDeleteConfirmationDrawer = (show: boolean): void => {
    if (show && !showDeleteConfirmationDrawer) {
      void router.navigate({
        to: "/settings/schedule-options/schedules/$scheduleId",
        params: {
          scheduleId,
        },
        search: { action: "delete" },
      });
    } else if (!show && showDeleteConfirmationDrawer) {
      router.history.back();
    }
  };

  const [showUnexpectedErrorDrawer, setShowUnexpectedErrorDrawer] = useState(false);

  const { mutateAsync, isPending } = useGraphQLMutation(DeleteScheduleMutation);

  const onClickTrashButton = (): void => setShowDeleteConfirmationDrawer(true);

  const onConfirmDelete = async (): Promise<void> => {
    await mutateAsync(
      { input: { codUsuarioAgenda: scheduleId } },
      {
        onSuccess: onSuccessDelete,
        onError: onErrorDelete,
      },
    );
  };

  const onSuccessDelete = (): void => {
    void queryClient.resetQueries({
      queryKey: ["SchedulesPageQuery"],
    });
    router.history.go(-2);
  };

  const onErrorDelete = (): void => {
    setShowDeleteConfirmationDrawer(false);
    setShowUnexpectedErrorDrawer(true);
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Editar agenda" align="center" />
        <HeaderButton icon={Trash} onClick={onClickTrashButton} align="end" />
      </HeaderRoot>
      <Page>
        <ScheduleForm data={data} />

        <MessageDrawerRoot
          icon={Trash}
          variant="secondary"
          open={showDeleteConfirmationDrawer}
          setOpen={setShowDeleteConfirmationDrawer}
        >
          <MessageDrawerTitle>Excluir agenda</MessageDrawerTitle>
          <MessageDrawerBody>
            Tem certeza de que deseja excluir esta agenda?
          </MessageDrawerBody>
          <MessageDrawerActions>
            <MessageDrawerActionButton
              onClick={() => void onConfirmDelete()}
              loading={isPending}
            >
              Excluir
            </MessageDrawerActionButton>
          </MessageDrawerActions>
        </MessageDrawerRoot>
        <UnexpectedErrorDrawer
          open={showUnexpectedErrorDrawer}
          setOpen={setShowUnexpectedErrorDrawer}
        />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/schedule-options/schedules/$scheduleId")({
  component: EditSchedulePage,
  pendingComponent: () => <PendingComponent title="Editar agenda" />,
  parseParams: (params) => ({
    scheduleId: z.number().int().parse(Number(params.scheduleId)),
  }),
  beforeLoad: async ({ context, params }) => {
    const variables = {
      codUsuario: context.user.codUsuario,
      codUsuarioAgenda: params.scheduleId,
    };

    const data = await fetchQuery(context, EditSchedulePageQuery, variables);

    const scheduleHasFound = data.schedules.length > 0;

    if (!scheduleHasFound) {
      redirect({
        to: "/settings/schedule-options/schedules",
        throw: true,
      });
    }

    return { data };
  },
  validateSearch: (search) => editSchedulePageSearchParamsSchema.parse(search),
});
