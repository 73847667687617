import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import {
  formatCurrencyFromCentsToReais,
  formatWithZonedDate,
  PaymentMethods,
  PagarmePayableTypes,
} from "@repo/lib";
import {
  Mastercard,
  Visa,
  type SVGIconProps,
  Hipercard,
  DinersClub,
  Pix,
  Paypal,
  Elo,
  Amex,
  Jcb,
  Aura,
  Discover,
} from "@repo/icons";
import type { FunctionComponent } from "react";
import { useAtomValue } from "jotai";
import { Item, ItemContent, ItemMainContent } from "@/components/list";
import { mapPagarmePaymentMethodToPaymentMethod } from "@/lib/mappers/payables";
import { amountVisibilityAtom } from "@/lib/atoms/amount-visibility";

export const DatePayablesDetailsListItemFragment = graphql(`
  fragment ExpensumGetPayablesFromDatePayable on ExpensumGetPayablesFromDatePayables {
    amount
    installment
    createdAt
    totalInstallments
    patientName
    brandName
    type
    paymentMethod
  }
`);

interface DatePayablesDetailsListItemProps {
  data: FragmentType<typeof DatePayablesDetailsListItemFragment>;
}

export const DatePayablesDetailsListItem = ({
  data,
}: DatePayablesDetailsListItemProps): JSX.Element => {
  const fragmentResult = useFragment(DatePayablesDetailsListItemFragment, data);

  const amountVisibility = useAtomValue(amountVisibilityAtom);

  const {
    type,
    amount,
    createdAt,
    brandName,
    paymentMethod: pagarmePaymentMethod,
    installment,
    totalInstallments,
    patientName,
  } = fragmentResult;

  const appointmentDate = createdAt
    ? `${formatWithZonedDate(createdAt, "dd/MM/yyyy")} às ${formatWithZonedDate(createdAt, "HH:mm")}`
    : "";

  const paymentInformation = (): JSX.Element => {
    const CardBrandIcon = getCardBrandIcon();

    const installmentsInfo = getInstallmentsInfo();

    return (
      <div className="flex flex-row gap-2">
        {CardBrandIcon ? <CardBrandIcon size={24} /> : null}
        <p className="text-neutral-500 text-sm">{installmentsInfo}</p>
      </div>
    );
  };

  const getCardBrandIcon = (): FunctionComponent<SVGIconProps> | undefined => {
    const cardBrandMap = new Map<string, FunctionComponent<SVGIconProps>>([
      ["Mastercard", Mastercard],
      ["Visa", Visa],
      ["Hiper", Hipercard],
      ["DinersClub", DinersClub],
      ["Pix", Pix],
      ["Paypal", Paypal],
      ["Elo", Elo],
      ["Amex", Amex],
      ["Hipercard", Hipercard],
      ["Jcb", Jcb],
      ["Aura", Aura],
      ["Discover", Discover],
    ]);

    return cardBrandMap.get(brandName ?? "");
  };

  const getInstallmentsInfo = (): string => {
    const paymentMethod = mapPagarmePaymentMethodToPaymentMethod(
      pagarmePaymentMethod ?? "",
    );

    if (type === PagarmePayableTypes.Refund) {
      const installmentText = ["Cancelamento"];
      if (installment) installmentText.push(`parcela ${installment}`);
      if (totalInstallments) installmentText.push(`de ${totalInstallments}`);

      return installmentText.join(" ");
    }

    const totalInstallmentsDetails = totalInstallments ? ` de ${totalInstallments}` : "";

    const installmentInfo: Record<string, string> = {
      [PaymentMethods.Credit]: `Crédito parcela ${installment}${totalInstallmentsDetails}`,
      [PaymentMethods.Debit]: "Débito à vista",
    };

    return installmentInfo[paymentMethod] || "";
  };

  return (
    <Item>
      <ItemContent>
        <ItemMainContent>
          {amountVisibility
            ? formatCurrencyFromCentsToReais(amount ?? 0)
            : "R$ \u2022\u2022\u2022\u2022\u2022"}{" "}
        </ItemMainContent>
        <p className="text-neutral-500 text-xs">
          Referente ao agendamento {appointmentDate}
        </p>
        {paymentInformation()}
        <p className="text-neutral-500 text-sm">{patientName}</p>
      </ItemContent>
    </Item>
  );
};
