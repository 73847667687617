import {
  TooltipDrawerRoot,
  TooltipDrawerTitle,
  TooltipDrawerBody,
} from "@/components/tooltip-drawer";

interface DatePayablesDetailsInformationDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const DatePayablesDetailsInformationDrawer = ({
  open,
  setOpen,
}: DatePayablesDetailsInformationDrawerProps): JSX.Element => {
  return (
    <TooltipDrawerRoot setOpen={setOpen} open={open}>
      <TooltipDrawerTitle>Recebimentos</TooltipDrawerTitle>
      <TooltipDrawerBody>
        <p>
          Nesta tela, você analisa o valor total dos seus recebimentos na data selecionada
          na tela anterior.
        </p>
        <p>
          Também é possível analisar a qual agendamento se refere, o nome do paciente e
          qual foi o método de pagamento utilizado para facilitar a conciliação dos seus
          recebimentos.
        </p>
        <p className="mb-3">
          Os valores exibidos são os valores líquidos, ou seja, são os valores já com as
          deduções das taxas.
        </p>
        <p className="mb-3">
          <span className="font-semibold text-neutral-600">Importante:</span> caso existam
          pagamentos com status &quot;Previsto&quot;, &quot;A confirmar&quot; ou
          &quot;Rejeitado&quot; na tela anterior, pode haver diferença entre o valor
          disponível aqui e o valor disponível em sua conta de recebimento. Caso tenha
          dúvidas, entre em contato com a sua instituição bancária.
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Entenda o ícone “olho”:</span>
        </p>
        <p>
          O ícone do olho ao lado do ícone de interrogação é uma ferramenta de
          privacidade. Ao clicar nele, os valores exibidos na tela serão ocultados,
          garantindo sua tranquilidade e segurança ao usar o aplicativo em locais
          públicos. Para exibir novamente os valores, basta clicar no ícone do olho mais
          uma vez.
        </p>
      </TooltipDrawerBody>
    </TooltipDrawerRoot>
  );
};
