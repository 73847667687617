import { forwardRef } from "react";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

const paymentMethods = ["Crédito", "Débito", "PIX"];

const combinations = paymentMethods.flatMap((v, i) =>
  paymentMethods.slice(i + 1).map((w) => [v, w]),
);

const allCombinations = [
  ...paymentMethods.map((method) => ({ label: method, value: method })),
  ...combinations.map((combo) => ({
    label: combo.join(" e "),
    value: combo.join(" e "),
  })),
  { label: "Crédito, Débito e PIX", value: "Crédito, Débito e PIX" },
];

export const AcceptedPaymentMethodsSelectInput = forwardRef<
  HTMLInputElement,
  Omit<SelectInputProps<string>, "items" | "title">
>(
  (
    { ...props }: Omit<SelectInputProps<string>, "items" | "title">,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    return (
      <SelectInput<string>
        ref={ref}
        {...props}
        title="Formas de pagamento"
        items={allCombinations}
      />
    );
  },
);

AcceptedPaymentMethodsSelectInput.displayName = "AcceptedPaymentMethodsSelectInput";
