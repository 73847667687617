import { graphql } from "@repo/graphql-types/gql";
import { CalendarTime } from "@repo/icons";
import { useSearch } from "@tanstack/react-router";
import { useState } from "react";
import {
  CalendarPageAppointmentsQueryDocument,
  CalendarPageFreeSlotsQueryDocument,
  FreeSchedulesSlotsHomePageCalendarFragmentFragmentDoc,
} from "@repo/graphql-types/graphql";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { useGraphQLMutation, useInvalidateQuery } from "@/hooks/use-graphql";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";

const RemoveUnavailableSlotMutation = graphql(`
  mutation RemoveUnavailableSlotMutation($codFechamento: Int!) {
    LivanceApiDeleteFechamento(codFechamento: $codFechamento)
  }
`);

interface RemoveUnavailableSlotDrawerProps {
  handleCloseRemoveUnavailableSlot: () => void;
}

export const RemoveUnavailableSlotDrawer = ({
  handleCloseRemoveUnavailableSlot,
}: RemoveUnavailableSlotDrawerProps): JSX.Element => {
  const searchParams = useSearch({
    from: "/",
  });

  const [showUnexpectedErrorDrawer, setShowUnexpectedErrorDrawer] = useState(false);

  const { mutateAsync, isPending } = useGraphQLMutation(RemoveUnavailableSlotMutation);

  const invalidateCalendarPageAppointmentsQuery = useInvalidateQuery(
    CalendarPageAppointmentsQueryDocument,
  );
  const invalidateCalendarPageFreeSlotsQuery = useInvalidateQuery(
    CalendarPageFreeSlotsQueryDocument,
  );
  const invalidateFreeSchedulesSlotsHomePageCalendarFragment = useInvalidateQuery(
    FreeSchedulesSlotsHomePageCalendarFragmentFragmentDoc,
  );

  const onConfirmDelete = async (): Promise<void> => {
    const unavailableSlotId = searchParams.unavailableSlotId;
    if (unavailableSlotId) {
      await mutateAsync(
        { codFechamento: unavailableSlotId },
        {
          onSuccess: onSuccessDelete,
          onError: onErrorDelete,
        },
      );
    }
  };

  const onSuccessDelete = (): void => {
    invalidateCalendarPageAppointmentsQuery();
    invalidateCalendarPageFreeSlotsQuery();
    invalidateFreeSchedulesSlotsHomePageCalendarFragment();

    handleDrawerOnChange(false);
  };

  const onErrorDelete = (): void => {
    handleDrawerOnChange(false);
    setShowUnexpectedErrorDrawer(true);
  };

  const handleDrawerOnChange = (value: boolean): void => {
    if (!value) {
      handleCloseRemoveUnavailableSlot();
    }
  };

  return (
    <>
      <MessageDrawerRoot
        icon={CalendarTime}
        setOpen={handleDrawerOnChange}
        open={searchParams.action === "delete-unavailable-slot"}
        variant="secondary"
      >
        <MessageDrawerTitle>Reabrir horário</MessageDrawerTitle>
        <MessageDrawerBody>
          Reabra este horário para uma nova reserva, você tem certeza que deseja reabrir
          este horário?
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            loading={isPending}
            onClick={() => void onConfirmDelete()}
          >
            Confirmar
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
      <UnexpectedErrorDrawer
        open={showUnexpectedErrorDrawer}
        setOpen={setShowUnexpectedErrorDrawer}
      />
    </>
  );
};
