import { type SVGIconProps } from "@repo/icons";
import { cn } from "@repo/lib";
import { type FunctionComponent } from "react";

interface PatientDetailActionListItemProps {
  text: string;
  Icon: FunctionComponent<SVGIconProps>;
  iconSize?: SVGIconProps["size"];
  disabled?: boolean;
  onClick: () => void;
}

export const PatientDetailActionListItem = ({
  text,
  Icon,
  iconSize = 24,
  disabled = false,
  onClick,
}: PatientDetailActionListItemProps): JSX.Element => {
  return (
    <button
      className={cn(
        disabled && "bg-neutral-50 text-neutral-300",
        !disabled && "bg-secondary-50",
        "h-[68px] w-full flex items-center justify-center flex-col rounded-lg",
      )}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <Icon
        className={disabled ? "fill-neutral-300" : "fill-secondary-400"}
        size={iconSize}
      />
      <div
        className={cn(
          "text-xs font-medium",
          disabled && "text-neutral-300",
          !disabled && "text-secondary-400",
        )}
      >
        {text}
      </div>
    </button>
  );
};
