export const isCpfValid = (cpf: string): boolean => {
  const cpfWithoutSpecialCharacters = cpf
    .trim()
    .replace(/(?<temp1>[~!@#$%^&*()_+=`{}[\]\-|\\:;'<>,./? ])+/g, "");

  if (cpfWithoutSpecialCharacters.length !== 11) {
    return false;
  }
  let sum = 0,
    i;

  const firstCN = parseInt(cpfWithoutSpecialCharacters.substring(9, 10), 10);
  const secondCN = parseInt(cpfWithoutSpecialCharacters.substring(10, 11), 10);

  if (
    cpfWithoutSpecialCharacters === "" ||
    cpfWithoutSpecialCharacters === "00000000000" ||
    cpfWithoutSpecialCharacters === "11111111111" ||
    cpfWithoutSpecialCharacters === "22222222222" ||
    cpfWithoutSpecialCharacters === "33333333333" ||
    cpfWithoutSpecialCharacters === "44444444444" ||
    cpfWithoutSpecialCharacters === "55555555555" ||
    cpfWithoutSpecialCharacters === "66666666666" ||
    cpfWithoutSpecialCharacters === "77777777777" ||
    cpfWithoutSpecialCharacters === "88888888888" ||
    cpfWithoutSpecialCharacters === "99999999999"
  ) {
    return false;
  }

  for (i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpfWithoutSpecialCharacters.substring(i - 1, i), 10) * (11 - i);
  }

  if (checkSumCpfResult(sum, firstCN)) {
    sum = 0;
    for (i = 1; i <= 10; i++) {
      sum =
        sum + parseInt(cpfWithoutSpecialCharacters.substring(i - 1, i), 10) * (12 - i);
    }
    return checkSumCpfResult(sum, secondCN);
  }

  return false;
};

export const checkSumCpfResult = (sum: number, cn: number): boolean => {
  let result = (sum * 10) % 11;
  if (result === 10 || result === 11) {
    result = 0;
  }
  return result === cn;
};
