import { Left, Right } from "@repo/icons";
import { forwardRef, useState } from "react";
import {
  CpsToast,
  CpsSelectorRoot,
  CpsSelectorItem,
  CpsSelectorTitle,
  CpsSingleSelector,
  type CpsSingleSelectorProps,
} from "corpus";

export interface MinuteConfig {
  value: number | string;
  unavailable: boolean;
  reason?: string;
}

interface MinutesSelectorProps extends Omit<CpsSingleSelectorProps, "children"> {
  minutes: MinuteConfig[];
  title?: string;
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
}

export const MinutesSelector = forwardRef<HTMLInputElement, MinutesSelectorProps>(
  (
    { minutes, title, onLeftArrowClick, onRightArrowClick, ...props },
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const handleAlertDisplay = (minute: MinuteConfig): void => {
      if (minute.unavailable) {
        setShowToast(true);
        setToastMessage(minute.reason ?? "No momento, essa ação não está disponível.");
      }
    };

    return (
      <>
        <div className="flex flex-col w-full gap-6" ref={ref}>
          <CpsSelectorRoot>
            {title ? (
              <CpsSelectorTitle className="text-neutral-600 text-sm mb-6">
                {title}
              </CpsSelectorTitle>
            ) : null}
            <div className="flex flex-row items-center gap-3">
              <button
                className="flex items-center justify-center rounded-full px-3 w-[48px] h-[48px] bg-secondary-50"
                onClick={onLeftArrowClick}
                data-testid="left-arrow-test-id"
                type="button"
              >
                <Left size={24} className="fill-secondary-400" />
              </button>
              <CpsSingleSelector activeBackgroundColor="secondary" {...props}>
                {minutes.map((minute) => (
                  <CpsSelectorItem
                    key={minute.value}
                    onClick={() => handleAlertDisplay(minute)}
                    {...minute}
                  >
                    {minute.value}
                  </CpsSelectorItem>
                ))}
              </CpsSingleSelector>
              <button
                className="flex items-center justify-center rounded-full px-3 w-[48px] h-[48px] bg-secondary-50"
                onClick={onRightArrowClick}
                data-testid="right-arrow-test-id"
                type="button"
              >
                <Right size={24} className="fill-secondary-400" />
              </button>
            </div>
          </CpsSelectorRoot>
        </div>

        <CpsToast
          title={toastMessage}
          show={showToast}
          onClose={() => setShowToast(false)}
          type="info"
          duration={5}
        />
      </>
    );
  },
);

MinutesSelector.displayName = "MinutesSelector";
