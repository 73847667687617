import { graphql } from "@repo/graphql-types";
import { useRouter, useSearch } from "@tanstack/react-router";
import { InformationCircle } from "@repo/icons";
import { useEffect } from "react";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { useGraphQL } from "@/hooks/use-graphql";
import { AppointmentTypeDurationSuggestionDrawer } from "@/components/appointment-type-duration-suggestion-drawer";
import { trackEvent } from "@/lib/tracking";

interface AppointmentFormDurationSuggestionAlertProps {
  appointmentTypeId: number;
  onSubmit?: (selectedValue: string) => void;
}

interface AppointmentFormDurationSuggestionAlertParams {
  action?: string;
}

export const AppointmentTypeDurationSuggestionAlertQuery = graphql(`
  query AppointmentTypeDurationSuggestionAlertQuery($codUsuarioCompromisso: Int!) {
    sugestaoDuracao: LivanceApiBuscaUsuarioCompromissoSugestaoDuracao(
      codUsuarioCompromisso: $codUsuarioCompromisso
    ) {
      novaDuracao
      deveSugerir
    }
    tipoDeAtendimento: usuariosCompromissos_by_pk(
      codUsuarioCompromisso: $codUsuarioCompromisso
    ) {
      duracao
      nome
    }
  }
`);

export const AppointmentFormDurationSuggestionAlert = ({
  appointmentTypeId,
  onSubmit,
}: AppointmentFormDurationSuggestionAlertProps): JSX.Element => {
  const router = useRouter();
  const searchParams: AppointmentFormDurationSuggestionAlertParams = useSearch({
    strict: false,
  });

  const isDrawerOpen =
    Boolean(searchParams.action) && searchParams.action === "suggestionDrawer";

  const queryResult = useGraphQL(AppointmentTypeDurationSuggestionAlertQuery, {
    codUsuarioCompromisso: appointmentTypeId,
  });
  const { data } = queryResult;

  const closeSuggestionDrawer = (value: boolean): void => {
    if (!value && isDrawerOpen) {
      router.history.back();
    }
  };

  const openDrawer = (): void => {
    void router.navigate({
      search: {
        action: "suggestionDrawer",
        ...searchParams,
      },
    });
  };

  useEffect(() => {
    if (data?.sugestaoDuracao?.deveSugerir) {
      trackEvent("Sugestao de Duracao Visualizada", {
        codUsuarioCompromisso: appointmentTypeId,
        duracaoTeorica: data.tipoDeAtendimento?.duracao,
        duracaoSugerida: data.sugestaoDuracao.novaDuracao,
      });
    }
  }, [
    appointmentTypeId,
    data?.sugestaoDuracao?.deveSugerir,
    data?.sugestaoDuracao?.novaDuracao,
    data?.tipoDeAtendimento?.duracao,
  ]);

  return (
    <AsyncDataWrapper {...queryResult} fallback={<>{/*Não deve mostrar loading*/}</>}>
      {data?.sugestaoDuracao?.deveSugerir ? (
        <div className="flex flex-col w-full items-start justify-between rounded-md p-4 bg-secondary-50 gap-2">
          <div className="flex items-center gap-2">
            <div className="h-6 w-6">
              <InformationCircle className="fill-secondary-500" size={24} />
            </div>
            <div className="ml-2 flex flex-col gap-3 justify-center">
              <p className="font-medium text-secondary-500">
                Recomendamos uma nova duração para esse tipo de atendimento.
              </p>
            </div>
          </div>
          <button
            type="button"
            className="ml-10 font-medium text-secondary-500 underline hover:cursor-pointer"
            onClick={openDrawer}
          >
            Ver recomendação
          </button>
        </div>
      ) : null}
      <AppointmentTypeDurationSuggestionDrawer
        open={isDrawerOpen}
        setOpen={closeSuggestionDrawer}
        appointmentTypeId={appointmentTypeId}
        appointmentTypeName={data?.tipoDeAtendimento?.nome ?? ""}
        actualDuration={data?.tipoDeAtendimento?.duracao ?? "00:00"}
        newDuration={data?.sugestaoDuracao?.novaDuracao ?? "00:00"}
        onSubmit={onSubmit}
      />
    </AsyncDataWrapper>
  );
};
