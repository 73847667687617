import { type ReactNode } from "react";
import {
  DrawerRoot,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerBody,
} from "@/components/drawer";

export interface TooltipDrawerRootProps {
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
}

export const TooltipDrawerRoot = ({
  open,
  setOpen,
  onClose,
  children,
}: TooltipDrawerRootProps): JSX.Element => {
  return (
    <DrawerRoot open={open} setOpen={setOpen} onClose={onClose}>
      <DrawerContent>{children}</DrawerContent>
    </DrawerRoot>
  );
};

interface TooltipDrawerTitleProps {
  children: ReactNode;
}

export const TooltipDrawerTitle = ({
  children,
}: TooltipDrawerTitleProps): JSX.Element => (
  <DrawerHeader>
    <DrawerTitle>{children}</DrawerTitle>
  </DrawerHeader>
);

interface TooltipDrawerBodyProps {
  className?: string;
  children: ReactNode;
}

export const TooltipDrawerBody = ({
  className,
  children,
}: TooltipDrawerBodyProps): JSX.Element => (
  <DrawerBody
    className={`text-md font-normal text-neutral-500 max-w-md w-full mx-auto flex flex-col overflow-auto gap-y-1 ${className}`}
  >
    {children}
  </DrawerBody>
);
