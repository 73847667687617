import { type SVGIconProps } from "@repo/icons";
import { type FunctionComponent } from "react";
import { graphql } from "@repo/graphql-types/gql";
import { formatCurrency, getTimeDateFromStrings } from "@repo/lib";
import { differenceInMinutes } from "date-fns";
import { useSearch } from "@tanstack/react-router";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { useGraphQL } from "@/hooks/use-graphql";

const FlexiblePriceDrawerQuery = graphql(`
  query FlexiblePriceDrawerQuery($appointmentId: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $appointmentId) {
      horaInicio
      horaFim
    }
    PrecificacoesTiposSalas(
      where: {
        unidade: { agendamentos: { codAgendamento: { _eq: $appointmentId } } }
        tiposSala: {
          usuariosCompromissosTiposSalas: {
            UsuarioCompromisso: {
              agendamentos: { codAgendamento: { _eq: $appointmentId } }
            }
          }
        }
        ativo: { _eq: true }
        TipoMovimentacao: { identificacao: { _eq: "UtilizacaoSala" } }
      }
    ) {
      TipoMovimentacao {
        valorUnitario
      }
    }
  }
`);

interface FlexiblePriceDrawerProps {
  appointmentId: number;
  title: string;
  description: string;
  icon: FunctionComponent<SVGIconProps>;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: () => void;
}

export const FlexiblePriceDrawer = ({
  open,
  title,
  description,
  icon,
  appointmentId,
  setOpen,
  onSubmit,
}: FlexiblePriceDrawerProps): JSX.Element => {
  const searchParams: { action: string } = useSearch({ strict: false });

  const shouldExecuteQuery =
    searchParams.action === "reschedule-flexible-price-appointment" ||
    searchParams.action === "cancel-flexible-price-appointment";

  const { data } = useGraphQL(
    FlexiblePriceDrawerQuery,
    { appointmentId },
    { enabled: shouldExecuteQuery },
  );

  const calculateAppointmentChangeTax = (): number => {
    const roomUtilizationPricePerMinute = getRoomUtilizationPricePerMinute();

    let appointmentChangeTax = roomUtilizationPricePerMinute;

    if (roomUtilizationPricePerMinute && roomUtilizationPricePerMinute > 0) {
      const discountPercentage = 0.75;

      appointmentChangeTax = roomUtilizationPricePerMinute * discountPercentage;
    }

    const appointmentDuration = getAppointmentDuration();

    appointmentChangeTax *= appointmentDuration;

    return Number(appointmentChangeTax.toFixed(2));
  };

  const getRoomUtilizationPricePerMinute = (): number => {
    return Math.max(
      ...(data?.PrecificacoesTiposSalas.map(
        (item) => item.TipoMovimentacao.valorUnitario,
      ) ?? []),
    );
  };

  const getAppointmentDuration = (): number => {
    const appointmentStart = getTimeDateFromStrings(
      new Date(),
      data?.agendamento?.horaInicio ?? "00:00:00",
    );

    const appointmentEnd = getTimeDateFromStrings(
      new Date(),
      data?.agendamento?.horaFim ?? "00:00:00",
    );

    const appointmentDuration = differenceInMinutes(appointmentEnd, appointmentStart);

    return appointmentDuration;
  };

  const appointmentChangeTax = calculateAppointmentChangeTax();

  return (
    <MessageDrawerRoot open={open} setOpen={setOpen} icon={icon} variant="secondary">
      <MessageDrawerTitle>{title}</MessageDrawerTitle>
      <MessageDrawerBody>
        <p>{description}</p>
        {appointmentChangeTax > 0 ? (
          <div className="bg-neutral-50">
            <div className="flex flex-row justify-between p-4 ">
              <p className="text-neutral-500 text-sm">Valor total</p>
              <p className="text-neutral-600 font-medium text-sm">
                {formatCurrency(appointmentChangeTax)}
              </p>
            </div>
          </div>
        ) : null}
      </MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton
          onClick={onSubmit}
          data-testid="submit-button-flexible-price-drawer"
        >
          Continuar
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
