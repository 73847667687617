import { Left } from "@repo/icons";
import { createFileRoute, useNavigate, useRouter } from "@tanstack/react-router";
import "@/styles/calendar.css";
import { useAtom } from "jotai";
import { graphql } from "@repo/graphql-types/gql";
import { useEffect } from "react";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  appointmentFormAtom,
  isBasicInfoStepConcluded,
} from "@/lib/atoms/appointment-form-atom";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { CreateAppointmentPatientList } from "@/components/create-appointment-patient-list";

const AppointmentPatientSearchPageQuery = graphql(/* GraphQL */ `
  query AppointmentPatientSearchPageQuery {
    ...CreateAppointmentPatientListFragment
  }
`);

export const AppointmentSearchPatientPage = (): JSX.Element => {
  const queryResult = useGraphQL(AppointmentPatientSearchPageQuery);
  const { data } = queryResult;

  const [atomValues, setAtomValues] = useAtom(appointmentFormAtom);

  const navigate = useNavigate();

  const router = useRouter();

  useEffect(() => {
    if (!isBasicInfoStepConcluded(atomValues)) {
      void navigate({ to: "/" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Apenas no carregamento inicial da página
  }, []);

  const onBackButtonClick = (): void => {
    setAtomValues({
      ...atomValues,
      patientId: undefined,
    });

    router.history.back();
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={onBackButtonClick} icon={Left} align="start" />
        <HeaderTitle title="Novo agendamento" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <CreateAppointmentPatientList data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/appointment/create/search-patient")({
  component: AppointmentSearchPatientPage,
});
