import { Check } from "@repo/icons";
import { useEffect } from "react";
import { ActionSheetActionGroup, ActionSheetRoot } from "@/components/action-sheet";

export interface ElementProps<T> {
  value: T;
  label: string;
}

export interface SelectModalProps<T> {
  show: boolean;
  setShow: (show: boolean) => void;
  elements: ElementProps<T>[];
  selected: T | null;
  onSelect?: (item: T) => void;
}

export const SelectModal = <T,>({
  show,
  setShow,
  elements,
  selected,
  onSelect,
}: SelectModalProps<T>): JSX.Element => {
  const handleSelection = (item: ElementProps<T>): void => {
    if (onSelect) {
      onSelect(item.value);
    }
  };

  /* Display Controls */
  const handleCloseDialog = (): void => {
    setTimeout(
      () => {
        setShow(false);
      },
      window.screen.width < 1280 ? 400 : 0,
    );
  };

  useEffect(() => {
    const overflowClass = "overflow-y-hidden";
    show
      ? document.documentElement.classList.add(overflowClass)
      : document.documentElement.classList.remove(overflowClass);
  }, [show]);

  return (
    <ActionSheetRoot showActionSheet={show} setShowActionSheet={handleCloseDialog}>
      <ActionSheetActionGroup>
        <div className="rounded-40 max-h-[300px] w-full overflow-auto bg-white">
          <ul className="grid grid-cols-1 divide-y ">
            {elements.map((element) => (
              <li
                className="relative cursor-pointer p-4 text-center"
                key={`calendar-header-unit-${element.label}`}
              >
                <button
                  className="w-full"
                  onClick={() => handleSelection(element)}
                  type="button"
                >
                  {element.value === selected && (
                    <div className="absolute inset-y-0 left-0 flex items-center pl-4">
                      <Check className="fill-neutral-600" size={24} />
                    </div>
                  )}
                  <p className="text-md font-normal leading-6 text-neutral-600">
                    {element.label}
                  </p>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </ActionSheetActionGroup>
    </ActionSheetRoot>
  );
};
