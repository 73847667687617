/* eslint-disable import/no-duplicates -- Necessário, pois o import do ptBR do date-fns não estava funcionando corretamente. */
import { DayPicker, type DateRange, type DateFormatter } from "react-day-picker";
import {
  CpsInput,
  CpsSelectorRoot,
  CpsSingleSelector,
  CpsSelectorItem,
  type CpsSelectorType,
} from "corpus";
import { Calendar } from "@repo/icons";
import { ptBR } from "date-fns/locale";
import { format } from "@repo/lib";
import { forwardRef } from "react";
import { isAfter } from "date-fns";
import {
  DrawerRoot,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerBody,
  DrawerFooter,
} from "@/components/drawer";
import "react-day-picker/dist/style.css";

interface DateRangeFilterDrawerRootProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
}
export const DateRangeFilterDrawerRoot = ({
  children,
  open,
  setOpen,
}: DateRangeFilterDrawerRootProps): JSX.Element => {
  return (
    <DrawerRoot setOpen={setOpen} open={open}>
      <DrawerContent>{children}</DrawerContent>
    </DrawerRoot>
  );
};

interface DataRangeFilterDrawerTitleProps {
  children: React.ReactNode;
}

export const DataRangeFilterDrawerTitle = ({
  children,
}: DataRangeFilterDrawerTitleProps): JSX.Element => {
  return (
    <DrawerHeader>
      <DrawerTitle>{children}</DrawerTitle>
    </DrawerHeader>
  );
};

interface DataRangeFilterDrawerFormProps {
  children: React.ReactNode;
  onChange: (value: CpsSelectorType) => void;
  value?: CpsSelectorType;
}

export const DataRangeFilterDrawerForm = ({
  children,
  onChange,
  value,
}: DataRangeFilterDrawerFormProps): JSX.Element => {
  return (
    <DrawerBody>
      <CpsSelectorRoot>
        <CpsSingleSelector
          activeBackgroundColor="secondary"
          value={value}
          onChange={onChange}
          fullWidth
        >
          <div className="flex flex-col gap-8 w-full">{children}</div>
        </CpsSingleSelector>
      </CpsSelectorRoot>
    </DrawerBody>
  );
};

interface DateRangeFilterDrawerRadioSelectProps {
  title: string;
  values: number[];
}

const describeTimeDistance = (dayCount: number): string => {
  const absoluteDayCount = Math.abs(dayCount);

  const timeDirection = dayCount < 0 ? "Último" : "Próximo";
  const directionWithProperPlural =
    absoluteDayCount === 1 ? timeDirection : `${timeDirection}s`;
  const dayWord = absoluteDayCount === 1 ? "dia" : "dias";

  return `${directionWithProperPlural} ${absoluteDayCount} ${dayWord}`;
};

export const DateRangeFilterDrawerRadioSelect = ({
  title,
  values,
}: DateRangeFilterDrawerRadioSelectProps): JSX.Element => {
  return (
    <div className="flex flex-col gap-3">
      <span className="font-medium">{title}</span>
      <div className="grid grid-cols-2 gap-3">
        {values.map((value) => (
          <CpsSelectorItem key={value} value={value} Icon={Calendar}>
            {describeTimeDistance(value)}
          </CpsSelectorItem>
        ))}
      </div>
    </div>
  );
};

interface DateRangeFilterDrawerCalendarSelectSelectProps {
  onChange: (date?: DateRange) => void;
  disableFutureDates?: boolean;
  value: DateRange;
}

export const DateRangeFilterDrawerCalendarSelectSelect = forwardRef<
  HTMLInputElement,
  DateRangeFilterDrawerCalendarSelectSelectProps
>(
  (
    { value, onChange, disableFutureDates = false },
    ref: React.ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const formatMonthUpperInitial: DateFormatter = (date: Date) => {
      const formattedDate = format(date, "LLLL", ptBR);
      return (
        <>
          {formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)},{" "}
          {format(date, "yyyy", ptBR)}
        </>
      );
    };

    const buildInterval = (): { from: string; to: string } => {
      const from = value.from;
      const to = value.to;

      if (from && to && isAfter(from, to)) {
        return {
          from: format(to, "dd/MM/yyyy"),
          to: format(from, "dd/MM/yyyy"),
        };
      }

      return {
        from: from ? format(from, "dd/MM/yyyy") : "",
        to: to ? format(to, "dd/MM/yyyy") : "",
      };
    };

    const { from, to } = buildInterval();

    return (
      <div className="flex flex-col justify-center items-center gap-4 pb-8">
        <div className="flex gap-3">
          <div className="w-full">
            <CpsInput
              ref={ref}
              cursorPointer
              id="date-picker-from"
              title="Período específico"
              value={from}
              placeholder="De"
              disabled
            />
          </div>
          <div className="w-full self-end">
            <CpsInput
              cursorPointer
              id="date-picker-to"
              disabled
              value={to}
              placeholder="Até"
            />
          </div>
        </div>
        <DayPicker
          mode="range"
          selected={value}
          onSelect={onChange}
          today={new Date()}
          showOutsideDays
          fixedWeeks
          disabled={Boolean(disableFutureDates) && { after: new Date() }}
          locale={ptBR}
          captionLayout="dropdown-buttons"
          defaultMonth={value.from ?? new Date()}
          formatters={{ formatCaption: formatMonthUpperInitial }}
        />
      </div>
    );
  },
);

DateRangeFilterDrawerCalendarSelectSelect.displayName =
  "DateRangeFilterDrawerCalendarSelectSelect";

interface DateRangeFilterDrawerFooterProps {
  children: React.ReactNode;
}

export const DateRangeFilterDrawerFooter = ({
  children,
}: DateRangeFilterDrawerFooterProps): JSX.Element => {
  return (
    <DrawerFooter>
      <div className="flex flex-col justify-center gap-4 w-full">{children}</div>
    </DrawerFooter>
  );
};
