import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import {
  DaysOfWeekEnum,
  DaysOfWeekToPortugueseAdverbial,
  ScheduleFrequencyEnum,
  convertToMinutes,
} from "@repo/lib";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouteContext, useRouter } from "@tanstack/react-router";
import { useState } from "react";
import { format } from "date-fns";
import { CpsAlert } from "corpus";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormSubmitButton,
  FormControl,
  FormItem,
} from "@/components/form";
import { UnitsMultiSelectInput } from "@/components/units-multi-select-input";
import { ScheduleFrequenciesSelectInput } from "@/components/schedule-frequencies-select-input";
import { AppointmentTypesMultiSelectInput } from "@/components/appointment-types-multi-select-input";
import { DaysOfWeekMultiSelector } from "@/components/days-of-week-multi-selector";
import { TimeInput } from "@/components/time-input";
import { DatePicker } from "@/components/date-picker";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";

export const ScheduleFormQueryFragment = graphql(/* GraphQL */ `
  fragment ScheduleFormQueryFragment on query_root {
    usuariosCompromissos(
      where: {
        ativo: { _eq: true }
        codUsuario: { _eq: $codUsuario }
        categoria: { _neq: 3 }
      }
      order_by: { nome: asc }
    ) {
      ...AppointmentTypesListToMultiSelectQueryFragment
    }
    tiposAgendasRepeticoes(where: { codTipoAgendaRepeticao: { _neq: 30 } }) {
      ...ScheduleFrequenciesListToSelectQueryFragment
    }
    unidades(where: { ativo: { _eq: true } }, order_by: { nome: asc }) {
      ...UnitsListToMultiSelectQueryFragment
      codUnidade
      nomeLimpo
      unidadeHorariosFuncionamentos {
        codUnidade
        diaSemana
        abertura
        fechamento
      }
    }
    schedule: usuariosAgendas_by_pk(codUsuarioAgenda: $codUsuarioAgenda) {
      codUsuarioAgenda
      dataInicio
      inicio
      fim
      codTipoAgendaRepeticao
      compromissos: tbUsuariosAgendasCompromissos(where: { ativo: { _eq: true } }) {
        codUsuarioCompromisso
      }
      diasSemana: tbUsuariosAgendasDiasSemanas(where: { ativo: { _eq: true } }) {
        diaSemana
      }
      unidades: tbUsuariosAgendasUnidades(where: { ativo: { _eq: true } }) {
        codUnidade
      }
    }
  }
`);

const CreateScheduleMutation = graphql(`
  mutation CreateScheduleMutation($input: LivanceApiCreateUsuarioAgendaInput!) {
    LivanceApiCreateUsuarioAgenda(arg1: $input)
  }
`);

const UpdateScheduleMutation = graphql(`
  mutation UpdateScheduleMutation($input: LivanceApiPatchUsuarioAgendaInput!) {
    LivanceApiPatchUsuarioAgenda(arg1: $input)
  }
`);

interface ScheduleFormProps {
  data: FragmentType<typeof ScheduleFormQueryFragment>;
}

const timerProps = {
  minHour: 0,
  minMinutes: 0,
  maxHour: 24,
  maxMinutes: 59,
  stepHour: 1,
  stepMinutes: 5,
};

export const ScheduleForm = ({ data }: ScheduleFormProps): JSX.Element => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const {
    user: { codUsuario: userId },
    queryClient,
  } = useRouteContext({ strict: false });

  const scheduleData = useFragment(ScheduleFormQueryFragment, data);

  const [showUnexpectedErrorDrawer, setShowUnexpectedErrorDrawer] =
    useState<boolean>(false);

  const { mutateAsync: createScheduleMutateAsync } =
    useGraphQLMutation(CreateScheduleMutation);

  const { mutateAsync: updateScheduleMutateAsync } =
    useGraphQLMutation(UpdateScheduleMutation);

  const formSchema = z
    .object({
      units: z.string().array().min(1, "Selecione ao menos uma unidade"),
      initialDate: z.date(),
      startTime: z.string(),
      endTime: z.string(),
      scheduleFrequency: z
        .number({ required_error: "Selecione a frequência da agenda" })
        .min(0, "Selecione a frequência da agenda"),
      daysOfWeek: z.number().array().optional(),
      appointmentTypes: z
        .string()
        .array()
        .min(1, "Selecione ao menos um tipo de atendimento"),
    })
    .superRefine((values, context) => {
      validateStartAndEndTime(values.startTime, values.endTime, context);
      if (hasRepetition) {
        validateDaysOfWeek(values.daysOfWeek, context);
      }
    });

  const validateStartAndEndTime = (
    startTime: string,
    endTime: string,
    context: z.RefinementCtx,
  ): void => {
    const startTimeMinutes = convertToMinutes(startTime);
    const endTimeMinutes = convertToMinutes(endTime);

    const addIssue = (message: string): void => {
      ["startTime", "endTime"].forEach((field) => {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message,
          path: [field],
        });
      });
    };

    if (startTimeMinutes === endTimeMinutes) {
      addIssue("A hora de início e fim não podem ser iguais");
    }

    if (startTimeMinutes > endTimeMinutes) {
      addIssue("A hora de início não pode ser maior que a hora de fim");
    }

    validateIfStartTimeIsLessThanUnitOpeningTime(startTime, context);
    validateIfEndTimeIsBiggerThanUnitClosingTime(endTime, context);
  };

  const validateIfStartTimeIsLessThanUnitOpeningTime = (
    startTime: string,
    context: z.RefinementCtx,
  ): void =>
    validateTimeAgainstUnitOperationTime(
      startTime,
      context,
      "abertura",
      (startTimeMinutes, unitOpeningTimeMinutes) =>
        startTimeMinutes < unitOpeningTimeMinutes,
      "startTime",
    );

  const validateIfEndTimeIsBiggerThanUnitClosingTime = (
    endTime: string,
    context: z.RefinementCtx,
  ): void =>
    validateTimeAgainstUnitOperationTime(
      endTime,
      context,
      "fechamento",
      (endTimeMinutes, unitClosingTimeMinutes) => endTimeMinutes > unitClosingTimeMinutes,
      "endTime",
    );

  const validateTimeAgainstUnitOperationTime = (
    time: string,
    context: z.RefinementCtx,
    validationProperty: "abertura" | "fechamento",
    comparison: (timeMinutes: number, unitTimeMinutes: number) => boolean,
    issuePath: string,
  ): void => {
    const timeMinutes = convertToMinutes(time);

    const selectedDaysOfWeek = hasRepetition
      ? form.watch("daysOfWeek")
      : [form.watch("initialDate").getDay()];

    const selectedUnits = form.watch("units");

    const operationsTimesOfSelectedUnitsInSelectedDaysOfWeek = scheduleData.unidades
      .filter((u) => selectedUnits.includes(String(u.codUnidade)))
      .flatMap((u) =>
        u.unidadeHorariosFuncionamentos.filter((x) =>
          selectedDaysOfWeek?.includes(x.diaSemana),
        ),
      )
      .sort((a, b) => {
        const difference =
          convertToMinutes(a[validationProperty]) -
          convertToMinutes(b[validationProperty]);
        return validationProperty === "abertura" ? difference : -difference;
      });

    operationsTimesOfSelectedUnitsInSelectedDaysOfWeek.forEach((operationTime) => {
      const validationPropertyMinutes = convertToMinutes(
        operationTime[validationProperty],
      );

      if (comparison(timeMinutes, validationPropertyMinutes)) {
        const unitName = scheduleData.unidades.find(
          (x) => x.codUnidade === operationTime.codUnidade,
        )?.nomeLimpo;
        const dayOfWeekPortugueseAdverbialLocution = DaysOfWeekToPortugueseAdverbial.get(
          operationTime.diaSemana,
        );

        const message = `${dayOfWeekPortugueseAdverbialLocution}, a unidade ${unitName} ${validationProperty === "abertura" ? "abre" : "fecha"} às ${formatTime(operationTime[validationProperty])}`;

        context.addIssue({
          code: z.ZodIssueCode.custom,
          message,
          path: [issuePath],
        });
      }
    });
  };

  const validateDaysOfWeek = (
    daysOfWeek: number[] | undefined,
    context: z.RefinementCtx,
  ): void => {
    if (!daysOfWeek || daysOfWeek.length === 0) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Selecione ao menos um dia da semana",
        path: ["daysOfWeek"],
      });
    }
  };

  type FormFields = z.infer<typeof formSchema>;

  const buildDefaultValues = (): FormFields => {
    if (!scheduleData.schedule) {
      return {
        units: [],
        initialDate: today,
        startTime: "07:00",
        endTime: "22:00",
        scheduleFrequency: -1,
        daysOfWeek: [],
        appointmentTypes: [],
      };
    }

    return {
      units: scheduleData.schedule.unidades.map((x) => String(x.codUnidade)),
      initialDate: new Date(`${scheduleData.schedule.dataInicio}T00:00:00`),
      startTime: scheduleData.schedule.inicio,
      endTime: scheduleData.schedule.fim,
      scheduleFrequency: scheduleData.schedule.codTipoAgendaRepeticao,
      daysOfWeek: scheduleData.schedule.diasSemana.map((x) => x.diaSemana),
      appointmentTypes: scheduleData.schedule.compromissos.map((x) =>
        String(x.codUsuarioCompromisso),
      ),
    };
  };

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: buildDefaultValues(),
  });

  form.register("units", { deps: ["startTime", "endTime"] });

  form.register("initialDate", { deps: ["startTime", "endTime"] });

  form.register("scheduleFrequency", { deps: ["startTime", "endTime"] });

  form.register("daysOfWeek", { deps: ["startTime", "endTime"] });

  form.register("startTime", {
    deps: ["endTime"],
  });

  form.register("endTime", {
    deps: ["startTime"],
  });

  const hasRepetition =
    form.watch("scheduleFrequency") === Number(ScheduleFrequencyEnum.Quinzenalmente) ||
    form.watch("scheduleFrequency") === Number(ScheduleFrequencyEnum.Semanalmente);

  const handleSubmit = async (formFields: FormFields): Promise<void> => {
    if (!scheduleData.schedule) {
      await createSchedule(formFields);
      return;
    }

    await updateSchedule(formFields);
  };

  const createSchedule = async (formFields: FormFields): Promise<void> => {
    await createScheduleMutateAsync(
      {
        input: {
          codUsuario: userId,
          dataInicio: format(formFields.initialDate, "yyyy-MM-dd'T'00:00:00"),
          inicio: formFields.startTime,
          fim: formFields.endTime,
          codTipoAgendaRepeticao: formFields.scheduleFrequency,
          usuariosAgendasCompromissos: formFields.appointmentTypes.map((x) => {
            return {
              codUsuarioAgenda: 0,
              codUsuarioCompromisso: Number(x),
              ativo: true,
            };
          }),
          usuariosAgendasUnidades: formFields.units.map((x) => {
            return { codUsuarioAgenda: 0, codUnidade: Number(x), ativo: true };
          }),
          usuariosAgendasDiasSemanas: formFields.daysOfWeek?.map((x) => {
            return { codUsuarioAgenda: 0, diaSemana: x, ativo: true };
          }),
        },
      },
      {
        onSuccess: onCreateOrUpdateScheduleSuccess,
        onError: onCreateOrUpdateScheduleError,
      },
    );
  };

  const updateSchedule = async (formFields: FormFields): Promise<void> => {
    if (!scheduleData.schedule) return;

    const scheduleId = scheduleData.schedule.codUsuarioAgenda;

    await updateScheduleMutateAsync(
      {
        input: {
          codUsuarioAgenda: scheduleId,
          jsonPatchDocument: [
            {
              op: "replace",
              path: "/dataInicio",
              value: format(formFields.initialDate, "yyyy-MM-dd'T'00:00:00"),
            },
            {
              op: "replace",
              path: "/inicio",
              value: formFields.startTime,
            },
            {
              op: "replace",
              path: "/fim",
              value: formFields.endTime,
            },
            {
              op: "replace",
              path: "/codTipoAgendaRepeticao",
              value: formFields.scheduleFrequency,
            },
            {
              op: "replace",
              path: "/usuariosAgendasCompromissos",
              value: formFields.appointmentTypes.map((x) => {
                return {
                  codUsuarioAgenda: scheduleId,
                  codUsuarioCompromisso: Number(x),
                  ativo: true,
                };
              }),
            },
            {
              op: "replace",
              path: "/usuariosAgendasUnidades",
              value: formFields.units.map((x) => {
                return {
                  codUsuarioAgenda: scheduleId,
                  codUnidade: Number(x),
                  ativo: true,
                };
              }),
            },
            {
              op: "replace",
              path: "/usuariosAgendasDiasSemanas",
              value: formFields.daysOfWeek?.map((x) => {
                return { codUsuarioAgenda: scheduleId, diaSemana: x, ativo: true };
              }),
            },
          ],
        },
      },
      {
        onSuccess: onCreateOrUpdateScheduleSuccess,
        onError: onCreateOrUpdateScheduleError,
      },
    );
  };

  const router = useRouter();

  const onCreateOrUpdateScheduleSuccess = (): void => {
    void queryClient.resetQueries({
      queryKey: ["SchedulesPageQuery"],
    });
    router.history.back();
  };

  const onCreateOrUpdateScheduleError = (): void => setShowUnexpectedErrorDrawer(true);

  const showSaturdayOperationTimeAlert = (): JSX.Element | undefined => {
    const hasOnlyOneSelectedUnit = form.watch("units").length === 1;

    const startsInSaturday =
      form.watch("initialDate").getDay() === DaysOfWeekEnum.Saturday.valueOf();

    const saturdayOperationTime = scheduleData.unidades
      .find((u) => u.codUnidade === Number(form.watch("units")[0]))
      ?.unidadeHorariosFuncionamentos.find(
        (x) => x.diaSemana === DaysOfWeekEnum.Saturday.valueOf(),
      );

    return hasOnlyOneSelectedUnit && !hasRepetition && startsInSaturday ? (
      <CpsAlert
        title={`Aos sábados funcionamos nesta unidade entre ${formatTime(saturdayOperationTime?.abertura)} e ${formatTime(saturdayOperationTime?.fechamento)}`}
        description=""
        type="info"
      />
    ) : undefined;
  };

  const formatTime = (time: string | undefined): string | undefined => time?.slice(0, 5);

  return (
    <>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={handleSubmit}>
          <FormField
            control={form.control}
            name="units"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <UnitsMultiSelectInput
                    data={scheduleData.unidades}
                    title="Unidades"
                    required
                    searchable
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="initialDate"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <DatePicker
                    title="A partir de"
                    disabledPastDates={today}
                    required
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="startTime"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TimeInput
                    label="Começa às"
                    required
                    timerConfigs={{
                      ...timerProps,
                      initialHour: Number(
                        form.control._defaultValues.startTime?.split(":")[0],
                      ),
                      initialMinutes: Number(
                        form.control._defaultValues.startTime?.split(":")[1],
                      ),
                    }}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="endTime"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TimeInput
                    label="Termina às"
                    required
                    timerConfigs={{
                      ...timerProps,
                      initialHour: Number(
                        form.control._defaultValues.endTime?.split(":")[0],
                      ),
                      initialMinutes: Number(
                        form.control._defaultValues.endTime?.split(":")[1],
                      ),
                    }}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {showSaturdayOperationTimeAlert()}

          <FormField
            control={form.control}
            name="scheduleFrequency"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <ScheduleFrequenciesSelectInput
                    data={scheduleData.tiposAgendasRepeticoes}
                    title="Repetir agenda"
                    required
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          {hasRepetition ? (
            <FormField
              control={form.control}
              name="daysOfWeek"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <DaysOfWeekMultiSelector title="Dias da semana" required {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          ) : null}
          <FormField
            control={form.control}
            name="appointmentTypes"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <AppointmentTypesMultiSelectInput
                    data={scheduleData.usuariosCompromissos}
                    title="Tipos de atendimento"
                    required
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormSubmitButton>Salvar</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>
      <UnexpectedErrorDrawer
        open={showUnexpectedErrorDrawer}
        setOpen={setShowUnexpectedErrorDrawer}
      />
    </>
  );
};
