import { forwardRef } from "react";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type CsatCategoriesListQueryFragmentFragment } from "@repo/graphql-types/graphql";
import {
  CpsMultiSelector,
  type CpsMultiSelectorProps,
  CpsSelectorItem,
  CpsSelectorRoot,
  CpsSelectorTitle,
  type CpsSelectorType,
} from "corpus";

export const CsatCategoriesListQueryFragment = graphql(/* GraphQL */ `
  fragment CsatCategoriesListQueryFragment on csatCategorias {
    nome
    csatCategoriasNotas {
      codCsatCategoria
      nota
      ordem
    }
  }
`);

export type ValidRatings = 0 | 1 | 2 | 3 | 4 | 5;

const csatCategoriesByRatingAndOrder = (
  csatCategories: CsatCategoriesListQueryFragmentFragment[],
  rating: ValidRatings,
): { codCsatCategoria: number; nome: string }[] => {
  const filteredCategories = csatCategories.flatMap((category) =>
    category.csatCategoriasNotas
      .filter((csatCategoriaNota) => csatCategoriaNota.nota === rating)
      .map((csatCategoriaNota) => ({
        codCsatCategoria: Number(csatCategoriaNota.codCsatCategoria),
        nome: category.nome,
        ordem: csatCategoriaNota.ordem,
      })),
  );

  const sortedCategories = filteredCategories.slice().sort((a, b) => a.ordem - b.ordem);

  return sortedCategories;
};

interface CSATFeedbackMultiSelectInputProps
  extends Omit<CpsMultiSelectorProps, "children" | "value" | "onChange"> {
  rating: ValidRatings;
  data: FragmentType<typeof CsatCategoriesListQueryFragment>[];
  value: { codCsatCategoria: number; nome: string }[];
  onChange: (value: { codCsatCategoria: number; nome: string }[]) => void;
}

export const CSATFeedbackMultiSelectInput = forwardRef<
  HTMLDivElement,
  CSATFeedbackMultiSelectInputProps
>(
  (
    { rating, data, value, onChange, ...props },
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    const title = rating <= 4 ? "O que podemos melhorar?" : "O que te surpreendeu?";
    const csatCategories = useFragment(CsatCategoriesListQueryFragment, data);
    const csatCategoriesByRating = csatCategoriesByRatingAndOrder(
      csatCategories as CsatCategoriesListQueryFragmentFragment[],
      rating,
    );

    const handleChange = (selectedValues: CpsSelectorType[]): void => {
      const selectedCategories = selectedValues
        .map((selectedValue) => {
          return csatCategoriesByRating.find(
            (category) => category.codCsatCategoria === selectedValue,
          ) as { codCsatCategoria: number; nome: string };
        })
        .filter(Boolean);
      onChange(selectedCategories);
    };

    const selectedValues = value.map((category) => category.codCsatCategoria);

    return (
      <CpsSelectorRoot>
        <div className="flex flex-col gap-3">
          {rating !== 0 ? (
            <div className="flex justify-center text-neutral-500">
              <CpsSelectorTitle required={props.required}>{title}</CpsSelectorTitle>
            </div>
          ) : null}
          <CpsMultiSelector
            {...props}
            ref={ref}
            value={selectedValues}
            onChange={handleChange}
            activeBackgroundColor="secondary"
          >
            <div className="w-full grid grid-cols-2 gap-y-2 gap-x-4">
              {csatCategoriesByRating.map((option) => (
                <CpsSelectorItem
                  className="w-full"
                  value={option.codCsatCategoria}
                  key={option.codCsatCategoria}
                >
                  {option.nome}
                </CpsSelectorItem>
              ))}
            </div>
          </CpsMultiSelector>
        </div>
      </CpsSelectorRoot>
    );
  },
);

CSATFeedbackMultiSelectInput.displayName = "CSATFeedbackMultiSelectInput";
