const IP_REGEX = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
const TRACE_URL = "https://www.cloudflare.com/cdn-cgi/trace";

function text(url: string): Promise<string> {
  return fetch(url).then((res) => res.text());
}

export const getIP = (): Promise<string> => {
  return text(TRACE_URL).then((data) => {
    return IP_REGEX.exec(data)?.[0] ?? "";
  });
};
