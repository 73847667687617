import { Calendar } from "@repo/icons";
import { useRouter, useSearch } from "@tanstack/react-router";
import { FlexiblePriceDrawer } from "@/components/flexible-price-drawer";
import { useMinimumAntecedenceFlexiblePrice } from "@/hooks/use-minimum-antecedence-flexible-price";

interface FlexiblePriceAppointmentRescheduleDrawerProps {
  appointmentId: number;
}

export const FlexiblePriceAppointmentRescheduleDrawer = ({
  appointmentId,
}: FlexiblePriceAppointmentRescheduleDrawerProps): JSX.Element => {
  const { action }: { action: string } = useSearch({ strict: false });

  const router = useRouter();

  const minimumAntecedence = useMinimumAntecedenceFlexiblePrice();

  const handleDrawerDismiss = (value: boolean): void => {
    if (!value && action === "reschedule-flexible-price-appointment") {
      router.history.back();
    }
  };

  const handleRescheduleSubmit = (): void => {
    void router.navigate({
      to: "/appointment/$appointmentId/reschedule",
      params: { appointmentId },
      replace: true,
    });
  };

  return (
    <FlexiblePriceDrawer
      open={action === "reschedule-flexible-price-appointment"}
      icon={Calendar}
      title="Reagendar"
      description={`Você tem a opção de reagendar este atendimento com até ${Math.floor(minimumAntecedence / 24)} dias de antecedência, do contrário, será necessário efetuar o pagamento integral do agendamento.`}
      onSubmit={handleRescheduleSubmit}
      setOpen={handleDrawerDismiss}
      appointmentId={appointmentId}
    />
  );
};
