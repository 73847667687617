import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { formatWithZonedDate, getCurrentDate } from "@repo/lib";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { UnavailableSlotsForm } from "@/components/unavailable-slots-form";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";

const unavailableSlotSchema = z.object({
  date: z
    .string()
    .optional()
    .refine((value) => !value || /^\d{4}-\d{2}-\d{2}$/.test(value))
    .catch(formatWithZonedDate(getCurrentDate())),
  locationId: z.number().optional(),
  start: z.string().optional(),
  end: z.string().optional(),
});

const UnavailableSlotsPageQuery = graphql(`
  query UnavailableSlotsPageQuery($dataInicio: date!) {
    usuariosAgendas(
      where: {
        ativo: { _eq: true }
        tbUsuariosAgendasCompromissos: { ativo: { _eq: true } }
        _or: [{ dataFim: { _gte: $dataInicio } }, { dataFim: { _is_null: true } }]
      }
    ) {
      ...UnavailableSlotFormFragment
    }
  }
`);

export const UnavailableSlotsCreate = (): JSX.Element => {
  const queryResult = useGraphQL(UnavailableSlotsPageQuery, {
    dataInicio: formatWithZonedDate(new Date(), "yyyy-MM-dd"),
  });

  const { data: queryData } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Fechar agenda" align="center" />
        <HeaderButton icon={Left} align="start" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {queryData ? (
            <UnavailableSlotsForm queryData={queryData.usuariosAgendas} />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/calendar/unavailable-slots/create")({
  component: UnavailableSlotsCreate,
  validateSearch: (search) => unavailableSlotSchema.parse(search),
});
