import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types";
import { ReservedScheduleTypeEnum } from "@repo/lib";
import { useAtomValue } from "jotai";
import { z } from "zod";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { ReservedScheduleConfirm } from "@/components/reserved-schedule-confirm";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { useGraphQL } from "@/hooks/use-graphql";
import { reservedScheduleFormAtom } from "@/lib/atoms/reserved-schedule-form-atom";

const GetReservedSchedulePriceQuery = graphql(/* GraphQL */ `
  query GetReservedSchedulePriceQuery(
    $codModalidadePeriodoGarantido: Int!
    $codTipoSala: Int!
    $codUnidade: Int!
    $codUsuario: Int!
    $duracao: String!
  ) {
    ...GetReservedSchedulePriceFragment
  }
`);

export const ReservedScheduleConfirmPage = (): JSX.Element => {
  const { reservedScheduleType } = useLoaderData({
    from: "/settings/reserved-schedules/create/confirm",
  });
  const { user } = Route.useRouteContext();
  const reservedScheduleAtom = useAtomValue(reservedScheduleFormAtom);

  const queryResult = useGraphQL(GetReservedSchedulePriceQuery, {
    codModalidadePeriodoGarantido: reservedScheduleType,
    codTipoSala: reservedScheduleAtom.roomType,
    codUnidade: reservedScheduleAtom.location,
    codUsuario: user.codUsuario,
    duracao: reservedScheduleAtom.duration,
  });

  const { data } = queryResult;
  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Novo período garantido" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <ReservedScheduleConfirm
              reservedSchedulePrice={data}
              reservedScheduleType={reservedScheduleType}
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

const reservedScheduleSearchSchema = z.object({
  action: z.string().optional(),
});

export const Route = createFileRoute("/settings/reserved-schedules/create/confirm")({
  component: ReservedScheduleConfirmPage,
  beforeLoad: ({ context }) => {
    return {
      reservedScheduleTypeByUser: context.ldClient.getReservedScheduleTypeByUser(),
    };
  },
  loader: ({ context }) => {
    let reservedScheduleType = context.reservedScheduleTypeByUser;
    if (
      context.user.codParceiroPortoSeguro &&
      reservedScheduleType !== ReservedScheduleTypeEnum.Porto.valueOf()
    ) {
      reservedScheduleType = ReservedScheduleTypeEnum.Porto.valueOf();
    }
    return { reservedScheduleType };
  },
  validateSearch: reservedScheduleSearchSchema,
});
