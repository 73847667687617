export enum DaysOfWeekEnum {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const MapDaysOfWeekToFirstLetter = new Map<DaysOfWeekEnum, string>([
  [DaysOfWeekEnum.Sunday, "D"],
  [DaysOfWeekEnum.Monday, "S"],
  [DaysOfWeekEnum.Tuesday, "T"],
  [DaysOfWeekEnum.Wednesday, "Q"],
  [DaysOfWeekEnum.Thursday, "Q"],
  [DaysOfWeekEnum.Friday, "S"],
  [DaysOfWeekEnum.Saturday, "S"],
]);

export const DaysOfWeekToPortugueseAdverbial = new Map<DaysOfWeekEnum, string>([
  [DaysOfWeekEnum.Sunday, "Aos domingos"],
  [DaysOfWeekEnum.Monday, "Às segundas-feiras"],
  [DaysOfWeekEnum.Tuesday, "Às terças-feiras"],
  [DaysOfWeekEnum.Wednesday, "Às quartas-feiras"],
  [DaysOfWeekEnum.Thursday, "Às quintas-feiras"],
  [DaysOfWeekEnum.Friday, "Às sextas-feiras"],
  [DaysOfWeekEnum.Saturday, "Aos sábados"],
]);

export const MapDaysOfWeekToFullName = new Map<DaysOfWeekEnum, string>([
  [DaysOfWeekEnum.Sunday, "domingo"],
  [DaysOfWeekEnum.Monday, "segunda-feira"],
  [DaysOfWeekEnum.Tuesday, "terça-feira"],
  [DaysOfWeekEnum.Wednesday, "quarta-feira"],
  [DaysOfWeekEnum.Thursday, "quinta-feira"],
  [DaysOfWeekEnum.Friday, "sexta-feira"],
  [DaysOfWeekEnum.Saturday, "sábado"],
]);
