interface Time {
  hour: number;
  minute: number;
  second: number;
}

interface Period {
  startTime: Time | string;
  endTime: Time | string;
}

export const parseTime = (timeStr: string): Time => {
  if (!timeStr) {
    return {
      hour: 0,
      minute: 0,
      second: 0,
    };
  }

  const time = timeStr.split(":");

  const hour = time.length > 1 ? Number(time[0]) : 0;
  const minute = time.length >= 2 ? Number(time[1]) : 0;
  const second = time.length === 3 ? Number(time[2]) : 0;

  return {
    hour,
    minute,
    second,
  };
};

/**
 * Formata um horário exibindo apenas as horas e minutos.
 * @param time - Horário a ser formatado.
 * @returns Horário formatado.
 */
export const formatTimeHourMinute = (time: Time | string): string => {
  const parsedTime = typeof time === "string" ? parseTime(time) : time;
  return `${parsedTime.hour.toString().padStart(2, "0")}:${parsedTime.minute.toString().padStart(2, "0")}`;
};

/**
 * Calcula o total em minutos de um tempo.
 * @param time - Horário a ser calculado.
 * @returns Total de minutos do horário.
 */
export const getTotalMinutes = (time: Time | string): number => {
  const parsedTime = typeof time === "string" ? parseTime(time) : time;
  return parsedTime.hour * 60 + parsedTime.minute;
};

/**
 * Calcula o total de minutos entre dois horários A função desconsidera os segundos.
 * @param period - Período que deve ser calculado.
 * @returns total de minutos entre os horários.
 */
export const differenceInMinutes = ({ startTime, endTime }: Period): number => {
  const parsedStartTime =
    typeof startTime === "string" ? parseTime(startTime) : startTime;
  const parsedEndTime = typeof endTime === "string" ? parseTime(endTime) : endTime;

  const startTimeTotalMinutes = parsedStartTime.hour * 60 + parsedStartTime.minute;
  const endTimeTotalMinutes = parsedEndTime.hour * 60 + parsedEndTime.minute;
  const diff = endTimeTotalMinutes - startTimeTotalMinutes;

  return diff;
};

/**
 * Formata um total de minutos para o formato "HH:mm".
 * @param totalMinutes - Total de minutos a ser formatado.
 * @returns tempo formatado em HH:mm
 */
export function formatTimeHourMinuteByTotalMinutes(totalMinutes: number): string {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

/**
 * Recebe uma hora e uma duração e retorna a hora resultante da soma.
 * @param time - Hora inicial.
 * @param duration - Duração a ser somada, em HH:mm.
 * @returns hora final formatado em HH:mm
 */
export const addDurationToTime = (time: string, duration: string): string => {
  const [hours1, minutes1] = time.split(":").map(Number);
  const [hours2, minutes2] = duration.split(":").map(Number);

  let hours = hours1 + hours2;
  let minutes = minutes1 + minutes2;

  if (minutes >= 60) {
    hours += Math.floor(minutes / 60);
    minutes %= 60;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};
