import {
  createFileRoute,
  redirect,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { type DocumentType, graphql } from "@repo/graphql-types";
import { Left, Trash } from "@repo/icons";
import { z } from "zod";
import { useState } from "react";
import { queryFn, useGraphQL, useGraphQLMutation } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AppointmentReceivingOptionsForm } from "@/components/appointment-receiving-options-form";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
  MessageDrawerActions,
  MessageDrawerActionButton,
} from "@/components/message-drawer";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { PendingComponent } from "@/components/pending-component";

const AppointmentReceivingOptionPageQuery = graphql(/* GraphQL */ `
  query AppointmentReceivingOptionPageQuery($codUsuarioFormaRecebimento: Int = 0) {
    formaRecebimento: usuariosFormasRecebimentos_by_pk(
      codUsuarioFormaRecebimento: $codUsuarioFormaRecebimento
    ) {
      codUsuarioFormaRecebimento
      sistema
    }
    ...AppointmentReceivingOptionsFormQueryFragment
  }
`);

const appointmentReceivingOptionSchema = z.object({
  appointmentReceivingOptionId: z.number().optional(),
  action: z.string().optional(),
});

const DeleteAppointmentReceivingOptionMutation = graphql(`
  mutation DeleteAppointmentReceivingOption($input: Int!) {
    LivanceApiDeleteUsuarioFormaRecebimento(codUsuarioFormaRecebimento: $input)
  }
`);

export const AppointmentReceivingOptionPage = (): JSX.Element => {
  const params = Route.useParams();

  const { queryClient } = useRouteContext({ strict: false });

  const router = useRouter();

  const searchParams = useSearch({
    from: "/settings/schedule-options/appointment-receiving-options/$appointmentReceivingOptionId",
  });

  const queryResult = useGraphQL(AppointmentReceivingOptionPageQuery, {
    codUsuarioFormaRecebimento: Number(params.appointmentReceivingOptionId),
  });

  const { data } = queryResult;

  const setShowDeleteConfirmationDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/schedule-options/appointment-receiving-options/$appointmentReceivingOptionId",
        params: {
          appointmentReceivingOptionId: String(params.appointmentReceivingOptionId),
        },
        search: { action: "delete" },
      });
    } else if (searchParams.action === "delete") {
      router.history.back();
    }
  };

  const [showUnexpectedErrorDrawer, setShowUnexpectedErrorDrawer] = useState(false);

  const { mutateAsync, isPending } = useGraphQLMutation(
    DeleteAppointmentReceivingOptionMutation,
  );

  const onSuccessDelete = (): void => {
    void queryClient.resetQueries({
      queryKey: ["AppointmentReceivingOptionsPageQuery"],
    });
    router.history.go(-2);
  };

  const onErrorDelete = (): void => {
    setShowDeleteConfirmationDrawer(false);
    setShowUnexpectedErrorDrawer(true);
  };

  const onConfirmDelete = async (): Promise<void> => {
    await mutateAsync(
      {
        input: Number(params.appointmentReceivingOptionId),
      },
      {
        onSuccess: onSuccessDelete,
        onError: onErrorDelete,
      },
    );
  };

  const enableDelete = data && !data.formaRecebimento?.sistema;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Editar convênio" align="center" />
        {enableDelete ? (
          <HeaderButton
            icon={Trash}
            onClick={() => {
              setShowDeleteConfirmationDrawer(true);
            }}
            align="end"
          />
        ) : (
          <div />
        )}
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data && queryResult.isFetchedAfterMount ? (
            <AppointmentReceivingOptionsForm data={data} />
          ) : (
            <FullPageSpinner />
          )}
        </AsyncDataWrapper>
        <MessageDrawerRoot
          icon={Trash}
          variant="secondary"
          open={searchParams.action === "delete"}
          setOpen={setShowDeleteConfirmationDrawer}
        >
          <MessageDrawerTitle>Excluir convênio</MessageDrawerTitle>
          <MessageDrawerBody>
            Tem certeza de que deseja excluir este convênio?
          </MessageDrawerBody>
          <MessageDrawerActions>
            <MessageDrawerActionButton
              onClick={() => void onConfirmDelete()}
              loading={isPending}
            >
              Excluir
            </MessageDrawerActionButton>
          </MessageDrawerActions>
        </MessageDrawerRoot>
        <UnexpectedErrorDrawer
          open={showUnexpectedErrorDrawer}
          setOpen={setShowUnexpectedErrorDrawer}
        />
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/schedule-options/appointment-receiving-options/$appointmentReceivingOptionId",
)({
  loader: async (opts) => {
    try {
      const data = await opts.context.queryClient.ensureQueryData<
        DocumentType<typeof AppointmentReceivingOptionPageQuery>
      >({
        queryKey: [
          "AppointmentReceivingOptionPageQuery",
          {
            codUsuarioFormaRecebimento: Number(opts.params.appointmentReceivingOptionId),
          },
        ],
        queryFn: () =>
          queryFn(AppointmentReceivingOptionPageQuery, opts.context.authClient, {
            codUsuarioFormaRecebimento: Number(opts.params.appointmentReceivingOptionId),
          }),
      });

      if (!data.formaRecebimento) throw new Error("data is empty");
    } catch (e) {
      redirect({
        to: "/settings/schedule-options/appointment-receiving-options",
        throw: true,
      });
    }
  },
  component: AppointmentReceivingOptionPage,
  pendingComponent: () => <PendingComponent title="Editar convênio" />,
  validateSearch: (search) => appointmentReceivingOptionSchema.parse(search),
});
