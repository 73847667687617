import { useFragment, type FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { CpsToggle } from "corpus";
import { CreditCard, HelpCircle } from "@repo/icons";
import { useState } from "react";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import {
  TooltipDrawerBody,
  TooltipDrawerRoot,
  TooltipDrawerTitle,
} from "@/components/tooltip-drawer";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

export const AutomaticAnticipationToggleQueryFragment = graphql(/* GraphQL */ `
  fragment AutomaticAnticipationToggleQueryFragment on query_root {
    ExpensumGetAutomaticAnticipationSettingsByRecipient(
      arg1: { codUsuario: $codUsuario, codClinica: $codClinica }
    ) {
      automaticAnticipationSettings {
        enabled
      }
    }
  }
`);

export const EnableAutomaticAnticipationMutation = graphql(/* GraphQL */ `
  mutation EnableAutomaticAnticipationMutation(
    $object: expensum_event_automatic_anticipation_settings_update_insert_input!
  ) {
    insert_expensum_event_automatic_anticipation_settings_update_one(object: $object) {
      id
    }
  }
`);

export const DisableAutomaticAnticipationMutation = graphql(/* GraphQL */ `
  mutation DisableAutomaticAnticipationMutation($codUsuario: Int!, $codClinica: Int) {
    insert_expensum_event_automatic_anticipation_settings_update_one(
      object: { enabled: false, codUsuario: $codUsuario, codClinica: $codClinica }
    ) {
      id
    }
  }
`);

enum AutomaticAnticipationToggleAction {
  AutomaticAnticipationInfo = "automatic-anticipation-info",
  AutomaticAnticipationConfirmation = "automatic-anticipation-confirmation",
  Error = "error",
}

interface AutomaticAnticipationToggleProps {
  data: FragmentType<typeof AutomaticAnticipationToggleQueryFragment>;
}

export const AutomaticAnticipationToggle = ({
  data,
}: AutomaticAnticipationToggleProps): JSX.Element => {
  const router = useRouter();
  const { user, queryClient } = useRouteContext({ strict: false });
  const { action } = useSearch({
    from: "/settings/payments/bank-account/",
  });

  const automaticAnticipationSettingsData = useFragment(
    AutomaticAnticipationToggleQueryFragment,
    data,
  );

  const {
    mutateAsync: enableAutomaticAnticipationMutateAsync,
    isPending: enableAutomaticAnticipationIsPending,
  } = useGraphQLMutation(EnableAutomaticAnticipationMutation);
  const {
    mutateAsync: disableAutomaticAnticipationMutateAsync,
    isPending: disableAutomaticAnticipationIsPending,
  } = useGraphQLMutation(DisableAutomaticAnticipationMutation);

  const [automaticAnticipationEnabled, setAutomaticAnticipationEnabled] =
    useState<boolean>(
      automaticAnticipationSettingsData
        .ExpensumGetAutomaticAnticipationSettingsByRecipient
        ?.automaticAnticipationSettings.enabled ?? false,
    );

  const showAutomaticAnticipationInfoDrawer =
    action === AutomaticAnticipationToggleAction.AutomaticAnticipationInfo.valueOf();
  const setShowAutomaticAnticipationInfoDrawer = (show: boolean): void => {
    if (show) {
      void router.navigate({
        to: "/settings/payments/bank-account/",
        search: {
          action: AutomaticAnticipationToggleAction.AutomaticAnticipationInfo.valueOf(),
        },
      });
    } else if (showAutomaticAnticipationInfoDrawer) {
      router.history.back();
    }
  };

  const showAutomaticAnticipationChangeConfirmationDrawer =
    action ===
    AutomaticAnticipationToggleAction.AutomaticAnticipationConfirmation.valueOf();
  const setShowAutomaticAnticipationChangeConfirmationDrawer = (show: boolean): void => {
    if (show) {
      void router.navigate({
        to: "/settings/payments/bank-account/",
        search: {
          action:
            AutomaticAnticipationToggleAction.AutomaticAnticipationConfirmation.valueOf(),
        },
      });
    } else if (showAutomaticAnticipationChangeConfirmationDrawer) {
      router.history.back();
    }
  };

  const showUnexpectedErrorDrawer =
    action === AutomaticAnticipationToggleAction.Error.valueOf();
  const setShowUnexpectedErrorDrawer = (show: boolean): void => {
    if (show) {
      void router.navigate({
        to: "/settings/payments/bank-account/",
        search: {
          action: AutomaticAnticipationToggleAction.Error.valueOf(),
        },
      });
    } else if (showUnexpectedErrorDrawer) {
      router.history.back();
    }
  };

  const onClickAutomaticAnticipationToggle = (): void =>
    setShowAutomaticAnticipationChangeConfirmationDrawer(true);

  const onConfirmHandleAutomaticAnticipationToggle = async (): Promise<void> => {
    const newAutomaticAnticipationEnabled = !automaticAnticipationEnabled;

    if (newAutomaticAnticipationEnabled) {
      const automaticAnticipation = {
        days: Array.from({ length: 31 }, (_, i) => String(i + 1)),
        delay: "1",
        enabled: true,
        type: "1025",
        volumePercentage: "100",
        codUsuario: user.codUsuario,
        codClinica: user.codClinica,
      };
      await enableAutomaticAnticipationMutateAsync(
        {
          object: automaticAnticipation,
        },
        {
          onSuccess: onAutomaticAnticipationChangeSuccess,
          onError: onAutomaticAnticipationChangeError,
        },
      );
    } else {
      await disableAutomaticAnticipationMutateAsync(
        {
          codUsuario: user.codUsuario,
          codClinica: user.codClinica,
        },
        {
          onSuccess: onAutomaticAnticipationChangeSuccess,
          onError: onAutomaticAnticipationChangeError,
        },
      );
    }
  };

  const onAutomaticAnticipationChangeSuccess = (): void => {
    void queryClient.resetQueries({ queryKey: ["BankAccountPageQuery"] });
    setShowAutomaticAnticipationChangeConfirmationDrawer(false);
    setAutomaticAnticipationEnabled(!automaticAnticipationEnabled);
  };

  const onAutomaticAnticipationChangeError = (): void => {
    setShowUnexpectedErrorDrawer(true);
  };

  return (
    <>
      <div className="bg-neutral-50 p-4 flex flex-col gap-5">
        <div className="flex justify-between">
          <p className="font-medium">Antecipação automática</p>
          <button
            onClick={() => setShowAutomaticAnticipationInfoDrawer(true)}
            tabIndex={0}
            type="button"
          >
            <HelpCircle className="fill-neutral-300" size={24} />
          </button>
        </div>
        <div className="flex justify-between gap-3 items-center">
          <p className="text-neutral-500">Pagamentos no crédito serão recebidos em D+2</p>
          <CpsToggle
            checked={automaticAnticipationEnabled}
            onChange={onClickAutomaticAnticipationToggle}
          />
        </div>
      </div>
      <TooltipDrawerRoot
        open={showAutomaticAnticipationInfoDrawer}
        setOpen={setShowAutomaticAnticipationInfoDrawer}
      >
        <TooltipDrawerTitle>Antecipação automática</TooltipDrawerTitle>
        <TooltipDrawerBody>
          Ao habilitar a antecipação automática, seus pagamentos no crédito ocorrerão em
          D+2 ou seja, no 2º dia útil após o pagamento. Se você habilitou essa função
          hoje, o pagamento ocorrerá após o 3º dia útil, pois as transferências para o dia
          seguinte são agendadas entre 7h e 9h da manhã do dia atual. Nos próximos dias,
          ocorrerão normalmente em D+2.
          <br />
          <br />
          A taxa de antecipação por parcela é de 1,45%, é calculada a juros simples e
          aplicada sobre o valor líquido da transação.
          <br />
          <br />
          Para mais informações, acesse a{" "}
          <a
            href="https://ajuda.livance.com.br/pt-BR/articles/6371855-como-funcionam-os-servicos-de-recebimento-dos-valores-das-minhas-consultas"
            className="underline"
            target="_blank"
            rel="noopener"
          >
            Central de Ajuda
          </a>
          .
        </TooltipDrawerBody>
      </TooltipDrawerRoot>
      <UnexpectedErrorDrawer
        open={showUnexpectedErrorDrawer}
        setOpen={setShowUnexpectedErrorDrawer}
      />
      <MessageDrawerRoot
        icon={CreditCard}
        variant="secondary"
        open={showAutomaticAnticipationChangeConfirmationDrawer}
        setOpen={setShowAutomaticAnticipationChangeConfirmationDrawer}
      >
        <MessageDrawerTitle>Antecipação automática</MessageDrawerTitle>
        <MessageDrawerBody>
          Deseja {automaticAnticipationEnabled ? "desabilitar" : "habilitar"} a
          antecipação automática para pagamento no crédito?
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            onClick={() => void onConfirmHandleAutomaticAnticipationToggle()}
            loading={
              enableAutomaticAnticipationIsPending ||
              disableAutomaticAnticipationIsPending
            }
          >
            Confirmar
          </MessageDrawerActionButton>
          <MessageDrawerActionButton
            secondary
            onClick={() => setShowAutomaticAnticipationChangeConfirmationDrawer(false)}
          >
            Cancelar
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
    </>
  );
};
