import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { add } from "date-fns";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { useRouter } from "@tanstack/react-router";
import { logError } from "@repo/lib";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { SelectInput } from "@/components/select-input";
import { AppointmentTypesSelectInput } from "@/components/appointment-types-select-input";
import { InputNumber } from "@/components/input-number";

const FollowUpAppointmentReminderInsertMutation = graphql(/* GraphQL */ `
  mutation FollowUpAppointmentReminderInsertMutation(
    $codAgendamento: Int!
    $codPaciente: Int!
    $codUsuario: Int
    $dataCadastro: datetime2!
    $codTipoLembrete: Int!
    $dataParaEnvio: datetime2!
    $codUsuarioCompromisso: Int!
  ) {
    insert_lembretes_one(
      object: {
        ativo: true
        codAgendamento: $codAgendamento
        codPaciente: $codPaciente
        codTipoLembrete: $codTipoLembrete
        codUsuario: $codUsuario
        dataCadastro: $dataCadastro
        dataParaEnvio: $dataParaEnvio
        codUsuarioCompromisso: $codUsuarioCompromisso
      }
    ) {
      codLembrete
    }
  }
`);

export const FollowUpAppointmentReminderFormFragment = graphql(/* GraphQL */ `
  fragment FollowUpAppointmentReminderFormFragment on query_root {
    usuariosCompromissos(
      where: {
        usuariosCompromissosCanaisAgendamentos: {
          codCanalAgendamento: { _eq: $canalAgendamentoPaciente }
        }
        categoria: { _neq: $categoriaPeriodoGarantido }
      }
    ) {
      ...AppointmentTypesListToSelectQueryFragment
      codUsuarioCompromisso
      nome
      duracao
    }
    appointment: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      codPaciente
      codUsuario
      codAgendamento
    }
  }
`);

interface FollowUpAppointmentReminderFormProps {
  data: FragmentType<typeof FollowUpAppointmentReminderFormFragment>;
}

export const FollowUpAppointmentReminderForm = ({
  data,
}: FollowUpAppointmentReminderFormProps): JSX.Element => {
  const queryData = useFragment(FollowUpAppointmentReminderFormFragment, data);
  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    FollowUpAppointmentReminderInsertMutation,
  );

  const router = useRouter();
  const FollowUpAppointmentReminderFormSchema = z.object({
    time: z.number().min(1).max(31),
    period: z.number(),
    appointmentTypeId: z.number().min(1),
  });

  type FollowUpAppointmentReminderFormType = z.infer<
    typeof FollowUpAppointmentReminderFormSchema
  >;

  const form = useForm<FollowUpAppointmentReminderFormType>({
    resolver: zodResolver(FollowUpAppointmentReminderFormSchema),
    defaultValues: {
      time: 1,
      period: 1,
      appointmentTypeId: 0,
    },
  });

  const onSubmit = async (
    formData: FollowUpAppointmentReminderFormType,
  ): Promise<void> => {
    const onSuccess = (): void => {
      router.history.back();
    };
    const onError = (erro: Error): void => {
      logError(erro);
    };

    const appointment = queryData.appointment;
    if (!appointment?.codPaciente) {
      throw new Error("Appointment not found");
    }

    const now = new Date();
    let timeToAdd = {};

    if (formData.period === 1) {
      timeToAdd = { days: formData.time };
    } else if (formData.period === 30) {
      timeToAdd = { months: formData.time };
    }

    const variables = {
      codAgendamento: appointment.codAgendamento,
      codPaciente: appointment.codPaciente ?? 0,
      codUsuario: appointment.codUsuario,
      codTipoLembrete: 0,
      dataParaEnvio: add(now, timeToAdd).toISOString(),
      dataCadastro: now.toISOString(),
      codUsuarioCompromisso: formData.appointmentTypeId,
    };

    await mutateAsync(variables, { onSuccess, onError });
  };

  const periodInputs: SelectDrawerItemProps<number>[] = [
    { label: "dias", value: 1 },
    { label: "meses", value: 30 },
  ];

  const maxValue = form.watch("period") === 1 ? 31 : 12;

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit} className="gap-4">
        <p>
          Configure aqui tempo para o envio automático de mensagens, garantindo que seus
          pacientes sejam notificados no momento ideal para marcar uma consulta.
        </p>
        <div className="flex flex-row justify-between gap-4">
          <div className="w-full">
            <FormField
              control={form.control}
              name="time"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputNumber
                      title="Tempo"
                      inputMode="numeric"
                      type="number"
                      max={maxValue}
                      min="1"
                      pattern="[0-9]+$"
                      style={{ appearance: "textfield" }}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="w-full">
            <FormField
              control={form.control}
              name="period"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <SelectInput
                      items={periodInputs}
                      title="Período"
                      {...field}
                      onChange={(value): void => {
                        const maxValeuByType = value === 1 ? 31 : 12;
                        const timeValue = form.getValues("time");
                        if (timeValue > maxValeuByType) {
                          form.setValue("time", maxValeuByType);
                        }
                        field.onChange(value);
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
        <FormField
          control={form.control}
          name="appointmentTypeId"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <AppointmentTypesSelectInput
                  title="Tipo de atendimento"
                  data={queryData.usuariosCompromissos}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormSubmitButton disabled={!form.formState.isValid} type="submit">
          Programar envio
        </FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
