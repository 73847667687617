import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType } from "@repo/graphql-types/fragment-masking";
import { ensureQueryData, useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import {
  ChangeTimeConfirm,
  type ChangeTimeConfirmFragment,
} from "@/components/change-time-confirm.tsx";

const ChangeTimeConfirmPageQuery = graphql(/* GraphQL */ `
  query ChangeTimeConfirmPageQuery($codAgendamento: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      cancelado
      ...ChangeTimeConfirmFragment
    }
  }
`);

export const ChangeTimeConfirmPage = (): JSX.Element => {
  const { codAgendamento } = Route.useParams();
  const queryResult = useGraphQL(ChangeTimeConfirmPageQuery, {
    codAgendamento,
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Alterar horário" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <ChangeTimeConfirm
              data={data.agendamento as FragmentType<typeof ChangeTimeConfirmFragment>}
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

const changeTimeSearchSchema = z.object({
  action: z.string().optional(),
  horaInicio: z.string(),
  horaFim: z.string(),
});

export const Route = createFileRoute(
  "/waiting-room/$codAgendamento/change-time/confirm/",
)({
  component: ChangeTimeConfirmPage,
  parseParams: (params) => ({
    codAgendamento: z.number().int().parse(Number(params.codAgendamento)),
  }),
  validateSearch: changeTimeSearchSchema,
  loader: async (opts) => {
    try {
      const variables = {
        codAgendamento: opts.params.codAgendamento,
      };

      const data = await ensureQueryData(
        opts.context,
        ChangeTimeConfirmPageQuery,
        variables,
      );

      const appointment = data.agendamento;

      const appointmentNotFound = !appointment;

      if (appointmentNotFound) throw new Error("Appointment not found!");

      const appointmentCancelled = appointment.cancelado;

      if (appointmentCancelled) throw new Error("Appointment was cancelled!");

      return { appointment };
    } catch (e) {
      redirect({
        to: "/waiting-room",
        throw: true,
      });
    }

    return {};
  },
});
