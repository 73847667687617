import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import {
  MultiSelectInput,
  type MultiSelectInputProps,
} from "@/components/multi-select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";

export const AppointmentTypesListToMultiSelectQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentTypesListToMultiSelectQueryFragment on usuariosCompromissos {
    codUsuarioCompromisso
    nome
  }
`);

interface AppointmentTypesMultiSelectInputProps
  extends Omit<MultiSelectInputProps<number>, "items"> {
  data: FragmentType<typeof AppointmentTypesListToMultiSelectQueryFragment>[];
}

export const AppointmentTypesMultiSelectInput = forwardRef<
  HTMLInputElement,
  AppointmentTypesMultiSelectInputProps
>(({ data, ...props }, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
  const appointmentTypes = useFragment(
    AppointmentTypesListToMultiSelectQueryFragment,
    data,
  );

  const items: SelectDrawerItemProps<number>[] = appointmentTypes.map(
    (appointmentType: { nome: string; codUsuarioCompromisso: number }) => {
      return {
        label: appointmentType.nome,
        value: appointmentType.codUsuarioCompromisso,
      };
    },
  );

  return <MultiSelectInput {...props} items={items} ref={ref} />;
});

AppointmentTypesMultiSelectInput.displayName = "AppointmentTypesMultiSelectInput";
