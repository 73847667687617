import { cn } from "@repo/cn";
import type { MouseEventHandler } from "react";

export interface CpsToggleProps extends React.ComponentPropsWithoutRef<"button"> {
  color?: "primary" | "secondary";
  onChange?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  checked?: boolean;
}

export const CpsToggle = ({
  color = "primary",
  disabled = false,
  checked = false,
  onChange,
  ...props
}: CpsToggleProps): JSX.Element => {
  const colorClasses = {
    primary: "bg-primary-400",
    secondary: "bg-secondary-400",
  };

  return (
    <button
      type="button"
      className={cn(
        "bg-neutral-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
        checked && colorClasses[color],
        disabled && "bg-neutral-100 cursor-not-allowed",
      )}
      role="switch"
      aria-checked={checked}
      onClick={!disabled ? onChange : undefined}
      {...props}
    >
      <span className="sr-only">Use setting</span>

      <span
        aria-hidden="true"
        className={cn(
          "translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
          checked ? "translate-x-5" : "translate-x-0",
          disabled ? "bg-neutral-200" : "bg-white",
        )}
      />
    </button>
  );
};

CpsToggle.displayName = "CpsToggle";
