import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { forwardRef } from "react";
import { SelectInput, type SelectInputProps } from "@/components/select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";

export const ScheduleFrequenciesListToSelectQueryFragment = graphql(/* GraphQL */ `
  fragment ScheduleFrequenciesListToSelectQueryFragment on tiposAgendasRepeticoes {
    codTipoAgendaRepeticao
    nome
  }
`);

interface ScheduleFrequencyInputProps extends Omit<SelectInputProps<number>, "items"> {
  data: FragmentType<typeof ScheduleFrequenciesListToSelectQueryFragment>[];
}

export const ScheduleFrequenciesSelectInput = forwardRef<
  HTMLInputElement,
  ScheduleFrequencyInputProps
>(({ data, ...props }, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
  const frequencies = useFragment(ScheduleFrequenciesListToSelectQueryFragment, data);

  const items: SelectDrawerItemProps<number>[] = frequencies.map((frequency) => {
    return {
      label: String(frequency.nome),
      value: frequency.codTipoAgendaRepeticao,
    };
  });

  return <SelectInput<number> {...props} items={items} ref={ref} />;
});

ScheduleFrequenciesSelectInput.displayName = "ScheduleFrequenciesSelectInput";
