"use client";

import React, { useEffect } from "react";

import Slider from "react-slick";

export interface ICpsTimeProps {
  initialHour?: number;
  initialMinutes?: number;
  minHour?: number;
  maxHour?: number;
  stepHour?: number;
  minMinutes?: number;
  maxMinutes?: number;
  stepMinutes?: number;
  onlyMinutes?: boolean;
  onChange?: (hour: number, minute: number) => void;
}

interface TimeScrollProps {
  type: "h" | "m";
  min?: number;
  max?: number;
  step?: number;
  initial?: number;
  onChange?: (value: number) => void;
}

const buildValuesRange = (min: number, max: number, step: number) => {
  const times = [];
  for (let i = min; i < max; i += step) {
    times.push(("0" + i).slice(-2));
  }
  return times;
};

const TimeScroll: React.FC<TimeScrollProps> = ({
  type,
  min = 0,
  max = 24,
  step = 1,
  initial,
  onChange,
}) => {
  const sliderRef = React.useRef<Slider>(null);
  const listValues = buildValuesRange(min, max, step);

  const handleScroll = (e: WheelEvent) => {
    if (sliderRef.current && e.deltaY > 0) {
      sliderRef.current.slickNext();
    }
    if (sliderRef.current && e.deltaY < 0) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    const sliderReferred = sliderRef.current?.innerSlider?.list;

    if (sliderReferred) {
      sliderReferred.addEventListener("wheel", handleScroll, { passive: false });
    }

    return () => {
      if (sliderReferred) {
        sliderReferred.removeEventListener("wheel", handleScroll, {
          passive: false,
        } as EventListenerOptions);
      }
    };
  }, []);

  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 100,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
    adaptiveHeight: false,
    initialSlide: initial ? listValues.indexOf(("0" + initial).slice(-2)) ?? 0 : 0,
    afterChange: (index: number) => {
      const changedValue = Number(listValues[index]);
      onChange && onChange(changedValue);
    },
  };

  return (
    <Slider {...settings} className="slider-entity hours" ref={sliderRef}>
      {listValues.map((value) => (
        <p key={value} data-vaul-no-drag>
          {value}
          <span className="pl-[2px] text-[8px]">{type}</span>
        </p>
      ))}
    </Slider>
  );
};

/**
 * `CpsTime` is a component that renders a time picker.
 *
 * @remarks
 * This component is part of the CPS UI library.
 *
 * @example
 * Here is a basic usage example:
 * ```tsx
 * <CpsTime initialHour={9} initialMinutes={30} />
 * ```
 * @param initialHour - The initial hour of the time picker.
 * @param initialMinutes - The initial minutes of the time picker.
 * @param minHour - The minimum hour of the time picker.
 * @param maxHour - The maximum hour of the time picker.
 * @param stepHour - The step of the hour of the time picker.
 * @param minMinutes - The minimum minutes of the time picker.
 * @param maxMinutes - The maximum minutes of the time picker.
 * @param stepMinutes - The step of the minutes of the time picker.
 * @param onlyMinutes - Whether to show only the minutes.
 * @param onChange - A function that is called when the time picker is changed.
 *
 * @returns The `CpsTime` component.
 */
export const CpsTime: React.FC<ICpsTimeProps> = ({
  initialHour,
  initialMinutes,
  minHour,
  maxHour,
  stepHour,
  minMinutes,
  maxMinutes,
  stepMinutes,
  onlyMinutes = false,
  onChange,
}) => {
  const [hour, setHour] = React.useState(initialHour ?? minHour ?? 0);
  const [minute, setMinute] = React.useState(initialMinutes ?? minMinutes ?? 0);

  const onBothChange = (value: number, type: "h" | "m") => {
    if (type === "h") {
      setHour(value);
      onChange && onChange(value, minute);
    }
    if (type === "m") {
      setMinute(value);
      onChange && onChange(hour, value);
    }
  };

  return (
    <div className="relative h-fit w-full select-none overflow-hidden" data-vaul-no-drag>
      <div
        className="time-picker relative mx-auto flex w-fit items-center justify-center gap-8 break-normal"
        data-vaul-no-drag
      >
        {!onlyMinutes && (
          <TimeScroll
            type="h"
            min={minHour}
            max={maxHour}
            step={stepHour}
            initial={initialHour}
            onChange={(h) => onBothChange(h, "h")}
          />
        )}
        <TimeScroll
          type="m"
          min={minMinutes}
          max={maxMinutes}
          step={stepMinutes}
          initial={initialMinutes}
          onChange={(m) => onBothChange(m, "m")}
        />
      </div>
      <hr className="border-1 absolute top-[44px] h-px w-full bg-neutral-100" />
      <hr className="border-1 absolute top-[98px] h-px w-full bg-neutral-100" />
    </div>
  );
};

export default CpsTime;
