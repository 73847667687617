import { useAtom } from "jotai";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { convertBrazilianCurrencyToNumber } from "@repo/lib/src/helpers/currency";
import { type ExpensumCreateOnlinePaymentOutput } from "@repo/graphql-types/graphql";
import { type Info, InfoItem, ListRoot } from "@/components/list";
import { createPaymentFormAtom } from "@/lib/atoms/create-payment-atom";
import { Button } from "@/components/button";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql";
import { CreatePaymentConfirmSuccessDrawer } from "@/components/create-payment-confirm-success-drawer";

const CreatePaymentConfirmMutation = graphql(/* GraphQL */ `
  mutation InsertOnlinePayment($object: ExpensumCreateOnlinePaymentInput!) {
    ExpensumCreateOnlinePayment(arg1: $object) {
      guid
    }
  }
`);

export const CreatePaymentConfirm = (): JSX.Element | undefined => {
  const { flags } = useRouteContext({ strict: false });
  const showCheckboxSendPaymentAutomatically = flags["envio-automatico-link-pagamento"];

  const { appointmentId, appointmentTypeId, action } = useSearch({
    from: "/settings/payments/create/confirmation",
  });
  const [atomValues] = useAtom(createPaymentFormAtom);
  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    CreatePaymentConfirmMutation,
  );
  const { user } = useRouteContext({ strict: false });

  const router = useRouter();

  if (!atomValues) {
    void router.navigate({
      to: "/",
    });

    return;
  }

  const paymentMethods = atomValues.paymentMethods.map((method) => method.description);
  const paymentMethodDescription = paymentMethods.includes("Crédito")
    ? `${paymentMethods.join(", ")} ${atomValues.installments}x`
    : paymentMethods.join(", ");

  const infos: Info[] = [
    {
      label: "Nome do paciente",
      value: atomValues.patientName,
    },
    {
      label: "E-mail",
      value: atomValues.email,
    },
    {
      label: "Celular",
      value: atomValues.phone,
    },
    {
      label: "Valor",
      value: `R$ ${atomValues.value}`,
    },
    {
      label: "Nome do serviço",
      value: atomValues.serviceName,
    },
    {
      label: "Forma de pagamento",
      value: paymentMethodDescription,
    },
  ];

  const handleSubmit = (): void => {
    const paymentTypeIds = atomValues.paymentMethods.map((method) => method.id);

    const object = {
      codClinica: user.codClinica && Number(user.codClinica),
      codUsuarioCompromisso: appointmentTypeId,
      payerName: atomValues.patientName,
      payerEmail: atomValues.email,
      payerPhone: atomValues.phone,
      codAgendamento: appointmentId,
      amount: convertBrazilianCurrencyToNumber(atomValues.value) * 100,
      productDescription: atomValues.serviceName,
      automaticSending:
        showCheckboxSendPaymentAutomatically && atomValues.sendPaymentAutomatically,
      paymentTypes: paymentTypeIds,
      installments: atomValues.installments,
    };

    const onSuccess = ({
      ExpensumCreateOnlinePayment,
    }: {
      ExpensumCreateOnlinePayment?: ExpensumCreateOnlinePaymentOutput | null;
    }): void => {
      if (ExpensumCreateOnlinePayment) {
        void router.navigate({
          search: {
            action: "success",
            guid: ExpensumCreateOnlinePayment.guid,
          },
        });
      }
    };

    void mutateAsync(
      {
        object,
      },
      { onSuccess },
    );
  };

  return (
    <>
      <h2 className="text-lg font-medium">Confirme as informações antes de enviar:</h2>
      <ListRoot>
        {infos.map((info) => (
          <InfoItem key={`${info.label}-${info.value}`} itemInfo={info} />
        ))}
      </ListRoot>
      <Button onClick={handleSubmit} fullWidth>
        Enviar pagamento
      </Button>
      <CreatePaymentConfirmSuccessDrawer open={action === "success"} />
    </>
  );
};
