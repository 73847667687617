import { atomWithReset } from "jotai/utils";
import { type z } from "zod";
import { type reservedScheduleBasicInfo } from "@/lib/form-schemas/reserved-schedule-schema";

type ReservedScheduleForm = z.infer<typeof reservedScheduleBasicInfo>;
export const reservedScheduleFormAtom = atomWithReset<ReservedScheduleForm>({
  location: 0,
  locationName: "",
  roomType: 0,
  frequency: 0,
  dayOfWeek: 0,
  duration: "",
});

export const reservedScheduleSlotFormAtom = atomWithReset({
  data: "",
  horaInicio: "",
  horaFim: "",
});

export const reservedScheduleStartingDayFormAtom = atomWithReset({
  startingDay: new Date(),
});
