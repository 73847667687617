import { zodResolver } from "@hookform/resolvers/zod";
import { CpsInput } from "corpus";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { convertToMinutes, formatIgnoringTimezone } from "@repo/lib";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { useState } from "react";
import { differenceInDays } from "date-fns";
import {
  FormHandlerSubmit,
  FormItem,
  FormControl,
  FormSubmitButton,
  FormField,
  FormRoot,
} from "@/components/form";
import { UnavailableSlotsReasonsSelectInput } from "@/components/unavailable-slots-reasons-select-input";
import { TextToggle } from "@/components/text-toggle";
import { TimeInput } from "@/components/time-input";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import {
  type UnitsListToMultiSelectQueryFragment,
  UnitsMultiSelectInput,
} from "@/components/units-multi-select-input";
import { DatePickerRange } from "@/components/date-picker-range";

export const UnavailableSlotFormFragment = graphql(/* GraphQL */ `
  fragment UnavailableSlotFormFragment on usuariosAgendas {
    usuariosAgendasUnidades: tbUsuariosAgendasUnidades(
      where: { Unidade: { ativo: { _eq: true } } }
    ) {
      Unidade {
        codUnidade
        nomeLimpo
        ...UnitsListToMultiSelectQueryFragment
      }
    }
  }
`);

const UnavailableSlotCreateMutation = graphql(`
  mutation UnavailableSlotCreateMutation($input: CriarMultiplosFechamentosInput!) {
    criarMultiplosFechamentos(input: $input) {
      data: fechamentoApiOutput {
        codFechamento
      }

      errors {
        ... on ValidationError {
          __typename
          message
        }
      }
    }
  }
`);

export const AllLocationsOption = 0;

export interface UnavailableSlotsFormProps {
  queryData: FragmentType<typeof UnavailableSlotFormFragment>[];
}

export const UnavailableSlotsForm = ({
  queryData,
}: UnavailableSlotsFormProps): JSX.Element => {
  const timerProps = {
    minHour: 7,
    minMinutes: 0,
    maxHour: 23,
    maxMinutes: 60,
    stepHour: 1,
    stepMinutes: 5,
  };

  const usuariosAgendas = useFragment(UnavailableSlotFormFragment, queryData);

  const allLocations = usuariosAgendas
    .flatMap((usuarioAgenda) =>
      usuarioAgenda.usuariosAgendasUnidades.map(
        (usuarioAgendaUnidade) => usuarioAgendaUnidade.Unidade,
      ),
    )
    .sort((a, b) => (a?.nomeLimpo ?? "").localeCompare(b?.nomeLimpo ?? ""));

  const searchParams = useSearch({ from: "/calendar/unavailable-slots/create" });

  const locationId =
    searchParams.locationId &&
    allLocations.some((x) => x?.codUnidade === searchParams.locationId)
      ? searchParams.locationId
      : AllLocationsOption;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let selectedDate = today;
  if (searchParams.date) {
    selectedDate = new Date(`${searchParams.date}T00:00:00-03:00`);
  }

  const formSchema = z
    .object({
      dateRange: z.object({
        from: z.date().optional(),
        to: z.date().optional(),
      }),
      allDay: z.boolean(),
      allUnits: z.boolean(),
      locationsId: z.string().array(),
      reason: z.string().min(5, "O motivo deve ter no mínimo 5 caracteres"),
      initialHour: z.string().optional(),
      finalHour: z.string().optional(),
      otherReason: z.string().optional(),
    })
    .superRefine((values, context) => {
      if (!values.dateRange.from) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "A data de início é obrigatória",
          path: ["dateRange"],
        });
      }
      if (values.reason === "Outro") {
        validateMotivoOutro(values, context);
      }

      if (!values.allDay) {
        validateHoraInicioFim(values, context);
      }

      if (!values.allUnits) {
        validateUnits(values, context);
      }

      if (values.dateRange.to) {
        validateDataInicioFim(values, context);
      }
    });

  const validateMotivoOutro = (values: FormFields, context: z.RefinementCtx): void => {
    const motivoOutroLength = (values.otherReason ?? "").length;
    if (motivoOutroLength < 5) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "O motivo deve ter no mínimo 5 caracteres",
        path: ["otherReason"],
      });
    }
  };

  const validateUnits = (values: FormFields, context: z.RefinementCtx): void => {
    if (!values.locationsId.length) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Selecione uma unidade",
        path: ["locationsId"],
      });
    }
  };

  const validateHoraInicioFim = (values: FormFields, context: z.RefinementCtx): void => {
    const horaInicio = convertToMinutes(values.initialHour ?? "");
    const horaFim = convertToMinutes(values.finalHour ?? "");
    if (horaInicio === horaFim) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "A hora de início e fim não podem ser iguais",
        path: ["initialHour"],
      });
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "A hora de início e fim não podem ser iguais",
        path: ["finalHour"],
      });
    }

    if (horaInicio > horaFim) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "A hora de início não pode ser maior que a hora de fim",
        path: ["initialHour"],
      });
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "A hora de início não pode ser maior que a hora de fim",
        path: ["finalHour"],
      });
    }
  };

  const validateDataInicioFim = (values: FormFields, context: z.RefinementCtx): void => {
    const { from: dataInicio, to: dataFim } = values.dateRange;

    if (dataInicio && dataFim) {
      const isDataInicioGreaterThanDataInicio = dataInicio.getTime() > dataFim.getTime();

      if (isDataInicioGreaterThanDataInicio) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "A data de início não pode ser maior do que a data de fim",
          path: ["dateRange"],
        });
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "A data de início não pode ser maior do que a data de fim",
          path: ["dateRange"],
        });
      }

      const isDifferenceBetweenDatesGreaterThanThirtyDays =
        differenceInDays(dataFim, dataInicio) > 30;

      if (isDifferenceBetweenDatesGreaterThanThirtyDays) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "O intervalo entre as datas não pode ser maior do que 30 dias",
          path: ["dateRange"],
        });
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "O intervalo entre as datas não pode ser maior do que 30 dias",
          path: ["dateRange"],
        });
      }
    }
  };

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      locationsId: locationId !== 0 ? [locationId.toString()] : [],
      dateRange: {
        from: selectedDate,
        to: undefined,
      },
      allDay: false,
      allUnits: false,
      reason: "Sem motivo",
      initialHour: searchParams.start ?? "07:00",
      finalHour: searchParams.end ?? "22:00",
      otherReason: "",
    },
  });

  const { mutateAsync } = useGraphQLMutation(UnavailableSlotCreateMutation);

  const { user } = useRouteContext({ strict: false });

  const router = useRouter();

  const handleSubmit = async (formData: FormFields): Promise<void> => {
    const onSuccess = (): void => {
      form.reset();
      void router.navigate({
        to: "/",
        search: {
          ...searchParams,
          locationId: searchParams.locationId ?? AllLocationsOption,
        },
      });
    };

    const onError = (_: Error): void => {
      setShowUnexpectedErrorDrawer(true);
    };

    const { from: dataInicio, to: dataFim } = formData.dateRange;

    if (!dataInicio) {
      form.setError("dateRange", {
        type: "required",
        message: "A data de início é obrigatória",
      });

      return;
    }

    const formattedDataInicio = formatIgnoringTimezone(dataInicio);

    const codUnidades =
      formData.allUnits || formData.locationsId.length === allLocations.length
        ? undefined
        : formData.locationsId.map(Number);

    await mutateAsync(
      {
        input: {
          codUsuario: user.codUsuario,
          dataInicio: formattedDataInicio,
          dataFim: dataFim ? formatIgnoringTimezone(dataFim) : formattedDataInicio,
          horaInicio: formData.allDay ? "00:00:00" : String(formData.initialHour),
          horaFim: formData.allDay ? "23:59:59" : String(formData.finalHour),
          motivo: formData.reason === "Outro" ? formData.otherReason : formData.reason,
          codUnidades,
        },
      },
      { onSuccess, onError },
    );
  };

  const onChangeAllUnitsToggle = (value: boolean): void => {
    if (value) {
      form.setValue("locationsId", []);
    }
  };

  const [showUnexpectedErrorDrawer, setShowUnexpectedErrorDrawer] =
    useState<boolean>(false);

  return (
    <>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={handleSubmit}>
          <FormField
            control={form.control}
            name="allUnits"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextToggle
                    label="Todas as unidades"
                    data-testid="all-units-toggle"
                    {...field}
                    onChange={(
                      event: boolean | React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      field.onChange(event);
                      onChangeAllUnitsToggle(Boolean(event));
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {!form.watch("allUnits") && (
            <FormField
              control={form.control}
              name="locationsId"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <UnitsMultiSelectInput
                      data={
                        allLocations as FragmentType<
                          typeof UnitsListToMultiSelectQueryFragment
                        >[]
                      }
                      title="Unidades"
                      required
                      searchable
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          )}

          <FormField
            control={form.control}
            name="dateRange"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <DatePickerRange
                    title="Data do fechamento"
                    disabledPastDates={today}
                    {...field}
                    value={{ from: field.value.from, to: field.value.to }}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="allDay"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextToggle label="Dia todo" data-testid="all-day-toggle" {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          {!form.watch("allDay") && (
            <div className="flex flex-row gap-4 justify-between">
              <FormField
                control={form.control}
                name="initialHour"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <TimeInput
                        label="Começa às"
                        timerConfigs={{
                          ...timerProps,
                          initialHour: Number(
                            form.control._defaultValues.initialHour?.split(":")[0] ??
                              "00",
                          ),
                          initialMinutes: Number(
                            form.control._defaultValues.initialHour?.split(":")[1] ??
                              "00",
                          ),
                        }}
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="finalHour"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <TimeInput
                        label="Termina às"
                        timerConfigs={{
                          ...timerProps,
                          initialHour: Number(
                            form.control._defaultValues.finalHour?.split(":")[0] ?? "00",
                          ),
                          initialMinutes: Number(
                            form.control._defaultValues.finalHour?.split(":")[1] ?? "00",
                          ),
                        }}
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          )}

          <FormField
            control={form.control}
            name="reason"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <UnavailableSlotsReasonsSelectInput
                    placeholder="Selecione"
                    title="Qual o motivo?"
                    required
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {form.watch("reason") === "Outro" && (
            <FormField
              control={form.control}
              name="otherReason"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CpsInput title="Motivo" type="text" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          )}

          <FormSubmitButton>Salvar</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>
      <UnexpectedErrorDrawer
        open={showUnexpectedErrorDrawer}
        setOpen={setShowUnexpectedErrorDrawer}
      />
    </>
  );
};
