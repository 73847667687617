import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AppointmentMode, zonedDate } from "@repo/lib";
import { CpsBadge, CpsRadioButtonGroup, CpsRadioButtonV2 } from "corpus";
import { type z } from "zod";
import { isAfter } from "date-fns";
import { useLoaderData } from "@tanstack/react-router";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { flexiblePricingInfo } from "@/lib/form-schemas/appointment-schema";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { FlexiblePriceAlert } from "@/components/flexible-price-alert";

interface AppointmentFlexiblePriceRadioSelectProps {
  isReservedSchedule: boolean;
  onSubmit: (formData: z.infer<typeof flexiblePricingInfo>) => Promise<void>;
  discountFee?: number;
  submitLabel?: string;
}
export const AppointmentFlexiblePriceRadioSelect = ({
  discountFee,
  isReservedSchedule,
  onSubmit,
  submitLabel = "Agendar",
}: AppointmentFlexiblePriceRadioSelectProps): JSX.Element => {
  const { minimumAntecedence }: { minimumAntecedence: number } = useLoaderData({
    strict: false,
  });

  const [atomValues] = useAtom(appointmentFormAtom);

  const checkIfNonRefundableOptionAvailable = (): boolean => {
    const today = zonedDate(new Date());

    today.setHours(today.getHours() + minimumAntecedence);

    const { appointmentTime } = atomValues;

    return isAfter(appointmentTime.start, today);
  };

  const isNonRefundableOptionAvailable =
    checkIfNonRefundableOptionAvailable() && !isReservedSchedule;

  const defaultAppointmentMode = (): AppointmentMode => {
    if (isReservedSchedule) return AppointmentMode.ReservedSchedule;

    if (isNonRefundableOptionAvailable) return AppointmentMode.NonRefundable;

    return AppointmentMode.Standard;
  };

  const form = useForm<z.infer<typeof flexiblePricingInfo>>({
    resolver: zodResolver(flexiblePricingInfo),
    defaultValues: {
      appointmentMode: defaultAppointmentMode(),
    },
  });

  const getDiscountFee = (): number => {
    return isNonRefundableOptionAvailable && discountFee ? discountFee : 0;
  };

  const convertedDiscountFee = getDiscountFee() * 100;

  const discountInfo = convertedDiscountFee ? `${convertedDiscountFee}% desconto` : "";

  const minimumAntecedenceInDays = Math.floor(minimumAntecedence / 24);

  const nonRefundableOptionLabel = `Cancele com até ${minimumAntecedenceInDays} dias de antecedência`;

  const buildAlertMessage = (): string => {
    const reservedScheduleMessage =
      "Este agendamento está dentro de um período garantido. Por essa razão, não é possível definir a modalidade de agendamento.";
    const nonRefundableOptionMessage = `Para utilizar a modalidade de cancelamento com até ${minimumAntecedenceInDays} dias, o agendamento deve ser criado com até ${minimumAntecedenceInDays} dias de antecedência.`;
    const defaultMessage = `A cobrança integral do agendamento ocorrerá em caso de cancelamento com menos de ${minimumAntecedenceInDays} dias de antecedência.`;

    if (isReservedSchedule) return reservedScheduleMessage;
    if (!isNonRefundableOptionAvailable) return nonRefundableOptionMessage;
    return defaultMessage;
  };

  const shouldDisplayAlertMessage = !(
    isNonRefundableOptionAvailable &&
    form.watch("appointmentMode") === AppointmentMode.Standard
  );

  return (
    <div className="flex flex-col gap-6">
      <FormRoot {...form}>
        <FormHandlerSubmit className="w-full" handleSubmit={onSubmit}>
          <FormField
            control={form.control}
            name="appointmentMode"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsRadioButtonGroup
                    defaultValue={defaultAppointmentMode().toString()}
                    onChange={(e) => {
                      field.onChange(Number(e));
                    }}
                  >
                    <label
                      className="text-sm font-medium text-neutral-600"
                      htmlFor="appointment-mode"
                    >
                      Escolha a modalidade de agendamento
                    </label>
                    <CpsRadioButtonV2
                      disabled={!isNonRefundableOptionAvailable}
                      value={AppointmentMode.NonRefundable}
                      label={nonRefundableOptionLabel}
                      id="non-refundable-option"
                    >
                      {discountInfo ? (
                        <p className="text-xs text-neutral-400">
                          <CpsBadge color="warning">{discountInfo}</CpsBadge>
                        </p>
                      ) : null}
                    </CpsRadioButtonV2>
                    <CpsRadioButtonV2
                      disabled={isReservedSchedule}
                      value={AppointmentMode.Standard}
                      label="Cancele a qualquer momento"
                      id="standard-option"
                    />
                    <div
                      data-testid="reserved-scheduled-option-testid"
                      className={isReservedSchedule ? "block" : "hidden"}
                    >
                      <CpsRadioButtonV2
                        value={AppointmentMode.ReservedSchedule}
                        label="Agendamento em período garantido"
                        id="reserved-scheduled-option"
                      />
                    </div>
                  </CpsRadioButtonGroup>
                </FormControl>
              </FormItem>
            )}
          />

          {shouldDisplayAlertMessage ? (
            <FlexiblePriceAlert message={buildAlertMessage()} />
          ) : null}

          <FormSubmitButton>{submitLabel}</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>
    </div>
  );
};
