import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { CpsInput, type CpsBadgeProps, CpsToast } from "corpus";
import { HelpCircle, Left } from "@repo/icons";
import { Share as CapacitorShare } from "@capacitor/share";
import { type ExpensumGetPaymentInfo } from "@repo/graphql-types/graphql";
import { formatWithZonedDate } from "@repo/lib";
import { useState } from "react";
import { AmountTitle } from "@/components/amount-title";
import { InfoSection, type InfoSectionProps } from "@/components/info-section";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { PatientPaymentDetailsDrawer } from "@/components/patient-payment-details-drawer";
import { formatTimeHourMinute } from "@/lib/time";
import { Button } from "@/components/button";
import { generateOnlinePaymentLink } from "@/lib/online-payment";

interface PatientPaymentDetailsPageParams {
  payment: ExpensumGetPaymentInfo;
  about?: boolean;
}

export const PatientPaymentDetailsPage = (): JSX.Element => {
  const router = useRouter();

  const { payment, about }: PatientPaymentDetailsPageParams = useSearch({
    from: "/settings/payments/patient-payments/details",
  });

  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const setOpenDrawer = (open: boolean): void => {
    if (open) {
      void router.navigate({
        search: {
          payment,
          about: true,
        },
      });
    } else if (about) {
      router.history.back();
    }
  };

  const installments = payment.parcelas ? `${payment.parcelas}x` : "";

  const transactionId = payment.acquirerTransactionKey
    ? payment.acquirerTransactionKey
    : "";

  const buildAppointmentSection = (): InfoSectionProps | undefined => {
    const appointment = payment.agendamento;

    if (!appointment) return undefined;

    const appointmentTime = formatTimeHourMinute(appointment.horaInicio);

    return {
      section: {
        title: "Detalhes do agendamento",
        fields: [
          { label: "Paciente", value: appointment.paciente.nome },
          {
            label: "Agendamento",
            value: `${formatWithZonedDate(appointment.data, "dd/MM/yyyy")} ${appointmentTime}`,
          },
          { label: "Unidade", value: appointment.unidade },
        ],
      },
    };
  };

  const appointmentSection = buildAppointmentSection();

  const buildPayerSection = (): InfoSectionProps => {
    const checkout = payment.checkout;

    return {
      section: {
        title: "Dados do paciente",
        fields: [
          { label: "Nome", value: checkout?.nomeDoPagador },
          {
            label: "Email",
            value: checkout?.emailDoPagador,
          },
          { label: "Telefone", value: checkout?.telefoneDoPagador },
        ],
      },
    };
  };

  const payerSection = buildPayerSection();

  const paymentSection = {
    title: "Dados do pagamento",
    fields: [
      { label: "Forma de pagamento", value: payment.meioPagamentoSelecionado },
      {
        label: "Parcelamento",
        value: installments,
      },
      {
        label: "ID transação",
        value: transactionId,
      },
      {
        label: "Origem",
        value: payment.origem,
      },
    ],
  };

  const buildBadge = {
    text: payment.pago ? "Pago" : "Em Aberto",
    color: payment.pago ? "success" : "helper",
  };

  const { checkout } = payment;

  const shouldDisplayPaymentLink =
    payment.origem === "Online" && checkout?.guid && !payment.pago;

  const paymentLinkUrl = generateOnlinePaymentLink(checkout?.guid ?? "");

  const shareLink = async (): Promise<void> => {
    const canShare = await CapacitorShare.canShare();
    if (!canShare.value) {
      await navigator.clipboard.writeText(paymentLinkUrl);
      setOpenCopiedLinkToast(true);

      return;
    }

    await CapacitorShare.share({
      url: paymentLinkUrl,
    });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Detalhes do pagamento" align="center" />
        <HeaderButton icon={HelpCircle} onClick={() => setOpenDrawer(true)} align="end" />
      </HeaderRoot>
      <Page>
        <div className="flex flex-col gap-8">
          <AmountTitle
            amount={payment.valor}
            date={payment.agendamento?.data}
            startHour={payment.agendamento?.horaInicio}
            badgeColor={buildBadge.color as CpsBadgeProps["color"]}
            badgeText={buildBadge.text}
          />
          <div className="flex flex-col gap-8">
            {appointmentSection ? (
              <InfoSection {...appointmentSection} />
            ) : (
              <InfoSection {...payerSection} />
            )}
            <InfoSection section={paymentSection} />
          </div>
          {shouldDisplayPaymentLink ? (
            <div className="flex flex-col gap-3">
              <CpsInput inputMode="text" type="text" value={paymentLinkUrl} readOnly />
              <Button onClick={() => void shareLink()}>Compartilhar</Button>
            </div>
          ) : null}
        </div>
        <PatientPaymentDetailsDrawer open={Boolean(about)} setOpen={setOpenDrawer} />
        <CpsToast
          title="Link copiado para a área de transferência"
          show={openCopiedLinkToast}
          onClose={() => setOpenCopiedLinkToast(false)}
          type="success"
          duration={10}
        />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/patient-payments/details")({
  component: PatientPaymentDetailsPage,
});
