import {
  TooltipDrawerRoot,
  TooltipDrawerTitle,
  TooltipDrawerBody,
} from "@/components/tooltip-drawer";

interface PatientPaymentDetailsDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const PatientPaymentDetailsDrawer = ({
  open,
  setOpen,
}: PatientPaymentDetailsDrawerProps): JSX.Element => {
  return (
    //TODO: Revisar texto desse tooltip
    <TooltipDrawerRoot setOpen={setOpen} open={open}>
      <TooltipDrawerTitle>Detalhes do pagamento</TooltipDrawerTitle>
      <TooltipDrawerBody>
        <p>
          Nesta tela, você analisa alguns dados sobre o pagamento selecionado na tela
          anterior.
        </p>
        <p>
          O valor exibido é o valor bruto, ou seja, é o valor sem deduções das taxas. Esse
          valor é o enviado para o totem ou para checkout online, sendo pago
          posteriormente pelo paciente.
        </p>
        <p>
          Você consegue analisar várias informações de acordo com o status do pagamento:
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Em aberto:</span> Você encontra
          o valor total do pagamento e as informações de data, horário e unidade do
          agendamento.
        </p>
        <p>
          <span className="font-medium">Importante:</span> Como esse pagamento ainda não
          foi realizado, ele continuará sendo mostrado “Em aberto” e não estará disponível
          nos seus Recebimentos.
        </p>
        <p className="mb-3">
          <span className="font-semibold text-neutral-600">Pago:</span> Você encontra o
          valor total do pagamento, a data e o horário que o pagamento foi aprovado e os
          detalhes do agendamento. Além disso, é possível analisar os dados do pagamento
          utilizados pelo paciente.
        </p>

        <p className="font-semibold text-neutral-600">Entenda o ícone “olho”:</p>
        <p>
          O ícone do olho ao lado do ícone de interrogação é uma ferramenta de
          privacidade. Ao clicar nele, os valores exibidos na tela serão ocultados,
          garantindo sua tranquilidade e segurança ao usar o aplicativo em locais
          públicos. Para exibir novamente os valores, basta clicar no ícone do olho mais
          uma vez.
        </p>
      </TooltipDrawerBody>
    </TooltipDrawerRoot>
  );
};
