import { Sentry } from "@repo/lib";
import { type ParsedLocation } from "@tanstack/react-router";

export const logNotAuthenticated = ({ location }: { location: ParsedLocation }): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `User is not authenticated. Redirecting to Login`,
      level: "info",
    },
    {
      url: location.pathname,
    },
  );
};

export const logLogoutInvalidUser = ({
  location,
  reason,
}: {
  location: ParsedLocation;
  reason: string;
}): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `User is invalid, logging out: ${reason}`,
      level: "info",
    },
    {
      url: location.pathname,
      reason,
    },
  );
};

export const logLoginStarted = ({
  location,
  retry,
}: {
  location: ParsedLocation;
  retry: number | undefined;
}): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `Login Requested`,
      level: "info",
    },
    {
      url: location.pathname,
      retry,
    },
  );
};

export const logLoginRequestedFinished = ({
  location,
}: {
  location: ParsedLocation;
}): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `Login Requested Finished`,
      level: "info",
    },
    {
      url: location.pathname,
    },
  );
};

export const logCallbackStarted = ({ location }: { location: ParsedLocation }): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `Handling redirect callback: ${location.href}`,
      level: "info",
    },
    {
      url: location.href,
    },
  );
};

export const logCallbackFinished = ({
  location,
  returnTo,
}: {
  location: ParsedLocation;
  returnTo: string;
}): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `Redirect callback handled: ${location.href}`,
      level: "info",
    },
    {
      url: location.href,
      redirectTo: returnTo,
    },
  );
};

export const logCallbackError = ({
  location,
  returnTo,
  error,
}: {
  location: ParsedLocation;
  returnTo: string;
  error: unknown;
}): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `Error handling redirect callback`,
      level: "error",
    },
    {
      url: location.href,
      redirectTo: returnTo,
      error,
    },
  );
};

export const logLogoutRequested = ({ returnTo }: { returnTo: string }): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `Logout requested`,
    },
    {
      returnTo,
    },
  );
};

export const logLogoutOpenUrl = ({ logoutUrl }: { logoutUrl: string }): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `Logout browser open`,
    },
    {
      url: logoutUrl,
    },
  );
};

export const logDeepLink = ({
  location,
  deeplinkUrl,
}: {
  location: ParsedLocation;
  deeplinkUrl: string;
}): void => {
  Sentry.addBreadcrumb(
    {
      category: "general",
      message: `Deeplink open`,
    },
    {
      url: location.pathname,
      deeplinkUrl,
    },
  );
};

export const logLogoutAccessDenied = ({
  location,
  reason,
}: {
  location: ParsedLocation;
  reason: string;
}): void => {
  Sentry.addBreadcrumb(
    {
      category: "auth",
      message: `User access denied, logging out: ${reason}`,
      level: "info",
    },
    {
      url: location.pathname,
      reason,
    },
  );
};
