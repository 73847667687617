import { ExclamationCircle } from "@repo/icons";
import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { logError } from "@repo/lib";
import { FlexiblePriceDrawer } from "@/components/flexible-price-drawer";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { useMinimumAntecedenceFlexiblePrice } from "@/hooks/use-minimum-antecedence-flexible-price";

const CancelAppointmentMutation = graphql(/* GraphQL */ `
  mutation CancelAppointmentMutation(
    $codAgendamento: Int!
    $origem: String = "discovery"
  ) {
    LivanceApiAgendamentoCancela(codAgendamento: $codAgendamento, origem: $origem) {
      codAgendamento
    }
  }
`);

interface FlexiblePriceAppointmentCancelDrawerProps {
  appointmentId: number;
  onCancelSuccess: () => void;
}

export const FlexiblePriceAppointmentCancelDrawer = ({
  appointmentId,
  onCancelSuccess,
}: FlexiblePriceAppointmentCancelDrawerProps): JSX.Element => {
  const searchParams: { action: string } = useSearch({ strict: false });

  const { action } = searchParams;

  const router = useRouter();

  const { mutate } = useGraphQLMutation(CancelAppointmentMutation);

  const minimumAntecedence = useMinimumAntecedenceFlexiblePrice();

  const handleDrawerDismiss = (value: boolean): void => {
    if (!value && action === "cancel-flexible-price-appointment") {
      void router.navigate({
        search: { action: undefined, appointmentId: undefined },
        replace: true,
      });
    }
  };

  const handleCancellationSubmit = (): void => {
    const onError = (error: Error): void => {
      logError(error, { appointmentId });

      void router.navigate({
        search: {
          ...searchParams,
          action: "cancel-flexible-price-appointment-error",
        },
        replace: true,
      });
    };

    mutate({ codAgendamento: appointmentId }, { onSuccess: onCancelSuccess, onError });
  };

  const setOpenOnErrorDrawer = (value: boolean): void => {
    if (!value && action === "cancel-flexible-price-appointment-error") {
      void router.navigate({
        search: { action: undefined, appointmentId: undefined },
        replace: true,
      });
    }
  };

  return (
    <>
      <FlexiblePriceDrawer
        open={action === "cancel-flexible-price-appointment"}
        icon={ExclamationCircle}
        description={`Você tem a opção de cancelar este atendimento com até ${Math.floor(minimumAntecedence / 24)} dias de antecedência, do contrário, será necessário efetuar o pagamento integral do agendamento.`}
        title="Cancelar"
        onSubmit={handleCancellationSubmit}
        setOpen={handleDrawerDismiss}
        appointmentId={appointmentId}
      />

      <UnexpectedErrorDrawer
        open={action === "cancel-flexible-price-appointment-error"}
        setOpen={setOpenOnErrorDrawer}
      />
    </>
  );
};
