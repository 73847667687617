import { SwitchHorizontal } from "@repo/icons";
import { CpsAlert } from "corpus";
import { type WaitingRoomAppointmentListQueryFragmentFragment } from "@repo/graphql-types/graphql";

interface WaitingRoomAppointmentAlertProps {
  appointments: WaitingRoomAppointmentListQueryFragmentFragment["agendamentos"];
  index: number;
}

const getCodTipoSala = (
  appointmentData: WaitingRoomAppointmentListQueryFragmentFragment["agendamentos"][0],
  type: "compromisso" | "agendamentoPreAlocacao" | "agendamentoAlocacaoAtiva",
): number | undefined => {
  if (type === "compromisso") {
    return appointmentData.compromisso?.UsuariosCompromissosTiposSalas[0].codTipoSala;
  }

  return appointmentData[type].length > 0
    ? appointmentData[type][0].UnidadeSala?.TipoSala.codTipoSala
    : undefined;
};

export const WaitingRoomAppointmentAlert = ({
  appointments,
  index,
}: WaitingRoomAppointmentAlertProps): JSX.Element | null => {
  const currentAppointment = appointments[index];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Necessário para evitar erro após o invalidateQuery
  if (!currentAppointment) {
    return null;
  }

  const hasAppointmentEndTimePassed = (): boolean => {
    const currentTime = new Date();
    const appointmentEndDateTime = new Date(
      `${currentTime.toDateString()} ${currentAppointment.horaFim} GMT-0300`,
    );
    return appointmentEndDateTime < currentTime;
  };

  const shouldShowAlert = (): boolean => {
    const nextAppointment =
      index === appointments.length ? undefined : appointments[index + 1];

    if (hasAppointmentEndTimePassed()) {
      return false;
    }
    const currentCod =
      getCodTipoSala(currentAppointment, "agendamentoAlocacaoAtiva") ??
      getCodTipoSala(currentAppointment, "agendamentoPreAlocacao") ??
      getCodTipoSala(currentAppointment, "compromisso");

    const nextCod =
      nextAppointment &&
      (getCodTipoSala(nextAppointment, "agendamentoAlocacaoAtiva") ??
        getCodTipoSala(nextAppointment, "agendamentoPreAlocacao") ??
        getCodTipoSala(nextAppointment, "compromisso"));

    return Boolean(nextCod) && currentCod !== nextCod;
  };

  return shouldShowAlert() ? (
    <CpsAlert
      Icon={SwitchHorizontal}
      type="info"
      title="Será necessária uma mudança de sala para o próximo atendimento!"
      description=""
    />
  ) : null;
};
