import { Left } from "@repo/icons";
import {
  createFileRoute,
  redirect,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { z } from "zod";
import { graphql } from "@repo/graphql-types";
import {
  ReservedScheduleListQueryFragmentFragmentDoc,
  ReservedSchedulesDetailsQueryDocument,
} from "@repo/graphql-types/graphql";
import { CpsSpinner } from "corpus";
import { formatWithZonedDate, logError } from "@repo/lib";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  ensureQueryData,
  useGraphQL,
  useGraphQLMutation,
  useInvalidateQuery,
} from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { ReservedScheduleTermsOfUseUrl } from "@/components/reserved-schedule-terms-of-use-url";
import { ReservedScheduleCancelSimulationInfo } from "@/components/reserved-schedule-cancel-simulation-info";
import { ReservedScheduleCancelSuccessDrawer } from "@/components/reserved-schedule-cancel-success-drawer";
import { ReservedScheduleCancelFailDrawer } from "@/components/reserved-schedule-cancel-fail-drawer";
import { Button } from "@/components/button";
import { trackEvent } from "@/lib/tracking";

const reservedScheduleIndividualCancelPageSearchParamsSchema = z.object({
  success: z.boolean().optional(),
  fail: z.boolean().optional(),
});

const ReservedScheduleIndividualCancelPageQuery = graphql(/* GraphQL */ `
  query ReservedScheduleIndividualCancelPageQuery(
    $codPeriodoGarantidoRecorrente: Int!
    $codPeriodoGarantido: Int!
  ) {
    periodoGarantidoRecorrente: tbPeriodosGarantidosRecorrentes_by_pk(
      codPeriodoGarantidoRecorrente: $codPeriodoGarantidoRecorrente
    ) {
      ativo
      dataInicio
      horaInicio
      horaFim
      diaDaSemana
      Unidade {
        nomeLimpo
        codUnidade
      }
    }
    periodoGarantido: tbPeriodosGarantidos_by_pk(
      codPeriodoGarantido: $codPeriodoGarantido
    ) {
      ativo
      codPeriodoGarantido
      codModalidadePeriodoGarantido
      Agendamento {
        data
        codAgendamento
      }
    }
  }
`);

export const ReservedScheduleIndividualCancelSimulationQuery = graphql(/* GraphQL */ `
  query ReservedScheduleIndividualCancelSimulationQuery($codPeriodoGarantido: Int!) {
    LivanceApiSimulaCobrancaDeCancelamento(
      arg1: { codPeriodoGarantido: $codPeriodoGarantido, dataReferencia: null }
    ) {
      taxa
      valor
    }
  }
`);

export const CancelIndividualReservedScheduleMutation = graphql(/* GraphQL */ `
  mutation CancelIndividualReservedScheduleMutation($codPeriodoGarantido: Int!) {
    LivanceApiCancelaPeriodoGarantido(
      arg1: { codPeriodoGarantido: $codPeriodoGarantido }
    ) {
      message
      success
    }
  }
`);

export const ReservedScheduleIndividualCancelPage = (): JSX.Element => {
  const router = useRouter();
  const { reservedScheduleId, nextReserveId } = Route.useParams();

  const { user } = useRouteContext({
    strict: false,
  });

  const params = useSearch({
    from: "/settings/reserved-schedules/$reservedScheduleId/$nextReserveId/cancel",
  });

  const queryResult = useGraphQL(ReservedScheduleIndividualCancelPageQuery, {
    codPeriodoGarantidoRecorrente: reservedScheduleId,
    codPeriodoGarantido: nextReserveId,
  });

  const { data } = queryResult;

  const reservedSchedule = data?.periodoGarantidoRecorrente;
  const nextSchedule = data?.periodoGarantido;

  const queryResultCancelSimulation = useGraphQL(
    ReservedScheduleIndividualCancelSimulationQuery,
    {
      codPeriodoGarantido: nextReserveId,
    },
    {
      enabled: Boolean(nextSchedule?.Agendamento.data),
    },
  );

  const { data: dataCancelSimulation } = queryResultCancelSimulation;
  const simulationTax =
    dataCancelSimulation?.LivanceApiSimulaCobrancaDeCancelamento?.taxa ?? 0;
  const simulationCost =
    dataCancelSimulation?.LivanceApiSimulaCobrancaDeCancelamento?.valor ?? 0;

  const { mutateAsync, isPending } = useGraphQLMutation(
    CancelIndividualReservedScheduleMutation,
  );

  const invalidateReservedScheduleListQueryFragment = useInvalidateQuery(
    ReservedScheduleListQueryFragmentFragmentDoc,
  );
  const invalidateReservedSchedulesDetailsQuery = useInvalidateQuery(
    ReservedSchedulesDetailsQueryDocument,
  );

  const handleReservedScheduleCancelAsync = async (): Promise<void> => {
    const variables = {
      codPeriodoGarantido: nextReserveId,
    };

    const onSuccess = (): void => {
      trackEvent("Periodo Garantido Pontual Cancelado", {
        codUsuario: user.codUsuario,
        codPeriodoGarantidoRecorrente: reservedScheduleId,
        codPeriodoGarantido: nextReserveId,
        codAgendamento: nextSchedule?.Agendamento.codAgendamento,
        codUnidade: reservedSchedule?.Unidade.codUnidade,
        data: formatWithZonedDate(nextSchedule?.Agendamento.data ?? " ", "dd/MM/yyyy"),
      });

      void router.navigate({
        search: {
          success: true,
        },
      });

      invalidateReservedScheduleListQueryFragment();
      invalidateReservedSchedulesDetailsQuery();
    };

    const onError = (error: Error): void => {
      logError(error, variables);
      void router.navigate({
        search: {
          fail: true,
        },
      });
    };

    await mutateAsync(variables, { onSuccess, onError });
  };

  const setOpenDrawer = (open: boolean, paramName: "success" | "fail"): void => {
    const search = {
      success: undefined,
      fail: undefined,
      ...params,
    };
    if (open) {
      search[paramName] = open;

      void router.navigate({
        search,
      });
    } else if (params[paramName]) {
      router.history.back();
    }
  };

  const date = nextSchedule
    ? formatWithZonedDate(nextSchedule.Agendamento.data, "dd/MM")
    : "";
  const locationName = reservedSchedule?.Unidade.nomeLimpo ?? "";
  const successDrawerDescription = `O período garantido do dia ${date}, na unidade ${locationName}, foi cancelado.`;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Período garantido" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          <div className="flex flex-col gap-6">
            <p className="font-medium text-lg">Cancelar período pontual</p>
            <p>
              Ao cancelar o período garantido, você perderá a garantida de disponibilidade
              de sala.
            </p>
            <AsyncDataWrapper {...queryResultCancelSimulation} fallback={<CpsSpinner />}>
              <ReservedScheduleCancelSimulationInfo
                tax={simulationTax}
                cost={simulationCost}
                locationName={reservedSchedule?.Unidade.nomeLimpo}
                dayOfWeek={reservedSchedule?.diaDaSemana}
                date={nextSchedule?.Agendamento.data}
                startTime={reservedSchedule?.horaInicio}
                endTime={reservedSchedule?.horaFim}
              />
            </AsyncDataWrapper>
            <p>
              Mais detalhes em{" "}
              <ReservedScheduleTermsOfUseUrl
                reservedScheduleType={nextSchedule?.codModalidadePeriodoGarantido ?? 0}
                title="Termos e Condições de Uso"
              />
              .
            </p>
            <Button
              data-testid="botao-cancela-periodo-garantido-individual"
              onClick={() => {
                void handleReservedScheduleCancelAsync();
              }}
              loading={isPending}
            >
              Cancelar período pontual
            </Button>
          </div>
          <ReservedScheduleCancelSuccessDrawer
            description={successDrawerDescription}
            open={Boolean(params.success)}
            primaryButtonText="Ir para meu período"
            secondaryButtonText="Ir para agenda"
            primaryButtonAction={() => {
              router.history.go(-3);
            }}
            secondaryButtonAction={() => {
              void router.navigate({
                to: "/",
              });
            }}
          />
          <ReservedScheduleCancelFailDrawer
            open={Boolean(params.fail)}
            setOpen={(open: boolean) => {
              setOpenDrawer(open, "fail");
            }}
          />
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/reserved-schedules/$reservedScheduleId/$nextReserveId/cancel",
)({
  component: ReservedScheduleIndividualCancelPage,
  validateSearch: reservedScheduleIndividualCancelPageSearchParamsSchema,
  parseParams: (params) => ({
    reservedScheduleId: z.number().int().parse(Number(params.reservedScheduleId)),
    nextReserveId: z.number().int().parse(Number(params.nextReserveId)),
  }),
  beforeLoad: async (opts) => {
    try {
      const data = await ensureQueryData(
        opts.context,
        ReservedScheduleIndividualCancelPageQuery,
        {
          codPeriodoGarantidoRecorrente: opts.params.reservedScheduleId,
          codPeriodoGarantido: opts.params.nextReserveId,
        },
      );

      if (!data.periodoGarantidoRecorrente?.ativo || !data.periodoGarantido?.ativo) {
        throw new Error("reserved schedule is not active");
      }
    } catch (e) {
      redirect({
        to: "/settings/reserved-schedules",
        throw: true,
      });
    }
  },
});
