import { CpsInput, type CpsInputProps } from "corpus";
import { Register } from "@repo/icons";
import { useState } from "react";
import { formatCPF } from "@repo/lib";

interface InputCpfProps extends CpsInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
}

export const InputCpf = ({
  title = "CPF",
  defaultValue = "",
  onChange,
  ...props
}: InputCpfProps): JSX.Element => {
  const [cpfValue, setCpfValue] = useState(defaultValue);

  const handleChangeElement = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const maskedValue = formatCPF(value);
    if (onChange) {
      onChange(maskedValue);
    }
    setCpfValue(maskedValue);
  };

  return (
    <CpsInput
      IconLeft={Register}
      title={title}
      value={cpfValue}
      placeholder="Digite aqui"
      inputMode="numeric"
      onChange={handleChangeElement}
      maxLength={14}
      {...props}
    />
  );
};
