import { z } from "zod";

const envSchema = z.object({
  VITE_RUDDERSTACK_WRITE_KEY: z.string(),
  VITE_RUDDERSTACK_DATA_PLANE_URL: z.string().url(),
  VITE_AUTH0_DOMAIN: z.string().default("livance-dev.us.auth0.com"),
  VITE_AUTH0_CLIENTID: z.string().default("1IYeU8ROno8E4BkJ6vfOWuPiMKVWcku9"),
  VITE_AUTH0_AUDIENCE: z.string().default("dev-audience"),
  VITE_AUTH0_SCOPE: z.string().default("openid profile email offline_access api.all"),
  VITE_AUTH0_ISSUER: z.string().default("https://livance-dev.us.auth0.com/"),
  VITE_LAUNCHDARKLY_SDK_KEY: z.string(),
  VITE_SENTRY_DSN: z.string(),
  VITE_SENTRY_ENVIRONMENT: z.string(),
  VITE_FAVICON: z.string(),
  VITE_URL_NO_HTTPS: z.string().default("app.livance.com.br"),
  VITE_HASURA_ENDPOINT: z.string().default("hasura.livance.com.br/v1/graphql"),
  VITE_PAGARME_ENCRYPTED_KEY: z.string(),
  VITE_LIVANCE_API_URL: z.string(),
  VITE_EXPENSUM_URL: z.string().url(),
  VITE_USER_PILOT_TOKEN: z.string(),
  VITE_PENDO_API_KEY: z.string(),
});

export const env = envSchema.parse(import.meta.env);
