import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { ExclamationCircle, HelpCircle } from "@repo/icons";
import { RoomTypeEnum } from "@repo/lib";
import { forwardRef, useState } from "react";
import { CpsFeedbackBubble } from "corpus";
import {
  TooltipDrawerRoot,
  TooltipDrawerTitle,
  TooltipDrawerBody,
} from "@/components/tooltip-drawer";
import {
  PriorityMultiSelectorItem,
  PriorityMultiSelectorList,
  PriorityMultiSelectorRoot,
  PriorityMultiSelectorTitle,
} from "@/components/priority-multi-selector";

const helpDialogParagraph = [
  "Oferecemos três tipos de sala: Multidisciplinar, Ginecologia e Psicologia.",
  "A numeração atribuída representa a prioridade na liberação das salas.",
  "Selecione com atenção para priorizar salas conforme sua utilização. Evite dar prioridade a salas que não serão utilizadas.",
  "Vale lembrar que tipos de atendimento deve conter apenas salas de Psicologia ou apenas Multidisciplinar/Ginecologia.",
];

export const RoomTypesListQueryFragment = graphql(/* GraphQL */ `
  fragment RoomTypesListQueryFragment on tiposSalas {
    codTipoSala
    nome
  }
`);

export interface RoomTypesPriorityMultiSelectorProps {
  data: FragmentType<typeof RoomTypesListQueryFragment>[];
  selectedRoomTypes?: number[];
  setSelectedRoomTypes: (roomTypes: number[]) => void;
  required?: boolean;
  onChange?: (values: number[]) => void;
}

export const RoomTypesPriorityMultiSelectorInput = forwardRef<
  HTMLInputElement,
  RoomTypesPriorityMultiSelectorProps
>(
  (
    { data, selectedRoomTypes = [], setSelectedRoomTypes, required = false, onChange },
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const [selectedRoomTypesInvalidDialogShow, setSelectedRoomTypesInvalidDialogShow] =
      useState<boolean>(false);
    const [roomTypesHelpDialogShow, setRoomTypesHelpDialogShow] =
      useState<boolean>(false);

    const roomTypes = useFragment(RoomTypesListQueryFragment, data);

    const psicoRoomCode: number = RoomTypeEnum.Psico;
    const multiRoomCode: number = RoomTypeEnum.Multidisciplinar;
    const goRoomCode: number = RoomTypeEnum.GO;

    const hasPsico = (roomTypesArray: number[]): boolean =>
      roomTypesArray.some((x) => x === psicoRoomCode);
    const hasMultiOrGO = (roomTypesArray: number[]): boolean =>
      roomTypesArray.some((x) => x === multiRoomCode || x === goRoomCode);

    const areSelectedRoomTypesValid = (values: number[]): boolean =>
      !(hasPsico(values) && hasMultiOrGO(values));
    const isRoomTypeValid = (value: number): boolean =>
      !(
        (hasMultiOrGO(selectedRoomTypes) && value === psicoRoomCode) ||
        (hasPsico(selectedRoomTypes) && (value === multiRoomCode || value === goRoomCode))
      );

    const prepareRoomTypesToSelect = (): RoomTypeSelect[] =>
      roomTypes.map((tiposSala) => {
        return {
          value: tiposSala.codTipoSala,
          label: tiposSala.nome,
        };
      });

    const handleSetSelectedItems = (values: number[]): void => {
      if (areSelectedRoomTypesValid(values)) {
        setSelectedRoomTypes(values);
        onChange && onChange(values);
      }
    };

    return (
      <>
        <TooltipDrawerRoot
          open={roomTypesHelpDialogShow}
          setOpen={setRoomTypesHelpDialogShow}
        >
          <TooltipDrawerTitle>Tipos de sala</TooltipDrawerTitle>
          <TooltipDrawerBody>
            {helpDialogParagraph.map((text) => (
              <div className="mb-1" key={`help-drawer-${text.slice(0, 3)}`}>
                {text}
              </div>
            ))}
          </TooltipDrawerBody>
        </TooltipDrawerRoot>

        <TooltipDrawerRoot
          open={selectedRoomTypesInvalidDialogShow}
          setOpen={setSelectedRoomTypesInvalidDialogShow}
        >
          <TooltipDrawerTitle>
            <div className="mb-3 flex w-full justify-center">
              <CpsFeedbackBubble
                Icon={ExclamationCircle}
                fill="secondary"
                size="medium"
              />
            </div>
          </TooltipDrawerTitle>
          <TooltipDrawerBody>
            O tipo de atendimento deve conter apenas salas Psico ou apenas Multi/GO
          </TooltipDrawerBody>
        </TooltipDrawerRoot>

        <PriorityMultiSelectorRoot
          name="room-types"
          onChange={(values) =>
            handleSetSelectedItems(values.map((item) => Number(item)))
          }
          initialCheckedValues={selectedRoomTypes.map((roomType) => String(roomType))}
        >
          <PriorityMultiSelectorTitle
            required={required}
            Icon={HelpCircle}
            onIconClick={() => setRoomTypesHelpDialogShow(true)}
            aria-label="Mais informações sobre tipos de sala"
          >
            Tipos de sala
          </PriorityMultiSelectorTitle>
          <PriorityMultiSelectorList>
            {prepareRoomTypesToSelect().map((roomType) => {
              const isValid = isRoomTypeValid(roomType.value);

              return (
                <PriorityMultiSelectorItem
                  key={roomType.value}
                  value={roomType.value}
                  id={String(roomType.value)}
                  isValid={isValid}
                  onClick={() => {
                    if (!isValid) setSelectedRoomTypesInvalidDialogShow(true);
                  }}
                  ref={ref}
                >
                  {roomType.label}
                </PriorityMultiSelectorItem>
              );
            })}
          </PriorityMultiSelectorList>
        </PriorityMultiSelectorRoot>
      </>
    );
  },
);

RoomTypesPriorityMultiSelectorInput.displayName = "RoomTypesPriorityMultiSelectorInput";

interface RoomTypeSelect {
  value: number;
  label: string;
}
