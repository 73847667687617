import { formatWithZonedDate } from "@repo/lib";
import { type InputHTMLAttributes, forwardRef, useState } from "react";
import { Calendar } from "@repo/icons";
import { CpsInput } from "corpus";
import { type CaptionLayout, type DateFormatter, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import "react-day-picker/dist/style.css";
import "@/styles/day-picker.css";
import { Button } from "@/components/button";
import { parseDate } from "@/lib/date";
import { DatePickerRoot } from "@/components/date-picker-root";
import { DrawerContent, DrawerFooter, DrawerRoot } from "@/components/drawer";

interface DatePickerSingleProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
  disabledPastDates?: Date;
  disabledFutureDates?: Date;
  captionLayout?: CaptionLayout;
  showOutsideDays?: boolean;
  fromYear?: number;
  toYear?: number;
  onChange?: (date: Date) => void;
  value?: Date;
}

const MINIMAL_DATE = new Date(-8640000000000000);
const MAXIMUM_DATE = new Date(8640000000000000);
export const DatePicker = forwardRef<HTMLInputElement, DatePickerSingleProps>(
  (
    {
      title = "Selecione a data",
      disabledPastDates = MINIMAL_DATE,
      disabledFutureDates = MAXIMUM_DATE,
      captionLayout,
      showOutsideDays = true,
      fromYear,
      toYear,
      onChange,
      value,
      required = false,
      ...props
    }: DatePickerSingleProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedDay, setSelectedDay] = useState<Date>(value ?? new Date());
    const [submittedDate, setSubmittedDate] = useState<string>(
      value ? formatWithZonedDate(value, "dd/MM/yyyy") : "",
    );

    const formatMonthUpperInitial: DateFormatter = (date: Date) => {
      const formattedDate = formatWithZonedDate(date, "LLLL", ptBR);
      return (
        <>
          {formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)},{" "}
          {formatWithZonedDate(date, "yyyy", ptBR)}
        </>
      );
    };

    const resetNotSubmittedDate = (): void => {
      if (submittedDate) setSelectedDay(parseDate(submittedDate));
    };

    const openCalendarDrawer = (): void => {
      setOpen((isOpen) => !isOpen);
    };

    const submitSelectedDate = (): void => {
      setSubmittedDate(formatWithZonedDate(selectedDay, "dd/MM/yyyy"));
      onChange && onChange(selectedDay);
      setOpen(false);
    };

    return (
      <>
        <CpsInput
          cursorPointer
          title={title}
          IconLeft={Calendar}
          onClick={openCalendarDrawer}
          value={submittedDate}
          onChange={(e) => e.preventDefault()}
          ref={ref}
          required={required}
          {...props}
        />
        <DrawerRoot setOpen={setOpen} open={open} onClose={resetNotSubmittedDate}>
          <DrawerContent>
            <DatePickerRoot>
              <DayPicker
                mode="single"
                selected={selectedDay}
                onSelect={(selectedValue) => {
                  if (selectedValue) setSelectedDay(selectedValue);
                }}
                today={new Date()}
                showOutsideDays={showOutsideDays}
                fixedWeeks
                disabled={{ before: disabledPastDates, after: disabledFutureDates }}
                locale={ptBR}
                captionLayout={captionLayout}
                fromYear={fromYear}
                toYear={toYear}
                defaultMonth={selectedDay}
                formatters={{ formatCaption: formatMonthUpperInitial }}
              />
            </DatePickerRoot>
            <DrawerFooter>
              <Button onClick={submitSelectedDate} fullWidth>
                Confirmar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerRoot>
      </>
    );
  },
);

DatePicker.displayName = "DatePicker";
