import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { type z } from "zod";
import { useAtom } from "jotai";
import {
  AppointmentMode,
  AppointmentPurpose,
  TimeSlotSuggestionType,
  formatIgnoringTimezone,
} from "@repo/lib";
import {
  useNavigate,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { useResetAtom } from "jotai/utils";
import { type CreateFlexiblePriceAppointmentMutation as CreateFlexiblePriceAppointmentMutationType } from "@repo/graphql-types/graphql";
import { type flexiblePricingInfo } from "@/lib/form-schemas/appointment-schema";
import { AppointmentFlexiblePriceRadioSelect } from "@/components/appointment-flexible-price-radio-select";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { CreatedAppointmentDrawer } from "@/components/created-appointment-drawer";
import { CreatedAppointmentWithFeedbackDrawer } from "@/components/created-appointment-with-feedback-drawer";
import { isRepeatedPatientError } from "@/lib/repeated-patient-error";
import { AppointmentRepeatedPatientErrorDrawer } from "@/components/appointment-repeated-patient-error-drawer";
import { trackEvent } from "@/lib/tracking";

export const AppointmentFlexiblePriceFormFragment = graphql(`
  fragment AppointmentFlexiblePriceFormFragment on query_root {
    unidadesPrecosFlexiveis: UnidadesPrecosFlexiveis(
      where: {
        ativo: { _eq: true }
        codUnidade: { _eq: $codUnidade }
        codTipoSala: { _in: $codTipoSala }
      }
    ) {
      taxaDesconto
    }
    unidade: unidades_by_pk(codUnidade: $codUnidade) {
      sigla
    }
    periodosGarantidos: tbPeriodosGarantidos(
      where: {
        ativo: { _eq: true }
        Agendamento: {
          cancelado: { _eq: false }
          codUnidade: { _eq: $codUnidade }
          codUsuario: { _eq: $codUsuario }
          data: { _eq: $data }
          horaInicio: { _lte: $horaInicio }
          horaFim: { _gt: $horaInicio }
          UsuarioCompromisso: {
            UsuariosCompromissosTiposSalas: { codTipoSala: { _in: $codTipoSala } }
          }
        }
      }
    ) {
      codPeriodoGarantido
    }
  }
`);

const CreateFlexiblePriceAppointmentMutation = graphql(`
  mutation CreateFlexiblePriceAppointment($input: CriarAgendamentoInput!) {
    criarAgendamento(input: $input) {
      data: agendamentoApiOutput {
        codAgendamento
      }
      errors {
        ... on ValidationError {
          __typename
          message
        }
        ... on RepeatedPatientError {
          __typename
          message
        }
      }
    }
  }
`);

interface AppointmentFlexiblePriceFormProps {
  data: FragmentType<typeof AppointmentFlexiblePriceFormFragment>;
}
export const AppointmentFlexiblePriceForm = ({
  data,
}: AppointmentFlexiblePriceFormProps): JSX.Element => {
  const fragmentResult = useFragment(AppointmentFlexiblePriceFormFragment, data);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    CreateFlexiblePriceAppointmentMutation,
    {
      retry: 2,
    },
  );

  const [{ ...atomValues }, setAtomValues] = useAtom(appointmentFormAtom);

  const resetAppointmentFormAtom = useResetAtom(appointmentFormAtom);

  const { user, queryClient, showCreatedAppointmentFeedbackDrawer } = useRouteContext({
    from: "/appointment/create/flexible-pricing",
  });

  const navigate = useNavigate();
  const router = useRouter();

  const searchParams = useSearch({ from: "/appointment/create/flexible-pricing" });

  const { periodosGarantidos, unidadesPrecosFlexiveis, unidade } = fragmentResult;

  const isReservedSchedule = periodosGarantidos.length > 0;

  const navigateWithSearch = (
    action: "success" | "error" | "error-repeated-patient",
    codAgendamento?: number,
  ): void => {
    void navigate({
      to: "/appointment/create/flexible-pricing",
      search: {
        action,
        codAgendamento,
      },
      replace: action === "error-repeated-patient",
    });
  };

  const onSubmit = async (
    formData: z.infer<typeof flexiblePricingInfo>,
  ): Promise<void> => {
    const onError = (error: Error): void => {
      const isRepeatedPatient = isRepeatedPatientError(error);

      setAtomValues((prev) => ({ ...prev, patientId: undefined }));

      if (isRepeatedPatient) {
        trackEvent("Alerta Paciente Repetido Visualizado", {
          codUsuario: user.codUsuario,
          codPaciente: atomValues.patientId ?? 0,
          codUnidade: atomValues.unit,
          codTipoSala: atomValues.roomTypes,
          telaOrigem: "Novo agendamento preço flexivel",
          data: formatIgnoringTimezone(atomValues.appointmentDate ?? new Date()),
          codAgendamento: atomValues.scheduleId,
          nomePaciente: atomValues.name,
          telefone: atomValues.phone,
        });

        navigateWithSearch("error-repeated-patient");
      }
    };

    const onSuccess = ({
      criarAgendamento: response,
    }: CreateFlexiblePriceAppointmentMutationType): void => {
      trackEvent("Agendamento Realizado", {
        codAgendamento: response.data?.codAgendamento,
        modalidade: "preco_flexivel",
      });

      void queryClient.resetQueries({ queryKey: ["PatientPageQuery"] });
      void queryClient.resetQueries({
        queryKey: ["AppointmentPatientSearchPageQuery"],
      });

      navigateWithSearch("success", response.data?.codAgendamento);
    };

    const {
      unit,
      appointmentDate,
      appointmentTime,
      appointmentType,
      paymentMethod,
      scheduleId,
      email,
      name,
      phone,
      cpf,
      dateOfBirth,
      sendConfirmationViaWhatsApp,
      patientId,
    } = atomValues;

    const cobraCancelamento = formData.appointmentMode === AppointmentMode.NonRefundable;

    await mutateAsync(
      {
        input: {
          codUnidade: unit,
          codUsuario: user.codUsuario,
          codUsuarioCompromisso: appointmentType,
          data: formatIgnoringTimezone(appointmentDate ?? new Date()),
          codUsuarioFormaRecebimento: paymentMethod,
          email,
          nome: name,
          telefone: phone,
          cpf: cpf ?? null,
          dataNascimento: dateOfBirth ? formatIgnoringTimezone(dateOfBirth) : null,
          horaFim: formatIgnoringTimezone(appointmentTime.end, "HH:mm"),
          horaInicio: formatIgnoringTimezone(appointmentTime.start, "HH:mm"),
          codUsuarioAgenda: scheduleId ?? null,
          receberWhatsAppConfirmacao: sendConfirmationViaWhatsApp,
          codTipoConvenio: null,
          ignorarEnvioEmail: false,
          ignorarEnvioWhatsApp: false,
          nomePlano: null,
          numeroCarteirinha: null,
          codPaciente: patientId ?? null,
          cobraCancelamento,
          codFinalidadeAgendamento:
            appointmentTime.timeSlotType ===
            TimeSlotSuggestionType.LimitedDurationAppointment.valueOf()
              ? AppointmentPurpose.HorariosMenores
              : AppointmentPurpose.AtendimentoPresencial,
        },
      },
      { onSuccess, onError },
    );
  };

  const getDiscountFee = (): number => {
    return unidadesPrecosFlexiveis.length > 0
      ? unidadesPrecosFlexiveis[0].taxaDesconto
      : 0;
  };

  const navigateToHomePage = (date: string): void =>
    void navigate({ to: "/", search: { date } });

  const handleSuccessDrawerDismiss = (value: boolean): void => {
    if (!value) {
      const appointmentDate = formatIgnoringTimezone(
        atomValues.appointmentDate ?? new Date(),
      );
      resetAppointmentFormAtom();
      navigateToHomePage(appointmentDate);
    }
  };

  const handleRepeatedPatientErrorDrawerDismiss = (value: boolean): void => {
    const patientsPageIndex = -2;
    if (!value) {
      router.history.go(patientsPageIndex);
    }
  };

  return (
    <>
      <AppointmentFlexiblePriceRadioSelect
        isReservedSchedule={isReservedSchedule}
        onSubmit={onSubmit}
        discountFee={getDiscountFee()}
      />

      {showCreatedAppointmentFeedbackDrawer ? (
        <CreatedAppointmentWithFeedbackDrawer
          locationAcronym={unidade?.sigla ?? "-"}
          setOpen={handleSuccessDrawerDismiss}
          open={Boolean(searchParams.action === "success")}
        />
      ) : (
        <CreatedAppointmentDrawer
          setOpen={handleSuccessDrawerDismiss}
          title="Agendamento marcado"
          description="O paciente receberá um e-mail com os dados do agendamento."
          confirmButtonText="Ir para minha agenda"
        />
      )}

      <AppointmentRepeatedPatientErrorDrawer
        open={searchParams.action === "error-repeated-patient"}
        setOpen={handleRepeatedPatientErrorDrawerDismiss}
      />
    </>
  );
};
