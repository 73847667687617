import { CpsInput, type CpsInputProps } from "corpus";
import { Mail } from "@repo/icons";

type InputEmailProps = CpsInputProps;

export const InputEmail = ({
  title = "E-mail",
  ...props
}: InputEmailProps): JSX.Element => {
  return (
    <CpsInput
      IconLeft={Mail}
      title={title}
      placeholder="Digite aqui"
      inputMode="email"
      type="email"
      {...props}
    />
  );
};
