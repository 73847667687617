import { useFragment, type FragmentType } from "@repo/graphql-types";
import { graphql } from "@repo/graphql-types/gql";
import { Link } from "@tanstack/react-router";
import { Building, Clock, DoctorWithClipboard, Door } from "@repo/icons";
import { ScheduleFrequencyEnum, cn } from "@repo/lib";
import { useState } from "react";
import { Button } from "@/components/button";
import {
  EmptyStateBody,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateImage,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { ListRoot } from "@/components/list/list-root";
import { ReservedScheduleListItem } from "@/components/reserved-schedule-list-item";

export const ReservedScheduleListQueryFragment = graphql(/* GraphQL */ `
  fragment ReservedScheduleListQueryFragment on query_root {
    periodosGarantidosRecorrentes: tbPeriodosGarantidosRecorrentes(
      where: { ativo: { _eq: true } }
    ) {
      codPeriodoGarantidoRecorrente
      codPeriodoGarantidoRecorrenteFrequencia
      ...ReservedScheduleListItemQueryFragment
      PeriodosGarantidos(
        where: {
          Agendamento: {
            cancelado: { _eq: false }
            _or: [
              { data: { _gt: $dataAgendamento } }
              {
                _and: [
                  { data: { _eq: $dataAgendamento } }
                  { horaInicio: { _gte: $horaInicio } }
                ]
              }
            ]
          }
          ativo: { _eq: true }
        }
      ) {
        codPeriodoGarantido
      }
    }
  }
`);

export const ReservedScheduleList = (props: {
  children?: JSX.Element;
  reservedScheduleList: FragmentType<typeof ReservedScheduleListQueryFragment>;
  onClick?: () => void;
}): JSX.Element => {
  const data = useFragment(ReservedScheduleListQueryFragment, props.reservedScheduleList);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {data.periodosGarantidosRecorrentes.length > 0 ? (
        <div className="-mt-4">
          <ListRoot>
            {data.periodosGarantidosRecorrentes
              .filter(
                (reservedSchedule) =>
                  reservedSchedule.PeriodosGarantidos.length > 0 ||
                  reservedSchedule.codPeriodoGarantidoRecorrenteFrequencia !==
                    ScheduleFrequencyEnum.NaoRepetir.valueOf(),
              )
              .map((reservedSchedule) => {
                return (
                  <ReservedScheduleListItem
                    key={reservedSchedule.codPeriodoGarantidoRecorrente}
                    data={reservedSchedule}
                  />
                );
              })}
          </ListRoot>
          <div
            className={cn(
              "sticky bottom-0 pb-4",
              data.periodosGarantidosRecorrentes.length === 0 ? "pt-4" : "",
            )}
          >
            <Link to="/settings/reserved-schedules/terms-of-use" className="w-full">
              <Button
                loading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                }}
                fullWidth
              >
                Criar período garantido
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <EmptyStateRoot>
          <EmptyStateImage
            icon={DoctorWithClipboard}
            alt="Nenhum período garantido ativo"
            className="w-full h-[25vh]"
          />
          <EmptyStateTitle>Período Garantido</EmptyStateTitle>
          <EmptyStateDescription>
            O Período Garantido é a melhor forma de gerir sua agenda sem se preocupar com
            disponibilidade de sala
          </EmptyStateDescription>
          <EmptyStateBody>
            <ul className="flex flex-col gap-2 px-4 py-6 text-sm font-normal text-neutral-600">
              <li className="flex gap-2">
                <Door className="fill-primary-400" size={24} />
                <span className="self-end">Sala sempre disponível</span>
              </li>
              <li className="flex gap-2">
                <Clock className="fill-primary-400 self-center" size={24} />
                <span className="">Fique na mesma sala durante todo o período</span>
              </li>
              <li className="flex gap-2">
                <Building className="fill-primary-400" size={24} />
                <span className="self-end">Disponível em todas as unidades</span>
              </li>
            </ul>
          </EmptyStateBody>
          <Link to="/settings/reserved-schedules/terms-of-use" className="w-full">
            <EmptyStateButton
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
              }}
              fullWidth
            >
              Criar período garantido
            </EmptyStateButton>
          </Link>
        </EmptyStateRoot>
      )}
    </>
  );
};
