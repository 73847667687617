import { CpsSpinner } from "corpus";

export const FullPageSpinner = (): JSX.Element => {
  return (
    <div className="flex" data-testid="full-page-spinner">
      <div className="absolute top-1/2 right-1/2 items-center justify-center h-full translate-x-1/2">
        <CpsSpinner />
      </div>
    </div>
  );
};
