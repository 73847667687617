import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { HelpCircle, Left } from "@repo/icons";
import { formatWithZonedDate, formatCurrencyFromCentsToReais } from "@repo/lib";
import { z } from "zod";
import { graphql } from "@repo/graphql-types";
import { AmountTitle } from "@/components/amount-title";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  PaymentsActionButtons,
  PaymentsContent,
  PaymentsRoot,
} from "@/components/payments-root";
import { DatePayablesDetailsInformationDrawer } from "@/components/data-payables-details-information-drawer";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { DatePayablesDetailsList } from "@/components/date-payables-details-list";
import {
  mapPagarmePayableTypeToPayableType,
  mapPagarmePaymentMethodToPaymentMethod,
} from "@/lib/mappers/payables";
import { DownloadFileButton } from "@/components/download-file-button";

const ExpensumGetPayablesFromDate = graphql(`
  query ExpensumGetPayablesFromDate($input: ExpensumGetPayablesFromDateInput!) {
    ExpensumGetPayablesFromDate(arg1: $input) {
      totalAmount
      payables {
        patientName
        amount
        createdAt
        brandName
        paymentMethod
        installment
        totalInstallments
        type
      }
      ...ExpensumGetPayablesFromDatePayables
    }
  }
`);

const datePayablesDetailsPageSearchSchema = z.object({
  about: z.string().optional().catch("false"),
});

export const DatePaymentsDetailsPage = (): JSX.Element => {
  const router = useRouter();

  const { about } = useSearch({
    from: "/settings/payments/payables/$date",
  });

  const { date } = Route.useParams();

  const { user } = Route.useRouteContext();

  const queryData = useGraphQL(ExpensumGetPayablesFromDate, {
    input: {
      codUsuario: Number(user.codUsuario),
      codClinica: Number(user.codClinica),
      date,
    },
  });

  const { data } = queryData;

  const setOpenDrawer = (open: boolean): void => {
    if (open) {
      void router.navigate({
        search: {
          about: true,
        },
      });
    } else if (about) {
      router.history.back();
    }
  };

  const totalAmount = data?.ExpensumGetPayablesFromDate?.totalAmount ?? 0;

  const getFileNameAndContent = (): { fileName: string; fileContent: string } => {
    const csvRows: string[][] = [];

    const headerRow = [
      "Nome do paciente",
      "Valor total",
      "Horário",
      "Descrição da operação",
      "Bandeira do cartão",
      "Método de pagamento",
      "Parcela",
      "Total de parcelas",
    ];

    csvRows.push(headerRow);

    if (data?.ExpensumGetPayablesFromDate) {
      const { payables } = data.ExpensumGetPayablesFromDate;

      if (payables.length > 0) {
        payables.forEach((payable) => {
          if (payable) {
            const {
              amount,
              brandName,
              createdAt,
              installment,
              patientName,
              paymentMethod,
              totalInstallments,
              type,
            } = payable;

            const itemRow: string[] = [
              patientName ?? "",
              formatCurrencyFromCentsToReais(amount ?? 0),
              createdAt ? formatWithZonedDate(createdAt, "HH:mm") : "",
              mapPagarmePayableTypeToPayableType(type ?? ""),
              brandName ?? "",
              mapPagarmePaymentMethodToPaymentMethod(paymentMethod ?? ""),
              installment?.toString() ?? "",
              totalInstallments?.toString() ?? "",
            ];

            csvRows.push(itemRow);
          }
        });

        const fileContent = csvRows
          .map((row) => row.map((value) => `"${value}"`).join(","))
          .join("\n");

        const fileName = `recebimento_${formatWithZonedDate(date, "dd-MM-yyyy")}`;

        return { fileName, fileContent };
      }
    }

    return { fileName: "", fileContent: "" };
  };

  const { fileContent, fileName } = getFileNameAndContent();

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Detalhes do pagamento" align="center" />
        <HeaderButton icon={HelpCircle} onClick={() => setOpenDrawer(true)} align="end" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryData}>
          <PaymentsRoot>
            <AmountTitle amount={totalAmount} title="Valor total" />
            <PaymentsActionButtons>
              <DownloadFileButton
                title="Baixar relatório"
                fileExtension="csv"
                fileContent={fileContent}
                fileName={fileName}
              />
            </PaymentsActionButtons>
            <PaymentsContent>
              {data?.ExpensumGetPayablesFromDate ? (
                <DatePayablesDetailsList data={data.ExpensumGetPayablesFromDate} />
              ) : null}
            </PaymentsContent>
          </PaymentsRoot>
          <DatePayablesDetailsInformationDrawer
            open={Boolean(about)}
            setOpen={setOpenDrawer}
          />
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/payables/$date")({
  component: DatePaymentsDetailsPage,
  validateSearch: datePayablesDetailsPageSearchSchema,
});
