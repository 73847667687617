import { ExclamationCircle } from "@repo/icons";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
} from "@/components/message-drawer";

interface UnexpectedErrorDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
}
export const UnexpectedErrorDrawer = ({
  open,
  setOpen,
  onClose,
}: UnexpectedErrorDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot
      open={open}
      setOpen={setOpen}
      icon={ExclamationCircle}
      variant="primary"
      onClose={onClose ? onClose : undefined}
    >
      <MessageDrawerTitle>Erro inesperado</MessageDrawerTitle>
      <MessageDrawerBody>
        Ocorreu um erro ao processar a sua solicitação. Por favor, tente novamente.
      </MessageDrawerBody>
    </MessageDrawerRoot>
  );
};
