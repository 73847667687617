import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useNavigate } from "@tanstack/react-router";
import { zonedDate, type Patient } from "@repo/lib";
import { ItemContent, ItemMainContent, Item } from "@/components/list";
import { PatientAvatar } from "@/components/patient-avatar";

export const PatientListItemFragment = graphql(/* GraphQL */ `
  fragment PatientListItemFragment on pacientes {
    telefone
    codPaciente
    nome
    email
    cpf
    dataNascimento
    ...PatientAvatarFragment
  }
`);

export const PatientListItem = (props: {
  patient: FragmentType<typeof PatientListItemFragment>;
  onClick?: (patient: Patient) => void;
}): JSX.Element => {
  const patient = useFragment(PatientListItemFragment, props.patient);
  const navigate = useNavigate();

  const onPatientClick = (): void => {
    if (props.onClick) {
      props.onClick({
        patientId: patient.codPaciente,
        name: patient.nome,
        phone: patient.telefone,
        email: patient.email ?? "",
        cpf: patient.cpf ?? undefined,
        dateOfBirth: patient.dataNascimento
          ? zonedDate(patient.dataNascimento)
          : undefined,
      });
    } else {
      void navigate({
        to: "/patients/$patientId",
        params: { patientId: String(patient.codPaciente) },
      });
    }
  };

  return (
    <Item onClick={onPatientClick}>
      <PatientAvatar data={patient} color="primaryLight" />
      <ItemContent>
        <ItemMainContent>{patient.nome}</ItemMainContent>
        <p className="text-md">{patient.telefone}</p>
      </ItemContent>
    </Item>
  );
};
