import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { AppointmentTypeForm } from "@/components/appointment-type-form";
import { Page } from "@/components/page";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { PendingComponent } from "@/components/pending-component";

const GetAppointmentTypeCreatePageQuery = graphql(`
  query GetAppointmentTypeCreatePageQuery(
    $codUsuario: Int!
    $codUsuarioCompromisso: Int!
  ) {
    ...GetAppointmentTypeFormQueryFragment
  }
`);

export const CreateAppointmentTypePage = (): JSX.Element => {
  const { user } = Route.useRouteContext();

  const queryResult = useGraphQL(GetAppointmentTypeCreatePageQuery, {
    codUsuarioCompromisso: 0,
    codUsuario: Number(user.codUsuario),
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Criar tipo de atendimento" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data && queryResult.isFetchedAfterMount ? (
            <AppointmentTypeForm data={data} />
          ) : (
            <FullPageSpinner />
          )}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/schedule-options/appointment-types/create",
)({
  component: CreateAppointmentTypePage,
  pendingComponent: () => <PendingComponent title="Criar tipo de atendimento" />,
});
