import { createClient, type Client } from "graphql-ws";
import { HASURA_WEBSOCKETS_ENDPOINT } from "@repo/lib";
import { type Auth0Client } from "@auth0/auth0-spa-js";
import { getAuthorizationHeaders } from "@/lib/authentication-headers.ts";

export function createWsClient(authClient: Auth0Client): Client {
  const authenticationFn = async (): Promise<{ headers: { authorization: string } }> => {
    return {
      headers: await getAuthorizationHeaders(authClient),
    };
  };

  let timedOut: NodeJS.Timeout;

  const client = createClient({
    url: HASURA_WEBSOCKETS_ENDPOINT,
    connectionParams: authenticationFn,
    keepAlive: 10_000,
    shouldRetry: () => true,
    lazyCloseTimeout: 15_000,
    on: {
      ping: (received) => {
        if (!received /* sent */) {
          timedOut = setTimeout(() => {
            // a close event `4499: Terminated` is issued to the current WebSocket and an
            // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
            // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
            //
            // calling terminate is not considered fatal and a connection retry will occur as expected
            //
            // see: https://github.com/enisdenjo/graphql-ws/discussions/290
            client.terminate();
          }, 5_000);
        }
      },
      pong: (received) => {
        if (received) {
          clearTimeout(timedOut);
        }
      },
    },
    lazy: true,
  });
  return client;
}
