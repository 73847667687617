import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

export const OneLivMedicalInstitutionsSelectInputFragment = graphql(/* GraphQL */ `
  fragment OneLivMedicalInstitutionsSelectInputFragment on locus_instituicao_medica {
    id
    nome
  }
`);

export interface OneLivMedicalInstitutionsSelectInputProps
  extends Omit<SelectInputProps<number>, "items"> {
  data?: FragmentType<typeof OneLivMedicalInstitutionsSelectInputFragment>[];
}

export const OneLivMedicalInstitutionsSelectInput = forwardRef<
  HTMLInputElement,
  OneLivMedicalInstitutionsSelectInputProps
>(
  (
    { data, ...props }: OneLivMedicalInstitutionsSelectInputProps,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const medicalInstitutions = useFragment(
      OneLivMedicalInstitutionsSelectInputFragment,
      data,
    );

    const getItems = (): SelectDrawerItemProps<number>[] => {
      if (!medicalInstitutions || medicalInstitutions.length === 0) return [];

      const items: SelectDrawerItemProps<number>[] = medicalInstitutions.map(
        (medicalInstitution) => {
          return {
            label: medicalInstitution.nome,
            value: medicalInstitution.id,
          };
        },
      );

      return items;
    };

    const items = getItems();

    return <SelectInput<number> ref={ref} {...props} items={items} searchable />;
  },
);

OneLivMedicalInstitutionsSelectInput.displayName = "OneLivMedicalInstitutionsSelectInput";
