export const getNameInitials = (name?: string): string | undefined => {
  if (name) {
    const cleanedName = name
      .replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]+|(?<temp1> )+/g, (_, space) => (space ? " " : ""))
      .trim();
    const splittedName = cleanedName.split(" ");

    if (splittedName.length > 1) {
      return `${splittedName[0][0]}${splittedName[1][0]}`.toUpperCase();
    }

    return `${splittedName[0][0]}${splittedName[0][1]}`.toUpperCase();
  }

  return undefined;
};

export const formatDateOfBirth = (dateOfBirth: string): string => {
  if (dateOfBirth) {
    return dateOfBirth.split("-").reverse().join("/");
  }

  return "-";
};

export const normalizeToHTMLAttribute = (value: string): string => {
  return value.replaceAll(" ", "-");
};

/**
 * Calcula a duração de um agendamento e retorna formatado
 *
 * @param start - hora inicio no formato HH:MM
 * @param end - hora final no formato HH:MM
 * @returns duração no formato 1 h 40 min, 50 min ou 1 h
 */
export const calculateFormattedDuration = (start: string, end: string): string => {
  const [startHours, startMinutes] = start.split(":");
  const [endHours, endMinutes] = end.split(":");
  const diff =
    new Date(0, 0, 0, Number(endHours), Number(endMinutes), 0).getTime() -
    new Date(0, 0, 0, Number(startHours), Number(startMinutes), 0).getTime();
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor((diff - hours * 1000 * 60 * 60) / 1000 / 60);

  const formattedHours = hours > 0 ? `${hours} h` : "";
  const formattedMinutes = minutes > 0 ? `${minutes} min` : "";
  return `${formattedHours} ${formattedMinutes}`.trim();
};

/**
 * Recebe um documento (CPF ou CNPJ) e retorna formatado.
 *
 * @param type - Tipo do documento
 * @param document - O documento a ser formatado. Ex: 49205263001 ou 92282579000140
 * @returns O documento formatado. Ex: 492.052.630-01 ou 92.282.579/0001-40.
 */
export const formatRecipientDocument = (type: string, document: string): string => {
  if (type === "company") {
    // eslint-disable-next-line prefer-named-capture-group -- Não há a necessidade de explicar os grupos.
    const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
    return document.replace(cnpjRegex, "$1.$2.$3/$4-$5");
  }
  // eslint-disable-next-line prefer-named-capture-group -- Não há a necessidade de explicar os grupos.
  const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})/;
  return document.replace(cpfRegex, "$1.$2.$3-$4");
};
