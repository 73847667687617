import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { type AppointmentTypeModalityEnum } from "@repo/lib";
import { forwardRef } from "react";
import { SelectInput, type SelectInputProps } from "@/components/select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";

export const AppointmentTypeModalitiesListToSelectQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentTypeModalitiesListToSelectQueryFragment on usuariosCompromissosModalidades {
    codUsuarioCompromissoModalidade
    nome
  }
`);

interface AppointmentTypeModalitiesSelectInputProps
  extends Omit<SelectInputProps<AppointmentTypeModalityEnum>, "items"> {
  data: FragmentType<typeof AppointmentTypeModalitiesListToSelectQueryFragment>[];
}

export const AppointmentTypeModalitiesSelectInput = forwardRef<
  HTMLInputElement,
  AppointmentTypeModalitiesSelectInputProps
>(({ data, ...props }, _ref: React.Ref<HTMLInputElement>): JSX.Element => {
  const modalities = useFragment(
    AppointmentTypeModalitiesListToSelectQueryFragment,
    data,
  );

  const items: SelectDrawerItemProps<AppointmentTypeModalityEnum>[] = modalities.map(
    (modality) => {
      return {
        label: modality.nome ?? "",
        value: modality.codUsuarioCompromissoModalidade,
      };
    },
  );

  return <SelectInput<AppointmentTypeModalityEnum> {...props} items={items} />;
});

AppointmentTypeModalitiesSelectInput.displayName = "AppointmentTypeModalitiesSelectInput";
