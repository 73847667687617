import type { ForwardedRef, FunctionComponent, InputHTMLAttributes } from "react";
import { forwardRef, useEffect, useState } from "react";
import { type SVGIconProps } from "@repo/icons";
import { Button } from "@/components/button";
import {
  InputSelect,
  InputSelectContent,
  InputSelectTitle,
} from "@/components/input-select";
import {
  SelectDrawer,
  SelectDrawerBodyListRadio,
  SelectDrawerFooter,
  type SelectDrawerItemProps,
  SelectDrawerTitle,
  SelectDrawerSearch,
  SelectDrawerBodySingleList,
} from "@/components/select-drawer";

export interface SelectInputProps<T extends string | number>
  extends InputHTMLAttributes<HTMLInputElement> {
  value?: T;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | T) => void;
  items: SelectDrawerItemProps<T>[];
  searchable?: boolean;
  required?: boolean;
  title: string;
  placeholder?: string;
  Icon?: FunctionComponent<SVGIconProps>;
  name: string;
  hasConfirmButton?: boolean;
}
export const SelectInputInner = <T extends string | number>(
  {
    id,
    name,
    title,
    value,
    onChange,
    items,
    searchable,
    placeholder = "Selecione",
    hasConfirmButton = false,
    ...props
  }: SelectInputProps<T>,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
  const initialSelectedItem = items.find((x) => x.value === value);
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchResults, setSearchResults] = useState<SelectDrawerItemProps<T>[]>(items);
  const [selectedItem, setSelectedItem] = useState<SelectDrawerItemProps<T> | undefined>(
    value ? initialSelectedItem : undefined,
  );

  const commitChangesAndClose = (): void => {
    if (!selectedItem) return;
    onChange(selectedItem.value);
    setShowDrawer(false);
  };

  // TODO: Refatorar esse useEffect posteriormente para uma solução mais elegante
  useEffect(() => {
    setSearchResults(items);
  }, [items]);

  useEffect(() => {
    if (!hasConfirmButton && selectedItem && selectedItem !== initialSelectedItem) {
      onChange(selectedItem.value);
      setShowDrawer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Efeito executado quando um item for selecionado.
  }, [selectedItem]);

  const filterUniqueValues = (
    item: SelectDrawerItemProps<T>,
    index: number,
    array: SelectDrawerItemProps<T>[],
  ): boolean => {
    const itemIndex = array.findIndex((b) => item.value === b.value);
    return index === itemIndex;
  };

  return (
    <>
      <InputSelect required={props.required}>
        <InputSelectTitle>{title}</InputSelectTitle>
        <InputSelectContent
          id={id}
          name={name}
          disabled={props.disabled}
          value={value}
          onClick={() => setShowDrawer(true)}
          placeholder={placeholder}
          label={initialSelectedItem?.label}
          ref={ref}
          Icon={props.Icon}
          required={props.required}
        />
      </InputSelect>
      <SelectDrawer open={showDrawer} setOpen={setShowDrawer}>
        <SelectDrawerTitle>{title}</SelectDrawerTitle>
        {searchable ? (
          <SelectDrawerSearch items={items} onSearch={setSearchResults} />
        ) : null}
        <SelectDrawerBodySingleList
          initialSelectedItem={initialSelectedItem}
          setSelectedItem={setSelectedItem}
        >
          {searchResults.filter(filterUniqueValues).map((item) => (
            <SelectDrawerBodyListRadio
              value={item.value}
              key={`searchResult-${item.label}-${item.value}`}
            >
              {item.label}
            </SelectDrawerBodyListRadio>
          ))}
        </SelectDrawerBodySingleList>
        {hasConfirmButton ? (
          <SelectDrawerFooter>
            <Button
              onClick={commitChangesAndClose}
              disabled={!selectedItem}
              type="button"
              fullWidth
            >
              Confirmar
            </Button>
          </SelectDrawerFooter>
        ) : null}
      </SelectDrawer>
    </>
  );
};
export const SelectInput = forwardRef(SelectInputInner) as <T extends string | number>(
  p: SelectInputProps<T> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof SelectInputInner>;
