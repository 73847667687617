import { createFileRoute, type ParsedLocation, redirect } from "@tanstack/react-router";
import { AuthenticationError } from "@auth0/auth0-spa-js";
import { type RootRouteContext } from "@/routes/-root-context.tsx";
import {
  logCallbackError,
  logCallbackFinished,
  logCallbackStarted,
} from "@/lib/breadcrumbs.ts";

const beforeLoad = async (
  context: RootRouteContext,
  location: ParsedLocation,
): Promise<void> => {
  logCallbackStarted({ location });

  let returnTo: string | null | undefined = "/";
  let retry = 0;
  try {
    const result = await context.authClient.handleRedirectCallback<{
      returnTo?: string;
      retry?: number;
    }>(location.href);
    returnTo = result.appState?.returnTo ?? "/";
    retry = result.appState?.retry ?? 1;
  } catch (error) {
    logCallbackError({ location, returnTo, error });

    if (error instanceof AuthenticationError && error.error === "access_denied") {
      redirect({
        to: "/",
        throw: true,
        search: {
          accessDenied: true,
          cause:
            error.error_description !== "access_denied" ? error.error_description : "",
        },
      });
      return;
    }

    if (error instanceof AuthenticationError && error.error === "missing_transaction") {
      // Invalid State: Tenta novamente
      redirect({
        to: "/auth/login",
        throw: true,
        search: {
          returnTo,
          retry: retry + 1,
        },
      });
      return;
    }
  }

  logCallbackFinished({ location, returnTo });

  redirect({
    to: returnTo,
    throw: true,
  });
};

export const Route = createFileRoute("/auth/callback")({
  async beforeLoad({ context, location }) {
    await beforeLoad(context, location);
  },
});
