import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { useAtom } from "jotai/index";
import { graphql } from "@repo/graphql-types/gql";
import { useEffect } from "react";
import { useResetAtom } from "jotai/utils";
import { z } from "zod";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header.tsx";
import { Page } from "@/components/page.tsx";
import { RecommendationMembersForPatientsForm } from "@/components/recommendation-members-for-patient-form.tsx";
import { recommendationMemberAtom } from "@/lib/atoms/recommendation-member-atom.ts";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper.tsx";

export const RecommendationMemberForPatientConfirmationPageQuery = graphql(/* GraphQL */ `
  query RecommendationMemberForPatientConfirmationPageQuery(
    $codPaciente: Int!
    $codMembro: Int!
  ) {
    ...RecommendationMembersForPatientFormFragment
  }
`);

const searchSchema = z.object({
  patientId: z.number(),
});

export const RecommendationMemberForPatientConfirmation = (): JSX.Element => {
  const [member] = useAtom(recommendationMemberAtom);
  const resetMemberAtomValues = useResetAtom(recommendationMemberAtom);

  const params = useSearch({ from: "/recommendation/confirmation/" });
  const router = useRouter();

  const { patientId } = params;

  const queryResult = useGraphQL(RecommendationMemberForPatientConfirmationPageQuery, {
    codPaciente: patientId,
    codMembro: member.id,
  });

  const { data } = queryResult;

  useEffect(() => {
    if (patientId === 0 || !member.id) {
      router.history.go(-2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps --- se o member vira dependencia ele buga o reset do atom
  }, [patientId, router]);

  const onBackButtonClick = (): void => {
    resetMemberAtomValues();
    router.history.back();
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton
          onClick={onBackButtonClick}
          icon={Left}
          align="start"
          data-testid="back-button"
        />
        <HeaderTitle title="Confirme os dados" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <RecommendationMembersForPatientsForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};
export const Route = createFileRoute("/recommendation/confirmation/")({
  component: RecommendationMemberForPatientConfirmation,
  validateSearch: searchSchema,
});
