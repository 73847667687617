import { CpsToggle } from "corpus";
import { forwardRef } from "react";

export interface TextToggleProps {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const TextToggle = forwardRef<HTMLInputElement, TextToggleProps>(
  (
    { label, value, onChange, ...props },
    _ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const onChangeHandler = (): void => {
      const newCheckedState = !value;
      onChange(newCheckedState);
    };

    return (
      <div className="bg-neutral-50 rounded-xl p-4 flex flex-row justify-between">
        <p className="text-sm text-neutral-600 font-medium">{label}</p>
        <CpsToggle checked={value} onChange={onChangeHandler} {...props} />
      </div>
    );
  },
);

TextToggle.displayName = "TextToggle";
