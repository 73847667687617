import { Eye, EyeOff } from "@repo/icons";
import { formatWithZonedDate, formatCurrencyFromCentsToReais } from "@repo/lib";
import { CpsBadge } from "corpus";
import type { CpsBadgeProps } from "corpus";
import { useAtomValue, useSetAtom } from "jotai";
import {
  amountVisibilityAtom,
  toggleAmountVisibilityAtom,
} from "@/lib/atoms/amount-visibility";

export interface AmountTitleProps {
  amount: number;
  badgeColor?: CpsBadgeProps["color"];
  badgeText?: string;
  title?: string;
  date?: string;
  startHour?: string;
}

export const AmountTitle = ({
  amount,
  badgeColor,
  badgeText,
  title,
  date,
  startHour,
}: AmountTitleProps): JSX.Element => {
  const isAmountVisible = useAtomValue(amountVisibilityAtom);
  const toggleAmountVisibility = useSetAtom(toggleAmountVisibilityAtom);

  const [hour, minutes] = startHour?.split(":") ?? [undefined, undefined];

  const amountText = isAmountVisible
    ? formatCurrencyFromCentsToReais(amount)
    : "R$ \u2022\u2022\u2022\u2022\u2022";

  return (
    <div>
      {badgeColor && badgeText ? (
        <div className="mb-1">
          <CpsBadge color={badgeColor}>{badgeText}</CpsBadge>
        </div>
      ) : null}
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          {Boolean(title) && <p className="text-sm text-neutral-500">{title}</p>}
          <p className="text-2xl font-medium text-neutral-600 shrink-0 flex-1">
            {amountText}
          </p>
          {Boolean(date) && Boolean(minutes) && Boolean(hour) && (
            <p className="text-neutral-500">
              {formatWithZonedDate(String(date), "dd/MM/yyyy")} {hour}:{minutes}
            </p>
          )}
        </div>
        <button data-testid="eye-button" onClick={toggleAmountVisibility} type="button">
          {isAmountVisible ? <Eye size={24} /> : <EyeOff size={24} />}
        </button>
      </div>
    </div>
  );
};
