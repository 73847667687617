/**
 * Converts a time string of the format "HH:MM" to minutes.
 *
 * @param time - A string representing time in the format "HH:MM".
 * @returns The total minutes derived from the input time string.
 *
 * @example
 * // returns 420
 * convertToMinutes("07:00");
 *
 * @example
 * // returns 1410
 * convertToMinutes("23:30");
 */
export const convertToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};
