import { createFileRoute, useRouter } from "@tanstack/react-router";
import { useEffect } from "react";
import { z } from "zod";
import { type FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { useGraphQL } from "@/hooks/use-graphql";
import {
  ReceiveRoomNowForm,
  type ReceiveRoomNowFragment,
} from "@/components/receive-room-now-form";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";

const ReceiveRoomNowQuery = graphql(/* GraphQL */ `
  query ReceiveRoomNowQuery($codAgendamento: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      ...ReceiveRoomNowFragment
    }
  }
`);

export const ReceiveRoomNowPage = (): JSX.Element => {
  const router = useRouter();
  const { codAgendamento } = Route.useParams();

  const queryResult = useGraphQL(ReceiveRoomNowQuery, {
    codAgendamento,
  });
  const { data } = queryResult;

  useEffect(() => {
    if (data && !data.agendamento) {
      void router.navigate({ to: "/waiting-room" });
    }
  }, [data, router]);

  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Receber sala agora" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <ReceiveRoomNowForm
              data={data.agendamento as FragmentType<typeof ReceiveRoomNowFragment>}
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

const receiveRoomNowSearchSchema = z.object({
  action: z.string().optional(),
});

export const Route = createFileRoute("/waiting-room/$codAgendamento/receive-room-now/")({
  component: ReceiveRoomNowPage,
  validateSearch: receiveRoomNowSearchSchema,
  beforeLoad: ({ context }) => {
    return {
      useNewMutationReceiveRoomNow: context.ldClient.getUseNewMutationReceiveRoomNow(),
    };
  },
  loader: ({ context }) => {
    const useNewMutationReceiveRoomNow = context.useNewMutationReceiveRoomNow;
    return {
      useNewMutationReceiveRoomNow,
    };
  },
  parseParams: (params) => ({
    codAgendamento: z.number().int().parse(Number(params.codAgendamento)),
  }),
});
