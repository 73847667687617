import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { DotsHorizontal } from "@repo/icons";
import { formatDateStringToHourMinute, removeSecondsFromTimeString, cn } from "@repo/lib";
import { useState } from "react";
import { CpsBadge } from "corpus";
import { AppointmentPurpose } from "@repo/lib/src/enums";
import { useLoaderData, useRouter, useSearch } from "@tanstack/react-router";
import type { PatientAvatarFragment } from "@/components/patient-avatar";
import { PatientAvatar } from "@/components/patient-avatar";
import { WaitingRoomAppointmentListItemActions } from "@/components/waiting-room-appointment-list-item-actions";
import {
  CardContent,
  CardFooter,
  CardHeader,
  CardIcon,
  CardRoot,
  CardTitle,
} from "@/components/card";
import { WaitingRoomAppointmentListItemPayment } from "@/components/waiting-room-appointment-list-item-payment";
import { DayEventBadge } from "@/components/day-event-badge";
import { differenceInMinutes } from "@/lib/time";
import { WaitingRoomWarningLatePatient } from "@/components/waiting-room-warning-late-patient";

export const WaitingRoomAppointmentListItemFragment = graphql(/* GraphQL */ `
  fragment WaitingRoomAppointmentListItemFragment on agendamentos {
    codAgendamento
    horaInicio
    horaFim
    codFinalidadeAgendamento
    paciente: Paciente {
      codPaciente
      nome
      ...PatientAvatarFragment
    }
    unidade: Unidade {
      nome
    }
    compromisso: UsuarioCompromisso {
      nome
    }
    checkin: AgendamentosCheckin {
      dataCheckin
    }
    atraso: notificacoesPacientesAtrasados(order_by: { dataCadastro: desc }) {
      tempoAtraso
      dataCadastro
    }
    tags {
      dataCadastro
      codAgendamentoTipoTag
    }
    ...WaitingRoomAppointmentListItemActionsFragment
    ...WaitingRoomAppointmentListItemPaymentFragment
  }
`);

interface WaitingRoomAppointmentListItemProps {
  data: FragmentType<typeof WaitingRoomAppointmentListItemFragment>;
}

export const WaitingRoomAppointmentListItem = ({
  data,
}: WaitingRoomAppointmentListItemProps): JSX.Element => {
  const router = useRouter();
  const appointment = useFragment(WaitingRoomAppointmentListItemFragment, data);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { showTreatedAppointmentInWaitingRoom } = useLoaderData({
    from: "/waiting-room/",
  });

  const {
    codAgendamento,
    checkin,
    paciente,
    compromisso,
    unidade,
    horaInicio,
    horaFim,
    codFinalidadeAgendamento,
    atraso,
    tags,
  } = appointment;

  const searchParams = useSearch({ from: "/waiting-room/" });

  const cardTitleContent = checkin?.dataCheckin
    ? formatDateStringToHourMinute(String(checkin.dataCheckin))
    : "Pendente";

  const appointmentDuration = differenceInMinutes({
    startTime: horaInicio,
    endTime: horaFim,
  });

  const paddingCardContent = atraso.length > 0 ? "py-0" : "pt-0";

  const isModalOverlayOpen: boolean =
    searchParams.action === "expand-avatar-image" && searchParams.id === codAgendamento;

  const handleOpenModalOverlay = (value: boolean): void => {
    if (value) {
      if (showModal) {
        setShowModal(false);
      }

      void router.navigate({
        to: "/waiting-room/",
        search: {
          ...searchParams,
          action: "expand-avatar-image",
          id: codAgendamento,
        },
      });
    } else if (isModalOverlayOpen) {
      router.history.back();
    }
  };

  return (
    <>
      <CardRoot
        data-testid="waiting-room-appointment-list-item-test-id"
        onClick={() => setShowModal(true)}
      >
        <CardHeader>
          <CardTitle>
            <span className="font-normal text-gray-400">Check-in: </span>
            {cardTitleContent}
          </CardTitle>
          <CardIcon size={24} icon={DotsHorizontal} />
        </CardHeader>
        <CardContent className={cn("gap-y-3 px-4", paddingCardContent)}>
          <div className="flex flex-row gap-x-2">
            <PatientAvatar
              shouldOpenModalOverlay
              isModalOverlayOpen={isModalOverlayOpen}
              setIsModalOverlayOpen={() => handleOpenModalOverlay(!isModalOverlayOpen)}
              data={paciente as FragmentType<typeof PatientAvatarFragment>}
              color="primaryLight"
              showCheckBadge={Boolean(
                showTreatedAppointmentInWaitingRoom && tags.length > 0,
              )}
            />
            <div className="flex flex-col">
              <p className="font-medium text-neutral-600">{paciente?.nome}</p>
              <div className="flex flex-row gap-x-4">
                <p className="text-sm font-normal text-neutral-500">
                  {removeSecondsFromTimeString(horaInicio)} às{" "}
                  {removeSecondsFromTimeString(horaFim)}
                </p>
                {codFinalidadeAgendamento ===
                AppointmentPurpose.HorariosMenores.valueOf() ? (
                  <DayEventBadge
                    title={`Limitado a ${appointmentDuration} min`}
                    variant="secondary"
                  />
                ) : (
                  <p className="text-sm font-normal text-neutral-500">{unidade.nome}</p>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <CpsBadge className="block max-w-[200px] truncate text-left leading-4">
              {compromisso?.nome ?? ""}
            </CpsBadge>
            <WaitingRoomAppointmentListItemPayment data={appointment} />
          </div>
        </CardContent>
        {atraso.length > 0 && (
          <CardFooter className="p-0 block">
            <WaitingRoomWarningLatePatient lateTime={atraso[0].tempoAtraso} />
          </CardFooter>
        )}
      </CardRoot>

      <WaitingRoomAppointmentListItemActions
        data={appointment}
        setShow={setShowModal}
        show={showModal}
      />
    </>
  );
};
