import { createFileRoute, useRouteContext, redirect } from "@tanstack/react-router";
import { type DocumentType, graphql } from "@repo/graphql-types";
import { Left, Register } from "@repo/icons";
import { LivanceApiPaymentMethod } from "@repo/lib";
import { useGraphQL, queryFn } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { Page } from "@/components/page";
import {
  EmptyStateRoot,
  EmptyStateIcon,
  EmptyStateTitle,
  EmptyStateDescription,
} from "@/components/empty-state";
import { CardDetails } from "@/components/card-details";

export const CardManagementPageQuery = graphql(/* GraphQL */ `
  query CardManagementPageQuery($codUsuario: Int!) {
    ...CardDetailsQueryFragment
    usuariosProfissionais: usuariosProfissionais_by_pk(codUsuario: $codUsuario) {
      formaDePagamento
    }
    usuariosCartoes(where: { ativo: { _eq: true } }) {
      ativo
    }
  }
`);

export const CardManagementPage = (): JSX.Element => {
  const { user } = useRouteContext({ strict: false });

  const queryResult = useGraphQL(CardManagementPageQuery, {
    codUsuario: user.codUsuario,
  });
  const { data } = queryResult;

  const hasCard = Boolean(data?.usuariosCartoes && data.usuariosCartoes.length > 0);
  const isConfiguredToCard = Boolean(
    data?.usuariosProfissionais?.formaDePagamento === LivanceApiPaymentMethod.CreditCard,
  );

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Gerenciar cartão" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {!isConfiguredToCard && !hasCard ? (
            <EmptyStateRoot>
              <EmptyStateIcon icon={Register} />
              <EmptyStateTitle>
                Sua conta está configurada para pagamento via boleto
              </EmptyStateTitle>
              <EmptyStateDescription>
                Para alterar a forma de pagamento consulte nosso time de relacionamento
              </EmptyStateDescription>
            </EmptyStateRoot>
          ) : (
            <>{data ? <CardDetails data={data} /> : null}</>
          )}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/profile/card/")({
  component: CardManagementPage,
  loader: async (opts) => {
    try {
      const data = await opts.context.queryClient.ensureQueryData<
        DocumentType<typeof CardManagementPageQuery>
      >({
        queryKey: [
          "CardManagementPageQuery",
          {
            codUsuario: opts.context.user.codUsuario,
          },
        ],
        queryFn: () =>
          queryFn(CardManagementPageQuery, opts.context.authClient, {
            codUsuario: opts.context.user.codUsuario,
          }),
      });

      const hasCard = Boolean(data.usuariosCartoes.length > 0);
      const isConfiguredToCard = Boolean(
        data.usuariosProfissionais?.formaDePagamento ===
          LivanceApiPaymentMethod.CreditCard,
      );

      if (isConfiguredToCard && !hasCard) throw new Error("user does not have a card");
    } catch (e) {
      redirect({
        to: "/settings/profile/card/create",
        throw: true,
      });
    }
  },
});
