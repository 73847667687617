import { forwardRef } from "react";
import { SelectInput, type SelectInputProps } from "@/components/select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";

type BankAccountTypesSelectInputProps = Omit<SelectInputProps<string>, "items">;

export const BankAccountTypesSelectInput = forwardRef<
  HTMLInputElement,
  BankAccountTypesSelectInputProps
>(({ ...props }, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
  const bankAccountTypes: SelectDrawerItemProps<string>[] = [
    {
      label: "Conta Corrente",
      value: "checking",
    },
    {
      label: "Conta Poupança",
      value: "savings",
    },
  ];

  return <SelectInput<string> {...props} items={bankAccountTypes} ref={ref} />;
});

BankAccountTypesSelectInput.displayName = "BankAccountTypesSelectInput";
