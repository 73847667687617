import { getCurrentDate, setTime } from "@repo/lib";
import type { LaunchDarklyClient } from "@/lib/launchdarkly.ts";

export const isAppointmentNeedsToBeChargedByCancellationFee = (
  ldClient: LaunchDarklyClient,
  appointmentDate: string,
): boolean => {
  const minimumAntecedence =
    ldClient.getMinimumAntecedenceForFlexiblePriceAppointmentCreation();

  const limitDateForCancellationWithoutAnyTaxes = setTime(appointmentDate, {
    hours: -minimumAntecedence,
  });

  const today = getCurrentDate();

  return today > limitDateForCancellationWithoutAnyTaxes;
};
