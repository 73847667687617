import { Mail, Phone, Whatsapp } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { generateContactUrl } from "@repo/lib/src/helpers/contact-url";
import { PatientDetailActionListItem } from "@/components/patient-detail-action-list-item";

export const AppointmentDrawerPatientContactActionsFragment = graphql(/* GraphQL */ `
  fragment AppointmentDrawerPatientContactActionsFragment on pacientes {
    telefone
    email
  }
`);

export interface AppointmentDrawerPatientContactActionsProps {
  patient: FragmentType<typeof AppointmentDrawerPatientContactActionsFragment>;
}

export const AppointmentDrawerPatientContactActions = ({
  patient,
}: AppointmentDrawerPatientContactActionsProps): JSX.Element => {
  const patientData = useFragment(
    AppointmentDrawerPatientContactActionsFragment,
    patient,
  );

  const hasEmail = Boolean(patientData.email);

  return (
    <div className="flex justify-between my-4 gap-4">
      <div className="w-full flex-1">
        <PatientDetailActionListItem
          Icon={Phone}
          onClick={() => openUrl(generateContactUrl(patientData.telefone, "phone"))}
          text="Ligação"
        />
      </div>
      <div className="w-full flex-1">
        <PatientDetailActionListItem
          Icon={Mail}
          disabled={!hasEmail}
          onClick={() =>
            openUrl(generateContactUrl(patientData.email ?? "", "email"), true)
          }
          text="E-mail"
        />
      </div>
      <div className="w-full flex-1">
        <PatientDetailActionListItem
          Icon={Whatsapp}
          onClick={() =>
            openUrl(generateContactUrl(patientData.telefone, "whatsapp"), true)
          }
          text="WhatsApp"
        />
      </div>
    </div>
  );
};

function openUrl(url: string, newTab = false): void {
  if (!newTab) {
    window.open(url);
  }

  window.open(url, "_blank")?.focus();
}
