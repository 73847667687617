import { MapDaysOfWeekToFirstLetter, DaysOfWeekEnum } from "@repo/lib";
import {
  CpsSelectorItem,
  CpsSingleSelector,
  CpsSelectorRoot,
  CpsSelectorTitle,
  type CpsSingleSelectorProps,
} from "corpus";

interface DaysOfWeekSelectorProps extends Omit<CpsSingleSelectorProps, "children"> {
  title?: string;
}
export const DaysOfWeekSelector = ({
  title,
  ...props
}: DaysOfWeekSelectorProps): JSX.Element => {
  const validDaysOfWeek = [
    DaysOfWeekEnum.Monday,
    DaysOfWeekEnum.Tuesday,
    DaysOfWeekEnum.Wednesday,
    DaysOfWeekEnum.Thursday,
    DaysOfWeekEnum.Friday,
    DaysOfWeekEnum.Saturday,
  ];

  return (
    <CpsSelectorRoot>
      {title ? (
        <CpsSelectorTitle required={props.required}>{title}</CpsSelectorTitle>
      ) : null}
      <CpsSingleSelector
        activeBackgroundColor="secondary"
        data-testid="days-of-week-selector-test-id"
        {...props}
      >
        {validDaysOfWeek.map((day) => (
          <CpsSelectorItem value={day} key={day}>
            {MapDaysOfWeekToFirstLetter.get(day)}
          </CpsSelectorItem>
        ))}
      </CpsSingleSelector>
    </CpsSelectorRoot>
  );
};
