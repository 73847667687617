import { atomWithReset } from "jotai/utils";

interface RecommendationMember {
  id: number;
  name: string;
  specialty: string;
}

export const recommendationMemberAtom = atomWithReset<RecommendationMember>({
  id: 0,
  name: "",
  specialty: "",
});
