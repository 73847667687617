import { CalendarTime, Dislike, DislikeFill, Like, LikeFill } from "@repo/icons";
import { useEffect, useRef, useState } from "react";
import { useSearch } from "@tanstack/react-router";
import { useAtom } from "jotai/index";
import { formatWithZonedDate } from "@repo/lib";
import {
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { trackEvent } from "@/lib/tracking.ts";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom.ts";
import { differenceInMinutes, formatTimeHourMinuteByTotalMinutes } from "@/lib/time.ts";

interface SearchType {
  codAgendamento: number;
}
interface CreatedAppointmentWithFeedbackDrawerProps {
  locationAcronym: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const CreatedAppointmentWithFeedbackDrawer = ({
  locationAcronym,
  open,
  setOpen,
}: CreatedAppointmentWithFeedbackDrawerProps): JSX.Element => {
  const [like, setLike] = useState("none");

  const IconLike = like === "like" ? LikeFill : Like;
  const IconDislike = like === "dislike" ? DislikeFill : Dislike;

  const [{ appointmentTime, appointmentDate, unit, roomTypes }] =
    useAtom(appointmentFormAtom);

  const { codAgendamento }: SearchType = useSearch({
    strict: false,
  });

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleClickFeedbackAction = (action: string): void => {
    setLike(action);

    trackEvent("Agendamento Feedback Satisfacao Enviado", {
      tipoSatisfacao: action === "like" ? "Satisfeito" : "Insatisfeito",
      codAgendamento,
      codUnidade: unit,
      codTiposSalas: roomTypes,
      siglaUnidade: locationAcronym,
      horarioAgendado: formatWithZonedDate(appointmentTime.start, "HH:mm"),
      duracao: formatTimeHourMinuteByTotalMinutes(
        differenceInMinutes({
          startTime: formatWithZonedDate(appointmentTime.start, "HH:mm"),
          endTime: formatWithZonedDate(appointmentTime.end, "HH:mm"),
        }),
      ),
      dataConsulta: appointmentDate,
      telaDeOrigem: "/appointment/create/patient",
    });

    timeoutRef.current = setTimeout(() => {
      setOpen(false);
    }, 1_000);
  };

  return (
    <MessageDrawerRoot
      open={open}
      setOpen={setOpen}
      icon={CalendarTime}
      variant="secondary"
    >
      <MessageDrawerTitle>Agendamento marcado</MessageDrawerTitle>
      <MessageDrawerBody>
        O paciente receberá um e-mail com os dados do agendamento.
      </MessageDrawerBody>
      <MessageDrawerActions>
        <div className="flex flex-col bg-neutral-50 rounded p-4 gap-2 ">
          <p className="font-medium text-md text-center w-[250px] self-center">
            Você ficou satisfeito com o horário agendado?
          </p>
          <div>
            <div className="flex flex-row gap-4 justify-center h-[73px]">
              <button
                onClick={() => handleClickFeedbackAction("dislike")}
                className="self-end"
                type="button"
                data-testid="dislike-button"
              >
                <IconDislike size={56} className="fill-neutral-400 scale-x-[-1]" />
              </button>
              <button
                onClick={() => handleClickFeedbackAction("like")}
                className="self-start"
                type="button"
                data-testid="like-button"
              >
                <IconLike size={56} className="fill-neutral-400" />
              </button>
            </div>
            <div className="flex flex-row gap-[40px] justify-center">
              <p>Não</p>
              <p>Sim</p>
            </div>
          </div>
        </div>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
