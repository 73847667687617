import {
  PaymentMethods,
  formatWithZonedDate,
  DatePayableStatus,
  PagarmePayableStatus,
  formatCurrencyFromCentsToReais,
  capitalizeFirstLetter,
  PayableTypes,
} from "@repo/lib";
import {
  type ExpensumGetPayablesOutput,
  type ExpensumPayables,
  type ExpensumPayablesQueryQuery,
} from "@repo/graphql-types/graphql";

interface Payable {
  paymentDate: string;
  amount: string;
  status: DatePayableStatus;
  type: PayableTypes;
  installment: number;
  paymentMethod: PaymentMethods;
  accrualAt: string;
  createdAt: string;
}

export interface DayPayables {
  date: string;
  status: DatePayableStatus;
  totalAmount: { dateTotalAmountInCents: number; formattedAmount: string };
  payables: Payable[];
  dayOfWeek: string;
}

export interface PeriodPayables {
  totalAmount: number;
  dayPayables: DayPayables[];
}

// TODO: Levar toda essa lógica para o próprio endpoint do Expensum
export const mapExpensumGetPayablesResultToPeriodPayables = (
  queryResult?: ExpensumPayablesQueryQuery,
): PeriodPayables => {
  const expensumPayables = queryResult?.ExpensumGetPayables?.data;

  const dayPayables = expensumPayables ? buildDayPayables(expensumPayables) : [];

  const periodTotalAmountInCents = dayPayables.reduce(
    (sum, dayPayable) => sum + dayPayable.totalAmount.dateTotalAmountInCents,
    0,
  );

  return {
    totalAmount: periodTotalAmountInCents,
    dayPayables,
  };
};

const buildDayPayables = (
  expensumPayables: ExpensumGetPayablesOutput["data"],
): DayPayables[] => {
  const result = expensumPayables.map((payablesGroupedByDate) => {
    if (!payablesGroupedByDate) return undefined;

    const { date, payables: pagarmePayables } = payablesGroupedByDate;

    const dateStatus = getDateStatus(pagarmePayables);

    const dateTotalAmount = getDateTotalAmount(pagarmePayables);

    const dayOfWeek = capitalizeFirstLetter(formatWithZonedDate(date, "EEEE"));

    const payables = buildPayables(pagarmePayables);

    const dayPayables: DayPayables = {
      date: formatWithZonedDate(date),
      dayOfWeek,
      status: dateStatus,
      totalAmount: dateTotalAmount,
      payables,
    };

    return dayPayables;
  });

  return result.filter((payable): payable is DayPayables => payable !== undefined);
};

const getDateStatus = (datePayables: ExpensumPayables["payables"]): DatePayableStatus => {
  const status = datePayables.some(
    (element) => element?.status === PagarmePayableStatus.WaitingFunds,
  )
    ? DatePayableStatus.Foreseen
    : DatePayableStatus.Paid;

  return status;
};

const getDateTotalAmount = (
  datePayables: ExpensumPayables["payables"],
): { dateTotalAmountInCents: number; formattedAmount: string } => {
  const dateTotalAmountInCents = datePayables.reduce(
    (sum, payable) => sum + (payable?.amount ?? 0),
    0,
  );

  const formattedAmount = formatCurrencyFromCentsToReais(dateTotalAmountInCents);

  return {
    dateTotalAmountInCents,
    formattedAmount,
  };
};

const buildPayables = (pagarmePayables: ExpensumPayables["payables"]): Payable[] => {
  const payables = pagarmePayables.map((pagarmePayable) => {
    if (!pagarmePayable) return undefined;
    const paymentDate = pagarmePayable.paymentDate
      ? formatWithZonedDate(pagarmePayable.paymentDate, "dd/MM/yyyy")
      : "";
    const amount = formatCurrencyFromCentsToReais(pagarmePayable.amount ?? 0);

    const status =
      pagarmePayable.status === PagarmePayableStatus.Prepaid ||
      pagarmePayable.status === PagarmePayableStatus.WaitingFunds
        ? DatePayableStatus.Foreseen
        : DatePayableStatus.Paid;

    const paymentMethod = mapPagarmePaymentMethodToPaymentMethod(
      pagarmePayable.paymentMethod ?? "",
    );

    const accrualAt = pagarmePayable.accrualAt
      ? formatWithZonedDate(pagarmePayable.accrualAt, "dd/MM/yyyy")
      : "";

    const createdAt = pagarmePayable.createdAt
      ? formatWithZonedDate(pagarmePayable.createdAt, "dd/MM/yyyy")
      : "";

    const type = mapPagarmePayableTypeToPayableType(pagarmePayable.type ?? "");

    const payable: Payable = {
      paymentMethod,
      amount,
      status,
      type,
      installment: pagarmePayable.installment ?? 0,
      paymentDate,
      accrualAt,
      createdAt,
    };
    return payable;
  });

  return payables.filter((payable): payable is Payable => payable !== undefined);
};

export const mapPagarmePaymentMethodToPaymentMethod = (
  pagarmePaymentMethod: string,
): PaymentMethods => {
  const map: Record<string, PaymentMethods> = {
    credit_card: PaymentMethods.Credit,
    debit_card: PaymentMethods.Debit,
  };

  return map[pagarmePaymentMethod] ?? PaymentMethods.NotIdentified;
};

export const mapPagarmePayableTypeToPayableType = (
  pagarmePayableType: string,
): PayableTypes => {
  const map: Record<string, PayableTypes> = {
    credit: PayableTypes.Credit,
    refund: PayableTypes.Refund,
    refund_reversal: PayableTypes.RefundReversal,
    chargeback: PayableTypes.Chargeback,
    chargeback_refund: PayableTypes.ChargebackRefund,
  };

  return map[pagarmePayableType] ?? "";
};
