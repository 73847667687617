import { z } from "zod";

export const ReturnRoomActionSearchParamsSchema = z.object({
  action: z.literal("return-room"),
});

// Default object
export const ReturnRoomActionSearchParams = {
  action: "return-room",
};
