import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { z } from "zod";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { CreatePaymentConfirm } from "@/components/create-payment-confirm";

export const CreatePaymentConfirmationPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Criar pagamento" align="center" />
      </HeaderRoot>
      <Page>
        <CreatePaymentConfirm />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/create/confirmation")({
  component: CreatePaymentConfirmationPage,
  validateSearch: z.object({
    appointmentId: z.number().optional(),
    appointmentTypeId: z.number().optional(),
    action: z.string().optional(),
    guid: z.string().optional(),
  }),
});
