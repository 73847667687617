import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { CpsBadge } from "corpus";

export const ScheduleListItemCardAppointmentTypeFragment = graphql(/* GraphQL */ `
  fragment ScheduleListItemCardAppointmentTypeFragment on usuariosAgendas {
    agendasCompromissos: tbUsuariosAgendasCompromissos(
      where: { ativo: { _eq: true }, UsuarioCompromisso: { ativo: { _eq: true } } }
    ) {
      compromisso: UsuarioCompromisso {
        codUsuarioCompromisso
        nome
      }
    }
  }
`);

export const ScheduleListItemCardAppointmentType = (props: {
  schedule: FragmentType<typeof ScheduleListItemCardAppointmentTypeFragment>;
}): JSX.Element => {
  const { agendasCompromissos } = useFragment(
    ScheduleListItemCardAppointmentTypeFragment,
    props.schedule,
  );

  const scheduleAppointmentsTypes = (): typeof agendasCompromissos => {
    return agendasCompromissos.slice(0, 2);
  };

  const totalOfScheduleAppointmentTypes = agendasCompromissos.length;

  const shouldShowMore = totalOfScheduleAppointmentTypes > 2;

  return (
    <div className="flex flex-row flex-wrap gap-1">
      {scheduleAppointmentsTypes().map((scheduleAppointmentType) => (
        <CpsBadge
          className="block max-w-[200px] truncate text-left leading-4"
          key={scheduleAppointmentType.compromisso.codUsuarioCompromisso}
        >
          {scheduleAppointmentType.compromisso.nome}
        </CpsBadge>
      ))}
      {shouldShowMore ? (
        <CpsBadge>{`+${totalOfScheduleAppointmentTypes - 2}`}</CpsBadge>
      ) : null}
    </div>
  );
};
