import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useRouteContext, useRouter } from "@tanstack/react-router";
import { WaitingRoomAppointmentActionSheet } from "@/components/waiting-room-appointment-action-sheet";
import { trackEvent } from "@/lib/tracking";

export const WaitingRoomAppointmentListItemActionsFragment = graphql(/* GraphQL */ `
  fragment WaitingRoomAppointmentListItemActionsFragment on agendamentos {
    codAgendamento
    ...WaitingRoomAppointmentActionSheetFragment
  }
`);

export interface WaitingRoomAppointmentListItemActionsProps {
  show: boolean;
  setShow: (value: boolean) => void;
  data: FragmentType<typeof WaitingRoomAppointmentListItemActionsFragment>;
}

export const WaitingRoomAppointmentListItemActions = ({
  show,
  setShow,
  data,
}: WaitingRoomAppointmentListItemActionsProps): JSX.Element => {
  const router = useRouter();
  const appointment = useFragment(WaitingRoomAppointmentListItemActionsFragment, data);
  const { user: userContext } = useRouteContext({
    strict: false,
  });

  const { codAgendamento } = appointment;

  const handleCloseDialog = (): void => {
    setShow(false);
  };

  const handleOpenChangeCancelDrawer = (isOpen: boolean): void => {
    if (show) handleCloseDialog();
    if (isOpen) {
      if (userContext.altaTaxaCancelamento) {
        trackEvent("Alerta Frequencia Cancelamento Visualizado", {
          codUsuario: userContext.codUsuario,
          dataHora: new Date().toISOString(),
          telaOrigem: "Drawer Cancelamento",
        });
      }
      void router.navigate({
        search: {
          action: "cancel-appointment",
          appointmentId: codAgendamento,
        },
      });
    }
  };

  return (
    <WaitingRoomAppointmentActionSheet
      cancelAppointment={() => handleOpenChangeCancelDrawer(true)}
      closeDialog={handleCloseDialog}
      data={appointment}
      show={show}
    />
  );
};
