import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { useLoaderData, useNavigate } from "@tanstack/react-router";
import { useAtom } from "jotai";
import { type Patient } from "@repo/lib";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { AppointmentPatientList } from "@/components/appointment-patient-list";

const PatientSwapPatientListFragment = graphql(/* GraphQL */ `
  fragment PatientSwapPatientListFragment on query_root {
    ...AppointmentPatientListFragment
  }
`);

interface PatientSwapPatientListProps {
  data: FragmentType<typeof PatientSwapPatientListFragment>;
}

export const PatientSwapPatientList = ({
  data,
}: PatientSwapPatientListProps): JSX.Element => {
  const fragmentResult = useFragment(PatientSwapPatientListFragment, data);

  const [atomValues, setAtomValues] = useAtom(appointmentFormAtom);

  const { data: loaderData } = useLoaderData({
    from: "/appointment/$appointmentId/patient-swap-search",
  });

  const navigate = useNavigate();

  const navigateToPatientSwapForm = (): void => {
    const appointmentId = loaderData?.appointment?.codAgendamento ?? 0;

    void navigate({
      to: "/appointment/$appointmentId/patient-swap",
      params: { appointmentId },
    });
  };

  const onAddPatientButtonClick = (): void => {
    setAtomValues({
      ...atomValues,
      patientId: undefined,
      name: "",
      phone: "",
      email: "",
      cpf: undefined,
      dateOfBirth: undefined,
    });

    navigateToPatientSwapForm();
  };

  const onPatientSelected = (patient: Patient): void => {
    setAtomValues({
      ...atomValues,
      ...patient,
    });

    navigateToPatientSwapForm();
  };

  return (
    <AppointmentPatientList
      onAddPatientButtonClick={onAddPatientButtonClick}
      onClick={onPatientSelected}
      data={fragmentResult}
    />
  );
};
