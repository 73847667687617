import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { z } from "zod";
import { HelpCircle, Left } from "@repo/icons";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { ChoosePaymentChannelForm } from "@/components/choose-payment-channel-form";
import { ChoosePaymentChannelTooltip } from "@/components/choose-payment-channel-tooltip";

export const ChoosePaymentChannel = (): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearch({
    from: "/waiting-room/$codAgendamento/choose-payment-channel/",
  });

  const setOpenAboutDrawer = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.about = open;

      void router.navigate({ search });
    } else if (searchParams.about) {
      router.history.back();
    }
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Enviar pagamento" align="center" />
        <HeaderButton
          align="end"
          icon={HelpCircle}
          onClick={() => {
            setOpenAboutDrawer(true);
          }}
        />
      </HeaderRoot>
      <Page>
        <ChoosePaymentChannelForm />
        <ChoosePaymentChannelTooltip
          open={Boolean(searchParams.about)}
          setOpen={setOpenAboutDrawer}
        />
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/waiting-room/$codAgendamento/choose-payment-channel/",
)({
  component: ChoosePaymentChannel,
  parseParams: (params) => ({
    codAgendamento: z.number().int().parse(Number(params.codAgendamento)),
  }),
});
