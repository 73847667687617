import type { CalendarEventType } from "../types/calendar";

interface Events {
  id: string;
  type: CalendarEventType;
  title: string;
  appointmentType: string;
  appointmentPurposeId?: number | null;
  unit: {
    id: number;
    name: string;
    acronym: string;
  };
  start: Date;
  end: Date;
}

export interface CalendarEvent extends Events {
  appointment?: { codAgendamento?: number | null } | null;
  color?: string;
}

export interface DayEvents extends Events {
  timeSlotType?: number | null;
}

export const formatTitleAsPatientName = (title: string): string => {
  const [firstName, lastName] = title.split(" ");
  const formattedName = lastName ? `${firstName} ${lastName[0]}.` : `${firstName}.`;

  return formattedName;
};

export const getAppointmentDurationFromEvent = (start: Date, end: Date): number => {
  const durationOfAppointment = end.getTime() - start.getTime();
  const durationOfAppointmentInMinutes = Math.floor(durationOfAppointment / (1000 * 60));

  return durationOfAppointmentInMinutes;
};
