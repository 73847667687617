import { createFileRoute } from "@tanstack/react-router";
import { Refresh } from "@repo/icons";
import { storeUrlByPlatform } from "@repo/lib";
import { Page } from "@/components/page";
import { HeaderRoot, HeaderTitle } from "@/components/header";
import {
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";

export const UpdateRequiredPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Livance" align="center" />
      </HeaderRoot>
      <Page>
        <EmptyStateRoot>
          <EmptyStateIcon fill="secondary" icon={Refresh} />
          <EmptyStateTitle>Nova atualização disponível</EmptyStateTitle>
          <EmptyStateDescription>
            Uma nova atualização está disponível para download. Atualize agora para
            acessar os recursos mais recentes.
          </EmptyStateDescription>
          {/* TODO: Esse botão tem que ser vermelho quando o CPSButton permitir */}
          <EmptyStateButton
            onClick={() => {
              window.open(storeUrlByPlatform(), "_blank")?.focus();
            }}
            fullWidth
          >
            Atualizar aplicativo
          </EmptyStateButton>
        </EmptyStateRoot>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/update-required/")({
  component: UpdateRequiredPage,
});
