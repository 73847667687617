import { CpsButtonText, type CpsButtonTextProps } from "corpus";
import { type MouseEventHandler } from "react";

interface AppointmentDrawerActionButtonSecondaryProps extends CpsButtonTextProps {
  children: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const AppointmentDrawerActionButtonSecondary = ({
  onClick,
  children,
  ...props
}: AppointmentDrawerActionButtonSecondaryProps): JSX.Element => {
  return (
    <div className="flex w-full items-center justify-center">
      <CpsButtonText onClick={onClick} {...props}>
        {children}
      </CpsButtonText>
    </div>
  );
};
