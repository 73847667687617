import { graphql } from "@repo/graphql-types/gql";
import { Calendar } from "@repo/icons";
import { logError } from "@repo/lib";
import { useRouteContext } from "@tanstack/react-router";
import {
  WaitingRoomPageQueryDocument,
  CalendarPageAppointmentsQueryDocument,
  CalendarPageFreeSlotsQueryDocument,
} from "@repo/graphql-types/graphql";
import { HighCancellationAlert } from "@/components/high-cancellation-alert.tsx";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";
import { trackEvent } from "@/lib/tracking.ts";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer.tsx";

const CancelAppointmentMutation = graphql(/* GraphQL */ `
  mutation CancelAppointmentMutation(
    $codAgendamento: Int!
    $origem: String = "discovery"
  ) {
    LivanceApiAgendamentoCancela(codAgendamento: $codAgendamento, origem: $origem) {
      codAgendamento
    }
  }
`);

interface AppointmentCancelDrawerProps {
  codAgendamento: number;
  showDrawer: boolean;
  setShowDrawer: (open: boolean) => void;
  onClose?: () => void;
}

export const AppointmentCancelDrawer = ({
  codAgendamento,
  showDrawer,
  setShowDrawer,
  onClose,
}: AppointmentCancelDrawerProps): JSX.Element => {
  const { user: userContext } = useRouteContext({
    strict: false,
  });

  const { mutateAsync, isPending } = useGraphQLMutationWithErrorHandler(
    CancelAppointmentMutation,
  );

  const invalidateWaitingRoomQuery = useInvalidateQuery(WaitingRoomPageQueryDocument);
  const invalidateCalendarAppointmentsQuery = useInvalidateQuery(
    CalendarPageAppointmentsQueryDocument,
  );
  const invalidateCalendarFreeSlotsQuery = useInvalidateQuery(
    CalendarPageFreeSlotsQueryDocument,
  );

  const handleAppointmentCancel = async (): Promise<void> => {
    trackEvent("Cancelar agendamento solicitado", {
      agendamento: codAgendamento,
    });

    const onSuccess = (): void => {
      invalidateWaitingRoomQuery();
      invalidateCalendarAppointmentsQuery();
      invalidateCalendarFreeSlotsQuery();

      setShowDrawer(false);
    };

    const onError = (error: Error): void => {
      logError(error, { codAgendamento });
    };

    await mutateAsync({ codAgendamento }, { onSuccess, onError });
  };

  const checkHighCancellationRate = userContext.altaTaxaCancelamento;

  return (
    <MessageDrawerRoot
      icon={Calendar}
      setOpen={setShowDrawer}
      open={showDrawer}
      onClose={onClose}
      variant="secondary"
    >
      <MessageDrawerTitle>Cancelar agendamento</MessageDrawerTitle>
      <MessageDrawerBody>
        <div className="flex flex-col gap-6">
          <p>
            Ao realizar esta ação, o paciente será notificado sobre o cancelamento via
            e-mail
          </p>
          <p>Deseja prosseguir com a alteração?</p>
        </div>
        {checkHighCancellationRate ? (
          <div className="pt-4">
            <HighCancellationAlert />
          </div>
        ) : null}
      </MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton
          onClick={() => void handleAppointmentCancel()}
          loading={isPending}
        >
          Cancelar agendamento
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
