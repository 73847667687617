import { graphql } from "@repo/graphql-types/gql";
import { createFileRoute } from "@tanstack/react-router";
import { HelpCircle, Left } from "@repo/icons";
import { AppointmentChanel, AppointmentTypeCategoryEnum } from "@repo/lib";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { FollowUpAppointmentReminderForm } from "@/components/follow-up-appointment-reminder-form";

const FollowUpAppointmentReminderPageQuery = graphql(/* GraphQL */ `
  query FollowUpAppointmentReminderPageQuery(
    $codAgendamento: Int!
    $canalAgendamentoPaciente: Int!
    $categoriaPeriodoGarantido: Int!
  ) {
    ...FollowUpAppointmentReminderFormFragment
  }
`);

export const FollowUpAppointmentReminderPage = (): JSX.Element => {
  const { appointmentId } = Route.useParams();
  const queryResult = useGraphQL(FollowUpAppointmentReminderPageQuery, {
    codAgendamento: Number(appointmentId),
    canalAgendamentoPaciente: AppointmentChanel.Paciente.valueOf(),
    categoriaPeriodoGarantido: AppointmentTypeCategoryEnum.PeriodoGarantido.valueOf(),
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Lembrete de retorno" align="center" />
        <HeaderButton icon={HelpCircle} align="end" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <FollowUpAppointmentReminderForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/waiting-room/follow-up-appointment-reminder/$appointmentId",
)({
  component: FollowUpAppointmentReminderPage,
});
