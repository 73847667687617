import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useEffect, useState } from "react";
import { Link } from "@tanstack/react-router";
import { DoctorWithStethoAndClipboard } from "@repo/icons";
import { cn } from "@repo/lib";
import { Button } from "@/components/button";
import { AppointmentTypeListItem } from "@/components/appointment-type-list-item";
import { SearchInput } from "@/components/search-input";
import {
  EmptyStateButton,
  EmptyStateImage,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { ListRoot } from "@/components/list";

/**
 * Este fragmento GraphQL é usado para buscar uma lista de tipos de compromissos.
 *
 * Ele ordena os resultados pelo campo 'nome' em ordem ascendente e filtra os resultados para incluir apenas os tipos de compromissos ativos.
 *
 * A condição 'categoria: _neq: 3' é usada para excluir os tipos de compromissos da categoria "período garantido".
 * Neste contexto, "período garantido" é representado pelo número da categoria 3.
 */
export const AppointmentTypeListQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentTypeListQueryFragment on query_root {
    usuariosCompromissos(
      order_by: { nome: asc }
      where: { ativo: { _eq: true }, categoria: { _neq: 3 } }
    ) {
      codUsuarioCompromisso
      ...AppointmentTypeListItemQueryFragment
    }
  }
`);

export const AppointmentTypeList = (props: {
  appointmentTypeList: FragmentType<typeof AppointmentTypeListQueryFragment>;
}): JSX.Element => {
  const { usuariosCompromissos } = useFragment(
    AppointmentTypeListQueryFragment,
    props.appointmentTypeList,
  );

  const [searchResults, setSearchResults] = useState(usuariosCompromissos);

  useEffect(
    function updateSearchResults() {
      setSearchResults(usuariosCompromissos);
    },
    [usuariosCompromissos],
  );

  return (
    <div className="justify-center items-center">
      {usuariosCompromissos.length > 0 ? (
        <>
          <SearchInput
            items={usuariosCompromissos}
            onSearch={setSearchResults}
            searchKeys={["nome"]}
          />
          <ListRoot>
            {searchResults.map((appointmentType) => (
              <AppointmentTypeListItem
                appointmentType={appointmentType}
                key={appointmentType.codUsuarioCompromisso}
              />
            ))}
          </ListRoot>
          <div
            className={cn(
              "sticky bottom-0 pb-4",
              searchResults.length === 0 ? "pt-4" : "",
            )}
          >
            <Link to="/settings/schedule-options/appointment-types/create">
              <Button fullWidth>Criar tipo de atendimento</Button>
            </Link>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-between h-full items-center bg-white mt-8">
          <EmptyStateRoot>
            <EmptyStateImage
              icon={DoctorWithStethoAndClipboard}
              alt="Imagem lista de tipos de atendimento sem atendimentos configurados"
            />
            <EmptyStateTitle>
              Você não tem tipos de atendimento configurados
            </EmptyStateTitle>
            <Link
              to="/settings/schedule-options/appointment-types/create"
              className="w-full"
            >
              <EmptyStateButton fullWidth>Criar tipo de atendimento</EmptyStateButton>
            </Link>
          </EmptyStateRoot>
        </div>
      )}
    </div>
  );
};
