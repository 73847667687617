import { Clock, UpDown } from "@repo/icons";
import { CpsInput } from "corpus";
import type { InputHTMLAttributes } from "react";
import { forwardRef, useState } from "react";
import { DrawerContent, DrawerRoot, DrawerTitle } from "@/components/drawer";
import { TimeSelector } from "@/components/time-selector";
import { parseTime } from "@/lib/time";

export interface Time {
  hour: number;
  minute: number;
}

export interface TimeInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label: string;
  onChange: (time: string) => void;
  timerConfigs: {
    initialHour?: number;
    initialMinutes?: number;
    minHour: number;
    minMinutes: number;
    maxHour: number;
    maxMinutes: number;
    stepHour: number;
    stepMinutes: number;
  };
}

export const TimeInput = forwardRef<HTMLInputElement, TimeInputProps>(
  (
    { label, onChange, timerConfigs, ...props },
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [time, setTime] = useState<Time>({
      hour: timerConfigs.initialHour ?? timerConfigs.minHour,
      minute: timerConfigs.initialMinutes ?? 0,
    });

    const pad = (value: number, size: number): string =>
      String(value).padStart(size, "0");

    const formatTime = (selectedTime: Time): string => {
      return `${pad(selectedTime.hour, 2)}:${pad(selectedTime.minute, 2)}`;
    };

    const onConfirmHandler = (selectedHour: number, selectedMinute: number): void => {
      const newTime: Time = {
        hour: selectedHour,
        minute: selectedMinute,
      };
      setTime(newTime);
      onChange(formatTime(newTime));
      setOpen(false);
    };

    const onClickDrawer = (): void => {
      setOpen(true);
    };

    const parsedValue = props.value ? parseTime(String(props.value)) : undefined;

    return (
      <div className="w-full">
        <CpsInput
          {...props}
          IconLeft={Clock}
          IconRight={UpDown}
          cursorPointer
          onClick={() => !props.disabled && onClickDrawer()}
          placeholder="Selecione"
          readOnly
          ref={ref}
          title={label}
          value={parsedValue ? formatTime(parsedValue) : formatTime(time)}
        />

        <DrawerRoot open={open} setOpen={setOpen}>
          <DrawerContent>
            <DrawerTitle>{label}</DrawerTitle>
            <TimeSelector
              onChange={onConfirmHandler}
              timerConfigs={{
                ...timerConfigs,
                initialMinutes: time.minute,
                initialHour: time.hour,
              }}
            />
          </DrawerContent>
        </DrawerRoot>
      </div>
    );
  },
);

TimeInput.displayName = "TimeInput";
