import { type ReactNode, useRef } from "react";
import {
  type DayEvents,
  cn,
  formatTitleAsPatientName,
  getAppointmentDurationFromEvent,
} from "@repo/lib";

interface WeekEventRootProps {
  children: ReactNode;
  className?: string;
}

export const WeekEventRoot = ({
  children,
  className,
}: WeekEventRootProps): JSX.Element => {
  const eventRef = useRef<HTMLDivElement>(null);
  return (
    <div data-testid="root" className={className} ref={eventRef}>
      {children}
    </div>
  );
};

type WeekEventProps = Pick<DayEvents, "type" | "start" | "end" | "title">;

export const WeekEvent = (props: WeekEventProps): JSX.Element => {
  const eventTypes: Record<string, { background: string; icon: string }> = {
    pending: {
      background: "bg-primary-200",
      icon: "primaryLight",
    },
    confirmed: {
      background: "bg-secondary-200",
      icon: "secondaryLight",
    },
    old: {
      background: "bg-neutral-200",
      icon: "neutralLight",
    },
    available: {
      background: "bg-success-200",
      icon: "bg-success-200",
    },
    availableLimited: {
      background: "bg-secondary-200",
      icon: "secondaryLight",
    },
    closed: {
      background: "bg-danger-200",
      icon: "bg-danger-200",
    },
    cancelled: {
      background: "bg-danger-200",
      icon: "dangerLight",
    },
    none: {
      background: "bg-transparent",
      icon: "neutralLight",
    },
    weekSlot: {
      background: "bg-success-100",
      icon: "bg-success-200",
    },
    holiday: {
      background: "bg-danger-200",
      icon: "bg-danger-200",
    },
    timePolicy: {
      background: "bg-danger-200",
      icon: "bg-danger-200",
    },
  };

  const squareClass = cn(
    "leading-none text-left rounded-20 h-full w-full flex items-start p-1",
    eventTypes[props.type].background,
  );

  const weekComponent = (): JSX.Element => {
    const { title, start, end } = props;
    const formattedName = formatTitleAsPatientName(title);
    const durationOfAppointmentInMinutes = getAppointmentDurationFromEvent(start, end);

    const isAppointmentDurationEqualOrHigherThanThirtyMinutes =
      durationOfAppointmentInMinutes >= 30;

    let displayedName = "";

    if (isAppointmentDurationEqualOrHigherThanThirtyMinutes) {
      displayedName = formattedName;
    } else {
      displayedName = title;
    }

    return (
      <WeekEventRoot className={squareClass}>
        <span
          className={cn(
            "font-medium text-neutral-600 text-xs",
            isAppointmentDurationEqualOrHigherThanThirtyMinutes
              ? "break-all"
              : "truncate",
          )}
        >
          {displayedName}
        </span>
      </WeekEventRoot>
    );
  };

  const backgroundStyle = {
    backgroundImage:
      "repeating-linear-gradient(-45deg, transparent 0 7px, #D5DADF 7px 9px)",
    minHeight: "25px",
    height: "100%",
    width: "100%",
  };

  const weekNoneComponent = (): JSX.Element => {
    return (
      <WeekEventRoot className={squareClass}>
        <div style={backgroundStyle} />
      </WeekEventRoot>
    );
  };

  const closedSlotWeekComponent = (): JSX.Element => {
    return (
      <WeekEventRoot className={squareClass}>
        <span className="font-medium text-neutral-600 text-xs break-all">Fechamento</span>
      </WeekEventRoot>
    );
  };

  const colorOnlyWeekComponent = (): JSX.Element => {
    return (
      <WeekEventRoot className={squareClass}>
        <div style={{ ...backgroundStyle, backgroundImage: "" }} />
      </WeekEventRoot>
    );
  };

  const weekActions = {
    pending: weekComponent,
    confirmed: weekComponent,
    old: weekComponent,
    cancelled: weekComponent,
    available: weekComponent,
    availableLimited: weekComponent,
    closed: closedSlotWeekComponent,
    holiday: weekComponent,
    timePolicy: weekComponent,
    weekSlot: colorOnlyWeekComponent,
    none: weekNoneComponent,
  };

  const action = weekActions[props.type];
  return action();
};
