import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { z } from "zod";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { RecommendationMembersForPatientSearch } from "@/components/recommendation-members-for-patient-search.tsx";

const searchSchema = z.object({
  patientId: z.number(),
});

export const RecommendationMembersForPatientPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Indicação de profissional" align="center" />
      </HeaderRoot>
      <Page>
        <RecommendationMembersForPatientSearch />
      </Page>
    </>
  );
};
export const Route = createFileRoute("/recommendation/")({
  component: RecommendationMembersForPatientPage,
  validateSearch: searchSchema,
});
