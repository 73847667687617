import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useMemo, useState } from "react";
import { BillingFooter } from "@/components/billing-footer";
import { NewBillingHeader } from "@/components/new-billing-header";
import type { NewBillingItemQueryFragment } from "@/components/new-billing-item";
import { NewBillingItemList } from "@/components/new-billing-item-list";

export const NewBillingListQueryFragment = graphql(/* GraphQL */ `
  fragment NewBillingListQueryFragment on CobrancaExibicao {
    codCobranca
    valor
    dataVencimento
    movimentacoes {
      ...NewBillingListItemQueryFragment
    }
  }
`);

export const NewBillingList = (props: {
  billingList: FragmentType<typeof NewBillingListQueryFragment>[];
}): JSX.Element => {
  const billingListFragment = useFragment(NewBillingListQueryFragment, props.billingList);
  const billingListOrdered = Object.values(billingListFragment).sort((a, b) => {
    return new Date((a).dataVencimento ?? "").getTime() <
      new Date((b).dataVencimento ?? "").getTime()
      ? 1
      : -1;
  });

  const billingList = useMemo(
    () => billingListOrdered,
    [billingListOrdered],
  );
  const [currentBillingIndex, setCurrentBillingIndex] = useState<number>(0);

  const [billingItens, setBillingItens] = useState<
    FragmentType<typeof NewBillingItemQueryFragment>[]
  >(
    billingList[currentBillingIndex].movimentacoes as FragmentType<
      typeof NewBillingItemQueryFragment
    >[],
  );

  const showBillingItensByBilling = (billingIndex: number): void => {
    setCurrentBillingIndex(billingIndex);
    setBillingItens(
      billingList[billingIndex].movimentacoes as FragmentType<
        typeof NewBillingItemQueryFragment
      >[],
    );
  };

  return (
    <div className="mx-auto w-full h-full overflow-hidden">
      <NewBillingHeader
        billingList={billingList}
        currentBillingIndex={currentBillingIndex}
        showBillingItensByBilling={showBillingItensByBilling}
      />
      <div className="h-[85%] overflow-y-scroll scrollbar-hidden">
        <NewBillingItemList
          billingItens={billingItens}
          currentBillingIndex={currentBillingIndex}
        />
      </div>
      <BillingFooter total={billingList[currentBillingIndex]?.valor as number} />
    </div>
  );
};
