import { CustomerIO } from "@repo/customerio-plugin";
import type { Auth0Client } from "@auth0/auth0-spa-js";
import { Browser } from "@capacitor/browser";
import { appUrl, iosOrAndroid } from "@repo/lib";
import type { QueryClient } from "@tanstack/react-query";
import { logLogoutOpenUrl, logLogoutRequested } from "@/lib/breadcrumbs.ts";

export const logout = async (
  authClient: Auth0Client,
  queryClient: QueryClient,
): Promise<void> => {
  const logoutFromCustomerIo = (): void => {
    if (iosOrAndroid) {
      CustomerIO.clearIdentify();
    }
  };

  const logoutFromAuthService = async (): Promise<void> => {
    logLogoutRequested({ returnTo: appUrl });
    await authClient.logout({
      logoutParams: {
        returnTo: appUrl,
      },
      async openUrl(url: string) {
        logLogoutOpenUrl({ logoutUrl: url });
        await Browser.open({
          url,
          windowName: "_self",
        });
      },
    });
  };

  logoutFromCustomerIo();
  await logoutFromAuthService();
  queryClient.clear();
};
