import React, { useEffect, useRef, useState } from "react";
import {
  type KeenSliderOptions,
  type TrackDetails,
  useKeenSlider,
} from "keen-slider/react";
import "@/styles/wheel-styles.css";

export interface TimePickerProps {
  initialHour?: number;
  initialMinutes?: number;
  minHour?: number;
  maxHour?: number;
  minMinutes?: number;
  onChange?: (hour: number, minute: number) => void;
}

export const TimePicker = ({
  initialHour,
  initialMinutes,
  minHour,
  maxHour,
  minMinutes,
  onChange,
}: TimePickerProps): JSX.Element => {
  let hour = initialHour ?? minHour ?? 0;
  let minute = initialMinutes ?? minMinutes ?? 0;

  const onBothChange = (value: number, type: "h" | "m"): void => {
    if (type === "h") {
      hour = value;
      onChange && onChange(value, minute);
    }
    if (type === "m") {
      minute = value;
      onChange && onChange(hour, value);
    }
  };

  return (
    <div
      className="relative w-full select-none overflow-hidden h-[166px]"
      data-vaul-no-drag
    >
      <div
        className="time-picker relative mx-auto flex w-fit items-center justify-center gap-8 break-normal"
        data-vaul-no-drag
      >
        <TimePickerContent
          onChange={onBothChange}
          initialHour={hour}
          initialMinutes={minute}
          minHour={minHour}
          maxHour={maxHour}
        />
      </div>
      <hr className="border-1 absolute top-[75px] h-px w-full bg-neutral-100" />
      <hr className="border-1 absolute top-[107px] h-px w-full bg-neutral-100" />
    </div>
  );
};

interface TimePickerContentProps {
  initialHour?: number;
  initialMinutes?: number;
  minHour?: number;
  maxHour?: number;
  onlyMinutes?: boolean;
  onChange: (value: number, type: "h" | "m") => void;
}

export const TimePickerContent = ({
  initialHour = 0,
  initialMinutes = 0,
  minHour = 0,
  maxHour = 24,
  onChange,
  onlyMinutes = false,
}: TimePickerContentProps): JSX.Element => {
  return (
    <div
      style={{
        height: "240px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
      }}
      className="gap-x-4"
    >
      {!onlyMinutes ? (
        <div style={{ width: 70, height: 180 }}>
          <Wheel
            initIdx={initialHour}
            label="h"
            length={maxHour - minHour}
            width={23}
            handleChange={(h) => onChange(h, "h")}
            min={minHour}
          />
        </div>
      ) : null}
      <div style={{ width: 70, height: 180 }}>
        <Wheel
          initIdx={initialMinutes}
          label="m"
          length={12}
          width={23}
          perspective="left"
          handleChange={(m) => onChange(m, "m")}
          step={5}
        />
      </div>
    </div>
  );
};

export interface WheelProps {
  initIdx?: number;
  label?: string;
  length: number;
  loop?: boolean;
  perspective?: "left" | "right" | "center";
  setValue?: (relative: number, absolute: number) => string;
  width: number;
  handleChange: (value: number) => void;
  min?: number;
  step?: number;
}

export const Wheel = ({
  initIdx,
  label,
  length,
  loop,
  perspective = "center",
  setValue,
  width,
  handleChange,
  min = 0,
  step = 1,
}: WheelProps): JSX.Element => {
  const wheelSize = 20;
  const slides = length;
  const slideDegree = 360 / wheelSize;
  const slidesPerView = loop ? 9 : 1;
  const [sliderState, setSliderState] = useState<TrackDetails | null>(null);
  const size = useRef(0);
  const options = useRef<KeenSliderOptions>({
    slides: {
      number: slides,
      origin: loop ? "center" : "auto",
      perView: slidesPerView,
      spacing: 15,
    },
    vertical: true,
    initial: initIdx ? (initIdx - min) / step : 0,
    loop,
    dragSpeed: (val) => {
      const height = size.current;
      return (
        val *
        (height /
          ((height / 2) * Math.tan(slideDegree * (Math.PI / 180))) /
          slidesPerView)
      );
    },
    created: (s) => {
      size.current = s.size;
    },
    updated: (s) => {
      size.current = s.size;
    },
    detailsChanged: (s) => {
      s.track.details.rel = s.track.details.rel * step + min;
      setSliderState(s.track.details);
      handleChange(s.track.details.rel);
    },
    rubberband: !loop,
    mode: "free-snap",
  });

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>(options.current);

  const [radius, setRadius] = useState(0);

  useEffect(() => {
    if (slider.current) setRadius(slider.current.size / 2);
  }, [slider]);

  function slideValues(): { style: React.CSSProperties; value: string | number }[] {
    if (!sliderState) return [];
    const offset = loop ? 1 / 2 - 1 / slidesPerView / 2 : 0;

    const values = [];
    for (let i = 0; i < slides; i++) {
      const distance = (sliderState.slides[i].distance - offset) * slidesPerView;

      const rotate =
        Math.abs(distance) > wheelSize / 2 ? 180 : distance * (360 / wheelSize) * -1;
      const style = {
        transform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
        WebkitTransform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
      };
      let value = setValue ? setValue(i, sliderState.abs + Math.round(distance)) : i;

      value = Number(value) * step + min;
      value = value.toString().padStart(2, "0");

      values.push({ style, value });
    }
    return values;
  }

  return (
    <div
      className={`wheel keen-slider wheel--perspective-${perspective}`}
      ref={sliderRef}
    >
      <div
        className="wheel__shadow-top"
        style={{
          transform: `translateZ(${radius}px)`,
          WebkitTransform: `translateZ(${radius}px)`,
        }}
      />
      <div className="wheel__inner">
        <div className="wheel__slides" style={{ width: `${width}px` }}>
          {slideValues().map(({ style, value }) => (
            <div className="wheel__slide" style={style} key={value}>
              <span>{value}</span>
            </div>
          ))}
        </div>
        {label ? (
          <div className={`wheel__label wheel__label-${label}`}>{label}</div>
        ) : null}
      </div>
      <div
        className="wheel__shadow-bottom"
        style={{
          transform: `translateZ(${radius}px)`,
          WebkitTransform: `translateZ(${radius}px)`,
        }}
      />
    </div>
  );
};
