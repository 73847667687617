import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { forwardRef } from "react";
import { graphql } from "@repo/graphql-types/gql";
import { SelectInput, type SelectInputProps } from "@/components/select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";

export const HealthInsurancePlansListToSelectQueryFragment = graphql(/* GraphQL */ `
  fragment HealthInsurancePlansListToSelectQueryFragment on tiposPlanos {
    codTipoPlano
    nome
  }
`);

interface HealthInsurancePlansSelectInputProps
  extends Omit<SelectInputProps<number>, "items"> {
  data: FragmentType<typeof HealthInsurancePlansListToSelectQueryFragment>[];
}

export const HealthInsurancePlansSelectInput = forwardRef<
  HTMLInputElement,
  HealthInsurancePlansSelectInputProps
>(({ data, ...props }, ref: React.Ref<HTMLInputElement>): JSX.Element => {
  const healthInsurancePlans = useFragment(
    HealthInsurancePlansListToSelectQueryFragment,
    data,
  );

  const items: SelectDrawerItemProps<number>[] = healthInsurancePlans.map(
    (healthInsurancePlan) => {
      return {
        label: healthInsurancePlan.nome,
        value: healthInsurancePlan.codTipoPlano,
      };
    },
  );

  return <SelectInput<number> {...props} items={items} ref={ref} />;
});

HealthInsurancePlansSelectInput.displayName = "HealthInsurancePlansSelectInput";
