import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { type z } from "zod";
import { Door, LocationMarker, Rotate } from "@repo/icons";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { useNavigate } from "@tanstack/react-router";
import { ReservedScheduleTypeEnum, RoomTypeEnum, ScheduleFrequencyEnum } from "@repo/lib";
import { useAtom } from "jotai";
import { reservedScheduleBasicInfo } from "@/lib/form-schemas/reserved-schedule-schema";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { SelectInput } from "@/components/select-input";
import { TimeInput } from "@/components/time-input";
import { DaysOfWeekSelector } from "@/components/days-of-week-selector";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { reservedScheduleFormAtom } from "@/lib/atoms/reserved-schedule-form-atom";
import {
  type UnitsListToSelectQueryFragment,
  UnitsSelectInput,
} from "@/components/units-select-input";

export const GetLocationsFragment = graphql(/* GraphQL */ `
  fragment GetLocationsFragment on query_root {
    locations: unidades(
      where: {
        ativo: { _eq: true }
        desligarAlocacao: { _eq: false }
        preAlocacaoAtiva: { _eq: true }
      }
      order_by: { nome: asc }
    ) {
      codUnidade
      nome
      rooms: tbUnidades_tbUnidadesSalas {
        codTipoSala
        roomType: TipoSala {
          nome
        }
      }
      ...UnitsListToSelectQueryFragment
    }
  }
`);

const LIVANCE_MINIMUM_HOURS = "04:00";
const PARTNERSHIPS_MINIMUM_HOURS = "01:00";
interface ReservedScheduleFormProps {
  locationsFragment: FragmentType<typeof GetLocationsFragment>;
  reservedScheduleType: number;
}

export const ReservedScheduleForm = ({
  locationsFragment,
  reservedScheduleType,
}: ReservedScheduleFormProps): JSX.Element => {
  const data = useFragment(GetLocationsFragment, locationsFragment);
  type ReservedScheduleForm = z.infer<typeof reservedScheduleBasicInfo>;

  const [reservedScheduleFormAtomValue, setReservedScheduleFormAtomValue] = useAtom(
    reservedScheduleFormAtom,
  );

  const getDuration = (): string => {
    return (
      reservedScheduleFormAtomValue.duration ||
      (reservedScheduleType === ReservedScheduleTypeEnum.Livance.valueOf()
        ? LIVANCE_MINIMUM_HOURS
        : PARTNERSHIPS_MINIMUM_HOURS)
    );
  };
  const form = useForm<ReservedScheduleForm>({
    resolver: zodResolver(reservedScheduleBasicInfo),
    defaultValues: {
      duration: getDuration(),
      location: reservedScheduleFormAtomValue.location,
      roomType: reservedScheduleFormAtomValue.roomType,
      frequency: reservedScheduleFormAtomValue.frequency,
      dayOfWeek: reservedScheduleFormAtomValue.dayOfWeek,
      locationName: "",
    },
  });

  function getFrequencyOptions(): SelectDrawerItemProps<number>[] {
    const frequency: SelectDrawerItemProps<number>[] = [
      {
        value: ScheduleFrequencyEnum.Semanalmente.valueOf(),
        label: "Semanal",
      },
    ];
    if (reservedScheduleType !== ReservedScheduleTypeEnum.Livance.valueOf()) {
      frequency.push({
        value: ScheduleFrequencyEnum.Quinzenalmente.valueOf(),
        label: "Quinzenal",
      });
    }
    return frequency;
  }

  function getDurationOptions(): {
    minHour: number;
    minMinutes: number;
    maxHour: number;
    maxMinutes: number;
    stepHour: number;
    stepMinutes: number;
    initialHour?: number;
    initialMinutes?: number;
  } {
    const timerProps = {
      minHour: 1,
      minMinutes: 0,
      maxHour: 13,
      maxMinutes: 60,
      stepHour: 1,
      stepMinutes: 30,
      initialHour: 1,
      initialMinutes: 0,
    };
    if (reservedScheduleType === ReservedScheduleTypeEnum.Livance.valueOf()) {
      timerProps.minHour = 4;
      timerProps.initialHour = 4;
    }
    if (reservedScheduleFormAtomValue.duration) {
      const [hours, minutes] = reservedScheduleFormAtomValue.duration.split(":");
      timerProps.initialHour = parseInt(hours, 10);
      timerProps.initialMinutes = parseInt(minutes, 10);
    }
    return timerProps;
  }

  const codUnidade = form.watch("location");

  const getRoomTypesOptions = (): SelectDrawerItemProps<number>[] => {
    if (codUnidade !== 0) {
      const selectedLocation = data.locations.find(
        (location) => location.codUnidade === codUnidade,
      );
      if (selectedLocation) {
        const roomTypesFiltered = selectedLocation.rooms.filter(
          (room) =>
            room.codTipoSala === RoomTypeEnum.Multidisciplinar.valueOf() ||
            room.codTipoSala === RoomTypeEnum.Psico.valueOf() ||
            room.codTipoSala === RoomTypeEnum.Teleconsulta.valueOf() ||
            room.codTipoSala === RoomTypeEnum.GO.valueOf(),
        );
        const rooms: SelectDrawerItemProps<number>[] = roomTypesFiltered.map((room) => {
          let label = room.roomType.nome;
          if (room.codTipoSala === RoomTypeEnum.Teleconsulta.valueOf()) {
            label = room.roomType.nome.split(" ")[0];
          }
          return {
            label,
            value: room.codTipoSala,
          };
        });

        rooms.sort((a, b) => a.label.localeCompare(b.label));

        return rooms;
      }
    }
    return [];
  };

  const verifyResetRoomType = (): void => {
    const selectedRoomType = form.getValues("roomType");
    const selectedLocation = data.locations.find(
      (location) => location.codUnidade === codUnidade,
    );
    if (selectedLocation) {
      const rooms = selectedLocation.rooms;
      const roomTypeExists = rooms.some((room) => room.codTipoSala === selectedRoomType);
      if (!roomTypeExists && selectedRoomType !== 0) {
        form.setValue("roomType", 0);
      }
    }
  };

  const navigate = useNavigate();
  const handleSubmit = (formData: ReservedScheduleForm): void => {
    const selectedLocation = data.locations.find(
      (item) => item.codUnidade === formData.location,
    );
    setReservedScheduleFormAtomValue({
      ...formData,
      locationName: selectedLocation?.nome ?? "",
    });
    void navigate({ to: "/settings/reserved-schedules/create/slots" });
  };

  const locations: FragmentType<typeof UnitsListToSelectQueryFragment>[] = data.locations;
  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={handleSubmit}>
        <FormField
          control={form.control}
          name="location"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <UnitsSelectInput
                  title="Unidade"
                  data={locations}
                  Icon={LocationMarker}
                  searchable
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    verifyResetRoomType();
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="roomType"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <SelectInput<number>
                  title="Tipo de sala"
                  items={getRoomTypesOptions()}
                  Icon={Door}
                  {...field}
                  disabled={!codUnidade}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="frequency"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <SelectInput<number>
                  items={getFrequencyOptions()}
                  Icon={Rotate}
                  title="Frequência"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="dayOfWeek"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <DaysOfWeekSelector title="Escolha o dia da semana" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="duration"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <TimeInput
                  label="Duração"
                  timerConfigs={getDurationOptions()}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormSubmitButton>Avançar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
