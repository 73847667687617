import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef, useState } from "react";
import { HelpCircle } from "@repo/icons";
import {
  MultiSelectInput,
  type MultiSelectInputProps,
} from "@/components/multi-select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import {
  TooltipDrawerBody,
  TooltipDrawerRoot,
  TooltipDrawerTitle,
} from "@/components/tooltip-drawer";

export const AppointmentChannelsListToMultiSelectQueryFragment = graphql(`
  fragment AppointmentChannelsListToMultiSelectQueryFragment on canaisAgendamentos {
    codCanalAgendamento
    nome
  }
`);

interface AppointmentChannelsMultiSelectInputProps
  extends Omit<MultiSelectInputProps<number>, "items"> {
  data: FragmentType<typeof AppointmentChannelsListToMultiSelectQueryFragment>[];
}

export const AppointmentChannelsMultiSelectInput = forwardRef<
  HTMLInputElement,
  AppointmentChannelsMultiSelectInputProps
>(({ data, ...props }, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
  const [appointmentChannelsHelpDialogShow, setAppointmentChannelsHelpDialogShow] =
    useState<boolean>(false);

  const appointmentChannels = useFragment(
    AppointmentChannelsListToMultiSelectQueryFragment,
    data,
  );

  const items: SelectDrawerItemProps<number>[] = appointmentChannels.map(
    (appointmentChannel) => {
      return {
        value: appointmentChannel.codCanalAgendamento,
        label: appointmentChannel.nome,
      };
    },
  );

  return (
    <>
      <MultiSelectInput<number>
        {...props}
        items={items}
        ref={ref}
        Icon={HelpCircle}
        onIconClick={() => setAppointmentChannelsHelpDialogShow(true)}
      />

      <TooltipDrawerRoot
        open={appointmentChannelsHelpDialogShow}
        setOpen={setAppointmentChannelsHelpDialogShow}
      >
        <TooltipDrawerTitle>Permitir agendamentos por</TooltipDrawerTitle>
        <TooltipDrawerBody>
          <p>
            Escolha os canais através dos quais seus pacientes poderão agendar consultas.
            <br />
            Se optar por &apos;Site&apos;, as consultas estarão disponíveis no seu site
            profissional.
            <br />
            Se optar por &apos;Secretária Livance&apos;, as consultas poderão ser
            agendadas por telefone e WhatsApp.
            <br />
            Se optar por &apos;Google&apos;, as consultas poderão ser agendadas pelo seu
            perfil do Google My Business.
          </p>
        </TooltipDrawerBody>
      </TooltipDrawerRoot>
    </>
  );
});

AppointmentChannelsMultiSelectInput.displayName = "AppointmentChannelsMultiSelectInput";
