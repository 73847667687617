import { createFileRoute, useSearch } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import {
  formatCurrency,
  formatDateAndTimeIgnoringTimezone,
  formatIgnoringTimezone,
} from "@repo/lib";
import { CpsBadge } from "corpus";
import { parseISO } from "date-fns";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { InformationalList } from "@/components/list/informational-list";
import { type Info } from "@/components/list/item";
import { formatTimeHourMinuteByTotalMinutes, addDurationToTime } from "@/lib/time";
import { BillingItemDiscount } from "@/components/billing-item-discount";

export interface BillingItemDetails {
  billingItem: {
    dataCadastro: string;
    quantidade: number;
    tempoUnitario: string;
    valor: number;
    valorDesconto: number;
    unidade?: {
      nome: string;
    };
    tipoMovimentacao: { nome: string };
  };
  appointment?: {
    cobraCancelamento: boolean;
    codFinalidadeAgendamento: number;
    data: string;
    horaInicio: string;
    horaFim: string;
    nome: string;
  };
  allocation?: [
    {
      duracaoEmMinutos: number;
    },
  ];
}

export const BillingDetailsPage = (): JSX.Element => {
  const { billingItem, appointment, allocation }: BillingItemDetails = useSearch({
    from: "/settings/billing/details",
  });

  const appointmentDuration = formatTimeHourMinuteByTotalMinutes(
    Math.floor(allocation?.[0].duracaoEmMinutos ?? 0),
  );

  const appointmentStartTime = formatDateAndTimeIgnoringTimezone(
    String(appointment?.data),
    String(appointment?.horaInicio),
    "HH:mm",
  );
  const appointmentEndTime = formatDateAndTimeIgnoringTimezone(
    String(appointment?.data),
    String(appointment?.horaFim),
    "HH:mm",
  );

  const appointmentDateTime = `${formatIgnoringTimezone(parseISO(String(appointment?.data)), "dd/MM/yyyy")}  ${appointmentStartTime} - ${appointmentEndTime}`;

  const allocationEndTime = addDurationToTime(appointmentStartTime, appointmentDuration);

  const appointmentBillingDuration = formatTimeHourMinuteByTotalMinutes(
    billingItem.quantidade,
  );

  const billingValue = billingItem.valor;
  const hasDiscount = billingItem.valorDesconto < 0;

  const totalBillingValue = hasDiscount
    ? billingValue + billingItem.valorDesconto
    : billingValue;

  const flexiblePrice = appointment?.cobraCancelamento ?? false;
  const durationLimit = (appointment?.codFinalidadeAgendamento ?? -1) === 3;

  const infos: Info[] = [
    {
      label: "Paciente",
      value: appointment?.nome ?? "",
    },
    {
      label: "Agendamento",
      value: appointmentDateTime,
    },
    {
      label: "Utilização",
      value: `${appointmentStartTime} - ${allocationEndTime}`,
    },
    {
      label: "Duração",
      value: appointmentDuration,
    },
    {
      label: "Tempo Cobrado",
      value: appointmentBillingDuration,
    },
    {
      label: "Unidade",
      value: billingItem.unidade?.nome ?? "",
    },
  ].filter((item) => item.value);

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Histórico de uso" align="center" />
      </HeaderRoot>
      <Page>
        <div className="flex flex-col items-start justify-center">
          <p className="font-normal text-sm text-neutral-500">
            {billingItem.tipoMovimentacao.nome}
          </p>
          <BillingItemDiscount
            billingItemValue={billingItem.valor}
            discountValue={billingItem.valorDesconto}
          />
          <p className="font-medium text-2xl text-neutral-700">
            {formatCurrency(totalBillingValue)}
          </p>

          <div className="w-full mt-6">
            <p className="font-medium">Detalhes do agendamento</p>

            {flexiblePrice || durationLimit ? (
              <div className="flex flex-row justify-between items-center py-6 border-b border-neutral-100">
                <p className="text-sm text-gray-600 font-normal">Modalidade</p>
                {flexiblePrice ? (
                  <CpsBadge color="primary">Preço flexível</CpsBadge>
                ) : null}
                {durationLimit ? (
                  <CpsBadge color="helper">Limite de duração</CpsBadge>
                ) : null}
              </div>
            ) : null}
            <InformationalList infos={infos} />
          </div>
        </div>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/billing/details")({
  component: BillingDetailsPage,
});
