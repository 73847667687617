import { useEffect, useRef, useState } from "react";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { CpsAvatar } from "corpus";
import { CheckCircleColored } from "@repo/icons";
import { ModalAvatarOverlay } from "@/components/modal-avatar-overlay";
import { useGraphQL } from "@/hooks/use-graphql";
import { getNameInitials } from "@/lib/string";

export const PatientPhotoQuery = graphql(/* GraphQL */ `
  query PatientPhotoQuery($codPaciente: Int!) {
    patient: pacientes_by_pk(codPaciente: $codPaciente) {
      foto
    }
  }
`);

export const PatientAvatarFragment = graphql(/* GraphQL */ `
  fragment PatientAvatarFragment on pacientes {
    codPaciente
    nome
  }
`);

export type AvatarColor =
  | "secondaryLight"
  | "primaryLight"
  | "neutralLight"
  | "white"
  | "dangerLight";

export type IconSizes = 24 | 40 | 56 | 72 | 96 | 156;

export interface PatientAvatarProps {
  data: FragmentType<typeof PatientAvatarFragment>;
  color: AvatarColor;
  size?: IconSizes;
  shouldOpenModalOverlay?: boolean;
  isModalOverlayOpen?: boolean;
  setIsModalOverlayOpen?: (value: boolean) => void;
  showCheckBadge?: boolean;
}

export const PatientAvatar = ({
  data,
  color,
  size = 40,
  shouldOpenModalOverlay = false,
  isModalOverlayOpen = false,
  setIsModalOverlayOpen,
  showCheckBadge = false,
}: PatientAvatarProps): JSX.Element => {
  const DEFAULT_STALE_TIME = 60_000;

  const patient = useFragment(PatientAvatarFragment, data);

  const [staleTime, setStaleTime] = useState(DEFAULT_STALE_TIME);
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  const { data: patientPhotoData } = useGraphQL(
    PatientPhotoQuery,
    { codPaciente: patient.codPaciente },
    { enabled: isVisible, staleTime },
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Verifica se o componente está visível
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        // Define as opções do IntersectionObserver
        root: null,
        rootMargin: "0px",
        threshold: 0.25, // 0.25 indica que pelo menos 25% do componente deve estar visível para disparar a função
      },
    );

    /* eslint-disable @typescript-eslint/no-unnecessary-condition -- currentRef not always false */
    const currentRef = componentRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    // Limpa o observer quando o componente for desmontado
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
    /* eslint-enable @typescript-eslint/no-unnecessary-condition -- currentRef not always false */
  }, []);

  const imageSrc = patientPhotoData?.patient?.foto
    ? `data:image/png;base64,${patientPhotoData.patient.foto as string}`
    : undefined;

  const hasImage = imageSrc !== undefined;
  const shouldOpen = shouldOpenModalOverlay && hasImage;

  if (hasImage && staleTime === DEFAULT_STALE_TIME) {
    setStaleTime(Infinity);
  }

  interface SetModalOverlayType {
    value: boolean;
    event?: React.MouseEvent | React.KeyboardEvent;
  }

  const setModalOverlay = ({ value, event }: SetModalOverlayType): void => {
    if (setIsModalOverlayOpen && shouldOpen) {
      if (event) {
        event.stopPropagation();
      }
      setIsModalOverlayOpen(value);
    }
  };

  return (
    <>
      <div
        ref={componentRef}
        className="relative"
        onClick={(event) => setModalOverlay({ value: true, event })}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") setModalOverlay({ value: true, event: e });
        }}
      >
        <CpsAvatar
          alt="Avatar"
          color={color}
          initials={getNameInitials(patient.nome)}
          size={size}
          src={imageSrc}
        />
        {showCheckBadge ? (
          <div
            data-testid="badge-treated-patient"
            className="h-[17px] w-[17px] absolute bottom-0 left-[-2px] justify-center flex items-center"
          >
            <CheckCircleColored size={48} />
          </div>
        ) : null}
      </div>

      {shouldOpen ? (
        <ModalAvatarOverlay
          src={imageSrc}
          alt={patient.nome}
          show={isModalOverlayOpen}
          setShow={(value, event) => setModalOverlay({ value, event })}
        />
      ) : null}
    </>
  );
};
