import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import { SelectInput, type SelectInputProps } from "@/components/select-input";
import { mapPaymentMethodsToSelectDrawerItemsProps } from "@/lib/mappers/payment-methods";

export const PaymentMethodsListToSelectQueryFragment = graphql(/* GraphQL */ `
  fragment PaymentMethodsListToSelectQueryFragment on usuariosFormasRecebimentos {
    codUsuarioFormaRecebimento
    codTipoFormaRecebimento
    codTipoConvenio
    nomeConvenio
    TipoConvenio {
      nome
    }
    TipoFormaRecebimento {
      nome
    }
  }
`);

interface PaymentMethodsSelectInputProps extends Omit<SelectInputProps<number>, "items"> {
  data: FragmentType<typeof PaymentMethodsListToSelectQueryFragment>[];
}

export const PaymentMethodsSelectInput = forwardRef<
  HTMLInputElement,
  PaymentMethodsSelectInputProps
>(({ data, ...props }, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
  const paymentMethods = useFragment(PaymentMethodsListToSelectQueryFragment, data);

  return (
    <SelectInput<number>
      {...props}
      items={mapPaymentMethodsToSelectDrawerItemsProps(paymentMethods)}
      ref={ref}
    />
  );
});

PaymentMethodsSelectInput.displayName = "PaymentMethodsSelectInput";
