import { Camera, type GalleryPhoto } from "@capacitor/camera";
import { graphql } from "@repo/graphql-types/gql";
import { Edit1, ExclamationCircle } from "@repo/icons";
import { formatWithZonedDate, logError } from "@repo/lib";
import { useRouteContext } from "@tanstack/react-router";
import imageCompression from "browser-image-compression";
import { CpsDialog, CpsToast } from "corpus";
import { useState } from "react";
import { MyProfilePageQueryDocument } from "@repo/graphql-types/graphql";
import { Button } from "@/components/button";
import { useLivanceAPIMutation } from "@/hooks/use-livance-api";
import { useInvalidateQuery } from "@/hooks/use-graphql";

export const ProfilePictureFragment = graphql(`
  fragment ProfilePictureFragment on query_root {
    profilePictureFile: LivanceApiSelecionaFotoDePerfil(
      arg1: { codUsuario: $codUsuario }
    ) {
      arquivo
      mimetype
    }
  }
`);

export const ChangeProfilePictureButton = (): JSX.Element => {
  const { user } = useRouteContext({
    strict: false,
  });

  const [showDeniedPermissionErrorToast, setShowDeniedPermissionErrorToast] =
    useState<boolean>(false);

  const uploadProfilePictureMutation = useLivanceAPIMutation("UsuarioArquivo", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const invalidateMyProfilePageQuery = useInvalidateQuery(MyProfilePageQueryDocument);

  const executeMutation = async (
    selectedFile: Uint8Array | null,
    mimetype: string,
  ): Promise<void> => {
    if (selectedFile === null) {
      throw new Error("É necessário escolher um arquivo válido");
    }

    const profilePictureFileType = 1;

    const body = {
      arquivo: Array.from(selectedFile),
      codUsuario: user.codUsuario,
      dataCadastro: formatWithZonedDate(new Date(), "yyyy-MM-dd"),
      mimetype,
      codTipoArquivo: profilePictureFileType,
    };

    await uploadProfilePictureMutation.mutateAsync(body, {
      onSuccess: () => {
        invalidateMyProfilePageQuery();
      },
    });
  };

  const checkAndRequestPermissions = async (): Promise<boolean> => {
    let permission = await Camera.checkPermissions();
    if (permission.photos === "denied") {
      permission = await Camera.requestPermissions();
    }

    if (permission.photos === "denied") {
      setShowDeniedPermissionErrorToast(true);
      return false;
    }

    return true;
  };

  const pickImage = async (): Promise<GalleryPhoto | null> => {
    const result = await Camera.pickImages({ limit: 1 });

    return result.photos.at(0) ?? null;
  };

  const fetchAndCompressImage = async (webPath: string): Promise<File> => {
    const response = await fetch(webPath);
    const responseBlob = await response.blob();
    const fileFromBlob = new File([responseBlob], "profile_picture", {
      type: responseBlob.type,
    });

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    return await imageCompression(fileFromBlob, options);
  };

  const readAndExecuteMutation = (file: File, format: string): void => {
    const reader = new FileReader();
    reader.onload = (): void => {
      const arrayDeBytes = new Uint8Array(reader.result as ArrayBuffer);
      const mimetype = `image/${format}`;
      void executeMutation(arrayDeBytes, mimetype);
    };
    reader.readAsArrayBuffer(file);
  };

  const openFileSelector = async (): Promise<void> => {
    try {
      const hasPermission = await checkAndRequestPermissions();
      if (!hasPermission) return;

      const firstSelectedPhoto = await pickImage();
      if (!firstSelectedPhoto) return;

      const compressedFile = await fetchAndCompressImage(firstSelectedPhoto.webPath);

      readAndExecuteMutation(compressedFile, firstSelectedPhoto.format);
    } catch (error) {
      logError(error);
    }
  };

  return (
    <>
      <div className="absolute bottom-0 left-24">
        <Button
          className="h-[56px] w-[56px]"
          color="secondary/secondary-400"
          Icon={Edit1}
          loading={uploadProfilePictureMutation.isPending}
          onClick={() => void openFileSelector()}
          rounded
        />
      </div>
      <CpsDialog
        Icon={ExclamationCircle}
        description="Ocorreu um erro ao processar a sua solicitação, por favor, tente novamente."
        iconFill="danger"
        setShow={(_: boolean): void => {
          uploadProfilePictureMutation.reset();
        }}
        show={uploadProfilePictureMutation.isError}
        title="Erro inesperado"
      />
      <CpsToast
        title="Você não autorizou a permissão de acesso às imagens do dispositivo. Verifique suas permissões nas configurações do dispositivo."
        show={showDeniedPermissionErrorToast}
        onClose={() => setShowDeniedPermissionErrorToast(false)}
        type="danger"
        duration={5}
      />
    </>
  );
};
