import type { ForwardedRef, FunctionComponent, InputHTMLAttributes } from "react";
import { forwardRef, useState } from "react";
import { cn } from "@repo/lib";
import { type SVGIconProps } from "@repo/icons";
import { Button } from "@/components/button";
import {
  SelectDrawer,
  SelectDrawerBodyListCheckbox,
  SelectDrawerBodyMultipleList,
  SelectDrawerFooter,
  type SelectDrawerItemProps,
  SelectDrawerSearch,
  SelectDrawerTitle,
} from "@/components/select-drawer";
import {
  InputSelect,
  InputSelectContent,
  InputSelectTitle,
} from "@/components/input-select";

export interface MultiSelectInputProps<T extends string | number>
  extends InputHTMLAttributes<HTMLInputElement> {
  value?: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement> | string[]) => void;
  items: SelectDrawerItemProps<T>[];
  searchable?: boolean;
  required?: boolean;
  title: string;
  placeholder?: string;
  name: string;
  Icon?: FunctionComponent<SVGIconProps>;
  onIconClick?: () => void;
}

export const MultiSelectInputInner = <T extends string | number>(
  {
    id,
    name,
    title,
    value,
    onChange,
    items,
    searchable,
    Icon,
    onIconClick,
    placeholder = "Selecione",
    required = false,
    ...props
  }: MultiSelectInputProps<T>,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
  const initialSelectedItems = value
    ? items.filter((x) => value.some((y) => y === String(x.value)))
    : [];

  const [showDrawer, setShowDrawer] = useState(false);
  const [searchResults, setSearchResults] = useState<SelectDrawerItemProps<T>[]>(items);
  const [selectedItems, setSelectedItems] = useState<
    SelectDrawerItemProps<T>[] | undefined
  >(value ? initialSelectedItems : undefined);

  const buildValue = (): string[] => {
    if (initialSelectedItems.length === 0) return [];

    return initialSelectedItems.map((x) => String(x.value));
  };

  const buildLabel = (): string => {
    if (initialSelectedItems.length === 0) return "";

    return initialSelectedItems.map((x) => x.label).join(", ");
  };

  const commitChangesAndClose = (): void => {
    if (!selectedItems) return;

    onChange(selectedItems.map((x) => String(x.value)));
    setShowDrawer(false);
  };

  const hasSelectedItems = selectedItems && selectedItems.length > 0;

  return (
    <>
      <InputSelect required={required}>
        <InputSelectTitle Icon={Icon} onIconClick={onIconClick}>
          {title}
        </InputSelectTitle>
        <InputSelectContent
          id={id}
          name={name}
          disabled={props.disabled}
          value={buildValue()}
          label={buildLabel()}
          ref={ref}
          onClick={() => setShowDrawer(true)}
          placeholder={placeholder}
          required={required}
        />
      </InputSelect>

      <SelectDrawer open={showDrawer} setOpen={setShowDrawer}>
        <SelectDrawerTitle>{title}</SelectDrawerTitle>

        {searchable ? (
          <SelectDrawerSearch items={items} onSearch={setSearchResults} />
        ) : null}

        <SelectDrawerBodyMultipleList
          selectedItems={initialSelectedItems}
          setSelectedItems={setSelectedItems}
        >
          {items.map((item) => {
            const isInSearchResults = searchResults.some(
              (result) => result.value === item.value,
            );

            return (
              <SelectDrawerBodyListCheckbox
                value={item.value}
                key={item.value}
                checked={initialSelectedItems.some((x) => x.value === item.value)}
                className={cn(!isInSearchResults && "hidden")}
              >
                {item.label}
              </SelectDrawerBodyListCheckbox>
            );
          })}
        </SelectDrawerBodyMultipleList>
        <SelectDrawerFooter>
          <Button
            onClick={commitChangesAndClose}
            disabled={!hasSelectedItems && required}
            type="submit"
            fullWidth
          >
            Confirmar
          </Button>
        </SelectDrawerFooter>
      </SelectDrawer>
    </>
  );
};

export const MultiSelectInput = forwardRef(MultiSelectInputInner) as <
  T extends string | number,
>(
  p: MultiSelectInputProps<T> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof MultiSelectInputInner>;
