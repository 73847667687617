import {
  createFileRoute,
  Link,
  type ParsedLocation,
  useSearch,
} from "@tanstack/react-router";
import { z } from "zod";
import { Browser } from "@capacitor/browser";
import { DoorEnter } from "@repo/icons";
import { Sentry } from "@repo/lib";
import { type RootRouteContext } from "@/routes/-root-context.tsx";
import { HeaderRoot, HeaderTitle } from "@/components/header.tsx";
import { Page } from "@/components/page.tsx";
import {
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state.tsx";
import { logLoginStarted, logLoginRequestedFinished } from "@/lib/breadcrumbs.ts";

const beforeLoad = async (
  context: RootRouteContext,
  location: ParsedLocation,
  search: LoginRedirectionSchema,
): Promise<void> => {
  logLoginStarted({ location, retry: search.retry });
  await context.authClient.loginWithRedirect({
    async openUrl(url) {
      Sentry.addBreadcrumb(
        {
          category: "auth",
          message: `Opening login URL: ${url}`,
          level: "info",
        },
        {
          url,
        },
      );

      await Browser.open({
        url,
        windowName: "_self",
      });
    },
    appState: { returnTo: search.returnTo, retry: search.retry },
  });
  logLoginRequestedFinished({ location });
};

export const LoginPendingPage = (): JSX.Element => {
  const search = useSearch({ from: "/auth/login" });
  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Livance" align="center" />
      </HeaderRoot>
      <Page>
        <EmptyStateRoot>
          <EmptyStateIcon icon={DoorEnter} />
          <EmptyStateTitle>Entrando no seu consultório</EmptyStateTitle>
          <EmptyStateDescription>
            Você será redirecionado para a tela de login, caso nada aconteça, use o botão
            abaixo.
          </EmptyStateDescription>
          <Link
            to="/"
            preload={false}
            search={{ ...search, retry: search.retry ? search.retry + 1 : 0 }}
            className="w-full"
          >
            <EmptyStateButton fullWidth>Tentar novamente</EmptyStateButton>
          </Link>
        </EmptyStateRoot>
      </Page>
    </>
  );
};

const loginRedirectionSearch = z.object({
  returnTo: z.string().default("/"),
  retry: z.number().optional().catch(0),
});

type LoginRedirectionSchema = z.output<typeof loginRedirectionSearch>;

export const Route = createFileRoute("/auth/login")({
  component: LoginPendingPage,
  validateSearch: loginRedirectionSearch,
  beforeLoad: async ({ context, location, search }) => {
    await beforeLoad(context, location, search);
  },
});
