import type { CalendarOptions, DayCellContentArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import { Left, Right } from "@repo/icons";
import {
  type CalendarView,
  cn,
  formatWithZonedDate,
  getCurrentDate,
  type CalendarEvent,
  convertDateTimeIgnoringTimezone,
  formatIgnoringTimezone,
} from "@repo/lib";
import { isBefore, isSameDay, setDay, startOfDay } from "date-fns";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useDrag } from "@use-gesture/react";
import { motion } from "framer-motion";

export interface CalendarProps extends CalendarOptions {
  events?: CalendarEvent[];
  initialView?: CalendarView;
  possibleViews: CalendarView[];
  selectedDate: string;
  disabledPastDates?: boolean;
  onDateClick?: (date: string) => void;
  onChange?: (date: Date) => void;
  onChangeView?: (view: CalendarView) => void;
  onBackwardClick?: () => void;
  onForwardClick?: () => void;
}

export const Calendar = forwardRef<HTMLDivElement, CalendarProps>(
  (
    {
      events,
      initialView,
      possibleViews,
      selectedDate,
      disabledPastDates = false,
      onDateClick,
      onChange,
      onChangeView,
      onBackwardClick,
      onForwardClick,
      ...props
    },
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    const bind = useDrag(({ swipe }) => {
      const [swipeX, swipeY] = swipe;

      if (swipeX === 1) {
        onInternalBackwardClick();
      }
      if (swipeX === -1) {
        onInternalForwardClick();
      }

      if (swipeY === -1) {
        setView("dayGridDay");
      }

      if (swipeY === 1) {
        setView("dayGridMonth");
      }
    }, {});

    const today = getCurrentDate();

    const [currentView, setCurrentView] = useState(initialView ?? "dayGridDay");

    const selectedDateObject = convertDateTimeIgnoringTimezone(selectedDate);

    const selectedWeekday = selectedDateObject.getDay();
    const calendarRef = useRef<FullCalendar>(null);

    const cellCalendarElement = (args: DayCellContentArg): JSX.Element => {
      const date = args.date;
      const startDate = startOfDay(date);
      const isPastDate = disabledPastDates && isBefore(startDate, today);
      const cellClasses = cn(
        "rounded-40 relative cursor-pointer select-none p-[20px] text-lg font-medium text-neutral-600 transition-all",
        isSameDay(selectedDateObject, date) && "bg-secondary-400 text-white",
        isPastDate && "disabled-past",
      );

      return (
        <button
          aria-label={`Data ${formatWithZonedDate(date, "d 'de' MMMM 'de' yyyy")}`}
          className={cellClasses}
          key={date.getDate()}
          onClick={() => {
            if (isPastDate) {
              return;
            }

            onDateClick && onDateClick(formatIgnoringTimezone(date));
            onChange && onChange(date);
          }}
          type="button"
        >
          <span className="absolute -translate-x-1/2 -translate-y-1/2">
            {date.getDate()}
          </span>
        </button>
      );
    };

    const handleCalendarNavigation = (direction: "prev" | "next"): void => {
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();

        if (direction === "prev") {
          calendarApi.prev();
        }

        if (direction === "next") {
          calendarApi.next();
        }

        let date = calendarApi.getDate();
        date = currentView !== "dayGridMonth" ? setDay(date, selectedWeekday) : date;

        onChange && onChange(date);
        onDateClick && onDateClick(formatIgnoringTimezone(date));
      }
    };

    const onInternalBackwardClick = (): void => {
      handleCalendarNavigation("prev");
      onBackwardClick && onBackwardClick();
    };

    const onInternalForwardClick = (): void => {
      handleCalendarNavigation("next");
      onForwardClick && onForwardClick();
    };

    const setView = (changed: CalendarView): void => {
      if (!calendarRef.current) {
        return;
      }

      setCurrentView(changed);
      if (changed === "dayGridDay") {
        calendarRef.current.getApi().changeView("dayGridWeek");
      } else {
        calendarRef.current.getApi().changeView(changed);
      }

      onChangeView && onChangeView(changed);
    };

    const onInternalChangeView = (): void => {
      if (calendarRef.current) {
        const currentIndex = possibleViews.indexOf(currentView);

        const nextIndex = getNextIndex(currentIndex);

        const changed = possibleViews[nextIndex];

        setView(changed);
      }
    };

    const viewTextMap: Record<CalendarView, string> = {
      dayGridDay: "Ver dia",
      dayGridWeek: "Ver semana",
      dayGridMonth: "Ver mês",
    };

    const renderTextView = (): string => {
      const currentIndex = possibleViews.indexOf(currentView);

      const nextIndex = getNextIndex(currentIndex);

      const nextView = possibleViews[nextIndex];
      return viewTextMap[nextView];
    };

    function getNextIndex(currentIndex: number): number {
      return (currentIndex + 1) % possibleViews.length;
    }

    useEffect(() => {
      queueMicrotask(() => {
        if (calendarRef.current && !onChange) {
          calendarRef.current.getApi().gotoDate(selectedDate);
        }
      });
    }, [selectedDate, onChange]);

    return (
      <div className="rounded-40" ref={ref}>
        <div className="flex flex-row items-center justify-between px-1">
          <h2 className="text-md font-medium capitalize text-neutral-600">
            {formatWithZonedDate(selectedDate, "MMMM, yyyy")}
          </h2>
          <div className="gap-3 flex flex-row items-center">
            <button
              aria-label="Botão de Retroceder Data"
              onClick={onInternalBackwardClick}
              type="button"
            >
              <Left className="fill-secondary-300" size={16} />
            </button>
            <button
              aria-label="Botão de Mudar Visualização"
              className="text-secondary-400 text-md cursor-pointer select-none font-medium"
              onClick={onInternalChangeView}
              type="button"
            >
              {renderTextView()}
            </button>
            <button
              aria-label="Botão de Avançar Data"
              onClick={onInternalForwardClick}
              type="button"
            >
              <Right className="fill-secondary-300" size={16} />
            </button>
          </div>
        </div>

        <div className="pt-4 day-selector h-full w-full">
          <div className={cn("block", "touch-none")} {...bind()}>
            <motion.div>
              <FullCalendar
                dayCellContent={(args) => cellCalendarElement(args)}
                dayHeaderFormat={{ weekday: "narrow" }}
                dayMaxEventRows={0}
                events={events}
                headerToolbar={false}
                initialDate={selectedDate}
                height="auto"
                initialView={currentView === "dayGridDay" ? "dayGridWeek" : currentView}
                locale="pt-br"
                plugins={[dayGridPlugin]}
                ref={calendarRef}
                titleFormat={{ year: "numeric", month: "long" }}
                stickyHeaderDates={false}
                {...props}
              />
            </motion.div>
          </div>
        </div>
      </div>
    );
  },
);

Calendar.displayName = "Calendar";
