import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import {
  CalendarPageAppointmentsQueryDocument,
  CalendarPageFreeSlotsQueryDocument,
  type CalendarPageAppointmentsQueryQuery,
  type CalendarPageFreeSlotsQueryQuery,
  type CalendarPageScheduleQueryQuery,
} from "@repo/graphql-types/graphql";
import {
  allLocationObject,
  type Location,
  type CalendarEvent,
  type CalendarView,
} from "@repo/lib";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { useState, useRef } from "react";
import { type ElementProps, SelectModal } from "@/components/select-modal";
import { PendingBillingDrawer } from "@/components/pending-billing-drawer";
import { RemoveUnavailableSlotDrawer } from "@/components/remove-unavailable-slot-drawer";
import { HomeCalendarDayEvent } from "@/components/home-calendar-day-event";
import { HomePageCalendar } from "@/components/home-page-calendar";
import { AppointmentDrawer } from "@/components/appointment-drawer/appointment-drawer";
import { AppointmentCancelDrawer } from "@/components/appointment-cancel-drawer";
import { FlexiblePriceAppointmentCancelDrawer } from "@/components/flexible-price-appointment-cancel-drawer";
import { FlexiblePriceAppointmentRescheduleDrawer } from "@/components/flexible-price-appointment-reschedule-drawer";
import { BlockedAppointmentAlert } from "@/components/blocked-appointment-alert";
import { HighCancellationAlert } from "@/components/high-cancellation-alert.tsx";
import { trackEvent } from "@/lib/tracking";
import { AppointmentExtendedDrawer } from "@/components/appointment-extended-drawer";
import { useInvalidateQuery } from "@/hooks/use-graphql";

export const AppointmentsQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentsQueryFragment on query_root {
    ...HomePageCalendarFragment
    ...HomeCalendarDayEventFragment
  }
`);

export const FreeScheduleSlotsFragment = graphql(/* GraphQL */ `
  fragment FreeScheduleSlotsFragment on query_root {
    ...FreeSchedulesSlotsHomePageCalendarFragment
  }
`);

export const ScheduleSlotsFragment = graphql(/* GraphQL */ `
  fragment ScheduleSlotsFragment on query_root {
    ...FreeSchedulesSlotsHomeCalendarDayEventFragment
  }
`);

export const HolidaysQueryFragment = graphql(/* GraphQL */ `
  fragment HolidaysQueryFragment on query_root {
    ...HolidaysHomeCalendarDayEventFragment
  }
`);

export const TimePoliciesQueryFragment = graphql(/* GraphQL */ `
  fragment TimePoliciesQueryFragment on query_root {
    ...TimePoliciesHomeCalendarDayEventFragment
  }
`);

export const BlockedActionsListFragment = graphql(/* GraphQL */ `
  fragment BlockedActionsListFragment on query_root {
    ...BlockedActionsListAlertFragment
  }
`);

const UnitsBySchedulesQueryFragment = graphql(/* GraphQL */ `
  fragment UnitsBySchedulesQueryFragment on query_root {
    usuariosAgendas(where: { ativo: { _eq: true } }) {
      usuariosAgendasUnidades: tbUsuariosAgendasUnidades(
        where: { Unidade: { ativo: { _eq: true } } }
      ) {
        Unidade {
          nome
          nomeLimpo
          codUnidade
          ativo
          sigla
        }
      }
    }
  }
`);

interface HomeCalendarProps {
  appointmentsQueryData: CalendarPageAppointmentsQueryQuery;
  scheduleQueryData: CalendarPageScheduleQueryQuery;
  freeSlotsQueryData: CalendarPageFreeSlotsQueryQuery;
  currentDate: string;
  currentLocation: Location;
}

export const HomeCalendar = ({
  appointmentsQueryData,
  scheduleQueryData,
  freeSlotsQueryData,
  currentDate,
  currentLocation,
}: HomeCalendarProps): JSX.Element => {
  const searchParams = useSearch({
    from: "/",
  });

  const { user: userContext } = useRouteContext({
    strict: false,
  });

  const currentCalendarView: CalendarView = searchParams.calendarView ?? "dayGridDay";
  const currentShowCancelled: boolean = searchParams.showCancelled ?? false;

  const router = useRouter();
  const calendarHeaderRef = useRef<HTMLDivElement>(null);

  const [currentEventSelected, setCurrentEventSelected] = useState<CalendarEvent>();

  /* Fragments data */
  const appointmentFragment = useFragment(
    AppointmentsQueryFragment,
    appointmentsQueryData,
  );
  const holidaysFragment = useFragment(HolidaysQueryFragment, appointmentsQueryData);
  const freeScheduleSlotsFragment = useFragment(
    FreeScheduleSlotsFragment,
    freeSlotsQueryData,
  );
  const scheduleSlotsFragment = useFragment(ScheduleSlotsFragment, scheduleQueryData);
  const timePoliciesFragment = useFragment(TimePoliciesQueryFragment, scheduleQueryData);
  const userSchedules = useFragment(
    UnitsBySchedulesQueryFragment,
    scheduleQueryData,
  ).usuariosAgendas;
  const blockedActionsListFragment = useFragment(
    BlockedActionsListFragment,
    scheduleQueryData,
  );

  const invalidateCalendarPageAppointmentsQuery = useInvalidateQuery(
    CalendarPageAppointmentsQueryDocument,
  );
  const invalidateCalendarPageFreeSlotsQuery = useInvalidateQuery(
    CalendarPageFreeSlotsQueryDocument,
  );

  const onCalendarChangeView = (view: CalendarView): void => {
    navigateWithSearch({ calendarView: view });
  };

  const handleLocationChange = (selectedLocation: Location): void => {
    navigateWithSearch({ locationId: selectedLocation.codUnidade });
  };

  const onDateChangeHandler = (date: string): void => {
    navigateWithSearch({ date });
  };

  const handleOpenRemoveUnavailableSlot = (unavailableSlotId: number): void => {
    navigateWithSearch({
      unavailableSlotId,
      action: "delete-unavailable-slot",
    });
  };

  const handleCloseRemoveUnavailableSlot = (): void => {
    navigateWithSearch({ unavailableSlotId: undefined, action: undefined });
  };

  const handleOpenCalendarAppointmentDetails = (appointmentId: number): void => {
    navigateWithSearch({
      appointmentId,
      action: "appointment-details",
    });
  };

  const handleClickCancelAppointment = (
    appointmentId: number,
    shouldDisplayFlexiblePriceDrawer: boolean,
  ): void => {
    if (shouldDisplayFlexiblePriceDrawer) {
      navigateWithSearch({
        action: "cancel-flexible-price-appointment",
        appointmentId,
      });
    } else {
      if (userContext.altaTaxaCancelamento) {
        trackEvent("Alerta Frequencia Cancelamento Visualizado", {
          codUsuario: userContext.codUsuario,
          dataHora: new Date().toISOString(),
          telaOrigem: "Drawer Cancelamento",
        });
      }
      navigateWithSearch({
        action: "cancel-appointment",
        appointmentId,
      });
    }
  };

  const handleCloseAppointmentDrawer = (): void => {
    const possibleOpenedDrawersAfterAppointmentDetailsIsDismissed = [
      "cancel-appointment",
      "expand-avatar-image",
      "reschedule-flexible-price-appointment",
      "cancel-flexible-price-appointment",
    ];

    if (
      !possibleOpenedDrawersAfterAppointmentDetailsIsDismissed.includes(
        searchParams.action ?? "",
      )
    ) {
      navigateWithSearch({ appointmentId: undefined, action: undefined });
    }
  };

  const navigateWithSearch = ({
    date,
    locationId,
    unavailableSlotId,
    action,
    appointmentId,
    calendarView,
    showCancelled,
  }: {
    date?: string;
    locationId?: number;
    unavailableSlotId?: number;
    action?: string;
    appointmentId?: number;
    calendarView?: string;
    showCancelled?: boolean;
  }): void => {
    void router.navigate({
      search: {
        date: date ?? currentDate,
        locationId: locationId ?? currentLocation.codUnidade,
        unavailableSlotId,
        action,
        appointmentId,
        calendarView: calendarView ?? currentCalendarView,
        showCancelled: showCancelled ?? currentShowCancelled,
      },
    });
  };

  const setShowAppointmentCancelDrawer = (showCancelDrawer: boolean): void => {
    if (!showCancelDrawer && searchParams.action === "cancel-appointment") {
      navigateWithSearch({ appointmentId: undefined, action: undefined });
    }
  };

  const getAppointmentIdFromCurrentEvent = (): number => {
    if (currentEventSelected?.appointment?.codAgendamento === undefined) return 0;
    return currentEventSelected.appointment.codAgendamento;
  };

  const currentAppointmentId = getAppointmentIdFromCurrentEvent();

  const onFlexiblePriceAppointmentCancelSuccess = (): void => {
    invalidateCalendarPageAppointmentsQuery();
    invalidateCalendarPageFreeSlotsQuery();

    navigateWithSearch({ appointmentId: undefined, action: undefined });
  };

  /* SelectModal functions and data */
  const selectedLocation = currentLocation;

  const locations: Location[] = userSchedules
    .reduce<Location[]>((acc, schedule) => {
      return acc.concat(
        schedule.usuariosAgendasUnidades
          .filter(
            (location) => location.Unidade !== undefined && location.Unidade !== null,
          )
          .map((location) => location.Unidade) as Location[],
      );
    }, [])
    .filter((location, index, self) => {
      return self.findIndex((l) => l.codUnidade === location.codUnidade) === index;
    });

  const handleSelection = (locationId: number): void => {
    const location = locations.find((x) => x.codUnidade === locationId);
    if (location) {
      handleLocationChange(location);
    }
  };

  const elementsFactory = (elements: Location[]): ElementProps<number>[] => {
    const sortedLocations = elements.sort((a, b) =>
      String(a.nome).localeCompare(String(b.nome)),
    );

    sortedLocations.unshift(allLocationObject);

    return sortedLocations.map((item) => ({
      value: item.codUnidade,
      label: item.nome,
    }));
  };

  const handleOpenSelectLocationModal = (): void => {
    if (searchParams.action === "select-location") {
      navigateWithSearch({ action: undefined });
    }
  };

  const checkHighCancellationRate = userContext.altaTaxaCancelamento;

  return (
    <>
      <div aria-label="Sessão do calendário" className="sr-only hidden">
        Calendário
      </div>

      <BlockedAppointmentAlert
        blockedActionsListFragmentData={blockedActionsListFragment}
      />

      <div className="z-20 bg-white sticky top-0">
        <div
          className="bg-white left-0 transition-shadow duration-200 pt-4"
          ref={calendarHeaderRef}
          id="calendar-header"
        >
          {checkHighCancellationRate && currentShowCancelled ? (
            <HighCancellationAlert showMoreInfo />
          ) : null}

          <div className="py-3">
            <HomePageCalendar
              currentDate={currentDate}
              currentUnit={{
                id: currentLocation.codUnidade,
                name: currentLocation.nome,
                acronym: currentLocation.sigla ?? "",
              }}
              onChangeView={onCalendarChangeView}
              onDateClick={onDateChangeHandler}
              initialView={currentCalendarView}
              homePageCalendarFragmentData={appointmentFragment}
              freeSchedulesSlotsHomePageCalendarFragmentData={freeScheduleSlotsFragment}
            />
          </div>
        </div>
      </div>

      <HomeCalendarDayEvent
        currentDate={currentDate}
        currentLocation={currentLocation}
        currentCalendarView={currentCalendarView}
        setCurrentEventSelected={setCurrentEventSelected}
        handleOpenRemoveUnavailableSlot={handleOpenRemoveUnavailableSlot}
        handleOpenAppointmentDetailsDrawer={handleOpenCalendarAppointmentDetails}
        homeCalendarDayEventFragmentData={appointmentFragment}
        holidaysHomeCalendarDayEventFragmentData={holidaysFragment}
        timePoliciesHomeCalendarDayEventFragmentData={timePoliciesFragment}
        freeScheduleSlotsHomeCalendarDayEventFragmentData={scheduleSlotsFragment}
      />

      {currentEventSelected?.appointment && searchParams.appointmentId !== undefined ? (
        <>
          <AppointmentDrawer
            appointmentType={currentEventSelected.type}
            handleAppointmentCancel={handleClickCancelAppointment}
            handleCloseAppointmentDrawer={handleCloseAppointmentDrawer}
          />
          <AppointmentCancelDrawer
            codAgendamento={currentAppointmentId}
            setShowDrawer={setShowAppointmentCancelDrawer}
            showDrawer={searchParams.action === "cancel-appointment"}
          />

          <FlexiblePriceAppointmentCancelDrawer
            appointmentId={currentAppointmentId}
            onCancelSuccess={onFlexiblePriceAppointmentCancelSuccess}
          />

          <FlexiblePriceAppointmentRescheduleDrawer
            appointmentId={currentAppointmentId}
          />
        </>
      ) : null}

      <SelectModal
        elements={elementsFactory(locations)}
        onSelect={handleSelection}
        selected={selectedLocation.codUnidade}
        setShow={handleOpenSelectLocationModal}
        show={searchParams.action === "select-location"}
      />

      <RemoveUnavailableSlotDrawer
        handleCloseRemoveUnavailableSlot={handleCloseRemoveUnavailableSlot}
      />

      <PendingBillingDrawer />
      <AppointmentExtendedDrawer />
    </>
  );
};
