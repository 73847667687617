import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { ListRoot } from "@/components/list";
import { DatePayablesDetailsListItem } from "@/components/date-payables-details-list-item";

const DatePayablesDetailsListFragment = graphql(`
  fragment ExpensumGetPayablesFromDatePayables on ExpensumGetPayablesFromDateOutput {
    payables {
      id
      ...ExpensumGetPayablesFromDatePayable
    }
  }
`);

interface DatePayablesDetailsListProps {
  data: FragmentType<typeof DatePayablesDetailsListFragment>;
}

export const DatePayablesDetailsList = ({
  data,
}: DatePayablesDetailsListProps): JSX.Element => {
  const fragmentResult = useFragment(DatePayablesDetailsListFragment, data);

  const { payables } = fragmentResult;

  const filteredPayables = payables.filter(
    (payable): payable is NonNullable<typeof payable> => Boolean(payable),
  );

  return (
    <ListRoot>
      {filteredPayables.map((payable) => {
        return <DatePayablesDetailsListItem data={payable} key={payable.id} />;
      })}
    </ListRoot>
  );
};
