import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useEffect, useState } from "react";
import { FemaleDoctorWithClipboard, ExclamationCircle } from "@repo/icons";
import { Link, useRouter, useSearch } from "@tanstack/react-router";
import { WaitingRoomPageQueryDocument } from "@repo/graphql-types/graphql";
import { SearchInput } from "@/components/search-input";
import { WaitingRoomAppointmentListItem } from "@/components/waiting-room-appointment-list-item";
import {
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateImage,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { AppointmentCancelDrawer } from "@/components/appointment-cancel-drawer.tsx";
import { WaitingRoomAppointmentAlert } from "@/components/waiting-room-alert-change-room.tsx";
import { FlexiblePriceAppointmentCancelDrawer } from "@/components/flexible-price-appointment-cancel-drawer";
import { FlexiblePriceAppointmentRescheduleDrawer } from "@/components/flexible-price-appointment-reschedule-drawer";
import { TreatedAppointmentDrawer } from "@/components/waiting-room-confirm-treated-appointment-drawer";
import { UntreatedAppointmentDrawer } from "@/components/waiting-room-confirm-untreated-appointment-drawer";
import { useInvalidateQuery } from "@/hooks/use-graphql";

export const WaitingRoomAppointmentListQueryFragment = graphql(/* GraphQL */ `
  fragment WaitingRoomAppointmentListQueryFragment on query_root {
    agendamentos(where: $whereBuild, order_by: { horaInicio: asc }) {
      codAgendamento
      horaFim
      agendamentoPreAlocacao: AgendamentoPreAlocacao {
        UnidadeSala {
          TipoSala {
            nome
            codTipoSala
          }
        }
      }
      agendamentoAlocacaoAtiva: AgendamentosAlocacoesAtivas {
        UnidadeSala {
          TipoSala {
            nome
            codTipoSala
          }
        }
      }
      compromisso: UsuarioCompromisso {
        UsuariosCompromissosTiposSalas {
          codTipoSala
          codUsuarioCompromisso
          prioridade
        }
      }
      ...WaitingRoomAppointmentListItemFragment
    }
  }
`);

interface WaitingRoomAppointmentListItemProps {
  data: FragmentType<typeof WaitingRoomAppointmentListQueryFragment>;
}
export interface WaitingRoomSearch {
  action?: string;
  appointmentId?: number;
  status?: number;
  checkin?: number;
}

export const WaitingRoomAppointmentList = ({
  data,
}: WaitingRoomAppointmentListItemProps): JSX.Element => {
  const params: WaitingRoomSearch = useSearch({ from: "/waiting-room/" });

  const { agendamentos: appointments } = useFragment(
    WaitingRoomAppointmentListQueryFragment,
    data,
  );

  const [searchResults, setSearchResults] = useState(appointments);

  const router = useRouter();

  const invalidateWaitingRoomQuery = useInvalidateQuery(WaitingRoomPageQueryDocument);

  useEffect(() => {
    const isDrawerOpen =
      params.action === "treated-appointment-drawer" ||
      params.action === "untreated-appointment-drawer";

    const verifyAppointmentId = (): boolean => {
      return appointments.some(
        (appointment) => appointment.codAgendamento === Number(params.appointmentId),
      );
    };

    if (isDrawerOpen && !verifyAppointmentId()) {
      void router.navigate({
        search: { action: undefined, appointmentId: undefined },
        replace: true,
      });
    }
  }, [appointments, params.action, params.appointmentId, router]);

  const handleShowDrawer = (value: boolean, action: string): void => {
    if (!value && params.action === action) {
      void router.navigate({
        search: { action: undefined, appointmentId: undefined },
        replace: true,
      });
      router.history.back();
    }
  };

  const setShowCancelDrawer = (value: boolean): void => {
    handleShowDrawer(value, "cancel-appointment");
  };

  const setShowTreatedDrawer = (value: boolean): void => {
    handleShowDrawer(value, "treated-appointment-drawer");
  };

  const setShowUntreatedDrawer = (value: boolean): void => {
    handleShowDrawer(value, "untreated-appointment-drawer");
  };

  useEffect(() => {
    setSearchResults(appointments);
  }, [appointments]);

  const onAppointmentCancelSuccess = (): void => {
    invalidateWaitingRoomQuery();

    router.history.back();
  };

  let content;

  const paramsExist = (): boolean =>
    params.checkin !== undefined || params.status !== undefined;

  if (appointments.length > 0) {
    content = (
      <>
        <SearchInput
          items={appointments}
          onSearch={setSearchResults}
          searchKeys={[
            "paciente.nome",
            "unidade.nome",
            "compromisso.nome",
            "horaInicio",
            "horaFim",
          ]}
        />
        <ul className="flex flex-col gap-4 mt-4 pb-6">
          {searchResults.map((appointment, index) => (
            <li key={appointment.codAgendamento} className="gap-4 flex flex-col">
              <WaitingRoomAppointmentListItem data={appointment} />
              <WaitingRoomAppointmentAlert appointments={appointments} index={index} />
            </li>
          ))}
        </ul>
        <AppointmentCancelDrawer
          codAgendamento={Number(params.appointmentId ?? 0)}
          setShowDrawer={setShowCancelDrawer}
          showDrawer={params.action === "cancel-appointment"}
        />
        <TreatedAppointmentDrawer
          open={params.action === "treated-appointment-drawer"}
          setOpen={setShowTreatedDrawer}
        />
        <UntreatedAppointmentDrawer
          open={params.action === "untreated-appointment-drawer"}
          setOpen={setShowUntreatedDrawer}
        />

        <FlexiblePriceAppointmentCancelDrawer
          appointmentId={Number(params.appointmentId ?? 0)}
          onCancelSuccess={onAppointmentCancelSuccess}
        />

        <FlexiblePriceAppointmentRescheduleDrawer
          appointmentId={Number(params.appointmentId ?? 0)}
        />
      </>
    );
  } else if (appointments.length === 0 && paramsExist()) {
    content = (
      <EmptyStateRoot>
        <EmptyStateIcon icon={ExclamationCircle} />
        <EmptyStateTitle>Nenhum agendamento encontrado</EmptyStateTitle>
        <EmptyStateDescription>
          Tente redefinir ou ajustar os filtros e realizar a busca novamente.
        </EmptyStateDescription>
        <EmptyStateButton
          onClick={() =>
            void router.navigate({
              search: {
                ...params,
                checkin: undefined,
                status: undefined,
              },
            })
          }
          fullWidth
        >
          Limpar filtros
        </EmptyStateButton>
      </EmptyStateRoot>
    );
  } else {
    content = (
      <EmptyStateRoot>
        <EmptyStateImage
          icon={FemaleDoctorWithClipboard}
          alt="Imagem de nenhum paciente agendado"
        />
        <EmptyStateTitle>Você não tem pacientes agendados para hoje.</EmptyStateTitle>
        <Link to="/appointment/create" className="w-full">
          <EmptyStateButton fullWidth>Agendar agora</EmptyStateButton>
        </Link>
      </EmptyStateRoot>
    );
  }

  return content;
};
