import { useRef, useState } from "react";

interface DraggableProps {
  children: React.ReactNode;
}

export const Draggable = ({ children }: DraggableProps): JSX.Element => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });
  const handleDragStart = (e: React.MouseEvent): void => {
    const slider = divRef.current?.children[0] as HTMLDivElement;
    const startX = e.pageX - slider.offsetLeft;
    const scrollLeft = slider.scrollLeft;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
  };

  const handleDrag = (e: React.MouseEvent): void => {
    if (!isMouseDown) return;
    e.preventDefault();
    const slider = divRef.current?.children[0] as HTMLDivElement;
    const x = e.pageX - slider.offsetLeft;
    const dragX = x - mouseCoords.current.startX;
    slider.scrollLeft = mouseCoords.current.scrollLeft - dragX;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions -- This div is used to handle mouse events
    <div
      ref={divRef}
      onMouseDown={handleDragStart}
      onMouseUp={() => {
        setIsMouseDown(false);
      }}
      onMouseLeave={() => {
        setIsMouseDown(false);
      }}
      onMouseMove={handleDrag}
    >
      {children}
    </div>
  );
};
