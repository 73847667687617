import { ExclamationCircle } from "@repo/icons";
import { useRouter } from "@tanstack/react-router";
import { type UseQueryResult } from "@tanstack/react-query";
import {
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { FullPageSpinner } from "@/components/full-page-spinner";

type AsyncDataWrapperProps = UseQueryResult & {
  children: React.ReactNode;
  fallback?: JSX.Element;
};

export const AsyncDataWrapper = ({
  isPending,
  isError,
  children,
  fallback = <FullPageSpinner />,
}: AsyncDataWrapperProps): JSX.Element => {
  const router = useRouter();

  if (isPending) {
    return fallback;
  }

  if (isError) {
    const reloadPage = (): void => router.history.go(0);

    return (
      <EmptyStateRoot>
        <EmptyStateIcon icon={ExclamationCircle} fill="danger" />
        <EmptyStateTitle>Ocorreu um erro</EmptyStateTitle>
        <EmptyStateDescription>
          Parece que ocorreu um erro ao tentar realizar esta ação. Por favor, tente
          novamente ou recarregue a página.
        </EmptyStateDescription>

        <EmptyStateButton onClick={reloadPage} fullWidth>
          Tentar novamente
        </EmptyStateButton>
      </EmptyStateRoot>
    );
  }

  return <>{children}</>;
};
