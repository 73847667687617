export interface Location {
  __typename?: string;
  codUnidade: number;
  nome: string;
  nomeLimpo?: string | null;
  ativo: boolean;
  sigla?: string | null;
}

export const allLocationObject: Location = {
  __typename: "unidades",
  codUnidade: 0,
  nome: "Todas",
  nomeLimpo: "Todas",
  ativo: true,
  sigla: "Todas",
};
