import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { ScheduleForm } from "@/components/schedule-form";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { PendingComponent } from "@/components/pending-component";

const CreateSchedulePageQuery = graphql(/* GraphQL */ `
  query CreateSchedulePageQuery($codUsuario: Int!, $codUsuarioAgenda: Int! = 0) {
    ...ScheduleFormQueryFragment
  }
`);

export const CreateSchedulePage = (): JSX.Element => {
  const { user } = Route.useRouteContext();

  const queryResult = useGraphQL(CreateSchedulePageQuery, {
    codUsuario: user.codUsuario,
    codUsuarioAgenda: 0,
  });
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Criar agenda" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data && queryResult.isFetchedAfterMount ? (
            <ScheduleForm data={data} />
          ) : (
            <FullPageSpinner />
          )}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/schedule-options/schedules/create")({
  component: CreateSchedulePage,
  pendingComponent: () => <PendingComponent title="Criar agenda" />,
});
