import { type FragmentType, useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { CpsCheckboxV2 } from "corpus";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { formatDateStringToHourMinute, logError } from "@repo/lib";
import { CircleCheck } from "@repo/icons";
import { WaitingRoomPageQueryDocument } from "@repo/graphql-types/graphql";
import {
  FormField,
  FormHandlerSubmit,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";
import { type Info, InfoItem, ListRoot } from "@/components/list";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

export const ChangeTimeConfirmFragment = graphql(`
  fragment ChangeTimeConfirmFragment on agendamentos {
    codAgendamento
    codUnidade
    codUsuarioCompromisso
    codUsuarioFormaRecebimento
    codUsuarioAgenda
    data
    nome
    nomeConvenio
    nomePlano
    numeroCarteirinha
    validadeCarteirinha
    nome
    dataNascimento
    confirmado
    cancelado
    cobraCancelamento
    codPaciente
    telefone
    telefoneProprio
    email
    validadeCarteirinha
    ignorarEnvioEmail
    ignorarEnvioWhatsApp
    codFinalidadeAgendamento
    paciente: Paciente {
      cpfProprio
    }
    unidade: Unidade {
      nomeLimpo
    }
  }
`);

const ChangeTimeMutation = graphql(`
  mutation ChangeTime($input: AdiantarOuAtrasarInput!) {
    adiantarOuAtrasar(input: $input) {
      data: agendamentoApiOutput {
        codAgendamento
      }
      errors {
        ... on ValidationError {
          __typename
          message
        }
        ... on RepeatedPatientError {
          __typename
          message
        }
      }
    }
  }
`);

interface ChangeTimeConfirmProps {
  data: FragmentType<typeof ChangeTimeConfirmFragment>;
}

export const ChangeTimeConfirm = ({ data }: ChangeTimeConfirmProps): JSX.Element => {
  const { user } = useRouteContext({ strict: false });
  const appointment = useFragment(ChangeTimeConfirmFragment, data);

  const params = useSearch({
    from: "/waiting-room/$codAgendamento/change-time/confirm/",
  });
  const { mutateAsync } = useGraphQLMutationWithErrorHandler(ChangeTimeMutation);

  const router = useRouter();
  const invalidateWaitingRoomQuery = useInvalidateQuery(WaitingRoomPageQueryDocument);

  const {
    data: appointmentDate,
    codUnidade,
    codUsuarioCompromisso,
    codUsuarioFormaRecebimento,
    codUsuarioAgenda,
    dataNascimento,
    email,
    nome,
    telefone,
    confirmado,
    cancelado,
    ignorarEnvioEmail,
    ignorarEnvioWhatsApp,
    nomePlano,
    numeroCarteirinha,
    cobraCancelamento,
    codFinalidadeAgendamento,
  } = appointment;

  const formattedStartHour = formatDateStringToHourMinute(params.horaInicio);
  const formattedEndHour = formatDateStringToHourMinute(params.horaFim);

  const formSchema = z.object({
    warningPatientAboutChange: z.boolean(),
  });

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      warningPatientAboutChange: false,
    },
  });

  const handleCloseDrawerSuccessAction = (): void => {
    invalidateWaitingRoomQuery();

    void router.navigate({ to: "/waiting-room" });
  };

  const setOpenFeedbackDrawer = (value: boolean, action: string): void => {
    if (value && params.action === action) {
      void router.navigate({
        search: {
          horaInicio: params.horaInicio,
          horaFim: params.horaFim,
          action,
        },
      });
    } else if (!value && params.action === action) {
      if (params.action === "change-time-success") {
        handleCloseDrawerSuccessAction();
      }
    }
  };

  const handleSubmit = async (values: FormFields): Promise<void> => {
    const onError = (error: Error): void => {
      logError(error);
    };

    const onSuccess = (): void => {
      void router.navigate({
        search: {
          horaInicio: params.horaInicio,
          horaFim: params.horaFim,
          action: "change-time-success",
        },
      });
    };

    await mutateAsync(
      {
        input: {
          codAgendamentoOrigem: Number(appointment.codAgendamento),
          enviaEmailPaciente: values.warningPatientAboutChange,
          remarcarNovoAgendamento: {
            codUnidade,
            codUsuarioAgenda,
            data: appointmentDate,
            horaInicio: formattedStartHour,
            horaFim: formattedEndHour,
            codUsuarioCompromisso,
            codUsuarioFormaRecebimento,
            codUsuario: user.codUsuario,
            email: email ?? "",
            nome: nome ?? "",
            telefone,
            dataNascimento,
            receberWhatsAppConfirmacao: values.warningPatientAboutChange,
            eventual: false,
            ignorarEnvioEmail,
            ignorarEnvioWhatsApp,
            nomePlano,
            numeroCarteirinha,
            confirmado,
            cancelado,
            cobraCancelamento,
            codFinalidadeAgendamento,
          },
        },
      },
      { onSuccess, onError },
    );
  };

  const infos: Info[] = [
    {
      label: "Início",
      value: formattedStartHour,
    },
    {
      label: "Fim",
      value: formattedEndHour,
    },
    {
      label: "Paciente",
      value: nome ?? "-",
    },
    {
      label: "Unidade",
      value: appointment.unidade.nomeLimpo ?? "-",
    },
  ];

  const warningPatientAboutChange = form.watch("warningPatientAboutChange");

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={handleSubmit}>
        <div className="flex flex-col gap-2">
          <h4 className="font-semibold text-md text-neutral-600">
            Detalhes da alteração
          </h4>
          <ListRoot>
            {infos.map((info) => (
              <InfoItem key={`${info.label}-${info.value}`} itemInfo={info} />
            ))}
          </ListRoot>
          <FormField
            render={({ field }) => (
              <CpsCheckboxV2
                label="Avisar o paciente sobre a mudança"
                className="bg-base p-0 py-4"
                onCheckedChange={field.onChange}
                value={String(field.value)}
              />
            )}
            name="warningPatientAboutChange"
          />
          <FormSubmitButton>Confirmar</FormSubmitButton>
        </div>
        <MessageDrawerRoot
          icon={CircleCheck}
          open={params.action === "change-time-success"}
          setOpen={(value) => setOpenFeedbackDrawer(value, "change-time-success")}
          variant="secondary"
        >
          <MessageDrawerTitle>Agendamento remarcado</MessageDrawerTitle>
          <MessageDrawerBody>
            {warningPatientAboutChange
              ? "O paciente receberá um e-mail com a atualização do agendamento."
              : "O horário desse agendamento foi alterado com sucesso."}
          </MessageDrawerBody>
          <MessageDrawerActions>
            <MessageDrawerActionButton onClick={handleCloseDrawerSuccessAction}>
              Ir para sala de espera
            </MessageDrawerActionButton>
          </MessageDrawerActions>
        </MessageDrawerRoot>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
