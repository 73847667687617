import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Calendar } from "@repo/icons";
import { ScheduleFrequencyEnum, formatWithZonedDate, zonedDate } from "@repo/lib";

export const ScheduleListItemCardStartDateFragment = graphql(/* GraphQL */ `
  fragment ScheduleListItemCardStartDateFragment on usuariosAgendas {
    tipoRepeticao: codTipoAgendaRepeticao
    startDate: dataInicio
  }
`);

export const ScheduleListItemCardStartDate = (props: {
  schedule: FragmentType<typeof ScheduleListItemCardStartDateFragment>;
}): JSX.Element => {
  const { startDate, tipoRepeticao } = useFragment(
    ScheduleListItemCardStartDateFragment,
    props.schedule,
  );

  const buildMainContent = (): string => {
    if (tipoRepeticao === Number(ScheduleFrequencyEnum.NaoRepetir)) {
      return formatStartDate();
    }

    return `A partir de ${formatStartDate()}`;
  };

  const formatStartDate = (): string => {
    const zonedStartDate = zonedDate(startDate);

    return formatWithZonedDate(zonedStartDate, "dd 'de' MMMM");
  };

  return (
    <div data-testid="start-date-content-test" className="bg-secondary-50 rounded-lg">
      <div className="flex flex-row items-center p-3 text-secondary-500 font-medium gap-x-2">
        <Calendar className="fill-current" size={24} />
        <p>{buildMainContent()}</p>
      </div>
    </div>
  );
};
