import { CpsInput } from "corpus";
import Cards, { type Focused } from "react-credit-cards";
import { graphql } from "@repo/graphql-types";
import "react-credit-cards/es/styles-compiled.css";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { formatCreditCard, formatCardExpireDate } from "@repo/lib";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import { getEncodedCard } from "@/lib/integrations/pagarme";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";

const LivanceApiCadastraCartaoMutation = graphql(/* GraphQL */ `
  mutation LivanceApiCadastraCartaoMutation($cardHash: String!, $codUsuario: Int!) {
    LivanceApiCadastraNovoCartao(arg1: { cardHash: $cardHash, codUsuario: $codUsuario }) {
      ativo
      codUsuario
      cardHash
    }
  }
`);

export const CreditCardForm = (): JSX.Element => {
  const router = useRouter();
  const { user } = useRouteContext({ strict: false });
  const { mutateAsync } = useGraphQLMutation(LivanceApiCadastraCartaoMutation);

  const searchParams = useSearch({
    from: "/settings/profile/card/create",
  });

  const setShowUnexpectedErrorDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/profile/card/create",
        search: { action: "create-card-error" },
      });
    } else if (searchParams.action === "create-card-error") {
      router.history.back();
    }
  };

  const formSchema = z
    .object({
      cvc: z.string().length(3, "Campo obrigatório"),
      expiry: z.string().length(5, "Campo obrigatório"),
      focus: z.string().optional(),
      name: z
        .string()
        .min(3, "Campo obrigatório")
        .max(30, "O nome não pode ter mais de 30 caracteres"),
      number: z.string().length(19, "Campo obrigatório"),
    })
    .superRefine((values, context) => {
      const month = Number(values.expiry.substring(0, 2));
      const year = Number(values.expiry.substring(3));

      const expiryDate = new Date(year + 2000, month - 1); // JavaScript months are 0-indexed
      const currentDate = new Date();

      if (month < 1 || month > 12 || expiryDate <= currentDate) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Data de vencimento inválida",
          path: ["expiry"],
        });
      }
    });

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      cvc: "",
      expiry: "",
      focus: "",
      name: "",
      number: "",
    },
  });

  const handleSubmit = async (formFields: FormFields): Promise<void> => {
    const cardNumber = formFields.number;
    const cardName = formFields.name;
    const cardExpiry = formFields.expiry.replace(/\//g, "");
    const cardCvc = formFields.cvc;

    const cardHash = await getEncodedCard(cardNumber, cardName, cardExpiry, cardCvc);

    const variables = {
      cardHash,
      codUsuario: user.codUsuario,
    };

    await mutateAsync(variables, {
      onSuccess,
      onError,
    });
  };

  const onSuccess = (): void => {
    form.reset();
    router.history.go(-1);
  };

  const onError = (): void => setShowUnexpectedErrorDrawer(true);

  const handleFocus = (focusField: React.FocusEvent<HTMLInputElement>): void => {
    form.setValue("focus", focusField.target.name);
  };

  return (
    <>
      <div className="pb-8">
        <Cards
          cvc={form.watch("cvc")}
          expiry={form.watch("expiry")}
          focused={form.watch("focus") as Focused}
          name={form.watch("name")}
          number={form.watch("number")}
          locale={{ valid: "Vencimento" }}
          placeholders={{ name: "NOME DO TITULAR" }}
        />
      </div>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={handleSubmit}>
          <FormField
            control={form.control}
            name="number"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    onFocus={handleFocus}
                    required
                    title="Número"
                    inputMode="numeric"
                    type="text"
                    placeholder="0000 0000 0000 0000"
                    maxLength={19}
                    {...field}
                    onChange={(e) =>
                      field.onChange(formatCreditCard(String(e.target.value)))
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    onFocus={handleFocus}
                    required
                    title="Nome no cartão"
                    type="text"
                    placeholder="Seu nome"
                    {...field}
                    onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="expiry"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    onFocus={handleFocus}
                    required
                    title="Vencimento"
                    type="text"
                    inputMode="numeric"
                    placeholder="MM/AA"
                    maxLength={5}
                    {...field}
                    onChange={(e) =>
                      field.onChange(formatCardExpireDate(String(e.target.value)))
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cvc"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    onFocus={handleFocus}
                    required
                    title="CVV"
                    inputMode="numeric"
                    type="text"
                    placeholder="xxx"
                    maxLength={3}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormSubmitButton>Cadastrar</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>
      <UnexpectedErrorDrawer
        open={searchParams.action === "create-card-error"}
        setOpen={setShowUnexpectedErrorDrawer}
      />
    </>
  );
};
