import { Clock } from "@repo/icons";
import { CpsFeedbackBubble } from "corpus";
import {
  DrawerRoot,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
} from "@/components/drawer";
import { FullPageSpinner } from "@/components/full-page-spinner";

export const SlotsFallback = (): JSX.Element => {
  return (
    <>
      <FullPageSpinner />
      <DrawerRoot dismissible={false} setOpen={() => true} open>
        <DrawerContent>
          <DrawerHeader>
            <div className="flex flex-col items-center">
              <CpsFeedbackBubble className="self-center" Icon={Clock} />
              <DrawerTitle>Buscando horários disponíveis</DrawerTitle>
            </div>
          </DrawerHeader>
          <DrawerDescription>
            <div className="flex flex-col items-center pb-14">
              <p className="text-center px-10">
                Estamos carregando as melhores opções de horários para o seu período
                garantido
              </p>
            </div>
          </DrawerDescription>
        </DrawerContent>
      </DrawerRoot>
    </>
  );
};
